import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { BaseService } from './base.service';
import { HttpClient} from '@angular/common/http';

// services
import { AuthService } from './auth.service';
import { AppConfig } from '../AppConfig';

@Injectable()
export class LogsService extends BaseService {

	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public find(id: number, limit: number = 50, page: number = 1, filter: number = 2, conditions: any = null, order: any = null) {
		let body = 'filter=' + filter;
		let offset = (page - 1) * limit;

		if (conditions) {
			body += '&conditions=' + JSON.stringify(conditions);
		}

		if (order) {
			body += '&order=' + JSON.stringify(order);
		}	

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/logs/view/' + id + '/' + limit + '/' + offset, body , { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error);
			});
		});
	}

	public delete(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/logs/deletebyid', 'id=' + id , { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getDataFromLogs(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/logs/data', 'id=' + id , { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}
}
