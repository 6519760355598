import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// services
import { AuthService } from '../../services/auth.service';
import { DevicesService } from '../../services/devices.service';
import { WeatherService } from '../../services/weather.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// utils
import * as moment from 'moment-timezone';

@Component({
	selector: 'family',
	templateUrl: './family.component.html'
})

export class FamilyComponent implements OnInit, OnDestroy {
	public id: number;
	public device: any;
	public forecast: any;
	public date: any;
	public background: string;
	public loggedUser: any = this._authSvc.user;
	public desconectedHours = '8';
	public deviceOffHours: any;
	private _offHoursTimeout: any;
	private _interval: any;
	public isNotOk: boolean;
	get cn() {
		return {
			value: parseFloat(this.device.vars.cn.substring(0, 4)),
			unit: 'gr/l'
		};
	}

	constructor(
		private route: ActivatedRoute,
		private _authSvc: AuthService,
		private _devicesSvc: DevicesService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService,
		private _weatherSvc: WeatherService) {
	}

	public ngOnInit() {
		this.route.params.subscribe((params) => {
			this.id = params['id'];

			if (this._interval) {
				clearInterval(this._interval);
			} else {
				this._getDevice();
			}

			this._interval = setInterval(() => {
				this._getDevice();
			}, 10000);
		});
	}

	ngOnDestroy() {
		clearInterval(this._interval);
	}

	public changeOffHours() {
		const newHours = parseInt(this.desconectedHours, 10);

		if (this._offHoursTimeout) {
			clearTimeout(this._offHoursTimeout);
			this._offHoursTimeout = null;
		}

		this._offHoursTimeout = setTimeout(() => {
			this._devicesSvc.changeOffHours(this.device.id, this.loggedUser.id_user, newHours).then((result: any) => {
				this._notificationSvc.success('', this._translate.instant(result.message));
				clearInterval(this._interval);
				this._getDevice();
				this._interval = setInterval(() => {
					this._getDevice();
				}, 10000);
			}).catch((error) => {
				this._notificationSvc.error('', this._translate.instant(error.message));
			});
		}, 750);
	}

	private _getDevice() {
		this._devicesSvc.get(this.id).then((device: any) => {
			this.deviceOffHours = device.hoursDesconected();
			this.device = device;
			this.desconectedHours = this.device.max_off_hours;
			this.date = moment().tz(this.device.timezone);
			this._devicesSvc.getDoesPoolNeedMaintenance(this.device.ns).then((result: any) => {
						this.isNotOk = result
				})
			this._weatherSvc.getWeather(this.device).then((forecast: any) => {
				if (forecast) {
					this.forecast = forecast['data'].current_condition[0];
					this.background = '/assets/images/vfamily/fondos/' + forecast.backgroundState.view;
				}
			});
		});
	}
}
