import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'temperature'
})
export class TemperaturePipe implements PipeTransform {
	transform(value: string, unit: string): any {
		let tempValue = parseFloat(value.substring(0, 5));
		let tempUnit = 'Cº';

		if (value === '------') {
			return '0.0 ' + unit;
		}

		if (unit !== 'C') {
			tempValue = tempValue * 1.8 + 32;
			tempValue = parseFloat(tempValue.toFixed(2));
			tempUnit = 'ºF';
		}

		return tempValue + ' ' + tempUnit;
	}

}
