<header ps-header>
	<h2>{{ "REGISTRO" | translate }}</h2>
</header>

<main class="container-fluid">
	<ul class="nav nav-pills nav-justified nav-wizard">
		<li role="presentation" [ngClass]="{'active': completed == 0}"><a href="#">{{'BARRA_PROGRESO_REGISTRO1' | translate}}</a></li>
		<li role="presentation" [ngClass]="{'active': completed == 1}"><a href="#">{{'BARRA_PROGRESO_REGISTRO2' | translate}}</a></li>
		<li role="presentation" [ngClass]="{'active': completed == 2}"><a href="#">{{'BARRA_PROGRESO_REGISTRO3' | translate}}</a></li>
		<li role="presentation" [ngClass]="{'active': completed == 3}"><a href="#">{{'BARRA_PROGRESO_REGISTRO4' | translate}}</a></li>
	</ul>

	<br>
	<div *ngIf="completed === 0">
		<h3 class="text-center">{{ 'TIPO_DE_CUENTA_EXPLICACION' | translate | uppercase }}</h3>
		<p id="mantenedor-register" class="text-center">{{ 'CUENTA_NECESITO' | translate }}</p>
		<p class="text-center" [innerHTML]="'EXPLICACION_CABECERA' | translate"></p>
		<div class="row">
			<div class="col-sm-6">
				<div position-relative class="panel panel-default" (click)="account.idRole = 2" [ngClass]="{'blured': account.idRole != 2 }">
					<div class="panel-body">
						<img class="img-responsive" src="../../assets/images/registro/img-usuario.png">
						<br>
						<span position-absolute class="checkmark">
							<img *ngIf="account.idRole === 2" src="../../assets/images/vp_estado_ok@2x.png">
						</span>
						<p [innerHTML]="'CUENTA_USUARIO_EXPLICACION' | translate"></p>
						<p [innerHTML]="'CUENTA_USUARIO_EXPLICACION_2' | translate"></p>
						<p>
							<strong>{{'QUIERO_CUENTA' | translate}} {{'USUARIO' | translate}}</strong><span [innerHTML]="'CUENTA_USUARIO_EXPLICACION_3' | translate"></span>
						</p>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div position-relative class="panel panel-default" (click)="account.idRole = 3" [ngClass]="{'blured': account.idRole != 3 }">
					<div class="panel-body">
						<img class="img-responsive" src="../../assets/images/registro/img-mantenedor.png">
						<br>
						<span position-absolute class="checkmark">
							<img *ngIf="account.idRole === 3" src="../../assets/images/vp_estado_ok@2x.png">
						</span>
						<p [innerHtml]="'CUENTA_MANTENEDOR_EXPLICACION' | translate"></p>
						<p [innerHtml]="'CUENTA_MANTENEDOR_EXPLICACION_2' | translate"></p>
						<p>
							<strong>{{ 'QUIERO_CUENTA' | translate }} {{ 'MANTENEDOR' | translate }}</strong><span [innerHTML]="'CUENTA_MANTENEDOR_EXPLICACION_3' | translate"></span>
						</p>
						<br>
						<div class="form-group">
							<label for="company">{{ 'COMPANY' | translate }}</label>
							<input type="text" class="form-control" name="company" id="company" maxlenght="255" [(ngModel)]="account.companyName" [readonly]="account.idRole === 2" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="completed === 1" class="container form-horizontal">
		<fieldset>
			<legend>{{'DATOS_ACCESO' | translate}}</legend>
			<div class="form-group">
				<label class="col-sm-2 control-label" for="email">{{'EMAIL' | translate}}</label>
				<div class="col-sm-9">
					<input type="email" [(ngModel)]="account.email" name="email" id="email" size="40" class="form-control" />
				</div>
			</div>
			<div class="form-group" [ngClass]="{'has-error': account.password && passwordAux && account.password != passwordAux}">
				<label class="col-sm-2 control-label" for="password1">{{'CONTRASENYA' | translate}}</label>
				<div class="col-sm-9">
					<input type="password" [(ngModel)]="account.password" name="password1" id="password1" class="form-control" />
				</div>
			</div>
			<div class="form-group" [ngClass]="{'has-error': account.password && passwordAux && account.password != passwordAux}">
				<label class="col-sm-2 control-label" for="password2">{{'REPITA_CONTRASENYA' | translate}}</label>
				<div class="col-sm-9">
					<input type="password" [(ngModel)]="passwordAux" name="password2" id="password2" class="form-control" />
				</div>
			</div>
		</fieldset>
		<fieldset>
			<legend>{{'DATOS_PERSONALES' | translate}}</legend>
			<div class="form-group">
				<label class="col-sm-2 control-label" for="name">{{'NOMBRE' | translate}}</label>
				<div class="col-sm-9"><input type="text" [(ngModel)]="account.name" name="name" id="name" class="form-control" size="50" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-2 control-label" for="aname">{{'APELLIDOS' | translate}}</label>
				<div class="col-sm-9">
					<input type="text" [(ngModel)]="account.surname" name="aname" id="aname" class="form-control" size="40" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-2 control-label" for="phone">{{'TELEFONO' | translate}}</label>
				<div class="col-sm-9">
					<input type="text" [(ngModel)]="account.phone" name="phone" id="phone" class="form-control" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-2 control-label" for="country">{{'PAIS' | translate}}</label>
				<div class="col-sm-9">
					<input type="text" [(ngModel)]="account.country" class="form-control" name="country" id="country" size="30" maxlenght="255" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-2 control-label" for="city">{{'LOCALIDAD' | translate}}</label>
				<div class="col-sm-9">
					<input type="text" [(ngModel)]="account.location" class="form-control" name="city" id="city" size="30" maxlenght="255" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-2 control-label" for="location">{{'DIRECCION' | translate}}</label>
				<div class="col-sm-9">
					<input type="text" [(ngModel)]="account.address" id="location" class="form-control" name="location" size="30" maxlenght="255" />
				</div>
			</div>
      <input type="text" style="display: none" [(ngModel)]="user" id="user" class="form-control" name="user" size="30" maxlenght="255" />
      <input type="text" style="display: none" [(ngModel)]="website" id="website" class="form-control" name="website" size="30" maxlenght="255" />
		</fieldset>

		<fieldset>
			<div class="form-group">
				<div class="col-sm-2"></div>
			<div class="col-sm-9">
				<ngx-recaptcha2 #reCaptcha
						[siteKey]="captchaSvc.key"
						(success)="onCaptchaSuccess($event)"
						[hl]="browserLang"
						[ngModel]="token"
				></ngx-recaptcha2>
			</div>
			</div>

			<div class="form-group">
				<div class="col-sm-10">
					<div class="checkbox">
						<label>
							<input type="checkbox" (checked)="conditions" (change)="conditions=$event.target.checked" />
							{{ 'ACEPTO_CONDICIONES_INIT' | translate }}
							<a [routerLink]="['/data-protection']">{{ 'ACEPTO_CONDICIONES' | translate }}</a>
							{{ 'ACEPTO_CONDICIONES_BRIDGE' | translate }}
							<a [routerLink]="['/privacy']">{{ 'ACEPTO_PRIVACIDAD' | translate }}</a>.
						</label>
					</div>
				</div>
			</div>
		</fieldset>
	</div>

	<div *ngIf="completed === 2" class="container">
		<div innerHtml="{{messageCompleted}}"></div>
	</div>

	<p class="text-center">
		<button [disabled]="saving" class="btn btn-danger" [routerLink]="['/']" aria-disabled="false">{{ 'BT_VOLVER' | translate }}</button>
		<button [disabled]="saving" *ngIf="completed == 1" class="btn btn-primary" role="button" aria-disabled="false" (click)="regPosition('back')">{{ 'BT_ATRAS' | translate }}</button>
		<button [disabled]="saving" *ngIf="completed < 1" class="btn btn-primary " role="button " aria-disabled="false" (click)="regPosition('next')">{{ 'BT_SIGUIENTE' | translate }}</button>
		<button [disabled]="saving" *ngIf="completed === 1" class="btn btn-success " role="button " aria-disabled="false"
				(click)="validate()">
			<div *ngIf="saving" class="spinner" role="status"></div>
			<ng-container *ngIf="!saving">
				{{ 'BT_GUARDAR' | translate }}
			</ng-container>
		</button>
	</p>
</main>

<footer class="navbar-fixed-bottom container-fluid hidden-xs">
	<div class="row" style="background: #38535c;">
		<div class="col-sm-6">

		</div>
		<div id="copyright" class="col-sm-6 text-right">
			<span>{{currentYear}} </span> <!-- JLF Aquí imprimimos la variable generada en el .ts con la fecha -->
			<span text-ellipsis [innerHTML]="'COPYRIGHT' | translate"></span>
		</div>
	</div>
</footer>
