export class Firmware {
	public url: string;

	get file() {
		if (this.url) {
			let url = this.url.split('/');
			return url[url.length - 1];
		} else {
			return '';
		}
	}

	constructor(raw: any) {
		for (let prop in raw) {
			if (raw.hasOwnProperty(prop)) {
				this[prop] = raw[prop];
			}
		}
	}
}
