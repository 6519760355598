import { Injectable, Pipe, PipeTransform } from '@angular/core';

// utils
import * as _ from 'lodash';

@Pipe({
	name: 'toNumber'
})
@Injectable()
export class ToNumberPipe implements PipeTransform {
	public transform(value) {
		return _.toNumber(value);
	}
}
