import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'input-number',
	templateUrl: './input-number.component.html',
	styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {

	// tslint:disable-next-line:no-input-rename
	@Input('value') rawValue: any = 0;
	@Input('step') step: any = 1;
	@Input('min') min: any = -65535;
	@Input('max') max: any = 65535;
	@Input('precision') precision: any = 0;
	@Input('append') append: string = '';
	@Input('size') size: string = 'md';
	@Input('block') block: boolean = false;
	@Input('nullValue') nullValue: boolean = false;
	@Output() exit = new EventEmitter();

	private _sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
	private _subtractInterval: any;
	private _addInterval: any;
	private _intervalCont = 0;

	set value(newVal: any) {
		newVal = parseFloat(newVal);

		if (newVal < this.min) {
			this.rawValue = this.min;
		} else {
			this.rawValue = (newVal > this.max) ? this.max : newVal;
		}

		this.exit.emit(this.rawValue);
	}

	get value() {
		return this.rawValue;
	}

	constructor() { }

	ngOnInit() {
		this.size = (this._sizes.indexOf(this.size) > -1) ? this.size : 'md';
		this.min = parseFloat(this.min);
		this.max = parseFloat(this.max);
		this.step = parseFloat(this.step);
		this.rawValue = parseFloat(this.rawValue);

		if (isNaN(this.rawValue)) {
			this.rawValue = 0;
		}

		if (isNaN(this.step)) {
			this.step = 1;
		}

		if (isNaN(this.min) || isNaN(this.max) || this.min > this.max) {
			this.max = 65535;
			this.min = -65535;
		}
	}

	public add() {
		this.value = (+this.rawValue) + (+this.step);
		this._intervalCont = 0;
	}

	public fastAdd() {
		if (!this._addInterval) {
			this._intervalCont = 0;

			this._addInterval = setInterval(() => {
				if (this.value >= this.max) {
					this.stopIntervals();
				} else if (this._intervalCont < 10) {
					this.value = (+this.rawValue) + (+this.step);
					this._intervalCont++;
				} else {
					this.value = (+this.rawValue) + (+this.step * 10);
				}
			}, 250);
		}
	}

	public subtract() {
		this.value = (+this.rawValue) - (+this.step);
		this._intervalCont = 0;
	}

	public fastSubtract() {
		if (!this._subtractInterval) {
			this._intervalCont = 0;

			this._subtractInterval = setInterval(() => {
				if (this.value <= this.min) {
					this.stopIntervals();
				} else if (this._intervalCont < 10) {
					this.value = (+this.rawValue) - (+this.step);
					this._intervalCont++;
				} else {
					this.value = (+this.rawValue) - (+this.step * 10);
				}
			}, 250);
		}
	}

	public stopIntervals() {
		this._intervalCont = 0;
		clearInterval(this._subtractInterval);
		this._subtractInterval = null;
		clearInterval(this._addInterval);
		this._addInterval = null;
	}
}
