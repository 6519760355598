import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

//services
import { DevicesService } from '../../services/devices.service';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LogsService } from '../../services/logs.service';
import {RolesPipe} from '../../pipes/roles.pipe';

// utils
import * as moment from 'moment-timezone';
import ROLES from '../../entities/roles';
import { isFloat } from '../../../utils';

@Component({
	selector: 'logs',
	templateUrl: 'logs.component.html',
	styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

	public readonly MAX_ATTEMPTS = 10;
	public bsConfig: Partial<BsDatepickerConfig>;
	public logsData: any;
	public datesRange: any;
	public datesRangeFormated: string[];
	public minDate: any;
	public maxDate: any;
	public nameFilterValue: any[] = [];
	public nameFilterOptions: IMultiSelectOption[] = [];
	public nameFilterSettings: IMultiSelectSettings;
	public nameFilterTexts: IMultiSelectTexts;
	public eventsFilterValue: any[] = [-1, 3, 4, 5, 6, 7, 8, 9, 10, 11, -2, 12, 13, 14, 15, 16, 17, 18, 20, -3, 25, 27, 28, 29, 30, 31, 32, 33];
	public eventsFilterOptions: IMultiSelectOption[] = [];
	public eventsFilterSettings: IMultiSelectSettings;
	public eventsFilterTexts: IMultiSelectTexts;
	public hasAlarmsBefore = true;
	public hasStateBefore = true;
	public hasConfigBefore = true;
	public usersFromLogs: any;
	public loggedUser: any;
	public filtersDelay: any;
	public relaysNames = {};
	// tslint:disable-next-line:max-line-length
	public varsToSearch: string[] = ['t1', 't2', 't3', 't4', 'mm', 'ms', 'ac', 'aj', 'ab', 'af', 'ax', 'al', 'p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'pc', 'pj', 'pb', 'pf', 'px', 'pl', 'mc', 'mj', 'mb', 'mf', 'mx', 'ml', 'e1', 'e2', 'e3', 'e4', 'e5', 'e6', 'd1', 'd2', 'd3', 'd4', 'cc', 'sc', 'os', 'se', 'si', 'sg', 'cv', 'sn', 'sv', 'or', 'ce', 'co', 'ee', 'ei', 'eg', 'wp', 'pe', 'bp', 'cp', 'dp', 'gp', 'sp', 'op', 'ap', 'hp', 'ip', 'xp', 'tm', 'fp', 'wo', 'oe', 'bo', 'po', 'do', 'go', 'so', 'oo', 'ao', 'ho', 'io', 'xo', 'om', 'fo', 'wh', 'he', 'bh', 'ch', 'dc', 'gh', 'sh', 'oh', 'ah', 'hh', 'ih', 'xh', 'hm', 'fh', 'sf', 'ae', 'aw', 'ad', 'fe', 'fs', 'fd', 'bu', 'fu', 'lu', 'ru', 'an', 'at', 'as', 'gs', 'ws', 'tu'];
	// public filters: boolean = false;
	public filter = 2;
	public disableButtons = false;
	public device: any;
	public logs: any;
	public loaded = false;
	public interlockName: any[] = [];

	public eventsTypes: any = {
		alarms: {
			// ELECTROLYSIS
			3: ['cc'],
			// PH
			4: ['wp', 'pe', 'bp', 'cp', 'dp'],
			// ORP
			5: ['wo', 'oe', 'bo', 'po', 'do'],
			// PPM
			6: ['wh', 'he', 'bh', 'ch', 'dc'],
			// FLUJO
			7: ['sf', 'ae', 'aw', 'ad'],
			// UV
			8: ['bu', 'fu'],
			// PRESION
			9: ['an'],
			// TEMPERATURA
			10: ['at'],
			// SAL
			11: ['as', 'ws']
		},
		state: {
			// SISTEMA
			12: ['t1', 't2', 't3', 't4', 'mm'],
			// RELES
			13: ['ac', 'aj', 'ab', 'af', 'ax'],
			// ENTRADAS
			14: ['d1', 'd2', 'd3', 'd4'],
			// ELECTROLYSIS
			15: ['sc', 'os', 'se', 'si', 'sg', 'cv'],
			// PH
			16: ['gp'],
			// ORP
			17: ['go'],
			// PPM
			18: ['gh'],
			// UV
			20: ['lu']
		},
		config: {
			// RELES
			25: ['al', 'p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'pc', 'pj', 'pb', 'pf', 'px', 'pl', 'mc', 'mj', 'mb', 'mf', 'mx', 'ml', 'e1', 'e2', 'e3', 'e4', 'e5', 'e6'],
			// ELECTROLYSIS
			27: ['sn', 'sv', 'or', 'ce', 'co', 'ee', 'ei', 'eg'],
			// PH
			28: ['sp', 'op', 'ap', 'hp', 'ip', 'xp', 'tm', 'fp'],
			// ORP
			29: ['so', 'oo', 'ao', 'ho', 'io', 'xo', 'om', 'fo'],
			// PPM
			30: ['sh', 'oh', 'ah', 'hh', 'ih', 'xh', 'hm', 'fh'],
			// FLUJO
			31: ['fe', 'fs', 'fd'],
			// UV
			32: ['ru'],
			// CAMBIOS EN LOS EQUIPOS
			33: ['gs', 'tu']
		}
	};
	public order: any = {
		field: 'fecha',
		direction: 'DESC',
	};
	public variableNames = {
		so: 'SETPOINT_ORP',
		sp: 'SETPOINT_PH',
		sh: 'SETPOINT_CL',
		sn: 'SETPOINT_PRO_NORMAL',
		sv: 'SETPOINT_PRO_CV',
		pb: 'PROG_SEMA_BOMBA',
		pc: 'PROG_SEMA_FOCOS',
		pj: 'PROG_SEMA_LUJA',
		pf: 'PROG_SEMA_FARO',
		pl: 'PROG_SEMA_FLOCU',
		px: 'PROG_SEMA_AUX',
		e6: 'MODO_ACTUAL_AUX_ENC',
		ml: 'MODO_ACTUAL_AUX',
		e1: 'MODO_ACTUAL_FOCO_ENC',
		mc: 'MODO_ACTUAL_FOCO',
		e2: 'MODO_ACTUAL_LUJA_ENC',
		mj: 'MODO_ACTUAL_LUJA',
		e4: 'MODO_ACTUAL_BOMBA_ENC',
		mf: 'MODO_ACTUAL_BOMBA',
		e3: 'MODO_ACTUAL_FAROLA_ENC',
		mb: 'MODO_ACTUAL_FAROLA',
		e5: 'MODO_ACTUAL_FLO_ENC',
		mx: 'MODO_ACTUAL_FLO',
		co: 'COBERT_ENABLE',
		cv: 'COBERT_RUNNING',
		fs: 'FLUJO_SWITCH_ACTIVO',
		fe: 'FLUJO_ELEC_ACTIVO',
		or: 'ORP_DEPENDIENTE',
		xh: 'PORC_MAX_CLORO',
		xo: 'PORC_MAX_ORP',
		xp: 'PORC_MAX_PH',
		ih: 'DOS_INT_CLORO',
		hm: 'TIEMPO_MAX_DOSI_CLORO',
		op: 'ALARM_DEPO_PH',
		ip: 'DOSIFICADO_INT',
		ap: 'MODO_REDUCTOR_AUM_PH',
		hp: 'HISTER_PH',
		tm: 'TIEMPO_MAX_DOSI_PH',
		pe: 'PUMPSTOP_PH',
		ao: 'MODO_REDUCTOR_AUM_ORP',
		ho: 'HISTER_ORP',
		io: 'INTEL_DOSI_ORP',
		oo: 'ALAR_DEP_ORP',
		oe: 'PUMPSTOP_ORP',
		om: 'TIEMPO_MAX_DOSI_ORP',
		ah: 'MOD_REDU_AUM_CLORO',
		hh: 'HISTER_CLORO',
		oh: 'ALAR_DEP_CLORO',
		he: 'CLORO_PUMP_STOP',
		fp: 'ON_OFF_PH',
		fo: 'ON_OFF_ORP',
		fh: 'ON_OFF_CLORO',
		gs: 'ON_OFF_GS',
		ce: 'LIM_ELEC',
		//mm: 'STATUS_CLORAD',
		mm: 'MODELO_SISTEMA',
		ac: 'STATUS_SALI1',
		aj: 'STATUS_SALI2',
		af: 'STATUS_SALI4',
		ab: 'STATUS_SALI3',
		al: 'STATUS_SALI6',
		ax: 'STATUS_SALI5',
		wp: 'ALARMA_MED_PH',
		gp: 'DOSI_PH',
		dp: 'ESTA_DEPO_PH',
		wo: 'ALARMA_MED_ORP',
		go: 'DOSI_ORP',
		do: 'STATUS_DEP_ORP',
		wh: 'ALARMA_MED_PPM_CLORO',
		gh: 'DOSI_PPM_CLORO',
		dc: 'STATUS_DEP_PPM_CLORO',
		sc: 'SETPOINT_PRO_CLOR',
		sf: 'ALARM_FLU',
		cc: 'ALARM_CHECK_CELL',
		ws: 'ALARM_CONDUCTIVIDAD',
		os: 'PARO_ORP_PPM',
		tc: 'STATUS_POOL',
		p1: 'CAMBIO_EN_P1',
		p2: 'CAMBIO_EN_P2',
		p3: 'CAMBIO_EN_P3',
		p4: 'CAMBIO_EN_P4',
		p5: 'CAMBIO_EN_P5',
		p6: 'CAMBIO_EN_P6',
		p7: 'CAMBIO_EN_P7',
		p8: 'CAMBIO_EN_P8',
		p9: 'CAMBIO_EN_P9',
		au: 'ESTADO_LUMIPLUS',
		mu: 'MODO_LUMIPLUS',
		cu: 'COLOR_LUMIPLUS',
		su: 'SECUENCIA_LUMIPLUS',
		vu: 'VELOCIDAD_LUMIPLUS',
		tu: 'TIPO_DE_FUNCIONAMIENTO',
		d1: 'CAMBIO_EN_ENTRADA_1',
		d2: 'CAMBIO_EN_ENTRADA_2',
		d3: 'CAMBIO_EN_ENTRADA_3',
		d4: 'CAMBIO_EN_ENTRADA_4',
		ee: 'CONTROL_ELEC_EXT',
		ei: 'CONTROL_ELEC_INT',
		eg: 'CONTROL_ELEC_GDIA',
		fd: 'ESTADO_CAUDA',
		t1: 'SLOT1',
		t2: 'SLOT2',
		t3: 'SLOT3',
		t4: 'SLOT4',
		se: 'PARADA_ELEC_EXT',
		si: 'PARADA_ELEC_INT',
		sg: 'PARADA_ELEC_PROD',
		lu: 'LAMP_UV',
		an: 'ALARMA_PRESION',
		ae: 'BURBUJA_AIRE_CEL',
		aw: 'ALARMA_FLUJOSTATO',
		ad: 'ALARMA_CAU',
		bp: 'ALARMA_FU_PH',
		cp: 'ALARMA_BOMBA_PH',
		bo: 'ALARMA_FU_ORP',
		po: 'ALARMA_BOMBA_ORP',
		bh: 'ALARMA_FU_PPM',
		ch: 'ALARMA_BOMBA_PPM',
		bu: 'ALARMA_BALA_UV',
		fu: 'ALARMA_FU_UV',
		at: 'ALARMA_TEMP',
		as: 'ALARM_SAL',
		ru: 'RESET_IGNICIONES',
		ms: 'PRESENCIA_PANELES'
	};
	public totalLogs = -1;
	public limit = 50;
	public page = 1;
	public totalPages: number;
	public conditions: any = {
		conditions: [],
		parameters: {}
	};
	public _searchText = '';

	private _id: number;

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		value = value.trim();

		if (value && value !== '') {
			this.conditions = {
				conditions: [],
				parameters: {}
			};
			const auxSearch: string[] = [];
			this._searchText = value;

			// tslint:disable-next-line:forin
			for (let variable in this.variableNames) {
				let translation = this._translateSvc.instant(this.variableNames[variable]).toLowerCase();

				if (translation.indexOf(value.toLowerCase()) > -1) {
					auxSearch.push(variable);
				}
			}

			for (let i = 0; i < auxSearch.length; i++) {
				this.conditions.conditions.push('logsDevice.variable = :changed' + i);
				this.conditions.parameters['changed' + i] = auxSearch[i];
			}
			this.conditions.parameters.search = value;
			this.conditions.users = this.nameFilterValue;
			this.conditions.vars = this.varsToSearch;
			this.conditions.dates = this.datesRangeFormated;
		} else {
			this.conditions = {
				conditions: [],
				parameters: {},
				users: this.nameFilterValue,
				vars: this.varsToSearch,
				dates: this.datesRangeFormated
			};
		}

		if (!this.logsData) {
			this.getdata();
		}

		this.loadItems();
	}

	private _attemptsLoadData  = 0;
	private _attemptsLoadItems = 0;

	constructor(
		public notificationsSvc: NotificationsService,
		private _translateSvc: TranslateService,
		private _logsSvc: LogsService,
		private _route: ActivatedRoute,
		private _devicesSvc: DevicesService,
		private _authSvc: AuthService) {
		this.loggedUser = this._authSvc.user;
	}

	public ngOnInit() {
		this._setFiltersSettings();
	}

	public loadData() {
		this._route.params.subscribe((params) => {
			this._id = params['id'];
			this.loadItems();
			this.getdata();
		});
	}
	public isNumber(value) {
			return !isNaN(value);
	}
	public loadItems(page: number = 1) {
		this.disableButtons = true;
		this.page = page;
		if (this.device) {

			this.fillRelaysNames(this.device.relays);
			this._logsSvc.find(this._id, this.limit, page, this.filter, this.conditions, this.order).then((devLogs: any) => {
				const allLogs = [];

				for (const log of devLogs.items) {
					this.format(log);
					allLogs.push(log);
				}

				this.logs = allLogs;
				this.totalLogs = devLogs.total;
				this.totalPages = Math.ceil(this.totalLogs / this.limit);
				this.disableButtons = false;
				this.loaded = true;
			}).catch((error) => {
				this.totalLogs = 0;
				this.totalPages = 0;
				this.disableButtons = false;
				this.loaded = true;
				console.log(error);
			});
		} else {
			this._devicesSvc.get(this._id).then((result) => {
				this.device = result;
				this.fillRelaysNames(this.device.relays);
				this._logsSvc.find(this._id, this.limit, page, this.filter, this.conditions, this.order).then((devLogs: any) => {
					const allLogs = [];

					for (const log of devLogs.items) {
						this.format(log);
						allLogs.push(log);
					}

					this.logs = allLogs;
					this.totalLogs = devLogs.total;
					this.totalPages = Math.ceil(this.totalLogs / this.limit);
					this.disableButtons = false;
					this.loaded = true;
				}).catch((error) => {
					this.totalLogs = 0;
					this.totalPages = 0;
					this.disableButtons = false;
					this.loaded = true;
					console.log(error);
				});
			}).catch((error) => {
				this._attemptsLoadItems++;
				if(error.status != 401){
					if(this._attemptsLoadItems < this.MAX_ATTEMPTS){
						setTimeout(() => {
							this.loadItems();
						}, 1000);
					}
				}
			});
		}
	}
	public getParsedNumber(n, log) {
			const varsConFormatoEspecial = {'ta': 1, 'sh': 2, 'mg': 2, 'sp': 2, 'mp': 2, 'cn': 2, 'vd': 2, 'ic': 2 }
			let decimales = varsConFormatoEspecial[log.variable]
			if (decimales) {
					return Number(n).toFixed(decimales)
			}
			if (isFloat(n)) {
					return Number(n).toFixed(2)
			}
			return n;
	}

	private fillRelaysNames(relays) {
		relays.forEach((relay, index ) => {
			if (relay) {
				this.interlockName[`e${index}`] = relay.nombre
				this.relaysNames[relay.sign] = relay.nombre;
			}
		});
	}
	public getRelayName(variable) {
			const stateRelayName = {'ac': 0, 'aj': 1, 'ab': 2, 'af': 3, 'ax': 4, 'al': 5 };
			if (variable in stateRelayName) {
					return this.device.relays[stateRelayName[variable]].nombre
			}
			if (typeof this.interlockName[variable] === 'string') {
					return this.interlockName[variable];
			}
			return this.relaysNames[variable];
	}
	public isDigitalInput(variableChanged) {
		if (['d1', 'd2', 'd3', 'd4'].includes(variableChanged)) {
			return true;
		} else {
			return false;
		}
	}

	public deleteLogs() {
		if (!this.loggedUser.isDemo) {
			this._logsSvc.delete(this._id).then((result: any) => {
				window.location.reload();
			}).catch((error: any) => {
				console.log(error);
			});
		} else {
			this.notificationsSvc.info('', this._translateSvc.instant('DEMO'));
		}
	}
	public getUserFormLog(log) {
			if (log.id_user.id_role === ROLES.MAQUINA) {
					if (this.device.series === 2) {
							return 'DS2_USER'
					} else {
							return 'MAQUINA'
					}
			}
			if (log.id_user.id_role === ROLES.SAT) {
					return 'userSAT';
			}
			return log.id_user.nombre;
	}

	public format(log: any) {
		let values = {};

		log.changed = this.variableNames[log.variable];
		log.alarm = 'dispositivo';
		log.type = 'info';

		switch (log.variable) {
			case 'mb':
			case 'mx':
			case 'mf':
			case 'mj':
			case 'mc':
			case 'ml':
			case 'cv':
				values = { '0': 'MODO_OFF', '1': 'MODO_ON', 'A': 'MODO_AUTO' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'modoRele';
				break;
			case 'dp':
			case 'do':
			case 'dc':
				values = { '0': 'OK', '1': 'ALARM' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'deposito';
				log.type = 'danger';
				break;
			case 'sf':
				values = { '0': 'HAY_FLU', '1': 'SIN_FLU' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'flujo';
				log.type = 'danger';
				break;
			case 'ws':
				values = { 0: 'OK', L: 'BAJA_COND', H: 'ALTA_COND', N: 'BAJA_COND', J: 'ALTA_COND' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'ph';
				log.type = 'danger';
				break;
			case 'wp':
				values = { 0: 'OK', L: 'PH_LOW', H: 'PH_HIGH', I: 'INIT' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'ph';
				log.type = 'danger';
				break;
			case 'wo':
				values = { 0: 'OK', L: 'ORP_LOW', H: 'ORP_HIGH', I: 'INIT' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'orp';
				log.type = 'danger';
				break;
			case 'wh':
				values = { 0: 'OK', L: 'PPM_LOW', H: 'PPM_HIGH', I: 'INIT' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'ppm';
				log.type = 'danger';
				break;
			case 'gp':
			case 'go':
			case 'gh':
				values = { 0: 'APAGADA', 1: 'ENCENDIDA' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'estadoDosificacion';
				log.type = 'success';
				break;
			case 'ap':
				values = { 0: 'ACIDO', 1: 'BASE' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'hp':
			case 'hh':
			case 'ho':
				values = { 0: 'DOS_SEC', 1: 'DOS_MIN' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'tm':
				const mins = ' ' + this._translateSvc.instant('MINUTOS');
				log.valor_anterior = +log.valor_anterior + mins;
				log.valor_nuevo = +log.valor_nuevo + mins;
				break;
			case 'gs':
				if (log.valor_anterior !== log.valor_nuevo) {
					const elecAnt = log.valor_anterior.charAt(5);
					const elecNue = log.valor_nuevo.charAt(5);
					let valuesNue = null;
					let valuesAnt = null;

					if (elecAnt !== elecNue) {
						valuesAnt = parseInt(elecAnt, 2) ? this._translateSvc.instant('ELEC_ON') : this._translateSvc.instant('ELEC_OFF');
						valuesNue = parseInt(elecNue, 2) ? this._translateSvc.instant('ELEC_ON') : this._translateSvc.instant('ELEC_OFF');
					}
					const uvAnt = log.valor_anterior.charAt(4);
					const uvNue = log.valor_nuevo.charAt(4);
					if (uvAnt !== uvNue) {
						switch (uvAnt) {
							case '0':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('UV_OFF') : valuesAnt = this._translateSvc.instant('UV_OFF');
								break;
							case '1':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('UV_ON') : valuesAnt = this._translateSvc.instant('UV_ON');
								break;
							default: break;
						}
						switch (uvNue) {
							case '0':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('UV_OFF') : valuesNue = this._translateSvc.instant('UV_OFF');
								break;
							case '1':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('UV_ON') : valuesNue = this._translateSvc.instant('UV_ON');
								break;
							default: break;
						}
					}
					const panelAnt = log.valor_anterior.charAt(3);
					const panelNue = log.valor_nuevo.charAt(3);
					if (panelAnt !== panelNue) {
						switch (panelAnt) {
							case '0':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('PANEL_OFF') : valuesAnt = this._translateSvc.instant('PANEL_OFF');
								break;
							case '1':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('PANEL_ON') : valuesAnt = this._translateSvc.instant('PANEL_ON');
								break;
							default: break;
						}
						switch (panelNue) {
							case '0':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('PANEL_OFF') : valuesNue = this._translateSvc.instant('PANEL_OFF');
								break;
							case '1':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('PANEL_ON') : valuesNue = this._translateSvc.instant('PANEL_ON');
								break;
							default: break;
						}
					}
					const ppmAnt = log.valor_anterior.charAt(2);
					const ppmNue = log.valor_nuevo.charAt(2);
					if (ppmAnt !== ppmNue) {
						switch (ppmAnt) {
							case '0':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('PPM_OFF') : valuesAnt = this._translateSvc.instant('PPM_OFF');
								break;
							case '1':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('PPM_ON') : valuesAnt = this._translateSvc.instant('PPM_ON');
								break;
							default: ; break;
						}
						switch (ppmNue) {
							case '0':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('PPM_OFF') : valuesNue = this._translateSvc.instant('PPM_OFF');
								break;
							case '1':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('PPM_ON') : valuesNue = this._translateSvc.instant('PPM_ON');
								break;
							default: ; break;
						}
					}
					const orpAnt = log.valor_anterior.charAt(1);
					const orpNue = log.valor_nuevo.charAt(1);
					if (orpAnt !== orpNue) {
						switch (orpAnt) {
							case '0':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('ORP_OFF') : valuesAnt = this._translateSvc.instant('ORP_OFF');
								break;
							case '1':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('ORP_ON') : valuesAnt = this._translateSvc.instant('ORP_ON');
								break;
							default: ; break;
						}
						switch (orpNue) {
							case '0':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('ORP_OFF') : valuesNue = this._translateSvc.instant('ORP_OFF');
								break;
							case '1':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('ORP_ON') : valuesNue = this._translateSvc.instant('ORP_ON');
								break;
							default: ; break;
						}
					}
					const phAnt = log.valor_anterior.charAt(0);
					const phNue = log.valor_nuevo.charAt(0);
					if (phAnt !== phNue) {
						switch (phAnt) {
							case '0':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('PH_OFF') : valuesAnt = this._translateSvc.instant('PH_OFF');
								break;
							case '1':
								valuesAnt ? valuesAnt += ', ' + this._translateSvc.instant('PH_ON') : valuesAnt = this._translateSvc.instant('PH_ON');
								break;
							default: ; break;
						}
						switch (phNue) {
							case '0':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('PH_OFF') : valuesNue = this._translateSvc.instant('PH_OFF');
								break;
							case '1':
								valuesNue ? valuesNue += ', ' + this._translateSvc.instant('PH_ON') : valuesNue = this._translateSvc.instant('PH_ON');
								break;
							default: ; break;
						}
					}
					valuesAnt ? log.valor_anterior = valuesAnt : log.valor_anterior = '';
					valuesNue ? log.valor_nuevo = valuesNue : log.valor_nuevo = '';
				}
				break;
			case 'mm':
				if (log.valor_nuevo.indexOf('DS2') > -1) {
					log.valor_anterior = this._getMMmodel(log.valor_anterior);
					log.valor_nuevo = this._getMMmodel(log.valor_nuevo);
				} else {
					values = { 0: 'CLORADOR_OFF', 1: 'CLORADOR_ON', 2: 'CLORADOR_OFFLINE' };
					log.valor_anterior = values[log.valor_anterior.charAt(7)];
					log.valor_nuevo = values[log.valor_nuevo.charAt(7)];
					log.alarm = 'conexion';
					log.type = 'success';
				}
				break;
			case 'ah':
			case 'ao':
				values = { 0: 'REDUCTOR_LARGO', 1: 'OXIDANTE_LARGO' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'oh':
			case 'op':
			case 'oo':
				values = { 0: 'ALARMA_DESHABILITADA', 1: 'ALARMA_HABILITADA' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'cc':
				values = { 0: 'SIN_PASIVACION', 1: 'CON_PASIVACION' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'checkCell';
				log.type = 'danger';
				break;
			case 'pe':
			case 'oe':
			case 'he':
				values = { 0: 'OK', 1: 'ALARM' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'pumpStop';
				log.type = 'danger';
				break;
			case 'fs':
				values = { 0: 'FLUJO_SWITCH_ACTIVO_0', 1: 'FLUJO_SWITCH_ACTIVO_1' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'fe':
				values = { 0: 'FLUJO_ELEC_ACTIVO_0', 1: 'FLUJO_ELEC_ACTIVO_1' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'co':
				values = { 0: 'COBERT_ENABLE_0', 1: 'COBERT_ENABLE_1', '2': 'COBERT_ENABLE_2' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'tc':
				values = { 0: 'POOL_DESCONECT', 1: 'POOL_CONECT' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'poolstationTC';
				log.type = 'success';
				break;
			case 'ac':
			case 'aj':
			case 'af':
			case 'ab':
			case 'al':
			case 'ax':
				log.alarm = 'estadoRele';
				log.type = 'success';
			case 'ih':
			case 'ip':
			case 'io':
				values = { 0: 'OFF', 1: 'ON' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'fp':
			case 'fo':
			case 'fh':
				values = { 0: 'OFF', 1: 'ON', A: 'AUTO', D: 'DOSIS' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'ce':
				values = { 'T': 'DOS_MIN_TEST', '2': 'DOS_H', '3': 'TRES_H', '4': 'CUATRO_H' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'os':
				values = { 0: 'MARCHA', 1: 'PARO_ORP' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'estadoRele';
				log.type = 'success';
				break;
			case 'or':
				values = { 0: 'DISABLED', 1: 'ENABLED' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			case 'sc':
				log.alarm = 'produccionClorador';
				log.type = 'success';
				break;
			case 'p1':
			case 'p2':
			case 'p3':
			case 'p4':
			case 'p5':
			case 'p6':
			case 'p7':
			case 'p8':
			case 'p9':
				log.alarm = 'modoRele';
				break;
			case 'pc':
			case 'pj':
			case 'pf':
			case 'pb':
			case 'pl':
			case 'px':
				let anteriorHorario = '<ul>';
				let nuevoHorario = '<ul>';

				if (log.valor_anterior.charAt(0) !== log.valor_nuevo.charAt(0)) {
					if (log.valor_anterior.charAt(0) === '0') {
						log.valor_anterior = 'X' + log.valor_anterior.substring(1);
					}
					if (log.valor_nuevo.charAt(0) === '0') {
						log.valor_nuevo = 'X' + log.valor_nuevo.substring(1);
					}
					anteriorHorario += '<li>' + this._translateSvc.instant('LUNES') + ' P' + log.valor_anterior.charAt(0) + '</li>';
					nuevoHorario += '<li>' + this._translateSvc.instant('LUNES') + ' P' + log.valor_nuevo.charAt(0) + '</li>';
				}
				if (log.valor_anterior.charAt(1) !== log.valor_nuevo.charAt(1)) {
					if (log.valor_anterior.charAt(1) === '0') {
						log.valor_anterior = log.valor_anterior.substring(0, 1) + 'X' + log.valor_anterior.substring(2);
					}
					if (log.valor_nuevo.charAt(1) === '0') {
						log.valor_nuevo = log.valor_nuevo.substring(0, 1) + 'X' + log.valor_nuevo.substring(2);
					}
					anteriorHorario += '<li>' + this._translateSvc.instant('MARTES') + ' P' + log.valor_anterior.charAt(1) + '</li>';
					nuevoHorario += '<li>' + this._translateSvc.instant('MARTES') + ' P' + log.valor_nuevo.charAt(1) + '</li>';
				}
				if (log.valor_anterior.charAt(2) !== log.valor_nuevo.charAt(2)) {
					if (log.valor_anterior.charAt(2) === '0') {
						log.valor_anterior = log.valor_anterior.substring(0, 2) + 'X' + log.valor_anterior.substring(3);
					}
					if (log.valor_nuevo.charAt(2) === '0') {
						log.valor_nuevo = log.valor_nuevo.substring(0, 2) + 'X' + log.valor_nuevo.substring(3);
					}
					anteriorHorario += '<li>' + this._translateSvc.instant('MIERCOLES') + ' P' + log.valor_anterior.charAt(1) + '</li>';
					nuevoHorario += '<li>' + this._translateSvc.instant('MIERCOLES') + ' P' + log.valor_nuevo.charAt(1) + '</li>';
				}
				if (log.valor_anterior.charAt(3) !== log.valor_nuevo.charAt(3)) {
					if (log.valor_anterior.charAt(3) === '0') {
						log.valor_anterior = log.valor_anterior.substring(0, 3) + 'X' + log.valor_anterior.substring(4);
					}
					if (log.valor_nuevo.charAt(3) === '0') {
						log.valor_nuevo = log.valor_nuevo.substring(0, 3) + 'X' + log.valor_nuevo.substring(4);
					}
					anteriorHorario += '<li>' + this._translateSvc.instant('JUEVES') + ' P' + log.valor_anterior.charAt(1) + '</li>';
					nuevoHorario += '<li>' + this._translateSvc.instant('JUEVES') + ' P' + log.valor_nuevo.charAt(1) + '</li>';
				}
				if (log.valor_anterior.charAt(4) !== log.valor_nuevo.charAt(4)) {
					if (log.valor_anterior.charAt(4) === '0') {
						log.valor_anterior = log.valor_anterior.substring(0, 4) + 'X' + log.valor_anterior.substring(5);
					}
					if (log.valor_nuevo.charAt(4) === '0') {
						log.valor_nuevo = log.valor_nuevo.substring(0, 4) + 'X' + log.valor_nuevo.substring(5);
					}
					anteriorHorario += '<li>' + this._translateSvc.instant('VIERNES') + ' P' + log.valor_anterior.charAt(1) + '</li>';
					nuevoHorario += '<li>' + this._translateSvc.instant('VIERNES') + ' P' + log.valor_nuevo.charAt(1) + '</li>';
				}
				if (log.valor_anterior.charAt(5) !== log.valor_nuevo.charAt(5)) {
					if (log.valor_anterior.charAt(5) === '0') {
						log.valor_anterior = log.valor_anterior.substring(0, 5) + 'X' + log.valor_anterior.substring(6);
					}
					if (log.valor_nuevo.charAt(5) === '0') {
						log.valor_nuevo = log.valor_nuevo.substring(0, 5) + 'X' + log.valor_nuevo.substring(6);
					}
					anteriorHorario += '<li>' + this._translateSvc.instant('SABADO') + ' P' + log.valor_anterior.charAt(1) + '</li>';
					nuevoHorario += '<li>' + this._translateSvc.instant('SABADO') + ' P' + log.valor_nuevo.charAt(1) + '</li>';
				}
				if (log.valor_anterior.charAt(6) !== log.valor_nuevo.charAt(6)) {
					if (log.valor_anterior.charAt(6) === '0') {
						log.valor_anterior = log.valor_anterior.substring(0, 6) + 'X';
					}
					if (log.valor_nuevo.charAt(6) === '0') {
						log.valor_nuevo = log.valor_nuevo.substring(0, 6) + 'X';
					}
					anteriorHorario += '<li>' + this._translateSvc.instant('DOMINGO') + ' P' + log.valor_anterior.charAt(1) + '</li>';
					nuevoHorario += '<li>' + this._translateSvc.instant('DOMINGO') + ' P' + log.valor_nuevo.charAt(1) + '</li>';
				}
				log.valor_anterior = anteriorHorario + '</ul>';
				log.valor_nuevo = nuevoHorario + '</ul>';
				log.alarm = 'modoRele';
				break;
			case 'd1':
			case 'd2':
			case 'd3':
			case 'd4':
				values = { '0': 'CONTACTO_ABIERTO', '1': 'CONTACTO_CERRADO' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				log.alarm = 'entradas';
				log.type = 'success';
				break;
			case 'e1':
			case 'e2':
			case 'e3':
			case 'e4':
			case 'e5':
			case 'e6':
				log.valor_anterior = '';
				log.valor_nuevo = 'CAMBIO_EN_ENCLAVAMIENTO';
				log.alarm = 'modoRele';
				break;
			case 'sn':
			case 'sv':
					log.valor_anterior =  (log.valor_anterior !== '---' && log.valor_anterior !== '') ? parseInt(log.valor_anterior, 10).toString() + '%' : '-';
					log.valor_nuevo = (log.valor_nuevo !== '---' && log.valor_nuevo !== '') ?  parseInt(log.valor_nuevo, 10).toString() + '%' : '-';
				break;
			// -------- VARIABLES NUEVAS DOMS2 ---------
			case 'ee':
			case 'ei':
			case 'fd':
				values = { '0': 'DESHABILITADO', '1': 'HABILITADO' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				break;
			case 'eg':
				let messageNewValue = '';
				let messageOldValue = '';

				if (log.valor_nuevo !== '0000' || log.valor_nuevo !== '----') {
					messageNewValue = this._translateSvc.instant('LIMITE_PRO_ELEC1') + +log.valor_nuevo;
					messageNewValue += this._translateSvc.instant('LIMITE_PRO_ELEC2');
				} else {
					messageNewValue = 'DESHABILITADO';
				}

				if (log.valor_anterior !== '0000' || log.valor_anterior !== '----') {
					messageOldValue = this._translateSvc.instant('LIMITE_PRO_ELEC1') + +log.valor_anterior;
					messageOldValue += this._translateSvc.instant('LIMITE_PRO_ELEC2');
				} else {
					messageOldValue = 'DESHABILITADO';
				}
				log.valor_anterior = messageOldValue;
				log.valor_nuevo = messageNewValue;
				break;
			case 'ru':
				log.valor_anterior = '';
				log.valor_nuevo = this._translateSvc.instant('N_RESETS') + +log.valor_nuevo;
				break;
			case 't1':
			case 't2':
			case 't3':
			case 't4':
				const messageStart = this._translateSvc.instant('CONECTADO_SLOT') + log.variable[1];
				switch (log.valor_nuevo) {
					case 'P':
						log.valor_nuevo = messageStart + this._translateSvc.instant('CONECT_SLOT_PH');
						break;
					case 'O':
						log.valor_nuevo = messageStart + this._translateSvc.instant('CONECT_SLOT_ORP');
						break;
					case 'C':
						log.valor_nuevo = messageStart + this._translateSvc.instant('CONECT_SLOT_PPM');
						break;
					default:
						log.valor_nuevo = 'DISPOSITIVO_DESCONECTADO';
						break;
				}
				switch (log.valor_anterior) {
					case 'P':
						log.valor_anterior = messageStart + this._translateSvc.instant('CONECT_SLOT_PH');
						break;
					case 'O':
						log.valor_anterior = messageStart + this._translateSvc.instant('CONECT_SLOT_ORP');
						break;
					case 'C':
						log.valor_anterior = messageStart + this._translateSvc.instant('CONECT_SLOT_PPM');
						break;
					default:
						log.valor_anterior = 'DISPOSITIVO_DESCONECTADO';
						break;
				}
				break;
			case 'se':
				values = { '0': 'ELECT_NO_PARADA_EXT', '1': 'ELECT_PARADA_EXT' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				break;
			case 'si':
				values = { '0': 'ELECT_NO_PARADA_INT', '1': 'ELECT_PARADA_INT' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				break;
			case 'sg':
				values = { '0': 'ELECT_NO_PARADA_PROD', '1': 'ELECT_PARADA_PROD' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				break;
			case 'lu':
				values = { '0': 'APAGADA', '1': 'ENCENDIDA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				break;
			case 'an':
				values = { '0': 'PRESION_OK', 'H': 'PRESION_ALTA', 'L': 'PRESION_BAJA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'ae':
				values = { '0': 'BURBUJA_OK', '1': 'BURBUJA_ALARMA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'aw':
			case 'ad':
				values = { '0': 'SIN_ALARMA', '1': 'ALARMA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'bo':
			case 'bh':
			case 'bp':
				values = { '0': 'OK', '1': 'FUSIBLE_FUN' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'po':
			case 'ch':
			case 'cp':
				values = { '0': 'OK', '1': 'ALARMA_HORAS_MARCHA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'bu':
				values = { '0': 'OK', '1': 'FUNDIDO' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'fu':
				values = { '0': 'OK', '1': 'ALARMA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'at':
				values = { '0': 'OK', 'H': 'TEMP_ALTA', 'L': 'TEMP_BAJA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'as':
				values = { '0': 'OK', 'H': 'SAL_ALTA', 'L': 'SAL_BAJA' };
				log.valor_anterior = values[log.valor_anterior || '0'];
				log.valor_nuevo = values[log.valor_nuevo || '0'];
				log.type = 'danger';
				log.alarm = 'deposito'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'ms':
				let messagePreviusVal = '<ul>';
				let messageNewVal = '<ul>';
				values = { '0': 'RPH200_OFF', '1': 'RPH200_ON', '2': 'RPH200_OFFLINE' };

				if (log.valor_anterior.charAt(0) !== log.valor_nuevo.charAt(0)) {
					switch (log.valor_anterior.charAt(0)) {
						case '0':
							messagePreviusVal = '<li>' + this._translateSvc.instant('RPH200_OFF') + '</li>';
							break;
						case '1':
							messagePreviusVal = '<li>' + this._translateSvc.instant('RPH200_ON') + '</li>';
							break;
						case '2':
							messagePreviusVal = '<li>' + this._translateSvc.instant('RPH200_OFFLINE') + '</li>';
							break;
					}

					switch (log.valor_nuevo.charAt(0)) {
						case '0':
							messageNewVal = '<li>' + this._translateSvc.instant('RPH200_OFF') + '</li>';
							break;
						case '1':
							messageNewVal = '<li>' + this._translateSvc.instant('RPH200_ON') + '</li>';
							break;
						case '2':
							messageNewVal = '<li>' + this._translateSvc.instant('RPH200_OFFLINE') + '</li>';
							break;
					}
				}

				if (log.valor_anterior.charAt(1) !== log.valor_nuevo.charAt(1)) {
					switch (log.valor_anterior.charAt(1)) {
						case '0':
							messagePreviusVal += '<li>' + this._translateSvc.instant('RMV200_OFF') + '</li>';
							break;
						case '1':
							messagePreviusVal += '<li>' + this._translateSvc.instant('RMV200_ON') + '</li>';
							break;
						case '2':
							messagePreviusVal += '<li>' + this._translateSvc.instant('RMV200_OFFLINE') + '</li>';
							break;
					}

					switch (log.valor_nuevo.charAt(1)) {
						case '0':
							messageNewVal += '<li>' + this._translateSvc.instant('RMV200_OFF') + '</li>';
							break;
						case '1':
							messageNewVal += '<li>' + this._translateSvc.instant('RMV200_ON') + '</li>';
							break;
						case '2':
							messageNewVal += '<li>' + this._translateSvc.instant('RMV200_OFFLINE') + '</li>';
							break;
					}
				}

				if (log.valor_anterior.charAt(2) !== log.valor_nuevo.charAt(2)) {
					switch (log.valor_anterior.charAt(2)) {
						case '0':
							messagePreviusVal += '<li>' + this._translateSvc.instant('RCL200_OFF') + '</li>';
							break;
						case '1':
							messagePreviusVal += '<li>' + this._translateSvc.instant('RCL200_ON') + '</li>';
							break;
						case '2':
							messagePreviusVal += '<li>' + this._translateSvc.instant('RCL200_OFFLINE') + '</li>';
							break;
					}

					switch (log.valor_nuevo.charAt(2)) {
						case '0':
							messageNewVal += '<li>' + this._translateSvc.instant('RCL200_OFF') + '</li>';
							break;
						case '1':
							messageNewVal += '<li>' + this._translateSvc.instant('RCL200_ON') + '</li>';
							break;
						case '2':
							messageNewVal += '<li>' + this._translateSvc.instant('RCL200_OFFLINE') + '</li>';
							break;
					}
				}

				if (log.valor_anterior.charAt(3) !== log.valor_nuevo.charAt(3)) {
					switch (log.valor_anterior.charAt(3)) {
						case '0':
							messagePreviusVal += '<li>' + this._translateSvc.instant('PR3XX_OFF') + '</li>';
							break;
						case '1':
							messagePreviusVal += '<li>' + this._translateSvc.instant('PR300_ON') + '</li>';
							break;
						case '2':
							messagePreviusVal += '<li>' + this._translateSvc.instant('PR300_OFFLINE') + '</li>';
							break;
						case '3':
							messagePreviusVal += '<li>' + this._translateSvc.instant('PR310_ON') + '</li>';
							break;
						case '4':
							messagePreviusVal += '<li>' + this._translateSvc.instant('PR310_OFFLINE') + '</li>';
							break;
						case '5':
							messagePreviusVal += '<li>' + this._translateSvc.instant('PR320_ON') + '</li>';
							break;
						case '6':
							messagePreviusVal += '<li>' + this._translateSvc.instant('PR320_OFFLINE') + '</li>';
							break;
					}

					switch (log.valor_nuevo.charAt(3)) {
						case '0':
							messageNewVal += '<li>' + this._translateSvc.instant('PR3XX_OFF') + '</li>';
							break;
						case '1':
							messageNewVal += '<li>' + this._translateSvc.instant('PR300_ON') + '</li>';
							break;
						case '2':
							messageNewVal += '<li>' + this._translateSvc.instant('PR300_OFFLINE') + '</li>';
							break;
						case '3':
							messageNewVal += '<li>' + this._translateSvc.instant('PR310_ON') + '</li>';
							break;
						case '4':
							messageNewVal += '<li>' + this._translateSvc.instant('PR310_OFFLINE') + '</li>';
							break;
						case '5':
							messageNewVal += '<li>' + this._translateSvc.instant('PR320_ON') + '</li>';
							break;
						case '6':
							messageNewVal += '<li>' + this._translateSvc.instant('PR320_OFFLINE') + '</li>';
							break;
					}
				}
				log.valor_anterior = messagePreviusVal + '</ul>';
				log.valor_nuevo = messageNewVal + '</ul>';
				log.type = 'success';
				log.alarm = 'entradas'; // CAMBIAR A LA CATEGORIA CORRECTA
				break;
			case 'tu':
				values = { 'C': 'COLOR', 'S': 'SECUENCIA' };
				log.valor_anterior = values[log.valor_anterior];
				log.valor_nuevo = values[log.valor_nuevo];
				break;
			default:
				break;
		}

		if (this.device) {
			let logTime: any;

			logTime = moment(log.fecha, 'YYYY-MM-DD HH:mm:ss');
			log.fecha = moment().tz('UTC');

			log.fecha.year(logTime.year());
			log.fecha.month(logTime.month());
			log.fecha.date(logTime.date());
			log.fecha.hour(logTime.hour());
			log.fecha.minute(logTime.minute());
			log.fecha.second(logTime.second());

			log.fecha.tz(this.device.timezone);
		}

		return log;
	}

	public changeOrder(order: any) {
		this.page = 1;
		this.order.field = order.column;
		this.order.direction = order.direction;
		this.loadItems();
	}

	public getdata() {
		this._logsSvc.getDataFromLogs(this._id).then((data: any) => {
			let dateStart: Date;
			let dateEnd: Date;

			this.nameFilterOptions = [];
			this.nameFilterValue = [];
			this.usersFromLogs = data.users;

			for (const user of data.users) {
				this.nameFilterOptions.push({
					id: +user.id,
					name: (user.name === 'MAQUINA') ? this._translateSvc.instant(user.name) : user.name
				});
				this.nameFilterValue.push(+user.id);
			}

			this.minDate = moment(data.dates.dateStart).toDate();
			this.maxDate = moment().toDate();
			dateStart = moment(data.dates.dateStart).local().toDate();
			dateEnd = moment().local().toDate();
			this.datesRange = [dateStart, dateEnd];

			this.datesRangeFormated = [
				moment(data.dates.dateStart).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
				moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
			];

			this.logsData = data;
		}).catch((error) => {
			this._attemptsLoadData++;
			if(error.status != 401){
				if(this._attemptsLoadData < this.MAX_ATTEMPTS){
					setTimeout(() => {
						this.getdata();
					}, 1000);
				}
			}
		});
	}

	public countLogs() {
		this._logsSvc.getDataFromLogs(this._id).then((data: any) => {
			this.usersFromLogs = data.users;
		}).catch((error) => {
			console.log(error);
		});
	}

	public filterChanged(option: number) {
		if (this.filtersDelay) {
			clearTimeout(this.filtersDelay);
			this.filtersDelay = null;
		}

		if (option === 2) {
			this.varsToSearch = [];
			let alarmsCount = 0;
			let stateCount = 0;
			let configCount = 0;
			const hasAlarms: boolean = this.eventsFilterValue.indexOf(-1) > -1;
			const hasState: boolean = this.eventsFilterValue.indexOf(-2) > -1;
			const hasConfig: boolean = this.eventsFilterValue.indexOf(-3) > -1;

			for (const index in this.eventsFilterValue) {
				if (this.eventsFilterValue[index] > 2 && this.eventsFilterValue[index] < 12) {
					alarmsCount++;
				} else if ((this.eventsFilterValue[index] > 11 && this.eventsFilterValue[index] < 19) || this.eventsFilterValue[index] === 20) {
					stateCount++;
				} else if ((this.eventsFilterValue[index] > 26 && this.eventsFilterValue[index] < 33) || this.eventsFilterValue[index] === 25) {
					configCount++;
				}
			}

			// ------------------------------------------------
			// Elimino el padre si no estan todos seleccionados
			if (alarmsCount !== 9 && alarmsCount !== 0 && hasAlarms) {
				const index = this.eventsFilterValue.indexOf(-1);

				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
			}

			// Añado el padre si estan todos seleccionados
			if (alarmsCount === 9 && !hasAlarms) {
				this.eventsFilterValue.push(-1);
			}

			// Selecciono todos si pulso sobre el padre
			if (alarmsCount < 9 && hasAlarms && !this.hasAlarmsBefore) {
				if (this.eventsFilterValue.indexOf(3) < 0) {
					this.eventsFilterValue.push(3);
				}
				if (this.eventsFilterValue.indexOf(4) < 0) {
					this.eventsFilterValue.push(4);
				}
				if (this.eventsFilterValue.indexOf(5) < 0) {
					this.eventsFilterValue.push(5);
				}
				if (this.eventsFilterValue.indexOf(6) < 0) {
					this.eventsFilterValue.push(6);
				}
				if (this.eventsFilterValue.indexOf(7) < 0) {
					this.eventsFilterValue.push(7);
				}
				if (this.eventsFilterValue.indexOf(8) < 0) {
					this.eventsFilterValue.push(8);
				}
				if (this.eventsFilterValue.indexOf(9) < 0) {
					this.eventsFilterValue.push(9);
				}
				if (this.eventsFilterValue.indexOf(10) < 0) {
					this.eventsFilterValue.push(10);
				}
				if (this.eventsFilterValue.indexOf(11) < 0) {
					this.eventsFilterValue.push(11);
				}
				if (this.eventsFilterValue.indexOf(-1) < 0) {
					this.eventsFilterValue.push(-1);
				}
			}

			// Elimino todos si pulso sobre el padre y esta deseleccionado
			if (alarmsCount !== 0 && !hasAlarms && this.hasAlarmsBefore) {
				let index: number = this.eventsFilterValue.indexOf(3);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(4);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(5);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(6);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(7);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(8);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(9);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(10);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(11);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(-1);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
			}

			// ------------------------------------------------
			// Elimino el padre si no estan todos seleccionados
			if (stateCount !== 8 && stateCount !== 0 && hasState) {
				const index = this.eventsFilterValue.indexOf(-2);

				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
			}

			// Añado el padre si estan todos seleccionados
			if (stateCount === 8 && !hasState) {
				this.eventsFilterValue.push(-2);
			}

			// Selecciono todos si pulso sobre el padre
			if (stateCount < 8 && hasState && !this.hasStateBefore) {
				if (this.eventsFilterValue.indexOf(12) < 0) {
					this.eventsFilterValue.push(12);
				}
				if (this.eventsFilterValue.indexOf(13) < 0) {
					this.eventsFilterValue.push(13);
				}
				if (this.eventsFilterValue.indexOf(14) < 0) {
					this.eventsFilterValue.push(14);
				}
				if (this.eventsFilterValue.indexOf(15) < 0) {
					this.eventsFilterValue.push(15);
				}
				if (this.eventsFilterValue.indexOf(16) < 0) {
					this.eventsFilterValue.push(16);
				}
				if (this.eventsFilterValue.indexOf(17) < 0) {
					this.eventsFilterValue.push(17);
				}
				if (this.eventsFilterValue.indexOf(18) < 0) {
					this.eventsFilterValue.push(18);
				}
				if (this.eventsFilterValue.indexOf(20) < 0) {
					this.eventsFilterValue.push(20);
				}
				if (this.eventsFilterValue.indexOf(-2) < 0) {
					this.eventsFilterValue.push(-2);
				}
			}

			// Elimino todos si pulso sobre el padre y esta deseleccionado
			if (stateCount !== 0 && !hasState && this.hasStateBefore) {
				let index: number = this.eventsFilterValue.indexOf(12);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(13);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(14);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(15);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(16);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(17);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(18);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(20);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(-2);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
			}

			// ------------------------------------------------
			// Elimino el padre si no estan todos seleccionados
			if (configCount !== 7 && configCount !== 0 && hasConfig) {
				const index = this.eventsFilterValue.indexOf(-3);

				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
			}

			// Añado el padre si estan todos seleccionados
			if (configCount === 7 && !hasConfig) {
				this.eventsFilterValue.push(-3);
			}

			// Selecciono todos si pulso sobre el padre
			if (configCount < 7 && hasConfig && !this.hasConfigBefore) {
				if (this.eventsFilterValue.indexOf(25) < 0) {
					this.eventsFilterValue.push(25);
				}
				if (this.eventsFilterValue.indexOf(27) < 0) {
					this.eventsFilterValue.push(27);
				}
				if (this.eventsFilterValue.indexOf(28) < 0) {
					this.eventsFilterValue.push(28);
				}
				if (this.eventsFilterValue.indexOf(29) < 0) {
					this.eventsFilterValue.push(29);
				}
				if (this.eventsFilterValue.indexOf(30) < 0) {
					this.eventsFilterValue.push(30);
				}
				if (this.eventsFilterValue.indexOf(31) < 0) {
					this.eventsFilterValue.push(31);
				}
				if (this.eventsFilterValue.indexOf(32) < 0) {
					this.eventsFilterValue.push(32);
				}
				if (this.eventsFilterValue.indexOf(33) < 0) {
					this.eventsFilterValue.push(33);
				}
				if (this.eventsFilterValue.indexOf(-3) < 0) {
					this.eventsFilterValue.push(-3);
				}
			}

			// Elimino todos si pulso sobre el padre y esta deseleccionado
			if (configCount !== 0 && !hasConfig && this.hasConfigBefore) {
				let index: number = this.eventsFilterValue.indexOf(25);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(27);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(28);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(29);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(30);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(31);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(32);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(33);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
				index = this.eventsFilterValue.indexOf(-3);
				if (index > -1) {
					this.eventsFilterValue.splice(index, 1);
				}
			}

			this.hasAlarmsBefore = this.eventsFilterValue.indexOf(-1) > -1;
			this.hasStateBefore = this.eventsFilterValue.indexOf(-2) > -1;
			this.hasConfigBefore = this.eventsFilterValue.indexOf(-3) > -1;

			this.eventsFilterValue.forEach((item) => {
				if (item > -1) {
					if (item > 2 && item < 12) {
						this.varsToSearch = this.varsToSearch.concat(this.eventsTypes.alarms[+item]);
					} else if ((item > 11 && item < 19) || item === 20) {
						this.varsToSearch = this.varsToSearch.concat(this.eventsTypes.state[+item]);
					} else if ((item > 26 && item < 34) || item === 25) {
						this.varsToSearch = this.varsToSearch.concat(this.eventsTypes.config[+item]);
					}
				}
			});
		}

		this.filtersDelay = setTimeout(() => {
			this.searchText = this._searchText;
		}, 750);
	}

	public filterByDate(event) {
		if (!event || !event[0] || !event[1]) {
			this.getdata();
		} else {
			this.datesRangeFormated = [
				moment(event[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
				moment(event[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
			];
			this.searchText = this._searchText;
		}
	}

	private _setFiltersSettings() {
		let nameText: any;
		let eventsText: any;
		const selected = this._translateSvc.instant('FILTER_SELECCIONADO');
		const selecteds = this._translateSvc.instant('FILTER_SELECCIONADOS');
		const search = this._translateSvc.instant('BUSCAR');
		const noResults = this._translateSvc.instant('SIN_RESULTADOS');
		const users = this._translateSvc.instant('USUARIOS');
		const allSelected = this._translateSvc.instant('TODOS');

		const system = this._translateSvc.instant('SYSTEM');
		const relays = this._translateSvc.instant('RELES');
		const inputs = this._translateSvc.instant('ENTRADAS');
		const electrolysis = this._translateSvc.instant('ELECTROLISIS');
		const ph = this._translateSvc.instant('PH');
		const orp = this._translateSvc.instant('ORP');
		const ppm = this._translateSvc.instant('PPM');
		const flow = this._translateSvc.instant('FLOW');
		const uv = this._translateSvc.instant('UV');
		const pressure = this._translateSvc.instant('PRESION');
		const temperature = this._translateSvc.instant('TEMPERATURA');
		const salt = this._translateSvc.instant('SAL');
		const alarms = this._translateSvc.instant('ALARMAS');
		const stateChanges = this._translateSvc.instant('CAMBIOS_ESTADO');
		const configChanges = this._translateSvc.instant('CAMBIOS_CONFIG');
		const systemOnOff = this._translateSvc.instant('ON_OFF_GS');
		const changes = this._translateSvc.instant('CAMBIOS');

		this.eventsFilterOptions = [
			{ id: -1, name: alarms },
			{ id: 3, name: electrolysis },
			{ id: 4, name: ph },
			{ id: 5, name: orp },
			{ id: 6, name: ppm },
			{ id: 7, name: flow },
			{ id: 8, name: uv },
			{ id: 9, name: pressure },
			{ id: 10, name: temperature },
			{ id: 11, name: salt },
			{ id: -2, name: stateChanges },
			{ id: 12, name: system },
			{ id: 13, name: relays },
			{ id: 14, name: inputs },
			{ id: 15, name: electrolysis },
			{ id: 16, name: ph },
			{ id: 17, name: orp },
			{ id: 18, name: ppm },
			{ id: 20, name: uv },
			{ id: -3, name: configChanges },
			{ id: 33, name: systemOnOff },
			{ id: 25, name: relays },
			{ id: 27, name: electrolysis },
			{ id: 28, name: ph },
			{ id: 29, name: orp },
			{ id: 30, name: ppm },
			{ id: 31, name: flow },
			{ id: 32, name: uv }
		];

		switch (this.loggedUser.idioma_notificaciones) {
			case 'Castellano':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'es' });
				break;
			case 'Aleman':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'de' });
				break;
			case 'Italiano':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'it' });
				break;
			case 'Frances':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'fr' });
				break;
			case 'Portugues':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'pt' });
				break;
			default:
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'en' });
				break;
		}

		this.nameFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};

		this.eventsFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 6,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};

		nameText = {
			checked: selected,
			checkedPlural: selecteds,
			searchPlaceholder: search,
			searchEmptyResult: noResults,
			defaultTitle: users,
			allSelected: allSelected,
		};

		eventsText = {
			checked: selected,
			checkedPlural: selecteds,
			searchPlaceholder: search,
			searchEmptyResult: noResults,
			defaultTitle: changes,
			allSelected: allSelected,
		};

		this.nameFilterTexts = Object.assign({}, nameText);
		this.eventsFilterTexts = Object.assign({}, eventsText);
		this.loadData();
	}

	private _getMMmodel(varMM: string) {
		let result = 'DS2: ';
		const modelCode = varMM.substr(6, 2);

		switch (modelCode) {
			case '00':
			case '01':
			case '02':
			case '03':
			case '04':
			case '05':
			case '06':
			case '07':
			case '08':
			case '09':
			case '0A':
			case '0B':
			case '0C':
			case '0D':
			case '0E':
			case '0F':
				result = result + this._translateSvc.instant('ELECTROLISIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_' + modelCode);
				break;
			case '20':
				result = result + this._translateSvc.instant('PH');
				break;
			case '21':
				result = result + 'Clmv';
				break;
			case '22':
				result = result + 'Clppm';
				break;
			case '23':
				result = result + this._translateSvc.instant('PH') + ' + ClmV';
				break;
			case '24':
				result = result + this._translateSvc.instant('PH') + ' + Clppm';
				break;
			case '25':
				result = result + 'ClmV + Clppm';
				break;
			case '26':
				result = result + this._translateSvc.instant('PH') + ' + ClmV + Clppm';
				break;
			case '27':
				result = result + this._translateSvc.instant('MM_01');
				break;
			case '28':
				result = result + this._translateSvc.instant('MM_02');
				break;
			case '29':
				result = result + this._translateSvc.instant('MM_03');
				break;
			case '2A':
				result = result + this._translateSvc.instant('MM_04');
				break;
			case '2B':
				result = result + this._translateSvc.instant('MM_05');
				break;
			case '2C':
				result = result + this._translateSvc.instant('MM_06');
				break;
			case '2D':
				result = result + this._translateSvc.instant('MM_07');
				break;
			case '40':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_00');
				break;
			case '41':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_01');
				break;
			case '42':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_02');
				break;
			case '43':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_03');
				break;
			case '44':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_04');
				break;
			case '45':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_05');
				break;
			case '46':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_06');
				break;
			case '47':
				result = result + this._translateSvc.instant('NEOLYSIS') + varMM.substr(3, 3) + this._translateSvc.instant('MM_07');
				break;
			case '60':
				result = result + this._translateSvc.instant('UV');
				break;
			case '61':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_00');
				break;
			case '62':
				result = result + this._translateSvc.instant('UV') + ' + ' + this._translateSvc.instant('PH');
				break;
			case '63':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_01');
				break;
			case '64':
				result = result + this._translateSvc.instant('UV') + ' + ClmV';
				break;
			case '65':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_02');
				break;
			case '66':
				result = result + this._translateSvc.instant('UV') + ' + Clppm';
				break;
			case '67':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_03');
				break;
			case '68':
				result = result + this._translateSvc.instant('UV') + ' + ' + this._translateSvc.instant('PH') + ' + ClmV';
				break;
			case '69':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_04');
				break;
			case '6A':
				result = result + this._translateSvc.instant('UV') + ' + ' + this._translateSvc.instant('PH') + ' + Clppm';
				break;
			case '6B':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_05');
				break;
			case '6C':
				result = result + this._translateSvc.instant('UV') + ' + ClmV + Clppm';
				break;
			case '6D':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_06');
				break;
			case '6E':
				result = result + this._translateSvc.instant('UV') + ' + ' + this._translateSvc.instant('PH') + ' + ClmV + Clppm';
				break;
			case '6F':
				result = result + this._translateSvc.instant('UV') + this._translateSvc.instant('MM_07');
				break;
			case '90':
				result = result + this._translateSvc.instant('TEMPERATURA');
				break;
			case 'A0':
			case 'A8':
				result = result + this._translateSvc.instant('ELECTROLISIS') + 'LS' + varMM.substr(3, 3) + this._translateSvc.instant('MM_' + modelCode);
				break;
			case 'C0':
			case 'C8':
				result = result + this._translateSvc.instant('NEOLYSIS') + 'LS' + varMM.substr(3, 3) + this._translateSvc.instant('MM_00');
				break;
		}

		return result;
	}
}
