import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'cookiesPage',
	templateUrl: './cookies.component.html',
	styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
	constructor() { }

	ngOnInit() {
	}

}
