import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevicesService } from '../../services/devices.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

// services
import { AuthService } from '../../services/auth.service';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'Relays',
	templateUrl: 'relays.component.html'
})

export class RelaysComponent implements OnInit {
	@ViewChild('saveRelay', {static: true}) public saveRelayModal: ModalDirective;
	public device: any;
	public loggedUser: any;
	public saving: boolean;
	public blockBtn: boolean = false;
	public selectedRelay: any = {};
	private id: string;

	constructor(
		public authSvc: AuthService,
		private route: ActivatedRoute,
		private _devicesSvc: DevicesService,
		private _translate: TranslateService,
		private _notificationSvc: NotificationsService) {
		this.loggedUser = this.authSvc.user;
	}

	public ngOnInit() {
		window.onbeforeunload = () => {
			if (this.device) {
				this._devicesSvc.changeMode(this.device.id, 'eco').then((error) => {
					console.log('Sin cambio de modo');
				});
			}
		};

		this.route.params.subscribe((params) => {
			this.id = params['id'];

			setTimeout(() => {
				this._devicesSvc.changeMode(this.id, 'fast').then((result: any) => {
					console.log(result.message);
				}).catch((error: any) => {
					console.log(error.message);
				});
			}, 750);

			this._devicesSvc.get(this.id).then((device: any) => {
				device.rate = device.rate || { id: null, id_device: device.id, precio: '0' };
				this.device = device;
				console.log(this.device);
			});
		});
	}

	public selectRelay(index: number) {
		this.selectedRelay = _.pick(this.device.relays[index], ['id', 'sign', 'nombre', 'consumo']);
		this.selectedRelay.index = index;
		this.saveRelayModal.show();
	}

	public saveThisRelay() {
		if (!this.loggedUser.isDemo) {
			let changes: any = _.pick(this.selectedRelay, ['id', 'nombre', 'consumo']);

			this.blockBtn = true;
			this.saveRelayModal.hide();

			if (changes.nombre === '' || !changes.consumo) {
				this._notificationSvc.error('', this._translate.instant('CONFIGURAR_RELE'));
				this.blockBtn = false;
			} else {
				this._devicesSvc.saveRelay(changes).then((result: any) => {
					this.device.relays[this.selectedRelay.index].nombre = changes.nombre;
					this.device.relays[this.selectedRelay.index].consumo = changes.consumo;
					this._notificationSvc.success('', this._translate.instant(result.message));
					this.blockBtn = false;
				}).catch((error: any) => {
					if (error.message) {
						this._notificationSvc.error('', this._translate.instant(error.message));
					}

					this.blockBtn = false;
				});
			}
		} else {
			this._notificationSvc.info('', this._translate.instant('DEMO'));
		}
	}

	public save() {
		if (!this.loggedUser.isDemo) {
			this.saving = true;
			this.blockBtn = true;

			if (!this.device.rate.precio) {
				this._notificationSvc.error('', this._translate.instant('INTRODUZCA_TARIFA_ELECTRICA_VALIDA'));
				this.saving = false;
				this.blockBtn = false;
			} else {
				let save = {
					id: this.device.id,
					precio: this.device.rate.precio
				};

				this._devicesSvc.saveRate(JSON.stringify(save)).then((result: any) => {
					this.saving = false;
					this.blockBtn = false;
					this._notificationSvc.success('', this._translate.instant('OK_MODIFICAR_DISPOSITIVO'));
				}).catch((error: any) => {
					this.saving = false;
					this.blockBtn = false;

					if (error.message) {
						this._notificationSvc.error('', this._translate.instant(error.message));
					}
				});
			}
		} else {
			this._notificationSvc.info('', this._translate.instant('DEMO'));
		}
	}

	public checkNumber(option: any) {
		switch (option) {
			case 'rate':
				if (!this.device.rate.precio || this.device.rate.precio === '') {
					this.device.rate.precio = 0;
				} else if (this.device.rate.precio < 0) {
					this.device.rate.precio = 0;
				} else if (this.device.rate.precio > 20) {
					this.device.rate.precio = 20;
				} else {
					this.device.rate.precio = this.device.rate.precio.toFixed(10);
				}
				break;
			case 'power':
				if (!this.selectedRelay.consumo || this.selectedRelay.consumo === '') {
					this.selectedRelay.consumo = 0;
				} else if (this.selectedRelay.consumo < 0) {
					this.selectedRelay.consumo = 0;
				} else if (this.selectedRelay.consumo > 15000) {
					this.selectedRelay.consumo = 15000;
				} else {
					this.selectedRelay.consumo = this.selectedRelay.consumo.toFixed(0);
				}
				break;
		}
	}
}
