import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { AppState } from './app.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { DevicesService } from './services/devices.service';
import { UsersService } from './services/users.service';

// services
import { AuthService } from './services/auth.service';
import {getFullYear} from 'ngx-bootstrap/chronos';

/*
 * App Component
 * Top Level Component
 */
@Component({
	selector: 'app',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [
		'./app.component.scss',
		'./animations.scss'
	],
	templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
	@ViewChild('cookieBar', {static: true}) cookieBar: ElementRef;

	public loggedUser = this._authSvc.user;
	public langTranslation: string;
	private _lastMoveInterval: any;
	private CurrentYear = getFullYear(new Date(), true);

	constructor(
		private _router: Router,
		private translate: TranslateService,
		public appState: AppState,
		private _authSvc: AuthService,
		private _usrSvc: UsersService,
		private _deviceSvc: DevicesService) {
		// idioma por defecto
		this.translate.setDefaultLang('en');
		//let browserLang = this.translate.getBrowserLang();

		// idioma que vamos a utilizar ahora
		//this.translate.use(browserLang.match(/en|es|fr|de|it|pt|nl/) ? browserLang : 'en');
	}

	public ngOnInit() {
		if (this.loggedUser) {
			/* this._deviceSvc.changeAllDevicesMode('fast').then((result: any) => {
				console.log(result.message);
			}).catch((error) => {
				console.log(error.message);
			}); */

      /* JLF Así generamos la variable que contiene el año, a través de la fecha actual. Luego, en el .html sólo tenemos que*/
                                                                /* que imprimir esta variable, proque la hemos generado en el OnInit, si estamos loggeados.*/

			if (!this._authSvc.language) {
				this._authSvc.language = this.loggedUser['idioma_notificaciones'];
			}
			
			switch (this._authSvc.language) {
				case 'Aleman':
					this.translate.use('de');
					break;
				case 'Italiano':
					this.translate.use('it');
					break;
				case 'Frances':
					this.translate.use('fr');
					break;
				case 'Portugues':
					this.translate.use('pt');
					break;
        case 'Holandes':
          this.translate.use('nl');
          break;
				case 'Español':
				case 'Castellano':
					this.translate.use('es');
					break;
				default:
					this.translate.use('en');
					break;
			}

			// ACTIVAR/DESACTIVAR llamadas para ver si el usuario esta online
			this._usrSvc.lastMove().catch((error) => {});
			this._lastMoveInterval = setInterval(() => {
				if(!this._authSvc.user) {
					clearInterval(this._lastMoveInterval);
					this.loggedUser = null;
					return;
				}
				this._usrSvc.lastMove().catch((error) => {});
			}, 60000);
		} else {
			const browserLang = this.translate.getBrowserLang();

			this.translate.use(browserLang.match(/en|es|fr|de|it|pt|nl/) ? browserLang : 'en');

			switch (browserLang) {
				case 'de':
					this._authSvc.language = 'Aleman';
					break;
				case 'it':
					this._authSvc.language = 'Italiano';
					break;
				case 'fr':
					this._authSvc.language = 'Frances';
					break;
				case 'pt':
					this._authSvc.language = 'Portugues';
					break;
				case 'es':
					this._authSvc.language = 'Castellano';
					break;
				case 'nl':
					this._authSvc.language = 'Holandes';
					break;
				default:
					this._authSvc.language = 'Ingles';
					break;
			}
		}


		this._router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				if (!this.loggedUser) {
					this.loggedUser = this._authSvc.user;
				}

				return;
			}

			window.scrollTo(0, 0);
		});

		if (this.getCookie() !== '1') {
			this.cookieBar.nativeElement.style.display = 'block';
		}
	}
	public getCurrentYear() {
			return this.CurrentYear;
	}

	public acceptCookie() {
		this.setCookie();
		this.cookieBar.nativeElement.style.display = 'none';
	}

	public setCookie() {
		let expirationDate = new Date();
		let cookieValue: string;

		expirationDate.setDate(expirationDate.getDate() + 365);
		cookieValue = 'CookieConsent=1; expires=' + expirationDate.toUTCString();
		document.cookie = cookieValue;
	}

	public getCookie(): string {
		let cookieValue = document.cookie;
		let cookieStart = cookieValue.indexOf(' CookieConsent=');

		if (cookieStart === -1) {
			cookieStart = cookieValue.indexOf('CookieConsent=');
		}

		if (cookieStart === -1) {
			cookieValue = null;
		} else {
			cookieStart = cookieValue.indexOf('=', cookieStart) + 1;
			let cookieEnd = cookieValue.indexOf(';', cookieStart);
			if (cookieEnd === -1) {
				cookieEnd = cookieValue.length;
			}

			cookieValue = cookieValue.substring(cookieStart, cookieEnd);
		}

		return cookieValue;
	}
}
