import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';

// utils
import * as moment from 'moment-timezone';

//declare var SunCalc: any;

@Injectable()
export class WeatherService {
	constructor(protected _http: HttpClient) {
	}

	public getWeather(device) {
		/*let url = 'https://api.worldweatheronline.com/premium/v1/weather.ashx?q=' + device.lat + ',' + device.longitud + '&format=json&num_of_days=3&key=0139c38eae714201aab85246171406';*/
		let url = 'https://api.worldweatheronline.com/premium/v1/weather.ashx?q=' + device.lat + ',' + device.longitud + '&format=json&num_of_days=3&key=c3aded035ecc4f628f085848192502';
		if (device.lat === 0 && device.longitud === 0) {
			return new Promise((resolve, reject) => {
				resolve(null);
			});
		} else {
			return new Promise((resolve, reject) => {
				this._http.get(url).subscribe((result) => {
					result = result/*.json()*/;
					if (result['data']['current_condition']) {
						result['backgroundState'] = this.getBackgroundState(result['data']['current_condition'][0]['weatherCode'], device.sunrise, device.sunset, device.timezone);
					} else {
						result = null;
					}

					resolve(result);
				}, (error) => {
					reject(error);
				});
			});
		}
	}

	public getBackgroundState(weatherCode: string, sunrise, sunset, timezone) {
		let sunriseHours = sunrise.hour();
		let sunriseMins = sunrise.minutes();
		let sunsetHours = sunset.hour();
		let sunsetMins = sunset.minutes();

		let now = moment().tz(timezone).hour() + (moment().tz(timezone).minutes() / 60);
		let sunUp = sunriseHours + (sunriseMins / 60);
		let sunDown = sunsetHours + (sunsetMins / 60);
		let background: any = {
			state: 0,
			view: 'vf_sol.jpg'
		};

		switch (weatherCode) {
			case '371': // lluvia fuerte con nieve
			case '368': // lluvia ligera con nieve
			case '395': // nieve fuerte
			case '392': // nieve ligera
			case '338': // nieve fuerte
			case '335': // nieve irregular
			case '332': // nieve moderada
			case '329': // nieve moderada irregular
			case '326': // nieve ligera
			case '323': // nieve ligera irregular
			case '179': // focos de nieve irregular, nieve
				background.state = 11;
				background.view = 'vf_snow.jpg';
				break;

			case '314': // lluvia helada fuerte
			case '308': // lluvia fuerte
			case '284': // llovizna helada fuerte
			case '281': // llovizna helada, lluvia helada
				background.state = 10;
				background.view = 'vf_lluvia.jpg';
				break;

			case '377': // lluvia fuerte con granizo
			case '374': // lluvia ligera con granizo
			case '365': // lluvia fuerte
			case '362': // lluvia ligera aguanieve
			case '350': // granizo
			case '320': // aguanieve fuerte
			case '317': // aguanieve ligera
			case '182': // focos de aguanieve irregular, granizo
				background.state = 9;
				background.view = 'vf_lluvia.jpg';
				break;

			case '260': // niebla helada
			case '248': // niebla
			case '143': // niebla, bruma, niebla
				background.state = 8;
				background.view = 'vf_sol.jpg';
				break;

			case '230': // ventisca
			case '277': // viento y nieve
				background.state = 7;
				background.view = 'vf_sol.jpg';
				break;

			case '311': // lluvia helada ligera
			case '296': // lluvia ligera
			case '293': // lluvia ligera irregular
			case '266': // llovizna ligera
			case '263': // llovizna ligera irregular
			case '185': // focos de llovizna helada irregular
			case '176': // focos de lluvia irregular, lluvia ligera
				background.state = 6;
				background.view = 'vf_lluvia.jpg';
				break;

			case '389': // lluvia fuerte relámpagos
			case '386': // lluvia ligera relámpagos, relámpagos
				background.state = 5;
				background.view = 'vf_rayos.jpg';
				break;

			case '200': // focos tormentosos, lluvioso
				background.state = 4;
				background.view = 'vf_rayos.jpg';
				break;

			case '359': // lluvias torrenciales
			case '356': // chaparrón fuerte
			case '353': // chaparrón ligeros
			case '305': // lluvia fuerte a intervalos
			case '302': // lluvia moderada
			case '299': // lluvia moderada a intervalos, chaparrón
				background.state = 3;
				background.view = 'vf_lluvia.jpg';
				break;

			case '122': // cubierto
			case '119': // nublado
			case '122': // cubierto, nuboso
				background.state = 2;
				background.view = 'vf_nublado.jpg';
				break;

			case '116': // parcialmente nuboso, parcialmente nuboso
				background.state = 1;
				background.view = 'vf_sol.jpg';
				break;

			case '113': // despejado, soleado
				background.state = 0;
				background.view = 'vf_sol.jpg';
				break;
		}

		if (now < sunUp || now > sunDown && (background.view === 'vf_sol.jpg' || background.view === 'vf_nublado.jpg')) {
			background.view = 'vf_noche.jpg';
		}

		return background;
	}
}

// http://api.worldweatheronline.com/free/v1/weather.ashx?q=0,0&format=json&num_of_days=5&key=c48wj7gem55u5e8yrw4azygm
