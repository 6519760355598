import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
// import { ChartModule } from 'angular2-highcharts';

import { StatsService } from '../../services/stats.service';

// utils
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
	selector: 'stats',
	templateUrl: 'stats.component.html'
})

export class StatsComponent implements OnInit {
	private readonly MAX_ATTEMPTS = 10;

	optionsStats = {
		title: {
			style: {
				fontSize: '13px'
			}
		},
		chart: {
			type: 'column'
		},
		series: [{
			turboThreshold: 10000,
			dataLabels: {
				enabled: true,
				rotation: -90,
				color: '#FFFFFF',
				align: 'right',
				y: 10, // 10 pixels down from the top
				style: {
					fontSize: '12px'
				}
			}
		}],
		xAxis: {
			labels: {
				rotation: -45,
				style: {
					fontSize: '13px'
				}
			}
		},
		yAxis: {
			min: 0,
			allowDecimals: false,
			title: {}
		},
		legend: {
			enabled: false
		},
		tooltip: {},
		credits: {
			enabled: false
		},
		exporting: {
			csv: {
				dateFormat: '%Y-%m-%d'
			}
		}
	};

	stats: any = {};
	calendarMonth: any;
	calendarLastMonth: any;
	month: string;
	lastMonth: string;

	private _attemptsCreateStats = 0;

	constructor(private _statsSvc: StatsService, private _translate: TranslateService) {
		this.month = this.monthName();
		this.lastMonth = this.monthName(1);
		this.createStats();
	}

	ngOnInit() {
	}

	createStats() {
		this.getStats().then((stat) => {
			this.stats = stat;

			// estadísticas de conectados
			this.stats.statLastMonth = this.initStat(stat['lastMonth']['data'],
				'EVOLUCION_CONECATOS_30_DIAS', 'POOLSTATION_CONETADOS',
				stat['lastMonth']['categories'], 'CONECTADOS', true);
			this.stats.statThisYear = this.initStat(stat['thisYear']['data'],
				'EVOLUCION_CONECATOS_ESTE_ANO', 'POOLSTATION_CONETADOS',
				stat['thisYear']['categories'], 'CONECTADOS', true);
			this.stats.statOneYear = this.initStat(stat['oneYear']['data'],
				'EVOLUCION_CONECATOS_UN_ANO', 'POOLSTATION_CONETADOS',
				stat['oneYear']['categories'], 'CONECTADOS');

			// estadísticas de funciones
			this.stats.statTwilight = this.initStat(stat['twilight']['data'],
				'EVOLUCION_CON_FUNCION_CREPUSCULAR', 'POOLSTATION_USANDO_CREPUSCULAR',
				stat['twilight']['categories'], 'CREPUSCULAR', false);

			this.stats.statInterlocks = this.initStat(stat['interlocks']['data'],
				'EVOLUCION_CON_FUNCION_ENCLAVAMIENTO', 'POOLSTATION_USANDO_ENCLAVAMIENTOS',
				stat['interlocks']['categories'], 'ENCLAVAMIENTOS', false);

			this.stats.statAlarms = this.initStat(stat['alarms']['data'],
				'EVOLUCION_CON_FUNCION_ALARMA', 'POOLSTATION_USANDO_ALARMA',
				stat['alarms']['categories'], 'FUNCION_ALARMAS', false);

			// estadísticas de usuarios
			this.stats.statUsers = this.initStat(stat['users']['data'],
				'USUARIOS_REGISTRADOS_POOLSTATION', 'USUARIOS_REGISTRADOS',
				stat['users']['categories'], 'USUARIOS_REGISTRADOS', false);

			this.stats.statUsersPoolstation = this.initStat(stat['usersPoolstation']['data'],
				'USUARIOS_ASOCIADOS_REGISTRADOS', 'USUARIOS_ASOCIADOS_REGISTRADOS',
				stat['usersPoolstation']['categories'], 'USUARIOS_ASOCIADOS_REGISTRADOS', false);

			this.stats.statProfessionals = this.initStat(stat['professionals']['data'],
				'PROFESIONALES_REGISTARADOS_POOLSTATION', 'PROFESIONALES_REGISTRADOS',
				stat['professionals']['categories'], 'PROFESIONALES_REGISTRADOS', false);

			this.stats.statAssociateProfessionals = this.initStat(stat['associateProfessionals']['data'],
				'PROFESIONALES_POOLSTATION_ASOCIADO', 'PROFESIONALES_POOLSTATION_ASOCIADO',
				stat['associateProfessionals']['categories'], 'PROFESIONALES_POOLSTATION_ASOCIADO', false);

			// estadisticas del calendario
			this.calendarMonth = this.calendar();
			this.calendarLastMonth = this.calendar(1);
		}).catch((error) => {
			this._attemptsCreateStats++;
			if(error.status != 401 ){
				if(this._attemptsCreateStats < this.MAX_ATTEMPTS){
					setTimeout(() => {
						this.createStats();
					}, 1000);
				}
			}
		});
	}

	initStat(data, title, yTitle, categories, tooltip, seriesLabel = false) {
		const stat = _.cloneDeep(this.optionsStats);

		stat.series[0]['data'] = data;
		stat.series[0]['dataLabels']['enabled'] = seriesLabel;
		stat.title['text'] = this._translate.instant(title);
		stat.yAxis.title['text'] = this._translate.instant(yTitle);
		stat.xAxis['categories'] = categories;
		stat.tooltip['pointFormat'] = this._translate.instant(tooltip) + ': <b>{point.y}</b>';

		return new Chart(stat);
	}

	getStats() {
		return this._statsSvc.getStats();
	}

	calendar(monthsSubtract: number = 0) {
		moment.locale('es');
		let startWeek = moment().subtract(monthsSubtract, 'months').startOf('month').week();
		let endWeek = moment().subtract(monthsSubtract, 'months').endOf('month').week();
		const dates = [];

		if (startWeek > endWeek) {
			endWeek = 53;
		}

		for (let week = startWeek; week <= endWeek; week++) {
			dates.push({
				week: week,
				stat: Array(7).fill(0).map((n, i) => {
					return {
						day: moment().week(week).startOf('week').isoWeekday(1).clone().add(n + i, 'day').format('D').toString(),
						data: this.stats['calendar'][moment().week(week).startOf('week').isoWeekday(1).clone().add(n + i, 'day').format('YYYY-MM-DD').toString()]
					};
				}),
			});
		}

		return dates;
	}

	monthName(monthsSubtract: number = 0) {
		moment.locale('es');
		return moment().subtract(monthsSubtract, 'month').startOf('month').format('MMMM');
	}

}
