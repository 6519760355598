
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// services
import { DevicesService } from '../../services/devices.service';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'alarms',
	templateUrl: 'alarms.component.html',
	styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent implements OnInit, OnDestroy {
	@ViewChild('notificationsModal', {static: true}) notificationsModal;

	public idDispositivo: number;
	public device: any;
	public relayTimeOut: any;
	public saving: boolean = false;
	public notificationsTmp: any;
	public notifications: any = {
		sf: '1',
		wp: '1',
		dp: '1',
		pe: '1',
		wo: '1',
		do: '1',
		oe: '1',
		wh: '1',
		dc: '1',
		he: '1',
		cc: '1',
		ws: '1',
		an: '1',
		bu: '1',
		fu: '1',
		bp: '1',
		cp: '1',
		bo: '1',
		po: '1',
		bh: '1',
		ch: '1',
		at: '1',
		as: '1'
	};

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private _notificationService: NotificationsService,
		private _translateSvc: TranslateService,
		private _devicesSvc: DevicesService) {
	}

	public ngOnInit() {
		this.route.params.subscribe((params) => {
			if (params['id'] != null) {
				this.idDispositivo = params['id'];
				setTimeout(() => {
					this._devicesSvc.changeMode(this.idDispositivo, 'fast').then((result: any) => {
						console.log(result.message);
					}).catch((error: any) => {
						console.log(error.message);
					});
				}, 750);

				this.getDevice();
			}
		});
	}

	public ngOnDestroy() {
		if (this.relayTimeOut) {
			clearTimeout(this.relayTimeOut);
			this.relayTimeOut = null;
		}
	}

	public getDevice() {
		this._devicesSvc.get(this.idDispositivo).then((device) => {
			this.device = device;

			if (this.device.alarmas_mail) {
				this.notifications = JSON.parse(this.device.alarmas_mail);

				for (let index in this.notifications) {
					if (this.notifications[index] === '1') {
						this.notifications[index] = true;
					} else {
						this.notifications[index] = false;
					}
				}
			}

			this.getUpdateTime(this.device.ns);
		}).catch((error) => {
			console.log(error);
			setTimeout(() => {
				this.getDevice();
			}, 3000);
		});
	}

	public getUpdateTime(ns: string) {
		if (this.router.url === '/devices/alarms/' + this.device.id) {
			this.relayTimeOut = setTimeout(() => {
				this._devicesSvc.getRelaysStatus(ns).then((result: any) => {
					this.device.vars.updated = result.updated;
					this.device.vars.ep = result.ep;
					this.device.vars.rp = result.rp;
					this.device.device_ep = result.ep;
					this.device.device_rp = result.rp;
					clearTimeout(this.relayTimeOut);
					this.getUpdateTime(ns);
				}).catch((error) => {
					console.log(error);
					clearTimeout(this.relayTimeOut);
					setTimeout(() => {
						this.getUpdateTime(ns);
					}, 3000);
				});
			}, 10000);
		}
	}

	public openNotifications() {
		this.notificationsTmp = _.clone(this.notifications);
		this.notificationsModal.show();
	}

	public saveNotifications() {
		this.notifications = this.notificationsTmp;
		this.notificationsModal.hide();
	}

	public selectAll() {
		// tslint:disable-next-line:forin
		for (let index in this.notificationsTmp) {
			this.notificationsTmp[index] = true;
		}
	}

	public deSelectAll() {
		// tslint:disable-next-line:forin
		for (let index in this.notificationsTmp) {
			this.notificationsTmp[index] = false;
		}
	}

	public save() {
		let alarms: any;
		this.saving = true;

		let notificationsCopy = Object.assign({}, this.notifications);
		for (let index in notificationsCopy) {
			if (notificationsCopy[index] === true || notificationsCopy[index] === '1') {
				notificationsCopy[index] = '1';
			} else {
				notificationsCopy[index] = '0';
			}
		}

		this.device.alarmas_mail = JSON.stringify(notificationsCopy);
		this.device.notificar_alarma = +this.device.notificar_alarma;
		this.device.alarma_dia = +this.device.alarma_dia;
		alarms = _.pick(this.device, ['id', 'notificar_alarma', 'alarma_dia', 'retraso_alarmas', 'alarmas_mail']);
		alarms.retraso_alarmas = +alarms.retraso_alarmas;

		this._devicesSvc.saveAlarms(alarms).then((result: any) => {
			this._notificationService.success('', this._translateSvc.instant(result.message));
			this.saving = false;
		}).catch((error: any) => {
			if (error.message) {
				this._notificationService.error('', this._translateSvc.instant(error.message));
			}

			this.saving = false;
		});
	}
}
