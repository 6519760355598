import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'version',
	templateUrl: './version.component.html'
})
export class VersionComponent implements OnInit {
	public table: string;
	public title: string;

	constructor(private _activatedRouter: ActivatedRoute,
		private _router: Router) { }

	ngOnInit() {
		this._activatedRouter.params.subscribe((params) => {
			(!params['option']) ? this._router.navigate(['/version', 'devices']) : this.table = params['option'];

			switch (this.table) {
				case 'devices':
					this.title = 'LISTADO_EQUIPOS';
					break;
				case 'updatelogs':
					this.title = 'REGISTRO_ACTUALIZ';
					break;
				case 'pbas':
					this.title = 'PBAS';
					break;
				case 'models':
					this.title = 'DEFINICION_MODELO';
					break;
				case 'hardware':
					this.title = 'HARDWARE';
					break;
				case 'software':
					this.title = 'SOFTWARE';
					break;
				case 'compatibility':
					this.title = 'COMPATIBILIDADES';
					break;
				default:
					this.title = '';
					break;
			}
		});
	}

	public goTo(option: string) {
		this._router.navigate(['/version', option]);
	}
}
