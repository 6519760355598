<header ps-header>
	<h2>{{ "ENTRADAS" | translate }}
		<small><strong>{{ device?.ns }}</strong></small>
		<small> - {{ device?.alias }}</small>
	</h2>
</header>
<main class="container">
	<div *ngIf="!device" class="text-center">
		<img src="/assets/images/wave.svg">
	</div>
		<div *ngIf="device">
			<div *ngIf="device.series === 1" id="entradasAnalogicas">
				<h3>{{ 'ENTRADAS_ANALOGICAS' | translate }}</h3>
				<div class="row">

					<div class="col-md-6">
						<div class="panel">
							<div class="panel-body row">
								<h3 class="col-md-12">{{ 'ENTRADA' | translate }} mA1</h3>
								<div class="col-md-6 form-group">
									<label>{{ 'NOMBRE_SENYAL_A1' | translate }}</label>
									<input type="text" class="form-control" [(ngModel)]="device.a1_name">
								</div>
								<div class="col-md-6 form-group">
									<label>{{ 'UNIDAD_DE_MEDIDA' | translate }}</label>
									<select class="form-control" [value]="device.a1_unidad" (change)="device.a1_unidad = $event.target.value; calibrate('', 'a1')">
										<option value="C">ºC</option>
										<option value="F">ºF</option>
										<option value="bar">bar</option>
										<option value="psi">psi</option>
										<option value="lux">lux</option>
										<option value="%">H%</option>
										<option value="NTU">ntu</option>
										<option value="g_l">g/L</option>
										<option value="mg_l">mg/L</option>
										<option value="ppm">ppm</option>
										<option value="mA">mA</option>
									</select>
								</div>
								<div class="col-md-6 form-group">
									<label>{{ 'VALOR_MINIMO' | translate }}</label>
									<input type="number" class="form-control" (change)="calibrate('', 'a1')" [(ngModel)]="device.a1_min">
								</div>
								<div class="col-md-6 form-group">
									<label>{{ 'VALOR_MAXIMO' | translate }}</label>
									<input type="number" class="form-control" (change)="calibrate('', 'a1')" [(ngModel)]="device.a1_max">
								</div>
								<div class="col-md-6">
									<label>{{ 'OFFSET' | translate }}</label>
									<div class="input-group">
										<span class="input-group-btn">
										<button class="btn btn-primary" (click)="calibrate('minus', 'a1')"><i class="fa fa-minus"></i></button>
									</span>
										<input type="text" class="form-control text-center" readonly [(ngModel)]="offsetA1">
										<span class="input-group-btn">
										<button class="btn btn-primary" (click)="calibrate('more', 'a1')"><i class="fa fa-plus"></i></button>
										<button type="button" class="btn btn-danger" (click)="reset('a1')">{{ 'RESET' | translate }}</button>
									</span>
									</div>
								</div>
								<div class="col-md-6">
									<label>{{ 'PREVIEW' | translate }}</label>
									<div class="input-group">
										<input type="text" class="form-control text-center" readonly [(ngModel)]="previewA1">
										<span class="input-group-btn">
										<button type="button" (click)="refresh()" class="btn btn-primary" [title]="'ACTUALIZAR_VALOR' | translate"><i class="fa fa-refresh"></i></button>
									</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md-6">
						<div class="panel">
							<div class="panel-body row">
								<div class="col-md-12">
									<h3>{{ 'ENTRADA' | translate }} mA2</h3>
								</div>
								<div class="col-md-6 form-group">
									<label>{{ 'NOMBRE_SENYAL_A2' | translate }}</label>
									<input type="text" class="form-control" [(ngModel)]="device.a2_name">
								</div>
								<div class="col-md-6 form-group">
									<label>{{ 'UNIDAD_DE_MEDIDA' | translate }}</label>
									<select class="form-control" [value]="device.a2_unidad" (change)="device.a2_unidad=$event.target.value; calibrate('', 'a2')">
										<option value="C">ºC</option>
										<option value="F">ºF</option>
										<option value="bar">bar</option>
										<option value="psi">psi</option>
										<option value="lux">lux</option>
										<option value="%">H%</option>
										<option value="NTU">ntu</option>
										<option value="g_l">g/L</option>
										<option value="mg_l">mg/L</option>
										<option value="ppm">ppm</option>
										<option value="mA">mA</option>
									</select>
								</div>
								<div class="col-md-6 form-group">
									<label>{{ 'VALOR_MINIMO' | translate }}</label>
									<input type="number" (change)="calibrate('', 'a2')" class="form-control" [(ngModel)]="device.a2_min">
								</div>
								<div class="col-md-6 form-group">
									<label>{{ 'VALOR_MAXIMO' | translate }}</label>
									<input type="number" (change)="calibrate('', 'a2')" class="form-control" [(ngModel)]="device.a2_max">
								</div>
								<div class="col-md-6">
									<label>{{ 'OFFSET' | translate }}</label>
									<div class="input-group">
										<span class="input-group-btn">
										<button class="btn btn-primary" (click)="calibrate('minus', 'a2')"><i class="fa fa-minus"></i></button>
									</span>
										<input type="text" class="form-control text-center" readonly [(ngModel)]="offsetA2">
										<span class="input-group-btn">
										<button class="btn btn-primary" (click)="calibrate('more', 'a2')"><i class="fa fa-plus"></i></button>
										<button type="button" class="btn btn-danger" (click)="reset('a2')">{{ 'RESET' | translate }}</button>
									</span>
									</div>
								</div>
								<div class="col-md-6">
									<label>{{ 'PREVIEW' | translate }}</label>
									<div class="input-group">
										<input type="text" class="form-control text-center" readonly [(ngModel)]="previewA2">
										<span class="input-group-btn">
										<button type="button" (click)="refresh()" class="btn btn-primary" [title]="'ACTUALIZAR_VALOR' | translate"><i class="fa fa-refresh"></i></button>
									</span>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<h3>{{ 'ENTRADAS_DIGITALES' | translate }}</h3>
			<table class="table table-striped table-bordered table-hover hidden-xs hidden-sm">
				<thead>
					<tr>
						<th>{{"ENTRADA" | translate}}</th>
						<th>{{ 'NOMBRE' | translate }}</th>
						<th>
							<img src="/assets/images/ico_en_cierra.png" /> {{ 'AVISAR_CORREO_CERRADO' | translate }}
						</th>
						<th>
							<img src="/assets/images/ico_en_abre.png" /> {{ 'AVISAR_CORREO_ABIERTO' | translate }}
						</th>
					</tr>
				</thead>
				<tbody class="text-center">
					<tr *ngFor="let key of [1, 2, 3, 4]">
						<td><strong>IN{{ key }}</strong></td>
						<td>
							<input type="text" class="form-control" [(ngModel)]="device['d' + key + '_name']" />
						</td>
						<td>
							<input type="checkbox" [checked]="(device['d' + key + '_alarma'] === 1 || device['d' + key + '_alarma'] === 3) ? true:false" (change)="$event.target.checked? device['d' + key + '_alarma'] = device['d' + key + '_alarma'] + 1: device['d' + key + '_alarma'] = device['d' + key + '_alarma'] - 1;" />
						</td>
						<td>
							<input type="checkbox" [checked]="(device['d' + key + '_alarma'] === 2 || device['d' + key + '_alarma'] === 3)? true:false" (change)="$event.target.checked? device['d' + key + '_alarma'] = device['d' + key + '_alarma'] + 2: device['d' + key + '_alarma'] = device['d' + key + '_alarma'] - 2;" />
						</td>
					</tr>
				</tbody>
			</table>

			<div class="visible-xs visible-sm">
				<div *ngFor="let key of [1, 2, 3, 4]" class="panel panel-default">
					<div class="panel-body">
						<div display-table>
							<div display-table-cell>
								<p><strong>IN{{ key }}</strong></p>
								<p>
									<input type="text" class="form-control" [(ngModel)]="device['d' + key + '_name']" />
								</p>
								<hr>
								<div class="row">
									<div class="col-xs-6">{{ 'AVISAR_CORREO_CERRADO' | translate }}</div>
									<div class="col-xs-6">{{ 'AVISAR_CORREO_ABIERTO' | translate }}</div>
									<div class="col-xs-3 text-center">
										<img src="/assets/images/ico_en_cierrab.png" />
									</div>
										<div class="col-xs-3 text-center">
											<input type="checkbox" [checked]="(device['d' + key + '_alarma'] === 1 || device['d' + key + '_alarma'] === 3) ? true:false" (change)="$event.target.checked? device['d' + key + '_alarma'] = device['d' + key + '_alarma'] + 1: device['d' + key + '_alarma'] = device['d' + key + '_alarma'] - 1;" />
										</div>
										<div class="col-xs-3 text-center">
											<img src="/assets/images/ico_en_abreb.png" />
									</div>
											<div class="col-xs-3 text-center">
												<input type="checkbox" [checked]="(device['d' + key + '_alarma'] === 2 || device['d' + key + '_alarma'] === 3)? true:false" (change)="$event.target.checked? device['d' + key + '_alarma'] = device['d' + key + '_alarma'] + 2: device['d' + key + '_alarma'] = device['d' + key + '_alarma'] - 2;" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="alert alert-info">
						<p>
							<i class="fa fa-fw fa-info"></i>
							<a href="javascript:{}" [routerLink]="['/devices/alarms', device.id]">{{ 'ACTIVE_ALARMAS' | translate }}</a>
						</p>
					</div>
					<p>
						<button [disabled]="loggedUser.isDemo" (click)="save()" class="btn btn-primary">{{'BT_GUARDAR' | translate}}</button>
						<button class="btn btn-danger" onclick="history.back()" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
					</p>
				</div>
</main>
