import { Component, OnInit, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

// services
import { VersionsService } from 'app/services/versions.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'compatibility',
	templateUrl: './compatibility.component.html',
	styleUrls: ['./compatibility.component.scss']
})
export class CompatibilityComponent implements OnInit {
	@ViewChild('editCompatibility', {static: true}) editCompatibility;
	@ViewChild('deleteCompatibility', {static: true}) deleteCompatibility;

	public compatibilities: any[];
	public totalCompatibilities: number;
	public tempCompatibility: any = {};
	public pbas: any[];
	public pba1Loading = false;
	public pba2Loading = false;
	public models: any[];
	public hwPba1: any[];
	public fwPba1: any[];
	public hwPba2: any[];
	public fwPba2: any[];
	public conditions: any;
	public limit: number = 20;
	public page: number = 1;
	public totalPages: number = 1;
	public disableButtons: boolean = false;

	public modelsFilterValue: any[] = [];
	public modelsFilterOptions: IMultiSelectOption[] = [];
	public modelsFilterSettings: IMultiSelectSettings;
	public modelsFilterTexts: IMultiSelectTexts;
	public modelsFilterValueBack: any;
	public modelsFilterHasAllBefore = true;

	public pbas1FilterValue: any[] = [];
	public pbas1FilterOptions: IMultiSelectOption[] = [];
	public pbas1FilterSettings: IMultiSelectSettings;
	public pbas1FilterTexts: IMultiSelectTexts;
	public pbas1FilterValueBack: any;
	public pbas1FilterHasAllBefore = true;

	public pbas2FilterValue: any[] = [];
	public pbas2FilterOptions: IMultiSelectOption[] = [];
	public pbas2FilterSettings: IMultiSelectSettings;
	public pbas2FilterTexts: IMultiSelectTexts;
	public pbas2FilterValueBack: any;
	public pbas2FilterHasAllBefore = true;

	public validFilterValue: any[] = [];
	public validFilterOptions: IMultiSelectOption[] = [];
	public validFilterSettings: IMultiSelectSettings;
	public validFilterTexts: IMultiSelectTexts;

	public filtersDelay: any;

	private _searchText: string = '';
	/* private _pba1: any;
	private _pba2: any; */

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		value = value.trim();

		if (value && value !== '') {
			this._searchText = value;
			this.conditions = {
				conditions: [
					'compa.observations LIKE :search'
				],
				parameters: {
					search: this._searchText
				},
				models: (this.modelsFilterValue.indexOf(-1) > -1) ? null : this.modelsFilterValue,
				pbas1: (this.pbas1FilterValue.indexOf(-1) > -1) ? null : this.pbas1FilterValue,
				pbas2: (this.pbas2FilterValue.indexOf(-1) > -1) ? null : this.pbas2FilterValue,
				valid: this.validFilterValue
			};
		} else {
			this.conditions = {
				conditions: [],
				parameters: {},
				models: (this.modelsFilterValue.indexOf(-1) > -1) ? null : this.modelsFilterValue,
				pbas1: (this.pbas1FilterValue.indexOf(-1) > -1) ? null : this.pbas1FilterValue,
				pbas2: (this.pbas2FilterValue.indexOf(-1) > -1) ? null : this.pbas2FilterValue,
				valid: this.validFilterValue
			};
		}

		this.loadItems();
	}

	constructor(private _versionSvc: VersionsService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService) { }

	ngOnInit() {
		this._setFilterData();
	}

	public loadItems(nPage: number = 1) {
		this.disableButtons = true;

		this._versionSvc.findCompatibilities(this.limit, nPage, this.conditions).then((result: any) => {
			this.disableButtons = false;
			this.compatibilities = result.items;
			this.totalCompatibilities = result.total;
			this.totalPages = Math.ceil(this.totalCompatibilities / this.limit);
			console.log(this.compatibilities);
		}).catch((error: any) => {
			this.disableButtons = false;
			this.loadItems();
		});
	}

	public createCompatibility() {
		this._getModels();
		this._getPbas();

		this.tempCompatibility = {
			id_model: null,
			indexPba1: null,
			indexPba2: null,
			indexHwPba1: null,
			indexHwPba2: null,
			indexFwPba1: null,
			indexFwPba2: null,
			indexBckPba1: null,
			indexBckPba2: null,
			valid: 0,
			observations: ''
		};

		this.editCompatibility.show();
	}

	public edit(compa: any = null) {
		this._getPbasWithIndexes(compa.id_pba1, compa.id_pba2);
		this._getModels();
		this.getPba1Data(compa.id_pba1, true);
		this.getPba2Data(compa.id_pba2, true);

		this.tempCompatibility = {
			id: compa.id,
			compa,
			id_model: +compa.id_model,
			indexPba1: null,
			indexPba2: null,
			valid: (compa.valid) ? 1 : 0,
			observations: compa.observations
		};

		this.editCompatibility.show();
	}

	public delete() {
		this.disableButtons = true;

		this._versionSvc.deleteCompatibility(this.tempCompatibility.id).then((result: any) => {
			this._notificationSvc.success('', this._translate.instant(result.message));
			this.disableButtons = false;
			this.deleteCompatibility.hide();
			this.loadItems(this.page);
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}

			this.disableButtons = false;
			this.deleteCompatibility.hide();
		});
	}

	public save() {
		let data: any;

		this.disableButtons = true;

		if (this.tempCompatibility.id_model && this.tempCompatibility.indexPba1 && this.tempCompatibility.indexPba2 && this.tempCompatibility.indexHwPba1 && this.tempCompatibility.indexFwPba1 && this.tempCompatibility.indexHwPba2 && this.tempCompatibility.indexFwPba2 && this.tempCompatibility.indexBckPba1 && this.tempCompatibility.indexBckPba2) {
			data = {
				idModel: +this.tempCompatibility.id_model,
				idPba1: this.pbas[this.tempCompatibility.indexPba1].id,
				idPba2: this.pbas[this.tempCompatibility.indexPba2].id,
				hw1Id: this.hwPba1[this.tempCompatibility.indexHwPba1].id,
				fw1Id: this.fwPba1[this.tempCompatibility.indexFwPba1].id,
				hw2Id: this.hwPba2[this.tempCompatibility.indexHwPba2].id,
				fw2Id: this.fwPba2[this.tempCompatibility.indexFwPba2].id,
				bck1: this.fwPba1[this.tempCompatibility.indexBckPba1].id,
				bck2: this.fwPba2[this.tempCompatibility.indexBckPba2].id,
				valid: +this.tempCompatibility.valid,
				observations: this.tempCompatibility.observations
			};

			if (this.tempCompatibility.id) {
				data.id = this.tempCompatibility.id;
			}

			this._versionSvc.saveCompatibility(data).then((result: any) => {
				this.disableButtons = false;
				this.editCompatibility.hide();
				this._notificationSvc.success('', this._translate.instant(result.message));
				this.loadItems(this.page);
			}).catch((error) => {
				this.disableButtons = false;
				this.editCompatibility.hide();
				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}
			});
		} else {
			this.disableButtons = false;
			this.editCompatibility.hide();
			this._notificationSvc.error('', this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS'));
		}
	}

	public filterChanged(option: number) {
		const hasAllModels: boolean = this.modelsFilterValue.indexOf(-1) > -1;
		const hasAllPbas1: boolean = this.pbas1FilterValue.indexOf(-1) > -1;
		const hasAllPbas2: boolean = this.pbas2FilterValue.indexOf(-1) > -1;

		if (this.filtersDelay) {
			clearTimeout(this.filtersDelay);
			this.filtersDelay = null;
		}

		switch (option) {
			case 1:
				if (!hasAllModels && this.modelsFilterHasAllBefore) {
					this.modelsFilterValue = [];
				} else if (hasAllModels && !this.modelsFilterHasAllBefore) {
					this.modelsFilterValue = _.clone(this.modelsFilterValueBack);
				}

				if (this.modelsFilterValue.length < this.modelsFilterValueBack.length && hasAllModels) {
					const index = this.modelsFilterValue.indexOf(-1);
					this.modelsFilterValue.splice(index, 1);
				} else if (this.modelsFilterValue.length === (this.modelsFilterValueBack.length - 1) && !hasAllModels) {
					this.modelsFilterValue.push(-1);
				}
				break;
			case 2:
				if (!hasAllPbas1 && this.pbas1FilterHasAllBefore) {
					this.pbas1FilterValue = [];
				} else if (hasAllPbas1 && !this.pbas1FilterHasAllBefore) {
					this.pbas1FilterValue = _.clone(this.pbas1FilterValueBack);
				}

				if (this.pbas1FilterValue.length < this.pbas1FilterValueBack.length && hasAllPbas1) {
					const index = this.pbas1FilterValue.indexOf(-1);
					this.pbas1FilterValue.splice(index, 1);
				} else if (this.pbas1FilterValue.length === (this.pbas1FilterValueBack.length - 1) && !hasAllPbas1) {
					this.pbas1FilterValue.push(-1);
				}
				break;
			case 3:
				if (!hasAllPbas2 && this.pbas2FilterHasAllBefore) {
					this.pbas2FilterValue = [];
				} else if (hasAllPbas2 && !this.pbas2FilterHasAllBefore) {
					this.pbas2FilterValue = _.clone(this.pbas2FilterValueBack);
				}

				if (this.pbas2FilterValue.length < this.pbas2FilterValueBack.length && hasAllPbas2) {
					const index = this.pbas2FilterValue.indexOf(-1);
					this.pbas2FilterValue.splice(index, 1);
				} else if (this.pbas2FilterValue.length === (this.pbas2FilterValueBack.length - 1) && !hasAllPbas2) {
					this.pbas2FilterValue.push(-1);
				}
				break;
			default:
				break;
		}

		this.modelsFilterHasAllBefore = this.modelsFilterValue.indexOf(-1) > -1;
		this.pbas1FilterHasAllBefore = this.pbas1FilterValue.indexOf(-1) > -1;
		this.pbas2FilterHasAllBefore = this.pbas2FilterValue.indexOf(-1) > -1;

		this.filtersDelay = setTimeout(() => {
			this.searchText = this._searchText;
		}, 750);
	}

	private _setFilterData() {
		// validFilter
		this.validFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: false,
			stopScrollPropagation: true
		};
		this.validFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('VERSION_VALIDA'),
			allSelected: this._translate.instant('TODOS')
		});
		this.validFilterValue = [0, 1];
		this.validFilterOptions = [{
			id: 1,
			name: this._translate.instant('SI')
		}, {
			id: 0,
			name: this._translate.instant('NO')
		}];

		// pbasFilter
		this.pbas1FilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.pbas1FilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('PBAS'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});
		this.pbas2FilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.pbas2FilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('PBAS'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// typeFilter
		this.modelsFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.modelsFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('MODELO'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// obtengo los datos
		this._versionSvc.getCompatibilityData().then((compData: any) => {
			this.modelsFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.modelsFilterValue.push(-1);

			for (let model of compData.models) {
				this.modelsFilterValue.push(model.id);
				this.modelsFilterOptions.push(model);
			}

			this.modelsFilterValueBack = _.clone(this.modelsFilterValue);
			this.pbas1FilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.pbas1FilterValue.push(-1);

			for (let pba1 of compData.pbas1) {
				this.pbas1FilterValue.push(pba1.id);
				this.pbas1FilterOptions.push(pba1);
			}

			this.pbas1FilterValueBack = _.clone(this.pbas1FilterValue);
			this.pbas2FilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.pbas2FilterValue.push(-1);

			for (let pba2 of compData.pbas2) {
				this.pbas2FilterValue.push(pba2.id);
				this.pbas2FilterOptions.push(pba2);
			}

			this.pbas2FilterValueBack = _.clone(this.pbas2FilterValue);
			this.loadItems();
		}).catch((error) => {
			this._notificationSvc.error('', this._translate.instant(error.message));
			this.loadItems();
		});
	}

	private _getModels() {
		this.models = null;

		this._versionSvc.getAllModels().then((result: any) => {
			this.models = result;
		}).catch((error) => {
			console.log(error);
		});
	}

	private _getPbas() {
		this.pbas = null;

		this._versionSvc.getAllPbas().then((result: any) => {
			this.pbas = result;
		}).catch((error) => {
			console.log(error);
		});
	}

	public getPba1Data(id: number, setIndexes = false) {
		this.pba1Loading = true;
		this._getHardwaresPba1(id, setIndexes);
		this._getFirmwaresPba1(id, setIndexes)
	}

	private _getHardwaresPba1(id: number, setIndex = false) {
		this.tempCompatibility.indexHwPba1 = null;
		this.hwPba1 = null;

		this._versionSvc.getAllPbaHardwares(id).then((result: any) => {
			this.hwPba1 = result;

			if (setIndex) {
				for (let i in this.hwPba1) {
					if (this.hwPba1[i].id === this.tempCompatibility.compa.hw1.id) {
						this.tempCompatibility.indexHwPba1 = i;
						break;
					}
				}
			}

			this._checkPba1Data();
		}).catch((error) => {
			this.pba1Loading = true;
			console.log(error);
		});
	}

	private _getFirmwaresPba1(id: number, setIndex = false) {
		this.tempCompatibility.indexFwPba1 = null;
		this.fwPba1 = null;

		this._versionSvc.getAllPbaFirmwares(id).then((result: any) => {
			this.fwPba1 = result;
			if (setIndex) {
				// tslint:disable-next-line:forin
				for (let i in this.fwPba1) {
					if (this.fwPba1[i].id === this.tempCompatibility.compa.fw1.id) {
						this.tempCompatibility.indexFwPba1 = i;
					}

					if (this.fwPba1[i].id === this.tempCompatibility.compa.bck1.id) {
						this.tempCompatibility.indexBckPba1 = i;
					}

					if (this.tempCompatibility.indexFwPba1 && this.tempCompatibility.indexBckPba1) {
						break;
					}
				}
			}

			this._checkPba1Data();
		}).catch((error) => {
			this.pba1Loading = true;
			console.log(error);
		});
	}

	private _checkPba1Data() {
		if (this.hwPba1 && this.fwPba1) {
			this.pba1Loading = false;
		}
	}

	public getPba2Data(id: number, setIndexes = false) {
		this.pba2Loading = true;
		this._getHardwaresPba2(id, setIndexes);
		this._getFirmwaresPba2(id, setIndexes)
	}

	private _getHardwaresPba2(id: number, setIndex = false) {
		this.tempCompatibility.indexHwPba2 = null;
		this.hwPba2 = null;

		this._versionSvc.getAllPbaHardwares(id).then((result: any) => {
			this.hwPba2 = result;
			if (setIndex) {
				for (let i in this.hwPba2) {
					if (this.hwPba2[i].id === this.tempCompatibility.compa.hw2.id) {
						this.tempCompatibility.indexHwPba2 = i;
						break;
					}
				}
			}

			this._checkPba2Data();
		}).catch((error) => {
			this.pba2Loading = true;
			console.log(error);
		});
	}

	private _getFirmwaresPba2(id: number, setIndex = false) {
		this.tempCompatibility.indexFwPba2 = null;
		this.fwPba2 = null;

		this._versionSvc.getAllPbaFirmwares(id).then((result: any) => {
			this.fwPba2 = result;

			if (setIndex) {
				// tslint:disable-next-line:forin
				for (let i in this.fwPba2) {
					if (this.fwPba2[i].id === this.tempCompatibility.compa.fw2.id) {
						this.tempCompatibility.indexFwPba2 = i;
					}

					if (this.fwPba2[i].id === this.tempCompatibility.compa.bck2.id) {
						this.tempCompatibility.indexBckPba2 = i;
					}

					if (this.tempCompatibility.indexFwPba2 && this.tempCompatibility.indexBckPba2) {
						break;
					}
				}
			}

			this._checkPba2Data();
		}).catch((error) => {
			this.pba2Loading = true;
			console.log(error);
		});
	}

	private _checkPba2Data() {
		if (this.hwPba2 && this.fwPba2) {
			this.pba2Loading = false;
		}
	}

	private _getPbasWithIndexes(id1: number, id2: number) {
		this.pbas = null;

		this._versionSvc.getAllPbas().then((result: any) => {
			this.pbas = result;

			// tslint:disable-next-line:forin
			for (let i in this.pbas) {
				if (this.pbas[i].id === id1) {
					this.tempCompatibility.indexPba1 = i;
				}

				if (this.pbas[i].id === id2) {
					this.tempCompatibility.indexPba2 = i;
				}

				if (this.tempCompatibility.indexPba1 && this.tempCompatibility.indexPba2) {
					break;
				} else {
					continue;
				}
			}
		}).catch((error) => {
			console.log(error);
		});
	}
}
