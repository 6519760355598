<header ps-header [device]="(device?.id) ? device : null">
	<h2 *ngIf="action == 'view'">{{ 'VISTA_PROFESIONAL' | translate }}
		<small><strong>{{ device?.ns }}</strong></small>
		<small> - {{ device?.alias }}</small>
	</h2>
	<h2 *ngIf="action != 'view'">{{ (action == 'add' ? 'NUEVO_DISPOSITIVO' : 'EDITAR_DISPOSITIVO') | translate }}
		<span *ngIf="action == 'edit'">
			<small><strong>{{ device?.ns }}</strong></small>
			<small> - {{ device?.alias }}</small>
		</span>
	</h2>
</header>

<main>
	<loading [ngClass]="{'hidden': device || !id}"></loading>
	<div *ngIf="device">
		<div *ngIf="action == 'view'" class="container-fluid">

			<div class="row">
				<div class="col-sm-8 col-xs-12">
					<h3>
						{{ 'ESTADO_PISCINA' | translate }}
						<small [ngClass]="device.vars.en === '0' ? 'text-success' : 'text-danger'">
							<span class="fa-stack fa-md">
								<i class="fa fa-circle fa-stack-2x"></i>
								<i class="fa fa-stack-1x fa-inverse" [ngClass]="device.vars.en === '0' ? 'fa-check' : 'fa-times'"></i>
							</span>
							<span [innerHtml]="(device.vars.en === '0' ? 'PISCINA_OK_PRO' : 'REVISA_LA_PISCINA') | translate"></span>
						</small>
					</h3>
				</div>
				<div class="col-sm-4 hidden-xs">
					<div class="text-right" style="height: 35px">
						<div *ngIf="relaysStatus" class="speedIndicator">
							<div *ngIf="!device.personalized && relaysStatus.isConnected">
								<span *ngIf="+device.device_ep >= +device.default_eco">
									<i class="fa fa-2x fa-leaf text-success" aria-hidden="true"></i>
									<span><strong>{{ 'MODO_ECO' | translate }}</strong></span>
								</span>
								<span *ngIf="+device.device_ep < +device.default_eco">
									<i class="fa fa-2x fa-fighter-jet text-primary" aria-hidden="true"></i>
									<span><strong>{{ 'MODO_FAST' | translate }}</strong></span>
								</span>
							</div>

							<div *ngIf="device.personalized && relaysStatus.isConnected">
								<span *ngIf="device.personalized.vel !== device.device_ep && +device.device_ep >= +device.default_eco">
									<i class="fa fa-2x fa-leaf text-success" aria-hidden="true"></i>
									<span><strong>{{ 'MODO_ECO' | translate }}</strong></span>
								</span>
								<span *ngIf="device.personalized.vel !== device.device_ep && +device.device_ep < +device.default_eco">
									<i class="fa fa-2x fa-fighter-jet text-primary" aria-hidden="true"></i>
									<span><strong>{{ 'MODO_FAST' | translate }}</strong></span>
								</span>
								<span *ngIf="device.personalized && device.personalized.vel === device.device_ep">
									<i class="fa fa-2x fa-cogs text-info" aria-hidden="true"></i>
									<span><strong>{{ 'MODO_PERSONALIZADO' | translate }}</strong></span>
								</span>
							</div>

							<span *ngIf="!relaysStatus.isConnected">
								<i class="fa fa-2x fa-plug text-danger unconected" aria-hidden="true"></i>
								<span class="text-danger"><strong>{{ 'DISPOSITIVO_DESCONECTADO' | translate }}</strong></span>
							</span>
						</div>

						<div *ngIf="!relaysStatus" class="speedIndicator">
							<span>
								<span class="fadeIn text-info"><strong>{{ 'LOADING' | translate }}</strong></span>
							</span>
						</div>
					</div>
				</div>
				<div class="col-xs-12 visible-xs">
					<div class="text-left" style="height: 35px">
						<div *ngIf="relaysStatus" class="speedIndicatorXs">
							<span *ngIf="relaysStatus.isConnected && relaysStatus.ep === '060'">
								<i class="fa fa-2x fa-leaf text-success" aria-hidden="true"></i>
								<span><strong>{{ 'MODO_ECO' | translate }}</strong></span>
							</span>
							<span *ngIf="relaysStatus.isConnected && relaysStatus.ep !== '060'">
								<i class="fa fa-2x fa-fighter-jet text-primary" aria-hidden="true"></i>
								<span><strong>{{ 'MODO_FAST' | translate }}</strong></span>
							</span>
							<span *ngIf="!relaysStatus.isConnected">
								<i class="fa fa-2x fa-plug text-danger unconected" aria-hidden="true"></i>
								<span class="text-danger"><strong>{{ 'DISPOSITIVO_DESCONECTADO' | translate }}</strong></span>
							</span>
						</div>
						<div *ngIf="!relaysStatus" class="speedIndicatorXs">
							<span>
								<span class="fadeIn text-info"><strong>{{ 'LOADING' | translate }}</strong></span>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="device.cardIZ70" id="relays">
				<div *ngIf="device.relays.length > 0" id="relayContainer" class="panel">
					<div class="panel-heading"><span>{{ 'RELES' | translate | uppercase }}</span></div>
					<div class="panel-body text-center">
						<ng-template ngFor let-i="index" let-relay [ngForOf]="device.relays">
							<div clickable *ngIf="relay" class="relay" [ngClass]="device.series === 1 ? 'col-xs-6 col-sm-4 col-md-2' : 'col-xs-6 col-sm-3'" [routerLink]="['/devices/programmer', 'weekly', device.id, relay.id]">
								<div text-ellipsis><strong>{{ 'RELE' | translate | uppercase }} {{ i + 1 }}</strong></div>
								<div class="subtitle" text-ellipsis>{{ relay.nombre | translate | uppercase }}</div>
								<div class="icon"><img [src]="relay.icon"></div>
									<p [ngClass]="'text-' + states[relay.state].color">
										<strong>{{ relay.label | translate | uppercase }}</strong>
										<small *ngIf="relay.state === 'A'" [ngClass]="device.vars['a' + relay.sign[1]] === '1' ? 'text-success' : 'text-danger'">{{ device.vars['a' + relay.sign[1]] === '1' ? '(ON)' : '(OFF)' }}</small>
									</p>
								</div>
						</ng-template>
					</div>
				</div>
				<div class="row">
					<div *ngIf="device.series == 1" class="col-md-4">
						<div id="analogicSensorContainer" class="panel sensorContainerMinHeight">
							<div class="panel-heading"><span>{{ 'SENSORES_ANALOGICOS' | translate | uppercase }}</span></div>
							<div class="panel-body text-center">
								<ng-template ngFor let-key [ngForOf]="[1, 2]">
									<div class="sensor analogic col-xs-6">
										<div class="icon"><img src="/assets/images/vprofesional/reles/ar{{ key }}.png"></div>
											<p class="analogMeasure"><strong>{{ device['a' + key].measure || '-' }}</strong></p>
											<p class="text-info"><strong>{{ device['a'+key+'_unidad'] | translate | uppercase }}</strong></p>
											<p class="text-muted">{{ device['a' + key].name }}</p>
										</div>
								</ng-template>
							</div>
						</div>
					</div>
					<div [ngClass]="device.series == 1 ? 'col-md-8' : 'col-md-12'">
						<div id="digitalSensorContainer" class="panel sensorContainerMinHeight">
							<div class="panel-heading"><span>{{ 'ENTRADAS_DIGITALES' | translate | uppercase }}</span></div>
							<div class="panel-body text-center">
								<ng-template ngFor let-key [ngForOf]="[1, 2, 3, 4]">
									<div class="sensor digital col-xs-6 col-sm-3" [ngClass]="device['d' + key].value ? 'closed' : 'open'">
										<div class="icon">
											<img [src]="'/assets/images/device/' + (device.vars['d' + key] == '1' ? 'closed-circuit.png' : 'open-circuit.png')">
										</div>
											<p class="text-info"><strong>{{ device['d' + key].name }}</strong></p>
											<p>{{ (device['d' + key].value ? 'CERRADO' : 'ABIERTO') | translate }}</p>
										</div>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-9">
					<div *ngIf="(device.alarms | filter : 'value').length || (device.vars.ac === '0' && device.isDS2)" class="alert" [ngClass]="(device.vars.ac === '0' && device.isDS2 && (device.alarms | filter : 'value').length === 0) ? 'alert-info' : ' alert-danger'" style="position: relative" role="alert">
						<p>
							<strong>{{ ((device.alarms | filter : 'value').length > 0) ? ('ALARMAS' | translate) + ':' : (('RELE' | translate) + ' 1 ' + ('PARO_ORP' | translate)) }}</strong>
							<span class="text-danger" *ngFor="let alarm of device.alarms | filter : 'value'">
								<span>
									<i class="fa fa-fw" *ngIf="alertIcons[alarm.value]" [ngClass]="alertIcons[alarm.value]"></i>{{ alarm.label | translate }}
								</span>&nbsp;&nbsp;
							</span>
						</p>

						<div *ngIf="device && device.isDS2 && device.vars.ac === '0'" class="techniques hidden-xs">
							<div class="techniquesContainer">
								<div class="data">
									<ul>
										<li *ngIf="device.isElectrolysis">
											<p>Electrolysis OFF</p>
										</li>
										<li *ngIf="device.isUV">
											<p>UV OFF</p>
										</li>
										<li *ngIf="device.isORP">
											<p>CLorp OFF</p>
										</li>
										<li *ngIf="device.isPH">
											<p>pH OFF</p>
										</li>
										<li *ngIf="device.isPPM">
											<p>Clppm OFF</p>
										</li>
										<li>
											<p>Sensors OFF</p>
										</li>
										<li>
											<p>Poolstation ON</p>
										</li>
									</ul>
								</div>
								<div class="image">
									<div text-ellipsis>{{ device.relays[0].nombre | uppercase }}</div>
									<div class="imgContainer">
										<img src="/assets/images/device/relay_mcr2.svg" alt="">
									</div>
										<div [ngSwitch]="device.vars.mc">
											<div *ngSwitchCase="'A'">A (OFF)</div>
											<div *ngSwitchDefault>OFF</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="alert alert-danger techniquesXS visible-xs" *ngIf="device && device.isDS2 && device.vars.ac === '0'">
							<div class="row">
								<div class="col-xs-8 data">
									<div *ngIf="device.isElectrolysis">Electrolysis OFF</div>
									<div *ngIf="device.isUV">UV OFF</div>
									<div *ngIf="device.isORP">CLorp OFF</div>
									<div *ngIf="device.isPH">pH OFF</div>
									<div *ngIf="device.isPPM">Clppm OFF</div>
									<div>Sensors OFF</div>
									<div>Poolstation ON</div>
								</div>
								<div class="col-xs-4 image">
									<div class="imgContainer">
										<img src="/assets/images/device/relay_mcr2.svg" alt="">
									</div>
										<div style="display: inline-block">
											<div>{{ device.relays[0].nombre | uppercase }}</div>
											<div [ngSwitch]="device.vars.mc">
												<div *ngSwitchCase="'A'">A (OFF)</div>
												<div *ngSwitchDefault>OFF</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="timeMessage text-danger" *ngIf="device.status.value === 0 && !deviceOffHours.limitReached">
								{{ 'DESCONECTADO_MENOR_1' | translate }}{{ deviceOffHours.hours | number:'1.1-1' }}{{ 'DESCONECTADO_MENOR_2' | translate }}{{ device.max_off_hours }} {{ 'HORAS' | translate }}.
							</div>

							<div [ngSwitch]="device.series">
								<div *ngSwitchCase="1">

									<!-- ELECTROLYSIS -->
									<div *ngIf="device.electrolysis || device.electrolysisPH || device.electrolysisORP || device.electrolysisPPM" id="electrolysis">
										<div class="row">
											<h4 class="col-xs-6">{{ 'ELECTROLISIS' | translate }} <small>{{ device.electrolysisModel }}</small>
												<span class="fa-stack fa-md" [ngClass]="device.electrolysisStatus !== 0 ? 'text-success': 'text-danger'">
													<i class="fa fa-circle fa-stack-2x"></i>
													<i class="fa fa-stack-1x fa-plug fa-inverse"></i>
												</span>
											</h4>
											<div class="col-xs-6 text-right">
												<button [disabled]="saving || !device.isUpdated" class="btn" (click)="editing('electrolysis')" [ngClass]="(!edit.electrolysis) ? 'btn-primary' : 'btn-default'"><i class="fa fa-pencil"></i></button>
												<button *ngIf="device.status.value === 0" [disabled]="saving" class="btn btn-danger" (click)="selectFunctionToDisable('electrolysis')"><i class="fa fa-trash"></i></button>
											</div>
										</div>
										<table class="table table-bordered table-fixed">
											<thead>
												<tr>
													<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'CONFIGURACION' | translate | uppercase }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><strong>{{ 'PRODUCCION' | translate }}</strong></td>
													<td class="text-center">
														{{ (device.vars.pa !== '') ? ((device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.pa | number:'1.0-0') : '-') : '0' }}% &#09;
														<span *ngIf="device.vars.ws === 'H'" class="text-danger"><i class="fa fa-arrow-up fa-fw" aria-hidden="true"></i>{{ 'SALT' | translate }}</span>
														<span *ngIf="device.vars.ws === 'J'" class="text-danger"><i class="fa fa-arrow-circle-up fa-fw" aria-hidden="true"></i>{{ 'SALT' | translate }}</span>
														<span *ngIf="device.vars.ws === 'L'" class="text-danger"><i class="fa fa-arrow-down fa-fw" aria-hidden="true"></i>{{ 'SALT' | translate }}</span>
														<span *ngIf="device.vars.ws === 'N'" class="text-danger"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> {{ 'SALT' | translate }}</span> &#09;
														<span *ngIf="device.vars.cc === '1'" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>{{ 'CELL' | translate }}</span>
														<span *ngIf="device.vars.cc === '2'" class="text-danger"><i class="fa fa-exclamation-circle fa-fw" aria-hidden="true"></i>{{ 'CELL' | translate }}</span>
													</td>
													<td class="text-center">
														<!--<div *ngIf="!edit.electrolysis">{{ sn }}%</div>-->
														<div *ngIf="!edit.electrolysis">{{ (device.vars.sn !== '' && device.vars.sn !== '---' && device.vars.sn
																!== 'fop---')  ? (device.vars.sn
																| number:'1.0-0') : '-' }}%</div>
														<input-number *ngIf="edit.electrolysis" [value]="sn" size="xs" step="10" min="0" max="100" precision="0" (exit)="sn = $event" [append]="'%'"></input-number>
													</td>
												</tr>
												<tr *ngIf="device.internalPhStatus > 0">
													<td><strong>{{ 'PH' | translate }}</strong></td>
													<td class="text-center">
														<span *ngIf="device.vars.wp === '0'">{{ (device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '-' }}</span>
														<span *ngIf="device.vars.wp !== '0'" class="text-danger">
															<ng-container *ngIf="device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached)); then iconPH; else noIconPH"></ng-container>
															<ng-template #iconPH>
																<span>
																	<i class="fa" [ngClass]="{'fa-arrow-up': device.vars.wp === 'H', 'fa-arrow-circle-up': device.vars.wp === 'J', 'fa-arrow-down': device.vars.wp === 'L', 'fa-arrow-circle-down': device.vars.wp === 'N'}" aria-hidden="true"></i>
																	{{ device.vars.mp | number:'1.2' }}
																</span>
														</ng-template>
														<ng-template #noIconPH>-</ng-template>
														</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.electrolysis">{{ sp }}</div>
														<input-number *ngIf="edit.electrolysis" [value]="sp" size="xs" step="0.20" min="7" max="7.8" precision="2" (exit)="sp = $event"></input-number>
													</td>
												</tr>
												<tr *ngIf="device.internalOrpStatus > 0">
													<td><strong>{{ 'ORP' | translate }}</strong></td>
													<td class="text-center">
														<span *ngIf="device.vars.wo === '0'">{{ (device.vars.mo !== '' && device.vars.mo !== '---' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mo | number:'1.0-0') : '-' }} mV</span>
														<span *ngIf="device.vars.wo !== '0'" class="text-danger">
															<ng-container *ngIf="device.vars.mo !== '' && device.vars.mo !== '---' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached)); then iconORP; else noIconORP"></ng-container>
															<ng-template #iconORP>
																<span>
																	<i class="fa" [ngClass]="{'fa-arrow-up': device.vars.wo === 'H', 'fa-arrow-circle-up': device.vars.wo === 'J', 'fa-arrow-down': device.vars.wo === 'L', 'fa-arrow-circle-down': device.vars.wo === 'N'}" aria-hidden="true"></i>
																	{{ device.vars.mo | number:'1.0-0' }}
																</span>
														</ng-template>
														<ng-template #noIconORP>-</ng-template>
														</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.electrolysis">{{ (device.vars.so !== '' && device.vars.so !== '---') ? (device.vars.so | number:'1.0-0') : '-' }} mV</div>
														<input-number *ngIf="edit.electrolysis" [value]="so" size="xs" step="25" min="600" max="850" precision="0" (exit)="so = $event" [append]="'mV'"></input-number>
													</td>
												</tr>
												<tr *ngIf="device.electrolysisInternalPPM">
													<td><strong>{{ 'PPM' | translate }}</strong></td>
													<td class="text-center">
														<span *ngIf="device.vars.wh === '0'">{{ (device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '-' }}</span>
														<span *ngIf="device.vars.wh !== '0'" class="text-danger">
															<ng-container *ngIf="device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached)); then iconPPM; else noIconPPM"></ng-container>
															<ng-template #iconPPM>
																<span>
																	<i class="fa" [ngClass]="{'fa-arrow-up': device.vars.wh === 'H', 'fa-arrow-circle-up': device.vars.wh === 'J', 'fa-arrow-down': device.vars.wh === 'L', 'fa-arrow-circle-down': device.vars.wh === 'N'}" aria-hidden="true"></i>
																	{{ device.vars.mh | number:'1.2' }}
																</span>
														</ng-template>
														<ng-template #noIconPPM>-</ng-template>
														</span>
													</td>
													<td class="text-center">
														<div>{{ (device.vars.sh !== '' && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.sh | number:'1.2') : '-' }} ppm</div>
														<!--<div *ngIf="!edit.electrolysis">{{ (device.vars.sh !== '' && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.sh | number:'1.2-2') : '-' }} ppm</div> -->
														<!--<input-number *ngIf="edit.electrolysis" [value]="sh" size="xs" step="0.25" min="0" max="3.50" precision="2" (exit)="sh = $event" [append]="'ppm'"></input-number> -->
													</td>
												</tr>
												<tr *ngIf="device.internalOrpStatus > 0">
													<td>{{ 'SELECT_ORP_SET' | translate }}</td>
													<td class="text-center">
														<span *ngIf="device.vars.os === '1'">{{ 'PARADO_OR_PPM' | translate }}</span>
													</td>
													<td class="text-center">
														<span *ngIf="!edit.electrolysis">{{ ((device.vars.or === '0') ? 'DESHABILITADO' : 'HABILITADO') | translate }}</span>
														<span>
															<select *ngIf="edit.electrolysis" [(ngModel)]="device.vars.or" [value]="device.vars.or" class="form-control">
																<option value="0">{{ 'DESHABILITADO' | translate }}</option>
																<option value="1">{{ 'HABILITADO' | translate }}</option>
															</select>
														</span>
													</td>
												</tr>
												<tr>
													<td>{{ 'SELECT_CLEANING_TIME' | translate }}</td>
													<td class="text-center" style="text-transform: capitalize">
														{{ ((device.vars.cd === '0') ? 'POLARIZACION_DIRECTA' : 'POLARIZACION_INVERSA') | translate }}
													</td>
													<td class="text-center">
														<div *ngIf="edit.electrolysis">
															<select *ngIf="edit.electrolysis" [(ngModel)]="device.vars.ce" [value]="device.vars.ce" class="form-control">
																<option value="2">2h/2h</option>
																<option value="3">3h/3h</option>
															</select>
														</div>
														<div *ngIf="!edit.electrolysis">
															<div [ngSwitch]="device.vars.ce">
																<span *ngSwitchCase="'2'">2h/2h</span>
																<span *ngSwitchCase="'3'">3h/3h</span>
																<span *ngSwitchCase="'4'">4h/4h</span>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'SELECT_COVER_CONFIG' | translate }}</td>
													<td class="text-center">
														<span *ngIf="(device.vars.co === '1' || device.vars.co === '2') && (device.vars.sc === device.vars.sn && device.vars.sn !== device.vars.sv)">{{ 'CUBIERTA_ABIERTA' | translate }}</span>
														<span *ngIf="(device.vars.co === '1' || device.vars.co === '2') && (device.vars.sc === device.vars.sv && device.vars.sn !== device.vars.sv)">{{ 'CUBIERTA_CERRADA' | translate }}</span>
													</td>
													<td>
														<div class="row">
															<div *ngIf="edit.electrolysis || device.vars.co !== '0'" class="col-sm-6 text-center">
																<div *ngIf="edit.electrolysis">
																	<select *ngIf="edit.electrolysis" [(ngModel)]="device.vars.co" [value]="device.vars.co" class="form-control">
																		<option value="0">{{ 'COBERT_ENABLE_0' | translate}}</option>
																		<option value="1">{{ 'COBERT_ENABLE_1' | translate}}</option>
																		<option value="2">{{ 'COBERT_ENABLE_2' | translate}}</option>
																	</select>
																</div>
																<div *ngIf="!edit.electrolysis">
																	<span *ngIf="device.vars.co === '1'">{{ 'COBERT_ENABLE_1' | translate}}</span>
																	<span *ngIf="device.vars.co === '2'">{{ 'COBERT_ENABLE_2' | translate}}</span>
																</div>
															</div>
															<div class="text-center" [ngClass]="(edit.electrolysis || device.vars.co !== '0') ? 'col-sm-6' : 'col-xs-12'">
																<input-number *ngIf="edit.electrolysis && device.vars.co !== '0'" [value]="sv" size="xs" step="10" min="10" max="90" precision="0" (exit)="sv = $event" [append]="'%'"></input-number>
																<span *ngIf="!edit.electrolysis && device.vars.co !== '0'">{{ (device.vars.sv !== '' && device.vars.sv
																		!== '---') ? (device.vars.sv
																		| number: '1.0-0') : '0' }}%</span>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'SELECT_FLOW_SWITCH' | translate }}</td>
													<td class="text-center">
														<span *ngIf="device.vars.sf === '1'" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>Flow!</span>
														<span *ngIf="device.vars.sf === '2'" class="text-danger"><i class="fa fa-exclamation-circle fa-fw" aria-hidden="true"></i>Flow!</span>
													</td>
													<td class="text-center">
														<div class="row">
															<div *ngIf="!edit.electrolysis" class="col-sm-6 col-xs-12">
																{{ 'FLUJOSTATO' | translate }}
																<br> {{ ((!fs) ? 'DESHABILITADO' : 'HABILITADO') | translate }}
															</div>
															<div *ngIf="edit.electrolysis" class="col-sm-6 col-xs-12">{{ 'FLUJOSTATO' | translate }}
																<ui-switch *ngIf="edit.electrolysis" class="form-control" [(ngModel)]="fs" color="#43a9c9"></ui-switch>
															</div>
															<div *ngIf="!edit.electrolysis" class="col-sm-6 col-xs-12">
																{{ 'SENSOR_GAS' | translate }}
																<br> {{ ((!fe) ? 'DESHABILITADO' : 'HABILITADO') | translate }}
															</div>
															<div *ngIf="edit.electrolysis" class="col-sm-6 col-xs-12">{{ 'SENSOR_GAS' | translate }}
																<ui-switch *ngIf="edit.electrolysis" class="form-control" [(ngModel)]="fe" color="#43a9c9"></ui-switch>
															</div>
														</div>
													</td>
												</tr>
												<tr *ngIf="edit.electrolysis">
													<td colspan="3" class="text-center">
														<button class="btn btn-link btn-success" (click)="editModal.show()"><i class="fa fa-floppy-o fa-fw" aria-hidden="true"></i>{{ 'BT_GUARDAR' | translate }}</button>
														<button class="btn btn-link btn-danger" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<!-- PH -->
									<div *ngIf="device.hasExternalPh" id="ph">
										<div class="row">
											<h4 class="col-xs-6">
												{{ 'PH' | translate }}
												<small>{{ device.regModel.ph }}</small>
												<span class="fa-stack fa-md" [ngClass]="device.phStatus > 0 ? ((device.phStatus === 1) ? 'text-success': 'text-danger') : 'text-default'">
													<i class="fa fa-circle fa-stack-2x"></i>
													<i class="fa fa-stack-1x fa-plug fa-inverse"></i>
												</span>
											</h4>
											<div class="col-xs-6 text-right">
												<button [disabled]="saving || device.phStatus !== 1 || !device.isUpdated" class="btn" (click)="editing('rph')" [ngClass]="(!edit.rph) ? 'btn-primary' : 'btn-default'"><i class="fa fa-pencil"></i></button>
												<button *ngIf="device.status.value > 0 && device.phStatus === 2" [disabled]="saving" class="btn btn-danger" (click)="selectFunctionToDisable('rph')"><i class="fa fa-trash"></i></button>
											</div>
										</div>

										<p class="form-inline text-right" *ngIf="edit.rph && device.regModel.ph">
											<span class="form-group">
												<label>{{ 'MODELO_REGULADOR' | translate }}:</label>
												<select [(ngModel)]="device.vars.modelo_regulador" class="form-control">
													<option *ngFor="let option of regPh.options" [value]="option + ((option === 'PR-207') ? '' : regPh.secondReg)">{{ option }}</option>
												</select>
											</span>
										</p>

										<table class="table table-bordered table-fixed">
											<thead>
												<tr>
													<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'CONFIGURACION' | translate | uppercase }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><strong>{{ 'PH' | translate }}</strong></td>
													<td class="text-center">
														<span *ngIf="device.vars.wp === '0'">{{ (device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '-' }}</span>
														<span *ngIf="device.vars.wp !== '0'" class="text-danger">
															<ng-container *ngIf="device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached)); then externalIconPH; else externalNoIconPH"></ng-container>
															<ng-template #externalIconPH>
																<span>
																	<i class="fa" [ngClass]="{'fa-arrow-up': device.vars.wp === 'H', 'fa-arrow-circle-up': device.vars.wp === 'J', 'fa-arrow-down': device.vars.wp === 'L', 'fa-arrow-circle-down': device.vars.wp === 'N'}" aria-hidden="true"></i>
																	{{ device.vars.mp | number:'1.2' }}
																</span>
														</ng-template>
														<ng-template #externalNoIconPH>-</ng-template>
														</span>
													</td>
													<td class="text-center tdRel">
														<div *ngIf="!edit.rph">{{ sp }}</div>
														<div *ngIf="edit.rph && savingBadge" class="savingBadge text-success">
															<i class="fa fa-refresh fa-spin" aria-hidden="true"></i>
														</div>
														<input-number *ngIf="edit.rph" [value]="sp" size="xs" [step]="device.vars.mm !== '--------' ? 0.20 : 0.10" min="7" max="7.8" precision="2" (exit)="sp = $event"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'DOSIFICACION' | translate }}</td>
													<td class="text-center">
														{{ (device.vars.gp == 0) ? ('BOMBA_PARADA' | translate) : ('ON' | translate) }}
														<span *ngIf="device.vars.pe == 1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> PumpStop</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.rph">
															<div [ngSwitch]="device.vars.fp">
																<div *ngSwitchCase="'0'">{{ 'OFF' | translate }}</div>
																<div *ngSwitchCase="'A'">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</div>
															</div>
														</div>
														<select *ngIf="edit.rph" [(ngModel)]="device.vars.fp" [value]="device.vars.fp" class="form-control">
															<option value="0">{{ 'OFF' | translate }}</option>
															<option value="A">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</option>
														</select>
													</td>
												</tr>
												<tr>
													<td>{{ 'TIEMPO_DOSIFICACION' | translate }}</td>
													<td class="text-center">{{ device.vars.td || 0 | number:'1.0-0' }} {{ 'MIN' | translate }}</td>
													<td class="text-center">
														<div *ngIf="!edit.rph">{{ tm }} {{ 'MIN' | translate }}</div>
														<input-number *ngIf="edit.rph" [value]="tm" size="xs" step="1" min="0" max="120" precision="0" (exit)="tm = $event" [append]="'MIN' | translate"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'PORCENTAJE_SALIDA' | translate }}</td>
													<td class="text-center">{{ device.vars.jp / 100 | percent }}</td>
													<td class="text-center">
														<div *ngIf="!edit.rph">{{ xp }} %</div>
														<input-number *ngIf="edit.rph" [value]="xp" size="xs" step="25" min="25" max="100" precision="0" (exit)="xp = $event" [append]="'%'"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'ACIDO' | translate }} / {{ 'BASE' | translate }}</td>
													<td colspan="2" class="text-center">{{ (device.vars.ap == 0 ? 'ACIDO' : 'BASE') | translate }}</td>
												</tr>
												<tr>
													<td>{{ 'MODO_INTELLIGENT' | translate }}</td>
													<td class="text-center">{{ (device.vars.ip == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
													<td class="text-center">
														<ui-switch *ngIf="edit.rph" class="form-control" [(ngModel)]="device.vars.ip" color="#43a9c9"></ui-switch>
													</td>
												</tr>
												<tr>
													<td>{{ 'SENSOR_NIVEL_DEPO' | translate }}</td>
													<td class="text-center">
														<div *ngIf="device.vars.op == 1 && device.vars.dp == 0">{{ 'NIVEL' | translate }} {{ 'OK' | translate }}</div>
														<div *ngIf="device.vars.op == 1 && device.vars.dp == 1" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> {{ 'DEPOT_VACIO' | translate }}</div>
													</td>
													<td class="text-center">
														<div class="text-center" *ngIf="device.vars.op == 0">{{ 'DISABLED' | translate }}</div>
														<div class="text-center" *ngIf="device.vars.op == 1">{{ 'ENABLED' | translate }}</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'HP_HISTERESIS' | translate }}</td>
													<td class="text-center">
														<div *ngIf="device.vars.hp == 0">C0 ({{ 'DOS_SEC' | translate }})</div>
														<div *ngIf="device.vars.hp == 1">C1 ({{ 'DOS_MIN' | translate }})</div>
													</td>
													<td>
													</td>
												</tr>
												<tr *ngIf="edit.rph">
													<td colspan="3" class="text-center">
														<button class="btn btn-link btn-success" (click)="editModal.show()"><i class="fa fa-floppy-o fa-fw" aria-hidden="true"></i>{{ 'BT_GUARDAR' | translate }}</button>
														<button class="btn btn-link btn-danger" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<!-- ORP -->
									<div *ngIf="device.hasExternalOrp" id="orp">
										<div class="row">
											<h4 class="col-xs-6">
												{{ 'ORP' | translate }}
												<small>{{ device.regModel.orp }}</small>
												<span class="fa-stack fa-md" [ngClass]="device.orpStatus > 0 ? ((device.orpStatus === 1) ? 'text-success' : 'text-danger') : 'text-default'">
													<i class="fa fa-circle fa-stack-2x"></i>
													<i class="fa fa-stack-1x fa-plug fa-inverse"></i>
												</span>
											</h4>
											<div class="col-xs-6 text-right">
												<button [disabled]="saving || device.orpStatus !== 1 || !device.isUpdated" class="btn" (click)="editing('orp')" [ngClass]="(!edit.orp) ? 'btn-primary' : 'btn-default'"><i class="fa fa-pencil"></i></button>
												<button *ngIf="device.status.value > 0 && device.orpStatus === 2" [disabled]="saving" class="btn btn-danger" title="{{ 'BORRAR' | translate }}" (click)="selectFunctionToDisable('rmv')"><i class="fa fa-trash"></i></button>
											</div>
										</div>

										<p class="form-inline text-right" *ngIf="edit.orp && device.regModel.orp">
											<span class="form-group">
												<label>{{ 'MODELO_REGULADOR' | translate }}:</label>
												<select [(ngModel)]="device.vars.modelo_regulador" class="form-control">
													<option *ngFor="let option of regOrp.options" [value]="regOrp.secondReg + option">{{ option }}</option>
												</select>
											</span>
										</p>

										<table class="table table-bordered table-fixed">
											<thead>
												<tr>
													<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'CONFIGURACION' | translate | uppercase }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><strong>{{ 'ORP' | translate }}</strong></td>
													<td class="text-center" [ngClass]="{'text-danger': device.vars.wo !== '0'}">
														<i *ngIf="device.vars.wo === 'H' || device.vars.wo === 'J'" class="fa fa-arrow-up"></i>
														<i *ngIf="device.vars.wo === 'L' || device.vars.wo === 'N'" class="fa fa-arrow-down"></i>
														<span>{{ ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached)) ? device.vars.mo + ' mV' : '-' }}</span>
													</td>
													<td class="text-center tdRel">
														<div *ngIf="!edit.orp">{{ (device.vars.so !== '' && device.vars.so !== '---') ? (device.vars.so | number:'1.0-0') : '-' }} mV</div>
														<div *ngIf="edit.orp && savingBadge" class="savingBadge text-success">
															<i class="fa fa-refresh fa-spin" aria-hidden="true"></i>
														</div>
														<input-number *ngIf="edit.orp" [value]="so" size="xs" step="25" min="600" max="850" precision="0" (exit)="so = $event" [append]="'mV'"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'DOSIFICACION' | translate }}</td>
													<td class="text-center">
														{{ 'BOMBA' | translate }} {{ (device.vars.go == 0 ? 'DETENIDA' : 'DOSIFICANDO') | translate }}
														<span *ngIf="device.vars.oe == 1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> PumpStop</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.orp">
															<div [ngSwitch]="device.vars.fo">
																<div *ngSwitchCase="'0'">{{ 'OFF' | translate }}</div>
																<div *ngSwitchCase="'A'">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</div>
															</div>
														</div>
														<select *ngIf="edit.orp" [(ngModel)]="device.vars.fo" [value]="device.vars.fo" class="form-control">
															<option value="0">{{ 'OFF' | translate }}</option>
															<option value="A">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</option>
														</select>
													</td>
												</tr>
												<tr>
													<td>{{ 'TIEMPO_DOSIFICACION' | translate }}</td>
													<td class="text-center">{{ device.vars.od || 0 | number:'1.0-0' }} {{ 'MIN' | translate }}</td>
													<td class="text-center">
														<div *ngIf="!edit.orp">{{ om }} {{ 'MIN' | translate }}</div>
														<input-number *ngIf="edit.orp" [value]="om" size="xs" step="1" min="0" max="120" precision="0" (exit)="om = $event" [append]="'MIN' | translate"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'PORCENTAJE_SALIDA' | translate }}</td>
													<td class="text-center">{{ device.vars.jo / 100 | percent }}</td>
													<td class="text-center">
														<div *ngIf="!edit.orp">{{ xo }} %</div>
														<input-number *ngIf="edit.orp" [value]="xo" size="xs" step="1" min="0" max="100" precision="0" (exit)="xo = $event" [append]="'%'"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'REDUCTOR_LARGO' | translate }} / {{ 'OXIDANTE_LARGO' | translate }}</td>
													<td colspan="2" class="text-center">{{ (device.vars.ao == 0 ? 'REDUCTOR_LARGO' : 'OXIDANTE_LARGO') | translate }}</td>
												</tr>
												<tr>
													<td>{{ 'MODO_INTELLIGENT' | translate }}</td>
													<td class="text-center">{{ (device.vars.io == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
													<td class="text-center">
														<ui-switch *ngIf="edit.orp" class="form-control" [(ngModel)]="device.vars.io" color="#43a9c9"></ui-switch>
													</td>
												</tr>
												<tr>
													<td>{{ 'DOSIFICACION' | translate }}</td>
													<td class="text-center">{{ (device.gs_rmv == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
													<td class="text-center">{{ device.vars.set_gs_rmv }}</td>
												</tr>
												<tr>
													<td>{{ 'SENSOR_NIVEL_DEPO' | translate }}</td>
													<td class="text-center">
														<div *ngIf="device.vars.oo == 1 && device.vars.do == 0">{{ 'NIVEL' | translate }} {{ 'OK' | translate }}</div>
														<div *ngIf="device.vars.oo == 1 && device.vars.do == 1" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> {{ 'DEPOT_VACIO' | translate }}</div>
													</td>
													<td class="text-center">
														<div class="text-center" *ngIf="device.vars.oo == 0">{{ 'DISABLED' | translate }}</div>
														<div class="text-center" *ngIf="device.vars.oo == 1">{{ 'ENABLED' | translate }}</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'HP_HISTERESIS' | translate }}</td>
													<td class="text-center">
														<div *ngIf="device.vars.ho == 0">C0 ({{ 'DOS_SEC' | translate }})</div>
														<div *ngIf="device.vars.ho == 1">C1 ({{ 'DOS_MIN' | translate }})</div>
													</td>
													<td></td>
												</tr>
												<tr *ngIf="edit.orp">
													<td colspan="3" class="text-center">
														<button class="btn btn-link btn-success" (click)="editModal.show()"><i class="fa fa-floppy-o fa-fw" aria-hidden="true"></i>{{ 'BT_GUARDAR' | translate }}</button>
														<button class="btn btn-link btn-danger" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									 <!--PPM-->
									<div *ngIf="device.hasExternalPpm" id="ppm">
										<div class="row">
											<h4 class="col-xs-6">
												{{ 'PPM' | translate }}
												<small>{{ device.regModel.ppm }}</small>
												<span class="fa-stack fa-md" [ngClass]="device.ppmStatus > 0 ? ((device.ppmStatus === 1) ? 'text-success' : 'text-danger') : 'text-default'">
													<i class="fa fa-circle fa-stack-2x"></i>
													<i class="fa fa-stack-1x fa-plug fa-inverse"></i>
												</span>
											</h4>
											<div class="col-xs-6 text-right">
												<button [disabled]="saving || device.ppmStatus !== 1 || !device.isUpdated" class="btn" (click)="editing('rcl')" [ngClass]="(!edit.rcl) ? 'btn-primary' : 'btn-default'"><i class="fa fa-pencil"></i></button>
												<button *ngIf="device.status.value > 0 && device.ppmStatus === 2" [disabled]="saving" class="btn btn-danger" (click)="selectFunctionToDisable('rcl')"><i class="fa fa-trash"></i></button>
											</div>
										</div>

										<p class="form-inline text-right" *ngIf="edit.rcl && device.regModel.ppm">
											<span class="form-group">
												<label>{{ 'MODELO_REGULADOR' | translate }}:</label>
												<select [(ngModel)]="device.vars.modelo_regulador" class="form-control">
													<option *ngFor="let option of regPpm.options" [value]="regPpm.secondReg + option">{{ option }}</option>
												</select>
											</span>
										</p>

										<table class="table table-bordered table-fixed">
											<thead>
												<tr>
													<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'CONFIGURACION' | translate | uppercase }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><strong>{{ 'PPM' | translate }}</strong></td>
													<td class="text-center">
														<span *ngIf="device.vars.wh === '0'">{{ (device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '-' }} ppm</span>
														<span *ngIf="device.vars.wh !== '0'" class="text-danger">
															<ng-container *ngIf="device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached)); then externalIconPPM; else externalNoIconPPM"></ng-container>
															<ng-template #externalIconPPM>
																<span>
																	<i class="fa" [ngClass]="{'fa-arrow-up': device.vars.wh === 'H', 'fa-arrow-circle-up': device.vars.wh === 'J', 'fa-arrow-down': device.vars.wh === 'L', 'fa-arrow-circle-down': device.vars.wh === 'N'}" aria-hidden="true"></i>
																	{{ device.vars.mh | number:'1.2' }}
																</span>
														</ng-template>
														<ng-template #externalNoIconPPM>-</ng-template>
														</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.rcl">{{ sh }} ppm</div>
														<input-number *ngIf="edit.rcl" [value]="sh" size="xs" [step]="device.vars.mm !== '--------' ? 0.25 : 0.10" min="0" max="3.5" precision="2" (exit)="sh = $event" [append]="'ppm'"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'DOSIFICACION' | translate }}</td>
													<td class="text-center">
														{{ (device.vars.gh == 0) ? ('BOMBA_PARADA' | translate) : ('ON' | translate) }}
														<span *ngIf="device.vars.he == 1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> PumpStop</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.rcl">
															<div [ngSwitch]="device.vars.fh">
																<div *ngSwitchCase="'0'">{{ 'OFF' | translate }}</div>
																<div *ngSwitchCase="'A'">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</div>
															</div>
														</div>
														<select *ngIf="edit.rcl" [(ngModel)]="device.vars.fh" [value]="device.vars.fh" class="form-control">
															<option value="0">{{ 'OFF' | translate }}</option>
															<option value="A">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</option>
														</select>
													</td>
												</tr>
												<tr>
													<td>{{ 'TIEMPO_DOSIFICACION' | translate }}</td>
													<td class="text-center">{{ device.vars.hd || 0 | number:'1.0-0' }} {{ 'MIN' | translate }}</td>
													<td class="text-center">
														<div *ngIf="!edit.rcl">{{ hm }} {{ 'MIN' | translate }}</div>
														<input-number *ngIf="edit.rcl" [value]="hm" size="xs" step="1" min="0" max="120" precision="0" (exit)="hm = $event" [append]="'MIN' | translate"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'PORCENTAJE_SALIDA' | translate }}</td>
													<td class="text-center">{{ device.vars.jh / 100 | percent }}</td>
													<td class="text-center">
														<div *ngIf="!edit.rcl">{{ xh }} %</div>
														<input-number *ngIf="edit.rcl" [value]="xh" size="xs" step="1" min="0" max="100" precision="0" (exit)="xh = $event" [append]="'%'"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'OXIDANTE_REDUCTOR' | translate }}</td>
													<td colspan="2" class="text-center">{{ (device.vars.ah == 0 ? 'REDUCTOR_LARGO' : 'OXIDANTE_LARGO') | translate }}</td>
												</tr>
												<tr>
													<td>{{ 'MODO_INTELLIGENT' | translate }}</td>
													<td class="text-center">{{ (device.vars.ih == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
													<td class="text-center">
														<ui-switch *ngIf="edit.rcl" class="form-control" [(ngModel)]="device.vars.ih" color="#43a9c9"></ui-switch>
													</td>
												</tr>
												<tr>
													<td>{{ 'SENSOR_NIVEL_DEPO' | translate }}</td>
													<td class="text-center">
														<div *ngIf="device.vars.oh == 1 && device.vars.dc == 0">{{ 'NIVEL' | translate }} {{ 'OK' | translate }}</div>
														<div *ngIf="device.vars.oh == 1 && device.vars.dc == 1" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> {{ 'DEPOT_VACIO' | translate }}</div>
													</td>
													<td class="text-center">
														<div class="text-center" *ngIf="device.vars.oh == 0">{{ 'DISABLED' | translate }}</div>
														<div class="text-center" *ngIf="device.vars.oh == 1">{{ 'ENABLED' | translate }}</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'HP_HISTERESIS' | translate }}</td>
													<td class="text-center">
														<div *ngIf="device.vars.hh == 0">C0 ({{ 'DOS_SEC' | translate }})</div>
														<div *ngIf="device.vars.hh == 1">C1 ({{ 'DOS_MIN' | translate }})</div>
													</td>
													<td>
													</td>
												</tr>
												<tr *ngIf="edit.rcl">
													<td colspan="3" class="text-center">
														<button class="btn btn-link btn-success" (click)="editModal.show()"><i
																class="fa fa-floppy-o fa-fw"
																aria-hidden="true"></i>{{ 'BT_GUARDAR' | translate }}</button>
														<button class="btn btn-link btn-danger" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<!-- PR3XX -->
									<div *ngIf="device.isPanel3XX" id="panel3XX">
										<div class="row">
											<h4 class="col-xs-6">{{ 'PANEL_REGULACION_TITULO' | translate }} <small>{{  device.prModel  }}</small></h4>
											<div class="col-xs-6 text-right">
												<button [disabled]="saving || device.pr3xxStatus === 2 || device.pr3xxStatus === 4 || device.pr3xxStatus === 6 || !device.isUpdated" class="btn" (click)="editing('pr3xx')" [ngClass]="(!edit.pr3xx) ? 'btn-primary' : 'btn-default'"><i class="fa fa-pencil"></i></button>
												<button *ngIf="device.status.value > 0 && (device.pr3xxStatus === 2 || device.pr3xxStatus === 4 || device.pr3xxStatus === 6)" [disabled]="saving" class="btn btn-danger" (click)="selectFunctionToDisable('pr3xx')"><i class="fa fa-trash"></i></button>
											</div>
										</div>
										<table class="table table-bordered table-fixed">
											<thead>
												<tr>
													<th>{{ 'PARAMETRO' | translate | uppercase }}</th>
													<th>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
													<th>{{ 'CONFIGURACION' | translate | uppercase }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><strong>{{ 'SALIDA_HABILITADA' | translate }}</strong></td>
													<td class="text-center">{{ (device.gs_pr300 == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
													<td></td>
												</tr>
												<tr>
													<td><strong>{{ 'TEMPERATURA' | translate }}</strong></td>
													<td class="text-center">{{ (device.status.value !== 0  || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.ta | temperature: loggedUser.unit_temp) : '-' }}</td>
													<td class="text-center"></td>
												</tr>
												<tr>
													<td><strong>{{ 'CONDUCTIVIDAD' | translate }}</strong></td>
													<td class="text-center">{{ (device.status.value !== 0  || (device.status.value === 0 &&
															!deviceOffHours.limitReached)) && device.specialStatus.prodUp30 ? (device.vars.cn.substring(0, 4) |
															number:'1.0-1') : '-' }} {{ 'g/L' }}</td>
													<td class="text-center"></td>
												</tr>
											</tbody>

											<thead>
												<tr>
													<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
													<th text-ellipsis class="tdRel">{{ 'CONFIGURACION' | translate | uppercase }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><strong>{{ 'PH' | translate }}</strong></td>
													<td class="text-center">
														<span *ngIf="device.vars.wp === '0'">{{ (device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '-' }}</span>
														<span *ngIf="device.vars.wp === 'H'" class="text-danger"><i class="fa fa-arrow-up fa-fw" aria-hidden="true"></i>{{ (device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '-' }}</span>
														<span *ngIf="device.vars.wp === 'J'" class="text-danger"><i class="fa fa-arrow-circle-up fa-fw" aria-hidden="true"></i>{{ (device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '-' }}</span>
														<span *ngIf="device.vars.wp === 'L'" class="text-danger"><i class="fa fa-arrow-down fa-fw" aria-hidden="true"></i>{{ (device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '-' }}</span>
														<span *ngIf="device.vars.wp === 'N'" class="text-danger"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> {{ (device.vars.mp !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '-' }}</span> &#09;
														<span *ngIf="device.vars.pe === '1'" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>{{ 'BOMBA_PARADA' | translate }}</span>
														<span *ngIf="device.vars.pe === '2'" class="text-danger"><i class="fa fa-exclamation-circle fa-fw" aria-hidden="true"></i>{{ 'BOMBA_PARADA' | translate }}</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.pr3xx">{{ sp }}</div>
														<input-number *ngIf="edit.pr3xx" [value]="sp" size="xs" step="0.10" min="7" max="7.8" precision="2" (exit)="sp = $event"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'DOSIFICACION' | translate }}</td>
													<td class="text-center">
														{{ (device.vars.gp == 0) ? ('BOMBA_PARADA' | translate) : ('ON' | translate) }}
														<span *ngIf="device.vars.pe == 1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> PumpStop</span>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.pr3xx">
															<div [ngSwitch]="device.vars.fp">
																<div *ngSwitchCase="'0'">{{ 'OFF' | translate }}</div>
																<div *ngSwitchCase="'A'">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</div>
															</div>
														</div>
														<select *ngIf="edit.pr3xx" [(ngModel)]="device.vars.fp" [value]="device.vars.fp" class="form-control">
															<option value="0">{{ 'OFF' | translate }}</option>
															<option value="A">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</option>
														</select>
													</td>
												</tr>
												<tr>
													<td>{{ 'TIEMPO_DOSIFICACION' | translate }}</td>
													<td class="text-center">{{ device.vars.td || 0 | number:'1.0-0' }} {{ 'MIN' | translate }}</td>
													<td class="text-center">
														<div *ngIf="!edit.pr3xx">{{ tm }} {{ 'MIN' | translate }}</div>
														<input-number *ngIf="edit.pr3xx" [value]="tm" size="xs" step="1" min="0" max="120" precision="0" (exit)="tm = $event" [append]="'MIN' | translate"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'PORCENTAJE_SALIDA' | translate }}</td>
													<td class="text-center">{{ device.vars.jp / 100 | percent }}</td>
													<td class="text-center">
														<div>{{ xp }} %</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'ACIDO' | translate }} / {{ 'BASE' | translate }}</td>
													<td colspan="2" class="text-center">{{ (device.vars.ap == 0 ? 'ACIDO' : 'BASE') | translate }}</td>
												</tr>
												<tr>
													<td>{{ 'MODO_INTELLIGENT' | translate }}</td>
													<td class="text-center">{{ (device.vars.ip == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
													<td class="text-center">
														<ui-switch *ngIf="edit.pr3xx" class="form-control" [(ngModel)]="device.vars.ip" color="#43a9c9"></ui-switch>
													</td>
												</tr>
												<tr>
													<td>{{ 'SENSOR_NIVEL_DEPO' | translate }}</td>
													<td class="text-center">
														<div *ngIf="device.vars.op == 1 && device.vars.dp == 0">{{ 'NIVEL' | translate }} {{ 'OK' | translate }}</div>
														<div *ngIf="device.vars.op == 1 && device.vars.dp == 1" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> {{ 'DEPOT_VACIO' | translate }}</div>
													</td>
													<td class="text-center">
														<div *ngIf="!edit.pr3xx" class="text-center">{{ ((device.vars.op === 0) ? 'DISABLED' : 'ENABLED') | translate }}</div>
														<div *ngIf="edit.pr3xx" class="text-center">
															<ui-switch *ngIf="edit.pr3xx" class="form-control" [(ngModel)]="op" color="#43a9c9"></ui-switch>
														</div>
													</td>
												</tr>
											</tbody>

											<ng-container *ngIf="!device.isPanel310 && (device.isPanel300 || device.isPanel320)">
												<thead>
													<tr>
														<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
														<th text-ellipsis>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
														<th text-ellipsis>{{ 'CONFIGURACION' | translate | uppercase }}</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td><strong>{{ 'ORP' | translate }}</strong></td>
														<td class="text-center">
															<span>{{ ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.mo + 'mV') : '-' }}</span>
															<i *ngIf="device.wo === 'H' || device.wo === 'J'" class="fa fa-arrow-up text-danger"></i>
															<i *ngIf="device.wo === 'L' || device.wo === 'N'" class="fa fa-arrow-down text-danger"></i>
														</td>
														<td class="text-center">
															<div *ngIf="!edit.pr3xx">{{ (device.vars.so !== '' && device.vars.so !== '---') ? (device.vars.so | number:'1.0-0') : '-' }} mV</div>
															<input-number *ngIf="edit.pr3xx" [value]="so" size="xs" step="10" min="600" max="850" precision="0" (exit)="so = $event" [append]="'mV'"></input-number>
														</td>
													</tr>
													<!--<tr>-->
														<!--<td>{{ 'DOSIFICACION' | translate }}</td>-->
														<!--<td class="text-center">-->
															<!--{{ device.vars.go == 0 ? ('BOMBA_PARADA' | translate) : (('BOMBA' | translate) + ' ' + ('DOSIFICANDO' | translate)) }}-->
															<!--<span *ngIf="device.vars.oe == 1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> PumpStop</span>-->
														<!--</td>-->
														<!--<td class="text-center">-->
															<!--<div *ngIf="!edit.pr3xx">-->
																<!--<div [ngSwitch]="device.vars.fo">-->
																	<!--<div *ngSwitchCase="'0'">{{ 'OFF' | translate }}</div>-->
																	<!--<div *ngSwitchCase="'A'">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</div>-->
																<!--</div>-->
															<!--</div>-->
															<!--<select *ngIf="edit.pr3xx" [(ngModel)]="device.vars.fo" [value]="device.vars.fo" class="form-control">-->
																<!--<option value="0">{{ 'OFF' | translate }}</option>-->
																<!--<option value="A">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</option>-->
															<!--</select>-->
														<!--</td>-->
													<!--</tr>-->
													<tr>
														<td>{{ 'TIEMPO_DOSIFICACION' | translate }}</td>
														<td class="text-center">{{ device.vars.od || 0 | number:'1.0-0' }} {{ 'MIN' | translate }}</td>
														<td class="text-center">
															<div *ngIf="!edit.pr3xx">{{ om }} {{ 'MIN' | translate }}</div>
															<input-number *ngIf="edit.pr3xx" [value]="om" size="xs" step="1" min="0" max="120" precision="0" (exit)="om = $event" [append]="'MIN' | translate"></input-number>
														</td>
													</tr>
													<tr>
														<td>{{ 'PORCENTAJE_SALIDA' | translate }}</td>
														<td class="text-center">{{ device.vars.jo / 100 | percent }}</td>
														<td class="text-center">
															<div>{{ xo }} %</div>
														</td>
													</tr>
													<tr>
														<td>{{ 'OXIDANTE_REDUCTOR' | translate }}</td>
														<td colspan="2" class="text-center">{{ (device.vars.ao == 0 ? 'REDUCTOR_LARGO' : 'OXIDANTE_LARGO') | translate }}</td>
													</tr>
													<tr>
														<td>{{ 'MODO_INTELLIGENT' | translate }}</td>
														<td class="text-center">{{ (device.vars.io == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
														<td class="text-center">
															<ui-switch *ngIf="edit.pr3xx" class="form-control" [(ngModel)]="device.vars.io" color="#43a9c9"></ui-switch>
														</td>
													</tr>
													<tr>
														<td>{{ 'SENSOR_NIVEL_DEPO' | translate }}</td>
														<td class="text-center">
															<div *ngIf="device.vars.oo == 1 && device.vars.do == 0">{{ 'NIVEL' | translate }} {{ 'OK' | translate }}</div>
															<div *ngIf="device.vars.oo == 1 && device.vars.do == 1" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> {{ 'DEPOT_VACIO' | translate }}</div>
														</td>
														<td class="text-center">
															<div *ngIf="!edit.pr3xx" class="text-center">{{ ((device.vars.oo === 0) ? 'DISABLED' : 'ENABLED') | translate }}</div>
															<div *ngIf="edit.pr3xx" class="text-center">
																<ui-switch *ngIf="edit.pr3xx" class="form-control" [(ngModel)]="oo" color="#43a9c9"></ui-switch>
															</div>
														</td>
													</tr>
												</tbody>
											</ng-container>

											<ng-container *ngIf="device.isPanel310 || device.isPanel320">
												<thead>
													<tr>
														<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
														<th text-ellipsis>{{ 'ESTADO_SENYAL' | translate | uppercase }}</th>
														<th text-ellipsis>{{ 'CONFIGURACION' | translate | uppercase }}</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td><strong>{{ 'PPM' | translate }}</strong></td>
														<td class="text-center">
															<span *ngIf="device.vars.wh === '0'">{{ (device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '-' }} ppm</span>
															<span *ngIf="device.vars.wh === 'H'" class="text-danger"><i class="fa fa-arrow-up fa-fw" aria-hidden="true"></i>{{ (device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '-' }} ppm</span>
															<span *ngIf="device.vars.wh === 'J'" class="text-danger"><i class="fa fa-arrow-circle-up fa-fw" aria-hidden="true"></i>{{ (device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '-' }} ppm</span>
															<span *ngIf="device.vars.wh === 'L'" class="text-danger"><i class="fa fa-arrow-down fa-fw" aria-hidden="true"></i>{{ (device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '-' }} ppm</span>
															<span *ngIf="device.vars.wh === 'N'" class="text-danger"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i> {{ (device.vars.mh !== '' && ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '-' }} ppm</span> &#09;
															<span *ngIf="device.vars.pe === '1'" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>{{ 'BOMBA_PARADA' | translate }}</span>
															<span *ngIf="device.vars.pe === '2'" class="text-danger"><i class="fa fa-exclamation-circle fa-fw" aria-hidden="true"></i>{{ 'BOMBA_PARADA' | translate }}</span>
														</td>
														<td class="text-center">
															<div *ngIf="!edit.pr3xx">{{ sh }} ppm</div>
															<input-number *ngIf="edit.pr3xx" [value]="sh" size="xs" step="0.10" min="0" max="3.5" precision="2" (exit)="sh = $event" [append]="'ppm'"></input-number>
														</td>
													</tr>
													<!--<tr>-->
														<!--<td>{{ 'DOSIFICACION' | translate }}</td>-->
														<!--<td class="text-center">-->
															<!--{{ (device.vars.gh == 0) ? ('BOMBA_PARADA' | translate) : ('ON' | translate) }}-->
															<!--<span *ngIf="device.vars.he == 1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> PumpStop</span>-->
														<!--</td>-->
														<!--<td class="text-center">-->
															<!--<div *ngIf="!edit.pr3xx">-->
																<!--<div [ngSwitch]="device.vars.fh">-->
																	<!--<div *ngSwitchCase="'0'">{{ 'OFF' | translate }}</div>-->
																	<!--<div *ngSwitchCase="'A'">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</div>-->
																<!--</div>-->
															<!--</div>-->
															<!--<select *ngIf="edit.pr3xx" [(ngModel)]="device.vars.fh" [value]="device.vars.fh" class="form-control">-->
																<!--<option value="0">{{ 'OFF' | translate }}</option>-->
																<!--<option value="A">{{ 'MODO' | translate }} {{ 'AUTO' | translate }}</option>-->
															<!--</select>-->
														<!--</td>-->
													<!--</tr>-->
													<tr>
														<td>{{ 'TIEMPO_DOSIFICACION' | translate }}</td>
														<td class="text-center">{{ device.vars.hd || 0 | number:'1.0-0' }} {{ 'MIN' | translate }}</td>
														<td class="text-center">
															<div *ngIf="!edit.pr3xx">{{ hm }} {{ 'MIN' | translate }}</div>
															<input-number *ngIf="edit.pr3xx" [value]="hm" size="xs" step="1" min="0" max="120" precision="0" (exit)="hm = $event" [append]="'MIN' | translate"></input-number>
														</td>
													</tr>
													<tr>
														<td>{{ 'PORCENTAJE_SALIDA' | translate }}</td>
														<td class="text-center">{{ device.vars.jh / 100 | percent }}</td>
														<td class="text-center">
															<div>{{ xh }} %</div>
														</td>
													</tr>
													<tr>
														<td>{{ 'OXIDANTE_REDUCTOR' | translate }}</td>
														<td colspan="2" class="text-center">{{ (device.vars.ah == 0 ? 'REDUCTOR_LARGO' : 'OXIDANTE_LARGO') | translate }}</td>
													</tr>
													<tr>
														<td>{{ 'MODO_INTELLIGENT' | translate }}</td>
														<td class="text-center">{{ (device.vars.ih == 0 ? 'DISABLED' : 'ENABLED') | translate }}</td>
														<td class="text-center">
															<ui-switch *ngIf="edit.pr3xx" class="form-control" [(ngModel)]="device.vars.ih" color="#43a9c9"></ui-switch>
														</td>
													</tr>
													<tr>
														<td>{{ 'SENSOR_NIVEL_DEPO' | translate }}</td>
														<td class="text-center">
															<div *ngIf="device.vars.oh == 1 && device.vars.dc == 0">{{ 'NIVEL' | translate }} {{ 'OK' | translate }}</div>
															<div *ngIf="device.vars.oh == 1 && device.vars.dc == 1" class="text-danger"><i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> {{ 'DEPOT_VACIO' | translate }}</div>
														</td>
														<td class="text-center">
															<div *ngIf="!edit.pr3xx" class="text-center">{{ ((device.vars.oh === 0) ? 'DISABLED' : 'ENABLED') | translate }}</div>
															<div *ngIf="edit.pr3xx" class="text-center">
																<ui-switch *ngIf="edit.pr3xx" class="form-control" [(ngModel)]="oh" color="#43a9c9"></ui-switch>
															</div>
														</td>
													</tr>
												</tbody>
											</ng-container>
											<tfoot *ngIf="edit.pr3xx">
												<tr>
													<td colspan="3" class="text-center">
														<button class="btn btn-success btn-link" (click)="editModal.show()"><i class="fa fa-floppy-o fa-fw" aria-hidden="true"></i>{{ 'BT_GUARDAR' | translate }}</button>
														<button class="btn btn-danger btn-link" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>

									<!-- LUMIPLUS -->
									<div *ngIf="device.isLumiplus" id="lumiplus">
										<div class="row">
											<h4 class="col-xs-6">{{ 'LUMIPLUS' | translate }}</h4>
											<div class="col-xs-6 text-right">
												<button [disabled]="saving || !device.isUpdated" class="btn" (click)="editing('lumiplus')" [ngClass]="(!edit.lumiplus) ? 'btn-primary' : 'btn-default'"><i class="fa fa-pencil"></i></button>
												<button *ngIf="device.vars.au === '2'" [disabled]="saving" class="btn btn-danger" (click)="selectFunctionToDisable('lumiplus')"><i class="fa fa-trash"></i></button>
											</div>
										</div>
										<table class="table table-bordered table-fixed">
											<thead>
												<tr>
													<th text-ellipsis>{{ 'PARAMETRO' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'VALORES' | translate | uppercase }}</th>
													<th text-ellipsis>{{ 'CONFIGURACION' | translate | uppercase }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{{ 'MODO' | translate  }}</td>
													<td class="text-center">{{ (edit.lumiplus) ? ((tempValues.mu === 'A' ? 'AUTO' : (tempValues.mu === 0) ? 'OFF' : 'ON') | translate) : ((device.vars.mu === 'A' ? 'AUTO' : (device.vars.mu === 0) ? 'OFF' : 'ON') | translate) }}</td>
													<td>
														<div *ngIf="edit.lumiplus">
															<button class="btn btn-primary btn-block" [routerLink]="['/devices/lumiplus/', 'weekly', device.id]"><i class="fa fa-pencil fa-fw"></i>{{ 'EDITAR' | translate }}</button>
														</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'COLOR' | translate  }}</td>
													<td class="text-center">
														<div class="colorWindow" *ngIf="device.vars.cu" [ngStyle]="(edit.lumiplus) ? {'backgroundColor': colors[tempValues.cu].color} : {'backgroundColor': colors[device.vars.cu].color}"></div>
														<div *ngIf="device.vars.cu">{{ (edit.lumiplus) ? colors[tempValues.cu].name : colors[device.vars.cu].name }}</div>
													</td>
													<td class="text-center">
														<div *ngFor="let index of ['8', 'A', '7', '1', '9', '4', '2', 'B', '3', '5', '6', 'C']" class="col-xs-6 col-sm-3 col-md-2" no-padding>
															<span class="colorWindow" [ngClass]="{'active' : index === device.vars.cu }" [ngStyle]="{'backgroundColor': colors[index].color, 'cursor' : (edit.lumiplus) ? 'pointer' : 'initial'}" (click)="(edit.lumiplus) ? device.vars.cu = index : null"></span>
														</div>
													</td>
												</tr>
												<tr>
													<td>{{ 'SECUENCIA' | translate }}</td>
													<td class="text-center">{{ ((edit.lumiplus) ? secuence[tempValues.su] : secuence[device.vars.su]) | translate }}</td>
													<td>
														<select *ngIf="edit.lumiplus" [(ngModel)]="device.vars.su" [value]="device.vars.su" class="form-control">
															<option value="1">{{ secuence[1] }}</option>
															<option value="2">{{ secuence[2] }}</option>
															<option value="3">{{ secuence[3] }}</option>
															<option value="4">{{ secuence[4] }}</option>
															<option value="5">{{ secuence[5] }}</option>
															<option value="6">{{ secuence[6] }}</option>
															<option value="7">{{ secuence[7] }}</option>
															<option value="8">{{ secuence[8] }}</option>
														</select>
													</td>
												</tr>
												<tr>
													<td>{{ 'VELOCIDAD' | translate }}</td>
													<td class="text-center">{{ (edit.lumiplus) ? tempValues.vu : device.vars.vu }}</td>
													<td>
														<input-number *ngIf="edit.lumiplus" [value]="vu" size="xs" step="1" min="1" max="8" precision="0" (exit)="vu = $event"></input-number>
													</td>
												</tr>
												<tr>
													<td>{{ 'TIPO_DE_FUNCIONAMIENTO' | translate }}</td>
													<td class="text-center">{{ ((edit.lumiplus) ? (tempValues.tu === 'S' ? 'SECUENCIA' : 'COLOR') : (device.vars.tu === 'S' ? 'SECUENCIA' : 'COLOR')) | translate }}</td>
													<td>
														<select *ngIf="edit.lumiplus" [(ngModel)]="device.vars.tu" [value]="device.vars.tu" class="form-control">
															<option value="C">{{ 'COLOR' | translate }}</option>
															<option value="S">{{ 'SECUENCIA' | translate }}</option>
														</select>
													</td>
												</tr>
												<tr *ngIf="edit.lumiplus">
													<td colspan="3" class="text-center">
														<button class="btn btn-link btn-success" (click)="editModal.show()"><i class="fa fa-floppy-o fa-fw"
																aria-hidden="true"></i>{{ 'BT_GUARDAR' | translate }}</button>
														<button class="btn btn-link btn-danger" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<div *ngSwitchCase="2">
									<ds2 [dev]="device" [deviceOffHours]="deviceOffHours" (wait)="waitToLoad($event)"></ds2>
								</div>
							</div>
						</div>

						<div class="col-md-3">
							<div *ngIf="loggedUser.id_role == ('' | roles).PROFESIONAL" class="panel">
								<div class="panel-heading">
									<h3 class="panel-title text-center">{{ 'FICHA_CLIENTE' | translate }}</h3>
								</div>
								<div class="panel-body text-center">
									<div>{{ device.user.nombre }}</div>
									<button text-ellipsis class="btn btn-primary" [routerLink]="['/messages/write', device.user.id]"><i class="fa fa-paper-plane fa-fw" aria-hidden="true"></i>{{ device.user.usr }}</button>
									<div>
										<a target="_blank" href="http://maps.apple.com/?q={{device.user.direccion}}+{{device.user.id_loc}}+{{device.user.id_pais}}">
									<i class="fa fa-fw fa-map-marker" aria-hidden="true"></i>{{ device.user.direccion }}<br/>{{ device.user.id_loc }}
								</a>
									</div>
								</div>
							</div>

							<div class="panel">
								<div class="panel-heading">
									<h3 class="panel-title text-center">{{ 'FECHA' | translate }}</h3>
								</div>
								<div class="panel-body text-center">
									<div><strong>{{ date | amDateFormat:'DD/MM/YYYY' }}</strong></div>
									<div><small> {{  date | amDateFormat:'A'  }}</small> <strong> {{ date | amDateFormat:'h:mm' }}</strong></div>
								</div>
							</div>

							<div class="panel">
								<div class="panel-heading">
									<h3 class="panel-title text-center">{{ 'TIEMPO' | translate }}</h3>
								</div>
								<div *ngIf="weather" class="panel-body text-center position-relative">
									<p class="position-top-right text-muted text-muted" style="margin: 16px;">
										<strong *ngIf="loggedUser.unit_temp === 'C'">{{ weather.data.current_condition[0].temp_C }} ºC</strong>
										<strong *ngIf="loggedUser.unit_temp === 'F'">{{ weather.data.current_condition[0].temp_F }} ºF</strong>
									</p>
									<p><img [src]="weatherIcons[weather.data.current_condition[0].weatherCode]"></p>
										<div>
											<div class="text-center">
												<p class="text-info"><strong>{{ device.id_loc }}</strong></p>
												<p class="text-muted" *ngIf="loggedUser.unit_vel === 'KMH'">{{ 'VIENTO' | translate }}: {{weather.data.current_condition[0].winddir16Point}} {{weather.data.current_condition[0].windspeedKmph}} Km/h</p>
												<p class="text-muted" *ngIf="loggedUser.unit_vel !== 'KMH'">{{ 'VIENTO' | translate }}: {{weather.data.current_condition[0].winddir16Point}} {{weather.data.current_condition[0].windspeedMiles}} mpH</p>
												<p class="text-muted">{{ 'HUMEDAD' | translate }}: {{weather.data.current_condition[0].humidity}}%</p>
											</div>

											<table class="text-center" style="width: 100%">
												<tr>
													<td>
														<p>{{ prevision[0][0] | translate }}</p>
														<span class="text-info">{{ prevision[0][2] }}</span>
														<span class="text-muted">{{ prevision[0][1] }}</span>
													</td>
													<td>
														<p>{{ prevision[1][0] | translate }}</p>
														<span class="text-info">{{ prevision[1][2] }}</span>
														<span class="text-muted">{{ prevision[1][1] }}</span>
													</td>
													<td>
														<p>{{ prevision[2][0] | translate }}</p>
														<span class="text-info">{{ prevision[2][2] }}</span>
														<span class="text-muted">{{ prevision[2][1] }}</span>
													</td>
												</tr>
											</table>

										</div>
								</div>
								<div *ngIf="!weather" class="text-center">
									<div><img width="18%" src="/assets/images/wave.svg"></div>
									</div>
								</div>

								<div class="panel">
									<div class="panel-heading">
										<h3 id="historics" class="panel-title text-center">{{ 'HISTORICOS' | translate }}</h3>
									</div>
									<div class="panel-body">
										<ul class="list">
											<li *ngIf="device.isPH">
												<a [routerLink]="['/devices/stat', device.id, 'mp' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_ph.png"></i>{{ 'PH' | translate }}</a>
											</li>
											<li *ngIf="device.isORP">
												<a [routerLink]="['/devices/stat', device.id, 'mo' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_orp.png"></i>{{ 'ORP' | translate }}</a>
											</li>
											<li *ngIf="device.isPPM">
												<a [routerLink]="['/devices/stat', device.id, 'mh' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_ppm.png"></i>{{ 'PPM' | translate }}</a>
											</li>
											<li *ngIf="device.isPanel3XX || (device.isDS2 && device.electrolysis)">
												<a [routerLink]="['/devices/stat', device.id, 'cn' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_conductividad.png"></i>{{ 'GRL' | translate }}</a>
											</li>
											<li *ngIf="device.isPanel3XX || device.isDS2">
												<a [routerLink]="['/devices/stat', device.id, 'ta' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_temperatura.png"></i>{{ 'TEMPERATURA' | translate }}</a>
											</li>
											<li *ngIf="device.electrolysis || device.electrolysisORP || device.electrolysisPPM">
												<a [routerLink]="['/devices/stat', device.id, 'pro' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_produccion.png"></i>{{ 'PRODUCCION' | translate }}</a>
											</li>
											<li *ngIf="device.cardIZ70 && device.series !== 2">
												<a [routerLink]="['/devices/stat', device.id, 'a1' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_analogico.png"></i>{{ device.a1.name }}</a>
											</li>
											<li *ngIf="device.cardIZ70 && device.series !== 2">
												<a [routerLink]="['/devices/stat', device.id, 'a2' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_analogico.png"></i>{{ device.a2.name }}</a>
											</li>
											<hr *ngIf="device.isPH || device.isORP || device.isPPM">
											<li *ngIf="device.isPH || device.isORP || device.isPPM">
												<a [routerLink]="['/devices/stat', device.id, 'all' ]"><i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_grafica.png"></i>{{ 'VS_CHART' | translate }}</a>
											</li>
										</ul>
									</div>
								</div>
								<div class="panel">
									<div class="panel-heading">
										<h3 class="panel-title text-center">{{ 'HISTORICO_DE_ACCIONES' | translate }}</h3>
									</div>
									<div class="panel-body">
										<ul class="list">
											<li>
												<a [routerLink]="['/devices/logs', device.id]">
										<i class="fa fa-fw fa-img"><img src="/assets/images/vprofesional/historicos/historico_historial.png"></i>{{ 'HISTORICO_DE_ACCIONES' | translate }}
									</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div *ngIf="action == 'add' || action == 'edit' || action == 'register'" class="container">
						<ul *ngIf="action == 'register'" class="nav nav-pills nav-justified nav-wizard">
							<li role="presentation" class="disabled"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO1' | translate}}</a></li>
							<li role="presentation" class="disabled"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO2' | translate}}</a></li>
							<li role="presentation" class="active"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO3' | translate}}</a></li>
							<li role="presentation" class="disabled"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO4' | translate}}</a></li>
						</ul>

						<div>
							<fieldset *ngIf="action == 'add' || action == 'register'">
								<legend>{{ 'TARJETA_POOLSTATION' | translate }}</legend>
                <div class="form-group" *ngIf="action == 'add' && (loggedUser.id_role === ('' | roles).ADMIN || loggedUser.id_role ===
                ('' | roles).PROPIETARIO ||
                loggedUser.id_role === ('' | roles).SAT)">
                  <label>{{ 'NUMERO_SERIE' | translate }}</label>
                  <input [(ngModel)]="device.ns" class="form-control" maxlength="13">
                </div>
                <label>{{ 'SELECCION_CODIGO_O_MAC' | translate }}</label>
                <div class="DivBoxGray">
                  <div class="form-group" *ngIf="loggedUser.id_role == ('' | roles).ADMIN">
                    <label>{{ 'CODIGO_ACTIVACION' | translate }}</label>
                    <ng-template #popContentActivationCode><div class="PopOverTexto"></div><img src="/assets/images/01.png"></ng-template>

                    <span *ngIf="loggedUser.id_role != ('' | roles).ADMIN" type="button" class="btn btn-outline-secondary mr-2 LinkAyuda" placement="right"
												[ngbPopover]="popContentActivationCode">
                      +Ayuda
                    </span>
                    <input [(ngModel)]="device.cod_activacion"  (ngModelChange)="onChangeActivationCode($event, action)" class="form-control">

                  </div>
                  <div class="form-group" *ngIf="action == 'add' && (loggedUser.id_role === ('' | roles).ADMIN || loggedUser.id_role ===
                  ('' | roles).PROPIETARIO || loggedUser.id_role === ('' | roles).SAT)">
                    <label>{{ 'DIRECCION_MAC' | translate }}</label>
                  
					<span  *ngIf="loggedUser.id_role != ('' | roles).ADMIN">
					<!-- 	+Ayuda
						<br> -->
						<ng-template #popContentActivationCode><div class="PopOverTexto"></div><img src="/assets/images/01.png"></ng-template>

						<span type="button" class="btn btn-outline-secondary mr-2 LinkAyuda" style="text-decoration: underline" placement="right" [ngbPopover]="popContentActivationCode">
						  Option 1
						</span>

					</span>
				
					
					<ng-template #popContentActivationCode><div class="PopOverTexto"></div><img src="/assets/images/02.png"></ng-template>
					<span *ngIf="loggedUser.id_role != ('' | roles).ADMIN"type="button" class="btn btn-outline-secondary mr-2 LinkAyuda" style="padding-left: 0;" placement="right" [ngbPopover]="popContentActivationCode">
                    <span  *ngIf="loggedUser.id_role == ('' | roles).ADMIN"> +Ayuda </span> <span style="text-decoration: underline"
												*ngIf="loggedUser.id_role != ('' | roles).ADMIN">Option 2 </span>
                    </span>

                    <input [(ngModel)]="device.register.mac" (ngModelChange)="onChangeMAC($event, action)" class="form-control" maxlength="12">
                  </div>
                </div>
								<div class="form-group" *ngIf="action == 'add' && (loggedUser.id_role === ('' | roles).ADMIN || loggedUser.id_role ===
								('' | roles).SAT)">
									<label>{{ 'TIPO' | translate }}</label>
									<select class="form-control" [(ngModel)]="deviceType">
										<option value="OTHERS">PS001/02 ( 6 {{ 'RELES' | translate }} )</option>
										<option value="DS2">Domotic Series 2 ( 4 {{ 'RELES' | translate }} )</option>
									</select>
								</div>
								<div class="form-group" *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO">
									<label>{{ 'ALIAS' | translate }}</label>
									<input [disabled]="device.ns === null || device.ns === ''" [(ngModel)]="device.alias" class="form-control">
								</div>
							</fieldset>

							<fieldset *ngIf="action == 'edit'">
								<legend>{{ 'TARJETA_POOLSTATION' | translate }}</legend>
								<div class="form-group" *ngIf="loggedUser.id_role != ('' | roles).PROFESIONAL">
									<label>{{ 'DIRECCION_MAC' | translate }}</label>
									<input disabled [value]="device.register?.mac" class="form-control">
								</div>
								<div class="form-group">
									<label>{{ 'NUMERO_SERIE' | translate }}</label>
									<input disabled [(ngModel)]="device.ns" class="form-control">
								</div>
								<div class="form-group" *ngIf="loggedUser.id_role != ('' | roles).PROFESIONAL">
									<label>{{ 'CODIGO_ACTIVACION' | translate }}</label>
									<input disabled [(ngModel)]="device.cod_activacion" class="form-control">
								</div>
								<div class="form-group">
									<label>{{ 'MODELO_SISTEMA' | translate }}</label>
									<div disabled class="form-control">
										<span *ngFor="let model of device.model">{{ model }} </span>
									</div>
								</div>
								<div class="form-group">
									<label>{{ 'NOMBRE_DISPOSITIVO' | translate }}</label>
									<input [disabled]="saving" [(ngModel)]="device.alias" class="form-control">
								</div>
							</fieldset>

							<fieldset *ngIf="(action === 'edit') || action === 'register' || (action === 'add' && loggedUser.id_role === ('' | roles).PROPIETARIO)">
								<div *ngIf="(action === 'edit' && loggedUser.id_role != ('' | roles).PROFESIONAL) || action !== 'edit'">
									<legend>{{ 'UBICACION_DISPOSITIVO' | translate }}</legend>
									<p >{{ 'TEXTO_UBICACION' | translate }}:</p>
									<div class="input-group">
										<input [disabled]="saving" (keypress)="($event.keyCode == 13) ? searchLocation() : null;" class="form-control" type='text' [(ngModel)]="search" />
										<span class="input-group-btn">
											<button [disabled]="saving" class="btn btn-primary" (click)="searchLocation()">{{ 'BUSCAR' | translate | uppercase }}</button>
										</span>
									</div>
								</div>
								<br>
								<agm-map style="height: 350px; width: 100%" [latitude]="map.lat" [longitude]="map.lng" [disableDefaultUI]="false" [zoomControl]="true" (mapClick)="mapClicked($event)">
									<agm-marker [latitude]="marker.lat" [longitude]="marker.lng" [markerDraggable]="false"></agm-marker>
								</agm-map>
								<br>
								<div class="form-group">
									<label>{{ 'PAIS' | translate }}</label>
									<input disabled [ngModel]="device.id_pais" class="form-control">
								</div>
								<div class="form-group">
									<label>{{ 'LOCALIDAD' | translate }}</label>
									<input [(ngModel)]="device.id_loc" class="form-control" [disabled]="!((action === 'edit' && loggedUser.id_role != ('' | roles).PROFESIONAL) || action !== 'edit')">
								</div>
								<div class="form-group">
									<label>{{ 'ZONA_HORARIA' | translate }}</label>
									<select [disabled]="saving || !((action === 'edit' && loggedUser.id_role != ('' | roles).PROFESIONAL) || action !== 'edit')" class="form-control" [(ngModel)]="timezone" (change)="changeTimezone($event.target.value)">
										<option value="Europe">{{ 'TZ_EUROPE' | translate }}</option>
										<option value="America">{{ 'TZ_AMERICA' | translate }}</option>
										<option value="Australia">{{ 'TZ_AUSTRALIA' | translate }}</option>
										<option value="Asia">{{ 'TZ_ASIA' | translate }}</option>
										<option value="Pacific">{{ 'TZ_PACIFIC' | translate }}</option>
										<option value="Indian">{{ 'TZ_INDIA' | translate }}</option>
										<option value="Africa">{{ 'TZ_AFRICA' | translate }}</option>
										<option value="Artic">{{ 'TZ_ARCTIC' | translate }}</option>
										<option value="Antarctica">{{ 'TZ_ANTARCTICA' | translate }}</option>
										<option value="Atlantic">{{ 'TZ_ATLANTIC' | translate }}</option>
										<option value="Other">{{ 'TZ_OTHER' | translate }}</option>
									</select>
									<br>
									<select [disabled]="saving || !((action === 'edit' && loggedUser.id_role != ('' | roles).PROFESIONAL) || action !== 'edit')" class="form-control" [(ngModel)]="device.timezone">
										<ng-container *ngIf="timezones">
											<option *ngFor="let tz of timezones[timezone] | keys" [value]="tz?.key">{{ tz?.value }}</option>
										</ng-container>
									</select>
								</div>
							</fieldset>
						</div>
						<p>
							<button [disabled]="saving || loggedUser.isDemo" *ngIf="loggedUser.id_role !== ('' | roles).SAT" class="btn btn-primary"
									(click)="save()">
								<i *ngIf="saving" class="fa fa-spinner fa-spin fa-fw"></i>{{ 'BT_GUARDAR' | translate }}
							</button>
							<button [disabled]="saving" class="btn btn-danger" [routerLink]="['/devices']" title="{{ 'DESCARTAR_CAMBIOS' | translate  }}">{{ 'BT_VOLVER' | translate }}</button>
						</p>
					</div>
				</div>

				<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
					<div *ngIf="device" class="modal-dialog">
						<div class="modal-content">
							<div *ngIf="device.status.value !== 0" class="modal-header">
								<button class="close" aria-hidden="true" (click)="editModal.hide()">&times;</button>
								<h4 class="modal-title">{{ 'EDITAR' | translate }}</h4>
							</div>
							<div class="modal-body">
								{{ 'PREGUNTA_GUARDAR_CAMBIOS' | translate }}
							</div>
							<div class="modal-footer">
								<button class="btn btn-primary" (click)="editValues()">{{ 'ACEPTAR' | translate }}</button>
								<button class="btn btn-danger" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
							</div>
						</div>
						<div *ngIf="device.status.value === 0" class="modal-content">
							<div class="modal-header">
								<button class="close" aria-hidden="true" (click)="editModal.hide()">&times;</button>
								<h4 class="modal-title">{{ 'EDITAR' | translate }}</h4>
							</div>
							<div class="modal-body">
								{{ 'ERR_DOMOTIC_DESCONECTADO' | translate }}
							</div>
							<div class="modal-footer">
								<button class="btn btn-danger" (click)="cancelEdit()">{{ 'BT_CANCELAR' | translate }}</button>
							</div>
						</div>
					</div>
				</div>

				<div bsModal #deleteFunctionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteFunctionModal" aria-hidden="true">
					<div class="modal-dialog modal-sm">
						<div class="modal-content">
							<div class="modal-header">
								<button class="close" aria-hidden="true" (click)="deleteFunctionModal.hide()">&times;</button>
							</div>
							<div class="modal-body text-center">
								<h4 class="modal-title">{{ 'BT_ELIMINAR' | translate }} {{ 'FUNCIONES' | translate }}?</h4>
							</div>
							<div class="modal-footer">
								<button class="btn btn-danger" (click)="disableFunction()">{{ 'BT_ELIMINAR' | translate }}</button>
								<button class="btn btn-primary" (click)="deleteFunctionModal.hide()">{{ 'BT_CANCELAR' | translate }}</button>
							</div>
						</div>
					</div>
				</div>

</main>
