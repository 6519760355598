import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'app/AppConfig';


//services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth.service';

@Component({
	selector: 'twofactors',
	templateUrl: 'twofactors.component.html',
    styleUrls: ['twofactors.component.scss']
})
export class TwoFactorsComponent implements OnInit {

    code: string;
    url = AppConfig.HOME_API + '/session/generateQR2fa';
    imageUrl: string;

    constructor(
        private _route: ActivatedRoute,
        private _sanitizer: DomSanitizer){}

    public ngOnInit() {
        this._route.queryParams
            .subscribe(params => {
            this.code = params.code;
            this.imageUrl = this.url + "?code=" + encodeURIComponent(this.code);
      });

    }

    public sanitizeUrl(url: string): SafeUrl {
        return this._sanitizer.bypassSecurityTrustUrl(url)
    }
}