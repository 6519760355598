<header ps-header>
	<h2>{{ 'PRIVACIDAD_TITLE' | translate }}</h2>
</header>
<main class="container-fluid">
	<div class="container">
		<h3>{{ 'PRIVACY_HEADER' | translate }}</h3>
		<p [innerHTML]="'INTRO_TEXTO' | translate"></p>
		<h3>{{ 'COMPROMISE_HEADER' | translate }}</h3>
		<div [innerHTML]="'COMPROMISO_TEXTO' | translate" class="dots"></div>
		<div [innerHTML]="'COMPROMISO_TEXTO_2' | translate" class="dotsABC"></div>
		<h3>{{ '3_COMUNICACIONES' | translate }}</h3>
		<div [innerHTML]="'COMUNICACIONES_TEXTO' | translate"></div>
		<h3>{{ '4_DECISIONES' | translate }}</h3>
		<div [innerHTML]="'DECISIONES_TEXTO' | translate"></div>
		<h3>{{ '5_DURACION' | translate }}</h3>
		<div [innerHTML]="'DURACION_TEXTO' | translate"></div>
		<h3>{{ '6_DERECHOS' | translate }}</h3>
		<div [innerHTML]="'DERECHOS_TEXTO' | translate"></div>
		<h3>{{ '7_CONFIDENCIALIDAD' | translate }}</h3>
		<div [innerHTML]="'CONFIDENCIALIDAD_TEXTO' | translate"></div>
		<h3>{{ '8_REDES' | translate }}</h3>
		<div [innerHTML]="'REDES_TEXTO' | translate"></div>
	</div>
</main>
