<header ps-header [device]="device">
	<h2>{{ 'CONFIG_ALARM' | translate }}
      <small><strong>{{ device?.ns }}</strong></small>
      <small> - {{ device?.alias }}</small>
  </h2>
</header>
<main class="container">
	<div *ngIf="device">
		<table class="table table-bordered table-striped">
			<thead>
				<tr>
					<th>{{ 'ESTADO' | translate }}</th>
					<th>{{ 'NOMBRE' | translate }}</th>
					<th>{{ 'SITUACION' | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="actions">
						<span class="fa-stack fa-lg" [ngClass]="{fadeIn: !device.status.value}">
							<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
							<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
						</span>
					</td>
					<td>
						<p>{{ device.alias | translate }}</p>
					</td>
					<td>
						<p>{{ device.id_loc }}</p>
					</td>
				</tr>
			</tbody>
		</table>
		<fieldset>
			<legend>{{ 'CONFIG_ALARM' | translate }}</legend>
			<div class="checkbox">
				<label><input type="checkbox" [disabled]="saving" [(ngModel)]="device.alarma_dia">{{ 'ALARMAS_NOTIFICACION' | translate }}</label>
			</div>
			<div class="checkbox">
				<label><input type="checkbox" [disabled]="saving" [(ngModel)]="device.notificar_alarma">{{ 'ALARMAS_NOTIFICACION2' | translate }}</label>
			</div>
			<div>
				<span>{{ 'ALARMAS_MAIL_LABEL' | translate }}</span>
				<button style="margin-left: 5px;" class="btn btn-primary" (click)="openNotifications()">{{ 'BT_ALARMAS_MAIL' | translate }}</button>
			</div>
			<div>
				<span class="timeLabel">{{ 'RETRASO_ALARMAS' | translate }}</span>
				<select [disabled]="saving" [(ngModel)]="device.retraso_alarmas" class="form-control timeSelect">
					<option value="5">5 {{ 'MINUTOS' | translate }}</option>
					<option value="10">10 {{ 'MINUTOS' | translate }}</option>
					<option value="15">15 {{ 'MINUTOS' | translate }}</option>
					<option value="20">20 {{ 'MINUTOS' | translate }}</option>
				</select>
			</div>
		</fieldset>
		<p class="text-danger" *ngIf="device.vars.vt == '001'" [innerHTML]="'ALARMAS_NO_COMPATIBLES_VERSION1' | translate"></p>
		<br>
		<p class="text-left">
			<button [disabled]="device.vars.vt == '001' || saving" class="btn btn-info" (click)="save()">{{ 'BT_GUARDAR' | translate }}</button>
			<button [disabled]="saving" class="btn btn-danger" onclick="history.back()" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
		</p>
	</div>
</main>

<div bsModal #notificationsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="notificationsModal" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button class="close" aria-hidden="true" (click)="notificationsModal.hide()">&times;</button>
				<h4 class="modal-title">{{ 'ALARMAS_MAIL_LABEL' | translate }}</h4>
			</div>
			<div class="modal-body text-center" *ngIf="notificationsTmp">
				<div class="row">
					<div class="col-xs-6 text-left">
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.sf">{{ 'ALARM_FLU' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.wp">{{ 'HIGH_LOW_PH' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.dp">{{ 'DEPO_PH' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.pe">{{ 'PUMPSTOP_PH' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.wo">{{ 'HIGH_LOW_ORP' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.do">{{ 'DEPO_ORP' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.oe">{{ 'PUMPSTOP_ORP' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.wh">{{ 'HIGH_LOW_PPM' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.dc">{{ 'DEPO_PPM' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.he">{{ 'CLORO_PUMP_STOP' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.cc">{{ 'ALARMA_CELULA' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.ws">{{ 'ALARM_CONDUCTIVIDAD' | translate }}</label>
						</div>
					</div>
					<div class="col-xs-6 text-left">
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.an">{{ 'ALARMA_PRE' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.bu">{{ 'ALARMA_BALA_UV' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.fu">{{ 'ALARMA_FU_UV' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.bp">{{ 'ALARMA_FU_PH' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.cp">{{ 'ALARMA_BOMBA_PH' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.bo">{{ 'ALARMA_FU_ORP' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.po">{{ 'ALARMA_BOMBA_ORP' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.bh">{{ 'ALARMA_FU_PPM' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.ch">{{ 'ALARMA_BOMBA_PPM' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.at">{{ 'ALARMA_TEMP' | translate }}</label>
						</div>
						<div class="checkbox">
							<label><input type="checkbox" [disabled]="saving" [(ngModel)]="notificationsTmp.as">{{ 'ALARM_SAL' | translate }}</label>
						</div>
					</div>
					<div class="col-xs-12 text-center">
						<button class="btn btn-link" (click)="selectAll()">{{ 'SELECT_ALL' | translate }}</button>
						<button class="btn btn-link" (click)="deSelectAll()">{{ 'DESELECT_ALL' | translate }}</button>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-primary" (click)="saveNotifications()">{{ 'OK' | translate }}</button>
				<button class="btn btn-danger" (click)="notificationsModal.hide()">{{ 'BT_CANCELAR' | translate }}</button>
			</div>
		</div>
	</div>
</div>
