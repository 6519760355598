<div *ngIf="totalPbas">
	<span *ngIf="totalPbas" class="badge badge-info">{{ totalPbas }}</span>
	<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
</div>

<p class="text-right" *ngIf="pbas">
	<button class="btn btn-primary" (click)="edit()">{{ 'ADD_PBA' | translate }}</button>
</p>

<table-sorter [disableBtn]="disableButtons" [loading]="!pbas" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limit = $event; loadItems()" (prev)="page = $event; loadItems($event);" (search)="searchText = $event" (next)="page = $event; loadItems($event)" (pageChange)="loadItems($event)">
	<div *ngIf="pbas" class="visible-xs">

		<div *ngIf="pbasFilterValue">
			<div>
				<label>{{ 'PBA' | translate }}</label>
				<ss-multiselect-dropdown [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged()"></ss-multiselect-dropdown>
			</div>
			<hr>
		</div>

		<div *ngFor="let pba of pbas; let i = index" class="panel panel-default">
			<div class="panel-body">
				<div>
					<strong>{{ 'PBA' | translate }}:</strong> {{ pba.name }}
				</div>
				<div>
					<strong>{{ 'TIPO' | translate }}:</strong> {{ (pba.type !== 'OTH') ? (pba.type | uppercase) : '-' }}
				</div>
				<div>
					<strong>{{ 'BOOTLOADER' | translate }}: </strong> {{ (pba.bootloader ? 'SI' : 'NO') | translate }}
				</div>
				<div>
					<strong>{{ 'IMAGEN_INFO' | translate }}: </strong> {{ (pba.image ? 'SI' : 'NO') | translate }}
				</div>
				<hr *ngIf="pba.observations">
				<div *ngIf="pba.observations">
					<div><strong>{{ 'NOTAS' | translate }}</strong></div>
					<div>{{ pba.observations }}</div>
				</div>
				<hr>
				<div class="text-center">
					<button [disabled]="disableButtons" class="btn btn-primary btn-link" (click)="edit(i)">
						{{ 'EDITAR' | translate }}
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger btn-link" (click)="tempPba = pba; deletePba.show()">
						{{ 'BORRAR' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<table *ngIf="pbas" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
		<thead>
			<tr>
				<th>{{ 'PBA' | translate }}</th>
				<th>{{ 'TIPO' | translate }}</th>
				<th>{{ 'TIENE_BOOTLOADER' | translate }}</th>
				<th>{{ 'TIENE_IMAGEN_INFO' | translate }}</th>
				<th>{{ 'NOTAS' | translate }}</th>
				<th>{{ 'ACCIONES' | translate }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="pbasFilterValue">
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged()"></ss-multiselect-dropdown>
					</div>
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
			<tr *ngFor="let pba of pbas; let i = index">
				<td>
					<strong>{{ pba.name }}</strong>
				</td>
				<td class="text-center">
					{{ (pba.type !== 'OTH') ? (pba.type | uppercase) : '-' }}
				</td>
				<td class="text-center">
					{{ (pba.bootloader ? 'SI' : 'NO') | translate }}
				</td>
				<td class="text-center">
					{{ (pba.image ? 'SI' : 'NO') | translate }}
				</td>
				<td class="text-center">
					{{ pba.observations }}
				</td>
				<td class="actions">
					<button [disabled]="disableButtons" class="btn" (click)="edit(i)">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger" (click)="tempPba = pba; deletePba.show()">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</table-sorter>

<div bsModal #editPba="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editPba" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="editPba.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 *ngIf="!tempPba.id" class="modal-title">{{ 'NUEVA_PBA' | translate }}</h4>
				<h4 *ngIf="tempPba.id" class="modal-title">{{ 'EDITAR' | translate }} {{ 'PBA' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div class="row">
					<div class="col-sm-12">
						<label>{{ 'NOMBRE' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempPba.name" maxlength="50">
					</div>
				</div>
				<br>
				<div class="row">
					<div class="col-sm-12">
						<label>{{ 'TIPO' | translate }}</label>
						<select [(ngModel)]="tempPba.type" class="form-control">
							<option value="POT">POT</option>
							<option value="VIS">VIS</option>
							<option value="IDC">IDCOM</option>
							<option value="IOT">IoT</option>
							<option value="TLC2">TLC2</option>
							<option value="TS2">TS2</option>
							<option value="TS3">TS3</option>
							<option value="DOM17-POT-IOT">DOM17-POT-IOT</option>
							<option value="OTH">-</option>
						</select>
					</div>
				</div>
				<br>
				<div class="row">
					<div class="col-sm-6">
						<label>{{ 'BOOTLOADER' | translate }}: </label>
						<span class="btn-group">
							<label class="radio-inline">
								<input type="radio" name="bootloader" [disabled]="disableButtons" [(ngModel)]="tempPba.bootloader" [value]="true"> {{ 'SI' | translate }}
							</label>
							<label class="radio-inline">
								<input type="radio" name="bootloader" [disabled]="disableButtons" [(ngModel)]="tempPba.bootloader" [value]="false"> {{ 'NO' | translate }}
							</label>
						</span>
					</div>
					<div class="col-sm-6">
						<label>{{ 'IMAGEN' | translate }} {{ 'INFO' | translate }}: </label>
						<span class="btn-group">
							<label class="radio-inline">
								<input type="radio" name="image" [disabled]="disableButtons" [(ngModel)]="tempPba.image" [value]="true"> {{ 'SI' | translate }}
							</label>
							<label class="radio-inline">
								<input type="radio" name="image" [disabled]="disableButtons" [(ngModel)]="tempPba.image" [value]="false"> {{ 'NO' | translate }}
							</label>
						</span>
					</div>
				</div>
				<br>
				<div>
					<label>{{ 'NOTAS' | translate }}</label>
					<textarea [disabled]="disableButtons" class="form-control" [(ngModel)]="tempPba.observations"></textarea>
				</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-primary" (click)="save()" role="button" aria-disabled="false">{{ 'BT_GUARDAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="editPba.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>

<div bsModal #deletePba="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deletePba" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="deletePba.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'BORRAR' | translate }} {{ 'PBA' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div><strong>{{ 'PBA' | translate }}:</strong> {{ tempPba.name }}</div>
				<div><strong>{{ 'TIPO' | translate }}:</strong> {{ (tempPba.type !== 'OTH') ? (tempPba.type | uppercase) : '-' }}</div>
				<div *ngIf="tempPba.observations">
					<p>
						{{ tempPba.observations }}
					</p>
				</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-danger" (click)="delete()" role="button" aria-disabled="false">{{ 'BORRAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="deletePba.hide()" class="btn btn-primary" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>
