import { Injectable, Pipe, PipeTransform } from '@angular/core';

// utils
import * as _ from 'lodash';

@Pipe({
	name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
	public transform(collection, predicate, field = null) {
		if (field) {
			return _.filter(collection, (o) => { return o ? o[field] !== predicate : false; });
		}

		return _.filter(collection, predicate);
	}
}
