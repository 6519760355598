import { Pipe, PipeTransform } from '@angular/core';
import ROLES from '../entities/roles';

@Pipe({
		name: 'roles'
})
export class RolesPipe implements PipeTransform {
		static transform(value: string): any {
				return ROLES;
		}
		transform(value: string): any {
				return ROLES;
		}
}
