<header ps-header>
	<h2>{{ 'ESTADISTICAS' | translate }}</h2>
</header>
<main class="container">
	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'POOLSTATION_CONETADOS' | translate }}
		</div>
		<div class="panel-body text-center">
			<div class="label label-info">
				<span>{{ 'EN_ESTE_MOMENTO' | translate }}</span>
				<span class="badge">
					<span *ngIf="!stats.connected?.onlineAll">
						<span *ngIf="stats.connected?.onlineAll != 0">-</span>
					</span>
					<span *ngIf="stats.connected?.onlineAll == 0">0</span>
					<span *ngIf="stats.connected?.onlineAll && stats.connected?.onlineAll != 0">{{ stats.connected.onlineAll }}</span>
				</span>
			</div>
			<div class="label label-info">
				<span>{{ 'DURANTE_EL_DIA' | translate }}</span>
				<span class="badge">
					<span *ngIf="!stats.connected?.today">
						<span *ngIf="stats.connected?.today != 0">-</span>
					</span>
					<span *ngIf="stats.connected?.today == 0">0</span>
					<span *ngIf="stats.connected?.today && stats.connected?.today != 0">{{ stats.connected.today }}</span>
				</span>
			</div>
			<div class="label label-info">
				<span>{{ 'AYER' | translate }}</span>
				<span class="badge">
					<span *ngIf="!stats.connected?.yesterday">
						<span *ngIf="stats.connected?.yesterday != 0">-</span>
					</span>
					<span *ngIf="stats.connected?.yesterday == 0">0</span>
					<span *ngIf="stats.connected?.yesterday && stats.connected?.yesterday != 0">{{ stats.connected.yesterday }}</span>
				</span>
			</div>
			<div class="label label-info">
				<span>{{ 'ALGUN_DIA' | translate }}</span>
				<span class="badge">
					<span *ngIf="!stats.connected?.someDay">
						<span *ngIf="stats.connected?.someDay != 0">-</span>
					</span>
					<span *ngIf="stats.connected?.someDay == 0">0</span>
					<span *ngIf="stats.connected?.someDay && stats.connected?.someDay != 0">{{stats.connected.someDay}}</span>
				</span>
			</div>
		</div>
	</div>

	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'ULTIMO_MES' | translate }}
		</div>
		<div class="panel-body">
			<div *ngIf="!stats.statLastMonth" class="text-center">
				<img src="/assets/images/wave.svg">
			</div>
			<div [chart]="stats.statLastMonth"></div>
		</div>
	</div>

	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'ANO_EN_CURSO' | translate }}
		</div>
		<div class="panel-body">
			<div *ngIf="!stats.statThisYear" class="text-center">
				<img src="/assets/images/wave.svg">
			</div>
			<div [chart]="stats.statThisYear"></div>
		</div>
	</div>

	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'UN_ANO' | translate }}
		</div>
		<div class="panel-body">
			<div *ngIf="!stats.statOneYear" class="text-center">
				<img src="/assets/images/wave.svg">
			</div>
			<div [chart]="stats.statOneYear"></div>
		</div>
	</div>

	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'DISTRIBUCION_EQUIPOS_MODELO' | translate }}
		</div>

		<table class="table hidden-xs">
			<tbody>
				<tr>
					<th>{{ 'SERIE_200' | translate }}</th>
					<td>RPH200/RPH201 <span class="badge badge-primary"><span *ngIf='!stats.RPH'><span *ngIf='stats.RPH != 0'>-</span></span>{{ stats.RPH }}</span></td>
					<td>RMV200/RMV201 <span class="badge badge-primary"><span *ngIf='!stats.RMV'><span *ngIf='stats.RMV != 0'>-</span></span>{{ stats.RMV }}</span></td>
					<td>PR204 <span class="badge badge-primary"><span *ngIf='!stats.PR204'><span *ngIf='stats.PR204 != 0'>-</span></span>{{ stats.PR204 }}</span></td>
					<td>PR206 <span class="badge badge-primary"><span *ngIf='!stats.PR206'><span *ngIf='stats.PR206 != 0'>-</span></span>{{ stats.PR206 }}</span></td>
					<td>PR207 <span class="badge badge-primary"><span *ngIf='!stats.PR207'><span *ngIf='stats.PR207 != 0'>-</span></span>{{ stats.PR207 }}</span></td>
				</tr>
				<tr>
					<th>{{ 'SERIE_300' | translate }}</th>
					<td>PR300 <span class="badge badge-primary"><span *ngIf='!stats.PR300'><span *ngIf='stats.PR300 != 0'>-</span></span>{{ stats.PR300 }}</span></td>
					<td>PR310 <span class="badge badge-primary"><span *ngIf='!stats.PR310'><span *ngIf='stats.PR310 != 0'>-</span></span>{{ stats.PR310 }}</span></td>
					<td>PR320 <span class="badge badge-primary"><span *ngIf='!stats.PR320'><span *ngIf='stats.PR320 != 0'>-</span></span>{{ stats.PR320 }}</span></td>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<th>{{ 'SERIE_DOMOTIC' | translate }}</th>
					<td>Domotic <span class="badge badge-primary"><span *ngIf='!stats.Dom'><span *ngIf='stats.Dom != 0'>-</span></span>{{ stats.Dom }}</span></td>
					<td>Domotic pH <span class="badge badge-primary"><span *ngIf='!stats.DomPh'><span *ngIf='stats.DomPh != 0'>-</span></span>{{ stats.DomPh }}</span></td>
					<td>Domotic + <span class="badge badge-primary"><span *ngIf='!stats.DomPlus'><span *ngIf='stats.DomPlus != 0'>-</span></span>{{ stats.DomPlus }}</span></td>
					<td>Domotic SPA <span class="badge badge-primary"><span *ngIf='!stats.DomSpa'><span *ngIf='stats.DomSpa != 0'>-</span></span>{{ stats.DomSpa }}</span></td>
					<td>Domotic DS2 <span class="badge badge-primary"><span *ngIf='!stats.DomS2'><span *ngIf='stats.DomS2 != 0'>-</span></span>{{ stats.DomS2 }}</span></td>
				</tr>
				<tr>
					<th>{{ 'INDUSTRIALES' | translate }}</th>
					<td>Domotic Industrial <span class="badge badge-primary"><span *ngIf='!stats.DomInd'><span *ngIf='stats.DomInd != 0'>-</span></span>{{ stats.DomInd }}</span></td>
					<td>DomoSerie Ex <span class="badge badge-primary"><span *ngIf='!stats.Ex'><span *ngIf='stats.Ex != 0'>-</span></span>{{ stats.Ex }}</span></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
			</tbody>
		</table>

		<div class="visible-xs">
			<div class="container">
				<div><strong>{{ 'SERIE_200' | translate }}</strong></div>
				<div class="container">
					<div>RPH200/RPH201 <span class="badge badge-primary"><span *ngIf='!stats.RPH'><span *ngIf='stats.RPH != 0'>-</span></span>{{ stats.RPH }}</span></div>
					<div>RMV200/RMV201 <span class="badge badge-primary"><span *ngIf='!stats.RMV'><span *ngIf='stats.RMV != 0'>-</span></span>{{ stats.RMV }}</span></div>
					<div>PR204 <span class="badge badge-primary"><span *ngIf='!stats.PR204'><span *ngIf='stats.PR204 != 0'>-</span></span>{{ stats.PR204 }}</span></div>
					<div>PR206 <span class="badge badge-primary"><span *ngIf='!stats.PR206'><span *ngIf='stats.PR206 != 0'>-</span></span>{{ stats.PR206 }}</span></div>
					<div>PR207 <span class="badge badge-primary"><span *ngIf='!stats.PR207'><span *ngIf='stats.PR207 != 0'>-</span></span>{{ stats.PR207 }}</span></div>
				</div>
			</div>
			<div class="container">
				<div><strong>{{ 'SERIE_300' | translate }}</strong></div>
				<div class="container">
					<div>PR300 <span class="badge badge-primary"><span *ngIf='!stats.PR300'><span *ngIf='stats.PR300 != 0'>-</span></span>{{ stats.PR300 }}</span></div>
					<div>PR310 <span class="badge badge-primary"><span *ngIf='!stats.PR310'><span *ngIf='stats.PR310 != 0'>-</span></span>{{ stats.PR310 }}</span></div>
					<div>PR320 <span class="badge badge-primary"><span *ngIf='!stats.PR320'><span *ngIf='stats.PR320 != 0'>-</span></span>{{ stats.PR320 }}</span></div>
				</div>
			</div>
			<div class="container">
				<div><strong>{{ 'SERIE_DOMOTIC' | translate }}</strong></div>
				<div class="container">
					<div>Domotic <span class="badge badge-primary"><span *ngIf='!stats.Dom'><span *ngIf='stats.Dom != 0'>-</span></span>{{ stats.Dom }}</span></div>
					<div>Domotic pH <span class="badge badge-primary"><span *ngIf='!stats.DomPh'><span *ngIf='stats.DomPh != 0'>-</span></span>{{ stats.DomPh }}</span></div>
					<div>Domotic + <span class="badge badge-primary"><span *ngIf='!stats.DomPlus'><span *ngIf='stats.DomPlus != 0'>-</span></span>{{ stats.DomPlus }}</span></div>
					<div>Domotic SPA <span class="badge badge-primary"><span *ngIf='!stats.DomSpa'><span *ngIf='stats.DomSpa != 0'>-</span></span>{{ stats.DomSpa }}</span></div>
					<div>Domotic DS2 <span class="badge badge-primary"><span *ngIf='!stats.DomS2'><span *ngIf='stats.DomS2 != 0'>-</span></span>{{ stats.DomS2 }}</span></div>
				</div>
			</div>
			<div class="container">
				<div><strong>{{ 'INDUSTRIALES' | translate }}</strong></div>
				<div class="container">
					<div>Domotic Industrial <span class="badge badge-primary"><span *ngIf='!stats.DomInd'><span *ngIf='stats.DomInd != 0'>-</span></span>{{ stats.DomInd }}</span></div>
					<div>DomoSerie Ex <span class="badge badge-primary"><span *ngIf='!stats.Ex'><span *ngIf='stats.Ex != 0'>-</span></span>{{ stats.Ex }}</span></div>
				</div>
			</div>
		</div>

	</div>

	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'FUNCIONES' | translate }}
		</div>
		<div class="panel-body">
			<div class="row">
				<div class="col-xs-12 hidden-xs" style="position: relative; height: 30px">
					<div class="infoBadgesBig">
						<div class="label label-info label-info-funciones">
							<small>
								{{ 'SOLO_INSTANTANEAS' | translate }}
								<span *ngIf='!stats.alarmsPs?.instant'><span *ngIf='stats.alarmsalarmsPs?.instant != 0'>-</span></span>{{ stats.alarmsPs?.instant }}
							</small>
						</div>
						<div class="label label-info label-info-funciones">
							<small>
								{{ 'SOLO_DIARIAS' | translate }}
								<span *ngIf='!stats.alarmsPs?.daily'><span *ngIf='stats.alarmsalarmsPs?.daily != 0'>-</span></span>{{ stats.alarmsPs?.daily }}
							</small>
						</div>
						<div class="label label-info label-info-funciones">
							<small>
								{{ 'INSTANTANEAS_Y_DIARIAS' | translate }}
								<span *ngIf='!stats.alarmsPs?.instantDaily'><span *ngIf='stats.alarmsalarmsPs?.instantDaily != 0'>-</span></span>{{ stats.alarmsPs?.instantDaily }}
							</small>
						</div>
					</div>
				</div>

				<div class="col-sm-4">
					<h4 class="hidden">
						{{ 'POOLSTATION_CON_FUNCION_CREPUSCULAR' | translate }}: <span class="value"></span>
					</h4>
					<div class="bloque-grafica" id="statTwilight">
						<div *ngIf="!stats.statTwilight" class="text-center">
							<img src="/assets/images/wave.svg">
						</div>
						<div [chart]="stats.statTwilight"></div>
					</div>
				</div>
				<div class="col-sm-4">
					<h4 class="hidden">
						{{ 'POOLSTATION_CON_ENCLAVAMIENTOS' | translate }}: <span class="value"></span>
					</h4>
					<div class="bloque-grafica" id="statInterlocks">
						<div *ngIf="!stats.statInterlocks" class="text-center">
							<img src="/assets/images/wave.svg">
						</div>
						<div [chart]="stats.statInterlocks"></div>
					</div>
				</div>
				<div class="col-xs-12 visible-xs xsBadges">
					<div class="label label-info label-info-funciones">
						<small>
							{{ 'SOLO_INSTANTANEAS' | translate }}
							<span *ngIf='!stats.alarmsPs?.instant'><span *ngIf='stats.alarmsalarmsPs?.instant != 0'>-</span></span>{{stats.alarmsPs?.instant}}
						</small>
					</div>
					<div class="label label-info label-info-funciones">
						<small>
							{{ 'SOLO_DIARIAS' | translate }}
							<span *ngIf='!stats.alarmsPs?.daily'><span *ngIf='stats.alarmsalarmsPs?.daily != 0'>-</span></span>{{stats.alarmsPs?.daily}}
						</small>
					</div>
					<div class="label label-info label-info-funciones">
						<small>
							{{ 'INSTANTANEAS_Y_DIARIAS' | translate }}
							<span *ngIf='!stats.alarmsPs?.instantDaily'><span *ngIf='stats.alarmsalarmsPs?.instantDaily != 0'>-</span></span>{{stats.alarmsPs?.instantDaily}}
						</small>
					</div>
				</div>
				<div class="col-sm-4 estadisticas-alarmas">
					<div class="bloque-grafica" id="statAlarms">
						<div *ngIf="!stats.statAlarms" class="text-center">
							<img src="/assets/images/wave.svg">
						</div>
						<div [chart]="stats.statAlarms"></div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="panel panel-default  estadisticas-usuarios">
		<div class="panel-heading">
			{{ 'USUARIOS' | translate }}
		</div>
		<div class="panel-body">
			<h4>
				{{ 'USUARIOS_REGISTRADOS' | translate }}
			</h4>
			<div class="col-md-6 bloque-usuarios estadisticas-perfil-usuario">
				<span class="badge badge-primary">{{ 'PERFIL_USUARIO' | translate }}: <span *ngIf='stats.userProfile || stats.userProfile==0' class="value">{{ stats.userProfile }}</span></span>
				<div class="bloque-grafica" id="statUsers">
					<div *ngIf="!stats.statUsers" class="text-center">
						<img src="/assets/images/wave.svg">
					</div>
					<div [chart]="stats.statUsers"></div>
				</div>
			</div>
			<div class="col-md-6 bloque-usuarios estadisticas-perfil-profesional">
				<span class="badge badge-primary">{{ 'PERFIL_PROFESIONAL' | translate }}: <span *ngIf='stats.professionalProfile || stats.professionalProfile==0' class="value">{{ stats.professionalProfile }}</span></span>
				<div class="bloque-grafica" id="statProfessionals">
					<div *ngIf="!stats.statProfessionals" class="text-center">
						<img src="/assets/images/wave.svg">
					</div>
					<div [chart]="stats.statProfessionals"></div>
				</div>
			</div>
			<h4>
				{{ 'USUARIOS_CON_POOLSTATION' | translate }}
			</h4>
			<div class="col-md-6 bloque-usuarios estadisticas-perfil-usuario-asociado">
				<span class="badge badge-primary">{{ 'PERFIL_USUARIO' | translate }}: <span *ngIf='stats.associateUserProfile || stats.associateUserProfile==0' class="value">{{ stats.associateUserProfile }}</span></span>
				<div class="bloque-grafica" id="statUsersPoolstation">
					<div *ngIf="!stats.statUsersPoolstation" class="text-center">
						<img src="/assets/images/wave.svg">
					</div>
					<div [chart]="stats.statUsersPoolstation"></div>
				</div>
			</div>
			<div class="col-md-6 bloque-usuarios estadisticas-perfil-profesional-asociado">
				<span class="badge badge-primary">{{ 'PERFIL_PROFESIONAL' | translate }}: <span *ngIf='stats.associateProfessionalProfile || stats.associateProfessionalProfile==0' class="value">{{ stats.associateProfessionalProfile }}</span></span>
				<div class="bloque-grafica" id="statAssociateProfessionals">
					<div *ngIf="!stats.statAssociateProfessionals" class="text-center">
						<img src="/assets/images/wave.svg">
					</div>
					<div [chart]="stats.statAssociateProfessionals"></div>
				</div>
			</div>
		</div>
	</div>

	<h2>{{ 'CALIDAD_SERVICIO' | translate }}</h2>
	<div class="panel panel-default estadisticas-bloque  estadisticas-usuarios">
		<div class="panel-heading">
			{{ 'PETICIONES_ULTIMA_HORA' | translate }}
		</div>
		<div class="panel-body text-center">
			<div class="label label-info estadisticas-calidad-servicio-read">
				{{ 'PETICIONES_MAQUINA' | translate }} (Read)
				<span class="badge value">
					<span *ngIf="!stats.readRequest">
						<span *ngIf="stats.readRequest != 0">-</span>
					</span>
					<span *ngIf="stats.readRequest == 0">0</span>
					<span *ngIf="stats.readRequest && stats.readRequest != 0">{{ stats.readRequest }}</span>
				</span>
			</div>
			<div class="label label-info estadisticas-calidad-servicio-write">
				{{ 'PETICIONES_MAQUINA' | translate }} (Write)
				<span class="badge value">
					<span *ngIf="!stats.writeRequest">
						<span *ngIf="stats.writeRequest != 0">-</span>
					</span>
					<span *ngIf="stats.writeRequest == 0">0</span>
					<span *ngIf="stats.writeRequest && stats.writeRequest != 0">{{ stats.writeRequest }}</span>
				</span>
			</div>
			<div class="label label-info estadisticas-calidad-servicio-navegador">
				{{ 'PETICIONES_CLIENTE_WEB' | translate }}
				<span class="badge value">
					<span *ngIf="!stats.webRequest">
						<span *ngIf="stats.webRequest != 0">-</span>
					</span>
					<span *ngIf="stats.webRequest == 0">0</span>
					<span *ngIf="stats.webRequest && stats.webRequest != 0">{{ stats.webRequest }}</span>
				</span>
			</div>
			<div class="label label-info estadisticas-calidad-servicio-total">
				{{ 'TOTAL' | translate }}
				<span class="badge value">
					<span *ngIf="!stats.totalRequest">
						<span *ngIf="stats.totalRequest != 0">-</span>
					</span>
					<span *ngIf="stats.totalRequest == 0">0</span>
					<span *ngIf="stats.totalRequest && stats.totalRequest != 0">{{ stats.totalRequest }}</span>
				</span>
			</div>
			<div class="label label-info estadisticas-calidad-servicio-frecuencia">
				{{ 'PETICIONES_SEGUNDO' | translate }}
				<span class="badge value">
					<span *ngIf="!stats.requestPerSecond">
						<span *ngIf="stats.requestPerSecond != 0">-</span>
					</span>
					<span *ngIf="stats.requestPerSecond == 0">0</span>
					<span *ngIf="stats.requestPerSecond && stats.requestPerSecond != 0">{{ stats.requestPerSecond }}</span>
				</span>
			</div>
		</div>
	</div>

	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'NUMERO_PETICIONES_MES' | translate }} <span>{{month}}</span>
		</div>
		<div class="panel-body text-center">
			<span class="request-r legend-cal"> {{ 'PETICIONES_MAQUINA' | translate }}(Read) </span>
			<span class="request-w legend-cal"> {{ 'PETICIONES_MAQUINA' | translate }}(Write) </span>
			<span class="request-web legend-cal"> {{ 'PETICIONES_CLIENTE_WEB' | translate }} </span>
		</div>

		<table class="table hidden-xs" *ngIf="calendarMonth">
			<thead>
				<tr>
					<th> {{ 'LUNES' | translate }} </th>
					<th> {{ 'MARTES' | translate }} </th>
					<th> {{ 'MIERCOLES' | translate }} </th>
					<th> {{ 'JUEVES' | translate }} </th>
					<th> {{ 'VIERNES' | translate }} </th>
					<th> {{ 'SABADO' | translate }} </th>
					<th> {{ 'DOMINGO' | translate }} </th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of calendarMonth">
					<td class="calendarDay" *ngFor="let stat of item.stat">
						<span>{{ stat.day }}: </span>
						<div>
							<span class="request-r" title="{{ 'PETICIONES_MAQUINA' | translate }} (Read)">{{ stat.data ? stat.data.readRequest : '-' }}</span>
							<span class="request-w" title="{{ 'PETICIONES_MAQUINA' | translate }}  (Write)">{{ stat.data ? stat.data.writeRequest : '-' }}</span>
							<span class="request-web" title="{{ 'PETICIONES_CLIENTE_WEB' | translate }} (WEB)">{{ stat.data ? stat.data.webRequest : '-' }}</span>
							<span title="{{ 'PETICIONES_SEGUNDO' | translate }}">{{ stat.data ? (((stat.data.readRequest + stat.data.writeRequest + stat.data.webRequest)/(3600*24)) | number : '1.2-2') : '-' }} <strong>pet/s</strong></span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div *ngFor="let item of calendarMonth" class="visible-xs container">
			<div class="day" *ngFor="let stat of item.stat">
				<div class="dayRow" style="width: 25px">
					<strong>{{ stat.day }}:</strong>
				</div>
				<div class="dayRow">
					<span class="request request-r" title="{{ 'PETICIONES_MAQUINA' | translate }} (Read)">{{ stat.data ? stat.data.readRequest : '-' }}</span>
					<span class="request request-w" title="{{ 'PETICIONES_MAQUINA' | translate }}  (Write)">{{ stat.data ? stat.data.writeRequest : '-' }}</span>
					<span class="request request-web" title="{{ 'PETICIONES_CLIENTE_WEB' | translate }} (WEB)">{{ stat.data ? stat.data.webRequest : '-' }}</span>
					<span title="{{ 'PETICIONES_SEGUNDO' | translate }}">{{ stat.data ? (((stat.data.readRequest+stat.data.writeRequest+stat.data.webRequest)/(3600*24)) | number : '1.2-2') : '-' }} <strong>pet/s</strong></span>
				</div>
			</div>
			<hr>
		</div>

	</div>

	<div class="panel panel-default">
		<div class="panel-heading">
			{{ 'NUMERO_PETICIONES_MES' | translate }} <span>{{lastMonth}}</span>
		</div>
		<div class="panel-body text-center">
			<span class="request-r legend-cal"> {{ 'PETICIONES_MAQUINA' | translate }}(Read) </span>
			<span class="request-w legend-cal"> {{ 'PETICIONES_MAQUINA' | translate }}(Write) </span>
			<span class="request-web legend-cal"> {{ 'PETICIONES_CLIENTE_WEB' | translate }} </span>
		</div>

		<table class="table hidden-xs" *ngIf="calendarLastMonth">
			<thead>
				<tr>
					<th> {{ 'LUNES' | translate }} </th>
					<th> {{ 'MARTES' | translate }} </th>
					<th> {{ 'MIERCOLES' | translate }} </th>
					<th> {{ 'JUEVES' | translate }} </th>
					<th> {{ 'VIERNES' | translate }} </th>
					<th> {{ 'SABADO' | translate }} </th>
					<th> {{ 'DOMINGO' | translate }} </th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of calendarLastMonth">
					<td class="calendarDay" *ngFor="let stat of item.stat">
						<span>{{stat.day}}</span>
						<div>
							<span class="request-r" title="{{ 'PETICIONES_MAQUINA' | translate }} (Read)">{{stat.data ? stat.data.readRequest : '-'}}</span>
							<span class="request-w" title="{{ 'PETICIONES_MAQUINA' | translate }}  (Write)">{{stat.data ? stat.data.writeRequest : '-'}}</span>
							<span class="request-web" title="{{ 'PETICIONES_CLIENTE_WEB' | translate }} (WEB)">{{stat.data ? stat.data.webRequest : '-'}}</span>
							<span title="{{ 'PETICIONES_SEGUNDO' | translate }}">{{stat.data ? (((stat.data.readRequest+stat.data.writeRequest+stat.data.webRequest)/(3600*24)) | number : '1.2-2') : '-'}} <strong>pet/s</strong></span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div *ngFor="let item of calendarLastMonth" class="visible-xs container">
			<div class="day" *ngFor="let stat of item.stat">
				<div class="dayRow" style="width: 25px">
					<strong>{{stat.day}} : </strong>
				</div>
				<div class="dayRow">
					<span class="request request-r" title="{{ 'PETICIONES_MAQUINA' | translate }} (Read)">{{stat.data ? stat.data.readRequest : '-'}}</span>
					<span class="request request-w" title="{{ 'PETICIONES_MAQUINA' | translate }}  (Write)">{{stat.data ? stat.data.writeRequest : '-'}}</span>
					<span class="request request-web" title="{{ 'PETICIONES_CLIENTE_WEB' | translate }} (WEB)">{{stat.data ? stat.data.webRequest : '-'}}</span>
					<span title="{{ 'PETICIONES_SEGUNDO' | translate }}">{{stat.data ? (((stat.data.readRequest+stat.data.writeRequest+stat.data.webRequest)/(3600*24)) | number : '1.2-2') : '-'}} <strong>pet/s</strong></span>
				</div>
			</div>
			<hr>
		</div>

	</div>
</main>
