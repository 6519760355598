<header ps-header>
	<h2>{{ 'HISTORICOS' | translate }}
		<small><strong>{{ device?.ns }}</strong></small>
		<small> - {{ device?.alias }}</small>
	</h2>
</header>

<main class="container-fluid">
	<div *ngIf="device" class="text-center visible-xs flexButtons">
		<button [disabled]="parameter === 'mp'" *ngIf="device.isPH" class="btn btn-xs btn-primary" [title]="'PH' | translate" [routerLink]="['/devices/stat', device.id, 'mp']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_ph.png"></i>
		</button>
		<button [disabled]="parameter === 'mo'" *ngIf="device.isORP" class="btn btn-xs btn-primary" [title]="'ORP' | translate" [routerLink]="['/devices/stat', device.id, 'mo']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_orp.png"></i>
		</button>
		<button [disabled]="parameter === 'mh'" *ngIf="device.isPPM" class="btn btn-xs btn-primary" [title]="'ppm' | translate" [routerLink]="['/devices/stat', device.id, 'mh']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_ppm.png"></i>
		</button>
		<button [disabled]="parameter === 'cn'" *ngIf="device.isPanel3XX || (device.isDS2 && device.electrolysis)" class="btn btn-xs btn-primary" [title]="'GRL' | translate" [routerLink]="['/devices/stat', device.id, 'cn']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_conductividad.png"></i>
		</button>
		<button [disabled]="parameter === 'ta'" *ngIf="device.isPanel3XX || device.isDS2" class="btn btn-xs btn-primary" [title]="'TEMPERATURA' | translate" [routerLink]="['/devices/stat', device.id, 'ta']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_temperatura.png"></i>
		</button>
		<button [disabled]="parameter === 'a1'" *ngIf="device.cardIZ70 && device.series !== 2" class="btn btn-xs btn-primary" [title]="device.a1.name" [routerLink]="['/devices/stat', device.id, 'a1']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_analogico.png"></i>
		</button>
		<button [disabled]="parameter === 'a2'" *ngIf="device.cardIZ70 && device.series !== 2" class="btn btn-xs btn-primary" [title]="device.a2.name" [routerLink]="['/devices/stat', device.id, 'a2']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_analogico.png"></i>
		</button>
		<button [disabled]="parameter === 'pro'" *ngIf="device.electrolysis || device.electrolysisORP || device.electrolysisPPM" class="btn btn-xs btn-primary" [title]="'PRODUCCION' | translate" [routerLink]="['/devices/stat', device.id, 'pro']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_produccion.png"></i>
		</button>
		<button [disabled]="parameter === 'all'" *ngIf="(device.isPH || device.isORP || device.isPPM)" class="btn btn-xs btn-primary" [title]="'VS_CHART' | translate" [routerLink]="['/devices/stat', device.id, 'all']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_grafica.png"></i>
		</button>
		<button [disabled]="parameter === 'sun'" class="btn btn-xs btn-warning" [routerLink]="['/devices/sun/sunrisesunset', device.id]" title="{{'GRAFICA_DURACION_DEL_DIA' | translate}}">
			<i class="fa fa-img"><img src="/assets/images/suncalc.png"></i>
		</button>
	</div>

	<div class="dropContainer hidden-xs">
		<div class="dropdown">
			<button [disabled]="!stats" class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
				<i class="fa fa-ellipsis-v"></i>
			</button>
			<ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
				<li>
					<a (click)="toXLS()" style="cursor:pointer">{{ 'DESCARGAR' | translate }} XLS</a>
				</li>
				<li>
					<a (click)="toCSV()" style="cursor:pointer">{{ 'DESCARGAR' | translate }} CSV</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="visible-xs text-center" style="margin-top: 10px">
		<button [disabled]="!stats || emptyStats" class="btn btn-sm btn-success" (click)="toXLS()">
			<i class="fa fa-download"></i> XLS
		</button>
		<button [disabled]="!stats|| emptyStats" class="btn btn-sm btn-success" (click)="toCSV()">
			<i class="fa fa-download"></i> CSV
		</button>
	</div>

	<div class="form-inline text-right" *ngIf="parameter === 'a1' || parameter === 'a2'">
		<div class="form-group">
			<label for="exampleInputName2">{{ 'MINIMO_EJE_Y' | translate }}</label>
			<input type="number" class="form-control" min='0' max='1000' [(ngModel)]="graphicMin">
		</div>
		<div class="form-group">
			<label for="exampleInputName2">{{ 'MAXIMO_EJE_Y' | translate }}</label>
			<input type="number" class="form-control" min='0' max='1000' [(ngModel)]="graphicMax">
		</div>
		<button class="btn btn-primary fa fa-refresh" title="{{ 'RECARGAR' | translate }}" (click)="redraw()"></button>
	</div>
	<br>
	<div class="text-center">
		<div *ngIf="stats && !emptyStats" [chart]="stats"></div>
		<div *ngIf="stats && emptyStats">{{"NO_DATA" | translate}}</div>
		<img *ngIf="!stats" src="/assets/images/wave.svg">
	</div>
		<br>
		<p>
			<button class="btn btn-danger" onclick="history.back()" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
		</p>
</main>
