import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';

import { BaseService } from './base.service';

// services
import { AuthService } from './auth.service';
import {AppConfig} from '../AppConfig';

@Injectable()
export class HistoryService extends BaseService {
	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public find(ns, varName) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/historic/view/' + ns, 'data=' + varName, { headers: this.headers } ).subscribe((result) => {
				let history = result/* .json() */;

				resolve(history);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}
}
