import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'app/services/users.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  @ViewChild('loadingModal', {static: true}) public loadingModal: ModalDirective;

  public expires: string;
  public id: string;
  public signature: any;
  public token: any;
  public status : string;
  
  

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _usersSvc: UsersService) {}

  ngOnInit(): void {

    this.loadingModal.show();
    

    this.id = this.route.snapshot.queryParams.id;
    this.expires =  this.route.snapshot.queryParams.expires;
    this.signature = encodeURIComponent(this.route.snapshot.queryParams.signature);
    this.token =  encodeURIComponent(this.route.snapshot.queryParams.token);
    this.status = "loading";

    if(!this.id || !this.expires || !this.signature || !this.token ) this._router.navigate(['/']);

    this.verify();
  }

  public verify() {

    this._usersSvc.verifyUser(this.id, this.expires, this.signature, this.token).then((result: any) => {
      
      const { message } = result;

      if(message == 'OK'){
        this.status = "verified";
        this.loadingModal.hide();
      }

    }).catch((error: any) => {
      
      const {status} = error;

      if(status == 409) this.status = "already_verified"; 
      
      this.loadingModal.hide(); 
    });
  }

}
