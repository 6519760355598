import { Component, OnInit, ViewChild } from '@angular/core';

// services
import { VersionsService } from 'app/services/versions.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'models',
	templateUrl: './models.component.html'
})
export class ModelsComponent implements OnInit {
	@ViewChild('editModel', {static: true}) editModel;
	@ViewChild('deleteModel', {static: true}) deleteModel;

	public models: any[];
	public selectedPbas: any = {};
	public totalModels: number;
	public tempModel: any = {};
	public pbas: any[];
	public limit: number = 20;
	public page: number = 1;
	public totalPages: number = 1;
	public conditions: any;
	public disableButtons: boolean = false;

	private _searchText: string = '';

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		value = value.trim();

		if (value && value !== '') {
			this._searchText = value;
			this.conditions = {
				conditions: [
					'model.name LIKE :search'
				],
				parameters: {
					search: this._searchText
				}
			};

			this.loadItems();
		} else {
			this.conditions = {
				conditions: [],
				parameters: {}
			};
		}
	}

	constructor(private _versionSvc: VersionsService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService) {
		}

	ngOnInit() {
		this.loadItems();
		this._getPbas();
	}

	public loadItems(nPage: number = 1) {
		this.disableButtons = true;

		this._versionSvc.findModels(this.limit, nPage, this.conditions).then((result: any) => {
			this.disableButtons = false;
			this.models = result.items;
			this.totalModels = result.total;
			this.totalPages = Math.ceil(this.totalModels / this.limit);
			console.log(this.models);
		}).catch((error: any) => {
			console.log(error);
			this.disableButtons = false;
			this.loadItems();
		});
	}

	public edit(index: number = null) {
		this.selectedPbas = {};
		this.tempModel = JSON.parse(JSON.stringify(this.models[index]));
		this.tempModel.idPba1 = (this.tempModel.id_pba1) ? this.tempModel.id_pba1.id : null;
		this.tempModel.idPba2 = (this.tempModel.id_pba2) ? this.tempModel.id_pba2.id : null;
		this.tempModel.idPba3 = (this.tempModel.id_pba3) ? this.tempModel.id_pba3.id : null;
		this.tempModel.idPba4 = (this.tempModel.id_pba4) ? this.tempModel.id_pba4.id : null;
		this.tempModel.idPba5 = (this.tempModel.id_pba5) ? this.tempModel.id_pba5.id : null;
		this.tempModel.idPba6 = (this.tempModel.id_pba6) ? this.tempModel.id_pba6.id : null;

		for (const key in this.tempModel) {
			if (key[0] !== 'm' && key[0] !== 'n') {
				if (this.tempModel[key]) {
					this.selectedPbas[this.tempModel[key]] = true;
				}
			}
		}

		if (this.tempModel.mm === 'DS2') {
			this.tempModel.deviceModel = 'DS2';
		} else if (this.tempModel.mm !== 'DS2' && this.tempModel.mr === 'IEZ-70') {
			this.tempModel.deviceModel = 'DOM';
		} else {
			this.tempModel.deviceModel = 'MINI';
		}

		delete (this.tempModel.id_pba1);
		delete (this.tempModel.id_pba2);
		delete (this.tempModel.id_pba3);
		delete (this.tempModel.id_pba4);
		delete (this.tempModel.id_pba5);
		delete (this.tempModel.id_pba6);

		this.editModel.show();
		console.log(this.tempModel.deviceModel);
	}

	public createModel() {
		this.tempModel = {
			name: '',
			idPba1: null,
			idPba2: null,
			idPba3: null,
			idPba4: null,
			idPba5: null,
			idPba6: null,
			min1: null,
			max1: null,
			min2: null,
			max2: null,
			min3: null,
			max3: null,
			min4: null,
			max4: null,
			min5: null,
			max5: null,
			min6: null,
			max6: null,
			mm: null,
			mr: null,
			deviceModel: 'DOM'
		};
		this.selectedPbas = {};
		this.editModel.show();
	}

	public setPba(pbaIndex: number, value: any) {
		if (value !== 'null') {
			if (this.tempModel['idPba' + pbaIndex]) {
				this.tempModel['min' + pbaIndex] = 0;
				this.tempModel['max' + pbaIndex] = 1;
			} else {
				this.tempModel['idPba' + pbaIndex] = null;
				this.tempModel['min' + pbaIndex] = null;
				this.tempModel['max' + pbaIndex] = null;
			}
		} else {
			if (pbaIndex < 6) {
				for (let i = 6; i >= pbaIndex; i--) {
					this.tempModel['idPba' + i] = null;
					this.tempModel['min' + i] = null;
					this.tempModel['max' + i] = null;
				}
			}
		}

		this.selectedPbas = {};

		for (const key in this.tempModel) {
			if (key[0] !== 'm' && key[0] !== 'n') {
				if (this.tempModel[key]) {
					this.selectedPbas[this.tempModel[key]] = true;
				}
			}
		}
	}

	public save() {
		this.disableButtons = true;

		if (this._checkLength('name', 50) && this._checkModel()) {
			this.tempModel.name = this.tempModel.name.trim();

			switch (this.tempModel.deviceModel) {
				case 'DOM':
					this.tempModel.mm = null;
					this.tempModel.mr = 'IEZ-70';
					break;
				case 'DS2':
					this.tempModel.mm = 'DS2';
					this.tempModel.mr = null;
					break;
				case 'MINI':
					this.tempModel.mm = null;
					this.tempModel.mr = null;
					break;
			}
			delete (this.tempModel.deviceModel);

			this._versionSvc.saveModel(this.tempModel).then((result: any) => {
				this.disableButtons = false;
				this.editModel.hide();
				this._notificationSvc.success('', this._translate.instant(result.message));
				this.loadItems(this.page);
			}).catch((error) => {
				this.disableButtons = false;
				this.editModel.hide();
			});
		} else {
			this.disableButtons = false;
			this.editModel.hide();
		}
	}

	public delete() {
		this.disableButtons = true;

		this._versionSvc.deleteModel(this.tempModel.id).then((result: any) => {
			this._notificationSvc.success('', this._translate.instant(result.message));
			this.disableButtons = false;
			this.deleteModel.hide();
			this.loadItems(this.page);
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}

			this.disableButtons = false;
			this.deleteModel.hide();
		});
	}

	private _getPbas() {
		this._versionSvc.getAllPbas().then((result: any) => {
			this.pbas = result;
			console.log(result);
		}).catch((error) => {
			console.log(error);
		});
	}

	private _checkLength(field: string, max: number, min: number = 1): boolean {
		let ok: boolean = true;

		if (this.tempModel[field].trim().length > max || this.tempModel[field].trim().length < min) {
			this.tempModel = {};
			this.editModel.hide();
			this._notificationSvc.error('', this._translate.instant('LONGITUD_CAMPO') + min + '-' + max);
			ok = false;
		} else if (this.tempModel[field].trim().length === 0) {
			this.tempModel = {};
			this.editModel.hide();
			this._notificationSvc.error('', this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS'));
			ok = false;
		}

		return ok;
	}

	private _checkModel() {
		if (!this.tempModel.idPba1 || this.tempModel.idPba1 === 'null') {
			this._notificationSvc.error('', this._translate.instant('MODELO') + ' ' + this._translate.instant('VALIDA_LONGITUD_2') + ' 1 ' + this._translate.instant('VALIDA_LONGITUD_3') + ' 6 ' + this._translate.instant('PBAS'));
			return false;
		}

		return true;
	}
}
