import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//services
import { AuthService } from 'app/services/auth.service';

@Component({
	selector: 'sessions',
	templateUrl: 'sessions.component.html',
    styleUrls: ['sessions.component.scss']
})
export class SessionsComponent implements OnInit {

    constructor(
        private _route: ActivatedRoute,
        private _authSvc: AuthService){}
        public sessions: string[]; 

    public ngOnInit() {
        this._authSvc.getSessions().then((result) => {
            this.sessions = result['sessions'];
        }).catch((error) => {

        });
    }

    public remove(session: string) {
        this._authSvc.removeSession(session);
        this.sessions = this.sessions.filter(tokenInfo => tokenInfo['token'] != session);
    }
}