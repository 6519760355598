import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'keys'
})
@Injectable()
export class KeysPipe implements PipeTransform {
	transform(value, options = {}) {
		let keys = [];
		let keyName = options['keyName'] || 'key';
		let valueName = options['valueName'] || 'value';

		for (let key in value) {
			if (value.hasOwnProperty(key)) {
				let item = {};
				item[keyName] = key;
				item[valueName] = value[key];
				keys.push(item);
			}
		}

		return keys;
	}
}
