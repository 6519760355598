import { Program } from './program';
import { Relay } from './relay';

// utils
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

declare var SunCalc: any;

export class Device {
	public id: number;
	public ms: string;
	public vars: any;
	public ns: number;
	public lat: number;
	public longitud: number;
	public programs: Program[] = [];
	public relays: Relay[] = [];
	public sunrise: any;
	public sunset: any;
	public rate: any;
	public user: any;
	public alias: string = '';
	public cod_activacion: string;
	public timezone: string = '';
	public id_pais: string = '';
	public id_loc: string = '';
	public activo: number;
	public register: any;
	public device_ep: string;
	public device_rp: string;
	public default_eco: string;
	public default_turbo: string;
	public max_off_hours: number;

	// constans
	get MIN_LAZO() { return 8; };
	get MIN4MA_IEZ70() { return 30; };
	get MAX20MA_IEZ70() { return 530; };
	get MAX_LAZO() { return 550; };
	get MIN_SETPOINT_ENCLAVAMIENTO() { return 42; };
	get EP_OFFLINE() { return '060'; };

	// inputs
	public a1_name: string;
	public a2_name: string;
	public a1_max_grafica: number;
	public a2_max_grafica: number;
	public a1_min_grafica: number;
	public a2_min_grafica: number;
	public a1_max: string;
	public a2_max: string;
	public a1_min: string;
	public a2_min: string;
	public a1_unidad: string;
	public a2_unidad: string;
	public dw: string;

	// visual helps
	//public alarmsCollapsed: boolean = true;

	private d1_name: string;
	private d2_name: string;
	private d3_name: string;
	private d4_name: string;
	private d1_alarma: number;
	private d2_alarma: number;
	private d3_alarma: number;
	private d4_alarma: number;
	private _relays: any[];
	private _personalized = null;

	get isDemo() {
		return (String(this.ns).indexOf('9999') > -1);
	}

	get encodedOptions(): string {
		if (this.vars && this.vars.mm && this.vars.mm !== '' && this.vars.mm.length === 8) {
			return String(this.vars.mm).substring(6, 8);
		} else {
			return null;
		}
	}

	get cardIZ70() {
		return this.vars.mr && (this.vars.mr !== '------');
	}

	get gs_rmv() {
		if (this.vars.gs && this.vars.gs.length === 6) {
			return this.vars.gs[1];
		} else {
			return false;
		}
	}

	get gs_pr300() {
		if (this.vars.gs && this.vars.gs.length === 6) {
			return this.vars.gs[3];
		} else {
			return false;
		}
	}

	get electrolysis() {
		switch (this.series) {
			case 1:
				return (this.vars.mm && this.vars.mm.length === 8 && this.vars.mm !== '--------');
			case 2:
				let allowed;

				if (this.ds2Family === 1) {
					allowed = [
						['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '0A', '0B', '0C', '0D', '0E', '0F'],
						['40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '4A', '4B', '4C', '4D', '4E', '4F'],
						['A0', 'A8', 'C0', 'C8']
					];
				} else {
					allowed = [
						['00', 'A0', '40', 'C0']
					];
				}

				return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
		}
	}

	get electrolysisPH() {
		switch (this.series) {
			case 1:
				if (this.vars.mm && this.vars.mm.length === 8 && this.vars.mm !== '--------') {
					return ['P'].indexOf(this.vars.mm[6]) !== -1 || +this.vars.ms[0] > 0;
				}

				return false;
			case 2:
				let allowed;

				if (this.ds2Family === 1) {
					allowed = [
						['01', '04', '05', '07', '09', '0C', '0D', '0F'],
						['41', '44', '45', '47', '49', '4C', '4D', '4F']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				} else {
					allowed = [
						['00', 'A0', '40', 'C0']
					];

					return (this.vars.ms.charAt(0) === '1' && _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
				}
		}
	}

	get electrolysisORP() {
		switch (this.series) {
			case 1:
				if (this.vars.mm && this.vars.mm.length === 8 && this.vars.mm !== '--------') {
					return ['L'].indexOf(this.vars.mm[6]) !== -1 || +this.vars.ms[1] > 0;
				}

				return false;
			case 2:
				let allowed;

				if (this.ds2Family === 1) {
					allowed = [
						['02', '04', '06', '07', '08', '0A', '0C', '0E', '0F'],
						['42', '44', '46', '47', '48', '4A', '4C', '4E', '4F']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				} else {
					allowed = [
						['00', 'A0', '40', 'C0']
					];

					return (this.vars.ms.charAt(1) === '1' && _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
				}
		}
	}

	get electrolysisInternalPPM() {
		switch (this.series) {
			case 1:
				if (this.vars.mm && this.vars.mm.length === 8 && this.vars.mm !== '--------') {
					return ['H'].indexOf(this.vars.mm[6]) !== -1;
				}

				return false;
			case 2:
				let allowed;

				if (this.ds2Family === 1) {
					allowed = [
						['03', '05', '06', '07', '0B', '0D', '0E', '0F'],
						['43', '45', '46', '47', '4B', '4D', '4E', '4F']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				} else {
					allowed = [
						['00', 'A0', '40', 'C0']
					];

					return (this.vars.ms.charAt(2) === '1' && _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
				}
		}
	}

	get electrolysisPPM() {
		switch (this.series) {
			case 1:
				if (this.vars.mm && this.vars.mm.length === 8 && this.vars.mm !== '--------') {
					return ['H'].indexOf(this.vars.mm[6]) !== -1 || +this.vars.ms[2] > 0;
				}

				return false;
			case 2:
				let allowed;

				if (this.ds2Family === 1) {
					allowed = [
						['03', '05', '06', '07', '0B', '0D', '0E', '0F'],
						['43', '45', '46', '47', '4B', '4D', '4E', '4F']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				} else {
					allowed = [
						['00', 'A0', '40', 'C0']
					];

					return (this.vars.ms.charAt(2) === '1' && _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
				}
		}
	}

	get ds2Family() {
		let family = 0;

		if (this.series === 2) {
			if (this.vars.ms && this.vars.ms.length === 6 && this.vars.ms === '000000') {
				family = 1;
			} else {
				family = 2;
			}
		}

		return family;
	}

	get isLumiplus() {
		let au = parseInt(this.vars.au, 10);

		return !isNaN(au);
	}

	get isTemperature() {
		switch (this.series) {
			case 1:
				return this.isPanel300 || this.isPanel310 || this.isPanel320;
			case 2:
				return true;
		}
	}

	get isElectrolysis() {
		switch (this.series) {
			case 1:
				return (this.electrolysis || this.electrolysisPH || this.electrolysisORP || this.electrolysisPPM);
			case 2:
				let allowed;

				if (this.ds2Family === 1) {
					allowed = [
						['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '0A', '0B', '0C', '0D', '0E', '0F'],
						['40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '4A', '4B', '4C', '4D', '4E', '4F'],
						['A0', 'A8', 'C0', 'C8']
					];
				} else {
					allowed = [
						['00', 'A0', '40', 'C0']
					];
				}

				return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
		}
	}

	get isUltraviolet() {
		switch (this.series) {
			case 1:
				return this.isPanel300 || this.isPanel310 || this.isPanel320 || this.isUV;
			case 2:
				let allowed: any;

				if (this.ds2Family === 1) {
					allowed = [
						['40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '4A', '4B', '4C', '4D', '4E', '4F'],
						['60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '6A', '6B', '6C', '6D', '6E', '6F', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '7A', '7B', '7C', '7D', '7E', '7F'],
						['C0', 'C8']
					];
				} else {
					allowed = [
						['40', 'C0', '60', '61']
					];
				}

				return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
		}
	}

	get hasPh() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return +this.vars.ms[0] > 0 || ['L', 'P', 'H'].indexOf(this.vars.mm[6]) !== -1;
		}

		return false;
	}

	get hasExternalPh() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return +this.vars.ms[0] > 0;
		}

		return false;
	}

	get isPH() {
		switch (this.series) {
			case 1:
				return (this.electrolysisPH || this.electrolysisORP || this.electrolysisPPM || this.isPanel3XX || this.hasPh);
			case 2:
				let allowed: any;

				if (this.ds2Family === 1) {
					allowed = [
						['01', '04', '05', '07', '09', '0C', '0D', '0F'],
						['20', '23', '24', '26', '27', '2A', '2B', '2D', '2E', '30', '31', '32', '34'],
						['41', '44', '45', '47', '49', '4C', '4D', '4F'],
						['62', '63', '68', '69', '6A', '6B', '6E', '6F', '71', '74', '75', '77', '78', '79', '7A', '7C', '7D', '7E', '7F']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				} else {
					return this.vars.ms.charAt(0) === '1';
				}
		}
	}

	get hasOrp() {
		return (this.vars.ms && this.vars.ms.length === 6 && +this.vars.ms[1] > 0) || ['L'].indexOf(this.vars.mm[6]) !== -1;
	}

	get hasExternalOrp() {
		return (this.vars.ms && this.vars.ms.length === 6 && +this.vars.ms[1] > 0);
	}

	get isORP() {
		switch (this.series) {
			case 1:
				return (this.electrolysisORP || ((this.isPanel300 || this.isPanel320) && !this.isPanel310) || this.hasOrp);
			case 2:
				let allowed: any;

				if (this.ds2Family === 1) {
					allowed = [
						['02', '04', '06', '07', '0A', '0C', '0E', '0F'],
						['21', '23', '25', '26', '28', '2A', '2C', '2D', '2F', '31', '33', '34'],
						['42', '44', '46', '47', '4A', '4C', '4E', '4F'],
						['64', '65', '68', '69', '6C', '6D', '6E', '6F', '72', '74', '76', '77', '78', '7A', '7C', '7E', '7F']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				} else {
					return this.vars.ms.charAt(1) === '1';
				}
		}
	}

	get hasPpm() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return parseInt(this.vars.ms[2], 10) > 0 || ['H'].indexOf(this.vars.mm[6]) !== -1;
		}

		return false;
	}

	get hasExternalPpm() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return parseInt(this.vars.ms[2], 10) > 0;
		}

		return false;
	}

	get isPPM() {
		switch (this.series) {
			case 1:
				return (this.electrolysisPPM || this.isPanel310 || this.isPanel320 || this.hasPpm);
			case 2:
				let allowed: any;

				if (this.ds2Family === 1) {
					allowed = [
						['03', '05', '06', '07', '0B', '0D', '0E', '0F'],
						['22', '24', '25', '26', '29', '2B', '2C', '2D', '30', '32', '33', '34'],
						['43', '45', '46', '47', '4B', '4D', '4E', '4F'],
						['66', '67', '6A', '6B', '6C', '6D', '6E', '6F', '73', '75', '76', '77', '78', '7A', '7B', '7C', '7D', '7E', '7F']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				} else {
					return this.vars.ms.charAt(2) === '1';
				}
		}
	}

	get electrolysisStatus() {
		if (this.series === 1) {
			if (this.vars.mm && this.vars.mm.length === 8) {
				return +this.vars.mm[7];
			} else {
				return 0;
			}
		} else {
			return 1;
		}
	}

	get internalPhStatus() {
		if (this.vars.mm === '--------') {
			return 0;
		} else {
			switch (this.series) {
				case 1:
					return +(['L', 'P', 'H'].indexOf(this.vars.mm[6]) !== -1);
				case 2:
					if (this.ds2Family === 1) {
						const allowed = [
							['01', '04', '05', '07', '09', '0C', '0D', '0F'],
							['20', '23', '24', '26', '27', '2A', '2B', '2D', '2E', '30', '31', '32', '34'],
							['41', '44', '45', '47', '49', '4C', '4D', '4F'],
							['62', '63', '68', '69', '6A', '6B', '6E', '6F', '71', '74', '75', '77', '78', '79', '7A', '7C', '7D', '7E', '7F']
						];

						return +(_.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
					} else {
						return +(this.vars.ms.charAt(0) === '1');
					}
			}
		}
	}

	get phStatus() {
		let panelType: number;

		if (this.vars.mm === '--------') {
			if (+this.vars.ms[0] > 0) {
				return +this.vars.ms[0];
			} else {
				panelType = +this.vars.ms[3];

				if (panelType > 0) {
					if ((panelType % 2) > 0) {
						return 1;
					} else {
						return 0;
					}
				} else {
					return 0;
				}
			}
		} else {
			switch (this.series) {
				case 1:
					let status: number;

					if (this.vars.mm[6] !== '0') {
						let phInDevice = ['L', 'P', 'H'].indexOf(this.vars.mm[6]) !== -1;

						if (!phInDevice) {
							if (+this.vars.ms[0] > 0) {
								status = +this.vars.ms[0];
							} else {
								panelType = +this.vars.ms[3];

								if (panelType > 0) {
									if ((panelType % 2) > 0) {
										status = 1;
									} else {
										status = 0;
									}
								} else {
									status = 0;
								}
							}
						} else {
							status = 1;
						}

						return status;
					} else {
						return 0;
					}
				case 2:
					if (this.ds2Family === 1) {
						const allowed = [
							['01', '04', '05', '07', '09', '0C', '0D', '0F'],
							['20', '23', '24', '26', '27', '2A', '2B', '2D', '2E', '30', '31', '32', '34'],
							['41', '44', '45', '47', '49', '4C', '4D', '4F'],
							['62', '63', '68', '69', '6A', '6B', '6E', '6F', '71', '74', '75', '77', '78', '79', '7A', '7C', '7D', '7E', '7F']
						];

						return +(_.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
					} else {
						return +(this.vars.ms.charAt(0) === '1');
					}
			}
		}
	}

	get internalOrpStatus() {
		if (this.vars.mm === '--------') {
			return 0;
		} else {
			switch (this.series) {
				case 1:
					return +(['L'].indexOf(this.vars.mm[6]) !== -1);
				case 2:
					if (this.ds2Family === 1) {
						const allowed = [
							['02', '04', '06', '07', '0A', '0C', '0E', '0F'],
							['21', '23', '25', '26', '28', '2A', '2C', '2D', '2F', '31', '33', '34'],
							['42', '44', '46', '47', '4A', '4C', '4E', '4F'],
							['64', '65', '68', '69', '6C', '6D', '6E', '6F', '72', '74', '76', '77', '78', '7A', '7C', '7E', '7F']
						];

						return +(_.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
					} else {
						return +(this.vars.ms.charAt(1) === '1');
					}
			}
		}
	}

	get orpStatus() {
		let panelType: number;

		if (this.vars.mm === '--------') {
			if (+this.vars.ms[1] > 0) {
				return +this.vars.ms[1];
			} else {
				panelType = +this.vars.ms[3];

				if (panelType > 0) {
					switch (panelType) {
						case 1:
						case 5:
							return 1;
						default:
							return 0;
					}
				} else {
					return 0;
				}
			}
		} else {
			switch (this.series) {
				case 1:
					let status: number;

					if (this.vars.mm[6] !== '0') {
						let orpInDevice = ['L'].indexOf(this.vars.mm[6]) !== -1;

						if (!orpInDevice) {
							if (+this.vars.ms[1] > 0) {
								status = +this.vars.ms[1];
							} else {
								panelType = +this.vars.ms[3];

								if (panelType > 0) {
									switch (panelType) {
										case 1:
										case 5:
											status = 1;
											break;
										default:
											status = 0;
											break;
									}
								} else {
									status = 0;
								}
							}
						} else {
							status = 1;
						}

						return status;
					} else {
						return 0;
					}
				case 2:
					if (this.ds2Family === 1) {
						const allowed = [
							['02', '04', '06', '07', '0A', '0C', '0E', '0F'],
							['21', '23', '25', '26', '28', '2A', '2C', '2D', '2F', '31', '33', '34'],
							['42', '44', '46', '47', '4A', '4C', '4E', '4F'],
							['64', '65', '68', '69', '6C', '6D', '6E', '6F', '72', '74', '76', '77', '78', '7A', '7C', '7E', '7F']
						];

						return +(_.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
					} else {
						return +(this.vars.ms.charAt(1) === '1');
					}
			}
		}
	}

	get internalPpmStatus() {
		if (this.vars.mm === '--------') {
			return 0;
		} else {
			switch (this.series) {
				case 1:
					return +(['H'].indexOf(this.vars.mm[6]) !== -1);
				case 2:
					if (this.ds2Family === 1) {
						const allowed = [
							['03', '05', '06', '07', '0B', '0D', '0E', '0F'],
							['22', '24', '25', '26', '29', '2B', '2C', '2D', '30', '32', '33', '34'],
							['43', '45', '46', '47', '4B', '4D', '4E', '4F'],
							['66', '67', '6A', '6B', '6C', '6D', '6E', '6F', '73', '75', '76', '77', '78', '7A', '7B', '7C', '7D', '7E', '7F']
						];

						return +(_.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
					} else {
						return +(this.vars.ms.charAt(2) === '1');
					}
			}
		}
	}

	get ppmStatus() {
		let panelType: number;

		if (this.vars.mm === '--------') {
			if (+this.vars.ms[2] > 0) {
				return +this.vars.ms[2];
			} else {
				panelType = +this.vars.ms[3];

				if (panelType > 0) {
					switch (panelType) {
						case 3:
						case 5:
							return 1;
						default:
							return 0;
					}
				} else {
					return 0;
				}
			}
		} else {
			switch (this.series) {
				case 1:
					let status: number;

					if (this.vars.mm[6] !== '0') {
						let ppmInDevice = ['H'].indexOf(this.vars.mm[6]) !== -1;

						if (!ppmInDevice) {
							if (+this.vars.ms[2] > 0) {
								status = +this.vars.ms[2];
							} else {
								panelType = +this.vars.ms[3];

								if (panelType > 0) {
									switch (panelType) {
										case 3:
										case 5:
											status = 1;
											break;
										default:
											status = 0;
											break;
									}
								} else {
									status = 0;
								}
							}
						} else {
							status = 1;
						}

						return status;
					} else {
						return 0;
					}
				case 2:
					if (this.ds2Family === 1) {
						const allowed = [
							['03', '05', '06', '07', '0B', '0D', '0E', '0F'],
							['22', '24', '25', '26', '29', '2B', '2C', '2D', '30', '32', '33', '34'],
							['43', '45', '46', '47', '4B', '4D', '4E', '4F'],
							['66', '67', '6A', '6B', '6C', '6D', '6E', '6F', '73', '75', '76', '77', '78', '7A', '7B', '7C', '7D', '7E', '7F']
						];

						return +(_.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1);
					} else {
						return +(this.vars.ms.charAt(2) === '1');
					}
			}
		}
	}

	get power(): number {
		if (this.series === 1) {
			if (this.vars && this.vars.mm && this.vars.mm !== '' && this.vars.mm.length === 8) {
				return parseInt(String(this.vars.mm).substring(3, 6), 10);
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	get isPanel300() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return [1, 2].indexOf(parseInt(this.vars.ms[3], 10)) !== -1;
		}

		return false;
	}

	get isPanel310() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return [3, 4].indexOf(parseInt(this.vars.ms[3], 10)) !== -1;
		}

		return false;
	}

	get isPanel320() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return [5, 6].indexOf(parseInt(this.vars.ms[3], 10)) !== -1;
		}

		return false;
	}

	get isPanel3XX() {
		if (this.vars.ms && this.vars.ms.length === 6) {
			return +this.vars.ms[3] > 0;
		}

		return false;
	}

	get regModel() {
		let ms = this.vars.ms.substr(0, 3);
		let model: any = {
			ph: null,
			orp: null,
			ppm: null
		};

		if (ms !== '---' && ms !== '') {
			switch (this.series) {
				case 1:
					switch (ms) { // PH
						case '100':
						case '200':
							if (this.vars.modelo_regulador.indexOf('RPH-201') > -1) {
								model.ph = 'RPH-201';
							} else {
								model.ph = 'RPH-200';
							}
							break;
						case '110':
						case '220':
							if (this.vars.modelo_regulador.indexOf('RPH-201') > -1) {
								model.ph = 'RPH-201';
							} else if (this.vars.modelo_regulador.indexOf('PR-206') > -1) {
								model.ph = 'PR-206';
							} else {
								model.ph = 'RPH-200';
							}
							break;
						case '101':
						case '202':
						case '111':
						case '222':
							if (this.vars.modelo_regulador.indexOf('RPH-201') > -1) {
								model.ph = 'RPH-201';
							} else if (this.vars.modelo_regulador.indexOf('PR-207') > -1) {
								model.ph = 'PR-207';
							} else {
								model.ph = 'RPH-200';
							}
							break;
					}

					switch (ms) { // ORP
						case '010':
						case '020':
							if (this.vars.modelo_regulador.indexOf('RMV-201') > -1) {
								model.orp = 'RMV-201';
							} else {
								model.orp = 'RMV-200';
							}
							break;
						case '110':
						case '220':
						case '111':
						case '222':
							if (this.vars.modelo_regulador.indexOf('RMV-201') > -1) {
								model.orp = 'RMV-201';
							} else if (this.vars.modelo_regulador.indexOf('PR-206') > -1) {
								model.orp = 'PR-206';
							} else {
								model.orp = 'RMV-200';
							}
							break;
					}

					switch (ms) { // PPM
						case '001':
						case '002':
							if (this.vars.modelo_regulador.indexOf('RCL-201') > -1) {
								model.orp = 'RCL-201';
							} else {
								model.orp = 'RCL-200';
							}
							break;
						case '101':
						case '202':
						case '111':
						case '222':
							if (this.vars.modelo_regulador.indexOf('RCL-201') > -1) {
								model.orp = 'RCL-201';
							} else if (this.vars.modelo_regulador.indexOf('PR-207') > -1) {
								model.ppm = 'PR-207';
							} else {
								model.ppm = 'RCL-200';
							}
							break;
					}
					break;
				case 2:
					if (this.ds2Family === 1) {
						model.ph = (this.isPH) ? 'PH' : null;
						model.orp = (this.isORP) ? 'ORP' : null;
						model.ppm = (this.isPPM) ? 'PPM' : null;
					} else {
						model.ph = ms.charAt(0) === '1' ? 'PH' : null;
						model.orp = ms.charAt(1) === '1' ? 'ORP' : null;
						model.ppm = ms.charAt(2) === '1' ? 'PPM' : null;
					}
					break;
			}
		}

		return model;
	}

	get model() {
		let models: String[] = [];

		switch (this.series) {
			case 1:
				const type = this.vars.mm.substr(0, 3);
				const variation = this.vars.mm.charAt(6);

				switch (type) {
					case 'SPA':
						models.push('Spa');
						break;
					case 'DOM':
						switch (variation) {
							case 'L':
								models.push('DomPLUS');
								break;
							case 'P':
								models.push('DomPH');
								break;
							case 'E':
								models.push('Dom');
								break;
						}
						break;
					case 'IND':
						const familyPower = [50, 70, 90, 180].indexOf(this.power) !== -1 ? 'domIND' : 'domEx';

						switch (variation) {
							case 'L':
								models.push(familyPower + '+');
								break;
							case 'P':
								models.push(familyPower + 'PH');
								break;
							case 'E':
								models.push(familyPower);
								break;
						}
						break;
				}

				if (this.regModel.ph) {
					models.push(this.regModel.ph);
				}

				if (this.regModel.orp) {
					models.push(this.regModel.orp);
				}

				if (this.regModel.ppm) {
					models.push(this.regModel.ppm);
				}

				if (this.prModel !== '') {
					models.push(this.prModel);
				}

				if (this.isLumiplus) {
					models.push('Lumiplus');
				}

				if (this.isUV) {
					models.push('Neo');
				}

				/* if ((this.hasOrp)) {
					models.push('RMV-200');
				}

				if (this.hasPh) {
					models.push('RPH-200');
				}

				if (this.ppm) {
					models.push('RCL-200');
				}

				if (this.prModel !== '') {
					models.push(this.prModel);
				}

				if (this.isUV) {
					models.push('neo');
				}

				if (this.isSPA) {
					models.push('spa');
				}

				if (this.isIndustrial) {
					models.push(this.industrialModel());
				}

				if (this.isDomotic) {
					models.push(this.domoticModel());
				} */
				break;
			case 2:
				models.push('DS2' + this.vars.mm.substr(3, 3));
				break;
		}

		return models;
	}

	get abbreviationModel() {
		let model: string = null;

		if (this.series === 2) {
			let intValue: number = -1;
			model = this.vars.mm.substr(6, 2);
			const cloro = parseInt(this.vars.mm.substr(3, 3));
			intValue = parseInt(model, 16);
			const models = {
					0:`ELEC${cloro}`,
					32:"SENSORS",
					64:`NEO${cloro}`,
					96: "UV",
					97: "UV",
					144: "SENSORS",
					160: `ELEC${cloro}LS`,
					192: `NEO${cloro}LS`,
			}
			model = models[intValue] ?? "IDEGIS";
		}

		return model;
	}

	get isUV() {
		switch (this.series) {
			case 1:
				if (this.vars.ms && this.vars.ms.length === 6) {
					return +this.vars.ms[4] > 0;
				}
				return false;
			case 2:
				let allowed: any;

				if (this.ds2Family === 1) {
					allowed = [
						['40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '4A', '4B', '4C', '4D', '4E', '4F'],
						['60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '6A', '6B', '6C', '6D', '6E', '6F'],
						['70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '7A', '7B', '7C', '7D', '7E', '7F'],
						['C0', 'C8']
					]
				} else {
					allowed = [
						['40', 'C0', '60', '61']
					];
				}

				return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
		}
	}

	get isLowSal() {
		switch (this.series) {
			case 1:
				return false;
			case 2:
				let allowed: any;

				if (this.ds2Family === 1) {
					return null;
				} else {
					allowed = [
						['A0', 'A8', 'C0', 'C8']
					];

					return _.chain(allowed).flattenDeep().indexOf(this.encodedOptions).value() !== -1;
				}
		}
	}

	get isSPA() {
		if (this.vars.mm && this.vars.mm.length === 8) {
			return this.vars.mm.substr(0, 3) === 'SPA';
		}

		return false;
	}

	get isIndustrial() {
		if (this.vars.mm && this.vars.mm.length === 8) {
			return this.vars.mm.substr(0, 3) === 'IND';
		}

		return false;
	}

	get isDomotic() {
		if (this.vars.mm && this.vars.mm.length === 8) {
			return this.vars.mm.substr(0, 3) === 'DOM';
		}

		return false;
	}

	get isDS2() {
		if (this.vars.mm && this.vars.mm.length === 8) {
			return this.vars.mm.substr(0, 3) === 'DS2';
		}

		return false;
	}

	get prModel(): string {
		let model = '';

		if (this.vars.ms && this.vars.ms.length === 6) {
			switch (+this.vars.ms[3]) {
				case 1:
				case 2:
					model = 'PR-300';
					break;
				case 3:
				case 4:
					model = 'PR-310';
					break;
				case 5:
				case 6:
					model = 'PR-320';
					break;
			}
		}

		return model;
	}

	get electrolysisModel(): string {
		let parsedPower = this.power;
		let model = '';

		if (this.vars.mm && this.vars.mm.length === 8) {
			let type = String(this.vars.mm).substring(0, 3);

			switch (type) {
				case 'IND':
					model = [50, 70, 90, 180].indexOf(parsedPower) !== -1 ? 'D' : 'EX';
					break;
				default:
					model = type;
					break;
			}

			if (model === 'EX') {
				if (parsedPower % 2 !== 0) {
					parsedPower--;
				}
			}

			switch (this.vars.mm[6]) {
				case 'L':
					if (type === 'D') {
						model += '-' + parsedPower;
					} else {
						if (model === 'SPA') {
							model = 'DOM' + parsedPower + '+SPA';
						} else {
							model = model + '-' + parsedPower + ' +';
						}
					}
					break;
				case 'P':
					model += '-' + parsedPower + ' pH';
					break;
				case 'E':
					model += '-' + parsedPower;
					break;
				case 'H':
					model += '-' + parsedPower + ' PPM';
					break;
				default:
					model = model;
					break;
			}
		}

		return model;
	}

	public industrialModel() {
		if (this.vars.mm) {
			if (this.isIndustrial) {
				if (this.power === 50 || this.power === 70 || this.power === 90 || this.power === 180) {
					return 'domIND' + this.vars.mm[6];
				} else {
					return 'domEx' + this.vars.mm[6];
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public domoticModel() {
		let dom = this.classDomotic();

		if (dom) {
			if (dom.length > 0) {
				return dom;
			} else {
				return null;
			}
		} else {
			if (this.isSPA) {
				return this.classSPA();
			} else if (this.isIndustrial) {
				return this.industrialModel();
			}

			return null;
		}
	}

	public classDomotic() {
		if (this.vars.mm) {
			if (this.vars.mm.substr(0, 3) === 'DOM') {
				switch (this.vars.mm[6]) {
					case 'L':
						return 'domPLUS';
					case 'P':
						return 'domPH';
					case 'H':
						return 'domPH PPM';
					case 'E':
						return 'dom';
					default:
						return '';
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public classSPA() {
		return (this.vars.mm && this.vars.mm.substr(0, 3) === 'SPA') ? 'domPLUS spa' : null;
	}

	get ds2Model() {
		if (this.series === 2) {
			const model: string = this.vars.mm.substr(3, 3);

			return parseInt(model, 10);
		}

		return null;
	}

	get series(): number {
		return this.vars.mm.substring(0, 3) === 'DS2' ? 2 : 1;
	}

	get alarms() {
		let alarms = [];
		let keys = (this.series === 1) ? this._ds1alarmsSignals() : this._ds2alarmsSignals();
		let labels = {
			sf: 'FLOW',
			wp: 'PH',
			dp: 'NIVELTANQUEMINORADORPH',
			pe: 'DOSISANQUEMINORADORPH',
			wo: 'ORP',
			do: 'NIVELTANQUEHIPO',
			wh: 'CLORO',
			oe: 'PE_STOP',
			dc: 'NIVELTANQUEHIPO',
			he: 'DOSISTANQUEHIPO',
			cc: 'CELL',
			ws: 'CONDUCTIVIDAD',
			bp: 'ALARMA_FU_PH',
			cp: 'REVISAR_BOMBA_PH',
			po: 'REVISAR_BOMBA_HIPO',
			ch: 'REVISAR_BOMBA_HIPO',
			bo: 'ALARMA_FU_ORP',
			bh: 'ALARMA_FU_PPM',
			at: 'ALARMA_TEMP',
			as: 'ALARM_SAL',
			bu: 'ALARMA_BALA_UV',
			fu: 'ALARMA_FU_UV'
		};

		for (let key of keys) {
			if (key === 'sf' && this.isDS2) {
				alarms.push({
					label: labels[key],
					value: this.vars.ac === '0' ? 0 : parseInt(this.vars.sf, 10)
				});
			} else {
				let value = ((key === 'wp' || key === 'wh' || key === 'wo') && this.vars[key] === 'I') ? 0 : this.vars[key];

				alarms.push({
					label: labels[key],
					value: isNaN(value) ? value : ((value === true || value === false) ? value : parseInt(value, 10))
				});
			}
		}

		return alarms;
	}

	get d1() {
		return {
			value: parseInt(this.vars.d1, 2),
			name: this.d1_name,
			alarms: {
				open: this.d1_alarma >= 2 ? true : false,
				close: this.d1_alarma === 1 || this.d1_alarma === 3 ? true : false
			}
		};
	}

	get d2() {
		return {
			value: parseInt(this.vars.d2, 2),
			name: this.d2_name,
			alarms: {
				open: this.d2_alarma >= 2 ? true : false,
				close: this.d2_alarma === 1 || this.d2_alarma === 3 ? true : false,
			}
		};
	}

	get d3() {
		return {
			value: parseInt(this.vars.d3, 2),
			name: this.d3_name,
			alarms: {
				open: this.d3_alarma >= 2 ? true : false,
				close: this.d3_alarma === 1 || this.d3_alarma === 3 ? true : false
			}
		};
	}

	get d4() {
		return {
			value: parseInt(this.vars.d4, 2),
			name: this.d4_name,
			alarms: {
				open: this.d4_alarma >= 2 ? true : false,
				close: this.d4_alarma === 1 || this.d4_alarma === 3 ? true : false,
			}
		}
	}

	get a1() {
		return {
			name: this.a1_name,
			min: this['a1_min'],
			minChart: this['a1_min_grafica'],
			max: this['a1_max'],
			maxChart: this['a1_max_grafica'],
			cal: this['a1_cal'],
			unit: this.vars.a1 >= 2 ? this['a1_unidad'] : null,
			decimals: (this['a1_unidad'] === 'C' || this['a1_unidad'] === 'F') ? 1 : 2,
			measure: this._analogicMeasure(1)
		};
	}

	get a2() {
		return {
			name: this.a2_name,
			min: this['a2_min'],
			minChart: this['a2_min_grafica'],
			max: this['a2_max'],
			maxChart: this['a2_max_grafica'],
			cal: this['a2_cal'],
			unit: this.vars.a2 >= 2 ? this['a2_unidad'] : null,
			decimals: (this['a2_unidad'] === 'C' || this['a2_unidad'] === 'F') ? 1 : 2,
			measure: this._analogicMeasure(2)
		};
	}

	get activationDate() {
		const dateInSource: string = this['fecha_activado'];
		const yearIsNegative: boolean = dateInSource.startsWith('-');
		if (yearIsNegative) {
			return null;
		}

		const activationDate = moment(dateInSource);

		return activationDate.isValid() ? activationDate : null;
	}

	get isUpdated() {
		if (this.vars) {
			let diff: number;
			let updatedDate = moment().tz('UTC');
			//let updatedDate = (this.vars.updated) ? moment(this.vars.updated, 'YYYY-MM-DD HH:mm:ss') : null;

			if (this.vars && this.vars.updated && this.vars.updated !== '') {
				updatedDate.set({
					year: this.vars.updated.substr(0, 4),
					month: +this.vars.updated.substr(5, 2) - 1,
					date: this.vars.updated.substr(8, 2),
					hour: this.vars.updated.substr(11, 2),
					minute: this.vars.updated.substr(14, 2),
					second: this.vars.updated.substr(17, 2)
				});
			} else {
				updatedDate = null;
			}

			(updatedDate) ? diff = ((moment().tz('UTC').valueOf() - updatedDate.valueOf()) / 1000) : diff = 121;

			return (diff < 120);
		} else {
			return false;
		}
	}

	get status(): any {
		let labels: string[] = ['DISPOSITIVO_DESCONECTADO', 'DISPOSITIVO_CONECTADO', 'MODO_LENTO', 'DISPOSITIVO_NO_ASOCIADO', 'MODO_PERSONALIZADO'];
		let icons: string[] = ['plug', 'thumbs-up', 'thumbs-up', 'chain-broken', 'cogs'];
		let colors: string[] = ['red', 'green', '#eeda00', '#eeda00', '#43a9c9'];
		let value: number = 3;

		if (this.activo) {
			if (!this.isUpdated) {
				if (this.user) {
					value = (this.isDemo) ? 1 : 0;
				}
			} else {
				if (this.personalized && this.personalized.vel === this.device_ep) {
					value = 4;
				} else {
					value = +this.device_ep < +this.default_eco ? 1 : 2;
				}
			}
		} else {
			value = 0;
		}

		return {
			value,
			label: labels[value],
			icon: icons[value],
			color: colors[value],
			updated: this.isUpdated
		};
	}

	get lastConnection() {
		const lastConnection = moment();
		let updated: any;

		if (this.vars && this.vars.updated) {
			updated = new Date(this.vars.updated);

			if (updated.getFullYear() < 3000) {
				lastConnection.tz('UTC');
				lastConnection.set('year', updated.getFullYear());
				lastConnection.set('month', updated.getMonth());
				lastConnection.set('date', updated.getDate());
				lastConnection.set('hour', updated.getHours());
				lastConnection.set('minute', updated.getMinutes());
				lastConnection.set('second', updated.getSeconds());

				return lastConnection.tz(this.timezone).format('YYYY-MM-DD HH:mm:ss');
			} else {
				return '0000-00-00 00:00:00';
			}
		} else {
			return '0000-00-00 00:00:00';
		}
	}

	public generateActivationCode() {
		let code = [];

		for (let i = 0; i < 4; i++) {
			code.push(this._randomString(5));
		}

		return code.join('-');
	}

	get specialStatus() {
		let result = {
			offline: this.status.value === 0,
		};

		if (this.isDS2) {
			result['flow'] = (this.vars.sf === '0' && this.vars.ac === '1');
			result['prodUp30'] = parseInt(this.vars.pa, 10) >= 30;
			result['ac'] = this.vars.ac !== '0';
		} else {
			result['flow'] = true;
			result['prodUp30'] = true;
			result['ac'] = true;
		}

		return result;
	}

	get operationMod() {
		return +this.device_ep > +this.default_turbo ? 'ECO' : 'TURBO';
	}

	get personalized() {
		return (this._personalized && this._personalized !== '') ? JSON.parse(this._personalized) : null;
	}

	get booleanVars() {
		return {
			fe: this.vars.fe !== '-' ? !!+this.vars.fe : false,
			ei: this.vars.ei !== '-' ? !!+this.vars.ei : false,
			ee: this.vars.ee !== '-' ? !!+this.vars.ee : false,
			ae: this.vars.ae !== '-' ? !!+this.vars.ae : false,
			se: this.vars.se !== '-' ? !!+this.vars.se : false,
			si: this.vars.si !== '-' ? !!+this.vars.si : false,
			sg: this.vars.sg !== '-' ? !!+this.vars.sg : false,
			lu: this.vars.lu !== '-' ? !!+this.vars.lu : false,
			bu: this.vars.bu !== '-' ? !!+this.vars.bu : false,
			fu: this.vars.fu !== '-' ? !!+this.vars.fu : false
		};
	}

	constructor(raw: any) {
		for (let prop in raw) {
			if (raw.hasOwnProperty(prop)) {
				if (['relays'].indexOf(prop) !== -1) {
					this['_' + prop] = raw[prop];
				} else if (['personalized'].indexOf(prop) !== -1) {
					this._personalized = raw['personalized'];
				} else {
					this[prop] = raw[prop];
				}
			}
		}

		if (this.id) {
			let times: any;

			if (this.vars.hr && this.vars.hs && this.vars.hr !== '' && this.vars.hs !== '') {
				times = {};
				times.sunrise = new Date();
				times.sunrise.setHours(this.vars.hr.substr(0, 2));
				times.sunrise.setMinutes(this.vars.hr.substr(2, 2));
				times.sunset = new Date();
				times.sunset.setHours(this.vars.hs.substr(0, 2));
				times.sunset.setMinutes(this.vars.hs.substr(2, 2));
			} else {
				times = SunCalc.getTimes(new Date(), this.lat, this.longitud);
			}

			this.sunrise = this._nearestQuarter(times.sunrise);
			this.sunset = this._nearestQuarter(times.sunset);

			for (let key of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
				let program = new Program(raw['vars']['p' + key], raw['vars']['p' + key + 'anterior'], raw['vars']['p' + key + 'crepuscular'], this.sunrise, this.sunset);
				this.programs.push(program);
			}

			// relays
			let signs: string[];

			// series
			switch (this.series) {
				case 2:
					signs = ['mc', 'mj', 'mb', 'mf', null, null];
					break;
				default:
					signs = ['mc', 'mj', 'mb', 'mf', 'mx', 'ml'];
					break;
			}

			for (let sign of signs) {
				let relay = this._relays.find((o) => o.sign === sign);

				if (relay) {
					relay.series = this.series;
				}

				this.relays.push(relay ? new Relay(relay, this.vars[sign]) : null);
			}
		} else {
			this.id = null;
			this.ns = null;

			this.register = {
				fecha_insert: moment().format(),
				fecha_registro: moment().format(),
				id: null,
				id_device: null,
				mac: null,
				ns: null,
				customer: 0,
				hw_pot: 0,
				hw_tft: 0,
				low_salt: false,
				ns_tft: '',
				transformer: 0,
				transformer_manufacturer: 0,
			};
		}
	}

	public getPrograms(sign: string) {
		let programs = [];
		let map = {
			0: '00',
			1: 'A0',
			2: 'B0',
			3: '10',
			4: '0A',
			5: 'AA',
			6: 'BA',
			7: '1A',
			8: '0B',
			9: 'AB',
			A: 'BB',
			B: '1B',
			C: '01',
			D: 'A1',
			E: 'B1',
			F: '11',
		};

		for (let day = 0; day < 7; day++) {
			let relay: any;
			let programNumber = parseInt(this.vars['p' + sign[1]][day], 10);
			let program = {
				sign,
				number: programNumber > 0 && programNumber < 10 ? programNumber : 'x',
				current: programNumber > 0 && programNumber < 10 ? this.vars['p' + programNumber] : '00000000000000000000000000000000',
				prev: (sign !== 'mu') ? this.vars['p' + programNumber + 'anterior'] : '00000000000000000000000000000000',
				crepuscular: (sign !== 'mu') ? this.vars['p' + programNumber + 'crepuscular'] : '00000000000000000000000000000000',
				coded: null,
				consumption: 0,
				colors: []
			};
			let isCrepuscular: boolean = false;

			if (sign !== 'mu') {
				if (this.vars['p' + programNumber + 'crepuscular']) {
					if (this.vars['p' + programNumber + 'crepuscular'][0] === 1) {
						isCrepuscular = true;
					}
				}
			}

			relay = (sign !== 'mu') ? _.find(this._relays, { sign }) : null;
			program.coded = _.map(program.current, (o) => map[o + '']).join('');
			program.colors = _.map(program.coded, (o) => {
				switch (o) {
					case '0':
						return 'white';
					case '1':
						return isCrepuscular ? 'blue' : 'yellow';
					case 'A':
						return (isCrepuscular ? 'blue' : 'yellow') + 'white';
					case 'B':
						return 'white' + (isCrepuscular ? 'blue' : 'yellow');
					default:
						return 'white';
				}
			});

			program.consumption = (sign !== 'mu') ? this._programWattios(program, relay['consumo'] / 1000) : null;
			programs.push(program);
		}

		return programs;
	}

	public updatePrograms(programs: any) {
		let vars = this.vars;

		this.programs = [];

		for (let index of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
			let program: any;

			this.vars['p' + index] = (programs['p' + index]) ? programs['p' + index] : this.vars['p' + index];
			this.vars['p' + index + 'anterior'] = (programs['p' + index + 'anterior']) ? programs['p' + index + 'anterior'] : this.vars['p' + index + 'anterior'];
			this.vars['p' + index + 'crepuscular'] = (programs['p' + index + 'crepuscular']) ? programs['p' + index + 'crepuscular'] : this.vars['p' + index + 'crepuscular'];
			program = new Program(vars['p' + index], vars['p' + index + 'anterior'], vars['p' + index + 'crepuscular'], this.sunrise, this.sunset);
			this.programs.push(program);
		}
	}

	public hoursDesconected() {
		if (this.vars && this.vars.updated) {
			let diff: number;
			let hours: number;
			let updatedDate = moment(this.vars.updated, 'YYYY-MM-DD HH:mm:ss').valueOf();

			(updatedDate) ? diff = (((moment().valueOf() - ((moment().utcOffset() * 60) * 1000)) - updatedDate) / 1000) : diff = 121;
			hours = (diff < 120) ? 0 : diff / 60 / 60;

			return {
				hours,
				limitReached: hours > this.max_off_hours
			};
		} else {
			return {
				hours: Number.MAX_SAFE_INTEGER,
				limitReached: true
			};
		}
	}

	private _programWattios(program, consumption) {
		let total = 0;

		for (let item of program.current) {
			switch (item) {
				// Solo un cuarto de hora
				case '1':
				case '2':
				case '4':
				case '8':
					total += 0.25;
					break;
				// Solo media hora
				case '3':
				case 'C':
				case '5':
				case '6':
				case '9':
				case 'A':
					total += 0.5;
					break;
				// Tres cuartos de hora.
				case '7':
				case 'B':
				case 'D':
				case 'E':
					total += 0.75;
					break;
				// Hora completa.
				case 'F':
					total += 1;
					break;
				default:
					break;
			}
		}

		return total * consumption;
	}

	private _analogicMeasure(key: number) {
		let measure = null;

		if (this.vars['a' + key] < 2) {
			measure = 'N/A';
		} else if (this.vars['a' + key] <= this.MIN_LAZO) {
			measure = this.vars['a' + key].a1_min;
		} else if (this.vars['a' + key] > this.MIN_LAZO && this.vars['a' + key] <= this.MIN4MA_IEZ70) {
			measure = this.vars['a' + key].a1_min;
		} else if (this.vars['a' + key] > this.MAX20MA_IEZ70 && this.vars['a' + key] <= this.MAX_LAZO) {
			measure = this.vars['a' + key].a1_max;
		} else if (this.vars['a' + key] > this.MAX_LAZO) {
			measure = this.vars['a' + key].a1_max;
		} else {
			measure = this._decodeNumericInput(this.vars['a' + key], this['a' + key + '_min'], this['a' + key + '_max'], this['a' + key + '_cal'], this['a' + key + '_unidad']);
		}

		if (!measure) {
			measure = this._decodeNumericInput(this.vars['a' + key], this['a' + key + '_min'], this['a' + key + '_max'], this['a' + key + '_cal'], this['a' + key + '_unidad']);
		}

		return measure;
	}

	private _decodeNumericInput(digitalData: number, min: number, max: number, calibrated: number, units: string) {
		let value;
		let unitsData: any = this._getDecimals(units);

		if (isNaN(digitalData) || isNaN(min) || isNaN(max) || isNaN(calibrated)) {
			value = 0;
		} else {
			let current = 0.0344 * (digitalData + calibrated) + 2.64;
			let m = (max - min) / 16;
			let b = max - (20 * m);
			value = parseFloat((current * m + b).toFixed(unitsData.decimals));

			if (value > max) {
				value = parseFloat(max.toFixed(unitsData.decimals));
			} else if (value < min) {
				value = parseFloat(min.toFixed(unitsData.decimals));
			}
		}

		return value;
	}

	private _getDecimals(units: string): Object {
		let sufix: string = '';
		let nDecimals: number = 2;

		switch (units) {
			case 'C':
				sufix = '°C';
				nDecimals = 1;
				break;
			case 'F':
				sufix = '°F';
				nDecimals = 1;
				break;
			case 'bar':
				sufix = 'bar';
				break;
			case 'psi':
				sufix = 'psi';
				break;
			case 'lux':
				sufix = 'lux';
				break;
			case '%':
				sufix = '%';
				break;
			case 'NTU':
				sufix = 'NTU';
				break;
			case 'g_l':
				sufix = 'g/L';
				break;
				case 'microS_cm':
						sufix = 'g/L';
						break;
			case 'mg_l':
				sufix = 'mg/L';
				break;
			case 'ppm':
				sufix = 'ppm';
				break;
			case 'mA':
				sufix = 'mA';
				break;
			case ' ':
				sufix = '';
				break;
			default:
				sufix = units;
		}

		return { unit: sufix, decimals: nDecimals };
	}

	private _randomString(length) {
		let text = '';
		let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	}

	private _nearestQuarter(date: Date) {
		let momentDate = moment(date);
		let remainder = 15 - momentDate.minute() % 15;

		remainder = remainder < 8 ? remainder : -1 * (15 - remainder);

		return moment(momentDate).add(remainder, 'minutes');
	}

	private _ds1alarmsSignals() {
		let result: any = [];

		if (this.hasPh) {
			result = ['sf', 'wp', 'dp', 'pe'/*, 'mp', 'sp'*/];
		}

		if (this.hasOrp) {
			result = _.union(result, ['sf', 'wo', 'do', 'oe'/*, 'mo', 'so'*/]);
		}

		if (this.hasPpm) {
			result = _.union(result, ['sf', 'wh', /*'dc',*/ 'he'/*, 'mh', 'sh'*/]);
		}

		if (this.isPanel320) {
			result = _.union(result, ['sf', 'wp', 'dp', 'pe', 'wo', 'do', 'oe', 'wh', /*'dc',*/ 'he'/*, 'mp', 'sp',*/ /*'mo', 'so',*/ /*'mh', 'sh', 'ta'*/]);
		}

		if (this.isPanel310) {
			result = _.union(result, ['sf', 'wp', 'dp', 'pe', 'wh', /*'dc',*/ 'he'/*, 'mp', 'sp',*/ /*'mh', 'sh', 'ta'*/]);
		}

		if (this.isPanel300) {
			result = _.union(result, ['sf', 'wp', 'dp', 'pe', 'wo', 'do', 'oe'/*, 'mp', 'sp',*/ /*'mo', 'so', 'ta'*/]);
		}

		result = _.uniq(result);

		if (this.electrolysis) {
			result = _.chain(result).concat(['sf', 'cc', 'ws']).uniq().value();
		} else if (this.electrolysisPPM) {
			result = _.chain(result).concat(['sf', 'cc', 'ws', 'wp', 'pe', 'wh']).uniq().value();
		} else if (this.electrolysisORP) {
			result = _.chain(result).concat(['sf', 'cc', 'ws', 'wp', 'pe', 'wo']).uniq().value();
		} else if (this.electrolysisPH) {
			result = _.chain(result).concat(['sf', 'cc', 'ws', 'wp', 'pe']).uniq().value();
		}

		return result;
	}

	private _ds2alarmsSignals() {
		let result = ['sf', 'at'];

		if (this.isPH) {
			result = _.concat(result, ['wp', 'bp', 'cp', 'pe', 'dp']);
		}

		if (this.isORP && !this.isPPM) {
			result = _.concat(result, 'wo');

			if (!this.isElectrolysis) {
				result = _.concat(result, ['oe', 'do', 'po', 'bo']);
			}
		}

		if (this.isPPM) {
			result = _.concat(result, 'wh');

			if (!this.isElectrolysis) {
				result = _.concat(result, ['he', 'dc', 'ch', 'bh']);
			}
		}

		if (this.isElectrolysis) {
			result = _.concat(result, ['cc', 'ws', 'as']);
		}

		if (this.isUV) {
			result = _.concat(result, ['bu', 'fu']);
		}

		return result;
	}
}
