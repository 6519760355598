<header ps-header *ngIf="user">
	<h2>{{ (loggedUser.id_user == user.id_user ? "MI_CUENTA" : "EDITAR") | translate }}</h2>
</header>
<div *ngIf="!user" class="text-center">
	<img src="/assets/images/wave.svg">
</div>
<main *ngIf="user" class="container">
	<div class="text-center">
		<img [src]="'data:'+user.avatar" onError="this.src='/assets/images/avatars/default.jpg';" width="64" height="64" class="avatar img-circle img-responsive">
		<input accept=".jpg, .png, .jpeg" style="visibility: hidden; height: 2px;" #inputFileImg id="file" type="file" class="form-control" (change)="onFileChange($event)">
		<button class="btn btn-primary" [disabled]="loggedUser.isDemo" (click)="inputFileImg.click()"><i aria-hidden="true" class="fa fa-pencil fa-fw"></i>{{ 'EDITAR' | translate }}</button>
		<button class="btn btn-danger" [disabled]="loggedUser.isDemo" (click)="user.avatar = null; save()">{{ 'BT_ELIMINAR' | translate }}</button>
	</div>
	<fieldset>
		<legend>{{ 'DATOS_ACCESO' | translate }}</legend>
		<div class="row">
			<div *ngIf="loggedUser.id_role == ('' | roles).ADMIN" class="form-group col-md-6">
				<label for="role">{{ 'ROL' | translate }}</label>
				<select id="role" name="role" class="form-control" [(ngModel)]="user.id_role">
					<option value="1">{{ 'ADMINISTRADOR' | translate }}</option>
					<option value="2">{{ 'PRIVADO' | translate }}</option>
					<option value="3">{{ 'MANTENEDOR' | translate }}</option>
					<option value="5">{{ 'QC' }}</option>
					<option value="6">SAT</option>
				</select>
			</div>
			<div class="form-group col-md-6">
				<label for="email">{{ 'EMAIL' | translate }}</label>
				<input id="email" [disabled]="_canEditEmail()" type="text" name="email" size="30"
						maxlength="200" class="form-control" [(ngModel)]="user.usr">
			</div>
		</div>
	</fieldset>
	<fieldset>
		<div class="row">
			<div class="form-group col-md-6">
				<label for="password1">{{ 'CONTRASENYA' | translate }}</label>
				<input id="password1" [disabled]="_editingAnotherUser()" type="password" name="password1" size="30"
						maxlength="200" class="form-control" [(ngModel)]="password[0]">
			</div>
			<div class="form-group col-md-6">
				<label for="password2">{{ 'REPITA_CONTRASENYA' | translate }}</label>
				<input id="password2" [disabled]="_editingAnotherUser()" type="password" name="password2"
						size="30" maxlength="200" class="form-control" [(ngModel)]="password[1]">
			</div>
		</div>
	</fieldset>
	<br>
	<fieldset>
		<legend>{{ 'DATOS_PERSONALES' | translate }}</legend>
		<div class="row">
			<div class="form-group col-md-6">
				<label for="name">{{ 'NOMBRE' | translate }}</label>
				<input id="name" type="text" name="name" size="30" maxlength="200" class="form-control" [(ngModel)]="user.nombre">
			</div>
			<div class="form-group col-md-6">
				<label for="surname">{{ 'APELLIDOS' | translate }}</label>
				<input id="surname" type="text" name="surname" size="60" maxlength="200" class="form-control" [(ngModel)]="user.apellidos">
			</div>

			<div class="form-group col-md-6">
				<label for="phone">{{ 'TELEFONO' | translate }}</label>
				<input id="phone" type="text" name="phone" size="30" maxlength="20" class="form-control" [(ngModel)]="user.telefono">
			</div>
		</div>
	</fieldset>
	<fieldset>
		<legend>{{ 'LOCALIZACION' | translate }}</legend>
		<div class="row">
			<div class="form-group col-md-6">
				<label for="address">{{ 'DIRECCION' | translate }}</label>
				<input id="address" type="text" name="address" size="30" maxlength="20" class=" form-control" [(ngModel)]="user.direccion">
			</div>
			<div class="form-group col-md-6">
				<label for="country">{{ 'PAIS' | translate }}</label>
				<input id="country" type="text" class="form-control" name="country" size="30" maxlength="255" [(ngModel)]="user.id_pais">
			</div>
			<div class="form-group col-md-6">
				<label for="location">{{ 'LOCALIDAD' | translate }}</label>
				<input id="location" type="text" class="form-control" name="location" size="30" maxlength="255" [(ngModel)]="user.id_loc">
			</div>
		</div>
	</fieldset>
	<fieldset>
		<legend>{{ 'CONFIGURACION_REGIONAL' | translate }}</legend>
		<div class="row">
			<div class="form-group col-md-6">
				<label for="tempUnit">{{ 'UNIDAD_TEMPERATURA' | translate }}</label>
				<select id="tempUnit" class="form-control" name="tempUnit" [(ngModel)]="user.unit_temp">
					<option value="C">{{'GRADOS_CELSIUS' | translate}}</option>
					<option value="F">{{'GRADOS_FARENHEIT' | translate}}</option>
				</select>
			</div>
			<div class="form-group col-md-6">
				<label for="velUnit">{{ 'UNIDAD_VELOCIDAD' | translate }}</label>
				<select id="velUnit" class="form-control" name="velUnit" [(ngModel)]="user.unit_vel">
					<option value="KMH">{{ 'KILOMETROS_HORA' | translate }}</option>
					<option value="MPH">{{ 'MILLAS_HORA' | translate }}</option>
				</select>
			</div>
			<div class="form-group col-md-6">
				<label for="notifications">{{ 'IDIOMA_NOTIFICACIONES' | translate }}</label>
				<select id="notifications" class="form-control" name="notifications" [(ngModel)]="user.idioma_notificaciones">
					<option value="Castellano">{{ 'ESPANYOL' | translate }}</option>
					<option value="Ingles">{{ 'INGLES' | translate }}</option>
					<option value="Frances">{{ 'FRANCES' | translate }}</option>
					<option value="Italiano">{{ 'ITALIANO' | translate }}</option>
					<option value="Portugues">{{ 'PORTUGUES' | translate }}</option>
					<option value="Aleman">{{ 'ALEMAN' | translate }}</option>
				</select>
			</div>
		</div>
	</fieldset>
	<div class="row">
		<div class="col-xs-12 col-md-6">
			<p>
				<button [disabled]="blockButtons || loggedUser.isDemo" class="btn btn-primary" (click)="checkSave()">
					<div *ngIf="blockButtons" class="spinner" role="status"></div>
					<ng-container *ngIf="!blockButtons">
						{{ 'BT_GUARDAR' | translate }}
					</ng-container>
				</button>
				<button [disabled]="blockButtons" class="btn btn-danger" [routerLink]="['/devices']" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
			</p>
		</div>
		<div class="col-xs-12 col-md-6">
			<p *ngIf="user.id === loggedUser.id_user && loggedUser.id_role !== 1" id="removeUser">
				<button [disabled]="blockButtons || loggedUser.isDemo" class="btn btn-danger" (click)="showDelete()">{{"ELIMINAR_CUENTA" |  translate}}</button>
			</p>
		</div>
	</div>
	
	
	<div bsModal #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">

				<div class="modal-header">
					<button class="close" (click)="deleteModal.hide()" aria-hidden="true">&times;</button>
					<h4 class="modal-title">{{ 'ELIMINAR_CUENTA' | translate }}</h4>
				</div>

				<div class="modal-body">
					<p class="modal-title" [innerHTML]="'PREGUNTA_ELIMINAR_CUENTA' | translate" ></p>
				</div>

				<div class="modal-footer">
					<button class="btn btn-primary" (click)="deleteModal.hide()" title="{{ 'DESCARTAR_BT_DELETE_ACC' | translate }}">{{ 'DESCARTAR_BT_DELETE_ACC' | translate }}</button>
					<button class="btn btn-danger" (click)="deleteAccount()" aria-disabled="false">{{ 'CONFIRM_BT_DELETE_ACC' | translate }}</button>
				</div>

			</div>
		</div>
	</div>
	<div bsModal #adviceDeletedAccount="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="adviceDeletedAccount" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<p class="modal-title" [innerHTML]="'ADVICE_DELETED_ACCOUNT' | translate" ></p>
				</div>
			</div>
		</div>
	</div>
</main>
