<header ps-header>
	<h2>{{ 'VERSION' | translate }} <small>{{ title | translate }}</small></h2>
</header>
<main class="container-fluid">
	<div class="btn-group btn-group-justified hidden-sm hidden-xs" role="group">
		<div class="btn-group" role="group">
			<button text-ellipsis class="btn btn-primary" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [routerLink]="['/version', 'devices']">{{ 'LISTADO_EQUIPOS' | translate }}</button>
		</div>
		<div class="btn-group" role="group">
			<button text-ellipsis class="btn btn-primary" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [routerLink]="['/version', 'updatelogs']">{{ 'REGISTRO_ACTUALIZ' | translate }}</button>
		</div>
		<div class="btn-group" role="group">
			<button text-ellipsis class="btn btn-primary" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [routerLink]="['/version', 'pbas']">{{ 'PBAS' | translate }}</button>
		</div>
		<div class="btn-group" role="group">
			<button text-ellipsis class="btn btn-primary" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [routerLink]="['/version', 'models']">{{ 'DEFINICION_MODELO' | translate }}</button>
		</div>
		<div class="btn-group" role="group">
			<button text-ellipsis class="btn btn-primary" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [routerLink]="['/version', 'hardware']">{{ 'HARDWARE' | translate }}</button>
		</div>
		<div class="btn-group" role="group">
			<button text-ellipsis class="btn btn-primary" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [routerLink]="['/version', 'software']">{{ 'SOFTWARE' | translate }}</button>
		</div>
		<div class="btn-group" role="group">
			<button text-ellipsis class="btn btn-primary" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [routerLink]="['/version', 'compatibility']">{{ 'COMPATIBILIDADES' | translate }}</button>
		</div>
	</div>

	<div class="visible-sm visible-xs">
		<select [ngModel]="table" (change)="goTo($event.target.value)" class="form-control">
			<option value="devices">{{ 'LISTADO_EQUIPOS' | translate }}</option>
			<option value="updatelogs">{{ 'REGISTRO_ACTUALIZ' | translate }}</option>
			<option value="pbas">{{ 'PBAS' | translate }}</option>
			<option value="models">{{ 'DEFINICION_MODELO' | translate }}</option>
			<option value="hardware">{{ 'HARDWARE' | translate }}</option>
			<option value="software">{{ 'SOFTWARE' | translate }}</option>
			<option value="compatibility">{{ 'COMPATIBILIDADES' | translate}}</option>
		</select>
	</div>

	<p></p>

	<compatibility *ngIf="table === 'compatibility'"></compatibility>
	<devices-logs *ngIf="table === 'devices'"></devices-logs>
	<firmware *ngIf="table === 'software'"></firmware>
	<hardware *ngIf="table === 'hardware'"></hardware>
	<models *ngIf="table === 'models'"></models>
	<pba *ngIf="table === 'pbas'"></pba>
	<update-logs *ngIf="table === 'updatelogs'"></update-logs>
</main>
