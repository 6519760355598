import { Component, OnInit, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

// services
import { VersionsService } from 'app/services/versions.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'hardware',
	templateUrl: './hardware.component.html',
	styleUrls: ['./hardware.component.scss']
})
export class HardwareComponent implements OnInit {
	@ViewChild('editHardware', {static: true}) editHardware;
	@ViewChild('deleteHardware', {static: true}) deleteHardware;

	public hardwares: any[];
	public pbas: any[];
	public pbaNames: any = {};
	public totalHardwares: number;
	public tempHardware: any = {};
	public limit: number = 20;
	public page: number = 1;
	public totalPages: number = 1;
	public conditions: any;
	public disableButtons: boolean = false;

	public pbasFilterValue: any[] = [];
	public pbasFilterOptions: IMultiSelectOption[] = [];
	public pbasFilterSettings: IMultiSelectSettings;
	public pbasFilterTexts: IMultiSelectTexts;

	public hwFilterValue: any[] = [];
	public hwFilterOptions: IMultiSelectOption[] = [];
	public hwFilterSettings: IMultiSelectSettings;
	public hwFilterTexts: IMultiSelectTexts;

	public validFilterValue: any[] = [];
	public validFilterOptions: IMultiSelectOption[] = [];
	public validFilterSettings: IMultiSelectSettings;
	public validFilterTexts: IMultiSelectTexts;

	public pbasFilterValueBack: any;
	public hwFilterValueBack: any;
	public pbasFilterHasAllBefore = true;
	public hwFilterHasAllBefore = true;

	public filtersDelay: any;

	private _searchText: string = '';

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		value = value.trim();

		if (value && value !== '') {
			this._searchText = value;
			this.conditions = {
				conditions: [
					'hardware.name LIKE :search OR hardware.code LIKE :search OR hardware.observations LIKE :search'
				],
				parameters: {
					search: this._searchText
				},
				hardwares: (this.hwFilterValue.indexOf(-1) > -1) ? null : this.hwFilterValue,
				pbas: (this.pbasFilterValue.indexOf(-1) > -1) ? null : this.pbasFilterValue,
				valid: this.validFilterValue
			};
		} else {
			this.conditions = {
				conditions: [],
				parameters: {},
				hardwares: (this.hwFilterValue.indexOf(-1) > -1) ? null : this.hwFilterValue,
				pbas: (this.pbasFilterValue.indexOf(-1) > -1) ? null : this.pbasFilterValue,
				valid: this.validFilterValue
			};
		}

		this.loadItems();
	}

	constructor(private _versionSvc: VersionsService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService) {
		}

	ngOnInit() {
		this._setFilterData();
	}

	public loadItems(nPage: number = 1) {
		this.disableButtons = true;

		this._versionSvc.findHardwares(this.limit, nPage, this.conditions).then((result: any) => {
			this.disableButtons = false;
			this.hardwares = result.items;
			this.totalHardwares = result.total;
			this.totalPages = Math.ceil(this.totalHardwares / this.limit);

			for (let pba of result.pbas) {
				this.pbaNames[pba.id] = pba.name;
			}
		}).catch((error: any) => {
			console.log(error);
			this.disableButtons = false;
			this.loadItems();
		});
	}

	public edit(index: number = null) {
		if (!this.pbas) {
			this._getPbas();
		}

		this.tempHardware = _.clone(this.hardwares[index]);
		this.editHardware.show();
	}

	public createHardware() {
		if (!this.pbas) {
			this._getPbas();
		}

		this.tempHardware = {
			name: '',
			code: '',
			id_pba: null,
			valid: false,
			observations: ''
		};

		this.editHardware.show();
	}

	public save() {
		this.disableButtons = true;
		if (this._checkLength('name', 50) && this._checkLength('code', 6, 6)) {
			this.tempHardware.name = this.tempHardware.name.trim();
			this.tempHardware.code = this.tempHardware.code.trim();

			this._versionSvc.saveHardware(this.tempHardware).then((result: any) => {
				this.disableButtons = false;
				this.editHardware.hide();
				this._notificationSvc.success('', this._translate.instant(result.message));
				this.loadItems(this.page);
			}).catch((error) => {
				this.disableButtons = false;
				this.editHardware.hide();
			});
		} else {
			this.disableButtons = false;
			this.editHardware.hide();
		}
	}

	public delete() {
		this.disableButtons = true;

		this._versionSvc.deleteHardware(this.tempHardware.id).then((result: any) => {
			this._notificationSvc.success('', this._translate.instant(result.message));
			this.disableButtons = false;
			this.deleteHardware.hide();
			this.loadItems(this.page);
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}
			this.disableButtons = false;
			this.deleteHardware.hide();
		});
	}

	public filterChanged(option: number) {
		const hasAllPbas: boolean = this.pbasFilterValue.indexOf(-1) > -1;
		const hasAllHw: boolean = this.hwFilterValue.indexOf(-1) > -1;

		if (this.filtersDelay) {
			clearTimeout(this.filtersDelay);
			this.filtersDelay = null;
		}

		switch (option) {
			case 1:
				if (!hasAllPbas && this.pbasFilterHasAllBefore) {
					this.pbasFilterValue = [];
				} else if (hasAllPbas && !this.pbasFilterHasAllBefore) {
					this.pbasFilterValue = _.clone(this.pbasFilterValueBack);
				}

				if (this.pbasFilterValue.length < this.pbasFilterValueBack.length && hasAllPbas) {
					const index = this.pbasFilterValue.indexOf(-1);
					this.pbasFilterValue.splice(index, 1);
				} else if (this.pbasFilterValue.length === (this.pbasFilterValueBack.length - 1) && !hasAllPbas) {
					this.pbasFilterValue.push(-1);
				}
				break;
			case 2:
				if (!hasAllHw && this.hwFilterHasAllBefore) {
					this.hwFilterValue = [];
				} else if (hasAllHw && !this.hwFilterHasAllBefore) {
					this.hwFilterValue = _.clone(this.hwFilterValueBack);
				}

				if (this.hwFilterValue.length < this.hwFilterValueBack.length && hasAllHw) {
					const index = this.hwFilterValue.indexOf(-1);
					this.hwFilterValue.splice(index, 1);
				} else if (this.hwFilterValue.length === (this.hwFilterValueBack.length - 1) && !hasAllHw) {
					this.hwFilterValue.push(-1);
				}
				break;
			default:
				break;
		}

		this.pbasFilterHasAllBefore = this.pbasFilterValue.indexOf(-1) > -1;
		this.hwFilterHasAllBefore = this.hwFilterValue.indexOf(-1) > -1;

		this.filtersDelay = setTimeout(() => {
			this.searchText = this._searchText;
		}, 750);
	}

	private _setFilterData() {
		// validFilter
		this.validFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: false,
			stopScrollPropagation: true
		};
		this.validFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('VERSION_VALIDA'),
			allSelected: this._translate.instant('TODOS')
		});
		this.validFilterValue = [0, 1];
		this.validFilterOptions = [{
			id: 1,
			name: this._translate.instant('SI')
		}, {
			id: 0,
			name: this._translate.instant('NO')
		}];

		// pbasFilter
		this.pbasFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.pbasFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('PBAS'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// hwFilter
		this.hwFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.hwFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('HARDWARE'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// obtengo los datos
		this._versionSvc.getHardData().then((hwData: any) => {
			this.pbasFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.pbasFilterValue.push(-1);

			for (let pba of hwData.pbas) {
				this.pbasFilterValue.push(pba.id);
				this.pbasFilterOptions.push(pba);
			}

			this.pbasFilterValueBack = _.clone(this.pbasFilterValue);

			this.hwFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.hwFilterValue.push(-1);

			for (let hw of hwData.hardwares) {
				this.hwFilterValue.push(hw.id);
				this.hwFilterOptions.push(hw);
			}

			this.hwFilterValueBack = _.clone(this.hwFilterValue);

			this.loadItems();
		}).catch((error) => {
			this._notificationSvc.error('', this._translate.instant(error.message));
			this.loadItems();
		});
	}

	private _getPbas() {
		this._versionSvc.getAllPbas().then((result: any) => {
			this.pbas = result;

			for (let pba of this.pbas) {
				this.pbaNames[pba.id] = pba.name;
			}
		}).catch((error) => {
			console.log(error);
		});
	}

	private _checkLength(field: string, max: number, min: number = 1): boolean {
		let ok: boolean = true;

		if (this.tempHardware[field].trim().length > max || this.tempHardware[field].trim().length < min) {
			this.tempHardware = {};
			this.editHardware.hide();
			this._notificationSvc.error('', this._translate.instant('LONGITUD_CAMPO') + min + '-' + max);
			ok = false;
		} else if (this.tempHardware[field].trim().length === 0) {
			this.tempHardware = {};
			this.editHardware.hide();
			this._notificationSvc.error('', this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS'));
			ok = false;
		}

		return ok;
	}
}
