import { Injectable, Pipe, PipeTransform } from '@angular/core';

// utils
import * as _ from 'lodash';

@Pipe({
	name: 'sumBy'
})
@Injectable()
export class SumBy implements PipeTransform {
	public transform(objects, args) {
		try {
			if (objects) {
				let key = null;

				if (_.isString(args)) {
					key = args; _.sumBy(objects, args);
				} else if (_.isObject(args)) {
					key = args.key;
				} else {
					return objects;
				}

				if (args.conditions) {
					objects = _.filter(objects, args.conditions);
				}

				objects = _.sumBy(objects, function (o) {
					if (!_.isEmpty(args.eval)) {
						// tslint:disable-next-line:no-eval
						return eval(args.eval);
					} else {
						return parseFloat(o[key]);
					}
				});
				return objects;
			} else {
				console.log('[sumBy] No se ha pasado ningún objeto');
			}
		} catch (e) {
			console.log(e);
		}

		return 0;
	}
}
