<header ps-header>
	<h2>{{ (action == 'add' ? 'SOLI_PROFE' : 'PERMI_PROFE') | translate }}</h2>
</header>

<main class="container" [ngSwitch]="action">
	<ng-container *ngSwitchCase="'edit'">
		<ng-container *ngIf="request">
			<table class="table table-bordered table-striped hidden-xs">
				<thead>
					<tr>
						<th>{{ 'RELES' | translate }}</th>
						<th>{{ 'IMAGEN' |translate }}</th>
						<th>{{ 'NOMBRE' | translate}}</th>
						<th>{{ 'CONSUMO' | translate}}</th>
						<th>{{ 'ACCESO' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<ng-template ngFor let-i="index" let-relay [ngForOf]="request.device.relays">
						<tr *ngIf="relay">
							<td><strong>{{ 'RELE' | translate }} {{ i + 1 }}</strong></td>
							<td class="actions"><img [src]="'../../assets/images/device/relay_' + relay.sign + deviceSeries + '.svg'"></td>
							<td>{{ relay.nombre | translate }}</td>
							<td>{{ relay.consumo }} {{ 'WATIOS' | translate }}</td>
							<td class="actions">
								<div class="checkbox">
									<label>
										<ui-switch (change)="changeSwitch(i)" [(ngModel)]="switches[i]" [disabled]="(i==1 || i==2) && relay.id_relay_type==3" color="#43a9c9"></ui-switch>
									</label>
								</div>
							</td>
						</tr>
					</ng-template>
				</tbody>
			</table>

			<div class="visible-xs">
				<div *ngFor="let relay of request.device.relays; let i = index;" class="panel panel-default">
					<div *ngIf="relay" class="panel-body">
						<div class="row">
							<div class="col-xs-8">
								<strong>{{ 'RELE' | translate }} {{ i + 1 }}</strong>
								<div>{{ relay.nombre | translate }}</div>
								<div>{{ relay.consumo }} {{ 'WATIOS' | translate }}</div>
							</div>
							<div class="col-xs-4 text-center">
								<img width="60px" [src]="'/assets/images/device/relay_' + relay.sign + '.svg'">
              				</div>
							<br>
							<label class="col-xs-9">{{ 'ACCESO' | translate }}</label>
							<div class="col-xs-2">
								<ui-switch (change)="changeSwitch(i)" [(ngModel)]="switches[i]" [disabled]="(i==1 || i==2) && relay.id_relay_type==3" color="#43a9c9"></ui-switch>
							</div>
						</div>
					</div>
				</div>
			</div>

			<fieldset class="form-horizontal">
				<legend>{{ 'PROGRAMADOR_SEMANAL' | translate }}</legend>
				<div class="checkbox">
					<label>
						<input type="checkbox" (change)="changeWeeklyProgrammerPermission()" [(ngModel)]="request.weeklyProgrammerPermission"> {{ 'HABILITAR_PROGRAMADOR' | translate }} <small>{{ 'REQUIERE_PERMISOS_RELES' | translate }}</small>
					</label>
				</div>
			</fieldset>
			<br>
			<p>
				<button class="btn btn-info" [disabled]="blockButtons" (click)="save()">{{ 'BT_GUARDAR' | translate }}</button>
				<button class="btn btn-danger" onclick="history.back()" [disabled]="blockButtons" title="{{ 'VOLVER' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
			</p>
		</ng-container>
	</ng-container>

	<ng-container *ngSwitchDefault>
		<ul *ngIf="action == 'register'" class="nav nav-pills nav-justified nav-wizard">
			<li role="presentation" class="disabled"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO1' | translate}}</a></li>
			<li role="presentation" class="disabled"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO2' | translate}}</a></li>
			<li role="presentation" class="disabled"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO3' | translate}}</a></li>
			<li role="presentation" class="active"><a href="javascript:{}">{{'BARRA_PROGRESO_REGISTRO4' | translate}}</a></li>
		</ul>
		<ng-container *ngIf="devices">
			<h3>{{ 'PREGUNTA_MANTENEDOR' | translate }}</h3>
			<p [innerHtml]="'DESCRIPCION3_MANTENEDOR' | translate"></p>
			<p [innerHtml]="'DESCRIPCION_MANTENEDOR' | translate"></p>
			<fieldset>
				<legend>{{ 'SELECT_DISPOSITIVO' | translate }}</legend>
				<div class="row">
					<div class="col-md-6">
						<label>{{ 'DISPOSITIVO' | translate }}</label>
						<select *ngIf="devices" class="form-control" [(ngModel)]="request.idDevice">
							<option *ngFor="let device of devices" [value]="device.id">{{ device.alias }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>{{ 'EMAIL_MANTENEDOR' | translate }}</label>
						<input type="email" class="form-control" [(ngModel)]="request.email">
					</div>
				</div>
			</fieldset>
			<br>
			<p>
				<button class="btn btn-success" [disabled]="blockButtons || request.email == '' || !request.idDevice" (click)="newRequest()">{{ 'BT_SOLICITAR' | translate }}</button>
				<button [disabled]="blockButtons" class="btn btn-danger" [routerLink]="['/professionals']">{{ 'DESCARTAR_CAMBIOS' | translate }}</button>
			</p>
		</ng-container>

		<div *ngIf="!devices" class="text-center">
			<img src="/assets/images/wave.svg">
    	</div>
	</ng-container>

	<div *ngIf="!request" class="text-center">
		<img src="/assets/images/wave.svg">
  	</div>
</main>
