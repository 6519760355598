import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  private readonly urlsNoAuth = [
    'maps.googleapis.com'
  ]

  constructor(
    private router: Router,
    private _authSvc: AuthService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = this._authSvc.token;

    let request = req;
    if(this.isUrlAuth(request.url)) {
      if (this._authSvc.token) {
        request = req.clone({
          setHeaders: {
            authorization: `Bearer ${ this._authSvc.token }`
          }
        });
      }
    }
    

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          this._authSvc.localLogout();
        }

        return throwError(err);
      })
    )
  }

  isUrlAuth(url: String) {
    let auth = true;
    this.urlsNoAuth.forEach(domain => {
      if(url.indexOf(domain) != -1) {
        auth = false;
      }
    })
    return auth;
  }
}

