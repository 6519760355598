// utils
// import * as _ from 'lodash';
// import * as moment from 'moment';

export class Relay {
	public state;
	public series;
	public sign: string;
	private _labels = {
		'0': 'OFF',
		'1': 'ON',
		'A': 'A',
		'-': ''
	};

	private _colors = {
		'0': 'r',
		'1': 'g',
		'A': 'b',
		'-': '',
		'': ''
	};

	get label() {
		return this._labels[this.state];
	};

	get icon() {
		return '/assets/images/device/relay_' + this.sign + this._colors[this.state] + this.series + '.svg';
	};

	constructor(raw: any, state) {
		for (let prop in raw) {
			if (raw.hasOwnProperty(prop)) {
				if ([].indexOf(prop) !== -1) {
					this['_' + prop] = raw[prop];
				} else {
					this[prop] = raw[prop];
				}
			}
		}

		this.state = state && state !== '-' ? state : null;
	}
}
