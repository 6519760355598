import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'table-sorter',
	templateUrl: 'table-sorter.component.html',
	styleUrls: ['./table-sorter.component.scss']
})

export class TableSorterComponent implements OnInit {

	@Input('disableBtn') disableBtn: boolean = false;
	@Input('loading') loading: boolean = false;
	private _timeSearch: any;
	private _timePageChange: any;
	private _optionalTimer: any;
	private _option: boolean = false;
	private _limit: boolean;
	private _total: number;
	private _orderableColumns: any[];
	private _emptyText: string;
	private _searchText: string;
	private _page: number = 1;

	// limit
	get limit(): boolean {
		return this._limit;
	}

	@Input('limit')
	set limit(value: boolean) {
		this._limit = value;
	}

	// emptyText
	get emptyText(): string {
		return this._emptyText;
	}

	@Input('emptyText')
	set emptyText(value: string) {
		this._emptyText = value;
	}

	// total
	get total(): number {
		return this._total;
	}

	@Input('total')
	set total(value: number) {
		this._total = value;
	}

	// orderable columns
	get orderableColumns(): any[] {
		return this._orderableColumns;
	}

	@Input('orderableColumns')
	set orderableColumns(value: any[]) {
		this._orderableColumns = value;
	}

	get searchText(): string {
		return this._searchText;
	}

	@Input('searchText')
	set searchText(value: string) {
		this._searchText = value;
	}

	// page
	@Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
	@Input() set page(value: number) {
		this._page = value;

		if (this._option) {
			this.pageChange.emit(this._page);
		}

		this._option = false;
	}

	get page() {
		return this._page;
	}

	@Output() prev = new EventEmitter();
	@Output() next = new EventEmitter();
	@Output() limitChanged = new EventEmitter();
	@Output() search = new EventEmitter();
	@Output() optional = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	public refresh(value: number) {
		this._option = true;
		this.page = value;
	}

	public changePage(value: number) {
		if (this._timePageChange) {
			clearTimeout(this._timePageChange);
			this._timePageChange = null;
		}

		this._timePageChange = setTimeout(() => { this._change(value); }, 600);
		this.page = value;
	}

	public setSearch(op: boolean = false) {
		clearTimeout(this._timeSearch);

		if (op) {
			this.search.emit(this.searchText);
			this.page = 1;
		} else {
			this._timeSearch = setTimeout(() => {
				this.search.emit(this.searchText);
				this.page = 1;
			}, 1000);
		}
	}

	public emitOptional(option: string) {
		if (this._optionalTimer) {
			clearTimeout(this._optionalTimer);
			this._optionalTimer = null;
		}

		this._optionalTimer = setTimeout(() => { this.optional.emit(option); }, 750);
	}

	private _change(value: number) {
		this._page < value ? this.next.emit(value) : this.prev.emit(value);
	}
}
