import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { MessagesService } from '../../services/messages.service';
import { RequestsService } from '../../services/requests.service';
import { AuthService } from '../../services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '../../entities/device';

// utils
import * as moment from 'moment';
import * as _ from 'lodash';
import { RolesPipe } from '../../pipes/roles.pipe';
import ROLES from '../../entities/roles';

@Component({
	selector: 'messages',
	templateUrl: 'messages.component.html',
	styleUrls: ['messages.component.scss']
})
export class MessagesComponent implements OnInit {
	public recipients: any[] = [];
	public loading: boolean = true;
	public limit: number = 20;
	public page: number = 1;
	public action: string = 'inbox';
	public inbox: any[];
	public outbox: any[];
	public pendingRequests: any[];
	public totalPending: number;
	public totalPendingMessages: number = 0;
	public selectedRequest: any = {
		requestIndex: null,
		selected: false,
		newAlias: '',
		request: {}
	};
	public device: any;
	public blockButtons: boolean = false;
	public currentUser: any = this._authSvc.user;
	public totalDevices: number;
	public totalPages: number;
	public loggedUser: any;
	public conditions: any = {
		parameters: {}
	};
	public _searchText: string = '';
	public sending: Boolean = false;
	private _searchTimeOut: any = null;
	private _message: any = null;

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		if (this._searchTimeOut) {
			clearTimeout(this._searchTimeOut);
		}

		value = value.trim();

		this._searchTimeOut = setTimeout(() => {
			if (value && value !== '') {
				this._searchText = value;
				this.conditions = {
					parameters: {
						search: this._searchText
					},
					message: this.action === 'inbox' ? 1 : 0
				};
			} else {
				this._searchText = '';
				this.conditions = {
					parameters: {}
				};
			}

			switch (this.action) {
				case 'inbox':
					this.loadInbox();
					break;
				case 'outbox':
					this.loadOutbox();
					break;
			}
		}, 500);
	}

	get message() {
		return this._message;
	}

	set message(value: any) {
		this._message = value;
	}

	constructor(
		private route: ActivatedRoute,
		private _router: Router,
		private _messagesSvc: MessagesService,
		private _authSvc: AuthService,
		private _requestSvc: RequestsService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService) {
		this.loggedUser = this._authSvc.user;
	}

	public ngOnInit() {
		this.loadPendingMessages();
		this.route.params.subscribe((params) => {
			if (!params['action']) {
				this._router.navigate(['messages', 'inbox']);
			} else {
				let id = params['id'];

				this.loading = true;
				this.action = params['action'];

				if (id && this.action !== 'write') {
					this._messagesSvc.get(id).then(message => {
						this.message = message;
						this.action = 'view';
						console.log(this.message);
					}).catch((error) => {
						if (error.message) {
							this._notificationSvc.error('', this._translate.instant(error.message));
						}
					});
				} else {
					switch (this.action) {
						case 'write':
						case 'compose':
							this.loading = false;
							this.message = {
								id: null,
								id_emisor: this.currentUser.id_user,
								id_receptor: '',
								asunto: '',
								mensaje: '',
								fecha: '',
								borrado_emisor: 0,
								borrado_receptor: 0,
								leido: 0
							};
							this._requestSvc.find(9999, 0).then((result: any) => {
								switch (this.loggedUser.id_role) {
									case ROLES.PROFESIONAL:
										this.recipients = _.chain(result.items).map((o) => o['device']).uniqBy('user.id').value().map((o) => o.user);
										break;
									default:
										this.recipients = _.chain(result.items).map((o) => o['user']).uniqBy('id').value();
										break;
								}

								if (this.action === 'write') { this.message.id_receptor = id; }
							}).catch((error) => {
								console.log(error);
							});
							break;
						case 'inbox':
							this.loadInbox();
							break;
						case 'outbox':
							this.loadOutbox();
							break;
						case 'requests':
							//this.loadPendingRequest();
							break;
					}

					if (this.loggedUser.id_role === ROLES.PROFESIONAL) {
						this.loadPendingRequest();
					}
				}
			}
		});
	}

	public loadInbox(page: number = 1) {
		this.blockButtons = true;

		this._messagesSvc.inbox(this.limit, page, this.conditions).then((messages: any) => {
			this.inbox = messages.items;
			this.totalPages = Math.ceil(messages.total / this.limit);
			this.loading = false;
			this.blockButtons = false;
		}).catch((error) => {
			this.blockButtons = false;
			console.log(error);
		});
	}

	public loadOutbox(page: number = 1) {
		this.blockButtons = true;

		this._messagesSvc.outbox(this.limit, page, this.conditions).then((messages: any) => {
			this.outbox = messages.items;
			this.totalPages = Math.ceil(messages.total / this.limit);
			this.loading = false;
			this.blockButtons = false;
		}).catch((error) => {
			this.blockButtons = false;
			console.log(error);
		});
	}

	public loadPendingRequest() {
		this._requestSvc.pendingRequests().then((requests: any) => {
			this.pendingRequests = requests.items;
			this.totalPending = +requests.total;
			this.loading = false;
			this.blockButtons = false;
		}).catch((error) => {
			this.blockButtons = false;
			console.log(error);
		});
	}

	public loadPendingMessages() {
		this._messagesSvc.pendingMessages().then((result) => {
			this.totalPendingMessages = +result;
		}).catch((error) => {
			console.log(error);
		});
	}

	public acceptRequest() {
		let mailBody: string = '';

		this.blockButtons = true;

		if (this.selectedRequest.newAlias !== '') {
			this._requestSvc.acceptRequest(this.selectedRequest.request.id, this.selectedRequest.newAlias).then((result) => {

				this.message = {
					id: null,
					id_emisor: this.currentUser.id_user,
					id_receptor: this.selectedRequest.request.device.user.id,
					asunto: this._translate.instant('OK_MANTENEDOR_ASUNTO'),
					mensaje: `${this._translate.instant('OK_MANTENEDOR_MENSAJE1')} <strong>${this.loggedUser.nombre} ${this.loggedUser.apellidos}</strong> ${this._translate.instant('OK_MANTENEDOR_MENSAJE2')}`,
					fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
					borrado_emisor: 0,
					borrado_receptor: 0,
					leido: 0
				};
				this.message.mensaje = this.message.mensaje.replace(/[&]/g, '%26');

				this._messagesSvc.add(this.message).then((result) => {
					this.pendingRequests.splice(this.selectedRequest.index, 1);
					this.totalPending--;
					this.message = {
						id: null,
						id_emisor: '',
						id_receptor: '',
						asunto: '',
						mensaje: '',
						fecha: '',
						borrado_emisor: 0,
						borrado_receptor: 0,
						leido: 0
					};
					this.selectedRequest.index = null;
					this.selectedRequest.selected = false;
					this.selectedRequest.newAlias = '';
					this.selectedRequest.request = {};
					this.blockButtons = false;

					window.location.reload();
				});
			}).catch((error) => {
				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}

				this.blockButtons = false;
			});
		} else {
			this._notificationSvc.info('', this._translate.instant('ERR_INTRO_ALIAS_MANTENEDOR'));
			this.blockButtons = false;
		}
	}

	public rejectRequest() {
		this.blockButtons = true;

		this._requestSvc.delete(this.selectedRequest.request.id).then((result) => {

			this.message = {
				id: null,
				id_emisor: this.currentUser.id_user,
				id_receptor: this.selectedRequest.request.device.user.id,
				asunto: this._translate.instant('OK_MANTENEDOR_RECHAZAR_ASUNTO'),
				mensaje: `<strong>${this.loggedUser.nombre} ${this.loggedUser.apellidos}</strong> ${this._translate.instant('RECHAZAR_MANTENEDOR_MENSAJE')}`,
				fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
				borrado_emisor: 0,
				borrado_receptor: 0,
				leido: 0
			};
			this.message.mensaje = this.message.mensaje.replace(/[&]/g, '%26');

			this._messagesSvc.add(this.message).then((result) => {
				this.pendingRequests.splice(this.selectedRequest.index, 1);
				this.totalPending--;
				this.message = {
					id: null,
					id_emisor: '',
					id_receptor: '',
					asunto: '',
					mensaje: '',
					fecha: '',
					borrado_emisor: 0,
					borrado_receptor: 0,
					leido: 0
				};
				this.selectedRequest.index = null;
				this.selectedRequest.selected = false;
				this.selectedRequest.newAlias = '';
				this.selectedRequest.request = {};
				this.blockButtons = false;

				window.location.reload();
			});
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}

			this.blockButtons = false;
		});
	}

	public selectRequest(index: number) {
		this.selectedRequest = {
			requestIndex: index,
			selected: true,
			newAlias: `POOLSTATION de ${this.pendingRequests[index].device.user.nombre}`,
			request: this.pendingRequests[index]
		};
		this.device = new Device(this.pendingRequests[index].device);
	}

	public send() {
		if (!this.loggedUser.isDemo) {
			this.sending = true;
			this.message.mensaje = this.message.mensaje.replace(/"/g, '\"');
			this.message.mensaje = this.message.mensaje.replace(/:/g, '\:');

			this._messagesSvc.add(this.message).then((result: any) => {
				this._notificationSvc.success('', this._translate.instant(result.message));
				setTimeout(() => { this._router.navigate(['messages/outbox']); }, 5500);
			}).catch((error: any) => {
				this.sending = false;

				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}
			});
		} else {
			this._notificationSvc.info('', this._translate.instant('DEMO'));
		}
	}

	public deleteMessage(id: number) {
		if (!this.loggedUser.isDemo) {
			this.blockButtons = true;
			let messageData: any = {
				id: this.message.id,
				deletedBy: (this.loggedUser.id_user === this.message.id_receptor) ? 'borradoReceptor' : 'borradoEmisor'
			};

			this._messagesSvc.delete(messageData).then((result: any) => {
				this._notificationSvc.success('', this._translate.instant(result.message));
				setTimeout(() => { window.history.back(); }, 3000);
			}).catch((error) => {
				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}

				this.blockButtons = false;
			});
		} else {
			this._notificationSvc.info('', this._translate.instant('DEMO'));
		}
	}
}
