import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'loading',
	template: '',
})
export class LoadingComponent implements OnInit {
	constructor() { }

	public ngOnInit() {
	}
}
