import { Component, OnDestroy, AfterViewInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'simple-tiny',
	templateUrl: './simple-tiny.component.html'
})
export class SimpleTinyComponent implements AfterViewInit, OnDestroy {
	@Input() elementId: String;
	@Output() onEditorKeyup = new EventEmitter<any>();

	tinymce = (<any>window).tinymce;
	editor;

	ngAfterViewInit() {
		this.tinymce.init({
			selector: '#' + this.elementId,
			themes: 'modern',
			plugins: ['link', 'paste', 'table'],
			skin_url: './assets/skins/lightgray',
			menubar: false,
			toolbar1: 'undo redo | alignleft aligncenter alignright | bold italic | link',
			setup: editor => {
				this.editor = editor;
				editor.on('keyup', () => {
					const content = editor.getContent();
					this.onEditorKeyup.emit(content);
				});
			},
		});
	}

	ngOnDestroy() {
		this.tinymce.remove(this.editor);
	}
}
