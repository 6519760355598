import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DevicesService } from '../../services/devices.service';
import { Device } from '../../entities/device';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
// services
import { AuthService } from '../../services/auth.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { RolesPipe } from '../../pipes/roles.pipe';
import ROLES from '../../entities/roles';
import { UsersService } from 'app/services/users.service';

@Component({
	selector: 'devices',
	templateUrl: 'devices.component.html',
	styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {
	@ViewChild('linkDev', {static: true}) public linkModal: ModalDirective;
	@ViewChild('deleteDev', {static: true}) public deleteModal: ModalDirective;

	readonly maxFailedRequests = 10;
	readonly limitDefault      = 10;
	private failedRequests     = 0;

	public nsValid: boolean = false;
	public load: boolean = true;
	public disableButtons: boolean = false;
	public devices: Device[];
	public deviceTools: any = {};
	public devicesOffHours = [];
	public totalDevices: number;
	public selectedDevice: any = {};
	public validFirmwares: any = null;
	public alertIcons = {
		H: 'fa-arrow-up',
		L: 'fa-arrow-down',
		J: 'fa-arrow-circle-up',
		N: 'fa-arrow-circle-down',
		I: 'fa-level-up',
		1: 'fa-exclamation-triangle',
		2: 'fa-exclamation-circle',
	};
	public states = {
		'0': {
			class: 'danger',
			label: 'OFF'
		},
		'1': {
			class: 'success',
			label: 'ON'
		},
		'A': {
			class: 'info',
			label: 'A'
		},
		'-': {
			class: 'hidden',
			label: ''
		},
		'': {
			class: 'info',
			label: ''
		}
	};
	public id: number;
	public action: string;
	public blockButtons: boolean = false;
	public loggedUser: any;
	public limit: number = 10;
	public totalPages: number;
	public page: number = 1;
	public conditions: any = {
		parameters: {
			search: ''
		}
	};
	public order: any = {
		field: 'vars/updated',
		direction: 'DESC',
	};
	public _searchText: string = '';
	public userSearch = 1;
	public idcomFirmware: any;
	private _searchTimeOut: any = null;
	private _interval: any;

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		if (this._searchTimeOut) {
			clearTimeout(this._searchTimeOut);
		}

		value = value.trim();

		this._searchTimeOut = setTimeout(() => {
			if (this._interval) {
				clearTimeout(this._interval);
			}

			if (value && value !== '') {
				this._searchText = value;
				this.conditions = {
					parameters: {
						search: this._searchText
					}
				};
			} else {
				this._searchText = '';
				this.conditions = {
					parameters: {
						search: ''
					}
				};
			}

			this.userSearch++;
			this.loadItems(1);
		}, 500);
	}

	constructor(
		private _userSvc: UsersService,
		private _devicesSvc: DevicesService,
		private _authSvc: AuthService,
		private _translate: TranslateService,
		private _notificationService: NotificationsService,
		private _router: Router) {
		this.loggedUser = this._authSvc.user;		
	}

	public async ngOnInit() {
		await this.loadLimit();
		await this.loadItems();
		this.load = false;
	}

	ngOnDestroy() {
		clearTimeout(this._interval);
		this._interval = null;
	}

	public async loadLimit(){
		let resp: any = await this._userSvc.getLimitUser();
		const { limit } = resp;
		if(typeof limit == 'number' && limit > 0) {
			this.limit = limit;
		} else {
			this.limit = this.limitDefault;
		}
	}

	public loadItems(nPage: number = 1) {
		if (this._interval) {
			clearTimeout(this._interval);
		}

		if (this.load) {
			this.page = nPage;
			this.disableButtons = true;
			this.blockButtons = true;
			this._devicesSvc.find(this.limit, nPage, this.conditions, this.order, false, this.userSearch).then((devices: any) => {
				this.failedRequests = 0;
				if (this.userSearch === parseInt(devices.userSearch, 10)) {
					this.devicesOffHours = [];
					devices.items.forEach((device) => {
							this.devicesOffHours.push(device.hoursDesconected());
							if (this.deviceTools[device.id] === undefined) {
									this.deviceTools[device.id] = false;
							}
					})
					this.devices = devices.items;
					this.totalDevices = devices.total;
					this.totalPages = Math.ceil(this.totalDevices / this.limit);
					this.validFirmwares = devices.validFirmwares;
					this.disableButtons = false;
					this.blockButtons = false;

					if (this.loggedUser.id_role === ROLES.QC) {
						this.nsValid = this.isNs(this.searchText);
						this.idcomFirmware = devices.idcomFirmware;
					}

					if (this._router.url === '/devices') {
						this._interval = setTimeout(() => {
							this.loadItems(this.page);
						}, 5000);
						this.load = true;
					}

					this.userSearch++;
				}
			}).catch((exception) => {
				const {error: devices} = exception;
				this.devices = devices.items;
				this.totalDevices = devices.total;
				this._notificationService.error('', this._translate.instant(devices.error))

				this.disableButtons = false;
				this.blockButtons = false;
				this.load = true;
				
				if (this._router.url === '/devices') {
					this.failedRequests++;
					if(this.failedRequests < this.maxFailedRequests){
						this._interval = setTimeout(() => {
							this.loadItems(this.page);
							this.load = true;
						}, 5000);
					}
				}
			});
		}
	}

	public showDeleteDevice(index) {
		this.selectedDevice = this.devices[index];
		this.deleteModal.show();
	}

	public disassociate() {
		let data = {
			id: this.selectedDevice.id,
			idUser: this.selectedDevice.user.id
		};

		this.deleteModal.hide();
		this.blockButtons = true;

		this._devicesSvc.disassociate(data).then((response: any) => {
			this._notificationService.success('', this._translate.instant(response.message));
			this.deviceTools[this.selectedDevice.id] = false;
			this.loadItems();
		}).catch((error) => {
			console.log(error);
			if (error.message) {
				this._notificationService.html(this._translate.instant(error.message), NotificationType.Error);
			}
		});
	}

	public showLinkDevice(index) {
		this.selectedDevice = this.devices[index];
		this.linkModal.show();
	}

	public linkDevice() {
		let idDevice = {
			id: this.selectedDevice.id
		};

		this.blockButtons = true;
		this.linkModal.hide();

		this._devicesSvc.associate(idDevice).then((result: any) => {
			console.log('success');
			this._notificationService.success('', this._translate.instant(result.message));
			this.loadItems();
		}).catch((error: any) => {
			console.log(error);
			if (error.message) {
				this._notificationService.error('', this._translate.instant(error.message));
			}
			setTimeout(() => { window.location.reload(); }, 5500);
		});
	}

	public changeOrder(order: any) {
		if (this._interval) {
			clearTimeout(this._interval);
		}

		this.page = 1;
		this.order.field = order.column;
		this.order.direction = order.direction;
		this.loadItems();
	}

	public isNs(ns: string): boolean {
		if (ns.length !== 13) {
			return false;
		}

		for (let i = 0; i < ns.length; i++) {
			if (isNaN(parseInt(ns[i], 10))) {
				return false;
			}
		}

		return true;
	}

	public limitDevices(event){
		this.limit = event;
		this.loadItems(1);
		this._userSvc.saveLimitUser(this.limit);
	}

	public updateDevice(id: number, code: string, url: string, nsDevice: number) {
		let data = {
			id: id,
			code: code,
			url: url,
			ns: nsDevice
		};

		this._devicesSvc.update(data).then((result: any) => {
			console.log(result);
			this._notificationService.success('', this._translate.instant(result.message));
			this.loadItems();
		}).catch((error) => {
			console.log(error);
			if (error.message) {
				this._notificationService.error('', this._translate.instant(error.message));
			}
		})
	}
}
