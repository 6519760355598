import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageScrollConfig } from 'ng2-page-scroll';
import { PrivacyComponent } from './privacy.component';

@Component({
	selector: 'docs',
	templateUrl: 'docs.component.html',
	styleUrls: ['./docs.component.scss']
})

export class DocsComponent implements OnInit {
	public lang: string;
	private languagesContainer: {[key:string]: string};
	public docs = {
		twilight: 'TM_TXT1',
		domind: 'DDM_TXT2',
		inputs: 'IM_TXT2',
		re200: 'RM_TXT2',
		re300: 'RM300_TXT2',
		ex: 'EM_TXT12',
		domotic: 'DM_TXT2',
		legal: 'AVISO_HEAD',
		cookies: 'POLITICA_COOKIES',
		privacy: 'PRIVACY_POLICY',
		/* walkthrough: 'PASEO_POOLSTATION', */
		thanks: 'AGRADECIMIENTOS'
	};
	private _doc: string;

	get doc() {
		return this._doc;
	}

	set doc(value: string) {
		this._doc = value;
		this.router.navigate(['docs', value]);
	}

	constructor(public router: Router, private _route: ActivatedRoute, private _translate: TranslateService) {
		PageScrollConfig.defaultScrollOffset = 67;
		this.languagesContainer = this._translate.store.translations[this._translate.currentLang]
		switch (this._translate.currentLang) {
			case 'es':
				this.lang = 'es';
				break;
			default:
				this.lang = 'en';
				break;
		}
	}

	ngOnInit() {
		this._route.params.subscribe((params) => {
			this._doc = params['doc'];
		});

		const policyURL =  `${window.location.origin}/#/docs/privacy`;
		const cookiesURL = `${window.location.origin}/#/docs/cookies`;

		this.languagesContainer.DATA_PROTECTION_LEGAL_ADVICE = this.languagesContainer.DATA_PROTECTION_LEGAL_ADVICE.replace("{{policy_link}}", policyURL);
		this.languagesContainer.DATA_PROTECTION_LEGAL_COOKIES_ADVICE = this.languagesContainer.DATA_PROTECTION_LEGAL_COOKIES_ADVICE.replace("{{cookies_link}}", cookiesURL);
	}
}
