import { Component, OnInit } from '@angular/core';
import { VersionsService } from 'app/services/versions.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// utils
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'update-logs',
	templateUrl: './update-logs.component.html',
	styleUrls: ['./update-logs.component.scss']
})
export class UpdateLogsComponent implements OnInit {
	public loggedUser: any;
	public updateLogs: any[];
	public totalUpdateLogs: number;
	public limit: number = 20;
	public page: number = 1;
	public totalPages: number = 1;
	public disableButtons: boolean = false;
	public conditions: any = {
		conditions: [],
		parameters: {}
	};

	public devicesFilterValue: any[] = [];
	public devicesFilterOptions: IMultiSelectOption[] = [];
	public devicesFilterSettings: IMultiSelectSettings;
	public devicesFilterTexts: IMultiSelectTexts;
	public devicesFilterValueBack: any;
	public devicesFilterHasAllBefore = true;

	public modelsFilterValue: any[] = [];
	public modelsFilterOptions: IMultiSelectOption[] = [];
	public modelsFilterSettings: IMultiSelectSettings;
	public modelsFilterTexts: IMultiSelectTexts;

	public pbasFilterValue: any[] = [];
	public pbasFilterOptions: IMultiSelectOption[] = [];
	public pbasFilterSettings: IMultiSelectSettings;
	public pbasFilterTexts: IMultiSelectTexts;

	public eventsFilterValue: any[] = [];
	public eventsFilterOptions: IMultiSelectOption[] = [];
	public eventsFilterSettings: IMultiSelectSettings;
	public eventsFilterTexts: IMultiSelectTexts;
	public eventsFilterValueBack: any;
	public eventsFilterHasAllBefore = true;

	public originFilterValue: any[] = [];
	public originFilterOptions: IMultiSelectOption[] = [];
	public originFilterSettings: IMultiSelectSettings;
	public originFilterTexts: IMultiSelectTexts;
	public originFilterValueBack: any;
	public originFilterHasAllBefore = true;

	public oldFwFilterValue: any[] = [];
	public oldFwFilterOptions: IMultiSelectOption[] = [];
	public oldFwFilterSettings: IMultiSelectSettings;
	public oldFwFilterTexts: IMultiSelectTexts;
	public oldFwFilterValueBack: any;
	public oldFwFilterHasAllBefore = true;

	public newFwFilterValue: any[] = [];
	public newFwFilterOptions: IMultiSelectOption[] = [];
	public newFwFilterSettings: IMultiSelectSettings;
	public newFwFilterTexts: IMultiSelectTexts;
	public newFwFilterValueBack: any;
	public newFwFilterHasAllBefore = true;

	public bsConfig: Partial<BsDatepickerConfig>;
	public minDate: any;
	public maxDate: any;
	public datesRange: any[];
	public datesRangeFormated: any[];

	public filtersDelay: any;

	private _searchTimeOut: any = null;
	private _searchText: string = '';

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		if (this._searchTimeOut) {
			clearTimeout(this._searchTimeOut);
		}

		value = value.trim();

		this._searchTimeOut = setTimeout(() => {
			if (value && value !== '') {
				this._searchText = value;
				this.conditions = {
					conditions: [
						'device.ns LIKE :search OR device.alias LIKE :search'
					],
					parameters: {
						search: this._searchText
					},
					devices: (this.devicesFilterValue.indexOf(-1) > -1) ? null : this.devicesFilterValue,
					models: this.modelsFilterValue,
					pbas: this.pbasFilterValue,
					events: (this.eventsFilterValue.indexOf(-1) > -1) ? null : this.eventsFilterValue,
					origins: (this.originFilterValue.indexOf(-1) > -1) ? null : this.originFilterValue,
					oldfirmwares: (this.oldFwFilterValue.indexOf(-1) > -1) ? null : this.oldFwFilterValue,
					newfirmwares: (this.newFwFilterValue.indexOf(-1) > -1) ? null : this.newFwFilterValue,
					dates: (this.datesRangeFormated.indexOf(-1) > -1) ? null : this.datesRangeFormated
				};
			} else {
				this._searchText = '';
				this.conditions = {
					conditions: [],
					parameters: {},
					devices: (this.devicesFilterValue.indexOf(-1) > -1) ? null : this.devicesFilterValue,
					models: this.modelsFilterValue,
					pbas: this.pbasFilterValue,
					events: (this.eventsFilterValue.indexOf(-1) > -1) ? null : this.eventsFilterValue,
					origins: (this.originFilterValue.indexOf(-1) > -1) ? null : this.originFilterValue,
					oldfirmwares: (this.oldFwFilterValue.indexOf(-1) > -1) ? null : this.oldFwFilterValue,
					newfirmwares: (this.newFwFilterValue.indexOf(-1) > -1) ? null : this.newFwFilterValue,
					dates: (this.datesRangeFormated.indexOf(-1) > -1) ? null : this.datesRangeFormated
				};
			}

			this.loadItems();
		}, 500);
	}

	constructor(private _translate: TranslateService,
		private _notificationSvc: NotificationsService,
		private _versionSvc: VersionsService,
		private _authSvc: AuthService) {
		this.loggedUser = this._authSvc.user;
	}

	ngOnInit() {
		this._setFilterData();
	}

	public loadItems(nPage: number = 1) {
		this.disableButtons = true;
		this.page = nPage;

		this._versionSvc.findUpdateLogs(this.limit, this.page, this.conditions).then((result: any) => {
			let errorNum: string;

			for (let log of result.items) {
				if (log.update_event !== '') {
					if (log.update_event.indexOf('INSTALACION_FALLO') > -1) {
						errorNum = log.update_event.charAt(17);
						log.update_event = '<div class="text-danger">' + this._translate.instant('INSTALACION_FALLO') + ' ' + errorNum + '</div>';
					} else if (log.update_event.indexOf('DESCARGA_FALLO') > -1) {
						errorNum = log.update_event.charAt(14);
						log.update_event = '<div class="text-danger">' + this._translate.instant('DESCARGA_FALLO') + ' ' + errorNum + '</div>';
					} else if (log.update_event.indexOf('TRANSFERENCIA_FALLO') > -1) {
						errorNum = log.update_event.charAt(19);
						log.update_event = '<div class="text-danger">' + this._translate.instant('TRANSFERENCIA_FALLO') + ' ' + errorNum + '</div>';
					} else if (log.update_event.indexOf('TRANS_POT_VIS_FALLO') > -1) {
						errorNum = log.update_event.charAt(19);
						log.update_event = '<div class="text-danger">' + this._translate.instant('TRANS_POT_VIS_FALLO') + ' ' + errorNum + '</div>';
					} else if (log.update_event.indexOf('ERROR_TIMEOUT') > -1 || log.update_event.indexOf('RESET_UPDATE') > -1) {
						log.update_event = '<div class="text-danger">' + this._translate.instant(log.update_event) + '</div>';
					} else if (log.update_event.indexOf('ORDEN_UPDATE') > -1) {
						log.update_event = this._translate.instant('ORDEN_UPDATE');
					} else {
						log.update_event = '<div class="text-success">' + this._translate.instant(log.update_event) + '</div>';
					}
				}
			}

			this.updateLogs = result.items;
			this.totalUpdateLogs = result.total;
			this.totalPages = Math.ceil(this.totalUpdateLogs / this.limit);
			this.disableButtons = false;
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}

			this.disableButtons = false;
		});
	}

	public modelType(device: any) {
		let devIsDS2 = false;
		let devIsIEZ = false;

		devIsDS2 = device.vars.mm.indexOf('DS2') > -1;
		devIsIEZ = device.vars.mr.indexOf('IEZ-70') > -1;

		if (devIsDS2) {
			return 'DS2';
		} else if (!devIsDS2 && devIsIEZ) {
			return 'PS';
		} else {
			return 'MINI';
		}
	}

	// FILTROS -------
	public filterChanged(option: number) {
		const hasAllDevices: boolean = this.devicesFilterValue.indexOf(-1) > -1;
		const hasAllEvents: boolean = this.eventsFilterValue.indexOf(-1) > -1;
		const hasAllOrigins: boolean = this.originFilterValue.indexOf(-1) > -1;
		const hasAllOldFw: boolean = this.oldFwFilterValue.indexOf(-1) > -1;
		const hasAllNewFw: boolean = this.newFwFilterValue.indexOf(-1) > -1;

		if (this.filtersDelay) {
			clearTimeout(this.filtersDelay);
			this.filtersDelay = null;
		}

		switch (option) {
			case 1:
				if (!hasAllDevices && this.devicesFilterHasAllBefore) {
					this.devicesFilterValue = [];
				} else if (hasAllDevices && !this.devicesFilterHasAllBefore) {
					this.devicesFilterValue = _.clone(this.devicesFilterValueBack);
				}

				if (this.devicesFilterValue.length < this.devicesFilterValueBack.length && hasAllDevices) {
					const index = this.devicesFilterValue.indexOf(-1);
					this.devicesFilterValue.splice(index, 1);
				} else if (this.devicesFilterValue.length === (this.devicesFilterValueBack.length - 1) && !hasAllDevices) {
					this.devicesFilterValue.push(-1);
				}
				break;
			case 4:
				if (!hasAllEvents && this.eventsFilterHasAllBefore) {
					this.eventsFilterValue = [];
				} else if (hasAllEvents && !this.eventsFilterHasAllBefore) {
					this.eventsFilterValue = _.clone(this.eventsFilterValueBack);
				}

				if (this.eventsFilterValue.length < this.eventsFilterValueBack.length && hasAllEvents) {
					const index = this.eventsFilterValue.indexOf(-1);
					this.eventsFilterValue.splice(index, 1);
				} else if (this.eventsFilterValue.length === (this.eventsFilterValueBack.length - 1) && !hasAllEvents) {
					this.eventsFilterValue.push(-1);
				}
				break;
			case 5:
				if (!hasAllOrigins && this.originFilterHasAllBefore) {
					this.originFilterValue = [];
				} else if (hasAllOrigins && !this.originFilterHasAllBefore) {
					this.originFilterValue = _.clone(this.originFilterValueBack);
				}

				if (this.originFilterValue.length < this.originFilterValueBack.length && hasAllOrigins) {
					const index = this.originFilterValue.indexOf(-1);
					this.originFilterValue.splice(index, 1);
				} else if (this.originFilterValue.length === (this.originFilterValueBack.length - 1) && !hasAllOrigins) {
					this.originFilterValue.push(-1);
				}
				break;
			case 6:
				if (!hasAllOldFw && this.oldFwFilterHasAllBefore) {
					this.oldFwFilterValue = [];
				} else if (hasAllOldFw && !this.oldFwFilterHasAllBefore) {
					this.oldFwFilterValue = _.clone(this.oldFwFilterValueBack);
				}

				if (this.oldFwFilterValue.length < this.oldFwFilterValueBack.length && hasAllOldFw) {
					const index = this.oldFwFilterValue.indexOf(-1);
					this.oldFwFilterValue.splice(index, 1);
				} else if (this.oldFwFilterValue.length === (this.oldFwFilterValueBack.length - 1) && !hasAllOldFw) {
					this.oldFwFilterValue.push(-1);
				}
				break;
			case 7:
				if (!hasAllNewFw && this.newFwFilterHasAllBefore) {
					this.newFwFilterValue = [];
				} else if (hasAllNewFw && !this.newFwFilterHasAllBefore) {
					this.newFwFilterValue = _.clone(this.newFwFilterValueBack);
				}

				if (this.newFwFilterValue.length < this.newFwFilterValueBack.length && hasAllNewFw) {
					const index = this.newFwFilterValue.indexOf(-1);
					this.newFwFilterValue.splice(index, 1);
				} else if (this.newFwFilterValue.length === (this.newFwFilterValueBack.length - 1) && !hasAllNewFw) {
					this.newFwFilterValue.push(-1);
				}
				break;
			default:
				break;
		}

		this.devicesFilterHasAllBefore = this.devicesFilterValue.indexOf(-1) > -1;
		this.eventsFilterHasAllBefore = this.eventsFilterValue.indexOf(-1) > -1;
		this.originFilterHasAllBefore = this.originFilterValue.indexOf(-1) > -1;
		this.oldFwFilterHasAllBefore = this.oldFwFilterValue.indexOf(-1) > -1;
		this.newFwFilterHasAllBefore = this.newFwFilterValue.indexOf(-1) > -1;

		this.filtersDelay = setTimeout(() => {
			this.searchText = this._searchText;
		}, 750);
	}

	private _setFilterData() {
		// devicesFilter
		this.devicesFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.devicesFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('DISPOSITIVOS'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// typeFilter
		this.modelsFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: false,
			stopScrollPropagation: true
		};
		this.modelsFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('MODELO'),
			allSelected: this._translate.instant('TODOS')
		});
		this.modelsFilterOptions = [{
			// tslint:disable-next-line:quotemark
			id: "vars.mr = 'IEZ-70'",
			name: 'PS'
		}, {
			// tslint:disable-next-line:quotemark
			id: "vars.mm LIKE 'DS2%'",
			name: 'DS2'
		}, {
			// tslint:disable-next-line:quotemark
			id: "(vars.mm NOT LIKE 'DS2%' AND vars.mr != 'IEZ-70')",
			name: 'MINI'
		}];
		// tslint:disable-next-line:quotemark
		this.modelsFilterValue = ["vars.mr = 'IEZ-70'", "vars.mm LIKE 'DS2%'", "(vars.mm NOT LIKE 'DS2%' AND vars.mr != 'IEZ-70')"];

		// pbasFilter
		this.pbasFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: false,
			stopScrollPropagation: true
		};
		this.pbasFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('PBAS'),
			allSelected: this._translate.instant('TODOS')
		});
		this.pbasFilterOptions = [{
			id: 'POT',
			name: 'POT'
		}, {
			id: 'VIS',
			name: 'VIS'
		}];
		this.pbasFilterValue = ['POT', 'VIS'];

		// eventsFilter
		this.eventsFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.eventsFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('EVENTO'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// originFilter
		this.originFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.originFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('ORIGEN_EVENTO'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// oldFwFilter
		this.oldFwFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.oldFwFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('FW_PREVIO'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// newFwFilter
		this.newFwFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.newFwFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('FW_NUEVO'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// filtro fecha
		switch (this.loggedUser.idioma_notificaciones) {
			case 'Castellano':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'es' });
				break;
			case 'Aleman':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'de' });
				break;
			case 'Italiano':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'it' });
				break;
			case 'Frances':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'fr' });
				break;
			case 'Portugues':
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'pt' });
				break;
			default:
				this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', locale: 'en' });
				break;
		}

		// obtengo los datos
		this._versionSvc.getUpdateLogData().then((updateLogData: any) => {
			let dateStart: any;
			let dateEnd: any;
			let errorNum: string;

			this.devicesFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.devicesFilterValue.push(-1);

			for (let device of updateLogData.devices) {
				device.name = `${device.name} (${device.ns})`;
				this.devicesFilterValue.push(device.id);
				this.devicesFilterOptions.push(device);
			}

			this.devicesFilterValueBack = _.clone(this.devicesFilterValue);
			this.eventsFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.eventsFilterValue.push(-1);

			for (let event of updateLogData.events) {
				if (event.id !== '') {
					errorNum = '';

					if (event.name.indexOf('INSTALACION_FALLO') > -1) {
						errorNum = event.name.charAt(17);
						event.name = this._translate.instant('INSTALACION_FALLO') + ' ' + errorNum;
					} else if (event.name.indexOf('DESCARGA_FALLO') > -1) {
						errorNum = event.name.charAt(14);
						event.name = this._translate.instant('DESCARGA_FALLO') + ' ' + errorNum;
					} else if (event.name.indexOf('TRANSFERENCIA_FALLO') > -1) {
						errorNum = event.name.charAt(19);
						event.name = this._translate.instant('TRANSFERENCIA_FALLO') + ' ' + errorNum;
					} else if (event.name.indexOf('TRANS_POT_VIS_FALLO') > -1) {
						errorNum = event.name.charAt(19);
						event.name = this._translate.instant('TRANS_POT_VIS_FALLO') + ' ' + errorNum;
					} else {
						event.name = this._translate.instant(event.name);
					}

					this.eventsFilterValue.push(event.id);
					this.eventsFilterOptions.push(event);
				}
			}

			this.eventsFilterValueBack = _.clone(this.eventsFilterValue);
			this.originFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.originFilterValue.push(-1);

			for (let origin of updateLogData.origins) {
				if (origin.id !== '') {
					origin.name = this._translate.instant(origin.name);
					this.originFilterValue.push(origin.id);
					this.originFilterOptions.push(origin);
				}
			}

			this.originFilterValueBack = _.clone(this.originFilterValue);
			this.oldFwFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.oldFwFilterValue.push(-1);

			for (let oldFw of updateLogData.oldfw) {
				this.oldFwFilterValue.push(oldFw.id);
				this.oldFwFilterOptions.push(oldFw);
			}

			this.oldFwFilterValueBack = _.clone(this.oldFwFilterValue);
			this.newFwFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.newFwFilterValue.push(-1);

			for (let newFw of updateLogData.newfw) {
				this.newFwFilterValue.push(newFw.id);
				this.newFwFilterOptions.push(newFw);
			}

			this.newFwFilterValueBack = _.clone(this.newFwFilterValue);
			this.minDate = moment(updateLogData.dates.dateStart).toDate();
			this.maxDate = moment(updateLogData.dates.dateEnd).toDate();
			dateStart = moment(updateLogData.dates.dateStart).local().toDate();
			dateEnd = moment(updateLogData.dates.dateEnd).local().toDate();
			this.datesRange = [dateStart, dateEnd];
			this.datesRangeFormated = [
				moment(updateLogData.dates.dateStart).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
				moment(updateLogData.dates.dateEnd).endOf('day').format('YYYY-MM-DD HH:mm:ss')
			];
			this.loadItems();
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}

			this.loadItems();
		});
	}

	public filterByDate(event) {
		if (!event || !event[0] || !event[1]) {
			this.loadItems();
		} else {
			this.datesRangeFormated = [
				moment(event[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
				moment(event[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
			];
			this.searchText = this._searchText;
		}
	}
}
