<div *ngIf="totalModels">
	<span *ngIf="totalModels" class="badge badge-info">{{ totalModels }}</span>
	<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
</div>

<p class="text-right" *ngIf="models">
	<button class="btn btn-primary" (click)="createModel()">{{ 'ADD_MODELO' | translate }}</button>
</p>

<table-sorter [disableBtn]="disableButtons" [loading]="!models" [(page)]="page" [limit]="limit" [total]="totalModels" (limitChanged)="limit = $event; loadItems()" (prev)="page = $event; loadItems($event);" (search)="searchText = $event" (next)="page = $event; loadItems($event)" (pageChange)="loadItems($event)">
	<div *ngIf="models" class="visible-xs">
		<div *ngFor="let model of models; let i = index" class="panel panel-default">
			<div class="panel-body">
				<div>
					<strong>{{ 'MODELO' | translate }}: </strong>{{ model.name }}
					<div>
						<strong>MM:</strong> {{ (model.mm) ? model.mm : '-' }} <strong>MR:</strong> {{ (model.mr) ? model.mr : '-' }}
					</div>
				</div>
				<hr>
				<div>
					<strong>{{ 'PBA1' | translate }}: </strong>
					<div *ngIf="model.id_pba1">
						{{ model.id_pba1.name }} <strong>{{ 'MINIMO' | translate }}: </strong>{{ model.min1 }} <strong>{{ 'MAXIMO' | translate }}: </strong>{{ model.max1 }}
					</div>
				</div>
				<div>
					<strong>{{ 'PBA2' | translate }}: </strong>
					<div *ngIf="model.id_pba2">
						{{ model.id_pba2.name }} <strong>{{ 'MINIMO' | translate }}: </strong>{{ model.min2 }} <strong>{{ 'MAXIMO' | translate }}: </strong>{{ model.max2 }}
					</div>
				</div>
				<div>
					<strong>{{ 'PBA3' | translate }}: </strong>
					<div *ngIf="model.id_pba3">
						{{ model.id_pba3.name }} <strong>{{ 'MINIMO' | translate }}: </strong>{{ model.min3 }} <strong>{{ 'MAXIMO' | translate }}: </strong>{{ model.max3 }}
					</div>
				</div>
				<div>
					<strong>{{ 'PBA4' | translate }}: </strong>
					<div *ngIf="model.id_pba4">
						{{ model.id_pba4.name }} <strong>{{ 'MINIMO' | translate }}: </strong>{{ model.min4 }} <strong>{{ 'MAXIMO' | translate }}: </strong>{{ model.max4 }}
					</div>
				</div>
				<div>
					<strong>{{ 'PBA5' | translate }}: </strong>
					<div *ngIf="model.id_pba5">
						{{ model.id_pba5.name }} <strong>{{ 'MINIMO' | translate }}: </strong>{{ model.min5 }} <strong>{{ 'MAXIMO' | translate }}: </strong>{{ model.max5 }}
					</div>
				</div>
				<div>
					<strong>{{ 'PBA6' | translate }}: </strong>
					<div *ngIf="model.id_pba6">
						{{ model.id_pba6.name }} <strong>{{ 'MINIMO' | translate }}: </strong>{{ model.min6 }} <strong>{{ 'MAXIMO' | translate }}: </strong>{{ model.max6 }}
					</div>
				</div>
				<hr>
				<div class="text-center">
					<button [disabled]="disableButtons" class="btn btn-primary btn-link" (click)="edit(i)">
						{{ 'EDITAR' | translate }}
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger btn-link" (click)="tempModel = model; deleteModel.show()">
						{{ 'BORRAR' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<table *ngIf="models" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
		<thead>
			<tr>
				<th>{{ 'MODELO' | translate }}</th>
				<th>MM</th>
				<th>MR</th>
				<th>{{ 'PBA1' | translate }}</th>
				<th>{{ 'PBA2' | translate }}</th>
				<th>{{ 'PBA3' | translate }}</th>
				<th>{{ 'PBA4' | translate }}</th>
				<th>{{ 'PBA5' | translate }}</th>
				<th>{{ 'PBA6' | translate }}</th>
				<th>{{ 'ACCIONES' | translate }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let model of models; let i = index" class="text-center">
				<td>
					<strong>{{ model.name }}</strong>
				</td>
				<td>
					{{ (model.mm) ? model.mm : '-' }}
				</td>
				<td>
					{{ (model.mr) ? model.mr : '-' }}
				</td>
				<td>
					<div class="text-primary">
						{{ model.id_pba1 ? model.id_pba1.name : '' }}
					</div>
					<div *ngIf="model.id_pba1">
						<strong>{{ 'MINIMO' | translate }}:</strong> {{ model.min1 !== null ? model.min1 : '' }} <strong>{{ 'MAXIMO' | translate }}:</strong> {{ model.max1 !== null ? model.max1 : '' }}
					</div>
				</td>
				<td>
					<div class="text-primary">
						{{ model.id_pba2 ? model.id_pba2.name : '' }}
					</div>
					<div *ngIf="model.id_pba2">
						<strong>{{ 'MINIMO' | translate }}:</strong> {{ model.min2 !== null ? model.min2 : '' }} <strong>{{ 'MAXIMO' | translate }}:</strong> {{ model.max2 !== null ? model.max2 : '' }}
					</div>
				</td>
				<td>
					<div class="text-primary">
						{{ model.id_pba3 ? model.id_pba3.name : '' }}
					</div>
					<div *ngIf="model.id_pba3">
						<strong>{{ 'MINIMO' | translate }}:</strong> {{ model.min3 !== null ? model.min3 : '' }} <strong>{{ 'MAXIMO' | translate }}:</strong> {{ model.max3 !== null ? model.max3 : '' }}
					</div>
				</td>
				<td>
					<div class="text-primary">
						{{ model.id_pba4 ? model.id_pba4.name : '' }}
					</div>
					<div *ngIf="model.id_pba4">
						<strong>{{ 'MINIMO' | translate }}:</strong> {{ model.min4 !== null ? model.min4 : '' }} <strong>{{ 'MAXIMO' | translate }}:</strong> {{ model.max4 !== null ? model.max4 : '' }}
					</div>
				</td>
				<td>
					<div class="text-primary">
						{{ model.id_pba5 ? model.id_pba5.name : '' }}
					</div>
					<div *ngIf="model.id_pba5">
						<strong>{{ 'MINIMO' | translate }}:</strong> {{ model.min5 !== null ? model.min5 : '' }} <strong>{{ 'MAXIMO' | translate }}:</strong> {{ model.max5 !== null ? model.max5 : '' }}
					</div>
				</td>
				<td>
					<div class="text-primary">
						{{ model.id_pba6 ? model.id_pba6.name : '' }}
					</div>
					<div *ngIf="model.id_pba6">
						<strong>{{ 'MINIMO' | translate }}:</strong> {{ model.min6 !== null ? model.min6 : '' }} <strong>{{ 'MAXIMO' | translate }}:</strong> {{ model.max6 !== null ? model.max6 : '' }}
					</div>
				</td>
				<td class="actions">
					<button [disabled]="disableButtons" class="btn" (click)="edit(i)">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger" (click)="tempModel = model; deleteModel.show()">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</table-sorter>

<div bsModal #editModel="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editModel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="editModel.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'EDIT_DEFINICION' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div *ngIf="pbas">
					<div>
						<label>{{ 'NOMBRE' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempModel.name" maxlength="50">
					</div>
					<br>
					<div class="row">
						<div class="col-sm-12">
							<label>{{ 'MODELO_SISTEMA' | translate }}</label>
							<select [(ngModel)]="tempModel.deviceModel" class="form-control">
								<option value="DOM">Poolstation series 1 (MM: - MR: IEZ-70)</option>
								<option value="DS2">Poolstation series 2 (MM: DS2 MR: -)</option>
								<option value="MINI">Poolstation Mini (MM: - MR: -)</option>
							</select>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-sm-6">
							<label>{{ 'PBA1' | translate }}</label>
							<select [disabled]="disableButtons" [(ngModel)]="tempModel.idPba1" (change)="setPba(1, $event.target.value)" class="form-control">
								<option value="null">-</option>
								<option *ngFor="let pba of pbas" [value]="pba.id" [disabled]="(selectedPbas[pba.id]) ? true : false">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MINIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba1 === null || tempModel.idPba1 === 'null')" [(ngModel)]="tempModel.min1" (change)="tempModel.max1 < tempModel.min1 ? tempModel.max1 = tempModel.min1 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MAXIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba1 === null || tempModel.idPba1 === 'null')" [(ngModel)]="tempModel.max1" (change)="tempModel.min1 > tempModel.max1 ? tempModel.min1 = tempModel.max1 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
					</div>
					<br>
					<div class="row">
						<div class="col-sm-6">
							<label>{{ 'PBA2' | translate }}</label>
							<select [disabled]="disableButtons || (tempModel.idPba1 === null || tempModel.idPba1 === 'null')" [(ngModel)]="tempModel.idPba2" (change)="setPba(2, $event.target.value)" class="form-control">
								<option value="null">-</option>
								<option *ngFor="let pba of pbas" [value]="pba.id" [disabled]="(selectedPbas[pba.id]) ? true : false">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MINIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba2 === null || tempModel.idPba2 === 'null')" [(ngModel)]="tempModel.min2" (change)="tempModel.max2 < tempModel.min2 ? tempModel.max2 = tempModel.min2 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MAXIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba2 === null || tempModel.idPba2 === 'null')" [(ngModel)]="tempModel.max2" (change)="tempModel.min2 > tempModel.max2 ? tempModel.min2 = tempModel.max2 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
					</div>
					<br>
					<div class="row">
						<div class="col-sm-6">
							<label>{{ 'PBA3' | translate }}</label>
							<select [disabled]="disableButtons || (tempModel.idPba2 === null || tempModel.idPba2 === 'null')" [(ngModel)]="tempModel.idPba3" (change)="setPba(3, $event.target.value)" class="form-control">
								<option value="null">-</option>
								<option *ngFor="let pba of pbas" [value]="pba.id" [disabled]="(selectedPbas[pba.id]) ? true : false">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MINIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba3 === null || tempModel.idPba3 === 'null')" [(ngModel)]="tempModel.min3" (change)="tempModel.max3 < tempModel.min3 ? tempModel.max3 = tempModel.min3 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MAXIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba3 === null || tempModel.idPba3 === 'null')" [(ngModel)]="tempModel.max3" (change)="tempModel.min3 > tempModel.max3 ? tempModel.min3 = tempModel.max3 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
					</div>
					<br>
					<div class="row">
						<div class="col-sm-6">
							<label>{{ 'PBA4' | translate }}</label>
							<select [disabled]="disableButtons || (tempModel.idPba3 === null || tempModel.idPba3 === 'null')" [(ngModel)]="tempModel.idPba4" (change)="setPba(4, $event.target.value)" class="form-control">
								<option value="null">-</option>
								<option *ngFor="let pba of pbas" [value]="pba.id" [disabled]="(selectedPbas[pba.id]) ? true : false">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MINIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba4 === null || tempModel.idPba4 === 'null')" [(ngModel)]="tempModel.min4" (change)="tempModel.max4 < tempModel.min4 ? tempModel.max4 = tempModel.min4 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MAXIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba4 === null || tempModel.idPba4 === 'null')" [(ngModel)]="tempModel.max4" (change)="tempModel.min4 > tempModel.max4 ? tempModel.min4 = tempModel.max4 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
					</div>
					<br>
					<div class="row">
						<div class="col-sm-6">
							<label>{{ 'PBA5' | translate }}</label>
							<select [disabled]="disableButtons || (tempModel.idPba4 === null || tempModel.idPba4 === 'null')" [(ngModel)]="tempModel.idPba5" (change)="setPba(5, $event.target.value)" class="form-control">
								<option value="null">-</option>
								<option *ngFor="let pba of pbas" [value]="pba.id" [disabled]="(selectedPbas[pba.id]) ? true : false">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MINIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba5 === null || tempModel.idPba5 === 'null')" [(ngModel)]="tempModel.min5" (change)="tempModel.max5 < tempModel.min5 ? tempModel.max5 = tempModel.min5 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MAXIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba5 === null || tempModel.idPba5 === 'null')" [(ngModel)]="tempModel.max5" (change)="tempModel.min5 > tempModel.max5 ? tempModel.min5 = tempModel.max5 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
					</div>
					<br>
					<div class="row">
						<div class="col-sm-6">
							<label>{{ 'PBA6' | translate }}</label>
							<select [disabled]="disableButtons || (tempModel.idPba5 === null || tempModel.idPba5 === 'null')" [(ngModel)]="tempModel.idPba6" (change)="setPba(6, $event.target.value)" class="form-control">
								<option value="null">-</option>
								<option *ngFor="let pba of pbas" [value]="pba.id" [disabled]="(selectedPbas[pba.id]) ? true : false">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MINIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba6 === null || tempModel.idPba6 === 'null')" [(ngModel)]="tempModel.min6" (change)="tempModel.max6 < tempModel.min6 ? tempModel.max6 = tempModel.min6 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label>{{ 'MAXIMO' | translate }}</label> 
							<select [disabled]="disableButtons || (tempModel.idPba6 === null || tempModel.idPba6 === 'null')" [(ngModel)]="tempModel.max6" (change)="tempModel.min6 > tempModel.max6 ? tempModel.min6 = tempModel.max6 : null" class="form-control">
								<option value="0">0</option>
								<option value="1">1</option>
								<option disabled value="2">2</option>
							</select>
						</div>
					</div>
				</div>
				<div *ngIf="!pbas" class="text-center">
					<img src="/assets/images/wave.svg">
				</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-primary" (click)="save()" role="button" aria-disabled="false">{{ 'BT_GUARDAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="editModel.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>

<div bsModal #deleteModel="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteModel" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="deleteModel.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'BORRAR' | translate }} Firmware</h4>
			</div>

			<div class="modal-body">
				<div>{{ 'MODELO' | translate }}: {{ tempModel.name }}</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-danger" (click)="delete()" role="button" aria-disabled="false">{{ 'BORRAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="deleteModel.hide()" class="btn btn-primary" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>
