import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';

// services
import { BaseService } from 'app/services/base.service';
import { AuthService } from 'app/services/auth.service';

// utils
import * as _ from 'lodash';
import { AppConfig } from '../AppConfig';

@Injectable()
export class VersionsService extends BaseService {
	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	// PBAs

	public findPbas(limit: number = 10, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		if (conditions){
			body = 'conditions=' + JSON.stringify(conditions);
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/pba/' + limit + '/' + offset, body, { headers: this._headers } ).subscribe((result) => {
				result = result/*.json()*/;

				resolve({
					total: result['total'],
					items: result['items']
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	public getPbasData() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/pba/pbasdata', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public savePba(data: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/pba/save', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public deletePba(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/pba/delete','id=' + id, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getAllPbas() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/pba/all', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	// HARDWARES

	public findHardwares(limit: number = 10, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		if(conditions) {
			body = 'conditions=' + JSON.stringify(conditions);
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/hardware/' + limit + '/' + offset, body, { headers: this._headers } ).subscribe((result) => {
				result = result/*.json()*/;

				resolve({
					total: result['total'],
					items: result['items'],
					pbas: result['pbas']
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	public getHardData() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/hardware/hwdata', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public saveHardware(data: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/hardware/save', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public deleteHardware(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/hardware/delete', 'id=' + id, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getAllHardwares() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/hardware/all', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getAllPbaHardwares(pbaId: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/hardware/pbahw/' + pbaId, null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	// FIRMWARES

	public findFirmwares(limit: number = 10, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		if(conditions) {
			body = 'conditions=' + JSON.stringify(conditions);
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/' + limit + '/' + offset, body, { headers: this._headers } ).subscribe((result) => {
				result = result/*.json()*/;
				resolve({
					total: result['total'],
					items: result['items'],
					pbas: result['pbas']
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	public getFirmwaresData() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/firmdata', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public saveFirmware(data: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/save', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public uploadFirmware(file: File): Promise<any> {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			const xhr = new XMLHttpRequest();

			formData.append('firmware', file, file.name);

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						resolve(JSON.parse(xhr.response));
					} else {
						reject(JSON.parse(xhr.response));
					}
				}
			};

			xhr.open('POST', AppConfig.HOME_API + '/firmware/newFirmware', true);
			xhr.setRequestHeader('Authorization', `Bearer ${ this._authSvc.token }`)
			xhr.send(formData);
		});
	}

	public deleteFirmware(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/delete', 'id=' + id, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public deleteFile(file: string) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/deletefile','file=' + file, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getAllFirmwares(type: string = null) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/all', 'type=' + type, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getPbaFirmwares(data) {
		if (data.type === 'BACK') {
			data.type = 'APP';
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/pbafirm', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getAllPbaFirmwares(pbaId: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/pbafw/' + pbaId, null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public upgradeDevice(data: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/upgradedevice', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public resetUpgrade(id: number, auto: boolean) {
		let data = {
			id,
			manual: (auto) ? 0 : 1
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmware/resetupdate', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	// MODELOS

	public findModels(limit: number = 10, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		if(conditions){
			body = 'conditions=' + JSON.stringify(conditions);
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/model/' + limit + '/' + offset, body, { headers: this._headers } ).subscribe((result) => {
				result = result/*.json()*/;
				resolve({
					total: result['total'],
					items: result['items']
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	public saveModel(data: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/model/save', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public deleteModel(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/model/delete', 'id=' + id, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getAllModels() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/model/all', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	// COMPATIBILITIES

	public findCompatibilities(limit: number = 10, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		if(conditions) {
			body = 'conditions=' + JSON.stringify(conditions);
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/compatibility/' + limit + '/' + offset, body, { headers: this._headers } ).subscribe((result) => {
				result = result/*.json()*/;

				resolve({
					total: result['total'],
					items: result['items']
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	public getCompatibilityData() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/compatibility/compdata', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public saveCompatibility(data: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/compatibility/save', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public deleteCompatibility(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/compatibility/delete', 'id=' + id, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	// UPDATE LOGS

	public findUpdateLogs(limit: number = 10, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		if(conditions) {
			body = 'conditions=' + JSON.stringify(conditions);
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/updatelogs/' + limit + '/' + offset, body, { headers: this._headers } ).subscribe((result) => {
				result = result/*.json()*/;

				resolve({
					total: result['total'],
					items: result['items']
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	public getUpdateLogData() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/updatelogs/updatelogdata', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}
}
