import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from '../../AppConfig';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

// services
import { VersionsService } from 'app/services/versions.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// utils
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
	selector: 'firmware',
	templateUrl: './firmware.component.html',
	styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent implements OnInit {
	@ViewChild('editFirmware', {static: true}) editFirmware;
	@ViewChild('newFirmware', {static: true}) newFirmware;
	@ViewChild('deleteFirmware', {static: true}) deleteFirmware;
	@ViewChild('inputFile', {static: true}) inputFile: any;
	@ViewChild('errorBin', {static: true}) errorBin: any;

	public firmwares: any[];
	public pbas: any[];
	public idcoms = {};
	public pbaNames: any = {};
	public totalFirmwares: number;
	public tempFirmware: any = {};
	public limit: number = 20;
	public page: number = 1;
	public totalPages: number = 1;
	public conditions: any;
	public disableButtons: boolean = false;
	public file: File;

	public pbasFilterValue: any[] = [];
	public pbasFilterOptions: IMultiSelectOption[] = [];
	public pbasFilterSettings: IMultiSelectSettings;
	public pbasFilterTexts: IMultiSelectTexts;

	public typeFilterValue: any[] = [];
	public typeFilterOptions: IMultiSelectOption[];
	public typeFilterSettings: IMultiSelectSettings;
	public typeFilterTexts: IMultiSelectTexts;

	public nameFilterValue: any[] = [];
	public nameFilterOptions: IMultiSelectOption[] = [];
	public nameFilterSettings: IMultiSelectSettings;
	public nameFilterTexts: IMultiSelectTexts;

	public validFilterValue: any[] = [];
	public validFilterOptions: IMultiSelectOption[];
	public validFilterSettings: IMultiSelectSettings;
	public validFilterTexts: IMultiSelectTexts;

	public codeFilterValue: any[] = [];
	public codeFilterOptions: IMultiSelectOption[] = [];
	public codeFilterSettings: IMultiSelectSettings;
	public codeFilterTexts: IMultiSelectTexts;

	public pbasFilterValueBack: any;
	public fwFilterValueBack: any;
	public codeFilterValueBack: any;
	public pbasFilterHasAllBefore = true;
	public fwFilterHasAllBefore = true;
	public codeFilterHasAllBefore = true;

	public filtersDelay: any;

	private _searchText: string = '';

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		value = value.trim();

		if (value && value !== '') {
			this._searchText = value;
			this.conditions = {
				conditions: [
					'firmware.name LIKE :search OR firmware.code LIKE :search OR firmware.observations LIKE :search OR firmware.kind LIKE :search'
				],
				parameters: {
					search: this._searchText
				},
				pbas: (this.pbasFilterValue.indexOf(-1) > -1) ? null : this.pbasFilterValue,
				types: this.typeFilterValue,
				names: (this.nameFilterValue.indexOf(-1) > -1) ? null : this.nameFilterValue,
				valid: this.validFilterValue,
				codes: (this.codeFilterValue.indexOf(-1) > -1) ? null : this.codeFilterValue
			};
		} else {
			this.conditions = {
				conditions: [],
				parameters: {},
				pbas: (this.pbasFilterValue.indexOf(-1) > -1) ? null : this.pbasFilterValue,
				types: this.typeFilterValue,
				names: (this.nameFilterValue.indexOf(-1) > -1) ? null : this.nameFilterValue,
				valid: this.validFilterValue,
				codes: (this.codeFilterValue.indexOf(-1) > -1) ? null : this.codeFilterValue
			};
		}

		this.loadItems();
	}

	constructor(private _versionSvc: VersionsService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService) { }

	ngOnInit() {
		this._setFilterData();
	}

	public loadItems(nPage: number = 1) {
		this.disableButtons = true;

		this._versionSvc.findFirmwares(this.limit, nPage, this.conditions).then((result: any) => {
			this.disableButtons = false;
			this.firmwares = result.items;
			this.totalFirmwares = result.total;
			this.totalPages = Math.ceil(this.totalFirmwares / this.limit);

			for (let pba of result.pbas) {
				this.pbaNames[pba.id] = pba.name;
			}
			console.log(this.firmwares);
		}).catch((error: any) => {
			console.log(error);
			this.disableButtons = false;
			this.loadItems();
		});
	}

	public edit(index: number = null) {
		if (!this.pbas) {
			this._getPbas();
		}

		this.tempFirmware = _.clone(this.firmwares[index]);
		this.editFirmware.show();
		console.log(this.tempFirmware);
	}

	public createFirmware() {
		if (!this.pbas) {
			this._getPbas();
		}

		this.tempFirmware = {
			name: '',
			code: '',
			kind: 'APP',
			id_pba: null,
			valid: false,
			observations: '',
			url: ''
		};
		this.newFirmware.show();
	}

	public fileChange(event) {
		const name: string = event.srcElement.files[0].name;
		const extension = name.substr(name.lastIndexOf('.'), name.length);

		if (extension === '.bin') {
			this.file = event.srcElement.files[0];
		} else {
			this.inputFile.nativeElement.value = '';
			this.errorBin.nativeElement.style = 'visibility: visible; color: red';

			setTimeout(() => {
				this.errorBin.nativeElement.style = 'visibility: hidden';
			}, 3000);
		}
	}

	public save() {
		this.disableButtons = true;
		if (this._checkLength('name', 50) && this._checkLength('code', 3)) {
			this.tempFirmware.name = this.tempFirmware.name.trim();
			this.tempFirmware.code = this.tempFirmware.code.trim();

			if (this.tempFirmware.id) {
				this.tempFirmware.updated = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
				this._editFirm();
			} else {
				if (this.file) {
					this._newFirm();
				} else {
					this.disableButtons = false;
					this.inputFile.nativeElement.value = '';
					this.inputFile.nativeElement.style = 'border: 2px solid red; border-radius: 3px';
					setTimeout(() => { this.inputFile.nativeElement.style = 'border: 0px'; }, 3000);
				}
			}
		} else {
			this.disableButtons = false;
			this.editFirmware.hide();
			this.newFirmware.hide();
		}
	}

	public delete() {
		this.disableButtons = true;

		this._versionSvc.deleteFirmware(this.tempFirmware.id).then((result: any) => {
			this._notificationSvc.success('', this._translate.instant(result.message));
			this.disableButtons = false;
			this.deleteFirmware.hide();
			this.loadItems(this.page);
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}
			this.disableButtons = false;
			this.deleteFirmware.hide();
		});
	}

	public filterChanged(option: number) {
		const hasAllPbas: boolean = this.pbasFilterValue.indexOf(-1) > -1;
		const hasAllNames: boolean = this.nameFilterValue.indexOf(-1) > -1;
		const hasAllCodes: boolean = this.codeFilterValue.indexOf(-1) > -1;

		if (this.filtersDelay) {
			clearTimeout(this.filtersDelay);
			this.filtersDelay = null;
		}

		switch (option) {
			case 1:
				if (!hasAllPbas && this.pbasFilterHasAllBefore) {
					this.pbasFilterValue = [];
				} else if (hasAllPbas && !this.pbasFilterHasAllBefore) {
					this.pbasFilterValue = _.clone(this.pbasFilterValueBack);
				}

				if (this.pbasFilterValue.length < this.pbasFilterValueBack.length && hasAllPbas) {
					const index = this.pbasFilterValue.indexOf(-1);
					this.pbasFilterValue.splice(index, 1);
				} else if (this.pbasFilterValue.length === (this.pbasFilterValueBack.length - 1) && !hasAllPbas) {
					this.pbasFilterValue.push(-1);
				}
				break;
			case 3:
				if (!hasAllNames && this.fwFilterHasAllBefore) {
					this.nameFilterValue = [];
				} else if (hasAllNames && !this.fwFilterHasAllBefore) {
					this.nameFilterValue = _.clone(this.fwFilterValueBack);
				}

				if (this.nameFilterValue.length < this.fwFilterValueBack.length && hasAllNames) {
					const index = this.nameFilterValue.indexOf(-1);
					this.nameFilterValue.splice(index, 1);
				} else if (this.nameFilterValue.length === (this.fwFilterValueBack.length - 1) && !hasAllNames) {
					this.nameFilterValue.push(-1);
				}
				break;
			case 5:
				if (!hasAllCodes && this.codeFilterHasAllBefore) {
					this.codeFilterValue = [];
				} else if (hasAllCodes && !this.codeFilterHasAllBefore) {
					this.codeFilterValue = _.clone(this.codeFilterValueBack);
				}

				if (this.codeFilterValue.length < this.codeFilterValueBack.length && hasAllCodes) {
					const index = this.codeFilterValue.indexOf(-1);
					this.codeFilterValue.splice(index, 1);
				} else if (this.codeFilterValue.length === (this.codeFilterValueBack.length - 1) && !hasAllCodes) {
					this.codeFilterValue.push(-1);
				}
				break;
			default:
				break;
		}

		this.pbasFilterHasAllBefore = this.pbasFilterValue.indexOf(-1) > -1;
		this.fwFilterHasAllBefore = this.nameFilterValue.indexOf(-1) > -1;
		this.codeFilterHasAllBefore = this.codeFilterValue.indexOf(-1) > -1;

		this.filtersDelay = setTimeout(() => {
			this.searchText = this._searchText;
		}, 750);
	}

	private _setFilterData() {
		// validFilter
		this.validFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: false,
			stopScrollPropagation: true
		};
		this.validFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('VERSION_VALIDA'),
			allSelected: this._translate.instant('TODOS')
		});
		this.validFilterValue = [0, 1];
		this.validFilterOptions = [{
			id: 1,
			name: this._translate.instant('SI')
		}, {
			id: 0,
			name: this._translate.instant('NO')
		}];

		// pbasFilter
		this.pbasFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.pbasFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('PBAS'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// typeFilter
		this.typeFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: false,
			stopScrollPropagation: true
		};
		this.typeFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('TIPO'),
			allSelected: this._translate.instant('TODOS')
		});
		this.typeFilterValue = ['APP', 'DATA'];
		this.typeFilterOptions = [{
			id: 'APP',
			name: 'APP'
		}, {
			id: 'DATA',
			name: 'DATA'
		}];

		// nameFilter
		this.nameFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.nameFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('FIRMWARE'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// codeFilter
		this.codeFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.codeFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('CODIGO'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// obtengo los datos
		this._versionSvc.getFirmwaresData().then((fwData: any) => {
			this.pbasFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.pbasFilterValue.push(-1);
			for (let pba of fwData.pbas) {
				this.pbasFilterValue.push(pba.id);
				this.pbasFilterOptions.push(pba);
			}
			this.pbasFilterValueBack = _.clone(this.pbasFilterValue);

			this.nameFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.nameFilterValue.push(-1);

			for (let fw of fwData.firmwareNames) {
				this.nameFilterValue.push(fw.id);
				this.nameFilterOptions.push(fw);
			}

			this.fwFilterValueBack = _.clone(this.nameFilterValue);

			this.codeFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.codeFilterValue.push(-1);

			for (let code of fwData.firmwareCodes) {
				this.codeFilterValue.push(code.id);
				this.codeFilterOptions.push(code);
			}

			this.codeFilterValueBack = _.clone(this.codeFilterValue);

			this.loadItems();
		}).catch((error) => {
			this._notificationSvc.error('', this._translate.instant(error.message));
			this.loadItems();
		});
	}

	private _getPbas() {
		this.idcoms = {};

		this._versionSvc.getAllPbas().then((result: any) => {
			this.pbas = result;

			for (const pba of this.pbas) {
				this.pbaNames[pba.id] = pba.name;
				if (pba.type === 'IDC') {
					this.idcoms[pba.id] = true;
				}
			}
		}).catch((error) => {
			this._getPbas();
			console.log(error);
		});
	}

	private _checkLength(field: string, max: number, min: number = 1): boolean {
		let ok = true;

		if (this.tempFirmware[field].trim().length > max || this.tempFirmware[field].trim().length < min) {
			this.tempFirmware = {};
			this.editFirmware.hide();
			this._notificationSvc.error('', this._translate.instant('LONGITUD_CAMPO') + min + '-' + max);
			ok = false;
		} else if (this.tempFirmware[field].trim().length === 0) {
			this.tempFirmware = {};
			this.editFirmware.hide();
			this._notificationSvc.error('', this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS'));
			ok = false;
		}

		return ok;
	}

	private _editFirm() {
		this._versionSvc.saveFirmware(this.tempFirmware).then((result: any) => {
			this.disableButtons = false;
			this.editFirmware.hide();
			this._notificationSvc.success('', this._translate.instant(result.message));
			this.loadItems(this.page);
		}).catch((error) => {
			this.disableButtons = false;
			this.editFirmware.hide();
		});
	}

	private _newFirm() {
		this.tempFirmware.url = AppConfig.FIRM_URL + '/fwupdates/' + this.file['name'];

		this._versionSvc.uploadFirmware(this.file).then((fileName) => {
			this._versionSvc.saveFirmware(this.tempFirmware).then((result: any) => {
				this.disableButtons = false;
				this.newFirmware.hide();
				this._notificationSvc.success('', this._translate.instant(result.message));
				this.loadItems(this.page);
			}).catch((err) => {
				this._versionSvc.deleteFile(fileName.file).then((deleteResult: any) => {
					console.log(deleteResult.message);
				}).catch((deleteError) => {
					console.log(deleteError.message);
				});

				this.newFirmware.hide();
				this._notificationSvc.error('', this._translate.instant(err.message));
				this.disableButtons = false;
			});
		}).catch((error) => {
			this.disableButtons = false;
			this.newFirmware.hide();
			this._notificationSvc.error('', this._translate.instant(error.message));
		});
	}
}
