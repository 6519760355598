<header ps-header>
	<h2>{{ 'AVISO_HEAD' | translate }}</h2>
</header>
<main class="container-fluid">
	<div class="container">
		<h3>{{ 'AVISO_HEAD' | translate }}</h3>
		<p [innerHTML]="'AVISO_TEXTO' | translate"></p>
		<h3>{{ 'ACCESO_TITLE' | translate }}</h3>
		<div [innerHTML]="'ACCESO_TEXTO' | translate" class="dots"></div>
		<div [innerHTML]="'COMUNICACIONES_TEXTO' | translate"></div>
		<h3>{{ 'EXONERACION_TITLE' | translate }}</h3>
		<div [innerHTML]="'EXONERACION_TEXTO' | translate"></div>
		<h3>{{ 'PROTECCION_DATOS' | translate }}</h3>
        <div [innerHTML] ="'PROTECCION_DATOS_TEXTO' | translate" ></div>
	</div>
</main>
