import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// vendors
import { NotificationsService } from 'angular2-notifications';

// services
import { ProfessionalsService } from '../../services/professionals.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
	selector: 'clients',
	templateUrl: 'clients.component.html'
})
export class ClientsComponent implements OnInit {
	@ViewChild('deleteModal', {static: true}) public deleteModal: ModalDirective;

	public selectedClient: any;
	public disableButtons: boolean = false;
	public blockButtons: boolean = false;
	public clients: any[];
	public totalClients: number = 0;
	public page: number = 1;
	public _searchText: string = '';
	public order: any = {
		field: 'nombre',
		direction: 'DESC',
	};
	public pagination = {
		total: 0,
		limit: 20,
		totalPages: 0
	};
	public conditions: any = {
		conditions: [],
		parameters: {}
	};

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		if (value && value !== '') {
			this._searchText = value;
			this.conditions = {
				conditions: [
					'user.nombre LIKE :search OR user.apellidos LIKE :search OR user.usr LIKE :search OR user.telefono LIKE :search OR user.idLoc like :search'
				],
				parameters: {
					search: this._searchText
				}
			};

			this.loadItems();
		} else {
			this.conditions = {
				conditions: [],
				parameters: {}
			};

			this.loadItems();
		}
	}

	constructor(
		private _notificationService: NotificationsService,
		private _professionalsSvc: ProfessionalsService) {
	}

	public ngOnInit() {
		this.loadItems();
	}

	public loadItems(page: number = 1) {
		this.disableButtons = true;

		this._professionalsSvc.findClients(this.pagination.limit, page, this.conditions, this.order).then((result: any) => {
			this.clients = result.items;
			this.totalClients = result.total;
			this.pagination.total = result.total;
			this.pagination.totalPages = Math.ceil(this.pagination.total / this.pagination.limit);
			this.disableButtons = false;
		}).catch((error) => {
			this.disableButtons = false;
			console.log(error);
		});
	}

	public selectClient(client) {
		this.selectedClient = client;
		this.deleteModal.show();
	}

	public delete() {
		this.blockButtons = true;

		this._professionalsSvc.deleteClients(this.selectedClient.id).then((requests: any) => {
			this.deleteModal.hide();
			this._notificationService.success('', 'OK');
			this.selectedClient = null;
			this.loadItems();
			this.blockButtons = false;
		}).catch((reason) => {
			this.deleteModal.hide();
			this._notificationService.error('', reason.message);
			this.selectedClient = null;
			this.loadItems();
			this.blockButtons = false;
		});
	}

	public changeOrder(order: any) {
		this.page = 1;
		this.order.field = order.column;
		this.order.direction = order.direction;
		this.loadItems();
	}
}
