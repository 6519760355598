<header ps-header>
	<h2>{{ "HISTORICO_DE_ACCIONES" | translate }}
		<span *ngIf="device">
			<small><b>{{ device.ns }}</b></small>
			<small>- {{ device.alias }}</small>
		</span>
	</h2>
</header>

<main class="container-fluid" style="overflow: hidden">
	<div class="row">
		<div class="col-sm-12 text-right" style="margin-bottom: 5px;">
			<table-sorter [disableBtn]="disableButtons" [loading]="!loaded" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limit = $event; loadItems();" (prev)="loadItems($event);" (search)="searchText = $event;" (next)="loadItems($event);" (pageChange)="loadItems($event);" (optional)="countLogs();">
				<span class="btn-group">
					<label class="radio-inline"><input type="radio" name="typeFilter" [(ngModel)]="filter" [value]="0" (change)="loadItems()">{{ 'USUARIO' | translate }}</label>
					<label class="radio-inline"><input type="radio" name="typeFilter" [(ngModel)]="filter" [value]="1" (change)="loadItems()">{{ 'MAQUINA' | translate }}</label>
					<label class="radio-inline"><input type="radio" name="typeFilter" [(ngModel)]="filter" [value]="2" (change)="loadItems()">{{ 'MOSTRAR_TODO' | translate }}</label>
				</span>&nbsp;
				<button *ngIf="loggedUser.id_role !== ('' | roles).PROFESIONAL && loggedUser.id_role !== ('' | roles).SAT" [disabled]="totalLogs <
				1 || disableButtons"
						class="btn btn-danger" title="{{
				'BORRAR_ACCIONES' | translate }}" data-toggle="modal" data-target="#deleteLogs"><i class="fa fa-trash fa-fw" aria-hidden="true"></i>{{ 'BORRAR_ACCIONES' | translate }}</button>
			</table-sorter>
		</div>
		<p *ngIf="usersFromLogs" class="col-sm-12 text-left">{{ 'USUARIOS' | translate}}:
			<span class="badge badge-info" *ngFor="let u of usersFromLogs">
				{{ u.name | translate }}: <span class="text-info">{{ u.total }}</span>
			</span>&nbsp;
		</p>
	</div>

	<table *ngIf="loaded" class="table table-striped table-bordered table-hover visible-lg">
		<thead>
			<tr>
				<th ordenable order="idUser/nombre" (change)="changeOrder($event)" style="width: 17%">{{ 'USUARIO' | translate }}</th>
				<th style="width: 40%">{{ 'CAMBIO' | translate }}</th>
				<th ordenable order="valorAnterior" (change)="changeOrder($event)" style="width: 17%">{{ 'ANTES' | translate }}</th>
				<th ordenable order="valorNuevo" (change)="changeOrder($event)" style="width: 17%">{{ 'DESPUES' | translate }}</th>
				<th ordenable order="fecha" (change)="changeOrder($event)" style="width: 9%">{{ 'FECHA' | translate }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="logsData && nameFilterOptions.length > 0">
				<td>
					<div *ngIf="logsData && nameFilterTexts">
						<ss-multiselect-dropdown [settings]="nameFilterSettings" [texts]="nameFilterTexts" [options]="nameFilterOptions" [(ngModel)]="nameFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div *ngIf="logsData && eventsFilterTexts">
						<ss-multiselect-dropdown id="changes" [settings]="eventsFilterSettings" [texts]="eventsFilterTexts" [options]="eventsFilterOptions" [(ngModel)]="eventsFilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td></td>
				<td></td>
				<td>
					<div *ngIf="logsData">
						<input class="form-control" [minDate]="minDate" [maxDate]="maxDate" #drp="bsDaterangepicker" (ngModelChange)="filterByDate($event)" [(ngModel)]="datesRange" [bsConfig]="bsConfig" placement="left" bsDaterangepicker>
					</div>
				</td>
			</tr>
			<ng-container *ngFor="let log of logs">
				<tr [ngClass]="log.type">
					<td class="actions" style="width: 12%"><span>{{ ( getUserFormLog(log) | translate) }}</span></td>

					<td>{{ log.changed | translate }}{{ (isDigitalInput(log.variable)) ? ' ' + log.variable[1] : '' }}
						<strong *ngIf="getRelayName(log.variable)">
							({{getRelayName(log.variable)}})
						</strong>
						<strong *ngIf="isDigitalInput(log.variable)">
							({{device[log.variable + '_name']}})
						</strong>
					</td>
					<td class="actions noStyle" style="width: 12%" [innerHTML]="isNumber(log.valor_anterior) ? getParsedNumber(log.valor_anterior,
					log) :
					(log.valor_anterior | translate)"></td>
					<td class="actions noStyle" style="width: 12%"
							[innerHTML]="isNumber(log.valor_nuevo) ? getParsedNumber(log.valor_nuevo, log) : (log.valor_nuevo | translate)"></td>
					<td class="actions">
						<div>{{ log.fecha | amDateFormat: 'DD-MM-YYYY' }}</div>
						<small>{{ log.fecha | amDateFormat: 'HH:mm:ss' }}</small>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>

	<div class="hidden-lg">
		<div *ngIf="logsData && nameFilterTexts">
			<label>{{ 'USUARIO' | translate }}</label>
			<ss-multiselect-dropdown [settings]="nameFilterSettings" [texts]="nameFilterTexts" [options]="nameFilterOptions" [(ngModel)]="nameFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
		</div>
		<div *ngIf="logsData && eventsFilterTexts">
			<label>{{ 'CAMBIO' | translate }}</label>
			<ss-multiselect-dropdown id="changes" [settings]="eventsFilterSettings" [texts]="eventsFilterTexts" [options]="eventsFilterOptions" [(ngModel)]="eventsFilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
		</div>
		<div *ngIf="logsData">
			<label>{{ 'FECHA' | translate }}</label>
			<input class="form-control" [minDate]="minDate" [maxDate]="maxDate" #drp="bsDaterangepicker" (ngModelChange)="filterByDate($event)" [(ngModel)]="datesRange" [bsConfig]="bsConfig" placement="bottom" bsDaterangepicker>
		</div>
		<p class="col-xs-12"></p>
	</div>

	<div *ngIf="nameFilterOptions.length > 0 && logs" class="hidden-lg">
		<ng-container *ngFor="let log of logs">
			<div class="panel panel-default">
				<div class="panel-body" [ngClass]="'bg-' + log.type" style="font-size: 12px">
					<div display-table>
						<p style="font-size: 14px">
							<strong>{{ log.fecha | amDateFormat: 'DD-MM-YYYY' }}</strong>
							<small>{{ log.fecha | amDateFormat: 'HH:mm:ss' }}</small>
						</p>
					</div>
					<div display-table class="text-center">
						<div display-table-cell>
							<strong>{{ 'DISPOSITIVOS' | translate }}:</strong> {{ device.alias }}
						</div>
						<div display-table-cell>
							<strong>{{ 'USUARIO' | translate }}:</strong> {{ log.id_user.nombre }}
						</div>
					</div>
					<div display-table class="text-center" style="font-size: 14px">
						<strong>{{ log.changed | translate }}{{ (isDigitalInput(log.variable)) ? ' ' + log.variable[1] : '' }}
							<label *ngIf="getRelayName(log.variable)">
								({{getRelayName(log.variable)}})
							</label>
							<label *ngIf="isDigitalInput(log.variable)">
								({{device[log.variable + '_name']}})
							</label>
						</strong>
					</div>
					<div display-table>
						<div><strong>{{ 'ANTES' | translate }}:</strong><span class="noStyle" [innerHTML]="log.valor_anterior | translate"></span></div>
						<div><strong>{{ 'DESPUES' | translate }}:</strong><span class="noStyle" [innerHTML]="log.valor_nuevo | translate"></span></div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div *ngIf="totalLogs === 0 && loaded">
		<div class="alert alert-info text-center">
			<strong>{{ 'LOG' | translate }}: {{ 'NO_DATOS' | translate }}</strong>
		</div>
	</div>

	<button class="btn btn-danger" onclick="history.back()">{{ 'BT_VOLVER' | translate }}</button>
</main>

<div class="modal fade" id="deleteLogs" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'BORRAR_ACCIONES' | translate }}</h4>
			</div>
			<div class="modal-body">
				<p class="modal-title">{{ 'PREGUNTA_ELIMINAR_ACCIONES' | translate }}</p>
			</div>
			<div class="modal-footer">
				<button class="btn btn-danger" [disabled]="loggedUser.isDemo" (click)="deleteLogs()" role="button" aria-disabled="false">{{ 'BT_ELIMINAR' | translate }}</button>
				<input class="btn btn-primary" title="{{ 'DESCARTAR_CAMBIOS' | translate }}" value="{{ 'BT_CANCELAR' | translate }}" data-dismiss="modal">
			</div>
		</div>
	</div>

</div>
