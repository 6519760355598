<header ps-header>
	<h2>{{ 'USUARIOS' | translate }}</h2>
</header>
<main class="container-fluid">
	<div *ngIf="users">
		<span *ngIf="totalUsers" class="badge badge-info">{{ totalUsers }}</span>
		<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
	</div>
	<p></p>

	<div *ngIf="loggedUser.id_role === ('' | roles).ADMIN">
		<button class="btn btn-primary" [routerLink]="['/users', 'new']">{{ 'NUEVO_USUARIO' | translate }}</button>
	</div>

	<table-sorter [disableBtn]="disableBtn" *ngIf="loggedUser.id_role === ('' | roles).ADMIN" [(page)]="page" [loading]="!users"
			[limit]="limit" (pageChange)="loadItems($event)" (limitChanged)="limit = $event; loadItems()" [total]="totalPages" (prev)="loadItems($event);" (search)="searchText = $event" (next)="loadItems($event)">
		<table cellspacing="1" class="table table-bordered table-responsive hidden-xs">
			<thead>
				<tr>
					<th ordenable order="nombre" (change)="changeOrder($event)">{{ 'NOMBRE_APELLIDOS' | translate }}</th>
					<th ordenable order="usr" (change)="changeOrder($event)">{{ 'EMAIL' | translate }}</th>
					<th ordenable order="idRole" (change)="changeOrder($event)">{{ 'TIPO' | translate }}</th>
					<th ordenable order="id" (change)="changeOrder($event)">{{ 'CODIGO' | translate }}</th>
					<th ordenable order="creado" (change)="changeOrder($event)">{{ 'FECHA' | translate }}</th>
					<th>{{ 'ACCIONES' | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of users; let i = index;">
					<td>{{ item.nombre }} {{ item.apellidos }}</td>
					<td>{{ item.usr }}</td>
					<td>{{ item.role?.nombre }}</td>
					<td>{{ item.id }}</td>
					<td>{{ item.creado | amDateFormat: 'DD/MM/YYYY hh:mm' }}</td>
					<td class="actions">
						<div *ngIf="loggedUser.id_user == item.id">{{ 'USUARIO_ACTUAL' | translate}}</div>
						<div *ngIf="loggedUser.id_user != item.id">
							<button [disabled]="blockButtons" *ngIf="item.id_role == 2 || item.id_role == 3 || item.id_role == 5" (click)="showAddDevice(i)" class="btn fa fa-plus" title="{{'ANADIR_DISPOSITIVO' | translate}}"></button>
							<button [disabled]="blockButtons" class="btn fa fa-pencil" title="{{'EDITAR_USUARIO' | translate}}" [routerLink]="['/users', 'edit', item.id]"></button>
							<button [disabled]="blockButtons" *ngIf="loggedUser.id_user != item.id" (click)="selectUserToDel(i)" class="btn btn-danger fa fa-trash" title="{{'BORRAR' | translate}}" data-toggle="modal" data-target="#removeUser"></button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="visible-xs">
			<div *ngFor="let item of users; let i = index" class="panel panel-default">
				<div class="panel-body">
					<div display-table>
						<div>
							<div>
								<strong>{{ item.nombre }} {{ item.apellidos }}</strong>
								<span *ngIf="loggedUser.id_user == item.id" class="badge badge-primary"><strong>{{ 'USUARIO_ACTUAL' | translate}}</strong></span>
							</div>
							<div text-ellipsis>{{ 'EMAIL' | translate }}: <strong>{{ item.usr }}</strong></div>
							<div>{{ 'FECHA' | translate }}: <strong>{{ item.creado | amDateFormat: 'DD/MM/YYYY hh:mm' }}</strong></div>
						</div>
						<hr>
						<div display-table class="row text-center">
							<div class="col-xs-6">
								<div>{{ 'CODIGO' | translate }}:</div>
								<div><strong>{{ item.id }}</strong></div>
							</div>
							<div class="col-xs-6">
								<div>{{ 'TIPO' | translate }}:</div>
								<div><strong>{{ item.role?.nombre | translate }}</strong></div>
							</div>
						</div>
					</div>
				</div>
				<div class="panel-footer text-center">
					<div *ngIf="loggedUser.id_user != item.id">
						<button [disabled]="blockButtons" *ngIf="item.id_role == 2 || item.id_role == 3 || item.id_role == 5" (click)="showAddDevice(i)" class="btn btn-link" [innerHtml]="'ANADIR_DISPOSITIVO' | translate"></button>
						<button [disabled]="blockButtons" class="btn btn-link" [routerLink]="['/users', 'edit', item.id]">{{ 'EDITAR' | translate }}</button>
						<button [disabled]="blockButtons" *ngIf="loggedUser.id_user != item.id" (click)="selectUserToDel(i)" class="btn btn-link" data-toggle="modal" data-target="#removeUser">{{ 'BORRAR' | translate }}</button>
					</div>
				</div>
			</div>
		</div>
	</table-sorter>

	<div bsModal #addDevice="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addDevice" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">

				<div class="modal-header">
					<button class="close" aria-hidden="true" (click)="addDeviceModal.hide()">&times;</button>
					<h4 class="modal-title">{{ 'DISPOSITIVO' | translate }}</h4>
				</div>

				<div class="modal-body">
					{{ 'INTRODUCE_NS' | translate }}
					<input [(ngModel)]="selectedDevice" class="form-control">
					<div class="mt-20" *ngIf="!showingNoAssociatedDevices">
						<button (click)="showNoAssociatedDevices()" class="btn btn-primary">{{ 'SHOW_NO_ASSOCIATED_DEVICES' | translate }}</button>
					</div>
					<div *ngIf="showingNoAssociatedDevices && loadingNoAssociatedDevices" class="text-center">
						<img src="/assets/images/wave.svg">
					</div>
					<div *ngIf="showingNoAssociatedDevices" class="mt-20">
						<select *ngIf="noAssociated" class="form-control" [(ngModel)]="selectedDevice">
							<option *ngFor="let device of noAssociated" [value]="device.ns">{{device.ns}} - {{device.codActivacion}}</option>
						</select>
					</div>
				</div>

				<div class="modal-footer">
					<div *ngIf="infoAssociatingDevice.saving" class="text-center center"><img width="20%" src="/assets/images/wave.svg"></div>
					<p class="text-center text-danger" *ngIf="infoAssociatingDevice.error && !infoAssociatingDevice.saving">
						{{ 'ERROR_ASOCIAR_DISPOSITIVO' | translate }}
					</p>
					<button (click)="selectedDevice = ''; addDeviceModal.hide()" class="btn" [disabled]="infoAssociatingDevice.saving" title="{{'DESCARTAR_CAMBIOS' | translate}}">{{'BT_CANCELAR' | translate}}</button>
					<button (click)="associateDevice()" class="btn btn-primary" [disabled]="infoAssociatingDevice.saving" role="button" aria-disabled="false">{{'BT_ANADIR' | translate}}</button>
				</div>
			</div>
		</div>
	</div>

	<div bsModal #removeUser="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="removeUser" aria-hidden="true">
		<div *ngIf="selectedUser" class="modal-dialog">
			<div class="modal-content">

				<div class="modal-header">
					<button (click)="removeUser.hide()" class="close" aria-hidden="true">&times;</button>
					<h4 class="modal-title">{{ 'BT_ELIMINAR' | translate }}</h4>
				</div>

				<div class="modal-body">
					<p>{{ 'PREGUNTA_ELIMINAR_USER_1' | translate }}<strong>{{ selectedUser.nombre + ' ' + selectedUser.apellidos + ' ' }}</strong>{{ 'PREGUNTA_ELIMINAR_USER_2' | translate }}</p>
				</div>

				<div class="modal-footer">
					<button (click)="removeUser.hide()" class="btn" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
					<button class="btn btn-danger" (click)="deleteUser(selectedUser.id); removeUser.hide()" role="button" aria-disabled="false">{{ 'BORRAR' | translate }}</button>
				</div>

			</div>
		</div>
	</div>

	<div bsModal #removeClient="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="removeClient" aria-hidden="true">
		<div *ngIf="selectedUser" class="modal-dialog">
			<div class="modal-content">

				<div class="modal-header">
					<button (click)="removeClient.hide()" class="close" aria-hidden="true">&times;</button>
					<h4 class="modal-title">{{ 'BT_ELIMINAR' | translate }}</h4>
				</div>

				<div class="modal-body">
					<p>{{ 'PREGUNTA_ELIMINAR_USUARIO_MANTENIDO1' | translate }} <strong>{{ selectedUser.nombre + ' ' + selectedUser.apellidos + ' ' }}</strong>{{ 'PREGUNTA_ELIMINAR_USUARIO_MANTENIDO2' | translate }}</p>
				</div>

				<div class="modal-footer">
					<button (click)="removeClient.hide()" class="btn" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
					<button class="btn btn-danger" (click)="deleteClient(selectedUser.id)" role="button" aria-disabled="false">{{ 'BORRAR' | translate }}</button>
				</div>

			</div>
		</div>
	</div>
</main>
