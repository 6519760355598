<header ps-header>
	<h2>{{ "PROFESIONALES" | translate }}</h2>
</header>
<main class="container-fluid">
	<div *ngIf="totalItems">
		<span class="badge badge-info">{{ totalItems }}</span>
		<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
	</div>
	<p class="text-right">
		<button [disabled]="disableBtn" class="btn btn-primary" [routerLink]="['/professionals', 'professional']">{{ 'NUEVO' | translate }}</button>
	</p>
	<table-sorter [disableBtn]="disableBtn" [(page)]="page" [loading]="!items" [limit]="pagination.limit" [total]="pagination.totalPages" (limitChanged)="pagination.limit = $event; page = 1; loadItems()" (prev)="loadItems($event);" (search)="searchText = $event" (next)="loadItems($event)" [emptyText]="'NO_USUARIOS_CRITERIO' | translate">

		<table cellspacing="1" class="table table-bordered table-responsive hidden-xs">
			<thead>
				<tr>
					<th ordenable order="user/nombre" (change)="changeOrder($event)">{{ 'PROFESIONALES' | translate }}</th>
					<th>{{ 'DISPOSITIVO' | translate }}</th>
					<th ordenable order="user/usr" (change)="changeOrder($event)">{{ 'DATOS_PERSONALES' | translate }}</th>
					<th ordenable order="fecha" (change)="changeOrder($event)">{{ 'FECHA' | translate }}</th>
					<th class="actions">{{ 'ACCIONES' | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of items; let i = index;">
					<td>
						<div>{{ item.user.nombre }} {{ item.user.apellidos }}</div>
						<div>{{ item.solicitud | translate }}</div>
					</td>
					<td>
						<div><span class="text-primary">{{ item.device.alias }}</span> - {{ item.device.id_loc }}</div>
						<div>NS: {{ item.device.ns }}</div>
					</td>
					<td>
						<div>{{ item.user.usr }}</div>
						<div><strong>{{ 'TELEFONO' | translate }}:</strong> {{ item.user.telefono }}</div>
					</td>
					<td>
						{{ item.fecha | amDateFormat: 'DD/MM/YYYY hh:mm' }}
					</td>
					<td class="actions">
						<button [disabled]="disableBtn" class="btn" title="{{'DESASOCIAR_MANTENEDOR' | translate}}" (click)="selectedRequest = item; disassociateMdl.show()">
							<i class="fa fa-chain-broken"></i>
						</button>
						<button [disabled]="disableBtn" class="btn" title="{{'EDITAR_PERMISOS' | translate}}" [routerLink]="['/professionals', 'edit', item.id]">
							<i class="fa fa-pencil"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="visible-xs">
			<div *ngFor="let item of items; let i = index;" class="panel panel-default">
				<div class="panel-body">
					<div>
						<strong>{{ item.user.nombre }} {{ item.user.apellidos }}</strong>
					</div>
					<div><a href="mailto:{{ item.user.usr }}">{{ item.user.usr }}</a></div>
					<div>
						<i class="fa fa-phone fa-fw" aria-hidden="true"></i>
						<a href="tel:{{ item.user.telefono }}">
							  {{ item.user.telefono }}
						  </a>
					</div>
					<hr>
					<div class="text-primary">{{ item.device.alias }}</div>
					<div><strong>{{ item.device.id_loc }}</strong></div>
					<div class="text-muted">{{ item.device.ns }}</div>
					<hr>
					<div>
						<div>
							<span class="text-muted">{{ 'SOLICITUD' | translate }}</span>
						</div>
						<div class="row">
							<div class="col-xs-6">
								{{ item.fecha | amDateFormat: 'DD/MM/YYYY hh:mm' }}
							</div>
							<div class="col-xs-6" [ngClass]="(item.solicitud === 'ACEPTADA') ? 'text-success' : 'text-danger'">
								{{ item.solicitud | translate }}
							</div>
						</div>
					</div>
				</div>
				<div class="panel-footer text-center">
					<button [disabled]="disableBtn" class="btn btn-link" (click)="selectedRequest = item; disassociateMdl.show()">
						<i class="fa fa-chain-broken fa-fw"></i> {{ 'DESASOCIAR' | translate }}
					</button>
					<button [disabled]="disableBtn" class="btn btn-link" [routerLink]="['/professionals','edit', item.id]">
						<i class="fa fa-pencil fa-fw"></i> {{ 'PERMISOS' | translate }}
					</button>
				</div>
			</div>
		</div>

	</table-sorter>

	<div bsModal #disassociateMdl="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="disassociateMdl" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button class="close" data-dismiss="modal" aria-hidden="true" (click)="disassociateMdl.hide()">&times;</button>
				</div>
				<div class="modal-body">
					<p>{{'PREGUNTA_ELIMINAR_MANTENEDOR_1' | translate}} <strong>{{ selectedRequest?.user?.nombre }} {{ selectedRequest?.user?.nombre + ' ' }}</strong> {{'PREGUNTA_ELIMINAR_MANTENEDOR_2' | translate}}</p>
				</div>
				<div class="modal-footer">
					<button class="btn" title="{{'DESCARTAR_CAMBIOS' | translate}}" data-dismiss="modal" (click)="disassociateMdl.hide()">{{'BT_CANCELAR' | translate}}</button>
					<button class="btn btn-danger" role="button" aria-disabled="false" (click)="delete(selectedRequest); disassociateMdl.hide();">{{'BORRAR' | translate}}</button>
				</div>
			</div>
		</div>
	</div>

</main>
