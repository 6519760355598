import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
	@Input() width: number = 64;
	@Input() height: number = 64;
	@Input() radius: number = 46;
	@Input() strokeWidthCapped: number = 8;
	@Input() stroke: string = '#000000';
	@Input() circumference: string = '192 64';
	@Input() rotate: boolean = true;
	@Input() clockwise: boolean = true;

	constructor() { }

	ngOnInit() {
	}

}
