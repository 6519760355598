import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';
import { BaseService } from './base.service';


// services
import { AuthService } from './auth.service';
import { AppConfig } from '../AppConfig';

@Injectable()
export class MessagesService extends BaseService {
	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public inbox(limit: number = 20, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/messages/inbox/' + limit + '/' + offset, 'conditions=' + JSON.stringify(conditions) , { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public outbox(limit: number = 20, page: number = 1, conditions: any = null) {
		let offset = (page - 1) * limit;

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/messages/outbox/' + limit + '/' + offset, 'conditions=' + JSON.stringify(conditions) , { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public add(message) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/messages/save', 'data=' + JSON.stringify(message) , { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public get(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/messages/view/' + id, null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error/*.json()*/);
			});
		});
	}

	public delete(data: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/messages/delete', 'data=' + JSON.stringify(data), { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error/*.json()*/);
			});
		});
	}

	public pendingMessages() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/messages/pendingmessages', null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error/*.json()*/);
			});
		});
	}
}
