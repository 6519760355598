<header ps-header>
	<h2>{{ 'PC_TXT2' | translate }}</h2>
</header>
<main class="container-fluid">
	<div class="container">
    <h3>{{ 'QUE_ES_COOKIE' | translate }}</h3>
    <p [innerHTML]="'COOKIES_TEXTO_1' | translate"></p>
    <h3>{{ 'COOKIES_NECESARIAS' | translate }}</h3>
    <p [innerHTML]="'COOKIES_TEXTO_2' | translate"></p>
    <div [innerHTML]="'COOKIES_TABLA_1' | translate"></div>
    <h3>{{ 'MAS_INFO_COOKIES' | translate }}</h3>
    <p [innerHTML]="'COOKIES_TEXTO_3' | translate"></p>
	</div>
</main>
