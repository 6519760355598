import { Routes } from '@angular/router';
import { ProfessionalsComponent } from './pages/professionals/professionals.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { DeviceComponent } from './pages/devices/device.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { NoContentComponent } from './no-content';
import { LoginComponent } from './pages/login/login.component';
import { RecoverComponent } from './pages/login/recover.component';
import { RegisterComponent } from './pages/login/register.component';
import { FamilyComponent } from './pages/devices/family.component';
import { InputsComponent } from './pages/devices/inputs.component';
import { RelaysComponent } from './pages/devices/relays.component';
import { StatsComponent } from './pages/stats/stats.component';
import { ProgrammerComponent } from './pages/devices/programmer.component';
import { AlarmsComponent } from './pages/devices/alarms.component';
import { UsersComponent } from './pages/users/users.component';
import { UserComponent } from './pages/users/user.component';
import { RequestComponent } from './pages/users/request.component';
import { LogsComponent } from './pages/action-logs/logs.component';
import { StatComponent } from './pages/devices/stat.component';
import { AdvancedComponent } from './pages/devices/advanced.component';
import { DocsComponent } from './pages/docs/docs.component';
import { SunhoursComponent } from './pages/devices/sunhours.component';
import { VersionComponent } from './pages/version/version.component';
import { ProgrammerlumiComponent } from './pages/devices/programmerlumi.component';
import { PrivacyComponent } from './pages/docs/privacy.component';
import { CookiesComponent } from './pages/docs/cookies.component';

// services
import { AuthGuard } from './guards/auth.guard';
import { TwoFactorsComponent } from './pages/users/twofactors.component';
import { VerificationComponent } from './pages/login/verification/verification.component';
import { DataProtectionComponent } from './pages/docs/data-protection.component';
import { SessionsComponent } from './pages/users/sessions/sessions.component';

export const ROUTES: Routes = [
	{ path: '', component: LoginComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'docs/:doc', component: DocsComponent},
	{ path: 'privacy', component: PrivacyComponent },
	{ path: 'cookies', component: CookiesComponent },
	{ path: 'data-protection', component: DataProtectionComponent },
    { path: '2fa', component: TwoFactorsComponent},
	{ path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
	{ path: 'users/:action/:id', component: UserComponent, canActivate: [AuthGuard] },
	{ path: 'users/:action', component: UserComponent, canActivate: [AuthGuard] },
	{ path: 'me', component: UserComponent, canActivate: [AuthGuard] },
	{ path: 'me/sessions', component: SessionsComponent, canActivate: [AuthGuard]},
	{ path: 'advanced', component: AdvancedComponent, canActivate: [AuthGuard] },
	{ path: 'recover', component: RecoverComponent },
	{ path: 'register', component: RegisterComponent },
	{ path: 'devices/alarms/:id', component: AlarmsComponent, canActivate: [AuthGuard] },
	{ path: 'devices/logs/:id', component: LogsComponent, canActivate: [AuthGuard] },
	{ path: 'devices/stat/:id/:parameter', component: StatComponent, canActivate: [AuthGuard] },
	{ path: 'devices/programs/:type/:id/:signal', component: ProgrammerComponent, canActivate: [AuthGuard] },
	{ path: 'devices/programmer/:type/:id/:idRelay', component: ProgrammerComponent, canActivate: [AuthGuard] },
	{ path: 'devices/lumiplus/:type/:id', component: ProgrammerlumiComponent, canActivate: [AuthGuard] },
	{ path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
	{ path: 'devices/family/:id', component: FamilyComponent, canActivate: [AuthGuard] },
	{ path: 'devices/:action', component: DeviceComponent, canActivate: [AuthGuard] },
	{ path: 'devices/:action/:id', component: DeviceComponent, canActivate: [AuthGuard] },
	{ path: 'devices/inputs/edit', component: InputsComponent, canActivate: [AuthGuard] },
	{ path: 'devices/inputs/edit/:id', component: InputsComponent, canActivate: [AuthGuard] },
	{ path: 'devices/relays/edit/:id', component: RelaysComponent, canActivate: [AuthGuard] },
	{ path: 'devices/sun/sunrisesunset/:id', component: SunhoursComponent, canActivate: [AuthGuard] },
	{ path: 'clients', component: ClientsComponent, canActivate: [AuthGuard] },
	{ path: 'professionals', component: ProfessionalsComponent, canActivate: [AuthGuard] },
	{ path: 'professionals/:action', component: RequestComponent, canActivate: [AuthGuard] },
	{ path: 'professionals/edit/:id', component: RequestComponent, canActivate: [AuthGuard] },
	{ path: 'messages', component: MessagesComponent, canActivate: [AuthGuard] },
	{ path: 'messages/:action', component: MessagesComponent, canActivate: [AuthGuard] },
	{ path: 'messages/:action/:id', component: MessagesComponent, canActivate: [AuthGuard] },
	{ path: 'stats', component: StatsComponent, canActivate: [AuthGuard] },
	{ path: 'stats/:id', component: StatsComponent, canActivate: [AuthGuard] },
	{ path: 'version', component: VersionComponent, canActivate: [AuthGuard] },
	{ path: 'version/:option', component: VersionComponent, canActivate: [AuthGuard] },
	{ path: 'login/verification', component: VerificationComponent , },
	{ path: '**', component: NoContentComponent, canActivate: [AuthGuard] },
];
