import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { UsersService } from '../../services/users.service';
import { Router } from '@angular/router';

@Component({
	selector: 'recover',
	templateUrl: 'recover.component.html'
})

export class RecoverComponent implements OnInit {
	public email: string = '';
	public blockButtons: boolean = false;
	public options = {
		position: ['bottom', 'right'],
		timeOut: 5000,
		lastOnBottom: true,
		showProgressBar: false,
		pauseOnHover: false,
		clickToClose: true
	};

	constructor(
		private _translate: TranslateService,
		private _notificationService: NotificationsService,
		private _usersSvc: UsersService,
		private _router: Router) { }

	ngOnInit() { }

	public send() {
		this.blockButtons = true;
		// tslint:disable-next-line:max-line-length
		let validate: boolean = this._validateField(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);

		if (validate) {
			this._usersSvc.passRecover(this.email).then((result: any) => {
				this._notificationService.success('', this._translate.instant(result.message));
				this._router.navigate(['/login']);
			}).catch((error: any) => {
				if (error.message) {
					this._notificationService.error('', this._translate.instant(error.message));
				}
				this.blockButtons = false;
			});
		} else {
			this._notificationService.error('', this._translate.instant('VALIDA_EMAIL'));
			this.blockButtons = false;
		}
	}

	private _validateField(pattern): boolean {
		let result: boolean = true;
		let translation: any = { email: 'VALIDA_EMAIL', password: 'VALIDA_PASSWORD', phone: 'TELEFONO', name: 'VALIDA_NOMBRE', surname: 'VALIDA_APELLIDOS' };

		if (!pattern.test(this.email)) {
			result = false;
		}

		return result;
	}
}
