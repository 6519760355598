<header ps-header>
	<h2>PoolStation - {{ 'RECUPERAR_CONTRASENYA' | translate }}</h2>
</header>

<main>
	<div class="row">
		<div class="col-sm-4 col-sm-offset-4">
			<div class="panel">
				<div class="panel-body">
					<p>{{ 'ES_NECESARIO_CORREO' | translate }}</p><br />
					<div>
						<label for="username" class="col-sm-3">{{ 'EMAIL' | translate }}</label>
						<div class="col-sm-9">
							<input type="text" name="username" class="form-control" [(ngModel)]="email" />
						</div>
					</div>
				</div>
				<div class="panel-footer text-center">
					<button [disabled]="blockButtons" class="btn btn-danger" onclick="window.history.back()">{{ 'BT_VOLVER' | translate }}</button>
					<button [disabled]="blockButtons" class="btn btn-primary" (click)="send()">
						<i *ngIf="blockButtons" class="fa fa-refresh fa-spin" aria-hidden="true"></i>
						{{ 'BT_RECUPERAR' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</main>
