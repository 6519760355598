import { Injectable } from '@angular/core';
/* import { Http, Headers } from '@angular/http'; */
import { HttpClient, HttpHeaders} from '@angular/common/http';

// services
import { AuthService } from './auth.service';

// utils
import * as _ from 'lodash';

@Injectable()
export class BaseService {
	protected page: number = 1;
	protected limit: number = 20;
	protected _headers = new  HttpHeaders({
		'Content-type': 'application/x-www-form-urlencoded'
	});

	get headers(): HttpHeaders {
		return this._headers;
	}

	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
	}
}
