import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: '[ordenable]',
	templateUrl: './ordenable.component.html'
})
export class OrdenableComponent {
	private _order: any;
	@Output() change: EventEmitter<number> = new EventEmitter<number>();
	@Output() orderChange: EventEmitter<number> = new EventEmitter<number>();
	@Input() set order(value: any) {
		let initial = this._order;

		value = value.split(' ');
		value[1] = (value[1]) ? value[1].toUpperCase() : null;
		this._order = {
			column: value[0],
			direction: (value[1] === 'ASC' || value[1] === 'DESC') ? value[1] : null,
		};

		if (initial) {
			this.change.emit(this._order);
			this.orderChange.emit(this._order);
		}
	}

	get order() {
		return this._order;
	}

	get direction(): string {
		return this._order.direction;
	}

	set direction(value: string) {
		this._order.direction = value;
	}

	constructor() { }

	changeDirection() {
		if (this._order.direction) {
			if (this._order.direction === 'ASC') {
				this.order.direction = 'DESC';
			} else {
				this.order.direction = null;
			}
		} else {
			this._order.direction = 'ASC';
		}

		this.change.emit(this._order);
	}
}
