<header ps-header>
	<h2>{{ "LIST_SMS" | translate }}</h2>
</header>
<main class="container-fluid p-b-0">
	<div class="row">
		<div class="col-sm-1 col-md-1 col-lg-1" no-padding>
			<ul id="messagesMenu" class="row text-center" no-margin>
				<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" class="col-sm-12" [ngClass]="(loggedUser.id_role === (''
				 | roles).PROFESIONAL) ? 'col-xs-3' : 'col-xs-4'" [routerLink]="['/messages', 'compose']">
					<img src="/assets/images/mensajes/mensaje-nuevo@2x.png">
					<div>{{ 'NUEVO' | translate }}</div>
				</li>
				<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" class="col-sm-12 withBadge"
						[ngClass]="(loggedUser.id_role === ('' | roles).PROFESIONAL) ? 'col-xs-3' : 'col-xs-4'" [routerLink]="['/messages', 'inbox']">
					<img src="/assets/images/mensajes/mensajes-recibidos@2x.png">
					<span *ngIf="totalPendingMessages !== 0" class="badge badge-danger">{{ totalPendingMessages }}</span>
					<div>{{ 'MENSAJES_RECIBIDOS' | translate }}</div>
				</li>
				<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" class="col-sm-12" [ngClass]="(loggedUser.id_role === (''
				 | roles).PROFESIONAL) ? 'col-xs-3' : 'col-xs-4'" [routerLink]="['/messages', 'outbox']">
					<img src="/assets/images/mensajes/mensajes-enviados@2x.png">
					<div>{{ 'MENSAJES_ENVIADOS' | translate }}</div>
				</li>
				<li *ngIf="loggedUser.id_role === ('' | roles).PROFESIONAL" (click)="selectedRequest.selected = false" routerLinkActive="active"
						[routerLinkActiveOptions]="{exact: false}" class="col-sm-12 col-xs-3 withBadge" [routerLink]="['/messages', 'requests']">
					<img src="/assets/images/mensajes/mensajes-solicitudes@2x.png">
					<span *ngIf="totalPending !== 0" class="badge badge-danger">{{ totalPending }}</span>
					<div>{{ 'SOLICITUDES' | translate }}</div>
				</li>
			</ul>
		</div>
		<div class="col-sm-11 m-t-10">
			<div [ngSwitch]="action" class="form-horizontal">
				<ng-container *ngSwitchCase="'view'">
					<div *ngIf="message">
						<h3>
							{{ message?.asunto | translate}}
							<button [disabled]="blockButtons || loggedUser.isDemo" class="btn btn-danger btn-link pull-right" (click)="deleteMessage(message.id)">
								<i class="fa fa-trash fa-2x" aria-hidden="true"></i>
							</button>
						</h3>
						<div class="form-group">
							<label for="email" class="col-sm-2 control-label">{{ (message.emitter.id == currentUser.id_user ? 'MENSAJE_PARA' : 'ENVIADO_POR') | translate }}</label>
							<div class="col-sm-10">
								<p *ngIf="message.emitter.id == currentUser.id_user">{{ message.recipient.nombre }} {{ message.recipient.apellidos }}</p>
								<p *ngIf="message.emitter.id != currentUser.id_user">{{ message.emitter.nombre }} {{ message.emitter.apellidos }}</p>
							</div>
						</div>
						<div class="form-group">
							<label for="asunto" class="col-sm-2 control-label">{{'MENSAJE' | translate}}</label>
							<div class="col-sm-10">
								<div [innerHtml]="message.mensaje"></div>
							</div>
						</div>
						<p>
							<button [disabled]="blockButtons" class="btn btn-danger" onclick="window.history.back()">{{ 'BT_VOLVER' |  translate }}</button>
							<button [disabled]="blockButtons || loggedUser.isDemo" *ngIf="loggedUser.id_user === message.id_receptor" class="btn btn-primary" [routerLink]="['/messages/write', message.id_emisor]">{{ 'RESPONDER' |  translate }}</button>
						</p>
					</div>
					<div *ngIf="!message">
						<div *ngIf="loading" class="text-center">
							<img src="/assets/images/wave.svg">
						</div>
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="'inbox'">
					<p></p>
					<table-sorter [disableBtn]="blockButtons" [(page)]="page" [loading]="!inbox" [limit]="limit" (limitChanged)="limit = $event; page = 1; loadInbox()" [total]="totalPages" (search)="searchText = $event" (prev)="loadInbox($event)" (next)="loadInbox($event)">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>{{'ENVIADO_POR' | translate}}</th>
									<th>{{'ASUNTO' | translate}}</th>
									<th>{{'FECHA' | translate}}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of inbox" class="clickable" [routerLink]="['/messages', 'view', item.id]">
									<td [ngClass]="{ 'text-primary' : !item.leido }">{{ item.emitter.nombre + ' ' + item.emitter.apellidos }}</td>
									<td [ngClass]="{ 'text-primary' : !item.leido }">{{ item.asunto }}</td>
									<td class="actions" [ngClass]="{ 'text-primary' : !item.leido }">{{ item.fecha | amDateFormat: 'DD/MM/YYYY HH:mm:ss' }}</td>
								</tr>
							</tbody>
						</table>
					</table-sorter>
					<p class="text-a">
						<button class="btn btn-danger" [routerLink]="['/']">{{ 'BT_VOLVER' |  translate }}</button>
					</p>
				</ng-container>

				<ng-container *ngSwitchCase="'outbox'">
					<p></p>
					<table-sorter [disableBtn]="blockButtons" [(page)]="page" [loading]="!outbox" [limit]="limit" (limitChanged)="limit = $event; page = 1; loadOutbox()" [total]="totalPages" (search)="searchText = $event" (prev)="loadOutbox($event);" (next)="loadOutbox($event)">
						<table class="table table-bordered table-striped">
							<thead>
								<tr>
									<th>{{'MENSAJE_PARA' | translate}}</th>
									<th>{{'ASUNTO' | translate}}</th>
									<th>{{'FECHA' | translate}}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of outbox" class="clickable" [routerLink]="['/messages', 'view', item.id]">
									<td>{{ item.recipient.nombre + ' ' + item.recipient.apellidos }}</td>
									<td>{{ item.asunto }}</td>
									<td class="actions">{{ item.fecha | amDateFormat: 'DD/MM/YYYY HH:mm:ss' }}</td>
								</tr>
							</tbody>
						</table>
					</table-sorter>
					<p class="text-a">
						<button class="btn btn-danger" [routerLink]="['/']">{{ 'BT_VOLVER' |  translate }}</button>
					</p>
				</ng-container>

				<ng-container *ngSwitchCase="'requests'">
					<div *ngIf="loading" class="text-center">
						<img src="/assets/images/wave.svg">
					</div>
					<ng-container *ngIf="!loading && loggedUser.id_role === ('' | roles).PROFESIONAL">

						<div *ngIf="totalPending !== 0 && !selectedRequest.selected">
							<h2>{{ 'NUEVAS_SOLICITUDES' | translate }}</h2>
							<table cellspacing="1" class="table table-bordered">
								<thead>
									<tr>
										<th style="width:5%">{{ 'FECHA' | translate }}</th>
										<th style="width:23%">{{ 'ALIAS' | translate }}</th>
										<th style="width:20%">{{ 'USUARIO' | translate }}</th>
										<th style="width:23%">{{ 'LOCALIZACION' | translate }}</th>
										<th style="width:5%">{{ 'TELEFONO' | translate }}</th>
										<th style="width:9%">{{ 'VER_SOLICITUD' | translate }}</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let request of pendingRequests; let i = index">
										<td>{{ request.fecha | date: 'dd/MM/y' }}</td>
										<td>{{ request.alias }}</td>
										<td>{{ request.device.user.nombre + ' ' + request.device.user.apellidos }}</td>
										<td>{{ request.device.id_loc }}</td>
										<td>{{ request.device.user.telefono }}</td>
										<td class="text-center">
											<button class="btn btn-primary" (click)="selectRequest(i)"><i class="fa fa-2 fa-eye" aria-hidden="true"></i></button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div *ngIf="selectedRequest.selected" class="row">
							<div class="col-sm-12">
								<h4>{{ 'USUARIO' | translate }}</h4>
								<hr>
							</div>
							<div class="col-sm-12">
								<table class="table table-bordered">
									<tr>
										<td style="width:25%" class="text-right"><strong>{{ 'NOMBRE' | translate }}:</strong></td>
										<td>{{ device.user.nombre + ' ' + device.user.apellidos }}</td>
									</tr>
									<tr>
										<td style="width:25%" class="text-right"><strong>{{ 'DIRECCION' | translate }}:</strong></td>
										<td>{{ device.user.direccion + ', ' + device.user.id_loc }}</td>
									</tr>
									<tr>
										<td style="width:25%" class="text-right"><strong>{{ 'TELEFONO' | translate }}:</strong></td>
										<td>{{ device.user.telefono }}</td>
									</tr>
									<tr>
										<td style="width:25%" class="text-right"><strong>{{ 'EMAIL' | translate }}:</strong></td>
										<td>{{ device.user.usr }}</td>
									</tr>
								</table>
							</div>
							<div class="col-sm-12">
								<h4>{{ 'POOLSTATION' | translate }}</h4>
								<hr>
							</div>
							<div class="col-sm-12">
								<table class="table table-bordered">
									<tr>
										<td style="width:25%" class="text-right"><strong>{{ 'ALIAS' | translate }}:</strong></td>
										<td>{{ device.alias }}</td>
									</tr>
									<tr>
										<td style="width:25%" class="text-right"><strong>{{ 'LOCALIZACION' | translate }}:</strong></td>
										<td>{{ device.id_loc }}</td>
									</tr>
									<tr>
										<td style="width:25%" class="text-right"><strong>{{ 'MODELO_SISTEMA' | translate }}:</strong></td>
										<td><span *ngFor="let model of device.model">{{ model }} </span></td>
									</tr>
								</table>
							</div>
							<div class="col-sm-12">
								<h4>{{ 'ACEPTAR_SOLICITUD' | translate }}</h4>
								<hr>
							</div>
							<div class="col-sm-12">
								<div>{{ 'ESCRIBA_ALIAS_MANTENEDOR' | translate }}</div>
								<div class="form-group">
									<label class="col-sm-3 col-md-2 col-lg-1 control-label">{{ 'ALIAS' | translate }}</label>
									<div class="col-sm-9 col-md-6 col-lg-3">
										<input type="text" class="form-control" [(ngModel)]="selectedRequest.newAlias">
									</div>
								</div>
								<div class="btnContainer">
									<button [disabled]="blockButtons" class="btn btn-danger" (click)="rejectRequest()">
										<i class="fa" [ngClass]="{'fa-refresh fa-spin' : blockButtons, 'fa-save' : !blockButtons}"></i> {{ 'RECHAZAR_SOLICITUD' | translate }}
									</button>
									<button [disabled]="blockButtons" class="btn btn-primary" (click)="acceptRequest()">
										<i class="fa" [ngClass]="{'fa-refresh fa-spin' : blockButtons, 'fa-save' : !blockButtons}"></i> {{ 'ACEPTAR_SOLICITUD' | translate }}
									</button>
								</div>
							</div>
						</div>

						<div *ngIf="totalPending === 0" class="row">
							<div class="col-sm-12 text-center">
								<h4>{{ 'NO_SOLICITUDES_PENDIENTES' | translate }}</h4>
							</div>
						</div>

					</ng-container>
					<ng-container *ngIf="loggedUser.id_role !== ('' | roles).PROFESIONAL">
						{{ 'ACCESO_PRIVADO' | translate }}
					</ng-container>
				</ng-container>

				<ng-container *ngSwitchDefault>
					<div *ngIf="!recipients" class="text-center">
						<img src="/assets/images/wave.svg">
					</div>
					<ng-container *ngIf="recipients">
						<h3>{{ 'NUEVO_MENSAJE' | translate }}</h3>
						<div class="form-group">
							<label for="email" class="col-sm-12 control-label">{{ 'MENSAJE_PARA' | translate }}</label>
							<div class="col-sm-12">
								<input type="hidden" [(ngModel)]="message.id_emisor">
								<select *ngIf="action === 'write'" [(ngModel)]="message.id_receptor" class="form-control">
									<option *ngFor="let selectedUser of recipients" [value]="selectedUser.id">
										{{ selectedUser.nombre + ' ' + selectedUser.apellidos }} - {{selectedUser.usr}}
									</option>
								</select>
								<select *ngIf="action === 'compose'" [(ngModel)]="message.id_receptor" class="form-control">
									<option *ngFor="let selectedUser of recipients" [value]="selectedUser.id">
										{{ selectedUser.nombre + ' ' + selectedUser.apellidos }} - {{selectedUser.usr}}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group">
							<label for="asunto" class="col-sm-12 control-label">{{ 'ASUNTO' | translate }}</label>
							<div class="col-sm-12">
								<input type="text" class="form-control" id="asunto" [(ngModel)]="message.asunto">
							</div>
						</div>
						<div class="form-group">
							<label for="asunto" class="col-sm-12 control-label">{{ 'MENSAJE' | translate }}</label>
							<div class="col-sm-12">
								<div>
									<simple-tiny [elementId]="'editor'" (onEditorKeyup)="message.mensaje = $event"></simple-tiny>
								</div>
							</div>
						</div>
						<p class="text-a">
							<button class="btn btn-primary" [disabled]="sending || !message.id_receptor || !message.asunto || !message.mensaje || loggedUser.isDemo" (click)="send()">
								<i *ngIf="sending" class="fa fa-spinner fa-spin fa-fw"></i>{{ 'SEND' |  translate }}
							</button>
							<button class="btn btn-danger" [routerLink]="['/messages']">{{ 'BT_VOLVER' |  translate }}</button>
						</p>
					</ng-container>
				</ng-container>

			</div>
		</div>
	</div>
</main>
