import { Component, OnInit, Inject, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { TabsetComponent } from 'ngx-bootstrap/tabs';

// services
import { DevicesService } from '../../services/devices.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { PageScrollConfig, PageScrollService, PageScrollInstance } from 'ng2-page-scroll';
import { AuthService } from '../../services/auth.service';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'ds2',
	templateUrl: 'ds2.component.html'
})
export class Ds2Component implements OnInit, OnChanges {
	// tslint:disable-next-line:no-input-rename
	@Input('dev') device: any;
	@Input('deviceOffHours') deviceOffHours: any;
	@Output('wait') wait = new EventEmitter();
	@ViewChild('dynamicTabs', {static: true}) dynamicTabs: TabsetComponent;

	public id: number;
	public now: any = new Date();
	public deviceTmp: any = {
		id: null,
		vars: null
	};
	public loading: boolean = true;
	public editSwt: boolean = false;
	public changed = {
		edited: false
	};
	public updated: string = null;
	public checkSave: boolean = false;
	public savedError = false;
	public errorsCont = 0;
	public loggedUser: any;
	private _setterElectrolysis: any;
	private _setterPh: any;
	private _setterMv: any;
	private _setterPpm: any;
	public tabs: any = {
		general: true,
		electrolysis: false,
		uv: false,
		ph: false,
		mv: false,
		ppm: false,
		sensors: false
	};
	private _taRanges: any = {
		unit: '',
		value: 0,
		C: {
			min: 15,
			max: 38
		},
		F: {
			min: 59,
			max: 100
		}
	};

	get ee() {
		return this.deviceTmp.vars.ee;
	}

	set ee(value: boolean) {
		this.change('ee', value);
	}

	get ei() {
		return this.deviceTmp.vars.ei;
	}

	set ei(value: boolean) {
		this.change('ei', value);
	}

	get fe() {
		return this.deviceTmp.vars.fe;
	}

	set fe(value: boolean) {
		this.change('fe', value);
	}

	get fs() {
		return this.deviceTmp.vars.fs;
	}

	set fs(value: boolean) {
		this.change('fs', value);
	}

	get hu(): number {
		return parseInt(this.device.vars.hu, 10) || 0;
	}

	get uvPercent() {
		return Math.round((this.hu * 100) / 13000);
	}

	get pa(): number {
		return parseInt(this.device.vars.pa, 10) || 0;
	}

	get sc(): number {
		return parseInt(this.device.vars.sc, 10) || 0;
	}

	get mp(): number {
		return parseFloat(this.device.vars.mp);
	}

	get sp(): number {
		return parseFloat(this.device.vars.sp);
	}

	set sp(value: number) {
		let data: any = {
			id: this.device.id,
			sign: 'sp',
			value: value.toFixed(2)
		};

		this.wait.emit(true);
		clearTimeout(this._setterPh);

		this._setterPh = setTimeout(() => {
			this._devicesSvc.saveSign(data).then((result: any) => {
				this.wait.emit(false);
			}).catch((error: any) => {
				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}
				this.loading = false;
			});
		}, 750);

		this.device.vars.sp = value;
	}

	get cn(): number {
		return parseFloat(this.device.vars.cn.substring(0, 4));
	}

	get sn(): number {
		return parseInt(this.device.vars.sn, 10) || 0;
	}

	set sn(value: number) {
		let data: any;
		let valueString = value.toString();

		this.wait.emit(true);

		if (valueString.length < 3) {
			for (let i = valueString.length; i < 3; i++) {
				valueString = '0' + valueString;
			}
		}

		data = {
			id: this.device.id,
			sign: 'sn',
			value: valueString
		};

		clearTimeout(this._setterElectrolysis);

		this._setterElectrolysis = setTimeout(() => {
			this._devicesSvc.saveSign(data).then((result: any) => {
				this.wait.emit(false);
			}).catch((error: any) => {
				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}

				this.loading = false;
				this.wait.emit(false);
			});
		}, 750);

		this.device.vars.sn = value;
	}

	get mo(): number {
		return parseInt(this.device.vars.mo, 10) || 0;
	}

	get so(): number {
		return parseInt(this.device.vars.so, 10) || 0;
	}

	set so(value: number) {
		let data: any = {
			id: this.device.id,
			sign: 'so',
			value: value
		};

		this.wait.emit(true);

		clearTimeout(this._setterMv);

		this._setterMv = setTimeout(() => {
			this._devicesSvc.saveSign(data).then((result: any) => {
				this.wait.emit(false);
			}).catch((error: any) => {
				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}
				this.loading = false;
			});
		}, 750);

		this.device.vars.so = value;
	}

	get mh(): number {
		return parseFloat(this.device.vars.mh) || 0.0;
	}

	get sh(): number {
		return parseFloat(this.device.vars.sh) || 0.0;
	}

	set sh(value: number) {
		let data: any = {
			id: this.device.id,
			sign: 'sh',
			value: value
		};

		this.wait.emit(true);

		clearTimeout(this._setterPpm);

		this._setterPpm = setTimeout(() => {
			//data.value = typeof (data.value) === 'number' ? data.value.toFixed(2) : data.value;
			this._devicesSvc.saveSign(data).then((result: any) => {
				this.wait.emit(false);
			}).catch((error: any) => {
				this._notificationSvc.error('', this._translate.instant(error.message));
				this.loading = false;
			});
		}, 750);

		this.device.vars.sh = _.round(value, 2);
	}

	get sv() {
		return +this.device.vars.sv || 0;
	}

	set sv(value: number) {
		let result = value.toString();

		if (result.length < 3) {
			for (let i = result.length; i < 3; i++) {
				result = '0' + result;
			}
		}

		this.device.vars.sv = result;
	}

	get tmpSv() {
		return +this.deviceTmp.vars.sv || 0;
	}

	set tmpSv(value: number) {
		let result = value.toString()

		if (result.length < 3) {
			for (let i = result.length; i < 3; i++) {
				result = '0' + result;
			}
		}

		this.change('sv', result);
	}

	get tmpCo() {
		return this.deviceTmp.vars.co !== '0';
	}

	set tmpCo(value: boolean) {
		(value) ? this.change('co', '2') : this.change('co', '0');
		(value) ? this.change('cv', '1') : this.change('cv', '0');
	}

	get tmpEg() {
		return +this.deviceTmp.vars.eg || 0;
	}

	set tmpEg(value: number) {
		let result = value.toString();

		if (result.length < 4) {
			for (let i = result.length; i < 4; i++) {
				result = '0' + result;
			}
		}

		this.change('eg', result);
	}

	get clGrD() {
		return this.deviceTmp.vars.eg !== '0000';
	}

	set clGrD(value: boolean) {
		if (!value) {
			this.change('eg', '0000');
		} else {
			if (this.device.vars.eg !== '0000') {
				this.change('eg', this.device.vars.eg);
			} else {
				this.change('eg', '0001');
			}
		}
	}

	get xuPercent(): number {
		return +(parseInt(this.device.vars.xu, 10) / 130).toFixed(2);
	}

	get swt() {
		return this.editSwt;
	}

	set swt(value: boolean) {
		if (value) {
			this.deviceTmp.id = this.device.id;
			this.deviceTmp.vars = _.pick(this.device.vars, ['ee', 'ei', 'eg', 'ce', 'sv', 'sn', 'sp', 'fp', 'so', 'fo', 'sh', 'fh', 'fe', 'fs', 'fd', 'hp', 'co', 'ho', 'hh']);

			this.deviceTmp.vars.fe = this.deviceTmp.vars.fe !== '-' ? !!+this.deviceTmp.vars.fe : false;
			this.deviceTmp.vars.ei = this.deviceTmp.vars.ei !== '-' ? !!+this.deviceTmp.vars.ei : false;
			this.deviceTmp.vars.ee = this.deviceTmp.vars.ee !== '-' ? !!+this.deviceTmp.vars.ee : false;

			this.loading = false;
		} else {
			this.deviceTmp = {
				id: null,
				vars: null
			};
		}

		this.editSwt = value;
	}

	get taRanges() {
		this._taRanges.value = parseFloat(this.device.vars.ta.substring(0, 5));
		this._taRanges.unit = this.loggedUser.unit_temp;

		if (this.loggedUser.unit_temp !== 'C') {
			this._taRanges.value = this._taRanges.value * 1.8 + 32;
		}

		return this._taRanges;
	}

	get grdLimit() {
		return this.device.ds2Model * 24 || 1008;
	}

	get vd() {
		return (this.device.vars.vd !== '') ? +this.device.vars.vd / 10 : 0;
	}

	get ic() {
		return (this.device.vars.ic !== '') ? +this.device.vars.ic / 10 : 0;
	}

	constructor(
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService,
		private _devicesSvc: DevicesService,
		private _authSvc: AuthService,
		private pageScrollService: PageScrollService,
		@Inject(DOCUMENT) private document: any) {
		PageScrollConfig.defaultDuration = 300;
		PageScrollConfig.defaultScrollOffset = 120;
		this.loggedUser = this._authSvc.user;
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.device && !changes.device.firstChange) {
			if (!changes.device.currentValue.isElectrolysis && changes.device.currentValue.isElectrolysis !== changes.device.previousValue.isElectrolysis) {
				this.dynamicTabs.tabs[0].active = true;
			} else if (!changes.device.currentValue.isUltraviolet && changes.device.currentValue.isUltraviolet !== changes.device.previousValue.isUltraviolet) {
				this.dynamicTabs.tabs[0].active = true;
			} else if (!changes.device.currentValue.isPH && changes.device.currentValue.isPH !== changes.device.previousValue.isPH) {
				this.dynamicTabs.tabs[0].active = true;
			} else if (!changes.device.currentValue.isORP && changes.device.currentValue.isORP !== changes.device.previousValue.isORP) {
				this.dynamicTabs.tabs[0].active = true;
			} else if (!changes.device.currentValue.isPPM && changes.device.currentValue.isPPM !== changes.device.previousValue.isPPM) {
				this.dynamicTabs.tabs[0].active = true;
			}
		}
	}

	public async save() {
		if (this.device.status.value !== 0) {
			let data: any = {
				id: this.deviceTmp.id,
				vars: null
			};

			this.savedError = false;
			this.updated = null;
			this.loading = true;
			this.checkSave = true;
			this.wait.emit(true);
			data.vars = _.clone(this.changed);
			delete data.vars.edited;
			this._notificationSvc.info('', this._translate.instant('GUARDANDO_INFO') + '<br><small><strong>' + this._translate.instant('MODO') + ':</strong> ' + ((this.device.vars.ep !== '060') ? 'Turbo</small>' : 'Eco</small>'));

			try {
				await this._devicesSvc.saveSignals(data)
			} catch (error) {
				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				} else {
					this._notificationSvc.error('', this._translate.instant('ERROR_SALVADO'));
				}
			} finally {

				this.changed.edited = true;
				this.checkSave = false;
				this.wait.emit(false);
				this.loading = false;

			}
			this._notificationSvc.success('', this._translate.instant('OK_MODIFICAR_DISPOSITIVO'));
		}
	}

	public cancel() {
		this.loading = true;
	}

	public spin(group) {
		let rotate = false;

		if (this.device.isElectrolysis) {
			rotate = (this.device.vars['g' + group] === '1') ? true : false;
		} else {
			rotate = ((this.device.vars['f' + group] === 'A' && this.device.vars['g' + group] === '1') || this.device.vars['f' + group] === 'D') ? true : false;
		}

		return rotate;
	}

	public activateTab(tabSelected: string = null) {
		let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#start');

		// tslint:disable-next-line:forin
		for (let tab in this.tabs) {
			this.tabs[tab] = false;
		}
		if (tabSelected) {
			this.tabs[tabSelected] = true;
		}

		this.pageScrollService.start(pageScrollInstance);
	}

	public scroll(option) {
		let pageScrollInstance: PageScrollInstance;

		switch (option) {
			case 'tabs':
				pageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#start');
				break;
			case 'relays':
				pageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#relays');
				break;
			case 'historics':
				pageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#historics');
				break;
		}

		this.pageScrollService.start(pageScrollInstance);
	}

	public change(sign: string, value: any) {
		this.savedError = false;
		this.updated = null;
		this.changed.edited = true;
		this.changed[sign] = value;
		this.deviceTmp.vars[sign] = value;
	}
}
