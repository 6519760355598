<header ps-header>
	<h2>{{ 'AVANZADO' | translate }}</h2>
</header>
<main class="container-fluid">
	<div *ngIf="devices">
		<span *ngIf="totalDevices" class="badge badge-info">{{ totalDevices }}</span>
		<span>{{ 'RESULTADOS' | translate }}</span>
	</div>
	<table-sorter [disableBtn]="disableBtn" [(page)]="page" [loading]="!devices" [limit]="limit" (limitChanged)="limit = $event; page = 1; loadItems()" [total]="totalPages" (prev)="loadItems($event);" (search)="searchText = $event" (next)="loadItems($event)" (pageChange)="loadItems($event)">
		<table cellspacing="1" class="table table-bordered table-responsive hidden-xs hidden-sm">
			<thead>
				<tr>
					<th ordenable order="vars/updated" (change)="changeOrder($event)" text-ellipsis style="width: 30%;">{{ 'ESTADO' | translate }}</th>
					<th ordenable order="fechaActivado" (change)="changeOrder($event)" text-ellipsis style="width: 10%;">{{ 'FECHA_ACTIVACION' | translate }}</th>
					<th ordenable order="user/nombre" (change)="changeOrder($event)" text-ellipsis style="width: 10%;">{{ 'USUARIO' | translate }}</th>
					<th style="border-right-color: transparent; width: 15%" text-ellipsis>{{ 'PROFESIONALES' | translate}}</th>
					<th></th>
					<th text-ellipsis style="width: 10%;">{{ 'MODELO' | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let device of devices; let i = index">
					<tr [ngClass]="{'danger': device.vars.vt != 1 && device.vars.vt != 13 && stableFirmware && stableFirmware.version != device.vars.vt}">
						<td>
							<div class="display-table">
								<div display-table-cell nowrap align-middle class="status">
									<span class="fa-stack fa-lg" [ngClass]="{fadeIn: !device.status.value}">
										<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
										<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
									</span>
								</div>
								<div display-table-cell class="infoContainer">
									<div *ngIf="device.isDS2 && !device.isDemo && device.status.value !== 0" class="personalizedIcon">
										<i *ngIf="device.personalized" [ngClass]="{'text-info': device.personalized.user === loggedUser.id_user}" class="fa fa-cogs" aria-hidden="true"></i>
										<i *ngIf="!device.personalized && device.vars.ep === device.default_eco" class="fa fa-leaf text-success" aria-hidden="true"></i>
										<i *ngIf="!device.personalized && device.vars.ep === device.default_turbo" class="fa fa-fighter-jet text-primary" aria-hidden="true"></i>
										<div *ngIf="!device.personalized && device.vars.ep !== device.default_turbo && device.vars.ep !== device.default_eco">
											<i class="fa fa-fighter-jet text-primary" aria-hidden="true"></i> <i class="fa fa-arrow-right miniArrow" aria-hidden="true"></i> <i class="fa fa-leaf text-success" aria-hidden="true"></i>
										</div>
									</div>
									<span>{{ device.id_loc }}</span>
									<br>
									<a href="javascript:{}" [routerLink]="['/devices', 'view', device.id]"><strong  class="text-overflow">{{ device.alias }}</strong></a> <span
										*ngIf="device.isDemo" class="badge badge-secondary">{{ 'DEMO' | translate }}</span>
									<br>
									<span>NS: {{ device.ns }}</span>
								</div>
								<div display-table-cell nowrap align-middle>
									<div class="col-xs-12 text-center" *ngIf="device.isDS2 && !device.isDemo && device.status.value !== 0" (click)="changeModeMenu(device)">
										<i class="fa fa-ellipsis-v togglerIcon text-center"></i>
									</div>
									<div *ngIf="loggedUser.id_role == ('' | roles).ADMIN" class="col-xs-12">
										<ui-switch (change)=changeAutoupdate(device,$event) [checked]="device.autoupdate == 1" color="#43a9c9"></ui-switch>
									</div>
								</div>
							</div>
						</td>
						<td>{{ device.activationDate | date : 'dd/MM/yyyy hh:mm' }}</td>
						<td>
							<a *ngIf="device?.user" href="javascript:{}" [routerLink]="['/users', 'edit', device.user.id]" >{{ device.user.nombre }} {{ device.user.apellidos }}</a>
						</td>
						<td style="border-right: 1px solid transparent">
							<div>
								<a *ngFor="let user of device.professionals" [routerLink]="['/users', 'edit', user.id]" class="text-center label label-default">
									{{ user.nombre }}<div><small>{{ user.usr }}</small></div>
								</a>
							</div>
						</td>
						<td class="actions">
							<button [disabled]="disableBtn" title="{{ 'NUEVO_PROFESIONAL' | translate }}" class="btn btn-primary" (click)="addProfessional(i)"><i class="fa fa-user"></i></button>
							<button [disabled]="disableBtn" [ngClass]="{'invisibleBtn' : device.professionals.length == 0}" title="{{ 'ELIMINAR_PROFESIONAL' | translate }}" class="btn btn-danger" (click)="delProfessional(i)"><i class="fa fa-trash"></i></button>
						</td>
						<td>
							<div *ngFor="let model of device.model">{{ model }}</div>
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>

		<div class="visible-xs visible-sm">
			<div *ngFor="let device of devices; let i = index" class="panel panel-default">
				<div class="panel-body">
					<div style="position: relative" *ngIf="device.isDS2 && !device.isDemo && device.status.value !== 0">
						<div class="togglerXs" display-table-cell>
							<div class="velStatus">
								<i *ngIf="device.personalized" [ngClass]="{'text-info': device.personalized.user === loggedUser.id_user}" class="fa fa-cogs" aria-hidden="true"></i>
								<i *ngIf="!device.personalized && device.vars.ep === device.default_eco" class="fa fa-leaf text-success" aria-hidden="true"></i>
								<i *ngIf="!device.personalized && device.vars.ep === device.default_turbo" class="fa fa-fighter-jet text-primary" aria-hidden="true"></i>
								<div *ngIf="!device.personalized && device.vars.ep !== device.default_turbo && device.vars.ep !== device.default_eco" style="width: 46px;">
									<i class="fa fa-fighter-jet text-primary" aria-hidden="true"></i> <i class="fa fa-arrow-right miniArrow" aria-hidden="true"></i> <i class="fa fa-leaf text-success" aria-hidden="true"></i>
								</div>
							</div>
							<i class="fa fa-ellipsis-v togglerIcon text-center" (click)="changeModeMenu(device)"></i>
						</div>
					</div>
					<div display-table>
						<div class="display-table">
							<div display-table-cell nowrap align-middle class="status">
								<span class="fa-stack fa-lg" [ngClass]="{fadeIn: !device.status.value}">
									<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
									<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
								</span>
							</div>

							<div display-table-cell>
								<span>{{ device.id_loc }}</span>
								<br>
								<a href="javascript:{}" [routerLink]="['/devices', 'view', device.id]"><strong  class="text-overflow">{{ device.alias }}</strong></a> <span
									*ngIf="device.isDemo" class="badge badge-secondary">{{ 'DEMO' | translate }}</span>
								<br>
								<span>NS: {{ device.ns }}</span>
							</div>
						</div>
					</div>
					<hr>
					<div display-table class="text-center">
						<div class="row">
							<div class="col-xs-12">
								<div>{{ 'FECHA_ACTIVACION' | translate }}:</div>
								<strong>{{ device.activationDate | date : 'dd/MM/yyyy hh:mm' }}</strong>
							</div>
							<div class="col-xs-12">
								<hr>
								<div>{{ 'USUARIO' | translate}}:</div>
								<a *ngIf="device?.user" href="javascript:{}">{{ device.user.nombre }} {{ device.user.apellidos }}</a>
							</div>
						</div>
					</div>
					<hr>
					<div display-table class="text-center row">
						<div class="col-xs-7">
							<div>{{ 'PROFESIONALES' | translate }}:</div>
						</div>
						<div class="col-xs-5 text-right">
							<button class="btn btn-primary" (click)="addProfessional(i)"><i class="fa fa-user"></i></button>
							<button *ngIf="device.professionals.length > 0" class="btn btn-danger" (click)="delProfessional(i)"><i class="fa fa-trash"></i></button>
						</div>
						<br>
						<ng-container *ngFor="let user of device.professionals">
							<div class="col-xs-12 text-left">
								<a [routerLink]="['/users', 'edit', user.id]" class="label label-default">{{ user.nombre }} <small>{{ user.usr }}</small></a>
							</div>
						</ng-container>
					</div>
					<hr>
					<div display-table class="text-left row">
						<div class="col-xs-9">
							<div><strong>{{ 'MODELO_SISTEMA' | translate }}:</strong></div>
							<div><span *ngFor="let model of device.model">{{ model }} </span></div>
							<div>{{ device.vars?.vt !== '' ? 'v' + device.vars.vt : '?' }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</table-sorter>

	<div bsModal #addProModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addProModal" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button class="close" aria-hidden="true" (click)="addProModal.hide()">&times;</button>
					<h4 class="modal-title">{{ 'PROFESIONALES_REGISTRADOS' | translate }}</h4>
				</div>
				<div class="modal-body text-center">
					<select *ngIf="professionals" class="form-control" [(ngModel)]="selectedProfessional">
						<option value="0" disabled selected >{{ 'SELECCION_PROFESIONAL_AVISO' | translate }}</option>
						<option *ngFor="let pro of professionals" [value]="pro.id">{{ pro.usr }} - {{ pro.nombre + ' ' + pro.apellidos }}</option>
					</select>
					<div *ngIf="!professionals" class="text-center">
						<div><img width="20%" src="/assets/images/wave.svg"></div>
						</div>
					</div>
					<div class="modal-footer">
						<button [disabled]="!selectedProfessional || blockButtons" class="btn btn-primary" (click)="associateProfessional()">{{'BT_ANADIR' | translate}}</button>
						<button class="btn btn-danger" title="{{'DESCARTAR_CAMBIOS' | translate}}" (click)="addProModal.hide()">{{ 'BT_CANCELAR' | translate }}</button>
					</div>
				</div>
			</div>
		</div>

		<div bsModal #removeProModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="removeProModal" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<button class="close" aria-hidden="true" (click)="removeProModal.hide()">&times;</button>
						<h4 class="modal-title">{{ 'PROFESIONALES_REGISTRADOS' | translate }}</h4>
					</div>
					<div class="modal-body text-center">
						<select *ngIf="devProfessionals" class="form-control" [(ngModel)]="selectedProfessional">
							<option *ngFor="let pro of devProfessionals" [value]="pro.id">{{ pro.nombre }} ({{ pro.company }})</option>
						</select>
						<div *ngIf="!devProfessionals" class="text-center">
							<div><img width="20%" src="/assets/images/wave.svg"></div>
							</div>
						</div>
						<div class="modal-footer">
							<button [disabled]="!selectedProfessional || blockButtons" class="btn btn-danger" (click)="desassociatePro()">{{'BT_ELIMINAR' | translate}}</button>
							<button class="btn btn-primary" title="{{'DESCARTAR_CAMBIOS' | translate}}" (click)="removeProModal.hide()">{{ 'BT_CANCELAR' | translate }}</button>
						</div>
					</div>
				</div>
			</div>

			<div bsModal #setVelModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="setVelModal" aria-hidden="true">
				<div *ngIf="selectedDevice" class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<button class="close" aria-hidden="true" (click)="setVelModal.hide()">&times;</button>
							<h4 class="modal-title">{{ selectedDevice.alias }}</h4>
						</div>
						<div class="modal-body">
							<div class="row">
								<p class="col-xs-12">{{ 'TEXTO_VEL_PERSONALIZADA' | translate }}</p>
								<div class="col-sm-4 col-xs-12 center">
									<strong>{{ 'MODO_PERSONALIZADO' | translate }}:</strong>
								</div>
								<div class="col-sm-5 col-xs-6">
									<input-number [block]="selectedDevice.status.value === 0" [value]="workMode" step="1" size="xs" min="1" max="999" precision="0" (exit)="workMode = $event"></input-number>
								</div>
								<div class="col-sm-3 col-xs-6 text-right">
									<button class="btn btn-xs btn-warning center" (click)="setVel()" [disabled]="settingVel || settingDefaults || blockVelBtns || selectedDevice.status.value === 0">
										<i class="fa fa-refresh" [ngClass]="{ 'fa-spin': settingVel }" aria-hidden="true"></i>
										{{ 'APLICAR' | translate }}
									</button>
								</div>
								<div class="resetVelBtns">
									<span class="col-xs-12 col-sm-4"><strong>{{ 'RESET' | translate }}:</strong></span>
									<span class="col-xs-12 col-sm-8 text-right">
										<button class="btn btn-xs btn-success" (click)="setDefaultMode('eco')" [disabled]="settingVel || settingDefaults || blockVelBtns || selectedDevice.status.value === 0">
											<i class="fa fa-leaf" aria-hidden="true"></i>
											{{ 'MODO_ECO' | translate }}
										</button>
										<!-- <button class="btn btn-xs btn-primary" (click)="setDefaultMode('fast')" [disabled]="settingVel || settingDefaults || blockVelBtns || selectedDevice.status.value === 0">
											<i class="fa fa-fighter-jet" aria-hidden="true"></i>
											{{ 'MODO_FAST' | translate }}
										</button> -->
									</span>
								</div>
								<p class="col-xs-12 divisor">{{ 'TEXTO_VEL_DEFECTO' | translate }}</p>
								<div class="col-xs-7 center">
									<strong>{{ 'ECO_DEFECTO' | translate }}:</strong>
								</div>
								<div class="col-xs-5">
									<input-number [block]="selectedDevice.status.value === 0" [value]="eco" step="1" size="xs" [min]="turbo + 1" max="999" precision="0" (exit)="eco = $event"></input-number>
								</div>
								<div class="col-xs-7 center">
									<strong>{{ 'TURBO_DEFECTO' | translate }}:</strong>
								</div>
								<div class="col-xs-5">
									<input-number [block]="selectedDevice.status.value === 0" [value]="turbo" step="1" size="xs" min="1" [max]="eco - 1" precision="0" (exit)="turbo = $event"></input-number>
								</div>
								<div class="text-right col-xs-12">
									<button class="btn btn-xs btn-warning" (click)="setDefaultVels()" [disabled]="settingVel || settingDefaults || blockVelBtns || selectedDevice.status.value === 0">
										<i class="fa fa-refresh" [ngClass]="{ 'fa-spin': settingDefaults }" aria-hidden="true"></i>
										{{ 'APLICAR' | translate }}
									</button>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-danger" title="{{'DESCARTAR_CAMBIOS' | translate}}" (click)="setVelModal.hide()">{{ 'BT_CANCELAR' | translate }}</button>
						</div>
					</div>
				</div>
			</div>
</main>
