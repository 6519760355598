<main class="container container-login-wrap">
	<div class="row container-form-login ">
		<div class="col-sm-6 col-md-5">
			<br>
			<h2 class="form-signin-heading">
				<img class="center-block img-responsive login-logo" src="../../assets/images/poolstation.png" />
			</h2>
			<br>
			<form name="form" #f="ngForm" (ngSubmit)="login()" novalidate *ngIf="!need2fa">
				<br>
				<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
					<label for="username">{{ 'USUARIO' | translate }}</label>
					<input id="username" type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required />
				</div>
				<div class="form-group" style="position:relative" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
					<label for="password">{{ 'CONTRASENYA' | translate }}</label>
					<input id="password" [type]="type" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
					<a class="btn btn-default eye" (click)="type = (type === 'password') ? 'text' : 'password'"><i class="fa" [ngClass]="type === 'password' ? 'fa-eye' : 'fa-eye-slash'" aria-hidden="true"></i></a>
				</div>
				<div class="form-group checkbox">
					<label>
						<input type="checkbox" [checked]="remember" (change)="remember=$event.target.checked"> {{ 'GUARDAR_CONTRASENYA' | translate }}
					</label>
				</div>
				<div class="form-group" *ngIf="captchaNeeded">
					<ngx-recaptcha2 #reCaptcha
						[siteKey]="captchaSvc.key"
						(success)="onCaptchaSuccess($event)"
						[hl]="browserLang"
						[ngModel]="token"
					></ngx-recaptcha2>
				</div>
				<input class="btn btn-default btn-block" type="submit" value="{{ 'BT_ACCEDER' | translate }}" />
				<a class="btn btn-link btn-block" [routerLink]="['/recover']">
					<small>{{ 'RECORDAR_PASS' | translate }}</small>
				</a>
				<a class="btn btn-default btn-block" [routerLink]="['/register']">{{ 'BT_REGISTRARSE' | translate }}</a>
			</form>
            <div *ngIf="need2fa">
				<form name="2faform" #f="ngForm" (ngSubmit)="login2fa()" novalidate>
					<div class="form-group">
						<label for="verificationCode">{{ 'WRITE_2FA_CODE' | translate }}</label>
						<input id="verificationCode" type="text" class="form-control" name="verificationCode" [(ngModel)]="model2fa.verification" autofocus required />
					</div>
					<input class="btn btn-default btn-block" type="submit" value="{{ 'BT_ACCEDER' | translate }}" />
					<br/>
				</form>
				<div class="text-center">
					<button class="btn btn-link" (click)="reset2fa()">{{ 'RESET_2FA' | translate }}</button>
					<br/>
					<button class="btn btn-link" (click)="comeBackToLogin()">{{ 'RETURN_TO_LOGIN' | translate }}</button>
				</div>
            </div>
			
		</div>
        <div class="col-sm-6 col-md-5">
            <br>
			<h5 style="text-align: center">DEMO POOLSTATION</h5>
			<hr style="margin-top: 0; margin-bottom: 8px" />
			<div class="row">
				<div class="text-center col-xs-6" no-padding>
					<button class="btn btn-link" (click)="model.username='demo@demo.com'; model.password='demo'">{{ 'USUARIO' | translate }}</button>
				</div>
				<div class="text-center col-xs-6" no-padding>
					<button class="btn btn-link" (click)="model.username = 'demoprofesional@demo.com'; model.password = 'demo'">{{ 'PROFESIONAL' | translate }}</button>
				</div>
			</div>
        </div>
	</div>
	<div class="footer-links-nav">
		<div class="col-sm-6 col-md-5" >
			<div class="text-center col-xs-4 policies-info">
				<a href="javascript:{}" [routerLink]="['/docs', 'cookies']">{{ 'POLITICA_COOKIES' | translate }}</a>
			</div>
			<div class="text-center col-xs-4 policies-info">
				<a href="javascript:{}" [routerLink]="['/docs', 'legal']">{{ 'AVISO_LEGAL' | translate }}</a>
			</div>
			<div class="text-center col-xs-4 policies-info">
				<a href="javascript:{}" [routerLink]="['/docs', 'privacy']">{{ 'PRIVACY_POLICY' | translate }}</a>
			</div>

		</div>
	</div>
</main>
