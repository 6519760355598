import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators'

@Injectable()
export class RegisterStateService {
	previousUrl: string = null;
	currentUrl: string = null;

	private readonly urls: Array<String> = ['/data-protection', '/privacy'];
	private readonly accountSelectionState: number = 1;
	private readonly initialRegisterState: number = 0;

	constructor(private router: Router) { }

	execute() {
		this.router.events
			.pipe(
				filter(e => e instanceof NavigationEnd),
				pairwise()
			)
			.subscribe((e: any[]) => {
				this.previousUrl = e[0].url;
				this.currentUrl = e[1].url;
			})

		if (
			this.urls.includes(this.previousUrl) ||
			this.urls.includes(this.currentUrl)
		) return this.accountSelectionState;

		return this.initialRegisterState;
	}
}
