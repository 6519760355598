import {Component, OnInit, ElementRef, ViewChild, OnDestroy, NgModule} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { AuthService } from '../../services/auth.service';
import { DevicesService } from '../../services/devices.service';
import { WeatherService } from '../../services/weather.service';
import { Device } from '../../entities/device';
import { GeolocationService } from '../../services/geolocation.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';

// utils
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { RolesPipe } from '../../pipes/roles.pipe';
import ROLES from '../../entities/roles';

@Component({
	selector: 'device',
	templateUrl: './device.component.html',
	styleUrls: ['./device.component.scss']
})

export class DeviceComponent implements OnInit, OnDestroy {
	@ViewChild('placeTxt', {static: true}) input: ElementRef;
	@ViewChild('editModal', {static: true}) editModal;
	@ViewChild('deleteFunctionModal', {static: true}) deleteFunctionModal;
		readonly maxFailedRequests = 10;
		private failedRequests     = 0;
	public waiting: boolean = false;
	public tempValues: any;
	public typeInterface: string;
	public functionToDisable: string;
	public saving: boolean = false;
	public id: number;
	public device: any;
	public deviceType: string = 'OTHERS';
	public deviceOffHours: any;
	public relaysStatus: any;
	public getDeviceTimer: any;
	public action = 'view';
	public date: any = moment();
	public loggedUser: any = this._authSvc.user;
	public weather: any;
	public prevision: any[];
	public timezone = 'Europe';
	public timezones: any;
	public weatherIcons = {
		371: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		368: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		395: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		392: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		338: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		335: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		332: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		329: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		326: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		323: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',
		179: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nieve.png',

		314: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		308: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		284: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		281: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',

		377: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		374: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		365: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		362: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		350: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		320: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		317: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		182: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',

		260: '../../assets/images/vprofesional/icon_weather_big/vp_ico_sol.png',
		248: '../../assets/images/vprofesional/icon_weather_big/vp_ico_sol.png',
		143: '../../assets/images/vprofesional/icon_weather_big/vp_ico_sol.png',

		230: '../../assets/images/vprofesional/icon_weather_big/vp_ico_viento.png',
		277: '../../assets/images/vprofesional/icon_weather_big/vp_ico_viento.png',

		311: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		296: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		293: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		266: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		263: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		185: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		176: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',

		389: '../../assets/images/vprofesional/icon_weather_big/vp_ico_rayos.png',
		386: '../../assets/images/vprofesional/icon_weather_big/vp_ico_rayos.png',

		200: '../../assets/images/vprofesional/icon_weather_big/vp_ico_rayos.png',

		359: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		356: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		353: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		305: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		302: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',
		299: '../../assets/images/vprofesional/icon_weather_big/vp_ico_lluvia.png',

		122: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nubes.png',
		119: '../../assets/images/vprofesional/icon_weather_big/vp_ico_nubes.png',

		116: '../../assets/images/vprofesional/icon_weather_big/vp_ico_solynubes.png',

		113: '../../assets/images/vprofesional/icon_weather_big/vp_ico_sol.png'
	};

	public alertIcons = {
		H: 'fa-arrow-up',
		L: 'fa-arrow-down',
		J: 'fa-arrow-left',
		N: 'fa-arrow-right',
		I: 'fa-cogs',
		1: 'fa-exclamation-triangle',
		2: 'fa-exclamation-circle'
	};
	public states = {
		0: { color: 'danger', label: 'OFF' },
		1: { color: 'success', label: 'ON' },
		A: { color: 'info', label: 'AUTO' }
	};
	public marker: any = {
		lat: 38.345996,
		lng: -0.490686
	};
	public map: any = {
		lat: 38.345996,
		lng: -0.490686
	};
	public search: string;
	public secuence = {
		1: 'ESTIVAL',
		2: 'CAROLINE',
		3: 'IRIS',
		4: 'CANDY',
		5: 'TROPICAL',
		6: 'PARADISE',
		7: 'ABRIL',
		8: 'ELEVEN'
	};
	public edit = {
		rph: false,
		rcl: false, // ppm
		orp: false,
		electrolysis: false,
		pr3xx: false,
		lumiplus: false
	};
	public colors: any = {
		'1': {
			name: 'Red',
			color: '#fe1100'
		},
		'2': {
			name: 'Green',
			color: '#00972c'
		},
		'3': {
			name: 'Blue',
			color: '#183360'
		},
		'4': {
			name: 'Yellow',
			color: '#fef31f'
		},
		'5': {
			name: 'Cyan',
			color: '#007aa9'
		},
		'6': {
			name: 'Magenta',
			color: '#b3105f'
		},
		'7': {
			name: 'Sky-Blue',
			color: '#779cc9'
		},
		'8': {
			name: 'Pale Violet',
			color: '#ff77c1'
		},
		'9': {
			name: 'Orange',
			color: '#ff6600'
		},
		'A': {
			name: 'Pink',
			color: '#ff3fa4'
		},
		'B': {
			name: 'Emeral Green',
			color: '#22cb28'
		},
		'C': {
			name: 'White',
			color: '#e6e6e6'
		}
	};
	public moreInfo: boolean = false;
	public morePH: boolean = false;
	public moreOthers: boolean = false;
	public savingBadge: boolean = false;
	public regPh: any;
	public regOrp: any;
	public regPpm: any;
	private _timeInterval: any;
	private _deviceRefresh: any;
	private _optionToEdit: string;
	private _setterPh: any;
	private _setterSo: any;

	get sp() {
		if (this.device.vars.sp !== '') {
			return parseFloat(this.device.vars.sp).toFixed(2);
		} else {
			return 0.0;
		}
	}

	set sp(value: any) {
		let data: any = {
			id: this.device.id,
			sign: 'sp',
			value: value
		};

		this.savingBadge = true;

		if (this._deviceRefresh) {
			clearTimeout(this._deviceRefresh);
		}

		clearTimeout(this._setterPh);

		this._setterPh = setTimeout(() => {
			data.value = data.value.toFixed(2);

			this._devicesSvc.saveSign(data).then((result: any) => {
				this.savingBadge = false;
				this.device.vars.sp = data.value;
			}).catch((error: any) => {
				this.device.vars.sp = this.tempValues.sp;

				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}
			});
		}, 750);
	}

	get sv() {
		if (this.device.vars.sv !== '') {
			return +this.device.vars.sv;
		} else {
			return 0;
		}
	}

	set sv(value: any) {
		this.device.vars.sv = value.toString();

		while (this.device.vars.sv.length < 3) {
			this.device.vars.sv = '0' + this.device.vars.sv;
		}
	}

	get sn() {
		if (this.device.vars.sn !== '') {
			return +this.device.vars.sn;
		} else {
			return 0;
		}
	}

	set sn(value: any) {
		this.device.vars.sn = value.toString();

		while (this.device.vars.sn.length < 3) {
			this.device.vars.sn = '0' + this.device.vars.sn;
		}
	}

	get sh() {
		if (this.device.vars.sh !== '') {
			return parseFloat(this.device.vars.sh).toFixed(2);
		} else {
			return 0.0;
		}
	}

	set sh(value: any) {
		this.device.vars.sh = value.toString();

		while (this.device.vars.sh.length < 4) {
			if (this.device.vars.sh.length === 1) {
				this.device.vars.sh = this.device.vars.sh + '.00';
			} else {
				this.device.vars.sh = this.device.vars.sh + '0';
			}
		}
	}

	get xp() {
		if (this.device.vars.xp !== '') {
			return +this.device.vars.xp;
		} else {
			return 0;
		}
	}

	set xp(value: any) {
		if (value < 25) {
			value = 25;
		} else if (value > 100) {
			value = 100;
		} else if (value > 25 && value < 50) {
			value = (this.xp < value) ? 25 : 50;
		} else if (value > 50 && value < 75) {
			value = (this.xp < value) ? 50 : 75;
		} else if (value > 75 && value < 100) {
			value = (this.xp < value) ? 75 : 100;
		}

		this.device.vars.xp = value.toString();

		while (this.device.vars.xp.length < 3) {
			this.device.vars.xp = '0' + this.device.vars.xp;
		}
	}

	get xh() {
		if (this.device.vars.xh !== '') {
			return +this.device.vars.xh;
		} else {
			return 0;
		}
	}

	set xh(value: any) {
		if (value < 25) {
			value = 25;
		} else if (value > 100) {
			value = 100;
		} else if (value > 25 && value < 50) {
			value = (this.xh < value) ? 25 : 50;
		} else if (value > 50 && value < 75) {
			value = (this.xh < value) ? 50 : 75;
		} else if (value > 75 && value < 100) {
			value = (this.xh < value) ? 75 : 100;
		}

		this.device.vars.xh = value.toString();

		while (this.device.vars.xh.length < 3) {
			this.device.vars.xh = '0' + this.device.vars.xh;
		}
	}

	get tm() {
		if (this.device.vars.tm !== '') {
			return +this.device.vars.tm;
		} else {
			return 0;
		}
	}

	set tm(value: any) {
		this.device.vars.tm = value.toString();

		while (this.device.vars.tm.length < 3) {
			this.device.vars.tm = '0' + this.device.vars.tm;
		}
	}

	get hm() {
		if (this.device.vars.hm !== '') {
			return +this.device.vars.hm;
		} else {
			return 0;
		}
	}

	set hm(value: any) {
		this.device.vars.hm = value.toString();

		while (this.device.vars.hm.length < 3) {
			this.device.vars.hm = '0' + this.device.vars.hm;
		}
	}

	get so() {
		if (this.device.vars.so !== '') {
			return +this.device.vars.so;
		} else {
			return 0;
		}
	}

	set so(value: any) {
		let data: any;

		this.savingBadge = true;
		this.device.vars.so = value.toString();

		while (this.device.vars.so.length < 3) {
			this.device.vars.so = '0' + this.device.vars.so;
		}

		data = {
			id: this.device.id,
			sign: 'so',
			value: this.device.vars.so
		};

		if (this._deviceRefresh) {
			clearTimeout(this._deviceRefresh);
		}

		clearTimeout(this._setterSo);

		this._setterSo = setTimeout(() => {
			this._devicesSvc.saveSign(data).then((result: any) => {
				this.savingBadge = false;
			}).catch((error: any) => {
				this.device.vars.so = this.tempValues.so;

				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}
			});
		}, 750);
	}

	get xo() {
		if (this.device.vars.xo !== '') {
			return +this.device.vars.xo;
		} else {
			return 0;
		}
	}

	set xo(value: any) {
		if (value < 25) {
			value = 25;
		} else if (value > 100) {
			value = 100;
		} else if (value > 25 && value < 50) {
			value = (this.xo < value) ? 25 : 50;
		} else if (value > 50 && value < 75) {
			value = (this.xo < value) ? 50 : 75;
		} else if (value > 75 && value < 100) {
			value = (this.xo < value) ? 75 : 100;
		}

		this.device.vars.xo = value.toString();

		while (this.device.vars.xo.length < 3) {
			this.device.vars.xo = '0' + this.device.vars.xo;
		}
	}

	get om() {
		if (this.device.vars.om !== '') {
			return +this.device.vars.om;
		} else {
			return 0;
		}
	}

	set om(value: any) {
		this.device.vars.om = value.toString();

		while (this.device.vars.om.length < 3) {
			this.device.vars.om = '0' + this.device.vars.om;
		}
	}

	get vu() {
		if (this.device.vars.vu !== '') {
			return +this.device.vars.vu;
		} else {
			return 1;
		}
	}

	set vu(value: any) {
		this.device.vars.vu = value.toString();
	}

	set op(value: boolean) {
		this.device.vars.op = (value) ? 1 : 0;
	}

	get op() {
		return this.device.vars.op === 1;
	}

	set oo(value: boolean) {
		this.device.vars.oo = (value) ? 1 : 0;
	}

	get oo() {
		return this.device.vars.oo === 1;
	}

	set oh(value: boolean) {
		this.device.vars.oh = (value) ? 1 : 0;
	}

	get oh() {
		return this.device.vars.oh === 1;
	}

	set fe(value) {
		this.device.vars.fe = (value) ? '1' : '0';
	}

	get fe() {
		return this.device.booleanVars.fe === 1 || this.device.booleanVars.fe === true;
	}

	set fs(value) {
		this.device.vars.fs = (value) ? 1 : 0;
	}

	get fs() {
		return this.device.vars.fs === 1 || this.device.vars.fs === true;
	}

	constructor(
		private _http: HttpClient,
		private _activeRoute: ActivatedRoute,
		private _router: Router,
		private _authSvc: AuthService,
		private _devicesSvc: DevicesService,
		private _weatherSvc: WeatherService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService,
		private _geo: GeolocationService) {
		this._http.get('/assets/enum/timezones.json').subscribe((res) => this.timezones = res/* .json() */);
	}

	public ngOnInit() {
		window.onbeforeunload = () => {
			if (this.device) {
				this._devicesSvc.changeMode(this.device.id, 'eco').catch((error) => {
					console.log('Sin cambio de modo');
				});
			}
		};

		this._activeRoute.params.subscribe((params) => {
			this.action = params['action'] || 'view';
			this.id = params['id'];

			switch (this.action) {
				case 'edit':
				case 'view':
					this.getDevice();
					break;
				case 'add':
				case 'register':
					this.device = new Device({});
					this.device.cod_activacion = (this.action === 'add' && (this.loggedUser.id_role === ROLES.ADMIN || this.loggedUser.id_role === ROLES.SAT)) ? this.device.generateActivationCode() : '';
					break;
			}
		});
	}

	ngOnDestroy() {
		clearTimeout(this._deviceRefresh);
	}

	public getDevice(delay: number = 1) {
		this.getDeviceTimer = setTimeout(() => {
			this._devicesSvc.get(this.id).then(async (device: any) => {
				this.map.lat = device.lat;
				this.map.lng = device.longitud;
				this.marker.lat = device.lat;
				this.marker.lng = device.longitud;

				if (!this.waiting) {
					if (!this._optionToEdit) {
						this.deviceOffHours = device.hoursDesconected();
						this.device = device;
						this.getRelayStatus();
						this._getTimezones();
						this._setRegulators(this.device.vars.ms, this.device.vars.modelo_regulador);

						if (this.device) {
							if (this.device.status.value !== 0 && this.device.vars.ep !== '004' && this.device.vars.ep !== '005') {
								await this._devicesSvc.changeMode(this.id, 'fast');
							}

							this.date = moment().tz(this.device.timezone);

							if (!this._timeInterval) {  //  JLF Editar Velociad de refresco de datos servicio 10 segundos 5 segundos
								this._timeInterval = setInterval(() => { this.date = moment().tz(this.device.timezone); }, 60 * 500);
							}                           // Aquí lo que refresco es la pantalla en la que sale el listado de dispositivos, digamos la general (de hecho es la pestaña general).

							if (!this.weather) {
								this._getWheater();
							}
						}
					}
				}
			}).catch((error) => {
					this.failedRequests++;
					if (this.failedRequests < this.maxFailedRequests) {
									this.getDevice();
					}
			});
		}, delay);
	}

	public getRelayStatus() {
		this._devicesSvc.getRelaysStatus(this.device.ns).then((relaysStatus) => {
			let time = 5000;  //  JLF Cuando arriba ponía que el resfresco de la página de datos era ese, no lo era. Es aquí donde en la pantalla principal de bolas llama a la función
						//  getDevice y actualiza periódicamente la API.
			this.relaysStatus = relaysStatus;

			if (!this.relaysStatus.isConnected) {
				time = 30000;
			}

			if (this._router.url === '/devices/view/' + this.device.id && !this._optionToEdit) {
				this._deviceRefresh = setTimeout(() => {
					this.getDevice(); //  JLF ************
				}, time);
			}
		}).catch((error) => {
			this.getRelayStatus();
			console.log(error.message);
		});
	}

	public mapClicked($event: any) {
		if((this.action === 'edit' && this.loggedUser.id_role != ROLES.PROFESIONAL) || this.action !== 'edit') {
			this._searchCoordinates($event.coords.lat, $event.coords.lng);
		}
	}

	public searchLocation() {
		let searched = this.search.trim();

		if (searched !== '') {
			this._geo.getCoordinates(searched).then((coordinates: any) => {
				(coordinates !== '') ? this._searchCoordinates(coordinates.lat, coordinates.lng, true) : console.log('Sin resultados en la busqueda');
			}).catch((error) => {
				console.log(error);
			});
		}
	}

	public save() {
		if (!this.loggedUser.isDemo) {
			let error: boolean = false;

			this.saving = true;

			switch (this.loggedUser['id_role']) {
				case ROLES.PROPIETARIO:

					this.device.id_user = this.loggedUser.id_user;

					//if (this.device.cod_activacion === null || this.device.cod_activacion.trim() === '') {
						//this._notificationSvc.error('', this._translate.instant('ERR_INTRO_CODIGO'));
						//error = true;
					//}

					if (!error && (this.device.alias === null || this.device.alias.trim() === '')) {
						this._notificationSvc.error('', this._translate.instant('NOMBRE_DISPOSITIVO'));
						error = true;
					}

					if (!error && (this.device.id_pais === null || this.device.id_pais === '')) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_PAIS'));
						error = true;
					}

					if (!error && (this.device.id_loc === null || this.device.id_loc === '' || this.device.lat === null || this.device.longitud === null)) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_LOCALIDAD'));
						error = true;
					}

					if (!error && (this.device.timezone === null || this.device.timezone === '')) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_ZONA_HORARIA'));
						error = true;
					}
					break;
				case ROLES.ADMIN:
				case  ROLES.SAT:
					if (this.device.cod_activacion === null || this.device.cod_activacion.trim() === '') {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_CODIGO'));
						error = true;
					}

					if (!error && this.action === 'add' && (this.device.register.mac === null || this.device.register.mac.trim() === '')) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_MAC'));
						error = true;
					}

					if (!error && this.action === 'add' && (this.device.ns === null || this.device.ns.trim() === '')) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_NUMSERIE'));
						error = true;
					}

					if (!error && this.action === 'edit' && (this.device.alias === null || this.device.alias.trim() === '')) {
						this._notificationSvc.error('', this._translate.instant('NOMBRE_DISPOSITIVO'));
						error = true;
					}

					if (!error && this.action === 'edit' && (this.device.id_pais === null || this.device.id_pais === '')) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_PAIS'));
						error = true;
					}

					if (!error && this.action === 'edit' && (this.device.id_loc === null || this.device.id_loc === '' || this.device.lat === null || this.device.longitud === null)) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_LOCALIDAD'));
						error = true;
					}

					if (!error && this.action === 'edit' && (this.device.timezone === null || this.device.timezone === '')) {
						this._notificationSvc.error('', this._translate.instant('ERR_INTRO_ZONA_HORARIA'));
						error = true;
					}
					break;
			}

			if (!error) {
				let data: any = _.pick(this.device, ['id', 'id_user', 'alias', 'ns', 'register', 'cod_activacion', 'id_loc', 'id_pais', 'timezone', 'lat', 'longitud']);

				if (this.action === 'edit' || this.action === 'view' || (this.loggedUser.id_role === ROLES.ADMIN && this.action === 'add')) {
					if (data.ns && this.device.register) {
						data.register.ns = data['ns'];
					} else {
						this._notificationSvc.error('', this._translate.instant('ERR_BBDD'));
						this.saving = false;
						return null;
					}

					if (!data.lat) {
						data.lat = '';
						data.longitud = '';
					}
				}

				switch (this.loggedUser['id_role']) {
					case  ROLES.ADMIN:
						switch (this.action) {
							case 'edit':
								data.diffHoraria = this._getHourDiff(data.timezone);
								delete data.register;
								delete data.ns;
								delete data.cod_activacion;

								this._devicesSvc.save(data).then((result: any) => {
									this._notificationSvc.success('', this._translate.instant(result.message));
									this.saving = false;
								}).catch((error: any) => {
									if (error.message) {
										if (error.error.message === 'REPEATED') {
											this._notificationSvc.error('', this._translate.instant('ERR_NUMSERIE_REGISTRADO_1') + ' ' + this._translate.instant('ERR_NUMSERIE_REGISTRADO_2'));
										}
										else{
											this._notificationSvc.error('', this._translate.instant(error.message));
										}
									}
									this.saving = false;
								});
								break;
							default:
								data.deviceType = this.deviceType;
								data.timezone = 'Europe/Madrid';
								data.id_loc = 'Alicante'
								data.id_pais = 'España';
								data.lat = '38.3451700';
								data.longitud = '-0.4814900';

								this._devicesSvc.save(data).then((result: any) => {
									this._notificationSvc.success('', this._translate.instant(result.message));
									this.saving = false;
									this._router.navigate(this.action === 'register' ? ['/request', 'register'] : ['/']);
								}).catch((error: any) => {
									if (error.message) {
										if (error.error.message === 'REPEATED') {
											this._notificationSvc.error('', this._translate.instant('ERR_NUMSERIE_REGISTRADO_1') + ' ' + this._translate.instant('ERR_NUMSERIE_REGISTRADO_2'));
										} else {
											this._notificationSvc.error('', this._translate.instant(error.message));
										}
									}

									this.saving = false;
								});
								break;
						}
						break;
					case ROLES.PROFESIONAL: 
						if (this.action == 'edit'){
							this._devicesSvc.saveAliasDeviceForProfessional(data.id, data.alias).then((result: any) => {
								this._notificationSvc.success('', this._translate.instant(result.message));
								this.saving = false;
							}).catch((error: any) => {
								if(error.message){
									this._notificationSvc.error('', this._translate.instant(error.message));
								}
								this.saving = false;
							});
						}
						break;
						
					case  ROLES.PROPIETARIO:
						data.diffHoraria = this._getHourDiff(data.timezone);

						switch (this.action) {
							case 'edit':
								delete data.register;
								delete data.ns;
								delete data.cod_activacion;
								this._devicesSvc.save(data).then((result: any) => {
									this._notificationSvc.success('', this._translate.instant(result.message));
									this.saving = false;
								}).catch((error: any) => {
									if (error.message) {
										if (error.error.message === 'REPEATED') {
											this._notificationSvc.error('', this._translate.instant('ERR_NUMSERIE_REGISTRADO_1') + ' ' + this._translate.instant('ERR_NUMSERIE_REGISTRADO_2'));
										} else {
											this._notificationSvc.error('', this._translate.instant(error.message));
										}
									}
									this.saving = false;
								});
								break;
							default:
				//  JLF: Edición Código Activación
								if(this.device.register.mac){
									data.mac = this.device.register.mac.padStart(12, '0');		
								}					  
								this._devicesSvc.associateMAC(data).then((result: any) => {
									this._notificationSvc.success('', this._translate.instant(result.message));
									this.saving = false;
									this._router.navigate(['/']);
								}).catch((error: any) => {
									if (error.error.message) {
									this._notificationSvc.error('', this._translate.instant(error.error.message));
									}
									this.saving = false;
								});
								break;
							}
						break;
				}
			} else {
				this.saving = false;
			}
		} else {
			this._notificationSvc.info('', this._translate.instant('DEMO'));
		}
	}

  onChangeActivationCode(newValue, action) {
		if(action == 'register'){
			if (newValue != null || newValue !== '') {
				this.device.register.mac = '';
			}
		}
  }

  onChangeMAC(newValue, action) {
	if(action == 'register'){
		if (newValue != null || newValue !== '') {
			this.device.cod_activacion = '';
		}
	}
  }

	private _getHourDiff(timezone: string): string {
		if (timezone) {
			let hours: number;
			let mins: number;
			let sign: string;
			let hoursDiff: string;
			let minsDiff: string;
			let utcOffset = moment().tz(timezone).utcOffset();

			hours = Math.trunc(utcOffset / 60);
			sign = (hours >= 0) ? '1' : '2';
			hoursDiff = (hours < 0) ? (hours *= -1).toString() : hours.toString();

			if (hoursDiff.length === 1) {
				hoursDiff = '0' + hoursDiff;
			}

			mins = utcOffset % 60;
			minsDiff = (mins < 0) ? (mins *= -1).toString() : mins.toString();

			if (minsDiff.length === 1) {
				minsDiff = '0' + mins;
			}

			return sign + hoursDiff + minsDiff;
		} else {
			return '10100';
		}
	}

	private _getWheater() {
		this._weatherSvc.getWeather(this.device).then((weather) => {
			this.weather = weather;

			if (this.weather) {
				this.prevision = this._prevision();
			}
		}).catch((error) => {
			console.log(error);
		});
	}

	private _prevision(): any[] {
		let aux: any = [];
		let result: any[] = [];
		let dayOfWeek: any = { '0': 'SUN', '1': 'MON', '2': 'TUE', '3': 'WED', '4': 'THU', '5': 'FRI', '6': 'SAT' };

		for (let dayPrevision of this.weather.data.weather) {
			switch (this.loggedUser['unit_temp']) {
				case 'C':
					aux = [dayOfWeek[new Date(dayPrevision.date).getDay()], dayPrevision.mintempC + 'ºC', dayPrevision.maxtempC + 'ºC'];
					break;
				case 'F':
					aux = [dayOfWeek[new Date(dayPrevision.date).getDay()], dayPrevision.mintempF + 'ºF', dayPrevision.maxtempF + 'ºF'];
					break;
				default: break;
			}
			result.push(aux);
		}

		return result;
	}

	private _searchCoordinates(lat: number, lng: number, center: boolean = false) {
		this.device.lat = lat;
		this.device.longitud = lng;

		this._geo.getAddress(lat, lng).then((address) => {
			if (address !== '') {
				this.marker.lat = lat;
				this.marker.lng = lng;
				(address['country']) ? this.device.id_pais = address['country'].long_name : this.device.id_pais = '';
				(address['locality']) ? this.device.id_loc = address['locality'].long_name : this.device.id_loc = '';

				if (center) {
					this.map.lat = lat;
					this.map.lng = lng;
				}
			}
		}).catch((error) => {
			console.log(error);
		});
	}

	public disableFunction() {
		let data: any = {
			id: this.device.id,
			disable: this.functionToDisable
		};

		this.deleteFunctionModal.hide();

		this._devicesSvc.disableFunction(data).then((result: any) => {
			this._notificationSvc.success('', this._translate.instant(result.message));
			this.getDevice();
		}).catch((error: any) => {
			if (error.message) {
				this._notificationSvc.html(this._translate.instant(error.message), NotificationType.Error);
			}
		});
	}

	public selectFunctionToDisable(disableFunction: string) {
		this.functionToDisable = disableFunction;
		this.deleteFunctionModal.show();
	}

	public editing(type: string = '', reload: boolean = true) {
		this.savingBadge = false;
		this._optionToEdit = type;

		if (this._optionToEdit) {
			// tslint:disable-next-line:forin
			for (const field in this.tempValues) {
				this.device.vars[field] = this.tempValues[field];
			}
		}

		clearTimeout(this._deviceRefresh);

		// tslint:disable-next-line:forin
		for (let field in this.edit) {
			this.edit[field] = (field === type) ? !this.edit[type] : false;
		}

		if (this.edit[type]) {
			switch (this._optionToEdit) {
				case 'rph':
					this.tempValues = _.pick(this.device.vars, ['sp', 'fp', 'xp', 'tm', 'ip', 'modelo_regulador']);
					break;
				case 'orp':
					this.tempValues = _.pick(this.device.vars, ['so', 'fo', 'xo', 'om', 'io', 'modelo_regulador']);
					break;
				case 'electrolysis':
					this.tempValues = _.pick(this.device.vars, ['sn', 'sp', 'so', 'sh', 'or', 'ce', 'co', 'sv', 'fs', 'fe']);
					break;
				case 'rcl':
					this.tempValues = _.pick(this.device.vars, ['sh', 'fh', 'xh', 'hm', 'ih', 'modelo_regulador']);
					break;
				case 'pr3xx':
					this.tempValues = _.pick(this.device.vars, ['sp', 'fp', 'xp', 'tm', 'ip', 'so', 'fo', 'xo', 'om', 'io', 'sh', 'fh', 'sh', 'hm', 'ih', 'oo', 'op', 'oh']);
					break;
				case 'lumiplus':
					this.tempValues = _.pick(this.device.vars, ['mu', 'cu', 'su', 'vu', 'tu']);
					break;
			}
		} else {
			this.cancelEdit(reload);
		}
	}

	public editValues() {
		if (this.device.status.value !== 0) {
			let data = {
				id: this.device.id,
				vars: null
			};

			this.saving = true;
			this.edit[this._optionToEdit] = false;
			clearTimeout(this._deviceRefresh);
			this.editModal.hide();

			switch (this._optionToEdit) {
				case 'rph':
					data.vars = _.pick(this.device.vars, ['sp', 'fp', 'xp', 'tm', 'ip', 'hp', 'modelo_regulador']);
					break;
				case 'orp':
					data.vars = _.pick(this.device.vars, ['so', 'fo', 'xo', 'om', 'io', 'ho', 'modelo_regulador']);
					break;
				case 'electrolysis':
					data.vars = _.pick(this.device.vars, ['sn', 'sp', 'so', 'sh', 'or', 'ce', 'co', 'sv', 'fs', 'fe']);
					break;
				case 'rcl':
					data.vars = _.pick(this.device.vars, ['sh', 'fh', 'xh', 'hm', 'ih', 'modelo_regulador']);
					break;
				case 'pr3xx':
					data.vars = _.pick(this.device.vars, ['sp', 'fp', 'xp', 'tm', 'ip', 'so', 'fo', 'xo', 'om', 'io', 'sh', 'fh', 'sh', 'hm', 'ih', 'oo', 'op', 'oh']);
					break;
				case 'lumiplus':
					data.vars = _.pick(this.device.vars, ['mu', 'cu', 'su', 'vu', 'tu']);
					break;
			}

			let delay = (+this.device.vars.rp < 30) ? 15000 : 60 * 1500;

			this._devicesSvc.saveSignals(data).then((result: any) => {
				this._notificationSvc.success('', this._translate.instant(result.message));

				if (this._router.url === '/devices/view/' + this.device.id) {
					this.tempValues = null;
					this._optionToEdit = null;
					this.editing(null, false);
					this.saving = false;

					if (this.device.status.value !== 0) {
						this.getDevice(delay);
					}
				}
			}).catch((error: any) => {
				if (error.message) {
					this._notificationSvc.error(this._translate.instant('ERROR'), this._translate.instant('ERROR_PROFESIONAL_PH_ASIGN'));
				}

				if (this._router.url === '/devices/view/' + this.device.id) {
					this.tempValues = null;
					this._optionToEdit = null;
					this.editing();
					this.saving = false;
					this.getDevice(delay);
				}
			});
		}
	}

	public cancelEdit(refresh: boolean = true) {
		// tslint:disable-next-line:forin
		for (let property in this.tempValues) {
			this.device.vars[property] = this.tempValues[property];
		}

		this.edit[this._optionToEdit] = false;
		this._optionToEdit = null;

		if (refresh) {
			this.getRelayStatus();
		}

		this.editModal.hide();
	}

	private _setRegulators(ms: string, regMod: string) {
		ms = ms.substr(0, 3);

		if (ms !== '---' && ms !== '') {
			switch (ms) {
				case '100':
				case '200':
					this.regPh = {
						selected: 0,
						secondReg: '',
						options: ['RPH-201', 'RPH-200']
					};
					break;
				case '110':
				case '220':
					this.regPh = {
						selected: 0,
						secondReg: '',
						options: ['RPH-201', 'RPH-200', 'PR-206']
					};

					if (regMod.indexOf('RMV-201') > -1) {
						this.regPh.secondReg = 'RMV-201';
					} else {
						this.regPh.secondReg = 'RMV-200';
					}
					break;
				case '101':
				case '202':
				case '111':
				case '222':
					this.regPh = {
						selected: 0,
						secondReg: 'PR-204',
						options: ['RPH-201', 'RPH-200', 'PR-207'],
					};
					break;
				default:
					this.regPh = {
						selected: '-',
						secondReg: '',
						options: ['-']
					};
					break;
			}

			switch (ms) {
				case '010':
				case '020':
					this.regOrp = {
						selected: '',
						secondReg: '',
						options: ['RMV-201', 'RMV-200']
					};
					break;
				case '110':
				case '220':
				case '111':
				case '222':
					this.regOrp = {
						selected: 0,
						secondReg: '',
						options: ['RMV-201', 'RMV-200', 'PR-206']
					};

					if (regMod.indexOf('RPH-201') > -1) {
						this.regOrp.secondReg = 'RPH-201';
					} else {
						this.regOrp.secondReg = 'RPH-200';
					}
					break;
				default:
					this.regOrp = {
						selected: '-',
						secondReg: '',
						options: ['-']
					};
					break;
			}

			switch (ms) {
				case '001':
				case '002':
					this.regPpm = {
						selected: 0,
						secondReg: '',
						options: ['RCL-201', 'RCL-200']
					};
					break;
				case '101':
				case '202':
				case '111':
				case '222':
					this.regPpm = {
						selected: '',
						secondReg: '',
						options: ['RCL-201', 'RCL-200', 'PR-207']
					};

					if (regMod.indexOf('RPH-201') > -1) {
						this.regPpm.secondReg = 'RPH-201';
					} else {
						this.regPpm.secondReg = 'RPH-200';
					}
					break;
				default:
					this.regPpm = {
						selected: '-',
						secondReg: '',
						options: ['-']
					};
					break;
			}
		}
	}

	public waitToLoad(wait: boolean) {
		this.waiting = wait;

		if (!wait) {
			if (this.device.status.value !== 0) {
				if (this.getDeviceTimer) {
					clearTimeout(this.getDeviceTimer);
				}

				this.getDevice(3000);
			}
		} else {
			clearTimeout(this._deviceRefresh);
		}
	}

	public changeTimezone(tz: string) {
		const zones = Object.keys(this.timezones[tz]);

		this.device.timezone = zones[0];
	}

	private _getTimezones() {
		for (const zone in this.timezones) {
			if (this.timezones[zone][this.device.timezone]) {
				this.timezone = zone;
				break;
			}
		}
	}

}
