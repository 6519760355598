import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ReCaptcha2Component } from "ngx-captcha";

// services
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DevicesService } from '../../services/devices.service';
import { CaptchaService } from '../../services/captcha.service';


@Component({
	selector: 'login',
	templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
	@ViewChild('reCaptcha') public captcha: ReCaptcha2Component;
	public model: any = {
		username: '',
		password: ''
	};
    public model2fa: any = {
        verification: '',
        code: ''
    }

    public need2fa: boolean = false;
	public type: string = 'password';
	public returnUrl: string;
	public remember: boolean = true;
	public loading: boolean = false;
	public captchaNeeded: boolean = false;
	public captchaValidation: boolean = false;
	public captchaToken: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private _authSvc: AuthService,
		private _deviceSvc: DevicesService,
		private _translate: TranslateService,
		private _notificationService: NotificationsService,
		public captchaSvc: CaptchaService) {
	}

	public ngOnInit() {
		if (this._authSvc.token != null) {
			this.router.navigate(['/devices']);
		}
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	}

	public login() {
		if (this.model.username === '' && this.model.password === '') {
			let message: string;

			message = this._translate.instant('ERR_INTRO_USUARIO') + '<br/>' + this._translate.instant('ERR_INTRO_PASSWORD');
			this._notificationService.html(message, NotificationType.Error);
		} else if (this.model.username === '') {
			this._notificationService.error('', this._translate.instant('ERR_INTRO_USUARIO'));
		} else if (this.model.password === '') {
			this._notificationService.error('', this._translate.instant('ERR_INTRO_PASSWORD'));
		} else {
			this._authSvc.login(this.model.username, this.model.password, this.remember, this.captchaToken).subscribe(
				(data) => {
					this.router.navigate(['/devices']);
				},
				(httpError) => {
					if(this.captchaNeeded) {
						this.captcha.resetCaptcha();
					}
                    if (httpError.status == 406 ){
                        this._notificationService.html(this._translate.instant('YOUR_ADMIN_ACCOUNT_SHOULD_VALIDATE'), NotificationType.Error);
                    } else if(httpError.status == 300) {
                        this._notificationService.html(this._translate.instant('EMAIL_2FA_INFO'), NotificationType.Info);
                    } else if(httpError.status == 303 ){
                        this.need2fa = true;
                        this.model2fa.code = httpError.error.code;
                    } else if(httpError.status == 429){
						this.captchaNeeded = true;
						this._notificationService.html(this._translate.instant('CAPTCHA_NECESARIO'), NotificationType.Error);
					} 
					else if(httpError.status === 409){
						this._notificationService.html(this._translate.instant('ERR_VERIFICACION'), NotificationType.Error);
					}
					else {
						this._notificationService.html(this._translate.instant('ERR_LOGIN'), NotificationType.Error);
					}
				}
			);
		}
	}

    public login2fa() {
        this._authSvc.check2fa(this.model2fa.verification, this.model2fa.code).subscribe(
            (data) => {
                this.router.navigate(['/devices']);
            }, (httpError) => {
                this._notificationService.html(this._translate.instant('INCORRECT_2FA_CODE'), NotificationType.Error);
            })
    }

    public reset2fa() {
        this._authSvc.remove2fa( this.model2fa.code).then(
            (result) => {
                this._notificationService.html(this._translate.instant('SUCCESS_RESTART_2FA'), NotificationType.Success);
				this.comeBackToLogin();
            }
        ).catch(
            (error) => {
                this._notificationService.html(this._translate.instant('ERR_BBDD'), NotificationType.Error);
            }
        );
    }

	public onCaptchaSuccess(token: string) {
		this.captchaToken = token;
		if (this.captchaToken) {
			this.captchaValidation = true;
		}
	}
	
	public comeBackToLogin() {
		this.model2fa.verification = '';
		this.need2fa = false;
	}
}
