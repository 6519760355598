<header ps-header>
	<h2>{{ "CLIENTES" | translate }}</h2>
</header>
<main class="container-fluid">
	<div *ngIf="totalClients">
		<span class="badge badge-info">{{ totalClients }}</span>
		<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
	</div>
	<p></p>
	<table-sorter [disableBtn]="disableButtons" [(page)]="page" [loading]="!clients" [limit]="pagination.limit" [total]="pagination.totalPages" (limitChanged)="pagination.limit = $event; page = 1; loadItems()" (prev)="loadItems($event);" (search)="searchText = $event" (next)="loadItems($event)">
		<table *ngIf="totalClients !== 0" cellspacing="1" class="table table-bordered table-responsive hidden-xs">
			<thead>
				<tr>
					<th ordenable order="nombre" (change)="changeOrder($event)">{{ 'NOMBRE' | translate }}</th>
					<th ordenable order="idLoc" (change)="changeOrder($event)">{{ 'LOCALIDAD' | translate }}</th>
					<th ordenable order="usr" (change)="changeOrder($event)">{{ 'EMAIL' | translate }}</th>
					<th ordenable order="telefono" (change)="changeOrder($event)">{{ 'TELEFONO' | translate }}</th>
					<th class="actions">{{ 'ACCIONES' | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let client of clients; let i = index;">
					<td>
						<div>{{ client.nombre }} {{ client.apellidos }}</div>
					</td>
					<td>
						<div>{{ client.id_loc }}</div>
					</td>
					<td>
						<div>{{ client.usr }}</div>
					</td>
					<td>
						<div>{{ client.telefono }}</div>
					</td>
					<td class="actions">
						<button [disabled]="blockButtons" class="btn" [routerLink]="['/messages/write', client.id]">
							<i class="fa fa-envelope"></i>
						</button>
						<button [disabled]="blockButtons" class="btn" (click)="selectClient(client)">
							<i class="fa fa-chain-broken"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>

		<div *ngIf="totalClients === 0" class="text-center"><h2>{{ 'NO_CLIENTES' | translate }}</h2>
		</div>

		<div *ngIf="totalClients !== 0" class="visible-xs">
			<div *ngFor="let client of clients; let i = index;" class="panel panel-default">
				<div class="panel-body">
					<div>
						<strong>{{ client.nombre }} {{ client.apellidos }}</strong>
					</div>
					<div>
						{{ client.id_loc }}
					</div>
					<div><a href="mailto:{{ client.usr }}">{{ client.usr }}</a></div>
					<div>
						<i class="fa fa-phone fa-fw" aria-hidden="true"></i>
						<a href="tel:{{ client.telefono }}">
							{{ client.telefono }}
						</a>
					</div>
				</div>
				<div class="panel-footer text-center">
					<button [disabled]="blockButtons" class="btn btn-link" [routerLink]="['/messages/write', client.id]">
						<i class="fa fa-envelope fa-fw"></i> {{ 'MENSAJE' | translate }}
					</button>
					<button [disabled]="blockButtons" class="btn btn-link" (click)="selectClient(client)">
						<i class="fa fa-chain-broken fa-fw"></i> {{ 'BT_ELIMINAR' | translate }}
					</button>
				</div>
			</div>
		</div>
	</table-sorter>

	<div bsModal #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button class="close" data-dismiss="modal" aria-hidden="true" (click)="deleteModal.hide()">&times;</button>
				</div>
				<div class="modal-body">
					<p>{{'PREGUNTA_ELIMINAR_USUARIO_MANTENIDO1' | translate}} <strong>{{ selectedClient?.nombre }} {{ selectedClient?.apellidos + ' ' }}</strong> {{'PREGUNTA_ELIMINAR_USUARIO_MANTENIDO2' | translate}}</p>
				</div>
				<div class="modal-footer">
					<button [disabled]="blockButtons" class="btn" title="{{'DESCARTAR_CAMBIOS' | translate}}" data-dismiss="modal" (click)="deleteModal.hide()">{{'BT_CANCELAR' | translate}}</button>
					<button [disabled]="blockButtons" class="btn btn-danger" aria-disabled="false" (click)="delete()">{{'BORRAR' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</main>
