import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// services
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private _router: Router, private _authSvc: AuthService) { }

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		let canPass: boolean = false;
		let urlParts = state.url.split('/');

		if (this._authSvc.user) {
			this._authSvc.checkSession();

			switch (urlParts[1]) {
				case 'messages':
					canPass = [2, 3].indexOf(this._authSvc.user['id_role']) !== -1;
					break;
				case 'users':
				case 'firmwares':
				case 'version':
				case 'advanced':
				case 'imports':
					canPass = [1].indexOf(this._authSvc.user['id_role']) !== -1;
					break;
				case 'professionals':
					canPass = [2].indexOf(this._authSvc.user['id_role']) !== -1;
					break;
				case 'clients':
					canPass = [3].indexOf(this._authSvc.user['id_role']) !== -1;
					break;
				default:
					canPass = true;
					break;
			}
		}

		if (canPass) {
			return true;
		} else {
			this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

			return false;
		}
	}
}
