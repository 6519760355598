import { Component, OnInit } from '@angular/core';

// vendors
import { NotificationsService, NotificationType } from 'angular2-notifications';

// services
import { ProfessionalsService } from '../../services/professionals.service';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'professionals',
	templateUrl: 'professionals.component.html'
})
export class ProfessionalsComponent implements OnInit {
	public items: any[];
	public disableBtn: boolean = false;
	public totalItems: number;
	public professional: any;
	public selectedRequest;
	public page: number = 1;
	public order: any = {
		field: 'fecha',
		direction: 'DESC',
	};
	public pagination = {
		total: 0,
		limit: 50,
		totalPages: 0
	};
	public conditions: any = {
		parameters: {}
	};

	public _searchText: string = '';
	private _searchTimeOut: any = null;

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		if (this._searchTimeOut) {
			clearTimeout(this._searchTimeOut);
		}

		value = value.trim();

		this._searchTimeOut = setTimeout(() => {
			if (value && value !== '') {
				this._searchText = value;
				this.conditions = {
					parameters: {
						search: this._searchText
					},
					professionals: 1
				};
			} else {
				this.conditions = {
					parameters: {},
				};
			}
			this.loadItems();
		}, 500);
	}

	constructor(
		public authSvc: AuthService,
		private _notificationService: NotificationsService,
		private _professionalsSvc: ProfessionalsService,
		private _translate: TranslateService) {
	}

	public ngOnInit() {
		this.loadItems();
	}

	public loadItems(page: number = 1) {
		this.disableBtn = true;

		this._professionalsSvc.find(this.pagination.limit, page, this.conditions, this.order).then((requests: any) => {
			this.items = requests.items;
			this.totalItems = requests.total;
			this.pagination.total = requests.total;
			this.pagination.totalPages = Math.ceil(this.pagination.total / this.pagination.limit);
			this.disableBtn = false;
		}).catch((error) => {
			this.disableBtn = false;
			console.log(error);
		});
	}

	public delete(item) {
		this.disableBtn = true;

		this._professionalsSvc.delete(item.id).then((requests: any) => {
			this.loadItems();
		}).catch((reason) => {
			this._notificationService.html(this._translate.instant('ERROR_PROFESIONAL_DEVICE_LINK'), NotificationType.Error);
			this.disableBtn = false;
		});
	}

	public changeOrder(order: any) {
		this.page = 1;
		this.order.field = order.column;
		this.order.direction = order.direction;
		this.loadItems();
	}
}
