import { Component, OnInit, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

// services
import { VersionsService } from 'app/services/versions.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'pba',
	templateUrl: './pba.component.html',
	styleUrls: ['./pba.component.scss']
})
export class PbaComponent implements OnInit {
	@ViewChild('editPba', {static: true}) editPba;
	@ViewChild('deletePba', {static: true}) deletePba;

	public pbas: any[];
	public tempPba: any = {};
	public totalPbas: number;
	public limit: number = 20;
	public page: number = 1;
	public totalPages: number;
	public conditions: any;
	public disableButtons: boolean = false;

	public pbasFilterValue: any[] = [];
	public pbasFilterOptions: IMultiSelectOption[] = [];
	public pbasFilterSettings: IMultiSelectSettings;
	public pbasFilterTexts: IMultiSelectTexts;
	public pbasFilterValueBack: any;
	public pbasFilterHasAllBefore = true;

	public filtersDelay: any;

	private _searchText: string = '';

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		value = value.trim();

		if (value && value !== '') {
			this._searchText = value;
			this.conditions = {
				conditions: [
					'pba.name LIKE :search OR pba.observations LIKE :search'
				],
				parameters: {
					search: this._searchText
				},
				pbas: (this.pbasFilterValue.indexOf(-1) > -1) ? null : this.pbasFilterValue
			};
		} else {
			this.conditions = {
				conditions: [],
				parameters: {},
				pbas: (this.pbasFilterValue.indexOf(-1) > -1) ? null : this.pbasFilterValue
			};
		}

		this.loadItems();
	}

	constructor(private _versionSvc: VersionsService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService) {
		}

	ngOnInit() {
		this._setFilterData();
	}

	public loadItems(nPage: number = 1) {
		this.disableButtons = true;

		this._versionSvc.findPbas(this.limit, nPage, this.conditions).then((result: any) => {
			this.disableButtons = false;
			this.pbas = result.items;
			this.totalPbas = result.total;
			this.totalPages = Math.ceil(this.totalPbas / this.limit);
			console.log(this.pbas);
		}).catch((error: any) => {
			console.log(error);
			this.disableButtons = false;
			this.loadItems();
		});
	}

	public edit(index: number = null) {
		if (index !== null) {
			this.tempPba = _.clone(this.pbas[index]);
		} else {
			this.tempPba = {
				name: '',
				type: 'POT',
				bootloader: false,
				valid: false,
				image: false,
				observations: ''
			};
		}

		this.editPba.show();
	}

	public save() {
		if (this._checkLength()) {
			this.disableButtons = true;
			this.tempPba.name = this.tempPba.name.trim();

			if (this.tempPba.id) {
				delete (this.tempPba.hardware);
				delete (this.tempPba.firmware);
			}

			this._versionSvc.savePba(this.tempPba).then((result: any) => {
				this.disableButtons = false;
				this.editPba.hide();
				this._notificationSvc.success('', this._translate.instant(result.message));
				this.loadItems(this.page);
			}).catch((error) => {
				this.disableButtons = false;
				this.editPba.hide();

				if (error.message) {
					this._notificationSvc.error('', this._translate.instant(error.message));
				}
				console.log(error);
			});
		} else {
			this.editPba.hide();
		}
	}

	public delete() {
		this.disableButtons = true;

		this._versionSvc.deletePba(this.tempPba.id).then((result: any) => {
			this._notificationSvc.success('', this._translate.instant(result.message));
			this.disableButtons = false;
			this.deletePba.hide();
			this.loadItems(this.page);
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
			}
			this.disableButtons = false;
			this.deletePba.hide();
		});
	}

	public filterChanged() {
		const hasAllPbas: boolean = this.pbasFilterValue.indexOf(-1) > -1;

		if (this.filtersDelay) {
			clearTimeout(this.filtersDelay);
			this.filtersDelay = null;
		}

		if (!hasAllPbas && this.pbasFilterHasAllBefore) {
			this.pbasFilterValue = [];
		} else if (hasAllPbas && !this.pbasFilterHasAllBefore) {
			this.pbasFilterValue = _.clone(this.pbasFilterValueBack);
		}

		if (this.pbasFilterValue.length < this.pbasFilterValueBack.length && hasAllPbas) {
			const index = this.pbasFilterValue.indexOf(-1);
			this.pbasFilterValue.splice(index, 1);
		} else if (this.pbasFilterValue.length === (this.pbasFilterValueBack.length - 1) && !hasAllPbas) {
			this.pbasFilterValue.push(-1);
		}

		this.pbasFilterHasAllBefore = this.pbasFilterValue.indexOf(-1) > -1;

		this.filtersDelay = setTimeout(() => {
			this.searchText = this._searchText;
		}, 750);
	}

	private _setFilterData() {
		// pbasFilter
		this.pbasFilterSettings = {
			checkedStyle: 'fontawesome',
			buttonClasses: 'btn btn-default btn-block',
			itemClasses: '',
			containerClasses: '',
			dynamicTitleMaxItems: 3,
			maxHeight: '300px',
			displayAllSelectedText: true,
			enableSearch: true,
			stopScrollPropagation: true
		};
		this.pbasFilterTexts = Object.assign({}, {
			checkedPlural: this._translate.instant('FILTER_SELECCIONADOS'),
			defaultTitle: this._translate.instant('PBAS'),
			allSelected: this._translate.instant('TODOS'),
			searchPlaceholder: this._translate.instant('BUSCAR'),
			searchEmptyResult: this._translate.instant('SIN_RESULTADOS')
		});

		// obtengo los datos
		this._versionSvc.getPbasData().then((fwData: any) => {
			this.pbasFilterOptions.push({ id: -1, name: this._translate.instant('SELECT_ALL') });
			this.pbasFilterValue.push(-1);

			for (let pba of fwData.pbas) {
				this.pbasFilterValue.push(pba.id);
				this.pbasFilterOptions.push(pba);
			}

			this.pbasFilterValueBack = _.clone(this.pbasFilterValue);

			this.loadItems();
		}).catch((error) => {
			this._notificationSvc.error('', this._translate.instant(error.message));
			this.loadItems();
		});
	}

	private _checkLength(): boolean {
		let ok: boolean = true;

		if (this.tempPba.name.trim().length > 50) {
			this.tempPba = {};
			this._notificationSvc.error('', this._translate.instant('LONGITUD_CAMPO') + '1 - 50');
			ok = false;
		} else if (this.tempPba.name.trim().length === 0) {
			this.tempPba = {};
			this._notificationSvc.error('', this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS'));
			ok = false;
		}

		return ok;
	}
}
