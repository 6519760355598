<header ps-header [device]="device">
	<h2>{{ 'PERSONALIZACION' | translate }}
		<small><strong>{{ device?.ns }}</strong></small>
		<small> - {{ device?.alias }}</small>
	</h2>
</header>
<main class="container">
	<div *ngIf="!device" class="text-center">
		<img src="/assets/images/wave.svg">
	</div>
	<div *ngIf="device">
		<table class="table table-bordered table-striped hidden-xs">
			<thead>
				<tr>
					<th>{{ 'RELES' | translate }}</th>
					<th>{{ 'IMAGEN' |translate }}</th>
					<th>{{ 'NOMBRE' | translate}}</th>
					<th>{{ 'CONSUMO' | translate}}</th>
					<th>{{ 'ACCIONES' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<ng-template ngFor let-i="index" let-relay [ngForOf]="device.relays">
					<tr *ngIf="relay">
						<td><strong>{{ 'RELE' | translate }} {{ i + 1 }}</strong></td>
						<td class="actions"><img [src]="'../../assets/images/device/relay_' + relay.sign + device.series + '.svg'"></td>
						<td>{{ relay.nombre }}</td>
						<td>{{ relay.consumo }} {{ 'WATIOS' | translate }}</td>
						<td class="actions">
							<button [disabled]="blockBtn" class="btn" (click)="selectRelay(i)">
								<i class="fa fa-pencil fa-fw"></i>
							</button>
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>

		<div class="visible-xs">
			<div *ngFor="let relay of device.relays; let i = index;" class="panel panel-default">
				<div *ngIf="relay" class="panel-body">
					<div class="row">
						<div class="col-xs-8">
							<strong>{{ 'RELE' | translate }} {{ i + 1 }}</strong>
							<div>{{ relay.nombre }}</div>
							<div>{{ relay.consumo }} {{ 'WATIOS' | translate }}</div>
						</div>
						<div class="col-xs-4 text-center">
							<img width="60px" [src]="'../../assets/images/device/relay_' + relay.sign + device.series + '.svg'">
						</div>
					</div>
				</div>
				<div class="panel-footer text-center">
					<button [disabled]="blockBtn" class="btn btn-link" (click)="selectRelay(i)">
						<i class="fa fa-pencil fa-fw"></i> {{ 'EDITAR' | translate }}
					</button>
				</div>
			</div>
		</div>

		<fieldset class="form-horizontal">
			<legend>{{ 'CONFIGURACION_DE_TARIFA_ELECTRICA' | translate }}</legend>
			<div class="form-group">
				<label class="col-sm-3 col-lg-2 control-label">{{ 'PRECIO_KWH' | translate }} ( € )</label>
				<div class="col-sm-9 col-lg-10" *ngIf="device">
					<input [disabled]="blockBtn" type="number" class="form-control" min="0" max="20" step=".01" (change)="checkNumber('rate')" [(ngModel)]="device.rate.precio">
				</div>
			</div>
		</fieldset>
		<p>
			<button [disabled]="blockBtn" class="btn btn-primary" (click)="save()"><i *ngIf="saving" class="fa fa-spinner fa-spin fa-fw"></i>{{ 'BT_GUARDAR' | translate }}</button>
			<button [disabled]="blockBtn" class="btn btn-danger" onclick="history.back()" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
		</p>
	</div>

	<div bsModal #saveRelay="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="saveRelay" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">

				<div class="modal-header">
					<button (click)="saveRelayModal.hide()" class="close" aria-hidden="true">&times;</button>
					<h4 class="modal-title">{{ 'CONSUMO_ENERGETICO' | translate }} <small>{{ 'RELE' | translate }} {{ selectedRelay.index  + 1 }}</small></h4>
				</div>

				<div *ngIf="selectedRelay && device" class="modal-body">
					<div class="row">
						<div class="col-sm-3 col-xs-12 text-center">
							<img class="img img-resposive" style="max-height: 128px;" width="100%" [src]="'../../assets/images/device/relay_' + selectedRelay.sign + device.series + '.svg'">
						</div>
						<div class="col-xs-12 col-sm-9">
							<div class="form-group">
								<label>{{ 'NOMBRE' | translate }}</label>
								<input type="text" class="form-control" [(ngModel)]="selectedRelay.nombre">
							</div>
							<div class="form-group">
								<label>{{ 'CONSUMO' | translate }}</label>
								<input type="number" class="form-control" min="0" max="15000" step="10" (change)="checkNumber('power')" [(ngModel)]="selectedRelay.consumo">
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer">
					<button class="btn btn-primary" [disabled]="loggedUser.isDemo" (click)="saveThisRelay()" role="button" aria-disabled="false">{{ 'BT_GUARDAR' | translate }}</button>
					<button class="btn btn-danger" (click)="saveRelayModal.hide()" role="button" aria-disabled="false" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
				</div>

			</div>
		</div>
	</div>

</main>
