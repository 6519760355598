import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

// services
import { AuthService } from '../../../services/auth.service';
import { UsersService } from '../../../services/users.service';
import { RequestsService } from '../../../services/requests.service';
import { MessagesService } from '../../../services/messages.service';

// utils
import * as moment from 'moment-timezone';
import { RolesPipe } from '../../../pipes/roles.pipe';
import ROLES from '../../../entities/roles';

declare var window: any;

@Component({
	// tslint:disable-next-line:component-selector
	selector: '[ps-header]',
	templateUrl: 'ps-header.component.html',
	styleUrls: ['./ps-header.component.scss']
})

export class PSHEaderComponent implements OnInit {
	@Output() menuState = new EventEmitter();
	@Input() device: any;
	@Input() showIcon: boolean = true;

	public loggedUser: any = this._authSvc.user;
	public stateNav: boolean = false;
	public totalPending: number = 0;
	public url: string;
	private _swtLanguage: boolean = true;
	private _language: string;

	get language(): string {
		return this._language;
	}

	set language(value: string) {
		
		switch (value) {
			case 'Aleman':
				this._translate.use('de');
				this._language = 'ALEMAN';
				this._authSvc.language = 'Aleman';
				break;
			case 'Italiano':
				this._translate.use('it');
				this._language = 'ITALIANO';
				this._authSvc.language = 'Italiano';
				break;
			case 'Frances':
				this._translate.use('fr');
				this._language = 'FRANCES';
				this._authSvc.language = 'Frances';
				break;
			case 'Portugues':
				this._translate.use('pt');
				this._language = 'PORTUGUES';
				this._authSvc.language = 'Portugues';
				break;
			case 'Ingles':
				this._translate.use('en');
				this._language = 'INGLES';
				this._authSvc.language = 'Ingles';
				break;
			case 'Holandes':
				this._translate.use('nl');
				this._language = 'NEERLANDES';
				this._authSvc.language = 'Holandes';
				break;
			default:
				this._translate.use('es');
				this._language = 'ESPANYOL';
				this._authSvc.language = 'Castellano'; // lo guarda en local
				break;
		}

		if (this._swtLanguage && this.loggedUser) {
			this._userSvc.setLanguage(value); // lo guarda en BD
		}
	}

	constructor(
		private _requestSvc: RequestsService,
		private _translate: TranslateService,
		private _authSvc: AuthService,
		private _userSvc: UsersService,
		private _router: Router,
		private _messagesSvc: MessagesService) {
	}

	ngOnInit() {
		this._swtLanguage = false;
		this.language = this._authSvc.language;
		this._swtLanguage = true;
		this.loadPendingMessages();
		this.url = this._router.url;
		document.body.classList.remove('noScroll');
	}

	public logout() {
		this._authSvc.logout();
	}

	public changeStateNav() {
		if(this.loggedUser){
			this._userSvc.lastMove().catch((error) => {});
		}
		this.stateNav ? this.closeNav() : this.openNav();
		this.stateNav = !this.stateNav;
		this.stateNav ? document.body.classList.add('noScroll') : document.body.classList.remove('noScroll');
	}

	public openNav() {
		document.getElementById('mySidenav').style.width = '250px';
		document.getElementById('mySidenav').style.visibility = 'visible';
		document.body.classList.add('noScroll');
	}

	public closeNav() {
		document.getElementById('mySidenav').style.width = '0px';
		document.getElementById('mySidenav').style.visibility = 'hidden';
		document.body.classList.remove('noScroll');
	}

	public loadPendingMessages() {
		if (this.loggedUser && (this.loggedUser.id_role === ROLES.PROPIETARIO || this.loggedUser.id_role === ROLES.PROFESIONAL)) {
			this._messagesSvc.pendingMessages().then((result) => {
				this.totalPending = +result;
			}).catch((error) => {
				console.log(error);
			});

			if (this.loggedUser.id_role === ROLES.PROFESIONAL) {
				this._requestSvc.pendingRequests('count').then((requests: any) => {
					this.totalPending += +requests.total;
				}).catch((error) => {
					console.log(error);
				});
			}
		}
	}

	public goBack() {
		let urlParts = this._router.url.split('/');

		switch (urlParts[1]) {
			case 'users':
				if (urlParts[2] === 'edit' && (this.loggedUser.id_role === ROLES.ADMIN || this.loggedUser.id_role === ROLES.SAT)) {
					this._router.navigate(['/users']);
				} else {
					this._router.navigate(['/devices']);
				}
				break;
			case 'stats':
			case 'me':
			case 'advanced':
			case 'version':
			case 'docs':
			case 'imports':
				this._router.navigate(['/devices']);
				break;
			case 'privacy':
			case 'data-protection':
			case 'cookies':
				window.history.back();
				break;
			case 'professionals':
				if (!urlParts[2]) {
					this._router.navigate(['/devices']);
				} else {
					this._router.navigate(['/professionals']);
				}
				break;
			case 'messages':
				if (urlParts[2] === 'view') {
					this._router.navigate(['/messages']);
				} else {
					this._router.navigate(['/devices']);
				}
				break;
			case 'register':
				this._router.navigate(['/']);
				break;
			case 'devices':
				if (urlParts[2] === 'view' || urlParts[2] === 'alarms' || urlParts[2] === 'edit' || urlParts[2] === 'inputs' || urlParts[2] === 'relays' || urlParts[2] === 'add') {
					this._router.navigate(['/devices']);
				} else if (urlParts[2] === 'programmer' || urlParts[2] === 'programs') {
					if (urlParts[3] === 'weekly') {
						this._router.navigate(['/devices/view', urlParts[4]]);
					} else if (urlParts[3] === 'diary') {
						for (let relay of this.device.relays) {
							if (relay.sign === urlParts[5]) {
								this._router.navigate(['/devices/programmer/weekly', this.device.id, relay.id]);
								break;
							}
						}
					}
				} else if (urlParts[2] === 'stat' || urlParts[2] === 'logs') {
					this._router.navigate(['/devices/view', urlParts[3]]);
				} else if (urlParts[2] === 'lumiplus') {
					if (urlParts[3] === 'diary') {
						this._router.navigate(['/devices/lumiplus/weekly', urlParts[4]]);
					} else {
						this._router.navigate(['/devices/view', urlParts[4]]);
					}
				} else if (urlParts[2] === 'suncalc') {
					this._router.navigate(['/devices/view', urlParts[4]]);
				} else if (urlParts[2] === 'sun') {
					this._router.navigate(['/devices/view', urlParts[4]]);
				}
				break;
			default:
				this._router.navigate(['/']);
				break;
		}
	}
}
