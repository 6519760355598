import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';

import { AppConfig } from '../AppConfig';
import { BaseService } from './base.service';
import { Device } from '../entities/device';

// services
import { AuthService } from './auth.service';

// utils
import * as _ from 'lodash';

@Injectable()
export class DevicesService extends BaseService {

	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public find(limit: number = 20, page: number = 1, conditions: any = null, order: any = null, noUser: boolean = false, userSearch: number = null) {
		let offset = (page - 1) * limit;
		let body = null;
		let parameters = [];

		if (conditions) {
			parameters.push('conditions=' + JSON.stringify(conditions));
		}
		if (order) {
			parameters.push('order=' + JSON.stringify(order));
		}
		if (noUser) {
			parameters.push('nouser=1');
		}
		if (userSearch) {
			parameters.push('userSearch=' + userSearch);
		}

		if(parameters.length > 0) {
			body = parameters.join('&');
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/' + limit + '/' + offset, body,{ headers: this.headers }).subscribe((result) => {
				result = result/* .json() */;
				resolve({
					total: result['total'],
					items: _.map(result['items'], o => new Device(o)),
					userSearch: result['userSearch'],
					idcomFirmware: result['idcomFirmware'],
					validFirmwares: result['validFirmwares']
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	public findNoAssociated(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/noassociated', 'id=' + id, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public get(id) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/' + id, null, { headers: this.headers }).subscribe((result) => {
				resolve(new Device(result/* .json() */));
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}
		public getDoesPoolNeedMaintenance(ns) {
				return new Promise((resolve, reject) => {
						this._http.post(`${AppConfig.HOME_API}/devices/${ns}/doesPoolNeedMaintenance`, null, { headers: this.headers }).subscribe((result) => {
								resolve(result);
						}, (error) => {
								console.log(error);
								reject(error);
						});
				});
		}

	public getRelaysStatus(ns) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/relaysstatus/' + ns, null, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public obtainVars(vars, ns) {
		let body: any;

		body = 'vars=' + vars;

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/vars/' + ns, body, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public changeMode(id, mode: string, automatic = 1) {
		let body: string = '';

		mode = mode.trim().toString();

		if (!mode || mode === '') {
			mode = 'eco';
		}

		body = 'id=' + id + '&mode=' + mode + '&automatic=' + automatic;

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/changemode', body, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public setVel(id, mode: string) {
		let body: string = '';

		mode = mode.trim().toString();

		if (!mode || mode === '') {
			mode = 'eco';
		}

		body = 'id=' + id + '&mode=' + mode;

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/setvel', body, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public setDefaultVels(id, data: any) {
		let body: string = '';

		body = 'id=' + id + '&default=' + JSON.stringify(data);

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/setdefaultvel', body, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public upgrade(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/upgrade', 'id=' + id, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public save(data) {
		let typeString: string = '';

		if (data.deviceType) {
			typeString = '&deviceType=' + data.deviceType;
			delete data.deviceType;
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/save', 'data=' + JSON.stringify(data) + typeString, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public saveAliasDeviceForProfessional(deviceId, newAlias) {
		const patchData = {op: 'replace', value: newAlias};
		return new Promise((resolve, reject) => {
			this._http.patch(`${AppConfig.HOME_API}/professionals/device/${deviceId}/alias`,
				'data=' + JSON.stringify(patchData),
				{ headers: this.headers })
			.subscribe(result => {
				resolve(result)
			}, error => {
				reject(error);
			});
		});
	}

	public saveAlarms(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/savealarms', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public associate(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/associate', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

  public associateMAC(data) {
    return new Promise((resolve, reject) => {
      this._http.post(AppConfig.HOME_API + '/devices/associateMAC', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
        resolve(result/* .json() */);
      }, (error) => {
        reject(error/* .json() */);
      });
    });
  }

	public saveRate(rate) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/e-rate/save', 'data=' + rate, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public saveRelay(relay) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/e-rate/saveRelay', 'data=' + JSON.stringify(relay), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public disassociate(ids) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/disassociate', 'data=' + JSON.stringify(ids), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public disableFunction(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/disablefunction', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public saveInterlock(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/saveInterlock', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public saveLumiplusProgram(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/savelumiprogram', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public savePrograms(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/savePrograms', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public saveSign(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/saveSign', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public saveSignals(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/saveSignals', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public getTimezones() {
		return new Promise((resolve, reject) => {
			this._http.get('../../assets/enum/timezones.json').subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public update(data) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/update', 'data=' + JSON.stringify(data), { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public changeAllDevicesMode(mode: string) {
		if (mode !== 'eco' && mode !== 'fast') {
			mode = 'eco';
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/changeallmodes/' + mode, null, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public changeAutoupdate(idDev, activate) {
		let body: any;
		let data = {
			device: idDev,
			activate
		}
		body = 'data=' + JSON.stringify(data);
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/autoupdate', body, {headers: this.headers}).subscribe((result) => {
				resolve(result);
			}, (error) => {
				reject(error);
			})
		});
	}

	public changeOffHours(idDev, userId, hours) {
		let body: any;
		let data = {
			user: userId,
			device: idDev,
			hours
		};

		body = 'data=' + JSON.stringify(data);

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/devices/offhours', body, { headers: this.headers }).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}
}
