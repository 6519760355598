import { Component } from '@angular/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'no-content',
	template: `
  	<header ps-header></header>
	<main class="container-fluid">
    <div>
      <h1>404: page missing</h1>
    </div>
	</main>
	`
})
export class NoContentComponent {

}
