<header ps-header>
	<h2>PoolStation - {{ 'ACC_VERIFICATION' | translate }}</h2>
</header>

<main>
	<div bsModal #loadingModal="bs-modal" class="modal fade" id="loadingModal" tabindex="-1" role="dialog"
		aria-labelledby="loadingModal" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<h2 class="text-center">Cargando</h2>
					<h2 class="text-center"><i class="fa fa-circle-o-notch fa-spin"></i> </h2>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-4 col-sm-offset-4">
			<div class="panel">
				<div class="panel-body">
					<p *ngIf="status == 'verified'" class="text-center">{{('ACC_VERIFICATION_MESSAGE' | translate)}} </p>
					<div *ngIf="status == 'already_verified'" class="alert alert-danger" role="alert">{{ 'ACC_ALREADY_VERIFIED_MESSAGE' | translate }}</div>
					<div *ngIf="status == 'loading'" ><i class="fa fa-circle-o-notch fa-spin"></i></div>
				</div>
				<div class="panel-footer text-center">
					<a class="btn btn-primary" [routerLink]="['/login']">{{ 'BT_ACCEDER' | translate }}</a>
				</div>
			</div>
		</div>
	</div>
</main>