<loading [ngClass]="{'hidden': device}"></loading>
<header ps-header [device]="device" [showIcon]="type === 'weekly' ? true : false">
	<h2>{{ (type === 'weekly' ? 'PROGRAMADOR_SEMANAL' : 'EDITOR_PROGRAMAS') | translate }}
		<small><strong>{{ device?.ns }}</strong></small>
		<small> - {{ device?.alias }}</small>
	</h2>
</header>

<main *ngIf="device" class="container">
	<div [ngSwitch]="type">
		<div *ngSwitchCase="'weekly'">
			<div class="row">
				<div class="col-xs-12 text-right" style="height: 35px">
					<div *ngIf="relaysStatus" class="speedIndicator">
						<div *ngIf="!device.personalized && relaysStatus?.isConnected">
							<span *ngIf="+device.device_ep >= +device.default_eco">
								<i class="fa fa-2x fa-leaf text-success" aria-hidden="true"></i>
								<span><strong>{{ 'MODO_ECO' | translate }}</strong></span>
							</span>
							<span *ngIf="+device.device_ep < +device.default_eco">
								<i class="fa fa-2x fa-fighter-jet text-primary" aria-hidden="true"></i>
								<span><strong>{{ 'MODO_FAST' | translate }}</strong></span>
							</span>
						</div>

						<div *ngIf="device.personalized && relaysStatus?.isConnected">
							<span *ngIf="device.personalized.vel !== device.device_ep && +device.device_ep >= +device.default_eco">
								<i class="fa fa-2x fa-leaf text-success" aria-hidden="true"></i>
								<span><strong>{{ 'MODO_ECO' | translate }}</strong></span>
							</span>
							<span *ngIf="device.personalized.vel !== device.device_ep && +device.device_ep < +device.default_eco">
								<i class="fa fa-2x fa-fighter-jet text-primary" aria-hidden="true"></i>
								<span><strong>{{ 'MODO_FAST' | translate }}</strong></span>
							</span>
							<span *ngIf="device.personalized && device.personalized.vel === device.device_ep">
								<i class="fa fa-2x fa-cogs text-info" aria-hidden="true"></i>
								<span><strong>{{ 'MODO_PERSONALIZADO' | translate }}</strong></span>
							</span>
						</div>

						<span *ngIf="!relaysStatus?.isConnected">
							<i class="fa fa-2x fa-plug text-danger unconected" aria-hidden="true"></i>
							<span class="text-danger"><strong>{{ 'DISPOSITIVO_DESCONECTADO' | translate }}</strong></span>
						</span>
					</div>

					<div *ngIf="!relaysStatus" class="speedIndicator">
						<span>
							<span class="fadeIn text-info"><strong>{{ 'LOADING' | translate }}</strong></span>
						</span>
					</div>
				</div>
				<h4 *ngIf="relay" class="col-xs-4 text-left">
					<img class="icon" [src]="relay.icon" height="32px"> {{ relay.nombre }}
				</h4>
				<div class="col-xs-8 text-right">
					<button [disabled]="(state === '1' && relaysStatus && state !== relaysStatus[relay.sign]) || !relaysStatus?.isConnected || interlock.input !=='0'" class="btn btn-default" [ngClass]="{ 'btn-success': state === '1' && relaysStatus && state === relaysStatus[relay.sign], 'btn-warning fadeIn': state === '1' && relaysStatus && state !== relaysStatus[relay.sign] }" (click)="state = '1'">{{ 'ON' | translate }}</button>
					<button [disabled]="(state === '0' && relaysStatus && state !== relaysStatus[relay.sign]) || !relaysStatus?.isConnected || interlock.input !=='0'" class="btn btn-default" [ngClass]="{ 'btn-danger': state === '0' && relaysStatus && state === relaysStatus[relay.sign], 'btn-warning fadeIn': state === '0' && relaysStatus && state !== relaysStatus[relay.sign] }" (click)="state = '0'">{{ 'OFF' | translate }}</button>
					<button [disabled]="(state === 'A' && relaysStatus && state !== relaysStatus[relay.sign]) || !relaysStatus?.isConnected || interlock.input !=='0'" class="btn btn-default" [ngClass]="{ 'btn-info': state === 'A' && relaysStatus && state === relaysStatus[relay.sign], 'btn-warning fadeIn': state === 'A' && relaysStatus && state !== relaysStatus[relay.sign] }" (click)="state = 'A'">{{ 'AUTO' | translate }}</button>
				</div>
			</div>
			<br>
			<div id="weeklyProgrammer" class="panel" [ngClass]="state === 'A' ? 'panel-info' : 'panel-default'">
				<div class="panel-heading">
					<p class="text-center text-uppercase text-primary md" *ngIf="state === 'A'"><strong>{{ 'AUTO' | translate }}</strong></p>
					<div no-margin class="row">
						<div class="col-xs-1" no-padding>&nbsp;</div>
						<div class="col-xs-8 col-sm-10" no-padding>
							<div class="row">
								<div class="col-xs-2 col-sm-1" [ngClass]="{'hidden-xs': odd}" no-padding *ngFor="let item of [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22]; let odd=odd; let even=even;">
									<span class="header">{{ item + ":00" }}</span>
								</div>
							</div>
						</div>
						<div class="col-xs-1" no-padding>&nbsp;</div>
					</div>
				</div>
				<div no-padding class="panel-body">
					<div no-margin class="row day" *ngFor="let program of programs; let i= index;">
						<div class="col-xs-1 text-right header" no-padding style="position: relative">
							<span class="twilightImage hidden-xs">
								<img *ngIf="program.crepuscular && program.crepuscular[0] === '1'" src="/assets/images/crepuscular.png">
							</span> {{ days[i] | translate }}&nbsp;
						</div>

						<div class="col-xs-8 col-sm-10" no-padding>
							<div class="col-xs-1" no-padding *ngFor="let index of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
								<div class="row fringe" no-margin>
									<span class="cell col-xs-3" no-padding *ngFor="let index2 of [0, 1, 2, 3]" [ngClass]="device.programs[program.number - 1]?.colors[4 * index + index2]"></span>
								</div>
							</div>
						</div>

						<div class="col-xs-3 col-sm-1" no-padding>
							<button [disabled]="hasPro?.permisos !== '222222'" (click)="nextProgram(i)" class="btn btn-primary programSwitcher">{{ 'P' | translate }}{{ program.number }} <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
						</div>
					</div>
				</div>
			</div>
			<div class="text-right">
				<button [disabled]="hasPro?.permisos !== '222222'" class="btn btn-warning" [title]="'EDITOR_PROGRAMAS' | translate" [routerLink]="['/devices/programs', 'diary', device.id, relay.sign]">{{ 'BT_EDITOR' | translate }}</button>
			</div>
			<br>
			<div class="text-center" *ngIf="relay?.sign != 'mu' && programs">
				<table margin-auto style="border-spacing: 5px" id="consumption">
					<tbody style="font-size: 12px">
						<tr>
							<td>
								<span class="dayAbrev"><strong>{{ days[N - 1] | translate }}</strong></span>
							</td>
							<td>
								<div>
									<strong>{{ programs[N - 1].consumption | number : '1.2-2' }}</strong> {{ 'KW' | translate }}
								</div>
								<hr>
								<div>
									<strong>{{ (todayConsumption | number:'1.2') || '?' }}</strong> €
								</div>
							</td>
							<td><strong>{{ 'SEMANALMENTE' | translate }}:</strong></td>
							<td>
								<div>
									<strong>{{ programs  | sumBy : 'consumption' | number : '1.2-2' }}</strong> {{ 'KW' | translate }}
								</div>
								<hr>
								<div>
									<strong>{{ (weekConsumption | number:'1.2') || '?' }}</strong> €
								</div>
							</td>
							<td><strong>{{ 'MENSUALMENTE' | translate }}:</strong></td>
							<td>
								<div>
									<strong>{{ (programs  | sumBy : 'consumption') * 4.28 | number : '1.2-2' }}</strong> {{ 'KW' | translate }}
								</div>
								<hr>
								<div>
									<strong>{{ (monthConsumption | number:'1.2') || '?' }}</strong> €
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="panel panel-default">
				<div class="panel-heading">{{ 'ENCLAVAMIENTO' | translate }}</div>

				<div class="panel-body">
					<div class="row">
						<div class="col-md-4 form-group">
							<label>{{ 'ENTRADA' | translate }} (s)</label>
							<select class="form-control" (change)="getInterlockData($event.target.value)">
								<option value="0" [selected]="interlock.input == 0">--</option>
								<option value="1" [selected]="interlock.input == 1">{{ device.d1_name }}</option>
								<option value="2" [selected]="interlock.input == 2">{{ device.d2_name }}</option>
								<option value="3" [selected]="interlock.input == 3">{{ device.d3_name }}</option>
								<option value="4" [selected]="interlock.input == 4">{{ device.d4_name }}</option>
								<option value="5" [selected]="interlock.input == 5">{{ (device.series === 1) ? device.a1_name : ('TEMPERATURA' | translate) }}</option>
								<option value="6" *ngIf="device.series === 1" [selected]="interlock.input == 6">{{ device.a2_name }}</option>
							</select>
						</div>

						<div class="col-md-4">
							<ng-container *ngIf="(interlock.input === '5' || interlock.input==='6')">
								<div class="row">
									<div class="col-xs-6" *ngIf="device.series === 1">
										<label>{{ 'ESTADO_CONTACTO' | translate }}</label>
										<div class="input-group" style="margin-top: 20px;">
											<span class="input-group-btn">
                        						<button class="btn btn-primary" (click)="calculateSetpoint('less')"><i class="fa fa-minus"></i></button>
                      						</span>
											<input type="text" class="form-control text-center" readonly [value]="interlock.setpointValue + ' ' + interlock.unit">
											<span class="input-group-btn">
                        						<button class="btn btn-primary" (click)="calculateSetpoint('more')"><i class="fa fa-plus"></i></button>
                      						</span>
										</div>
									</div>

									<div class="col-xs-6" *ngIf="device.series === 2">
										<label>{{ 'CONSIGNA' | translate }}</label>
										<div class="input-group" style="margin-top: 20px;">
											<span class="input-group-btn">
                        						<button class="btn btn-primary" (click)="calculateDs2Setpoint('less')"><i class="fa fa-minus"></i></button>
                      						</span>
											<input type="text" class="form-control text-center" readonly [value]="(((interlock.unit === 'ºC') ? (interlock.setpointValue) : (interlock.setpointValue | toFahrenheit)) | number:'1.1-1') + ' ' + interlock.unit">
											<span class="input-group-btn">
                        						<button class="btn btn-primary" (click)="calculateDs2Setpoint('more')"><i class="fa fa-plus"></i></button>
                      						</span>
										</div>
									</div>

									<div class="col-xs-6 form-horizontal">
										<label>{{ 'ACCIONES' | translate }}</label>

										<div class="form-group">
											<label no-padding class="col-xs-2 control-label arrowPosition">
												<i class="fa fa-arrow-up" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i>
											</label>
											<div class="col-xs-10">
												<select [(ngModel)]="interlock.closed" (change)="changeContact('closed')" class="form-control">
													<option value="N">--</option>
													<option value="1">{{ 'ON' | translate }}</option>
													<option value="0">{{ 'OFF' | translate }}</option>
													<option value="A">{{ 'AUTO' | translate }}</option>
												</select>
											</div>
										</div>
										<div class="form-group">
											<label no-padding class="col-xs-2 control-label arrowPosition">
												<i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-chevron-left" aria-hidden="true"></i>
											</label>
											<div class="col-xs-10">
												<select [(ngModel)]="interlock.opened" (change)="changeContact('opened')" class="form-control">
													<option value="N">--</option>
													<option value="1">{{ 'ON' | translate }}</option>
													<option value="0">{{ 'OFF' | translate }}</option>
													<option value="A">{{ 'AUTO' | translate }}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</ng-container>

							<div *ngIf="interlock.input !== '5' && interlock.input !== '6' && interlock.input !== '0'">
								<div>
									<label class="cols-xs-4">{{ 'ESTADO_CONTACTO' | translate }}</label>
									<label class="col-xs-8">{{ 'ACCIONES' | translate }}</label>
								</div>
								<div class="form-horizontal">
									<div class="form-group">
										<label text-center class="col-xs-4 control-label"><img src="/assets/images/programador/contacto_cerrado.png" height="16px"></label>
											<div class="col-xs-8">
												<select [(ngModel)]="interlock.closed" (change)="changeContact('closed')" class="form-control">
													<option value="N">--</option>
													<option value="1">{{ 'ON' | translate }}</option>
													<option value="0">{{ 'OFF' | translate }}</option>
													<option value="A">{{ 'AUTO' | translate }}</option>
												</select>
											</div>
									</div>
								</div>
								<div class="form-horizontal">
									<div class="form-group">
										<label text-center class="col-xs-4 control-label"><img src="/assets/images/programador/contacto_abierto.png" height="16px"></label>
											<div class="col-xs-8">
												<select [(ngModel)]="interlock.opened" (change)="changeContact('opened')" class="form-control">
													<option value="N">--</option>
													<option value="1">{{ 'ON' | translate }}</option>
													<option value="0">{{ 'OFF' | translate }}</option>
													<option value="A">{{ 'AUTO' | translate }}</option>
												</select>
											</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4">
							<div *ngIf="interlock.input === '5' || interlock.input === '6'">
								<div class="form-group" *ngIf="device.series === 1">
									<label>{{ 'VALOR' | translate }} ({{ device[inputUnits[interlock.input]] }})</label>
									<div class="input-group">
										<span class="input-group-btn">
                      						<button class="btn btn-primary" (click)="calculateHysteresis('less')"><i class="fa fa-minus"></i></button>
                    					</span>
										<input type="text" class="form-control text-center" readonly [value]="interlock.hysteresisValue">
										<span class="input-group-btn">
                      						<button class="btn btn-primary" (click)="calculateHysteresis('more')"><i class="fa fa-plus"></i></button>
                    					</span>
									</div>
								</div>

								<div class="form-group" *ngIf="device.series === 2">
									<label>{{ 'HISTERESIS_TEMP' | translate }}</label>
									<div class="input-group">
										<span class="input-group-btn">
                      						<button class="btn btn-primary" (click)="calculateDs2HysteresisTemp('less')"><i class="fa fa-minus"></i></button>
                    					</span>
										<input type="text" class="form-control text-center" readonly [value]="(((interlock.unit === 'ºC') ? (interlock.hysteresisValue) : (interlock.hysteresisValue | toFahrenheit)) | number:'1.1-1') + ' ' + interlock.unit">
										<span class="input-group-btn">
                      						<button class="btn btn-primary" (click)="calculateDs2HysteresisTemp('more')"><i class="fa fa-plus"></i></button>
                    					</span>
									</div>
								</div>
							</div>

							<div *ngIf="interlock.input !== '0'">
								<div class="form-group">
									<label>{{ 'HISTERESIS' | translate }} (s)</label>
									<div class="input-group">
										<span class="input-group-btn">
                      						<button class="btn btn-primary" (click)="programerTime('minus')"><i class="fa fa-minus"></i></button>
                    					</span>
										<input readonly class="form-control text-center" [value]="interlock.hysteresisTime">
										<span class="input-group-btn">
                      						<button class="btn btn-primary" (click)="programerTime('more')"><i class="fa fa-plus"></i></button>
                    					</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p>
				<button [disabled]="disableButtons || device.status.value === 0 || loggedUser.isDemo" class="btn btn-primary" title="{{ 'GUARDAR_CAMBIOS' | translate }}" (click)="save()">{{ 'BT_GUARDAR' | translate }}</button>
				<button [disabled]="disableButtons" class="btn btn-danger" onclick="history.back()" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
			</p>
		</div>

		<div *ngSwitchCase="'diary'" class="diario">
			<div>
				<tabset>
					<tab *ngFor="let program of device.programs; let i = index;" (click)="idSelectedProgram = i">
						<ng-template tabHeading>
							<strong>{{ 'P' + (i + 1) }}</strong>
						</ng-template>
						<table class="table table-bordered table-hover table-responsive programStretches">
							<thead>
								<tr>
									<th>{{ 'INICIO' | translate }}</th>
									<th>{{ 'FIN' | translate }}</th>
									<th class="actions">
										<button [disabled]="disableButtons" class="btn btn-info" (click)="program.addStretch(); program.refresh();" [disabled]="program.stretches.length >= 4"><i class="fa fa-plus" aria-hidden="true"></i></button>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of program.stretches">
									<td no-padding>
										<div class="form-group">
											<label class="col-xs-2 control-label text-right" no-padding><i class="fa fa-clock-o"></i></label>
											<div class="col-xs-10">
												<select [disabled]="disableButtons" class="form-control" [(ngModel)]="item.init" (change)="program.refresh()">
													<option *ngFor="let hour of hours | keys" [value]="hour.key" [disabled]="(hour.key | toNumber) >= (item.end | toNumber)">{{ hour.value }}</option>
												</select>
											</div>
										</div>
									</td>
									<td no-padding>
										<div class="form-group">
											<label class="col-xs-2 control-label text-right" no-padding><i class="fa fa-clock-o"></i></label>
											<div class="col-xs-10">
												<select [disabled]="disableButtons" class="form-control" [(ngModel)]="item.end" (change)="program.refresh()">
													<option *ngFor="let hour of hours | keys" [value]="hour.key" [disabled]="(hour.key | toNumber) <= (item.init | toNumber)">{{ hour.value }}</option>
												</select>
											</div>
										</div>
									</td>
									<td class="text-center actions">
										<button class="btn btn-link btn-danger" (click)="program.deleteStretch(item); program.refresh();"><i class="fa fa-times" aria-hidden="true"></i></button>
									</td>
								</tr>
							</tbody>
						</table>
					</tab>
				</tabset>
				<br>
				<div id="diaryProgrammer" #diaryProgrammer>
					<div class="panel panel-default">
						<div class="panel-heading">
							<div class="row">
								<div no-padding class="col-xs-1">&nbsp;</div>
								<div class="col-xs-11">
									<div class="row">
										<div class="col-xs-3 col-sm-1 col-md-1 header" [ngClass]="{'hidden-xs': item % 3 != 0, 'hidden-sm': item % 2 != 0}" no-padding *ngFor="let item of [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22];">
											<span>{{ item + ":00" }}</span>
										</div>
									</div>
								</div>
								<div class="col-xs-1"></div>
							</div>
						</div>
						<div id="programContainer" class="panel-body">
							<div class="row">
								<div *ngIf="sunriseBarLeft" id="SunriseBar" class="bar twilight" [style.left]="sunriseBarLeft + 'px'">
									<img src="/assets/images/salida-sol.png">



								</div>
									<div *ngIf="sunsetBarLeft" id="SunsetBar" class="bar twilight" [style.left]="sunsetBarLeft + 'px'">
										<img src="/assets/images/puesta-sol.png">



								</div>
										<div no-padding class="col-xs-1 text-right">
											<div position-relative class="title" *ngFor="let program of device.programs; let i=index;">
												<img position-absolute *ngIf="program.twilight.active" src="/assets/images/crepuscular.png">
												<strong class="text-mute">{{ 'P' + (i + 1) }}</strong>&nbsp;&nbsp;
											</div>
										</div>

										<div #programs class="col-xs-11" *ngFor="let program of device.programs; let i=index;" no-padding>
											<div class="col-xs-1" no-padding *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
												<div class="row fringe" no-margin>
													<span class="cell col-xs-3" no-padding *ngFor="let j of [0, 1, 2, 3]" [ngClass]="program.colors[i * 4 + j]"></span>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>

						</div>
						<br>
						<p>
							<button [disabled]="hasPro?.permisos !== '222222' || disableButtons" class="btn btn-warning" (click)="showAdvanced = !showAdvanced; placeTwilightBars()">
								{{ 'FUNCIONES_AVANZADAS' | translate }} <i class="fa" [ngClass]="showAdvanced ? 'fa-chevron-up' : 'fa-chevron-down'" aria-hidden="true"></i>
							</button>
						</p>
						<div #advanced *ngIf="showAdvanced">
							<fieldset>
								<legend>{{ 'SELECCIONE_PROGRAMA' | translate }}</legend>
								<div class="row">
									<div class="col-sm-6 form-inline">
										<div class="form-group switch">
											<label>{{ 'AUTOMATIC' | translate }}</label>
											<ui-switch [disabled]="disableButtons" class="form-control" [(ngModel)]="automatic"></ui-switch>
										</div>
										<div class="form-group">
											<label>{{ 'PROGRAMA' | translate }}</label>
											<select class="form-control" [(ngModel)]="idSelectedProgram" [disabled]="disableButtons">
												<option *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9]" [value]="i - 1">{{ 'P' + i }}</option>
											</select>
										</div>
									</div>
									<div class="col-sm-6 text-right">
										<button [disabled]="disableButtons" class="btn btn-info" [routerLink]="['/docs', 'twilight']">
											<i class="fa fa-img"><img src="../assets/images/info.png"></i>
										</button>
										<button [disabled]="disableButtons" class="btn btn-info" [routerLink]="['/devices/sun/sunrisesunset', device.id]">
											<i class="fa fa-img"><img src="../assets/images/prevision.png"></i>
										</button>
										<button [disabled]="disableButtons" class="btn btn-info" (click)="goToSuncalc()" [title]="'SUNCALC_LINK' | translate">
											<i class="fa fa-img"><img src="../assets/images/suncalc.png"></i>
										</button>
									</div>
								</div>
								<br>
								<div class="title text-center divider">{{ 'SELECCION_DE_MODO' | translate | uppercase }}</div>
								<br>
								<p clickable class="clearfix presetProgram" (click)="selectedProgram.preset = 1" [ngClass]="{'active': selectedProgram.automatic && selectedProgram.preset == 1}">
									<span class="stretch off col-xs-3 text-center">{{ 'OFF' | translate }}</span>
									<span class="stretch on col-xs-6 text-center">{{ 'ON' | translate }}</span>
									<span class="stretch off col-xs-3 text-center">{{ 'OFF' | translate }}</span>
								</p>
								<p clickable class="clearfix presetProgram" (click)="selectedProgram.preset = 2" [ngClass]="{'active': selectedProgram.automatic && selectedProgram.preset == 2}">
									<span class="stretch on col-xs-3 text-center">{{ 'ON' | translate }}</span>
									<span class="stretch off col-xs-6 text-center">{{ 'OFF' | translate }}</span>
									<span class="stretch on col-xs-3 text-center">{{ 'ON' | translate }}</span>
								</p>
								<p clickable class="clearfix presetProgram" (click)="selectedProgram.preset = 3" [ngClass]="{'active': selectedProgram.automatic && selectedProgram.preset == 3}">
									<span class="stretch manual col-xs-3 text-center">
                				<span *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]" class="item"></span>
									</span>
									<span class="stretch on col-xs-6 text-center">{{ 'ON' | translate }}</span>
									<span class="stretch manual col-xs-3 text-center">
                				<span *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]" class="item"></span>
									</span>
								</p>
								<p clickable class="clearfix presetProgram" (click)="selectedProgram.preset = 4" [ngClass]="{'active': selectedProgram.automatic && selectedProgram.preset == 4}">
									<span class="stretch manual col-xs-3 text-center">
                				<span *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]" class="item"></span>
									</span>
									<span class="stretch off col-xs-6 text-center">{{ 'OFF' | translate }}</span>
									<span class="stretch manual col-xs-3 text-center">
                				<span *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]" class="item"></span>
									</span>
								</p>
								<p clickable class="clearfix presetProgram" (click)="selectedProgram.preset = 5" [ngClass]="{'active': selectedProgram.automatic && selectedProgram.preset == 5}">
									<span class="stretch on col-xs-3 text-center">{{ 'ON' | translate }}</span>
									<span class="stretch manual col-xs-6 text-center">
                				<span *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]" class="item"></span>
									</span>
									<span class="stretch on col-xs-3 text-center">{{ 'ON' | translate }}</span>
								</p>
								<p clickable class="clearfix presetProgram" (click)="selectedProgram.preset = 6" [ngClass]="{'active': selectedProgram.automatic && selectedProgram.preset == 6}">
									<span class="stretch off col-xs-3 text-center">{{ 'OFF' | translate }}</span>
									<span class="stretch manual col-xs-6 text-center">
                				<span *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]" class="item"></span>
									</span>
									<span class="stretch off col-xs-3 text-center">{{ 'OFF' | translate }}</span>
								</p>
								<div class="row">
									<div class="col-sm-6">
										<div id="sunrise" class="col-sm-6 offsetBlock">
											<div class="row">
												<div class="col-xs-2">
													<img src="/assets/images/salida-sol.png">



										</div>
													<div class="col-xs-4 text-center">
														<p>{{ 'SUNRISE' | translate | uppercase }}</p>
														<div class="lg">{{ sunrise.time | amDateFormat:'H:mm'}}</div>
													</div>
													<div class="col-xs-6 text-center">
														{{ 'OFFSET' | translate }}
														<div>
															<div class="col-xs-4" no-padding>
																<select [disabled]="disableButtons" class="form-control" [(ngModel)]="selectedProgram.sunriseOffsetSign">
																	<option value="0">+</option>
																	<option value="1">-</option>
																</select>
															</div>
															<div class="col-xs-4" no-padding>
																<select [disabled]="disableButtons" class="form-control" [(ngModel)]="selectedProgram.sunriseOffsetHours">
																	<option *ngFor="let hour of [0, 1, 2, 3]" [value]="hour">{{ hour }}</option>
																</select>
															</div>
															<div class="col-xs-4" no-padding>
																<select [disabled]="disableButtons" class="form-control" [(ngModel)]="selectedProgram.sunriseOffsetMinutes">
																	<option *ngFor="let quarter of [0, 15, 30, 45]" [value]="quarter">{{ quarter | number:'2.0-0' }}</option>
																</select>
															</div>
														</div>
														<div>
															<small>{{ 'OFFSET_SUNRISE' | translate }} <strong>{{ selectedProgram.sunriseWithOffset | amDateFormat:'H:mm'}}</strong></small>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-6">
											<div id="sunset" class="col-sm-6 offsetBlock">
												<div class="row">
													<div class="col-xs-2">
														<img src="/assets/images/puesta-sol.png">



										</div>
														<div class="col-xs-4 text-center">
															<p>{{ 'SUNSET' | translate | uppercase }}</p>
															<div class="lg">{{ sunset.time | amDateFormat:'H:mm'}}</div>
														</div>
														<div class="col-xs-6 text-center">
															{{ 'OFFSET' | translate }}
															<div>
																<div class="col-xs-4" no-padding>
																	<select [disabled]="disableButtons" class="form-control" [(ngModel)]="selectedProgram.sunsetOffsetSign">
																		<option value="0">+</option>
																		<option value="1">-</option>
																	</select>
																</div>
																<div class="col-xs-4" no-padding>
																	<select [disabled]="disableButtons" class="form-control" [(ngModel)]="selectedProgram.sunsetOffsetHours">
																		<option *ngFor="let hour of [0, 1, 2, 3]" [value]="hour">{{ hour }}</option>
																	</select>
																</div>
																<div class="col-xs-4" no-padding>
																	<select [disabled]="disableButtons" class="form-control" [(ngModel)]="selectedProgram.sunsetOffsetMinutes">
																		<option *ngFor="let quarter of [0, 15, 30, 45]" [value]="quarter">{{ quarter | number:'2.0-0' }}</option>
																	</select>
																</div>
															</div>
															<div>
																<small>{{ 'OFFSET_SUNSET' | translate }} <strong>{{ selectedProgram.sunsetWithOffset | amDateFormat:'H:mm'}}</strong></small>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row timezone">
											<div class="col-xs-12">
												<small><strong>{{ 'ZONA_HORARIA' | translate }}:</strong> {{ device.timezone }} <strong>{{ 'LOCALIDAD' | translate }}:</strong> {{ device.id_loc }}</small>
											</div>
										</div>
										<br>
							</fieldset>
						</div>
					</div>
					<div>
						<button [disabled]="(this.loggedUser.id_role === 3 && this.device.user && this.loggedUser.id_user !== this.device.user.id) ?
						 (disableButtons || loggedUser.isDemo || (device.status.value === 0 && hasPro?.permisos !== '222222')) : (disableButtons || device.status.value === 0 || loggedUser.isDemo)"
								class="btn btn-primary" title="{{ 'GUARDAR_CAMBIOS' | translate }}" (click)="savePrograms()">{{ 'BT_GUARDAR' | translate }}</button>
						<button [disabled]="disableButtons" class="btn btn-danger" onclick="history.back()" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_VOLVER' | translate }}</button>
					</div>
				</div>
			</div>
</main>
