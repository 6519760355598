<tabset *ngIf="device" id="start" #dynamicTabs>
	<tab>
		<ng-template tabHeading>
			<div class="homeTab" (click)="activateTab()"><i class="fa fa-home"></i></div>
		</ng-template>
		<div class="panel panel-default">
			<div class="panel-body" style="position: relative">
				<div class="text-center hidden-xs" style="position: absolute; top: 20px; right: 20px">
					<button class="btn btn-xs btn-primary" (click)="scroll('historics')">
						<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_historialB.png"></i>
					</button>
					<button class="btn btn-xs btn-primary" (click)="scroll('relays')">
						<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/rele-iconoB.png"></i>
					</button>
					<button class="btn btn-xs btn-primary" (click)="infoModal.show()">
						<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/info-ds2.png"></i>
					</button>
				</div>
				<div>
					<!-- PRIMERA FILA -->
					<div class="row">
						<div class="col-xs-12 text-center visible-xs">
							<a class="btn btn-xs btn-primary" (click)="scroll('historics')">
								<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_historialB.png"></i>
							</a>
							<button class="btn btn-xs btn-primary" (click)="scroll('relays')">
								<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/rele-iconoB.png"></i>
							</button>
							<button class="btn btn-xs btn-primary" (click)="infoModal.show()">
								<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/info-ds2.png"></i>
							</button>
						</div>
						<div class="row circles">

							<div *ngIf="device.isElectrolysis && device.isUltraviolet" class="round-progress-container">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) || (device.status.value === 0 && !deviceOffHours.limitReached) ? pa : 0" [max]="100" color="#45ccce" background='#eaeaea' [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p class="xlg" (click)="activateTab('electrolysis')">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) || (device.status.value === 0 && !deviceOffHours.limitReached) ? pa +"%" : '-' }}</p>
										<p (click)="activateTab('electrolysis')">{{ sc }}%</p>
										<div (click)="activateTab('uv')">
											<progressbar class="active" max="13000" [value]="hu"></progressbar>
											<span>{{ hu }}h ({{ uvPercent }}%)</span>
										</div>
									</div>
								</div>
							</div>

							<div class="round-progress-container" *ngIf="!device.isElectrolysis && device.isUltraviolet" (click)="activateTab('uv')">
								<round-progress [current]="100" [max]="100" color="#D400FF" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p class="xlg">UV</p>
										<div>
											<progressbar class="active" max="13000" [value]="hu"></progressbar>
											<span>{{ hu }}h ({{ uvPercent }}%)</span>
										</div>
									</div>
								</div>
							</div>

							<div class="round-progress-container" *ngIf="device.isElectrolysis && !device.isUltraviolet" (click)="activateTab('electrolysis')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? pa : 0" [max]="100" color="#45ccce" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? pa +"%" : '-' }}</p>
										<p>{{ sc }}%</p>
									</div>
								</div>
							</div>

							<div class="round-progress-container" *ngIf="!device.isElectrolysis && !device.isUltraviolet && device.isPH" (click)="activateTab('ph')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mp - 6.5) : 0" [max]="2" color="#007FFF" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>pH</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wp === 'I' ? 'INIT' : (mp | number: '1.2-2')) : '-' }}</p>
										<p>{{ sp | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>

							<div *ngIf="!device.isPH && device.isPPM && device.isORP && !device.isElectrolysis && !device.isUltraviolet" class="round-progress-container" (click)="activateTab('mv')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mo - 650) : 0" [max]="200" color="#D9322A" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clmv</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wo === 'I' ? 'INIT' : mo) : '-' }}</p>
										<p>{{ so }}</p>
									</div>
								</div>
							</div>

						</div>
					</div>
					<!-- SEGUNDA FILA -->
					<div class="row">
						<div *ngIf="device.isPH && (device.isElectrolysis || device.isUltraviolet)" [class]="device.isORP || device.isPPM ? 'col-sm-6' : ['col-sm-6', 'col-sm-offset-3']">
							<div class="round-progress-container" (click)="activateTab('ph')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mp - 6.5) : 0" [max]="2" color="#007FFF" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>pH</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wp === 'I' ? 'INIT' : (mp | number: '1.2-2')) : '-' }}</p>
										<p>{{ sp | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="device.isPH && device.isPPM && (device.isElectrolysis || device.isUltraviolet)" class="col-sm-6">
							<div class="round-progress-container" (click)="activateTab('ppm')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? mh : 0" [max]="5" color="#239B56" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clppm</p>
										<p class="xlg">{{ (device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.wh === 'I' ? 'INIT' : (mh | number: '1.2-2')) : '-' }}</p>
										<p>{{ sh | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="device.isPH && device.isPPM && !device.isORP && !device.isElectrolysis && !device.isUltraviolet" class="col-sm-6 col-sm-offset-3">
							<div class="round-progress-container" (click)="activateTab('ppm')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? mh : 0" [max]="5" color="#239B56" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clppm</p>
										<p class="xlg">{{ (device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.wh === 'I' ? 'INIT' : (mh | number: '1.2-2')) : '-' }}</p>
										<p>{{ sh | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="device.isPH && !device.isPPM && device.isORP && !device.isElectrolysis && !device.isUltraviolet" class="col-sm-6 col-sm-offset-3">
							<div class="round-progress-container" (click)="activateTab('mv')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mo - 650) : 0" [max]="200" color="#D9322A" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clmv</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wo === 'I' ? 'INIT' : mo) : '-' }}</p>
										<p>{{ so }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="!device.isPH && device.isPPM && !device.isORP && !device.isElectrolysis && !device.isUltraviolet" class="col-sm-6 col-sm-offset-3">
							<div class="round-progress-container" (click)="activateTab('ppm')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? mh : 0" [max]="5" color="#239B56" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clppm</p>
										<p class="xlg">{{ (device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.wh === 'I' ? 'INIT' : (mh | number: '1.2-2')) : '-' }}</p>
										<p>{{ sh | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="!device.isPH && !device.isPPM && device.isORP && !device.isElectrolysis && !device.isUltraviolet" class="col-sm-6 col-sm-offset-3">
							<div class="round-progress-container" (click)="activateTab('mv')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mo - 650) : 0" [max]="200" color="#D9322A" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clmv</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wo === 'I' ? 'INIT' : mo) : '-' }}</p>
										<p>{{ so }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="device.isPH && device.isORP && (device.isElectrolysis || device.isUltraviolet) && !device.isPPM" class="col-sm-6">
							<div class="round-progress-container" (click)="activateTab('mv')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mo - 650) : 0" [max]="200" color="#D9322A" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clmv</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wo === 'I' ? 'INIT' : mo) : '-' }}</p>
										<p>{{ so }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="device.isPH && device.isPPM && device.isORP && !device.isElectrolysis && !device.isUltraviolet" class="col-sm-6">
							<div class="round-progress-container" (click)="activateTab('ppm')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? mh : 0" [max]="5" color="#239B56" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clppm</p>
										<p class="xlg">{{ (device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.wh === 'I' ? 'INIT' : (mh | number: '1.2-2')) : '-' }}</p>
										<p>{{ sh | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="device.isPH && device.isPPM && device.isORP && !device.isElectrolysis && !device.isUltraviolet" class="col-sm-6">
							<div class="round-progress-container" (click)="activateTab('mv')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mo - 650) : 0" [max]="200" color="#D9322A" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clmv</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wo === 'I' ? 'INIT' : mo) : '-' }}</p>
										<p>{{ so }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="!device.isPH && device.isPPM && (device.isElectrolysis || device.isUltraviolet)" [ngClass]="{'col-sm-offset-3' : !device.isORP}" class="col-sm-6">
							<div class="round-progress-container" (click)="activateTab('ppm')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? mh : 0" [max]="5" color="#239B56" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clppm</p>
										<p class="xlg">{{ (device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.wh === 'I' ? 'INIT' : (mh | number: '1.2-2')) : '-' }}</p>
										<p>{{ sh | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="!device.isPH && device.isORP && (device.isElectrolysis || device.isUltraviolet)" [ngClass]="{'col-sm-offset-3' : !device.isPPM}" class="col-sm-6">
							<div class="round-progress-container" (click)="activateTab('mv')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (mo - 650) : 0" [max]="200" color="#D9322A" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clmv</p>
										<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (device.vars.wo === 'I' ? 'INIT' : mo) : '-' }}</p>
										<p>{{ so }}</p>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="!device.isPH  && device.isORP && device.isPPM && !device.isElectrolysis && !device.isUltraviolet" class="col-sm-6 col-sm-offset-3">
							<div class="round-progress-container" (click)="activateTab('ppm')">
								<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? mh : 0" [max]="5" color="#239B56" background="#eaeaea" [radius]="128" [stroke]="20" [rounded]="true" [duration]="1024" animation='easeInOutQuart' [animationDelay]="1024"></round-progress>
								<div class="round-progress-content">
									<div class="round-progress-info">
										<p>Clppm</p>
										<p class="xlg">{{ (device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached)) ? (device.vars.wh === 'I' ? 'INIT' : (mh | number: '1.2-2')) : '-' }}</p>
										<p>{{ sh | number: '1.2-2' }}</p>
									</div>
								</div>
							</div>
						</div>

					</div>
					<!-- TERCERA FILA -->
					<div class="row">
						<div *ngIf="!device.isElectrolysis" class="round-progress-container" (click)="activateTab('sensors')">
							<round-progress [current]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? taRanges.value : 0" [max]="(taRanges.unit === 'C') ? 40 : 104" color="#FF6E40" background="#eaeaea" [rounded]="true" [radius]="128" [animation]="'easeInOutQuart'" [animationDelay]="1024"></round-progress>
							<div class="round-progress-content">
								<div class="round-progress-info">
									<p class="xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (taRanges.value | number: '1.1-1') : '-' }}</p>
									<p>{{ taRanges.unit === 'C' ? 'ºC' : 'ºF' }}</p>
								</div>
							</div>
						</div>

						<div class="doubleBar" *ngIf="device.isElectrolysis">
							<double-bar [temp]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? device.vars.ta : 0" [cnd]="device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) && device.specialStatus.prodUp30 ? device.vars.cn : 0" (click)="activateTab('sensors')">
								<div class="round-progress-content-sensors">
									<div class="round-progress-info">
										<p class="colorFont xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 && !deviceOffHours.limitReached) ? (taRanges.value | number: '1.1-1') : '-' }}</p>
										<p>{{ taRanges.unit === 'C' ? 'ºC' : 'ºF' }}</p>
									</div>
									<div *ngIf="device.isElectrolysis" class="round-progress-info">
										<p class="colorFont xlg">{{ device.status.value !== 0 && device.specialStatus.flow || (device.status.value === 0 &&
												!deviceOffHours.limitReached) && device.specialStatus.prodUp30 ? (cn | number: '1.2') : '-' }}</p>
										<p>{{ 'g/L'}}</p>
									</div>
								</div>
							</double-bar>
						</div>
					</div>
				</div>
			</div>
		</div>
	</tab>

	<tab *ngIf="device.isElectrolysis" heading="%" [active]="tabs.electrolysis" (select)="scroll('tabs')">
		<div class="panel panel-default">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-6 text-left"><strong>{{ device.abbreviationModel }}</strong></div>
					<div class="col-xs-6 text-right"><strong>{{ (device.vars.ce === 'T') ? 'TEST' : device.vars.ce + 'h.' + device.vars.ce + 'h' }}</strong></div>
				</div>
				<div class="text-center forInputNumber wrapperInputNumber">
					<span class="spanSet">SET</span>
					<input-number [block]="device.status.value === 0" [value]="sn" step="1" size="xl" min="0" max="100" precision="0" (exit)="sn = $event"></input-number>
				</div>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<spinner stroke="#43a9c9" [clockwise]="device.vars.cd === '0'" [rotate]="device.status.value !== 0 && (device.vars.pa !==
								'000' && !deviceOffHours.limitReached && !(device.vars.sf === 0 && device.vars.os === 1))">
									{{ device.vars.cd === '0' ? 'DIR' : 'REV' }}
								</spinner>
							</td>
							<td>
								<div *ngIf="device.vars.ac !== '0'">
									<div *ngIf="device.vars.sf === '1'" class="text-danger">
										<div>{{ 'FLOW' | translate }}</div>
										<div>0%</div>
									</div>
									<ng-container *ngIf="device.vars.sf === '0'">
										<div *ngIf="device.vars.os === '1'" class="text-danger">
											<div>STOP CI</div>
											<div>0%</div>
										</div>
										<ng-container *ngIf="device.vars.os === '0'">
											<div *ngIf="device.vars.cv === 1">COVER</div>
											<div *ngIf="device.vars.cv === 0">PROD</div>
											<div>{{ (device.vars.pa && (device.status.value !== 0  || (device.status.value === 0 && !deviceOffHours.limitReached)) && device.specialStatus.flow) ? (device.vars.pa | number: '1.0-0') : '-' }}%</div>
										</ng-container>
									</ng-container>
								</div>
								<div *ngIf="device.vars.ac === '0'">
									<div>-</div>
									<div>&nbsp;</div>
								</div>
							</td>
							<td>
								<div [ngClass]="{'text-muted' : !device.booleanVars.ee && !device.booleanVars.ei && device.vars.eg === '0000' }">AUTO</div>
								<div>
									<span *ngIf="!device.booleanVars.ee" class="text-muted">Ext-</span>
									<span *ngIf="device.booleanVars.ee" [ngClass]="{'text-danger' : device.booleanVars.se}">Ext-</span>
									<span *ngIf="!device.booleanVars.ei" class="text-muted">Int-</span>
									<span *ngIf="device.booleanVars.ei" [ngClass]="{'text-danger' :device.booleanVars.si}">Int-</span>
									<span *ngIf="device.vars.eg === '0000'" class="text-muted">gr/d</span>
									<span *ngIf="device.vars.eg !== '0000'" [ngClass]="{'text-danger' :device.booleanVars.sg}">gr/d</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<hr>
				<div class="row text-center">
					<table>
						<tbody>
							<tr>
								<td>
									<div>
										<span *ngIf="device.vars.sf !== '0'" [ngClass]="{'text-danger' : device.vars.sf === '1'}"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'FLOW' | translate }}</span>
										<span *ngIf="device.vars.sf === '0'">
											<ng-container *ngIf="!device.booleanVars.fe && !device.vars.fs && !device.vars.fd">{{ 'FLOW' | translate }} {{ 'OFF' | translate }}</ng-container>
											<ng-container *ngIf="device.booleanVars.fe || device.vars.fs || device.vars.fd">{{ 'FLOW' | translate }} {{ 'OK' | translate }}</ng-container>
										</span>
									</div>
									<div>
										<span *ngIf="!device.booleanVars.fe" class="text-muted">FE,</span>
										<span *ngIf="device.booleanVars.fe && !device.booleanVars.ae">FE,</span>
										<span *ngIf="device.booleanVars.fe && device.booleanVars.ae" class="text-danger">FE,</span>
										<span *ngIf="!device.vars.fs" class="text-muted">FS,</span>
										<span *ngIf="device.vars.fs && !device.vars.aw">FS,</span>
										<span *ngIf="device.vars.fs && device.vars.aw" class="text-danger">FS,</span>
									</div>
								</td>
								<td>
									<i class="fa fa-clock-o fa-fw" aria-hidden="true"></i> <span class="text-info"> {{ (device.vars.te && device.vars.te !== '------') ? (device.vars.te | number: '1.0-0') : '0' }}h / {{ (device.vars.tt && device.vars.tt !== '------') ? (device.vars.tt | number: '1.0-0') : '0' }}h</span>
								</td>
							</tr>
							<tr>
								<td>
									<span *ngIf="device.vars.ws === '0'">{{ 'OK' | translate }} {{ 'CONDUCTIVIDAD' | translate }}</span>
									<div *ngIf="device.vars.ws !== '0'" class="text-danger">
										{{ ((device.vars.ws === 'L' || device.vars.ws === 'N') ? 'LOW' : 'HIGH') | translate | uppercase }}
										<p><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'CONDUCTIVIDAD' }}</p>
									</div>
								</td>
								<td>
									<div>
										{{ 'COVER' | translate }}: <small class="text-muted">{{ (device.vars.co !== '0' && device.vars.co !== '-' ? 'ACTIVADA' : 'DESACTIVADA') | translate }}</small>
									</div>
									{{ 'COVER' | translate }} {{ (device.vars.co !== '0' && device.vars.co !== '-' && device.vars.sv !== '---') ? ((device.vars.sv | number: '1.0-0') + '%') : ('OFF' | translate) }}
								</td>
							</tr>
							<tr>
								<td>
									<p *ngIf="device.vars.cc === '0'">{{ 'OK' | translate }} {{ 'CELULA' | translate }}</p>
									<p class="text-danger" *ngIf="device.vars.cc === '1'"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'CELULA' | translate }}</p>
								</td>
								<td>
									grCl/d {{ (device.vars.ge && device.vars.ge !== '----') ? (device.vars.ge | number: '1.0-0') : '-' }}/{{ (device.vars.eg && device.vars.eg !== '----') ? (device.vars.eg === '0000' ? 'off' : device.vars.eg | number: '1.0-0') : '-' }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="text-right">
					<button [disabled]="device.status.value === 0 || checkSave" class="btn btn-default" (click)="swt = !swt"><i class="fa fa-bars" aria-hidden="true"></i></button>
				</div>
				<div class="panel" *ngIf="swt">
					<div class="panel-body options electrolysis">
						<div class="form-horizontal">
							<div class="form-group">
								<label class="col-xs-2 text-left">
									{{ (device.vars.ce === 'T') ? 'TEST' : device.vars.ce + 'h.' + device.vars.ce + 'h' }}
								</label>
								<div class="col-xs-10 text-right">
									<div class="btn-group">
										<button type="button" class="btn" [disabled]="checkSave" [ngClass]="(deviceTmp.vars.ce === '2') ? 'btn-primary' : 'btn-default'" (click)="change('ce', '2')">2H</button>
										<button type="button" class="btn" [disabled]="checkSave" [ngClass]="(deviceTmp.vars.ce === '3') ? 'btn-primary' : 'btn-default'" (click)="change('ce', '3')">3H</button>
										<button type="button" class="btn" [disabled]="checkSave" [ngClass]="(deviceTmp.vars.ce === '4') ? 'btn-primary' : 'btn-default'" (click)="change('ce', '4')">4H</button>
									</div>
								</div>
							</div>
							<div class="form-group switch">
								<label class="col-xs-9 text-left">AUTO CL EXT</label>
								<div class="col-xs-3">
									<ui-switch class="form-control" [disabled]="checkSave" [(ngModel)]="ee" color="#43a9c9"></ui-switch>
								</div>
							</div>
							<div class="form-group switch" *ngIf="device.isORP || device.isPPM">
								<label class="col-xs-9 text-left">AUTO CL INT</label>
								<div class="col-xs-3">
									<ui-switch class="form-control" [disabled]="checkSave" [(ngModel)]="ei" color="#43a9c9"></ui-switch>
								</div>
							</div>
							<div class="form-group switch">
								<label class="col-xs-9 text-left">AUTO CL GR/D</label>
								<div class="col-xs-3">
									<ui-switch class="form-control" [disabled]="checkSave" [(ngModel)]="clGrD" color="#43a9c9"></ui-switch>
								</div>
							</div>
							<div class="form-group switch">
								<label class="col-xs-6 text-left">AUTO CL GR/D</label>
								<div class="col-xs-6">
									<input-number class="form-control" [block]="!tmpEg || checkSave" [nullValue]="(tmpEg) ? false : true" [value]="tmpEg" size="sm" step="1" min="1" [max]="grdLimit" precision="0" append="{{ (tmpEg) ? '' : ('OFF' | translate) }}" (exit)="tmpEg = $event" [block]="!clGrD"></input-number>
								</div>
							</div>
							<div class="form-group switch">
								<label class="col-xs-9 text-left">{{ 'COVER' | translate }}</label>
								<div class="col-xs-3">
									<ui-switch class="form-control" [disabled]="checkSave" [(ngModel)]="tmpCo" color="#43a9c9"></ui-switch>
								</div>
							</div>
							<div class="form-group switch">
								<label class="col-xs-6 text-left">{{ 'COVER' | translate }}</label>
								<div class="col-xs-6">
									<input-number class="form-control" [block]="!tmpCo || checkSave" [nullValue]="(tmpCo) ? false : true" [value]="tmpSv" step="1" min="10" max="100" precision="0" append="{{ (tmpCo) ? '%' : ('OFF' | translate) }}" size="sm" (exit)="tmpSv = $event"></input-number>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-footer text-center">
						<button class="btn btn-link btn-success" (click)="save()" [disabled]="loading || device.status.value === 0 || !changed.edited || checkSave">
							<i *ngIf="!this.loading && !checkSave" class="fa fa-floppy-o" aria-hidden="true"></i>
							<i *ngIf="this.loading || checkSave" class="fa fa-spin fa-refresh" aria-hidden="true"></i> {{ 'BT_GUARDAR' | translate }}
						</button>
						<button class="btn btn-link btn-danger" [disabled]="loading || checkSave" (click)="swt = false">{{ 'BT_CANCELAR' | translate }}</button>
					</div>
				</div>
			</div>
		</div>
	</tab>

	<tab *ngIf="device.isUltraviolet" heading='UV' [active]="tabs.uv" (select)="scroll('tabs')">
		<div class="panel panel-default">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 text-left"><strong>{{ device.abbreviationModel }}</strong></div>
				</div>
				<br>
				<div>
					<progressbar class="active" max="13000" [value]="device.vars.hu"><i>{{ (device.vars.hu && device.vars.hu !== '------') ? (device.vars.hu  | number: '1.0-0') : 0 }} / 13000</i></progressbar>
				</div>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<spinner [rotate]="device.booleanVars.lu && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))" [stroke]="device.booleanVars.lu ? '#43a9c9' : '#000000'">UV</spinner>
							</td>
							<td [ngClass]="{'text-success' : device.booleanVars.lu && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))}">
								<p id="luLabel">{{ (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached)) ? ((device.booleanVars.lu) ? 'ON' : 'OFF') : '-' }}</p>
							</td>
						</tr>
					</tbody>
				</table>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<div *ngIf="device.vars.sf !== '0'">
									<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
									{{ 'FLOW' | translate }}
								</div>
								<div *ngIf="device.vars.sf === '0'">
									<span *ngIf="!device.booleanVars.fe && !device.vars.fs && !device.vars.fd">{{ 'FLOW' | translate }} {{ 'OFF' | translate }}</span>
									<span *ngIf="device.booleanVars.fe || device.vars.fs || device.vars.fd">{{ 'FLOW' | translate }} {{ 'OK' | translate }}</span>
								</div>
								<span *ngIf="!device.booleanVars.fe" class="text-muted">FE,</span>
								<span *ngIf="device.booleanVars.fe && !device.booleanVars.ae">FE,</span>
								<span *ngIf="device.booleanVars.fe && device.booleanVars.ae" class="text-danger">FE,</span>
								<span *ngIf="!device.vars.fs" class="text-muted">FS,</span>
								<span *ngIf="device.vars.fs && !device.vars.aw">FS,</span>
								<span *ngIf="device.vars.fs && device.vars.aw" class="text-danger">FS,</span>
							</td>
							<td>RESETS <span class="text-info">{{ (device.vars.ru && device.vars.ru !== '----') ? (device.vars.ru | number: '1.0-0') : 0 }}</span></td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.booleanVars.fu" class="text-danger">
									<i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> FUSE
								</div>
								<div *ngIf="!device.booleanVars.fu">FUSE OK</div>
							</td>
							<td>
								IGNICIONES
								<span class="text-info">{{ (device.vars.iu && device.vars.iu !== '------') ? (device.vars.iu | number: '1.0-0') : '-' }} / {{ (device.vars.nu && device.vars.nu !== '------') ? (device.vars.nu | number: '1.0-0') : '-' }}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span *ngIf="device.booleanVars.bu" class="text-danger">
									<i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i> {{ 'BALLAST' | translate }}
								</span>
								<span *ngIf="!device.booleanVars.bu">{{ 'BALLAST' | translate }} {{ 'OK' | translate }}</span>
							</td>
							<td>
								<i class="fa fa-clock-o fa-fw" aria-hidden="true"></i> <span class="text-info">{{ (device.vars.hu && device.vars.hu !== '------') ? (device.vars.hu | number: '1.0-0') : '0' }}h / {{ (device.vars.xu && device.vars.xu !== '------') ? (device.vars.xu | number: '1.0-0') : '0' }}h</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</tab>

	<tab *ngIf="device.isPH" [heading]="'PH' | translate" [active]="tabs.ph" (select)="scroll('tabs')">
		<div class="panel panel-default">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-6 text-left"><strong>{{ device.abbreviationModel }}</strong></div>
					<div class="col-xs-6 text-right"><strong>{{ device.vars.ap === '0' ? 'pH-' : 'pH+' }}</strong></div>
				</div>
				<div class="text-center forInputNumber wrapperInputNumber">
					<span class="spanSet">SET</span>
					<input-number [block]="device.status.value === 0" [value]="sp" size="xl" step="0.01" min="7" max="7.8" precision="2" (exit)="sp = $event"></input-number>
				</div>
					<hr>
				<table>
					<tbody>
						<tr>
							<td [ngClass]="((device.vars.fp === 'A' && device.vars.gp === '1') || device.vars.fp === 'D') ? 'text-primary' : 'text-muted'">
								<spinner [stroke]="(device.vars.fp == 'A' && device.vars.gp === '1') || device.vars.fp == 'D' ? '#43a9c9' : '#000000'" [rotate]="((device.vars.fp == 'A' && device.vars.gp === '1') || device.vars.fp == 'D') && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))">
									<span *ngIf="device.vars.fp === 'A'">{{ (device.vars.xp) ? (device.vars.xp | number: '1.0-0') : '-' }}%</span>
									<span *ngIf="device.vars.fp !== 'A'">{{ (device.vars.fp == '0') ? 'OFF' : 'DOS' }}</span>
								</spinner>
<!--								<span> {{device.vars.fp}} - </span>-->
<!--								<span> {{device.vars.gp}} - </span>-->
<!--								<span> {{device.vars.fp}} - </span>-->
<!--								<span> {{device.status.value}} - </span>-->
<!--								<span> {{device.status.value}} - </span>-->
<!--								<span> {{deviceOffHours.limitReached}} - </span>-->
								<span>{{ (device.vars.fp == 'D') ? ('15 ' + ('MINUTOS' | translate)) : ((device.vars.gp == 0) ? 'STOP' : 'RUN' )}}</span>
							</td>
							<td>
								<div class="lg"><strong>{{ (device.vars.mp && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached)) && device.specialStatus.flow) ? (device.vars.mp | number: '1.2') : '-' }}</strong></div>
								<div>{{ 'PH' | translate }}</div>
							</td>
							<td>
								<div>
									<span [ngSwitch]="device.vars.pe">
										<span *ngSwitchCase="1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> P.STOP</span>
									<span *ngSwitchCase="0">
											<span *ngIf="device.vars.tm === '000'" class="text-muted">PS OFF</span>
									<span *ngIf="device.vars.tm !== '000'" class="text-info">PS {{ device.vars.tm | number: '1.0-0' }}m</span>
									</span>
									</span>
								</div>
								<div [ngClass]="device.vars.ip == '0' ? 'text-muted' : 'text-success'">{{ 'INTELLIGENT' | translate | uppercase }}</div>
								<div class="text-info">{{ (device.vars.hp === '0') ? 'HYS 2s' : 'HYS 2m' }}</div>
							</td>
						</tr>
					</tbody>
				</table>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<div>
									<span *ngIf="device.vars.sf !== '0'"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'FLOW' | translate }}</span>
									<span *ngIf="device.vars.sf === '0'">
										<span *ngIf="!device.booleanVars.fe && !device.vars.fs && !device.vars.fd">{{ 'FLOW' | translate }} {{ 'OFF' | translate}}</span>
									<span *ngIf="device.booleanVars.fe || device.vars.fs || device.vars.fd">{{ 'FLOW' | translate }} {{ 'OK' | translate }}</span>
									</span>
								</div>
								<div>
									<span *ngIf="!device.booleanVars.fe" class="text-muted">FE,</span>
									<span *ngIf="device.booleanVars.fe && !device.booleanVars.ae">FE,</span>
									<span *ngIf="device.booleanVars.fe && device.booleanVars.ae" class="text-danger">FE,</span>
									<span *ngIf="!device.vars.fs" class="text-muted">FS,</span>
									<span *ngIf="device.vars.fs && !device.vars.aw">FS,</span>
									<span *ngIf="device.vars.fs && device.vars.aw" class="text-danger">FS,</span>
								</div>
							</td>
							<td [ngClass]="{'text-danger' : device.vars.cp}">
								<i class="fa fa-clock-o fa-fw" aria-hidden="true"></i><span [ngClass]="device.vars.cp ? text-danger : text-info">{{ (device.vars.tp) ? (device.vars.tp | number: '1.0-0') : '0' }} h / {{ (device.vars.pt) ? (device.vars.pt | number: '1.0-0') : '0' }} h</span>
							</td>
						</tr>
						<tr>
							<td [ngClass]="{'text-danger' : device.vars.wp !== '0'}">
								<span *ngIf="device.vars.wp === '0'">pH: 6.5-8.5</span>
								<ng-container *ngIf="device.vars.wp !== '0'">
									<ng-container *ngIf="device.vars.wp === 'L'">
										<p><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> pH {{ 'LOW_IDIOMA' | translate }}</p>
										<p>pH &#60; 6.5</p>
									</ng-container>
									<ng-container *ngIf="device.vars.wp === 'H'">
										<p><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> pH {{ 'HIGH_IDIOMA' | translate }}</p>
										<p>pH > 8.5</p>
									</ng-container>
								</ng-container>
							</td>
							<td>
								<p *ngIf="device.vars.cp" class="text-danger"><i class="fa fa-magnet fa-flip-vertical" aria-hidden="true"></i> CHECK</p>
							</td>
						</tr>
						<tr>
							<td>
								<span *ngIf="device.vars.op === '0'" class="text-muted">TANK</span>
								<ng-container *ngIf="device.vars.op === '1'">
									<span *ngIf="device.vars.dp === '0'">TANK OK</span>
									<span *ngIf="device.vars.dp === '1'" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> TANK</span>
								</ng-container>
							</td>
							<td>
								<span *ngIf="!device.vars.bp">FUSE OK</span>
								<span *ngIf="device.vars.bp" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> FUSE</span>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="text-right">
					<button [disabled]="device.status.value === 0 || checkSave" class="btn btn-default" (click)="swt = !swt"><i class="fa fa-bars" aria-hidden="true"></i></button>
				</div>
				<div class="panel" *ngIf="swt">
					<div class="panel-body options one">
						<div class="form-horizontal">
							<div class="form-group">
								<label class="col-xs-3">
									{{ 'MODO' | translate }}
								</label>
								<div class="col-xs-9 text-right">
									<div class="btn-group">
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.fp === '0') ? 'btn-primary' : 'btn-default'" (click)="change('fp', '0')">Off</button>
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.fp === 'A') ? 'btn-primary' : 'btn-default'" (click)="change('fp', 'A')">Auto</button>
									</div>
								</div>
							</div>
						</div>
						<div class="form-horizontal">
							<div class="form-group">
								<label class="col-xs-3">
									{{ 'HP_HISTERESIS' | translate }}
								</label>
								<div class="col-xs-9 text-right">
									<div class="btn-group">
										<button  type="button" [disabled]="true" class="btn" [ngClass]="(deviceTmp.vars.hp === '0') ? 'btn-primary' : 'btn-default'" (click)="change('hp', '0')">2s</button>
										<button  type="button" [disabled]="true" class="btn" [ngClass]="(deviceTmp.vars.hp === '1') ? 'btn-primary' : 'btn-default'" (click)="change('hp', '1')">2m</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-footer text-center">
						<button class="btn btn-link btn-success" (click)="save()" [disabled]="loading || device.status.value === 0 || !changed.edited || checkSave">
							<i *ngIf="!this.loading && !checkSave" class="fa fa-floppy-o" aria-hidden="true"></i>
							<i *ngIf="this.loading || checkSave" class="fa fa-spin fa-refresh" aria-hidden="true"></i> {{ 'BT_GUARDAR' | translate }}
						</button>
						<button class="btn btn-link btn-danger" [disabled]="loading || checkSave" (click)="swt = false">{{ 'BT_CANCELAR' | translate }}</button>
					</div>
				</div>
			</div>
		</div>
	</tab>

	<tab *ngIf="device.isORP" heading="ClmV" [active]="tabs.mv" (select)="scroll('tabs')">
		<div class="panel panel-default">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-6 text-left"><strong>{{ device.abbreviationModel }}</strong></div>
					<div class="col-xs-6 text-right"><strong>{{ (device.vars.ao === '0') ? 'RED' : 'OXD' }}</strong></div>
				</div>
				<div class="text-center forInputNumber wrapperInputNumber">
					<span class="spanSet">SET</span>
					<input-number [block]="device.status.value === 0" [value]="so" size="xl" step="1" min="600" max="850" precision="0" (exit)="so = $event"></input-number>
				</div>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<spinner [stroke]="(device.vars.fo == 'A' && device.vars.go === '1') || device.vars.fo == 'D' || device.vars.go == '1' ? '#a94442' : '#000000'" [rotate]="((device.vars.fo == 'A' && device.vars.go === '1') || device.vars.fo == 'D' || device.vars.go == '1') && device.status.value !== 0">
									<ng-container *ngIf="!device.isElectrolysis">
										<span *ngIf="device.vars.fo === 'A'">{{ ((device.vars.go === 0) ? device.vars.xo : device.vars.xo) | number: '1.0-0' }}%</span>
										<span *ngIf="device.vars.fo !== 'A'">{{ (device.vars.fo == 0 || device.vars.fo == '-') ? 'OFF' : 'DOS' }}</span>
									</ng-container>
									<ng-container *ngIf="device.isElectrolysis">
										<span>{{ device.vars.go === '1' ? 'RUN' : 'STOP' }}</span>
									</ng-container>
								</spinner>
							</td>
							<td>
								<div class="lg"><strong>{{ (device.vars.mo && device.vars.mo !== '---' && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached)) && device.specialStatus.flow) ? (device.vars.mo | number: '1.0-0') : '-' }}</strong></div>
								<div>ClmV</div>
							</td>
							<td>
								<div *ngIf="!device.isElectrolysis">
									<ng-container [ngSwitch]="device.vars.oe">
										<ng-container *ngSwitchCase="1" class="text-danger">
											<span><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> P.STOP</span>
										</ng-container>
										<ng-container *ngSwitchCase="0">
											<span *ngIf="device.vars.om === '000'" class="text-muted">PS OFF</span>
											<span *ngIf="device.vars.om !== '000'" class="text-info">PS {{ (device.vars.om) ? (device.vars.om | number: '1.0-0') : '-' }}m</span>
										</ng-container>
									</ng-container>
								</div>
								<div [ngClass]="device.vars.io == '0' ? 'text-muted' : 'text-success'">{{ 'INTELLIGENT' | translate | uppercase }}</div>
								<div class="text-info">{{ (device.vars.ho === '0') ? 'HYS 2s' : 'HYS 2m' }}</div>
							</td>
						</tr>
					</tbody>
				</table>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<div>
									<span *ngIf="device.vars.sf !== '0'"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'FLOW' | translate }}</span>
									<span *ngIf="device.vars.sf === '0'">
										<ng-container *ngIf="!device.booleanVars.fe && !device.vars.fs && !device.vars.fd">{{ 'FLOW' | translate }} {{ 'OFF' | translate }}</ng-container>
										<ng-container *ngIf="device.booleanVars.fe || device.vars.fs || device.vars.fd">{{ 'FLOW' | translate }} {{ 'OK' | translate }}</ng-container>
									</span>
								</div>
								<div>
									<span *ngIf="!device.booleanVars.fe" class="text-muted">FE,</span>
									<span *ngIf="device.booleanVars.fe && !device.booleanVars.ae">FE,</span>
									<span *ngIf="device.booleanVars.fe && device.booleanVars.ae" class="text-danger">FE,</span>
									<span *ngIf="!device.vars.fs" class="text-muted">FS,</span>
									<span *ngIf="device.vars.fs && !device.vars.aw">FS,</span>
									<span *ngIf="device.vars.fs && device.vars.aw" class="text-danger">FS,</span>
								</div>
							</td>
							<td>
								<div *ngIf="!device.isElectrolysis" [ngClass]="{'text-danger' : device.vars.po}">
									<i class="fa fa-clock-o fa-fw" aria-hidden="true"></i>
									<span>{{ (device.vars.to) ? (device.vars.to | number: '1.0-0') : '0' }} h / {{ (device.vars.ot) ? (device.vars.ot | number: '1.0-0') : '0' }} h</span>
								</div>
							</td>
						</tr>
						<tr>
							<td [ngClass]="{'text-danger' : device.vars.wo !== '0'}">
								<span *ngIf="device.vars.wo === '0'">ClmV: 600-850</span>
								<div *ngIf="device.vars.wo !== '0'">
									<div *ngIf="device.vars.wo === 'L'">
										<div><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> LOW ClmV</div>
										<div>Clmv &#60; 600</div>
									</div>
									<div *ngIf="device.vars.wo === 'H'">
										<div><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> HIGH Clmv</div>
										<div>Clmv > 855</div>
									</div>
								</div>
							</td>
							<td>
								<p *ngIf="device.vars.po && !device.isElectrolysis" class="text-danger"><i class="fa fa-magnet fa-flip-vertical" aria-hidden="true"></i> CHECK</p>
							</td>
						</tr>
						<tr *ngIf="!device.isElectrolysis">
							<td>
								<span *ngIf="device.vars.oo === '0'" class="text-muted">TANK</span>
								<ng-container *ngIf="device.vars.oo === '1'">
									<span *ngIf="device.vars.do === '1'">TANK OK</span>
									<span *ngIf="device.vars.do === '0'" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> TANK</span>
								</ng-container>
							</td>
							<td>
								<span *ngIf="!device.vars.bo">FUSE OK</span>
								<span *ngIf="device.vars.bo" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> FUSE</span>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="text-right">
					<button [disabled]="device.status.value === 0 || checkSave" class="btn btn-default" (click)="swt = !swt"><i class="fa fa-bars" aria-hidden="true"></i></button>
				</div>
				<div class="panel" *ngIf="swt">
					<div class="panel-body options one">
						<div class="form-horizontal" *ngIf="!device.isElectrolysis">
							<div class="form-group">
								<label class="col-xs-3">
									{{ 'MODO' | translate }}
								</label>
								<div class="col-xs-9 text-right">
									<div class="btn-group">
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.fo === '0') ? 'btn-primary' : 'btn-default'" (click)="change('fo', '0')">Off</button>
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.fo === 'A') ? 'btn-primary' : 'btn-default'" (click)="change('fo', 'A')">Auto</button>
									</div>
								</div>
							</div>
						</div>
						<div class="form-horizontal" *ngIf="!device.isElectrolysis">
							<div class="form-group">
								<label class="col-xs-3">
									{{ 'HP_HISTERESIS' | translate }}
								</label>
								<div class="col-xs-9 text-right">
									<div class="btn-group">
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.ho === '0') ? 'btn-primary' : 'btn-default'" (click)="change('ho', '0')">2s</button>
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.ho === '1') ? 'btn-primary' : 'btn-default'" (click)="change('ho', '1')">2m</button>
									</div>
								</div>
							</div>
						</div>
						<div class="form-horizontal" *ngIf="device.isElectrolysis">
							<div class="form-group">
								<label class="col-xs-3">
									AUTO CL INT
								</label>
								<div class="col-xs-9 text-right">
									<div class="switch">
										<ui-switch [disabled]="checkSave" class="form-control" [(ngModel)]="ei" color="#43a9c9"></ui-switch>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-footer text-center">
						<button class="btn btn-link btn-success" (click)="save()" [disabled]="loading || device.status.value === 0 || !changed.edited || checkSave">
							<i *ngIf="!this.loading && !checkSave" class="fa fa-floppy-o" aria-hidden="true"></i>
							<i *ngIf="this.loading || checkSave" class="fa fa-spin fa-refresh" aria-hidden="true"></i> {{ 'BT_GUARDAR' | translate }}
						</button>
						<button class="btn btn-link btn-danger" [disabled]="loading || checkSave" (click)="swt = false">{{ 'BT_CANCELAR' | translate }}</button>
					</div>
				</div>
			</div>
		</div>
	</tab>

	<tab *ngIf="device.isPPM" heading="Clppm" [active]="tabs.ppm" (select)="scroll('tabs')">
		<div class="panel panel-default">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-6 text-left"><strong>{{ device.abbreviationModel }}</strong></div>
					<div class="col-xs-6 text-right"><strong>{{ (device.vars.ah === '0') ? 'RED' : 'OXD' }}</strong></div>
				</div>
				<div class="text-center forInputNumber wrapperInputNumber">
					<span class="spanSet">SET</span>
					<input-number [block]="device.status.value === 0" [value]="sh" size="xl" step="0.01" min="0.3" max="3.50" precision="2" (exit)="sh = $event"></input-number>
				</div>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<spinner *ngIf="device.isElectrolysis" [stroke]="device.vars.gh === '1' ? '#3c763d' : '#000000'" [rotate]="device.vars.gh === '1' && device.status.value !== 0">
									{{ device.vars.gh === '1' && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached)) ? 'RUN' : '-' }}
								</spinner>
								<spinner *ngIf="!device.isElectrolysis" [stroke]="(device.vars.fh === 'A' && device.vars.gh === '1') || device.vars.fh === 'D' ? '#3c763d' : '#000000'" [rotate]="((device.vars.fh === 'A' && device.vars.gh === '1') || device.vars.fh === 'D') && device.status.value !== 0">
									<ng-container *ngIf="device.vars.fh === 'A'">{{ (device.vars.gp == 0) ? device.vars.xh : device.vars.xh }}%</ng-container>
									<ng-container *ngIf="device.vars.fh !== 'A'">{{ (device.vars.fh == 0 || device.vars.fh == '-') ? 'OFF' : 'DOS' }}</ng-container>
								</spinner>
							</td>
							<td>
								<div class="lg"><strong>{{ (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached)) ? ((device.vars.wh === 'I') ? 'INIT' : ((device.vars.mh && device.vars.ac !== '0') ? (device.vars.mh | number: '1.2') : '-')) : '-' }}</strong></div>
								<div>Clppm</div>
							</td>
							<td>
								<div *ngIf="!device.isElectrolysis">
									<ng-container [ngSwitch]="device.vars.he">
										<span *ngSwitchCase="1" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> P.STOP</span>
										<ng-container *ngSwitchCase="0">
											<span *ngIf="device.vars.hm === '000'" class="text-muted">PS OFF</span>
											<span *ngIf="device.vars.hm !== '000'" class="text-info">PS {{ (device.vars.hm !== '') ? (device.vars.hm | number: '1.0-0') : '0' }}m</span>
										</ng-container>
									</ng-container>
								</div>
								<div [ngClass]="device.vars.ih == '0' ? 'text-muted' : 'text-success'">{{ 'INTELLIGENT' | translate | uppercase }}</div>
								<div class="text-info">{{ (device.vars.hh === '0') ? 'HYS 2s' : 'HYS 2m' }}</div>
							</td>
						</tr>
					</tbody>
				</table>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<div>
									<span *ngIf="device.vars.sf !== '0'"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'FLOW' | translate }}</span>
									<ng-container *ngIf="device.vars.sf === '0'">
										<span *ngIf="!device.booleanVars.fe && !device.vars.fs && !device.vars.fd">{{ 'FLOW' | translate }} {{ 'OFF' | translate }}</span>
										<span *ngIf="device.booleanVars.fe || device.vars.fs || device.vars.fd">{{ 'FLOW' | translate }} {{ 'OK' | translate }}</span>
									</ng-container>
								</div>
								<div>
									<span *ngIf="!device.booleanVars.fe" class="text-muted">FE,</span>
									<span *ngIf="device.booleanVars.fe && !device.booleanVars.ae">FE,</span>
									<span *ngIf="device.booleanVars.fe && device.booleanVars.ae" class="text-danger">FE,</span>
									<span *ngIf="!device.vars.fs" class="text-muted">FS,</span>
									<span *ngIf="device.vars.fs && !device.vars.aw">FS,</span>
									<span *ngIf="device.vars.fs && device.vars.aw" class="text-danger">FS,</span>
								</div>
							</td>
							<td>
								<div *ngIf="!device.isElectrolysis" [ngClass]="{'text-danger' : device.vars.ch}">
									<div><i class="fa fa-clock-o fa-fw" aria-hidden="true"></i><span>{{ (device.vars.th) ? (device.vars.th | number: '1.0-0') : '0' }} h / {{ (device.vars.ht) ? (device.vars.ht | number: '1.0-0') : '0' }} h</span></div>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div [ngClass]="{'text-danger' : device.vars.wh !== '0'}">
									<div *ngIf="device.vars.wh === '0'">Clmpp: 0.30-3.50</div>
									<div *ngIf="device.vars.wh !== '0'">
										<ng-container *ngIf="device.vars.wh === 'L' && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))">
											<div class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> LOW Clppm</div>
											<div>Clppm &#60; 0.30</div>
										</ng-container>
										<ng-container *ngIf="device.vars.wh === 'H' && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))">
											<div class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> HIGH Clppm</div>
											<div>Clppm > 3.55</div>
										</ng-container>
										<ng-container *ngIf="(device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached));else offline">
											<div *ngIf="device.vars.wh === 'I'">INIT</div>
										</ng-container>
										<ng-template #offline>
											<div>-</div>
										</ng-template>
									</div>
								</div>
							</td>
							<td>
								<div *ngIf="device.vars.ch && !device.isElectrolysis"><i class="fa fa-magnet fa-flip-vertical" aria-hidden="true"></i> CHECK</div>
							</td>
						</tr>
						<tr *ngIf="!device.isElectrolysis">
							<td>
								<span *ngIf="device.vars.oh === '0'" class="text-muted">TANK</span>
								<ng-container *ngIf="device.vars.oh === '1'">
									<span *ngIf="device.vars.dc === '0'">TANK OK</span>
									<span *ngIf="device.vars.dc === '1'" class="text-danger">
										<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
										TANK
									</span>
								</ng-container>
							</td>
							<td>
								<span *ngIf="!device.vars.bh">FUSE OK</span>
								<span *ngIf="device.vars.bh" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> FUSE</span>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="text-right">
					<button [disabled]="device.status.value === 0 || checkSave" class="btn btn-default" (click)="swt = !swt"><i class="fa fa-bars" aria-hidden="true"></i></button>
				</div>
				<div class="panel" *ngIf="swt">
					<div class="panel-body options one">
						<div class="form-horizontal" *ngIf="!device.isElectrolysis">
							<div class="form-group">
								<label class="col-xs-3">
									{{ 'MODO' | translate }}
								</label>
								<div class="col-xs-9 text-right">
									<div class="btn-group">
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.fh === '0') ? 'btn-primary' : 'btn-default'" (click)="change('fh', '0')">Off</button>
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.fh === 'A') ? 'btn-primary' : 'btn-default'" (click)="change('fh', 'A')">Auto</button>
									</div>
								</div>
							</div>
						</div>
						<div class="form-horizontal" *ngIf="!device.isElectrolysis">
							<div class="form-group">
								<label class="col-xs-3">
									{{ 'HP_HISTERESIS' | translate }}
								</label>
								<div class="col-xs-9 text-right">
									<div class="btn-group">
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.hh === '0') ? 'btn-primary' : 'btn-default'" (click)="change('hh', '0')">2s</button>
										<button type="button" [disabled]="checkSave" class="btn" [ngClass]="(deviceTmp.vars.hh === '1') ? 'btn-primary' : 'btn-default'" (click)="change('hh', '1')">2m</button>
									</div>
								</div>
							</div>
						</div>
						<div class="form-horizontal" *ngIf="device.isElectrolysis">
							<div class="form-group">
								<label class="col-xs-3">
									AUTO CL INT
								</label>
								<div class="col-xs-9 text-right">
									<div class="switch">
										<ui-switch [disabled]="checkSave" class="form-control" [(ngModel)]="ei" color="#43a9c9"></ui-switch>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-footer text-center">
						<button class="btn btn-link btn-success" (click)="save()" [disabled]="loading || device.status.value === 0 || !changed.edited || checkSave">
							<i *ngIf="!this.loading && !checkSave" class="fa fa-floppy-o" aria-hidden="true"></i>
							<i *ngIf="this.loading || checkSave" class="fa fa-spin fa-refresh" aria-hidden="true"></i> {{ 'BT_GUARDAR' | translate }}
						</button>
						<button class="btn btn-link btn-danger" [disabled]="loading || checkSave" (click)="swt = false">{{ 'BT_CANCELAR' | translate }}</button>
					</div>
				</div>
			</div>
		</div>
	</tab>

	<tab heading='Sensors' [active]="tabs.sensors" (select)="scroll('tabs')" *ngIf="device">
		<div class="panel panel-default">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 text-left"><strong>{{ device.abbreviationModel }}</strong></div>
				</div>
				<table>
					<tbody>
						<tr>
							<td>gr/L</td>
							<td>
								<span *ngIf="device.vars.sf === '1'" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'FLOW' | translate }}</span>
								<ng-container *ngIf="device.vars.sf === '0'">
									<span *ngIf="!device.booleanVars.fe && !device.vars.fs && !device.vars.fd">{{ 'FLOW' | translate }} {{ 'OFF' | translate }}</span>
									<span *ngIf="device.booleanVars.fe || device.vars.fs || device.vars.fd">{{ 'FLOW' | translate }} {{ 'OK' | translate }}</span>
								</ng-container>
							</td>
						</tr>
						<tr>
							<td>
								<div>{{ (device.vars.cn !== '-' && (device.status.value !== 0  || (device.status.value === 0 &&
										!deviceOffHours.limitReached)) && device.specialStatus.flow && device.specialStatus.prodUp30) ? (device.vars.cn.substring(0, 4) | number: '1.2') : '-' }}</div>
								<div>
									{{ (device.vars.ta !== '-' && (device.status.value !== 0  ||
										(device.status.value === 0 && !deviceOffHours.limitReached)) && device.specialStatus.flow) ?
										(device.vars.ta.substring(0, 5) | number: '1.1') : '-' }}
									<span class="text-primary">{{ device.vars.ta.substring(5, 6) }} <i class="fa fa-thermometer-half fa-2" aria-hidden="true"></i></span>
								</div>
							</td>
							<td>
								<div>
									<div *ngIf="!device.booleanVars.fe || !device.isElectrolysis" class="text-muted">Gas(FE)</div>
									<div *ngIf="device.booleanVars.fe && !device.booleanVars.ae && device.isElectrolysis">Gas(FE)</div>
									<div *ngIf="device.booleanVars.fe && device.booleanVars.ae && device.isElectrolysis" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Gas(FE)</div>
								</div>
								<div>
									<span *ngIf="!device.vars.fs" class="text-muted">Switch(FS)</span>
									<span *ngIf="device.vars.fs && !device.vars.aw">Switch(FS)</span>
									<span *ngIf="device.vars.fs && device.vars.aw" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>Switch(FS)</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<hr>
				<table>
					<tbody>
						<tr>
							<td>
								<ng-container [ngSwitch]="device.vars.as">
									<span *ngSwitchCase="'H'" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> gr/L {{ 'HIGH_IDIOMA' | translate }}</span>
									<span *ngSwitchCase="'L'" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> gr/L {{ 'LOW_IDIOMA' | translate }}</span>
								</ng-container>
							</td>
						</tr>
						<tr>
							<td>
								<ng-container [ngSwitch]="device.vars.at">
									<span *ngSwitchCase="'H'" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'TEMPERATURA' | translate }} {{ 'HIGH_WARNINGS' | translate }}</span>
									<span *ngSwitchCase="'L'" class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'TEMPERATURA' | translate }} {{ 'LOW_WARNINGS' | translate }}</span>
								</ng-container>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="text-right">
					<button [disabled]="device.status.value === 0 || checkSave" class="btn btn-default" (click)="swt = !swt"><i class="fa fa-bars" aria-hidden="true"></i></button>
				</div>
				<div class="options sensors" *ngIf="swt">
					<div class="panel">
						<div class="panel-body form-horizontal">
							<div *ngIf="device.isElectrolysis" class="form-group switch">
								<label class="col-xs-4">Gas(F.E.)</label>
								<div class="col-xs-8">
									<ui-switch class="form-control" [disabled]="checkSave" [(ngModel)]="fe" color="#43a9c9"></ui-switch>
								</div>
							</div>
							<div class="form-group switch">
								<label class="col-xs-4">Switch(F.S.)</label>
								<div class="col-xs-8">
									<ui-switch class="form-control" [disabled]="checkSave" [(ngModel)]="fs" color="#43a9c9"></ui-switch>
								</div>
							</div>
						</div>
						<div class="panel-footer text-center">
							<button class="btn btn-link btn-success" (click)="save()" [disabled]="loading || device.status.value === 0 || !changed.edited || checkSave">
								<i *ngIf="!this.loading && !checkSave" class="fa fa-floppy-o" aria-hidden="true"></i>
								<i *ngIf="this.loading || checkSave" class="fa fa-spin fa-refresh" aria-hidden="true"></i> {{ 'BT_GUARDAR' | translate }}
							</button>
							<button class="btn btn-link btn-danger" [disabled]="loading || checkSave" (click)="swt = false">{{ 'BT_CANCELAR' | translate }}</button>
						</div>
					</div>
				</div>

			</div>
		</div>
	</tab>
</tabset>

<div bsModal #infoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="infoModal" aria-hidden="true">
	<div *ngIf="device" class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button class="close" aria-hidden="true" (click)="infoModal.hide()">&times;</button>
				<h4 class="modal-title">
					{{ 'INFO' | translate }}
					<small class="text-danger fadeIn" *ngIf="device.status.value === 0">{{ 'DISPOSITIVO_DESCONECTADO' | translate }}</small>
				</h4>
			</div>
			<div class="modal-body text-center">

				<table cellspacing="1" no-margin class="table table-condensed table-bordered table-hover table-responsive infoTable">
					<thead>
						<tr>
							<th style="overflow: hidden">{{ device.abbreviationModel }}</th>
							<th style="overflow: hidden">{{ now | date:'dd-MM-yy' }}</th>
							<th style="overflow: hidden">{{ now | date:'H:mm' }}</th>
							<th>
								<div></div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div></div>
							</td>
							<td>
								<div></div>
							</td>
							<td>
								<div></div>
							</td>
							<td>
								<div></div>
							</td>
						</tr>
						<tr>
							<td>
								<div></div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis">{{ (device.vars.pa && device.specialStatus.flow && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.pa | number:'1.0-0') : '0'}}%</div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis"><strong>SET</strong> {{ (device.vars.sn !== '' && device.vars.sn !== '---') ? (device.vars.sn | number:'1.0-0') : '0' }}%</div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis">{{ vd | number:'1.1-1' }} v</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.isElectrolysis">{{ ic | number:'1.1-1' }} A</div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis"><strong>INT</strong> {{ (!device.booleanVars.ei ? 'OFF' : 'ON') | translate }}</div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis"><strong>EXT</strong> {{ (!device.booleanVars.ee ? 'OFF' : 'ON') | translate }}</div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis">{{ (device.vars.eg !== '' && device.vars.eg !== '----') ? (device.vars.eg | number:'1.0-0') : '0' }} gr/d</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.isElectrolysis">
									<div *ngIf="device.vars.co !== '0'">
										<strong>COVER</strong> {{ (device.vars.sv !== '' && device.vars.sv !== '---') ? (device.vars.sv | number:'1.0-0') : '0' }}%
									</div>
									<div *ngIf="device.vars.co === '0'"><strong>COVER</strong> OFF</div>
								</div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis">{{ (device.vars.ce !== '' && device.vars.ce !== '-') ? ((device.vars.ce === 'T') ? 'TEST' : device.vars.ce + 'h/' + device.vars.ce + 'h') : '' }}</div>
							</td>
							<td>
								<div *ngIf="device.isElectrolysis">{{ (device.vars.ge !== '' && device.vars.ge !== '----') ? (device.vars.ge | number:'1.0-0') : '0' }} grCL/d</div>
							</td>
							<td class="text-danger">
								<div *ngIf="device.isElectrolysis">
									<div>{{ device.vars.cc !== '0' ? 'Ala.Cell' : '' }}</div>
									<div>{{ device.vars.wh !== '0' ? 'Ala.Clo' : '' }}</div>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.phStatus !== 0">{{ (device.vars.mp !== '' && device.specialStatus.flow && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mp | number:'1.2') : '' }}</div>
							</td>
							<td>
								<div *ngIf="device.phStatus !== 0"><strong>SET</strong> {{ (device.vars.sp !== '' && device.vars.sp !== '----') ? (device.vars.sp | number:'1.2') : '' }}</div>
							</td>
							<td>
								<div *ngIf="device.phStatus !== 0">{{ ((device.vars.fp === 'A') ? 'AUTO' : ((device.vars.fp === '0') ? 'OFF' : 'ON')) | translate }}</div>
							</td>
							<td>
								<div *ngIf="device.phStatus !== 0">{{ (device.vars.xp !== '' && device.vars.xp !== '000' && device.vars.xp !== '---') ? (device.vars.xp | number:'1.0-0') + '%' : 'OFF' }}</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.phStatus !== 0"><strong>P.Stop</strong> {{ (device.vars.tm !== '' && device.vars.tm !== '000' && device.vars.tm !== '---') ? (device.vars.tm | number:'1.0-0') + 'min' : 'OFF' }}</div>
							</td>
							<td>
								<div *ngIf="device.phStatus !== 0">{{ (device.vars.hp !== '') ? ((device.vars.hp === '0') ? 'HYS 2s' : 'HYS 2m') : '' }}</div>
							</td>
							<td>
								<div *ngIf="device.phStatus !== 0"><strong>INTELLI</strong> {{ ((device.vars.ip === 1) ? 'ON' : 'OFF') | translate }}</div>
							</td>
							<td>
								<div *ngIf="device.phStatus !== 0"><strong>TANK PH</strong> {{ ((device.vars.op === '1') ? 'ON' : 'OFF') | translate }}</div>
							</td>
						</tr>
						<tr>
							<td class="text-danger">
								<div *ngIf="device.phStatus !== 0">
									<div>{{ (device.vars.wp !== '0') ? 'Ala.PH' : '' }}</div>
									<div>{{ (device.vars.pe !== '0') ? 'Ala.Pump' : '' }}</div>
									<div>{{ (device.vars.dp !== '0') ? 'Ala.Tank' : '' }}</div>
									<div>{{ (device.vars.bp) ? 'Ala.Fuse' : '' }}</div>
									<div>{{ (device.vars.cp !== '0') ? 'Ala.Check' : '' }}</div>
								</div>
							</td>
							<td>
								<div *ngIf="device.orpStatus !== 0">{{ (device.vars.mo !== '' && device.vars.mo !== '---' && device.specialStatus.flow && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mo | number:'1.0-0') : '0' }} ClmV</div>
							</td>
							<td>
								<div *ngIf="device.orpStatus !== 0"><strong>SET</strong> {{ (device.vars.so !== '' && device.vars.so !== '---') ? (device.vars.so | number:'1.0-0') : '0' }}</div>
							</td>
							<td>
								<div *ngIf="device.orpStatus !== 0">{{ ((device.vars.fo !== 'A') ? ((device.vars.fo === '1') ? 'ON' : 'OFF') : 'AUTO') | translate }}</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.orpStatus !== 0"><strong>{{ 'PUMP' | translate }}</strong> {{ (device.vars.xo !== '' && device.vars.xo !== '---') ? (device.vars.xo | number:'1.0-0') : '0' }} %</div>
							</td>
							<td>
								<div *ngIf="device.orpStatus !== 0">{{ (device.vars.om !== '' && device.vars.om !== '---') ? ((device.vars.om !== '000') ? ('PS' + (device.vars.om | number:'1.0-0') + 'm') : ( 'OFF' | translate )) : '0' }}</div>
							</td>
							<td>
								<div *ngIf="device.orpStatus !== 0">{{ (device.vars.ho !== '') ? ((device.vars.ho === '0') ? 'HYS 2s' : 'HYS 2m') : '' }}</div>
							</td>
							<td>
								<div *ngIf="device.orpStatus !== 0"><strong>INTELLI</strong> {{ ((device.vars.io === 1) ? 'ON' : 'OFF') | translate }}</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.orpStatus !== 0"><strong>{{ 'NIVEL' | translate }}</strong> {{ ((device.vars.oo === '1') ? 'ON' : 'OFF') | translate }}</div>
							</td>
							<td class="text-danger">
								<div *ngIf="device.orpStatus !== 0">
									<div>{{ (device.vars.wo !== '0') ? 'Ala.ClmV' : '' }}</div>
									<div>{{ (device.vars.oe !== '0') ? 'Ala.Pump' : '' }}</div>
									<div>{{ (device.vars.do !== '0') ? 'Ala.Tank' : '' }}</div>
									<div>{{ (device.vars.bo) ? 'Ala.Fuse' : '' }}</div>
									<div>{{ (device.vars.po) ? 'Ala.Check' : '' }}</div>
								</div>
							</td>
							<td>
								<div *ngIf="device.ppmStatus !== 0">{{ (device.vars.mh !== '' && device.specialStatus.flow && (device.status.value !== 0 || (device.status.value === 0 && !deviceOffHours.limitReached))) ? (device.vars.mh | number:'1.2') : '0.00' }}</div>
							</td>
							<td>
								<div *ngIf="device.ppmStatus !== 0"><strong>SET</strong> {{ (device.vars.sh !== '' && device.vars.sh !== '----') ? (device.vars.sh | number:'1.2') : '0.00' }}</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.ppmStatus !== 0">{{ ((device.vars.fh === 'A') ? 'AUTO' : ((device.vars.fh === '0') ? 'OFF' : 'ON')) | translate }}</div>
							</td>
							<td>
								<div *ngIf="device.ppmStatus !== 0">{{ (device.vars.xh !== '' && device.vars.xh !== '---') ? (device.vars.xh | number:'1.0-0') : '' }}%</div>
							</td>
							<td>
								<div *ngIf="device.ppmStatus !== 0">{{ (device.vars.hm !== '' && device.vars.hm !== '---') ? ((device.vars.hm !== '000') ? ('PS' + (device.vars.hm | number:'1.0-0') + 'm') : ( 'OFF' | translate )) : '0' }}</div>
							</td>
							<td>
								<div *ngIf="device.ppmStatus !== 0">{{ (device.vars.hh === '0') ? 'HYS 2s' : 'HYS 2m' }}</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.ppmStatus !== 0">{{ ((device.vars.ih) ? 'ON' : 'OFF') | translate }}</div>
							</td>
							<td>
								<div *ngIf="device.ppmStatus !== 0">{{ ((device.vars.oh === '1') ? 'ON' : 'OFF') | translate }}</div>
							</td>
							<td class="text-danger">
								<div *ngIf="device.ppmStatus !== 0">
									<div>{{ (device.vars.wh !== '0') ? 'Ala.Clppm' : '' }}</div>
									<div>{{ (device.vars.he !== '0') ? 'Ala.Pump' : '' }}</div>
									<div>{{ (device.vars.dc !== '0') ? 'Ala.Tank' : '' }}</div>
									<div>{{ (device.vars.bh) ? 'Ala.Fuse' : '' }}</div>
									<div>{{ (device.vars.ch) ? 'Ala.Check' : '' }}</div>
								</div>
							</td>
							<td>
								<div *ngIf="device.isUV"><strong>UV</strong> {{ ((device.booleanVars.lu) ? 'ON' : 'OFF') | translate }}</div>
							</td>
						</tr>
						<tr>
							<td>
								<div *ngIf="device.isUV">{{ (device.vars.hu !== '' && device.vars.hu !== '------') ? (device.vars.hu | number:'1.0-0') : '0' }} {{ 'HORAS' | translate }}</div>
							</td>
							<td>
								<div *ngIf="device.isUV"><strong>IGNICIO</strong> {{ (device.vars.iu !== '') ? (device.vars.iu | number:'1.0-0') : '0' }}</div>
							</td>
							<td class="text-danger">
								<div *ngIf="device.isUV">
									<div>{{ device.booleanVars.bu ? 'Ala.Ballast' : '' }}</div>
									<div>{{ device.booleanVars.fu ? 'Ala.Fuse' : '' }}</div>
								</div>
							</td>
							<td><strong>TEMP</strong> {{ device.specialStatus.flow && (device.status.value !== 0 || (device.status.value === 0 &&
								!deviceOffHours.limitReached)) ? (device.vars.ta.substr(0, 5) | number:'1.1') : '-' }} {{taRanges.unit === 'C' ? 'ºC' : 'ºF'}}</td>
						</tr>
						<tr>
							<td><div *ngIf="device.isElectrolysis">{{ device.specialStatus.flow && (device.status.value !== 0 || (device.status.value
									=== 0 && !deviceOffHours.limitReached)) && device.specialStatus.prodUp30 ? (device.vars.cn.substr(0, 4) |
									number:'1.2') : '-' }} {{ 'gr/L' }}</div></td>
							<td></td>
							<td></td>
							<td>
								<div *ngIf="device.isElectrolysis"><strong>{{ 'FLUJO_GAS' | translate }}</strong> {{ (!device.booleanVars.fe ? 'OFF' : 'ON') | translate }}</div>
							</td>
						</tr>
						<tr>
							<td><strong>{{ 'FLUJO_PALETA' | translate }}</strong> {{ (!device.vars.fs ? 'OFF' : 'ON') | translate }}</td>
							<td></td>
							<td class="text-danger">
								<div *ngIf="device.vars.at == 'L' || device.vars.at == 'H'">Ala.Temp</div>
								<div *ngIf="device.vars.as == 'L' || device.vars.as == 'H'">Ala.Salt</div>
							</td>
							<td class="text-danger">
								<div>{{ (device.vars.sf !== '0') ? 'Ala.Flow' : '' }}</div>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
			<div class="modal-footer">
				<button class="btn btn-primary" (click)="infoModal.hide()">{{ 'ACEPTAR' | translate }}</button>
			</div>
		</div>
	</div>
</div>
