<div class="box">
	<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
		<circle cx="50" cy="50" fill="none" [attr.stroke]="stroke" [attr.stroke-width]="strokeWidthCapped" [attr.r]="radius" [attr.stroke-dasharray]="rotate ? circumference : null">
			<animateTransform attributeName="transform" type="rotate" calcMode="linear" [attr.values]="clockwise ? '0 50 50;360 50 50' : '360 50 50;0 50 50'" keyTimes="0;1" dur="2s" begin="0s" repeatCount="indefinite" />
		</circle>
	</svg>
	<div class="content">
		<ng-content></ng-content>
	</div>
</div>
