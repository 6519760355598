import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

// services
import { BaseService } from './base.service';
import { AuthService } from './auth.service';
import { AppConfig } from '../AppConfig';

@Injectable()
export class ProfessionalsService extends BaseService {
	constructor(public _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public find(limit: number = 20, page: number = 1, conditions: any = null, order: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		let parameters = [];

		if (conditions) {
			parameters.push('conditions=' + JSON.stringify(conditions));
		}

		if (order) {
			parameters.push('order=' + JSON.stringify(order));
		}

		if(parameters.length > 0) {
			body = parameters.join('&');
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/' + limit + '/' + offset, body , { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}

	public get(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/view/' + id, null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	public delete(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/delete/' + id, null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	public findClients(limit: number = 20, page: number = 1, conditions: any = null, order: any = null) {
		let offset = (page - 1) * limit;
		let body = null;
		let parameters = [];

		if (conditions) {
			parameters.push('conditions=' + JSON.stringify(conditions));
		}

		if (order) {
			parameters.push('order=' + JSON.stringify(order));
		}

		if(parameters.length > 0) {
			body = parameters.join('&');
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/findclients/' + limit + '/' + offset, body, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	public deleteClients(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/deleteclients', 'id=' + id, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	public findHasPro(idDev: number, idPro: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/professionals/haspro', 'idpro=' + idPro + '&iddev=' + idDev, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}
}
