<router-outlet></router-outlet>
<div class="col-lg-3" #cookieBar id="cookiesAlert">
	<div class="alert alert-info text-center">
		<div [innerHTML]="'INFO_COOKIES' | translate"></div>
		<div>
			<a href="javascript:void(0)" class="ok" (click)="acceptCookie()"><b>OK</b></a>
		</div>
	</div>
</div>
<footer *ngIf="loggedUser" class="navbar-fixed-bottom container-fluid visible-lg">
	<div id="interestLinks" class="row">
		<div padding-8 class="col-sm-6">
			<a href="mailto:poolstation@poolstation.net">{{ 'CONTACTO' | translate }}</a> |
			<a href="javascript:{}" [routerLink]="['docs', 'legal']">{{ 'AVISO_LEGAL' | translate }}</a> |
			<a href="javascript:{}" [routerLink]="['docs', 'cookies']">{{ 'POLITICA_COOKIES' | translate }}</a> |
			<a href="javascript:{}" [routerLink]="['docs', 'privacy']">{{ 'PRIVACY_POLICY' | translate }}</a>
			<!-- <a href="javascript:{}" [routerLink]="['docs', 'thanks']">{{ 'AGRADECIMIENTOS' | translate }}</a> -->
			<!-- <a href="javascript:{}" [routerLink]="['docs', 'walkthrough']">{{ 'PASEO_POOLSTATION' | translate }}</a> -->
		</div>
		<div padding-8 id="copyright" class="col-sm-6 text-right">
      <span>{{getCurrentYear()}} </span> <!-- JLF Aquí imprimimos la variable generada en el .ts con la fecha -->
			<span text-ellipsis [innerHTML]="'COPYRIGHT' | translate"></span>
		</div>
	</div>
</footer>
<simple-notifications [options]="{position: ['bottom', 'right'], theClass: 'notifications', timeOut: 5000, showProgressBar: false, pauseOnHover: false, clickToClose: true}"></simple-notifications>
