// utils
import * as _ from 'lodash';
import * as moment from 'moment';

export class Program {
	public colors: any[];
	public c: number;

	private stretches: any[];
	private _colors: string[];
	public overlapped = false;

	get current(): string {
		return this._current;
	}

	set current(value: string) {
		this._current = value;
	}

	get prev(): string {
		return this._prev;
	}

	set prev(value: string) {
		this._prev = value;
	}

	get twilight(): any {
		if (!this._twilight || this._twilight.length !== 10) {
			this._twilight = '0000000000';
		}

		return {
			raw: this._twilight,
			active: this._twilight[0] === '1',
			preset: parseInt(this._twilight[this._twilight.length - 1], 10),
			sunrise: {
				sign: (this._twilight[1] === '0' ? '+' : '-') || '+',
				hours: parseInt(this._twilight[2], 10) || 0,
				minutes: parseInt(this._twilight.substring(3, 5), 10) || 0
			},
			sunset: {
				sign: (this._twilight[5] === '0' ? '+' : '-') || '+',
				hours: parseInt(this._twilight[6], 10) || 0,
				minutes: parseInt(this._twilight.substring(7, 9), 10) || 0
			}
		};
	}

	set twilight(value: any) {
		this._twilight = value;
	}

	get sunriseOffsetSign(): number {
		return parseInt(this._twilight[1], 10);
	}

	set sunriseOffsetSign(value: number) {
		this._twilight = this._twilight.substring(0, 1) + value + this._twilight.substring(2, 10);
		this.colors = this.getColors();
	}

	get sunriseOffsetHours(): number {
		return parseInt(this._twilight[2], 10) || 0;
	}

	set sunriseOffsetHours(value: number) {
		this._twilight = this._twilight.substring(0, 2) + value + this._twilight.substring(3, 10);
		this.colors = this.getColors();
	}

	get sunriseOffsetMinutes(): number {
		return parseInt(this._twilight.substring(3, 5), 10) || 0;
	}

	set sunriseOffsetMinutes(value: number) {
		this._twilight = this._twilight.substring(0, 3) + _.padStart(value, 2, 0) + this._twilight.substring(5, 10);
		this.colors = this.getColors();
	}

	get sunsetOffsetSign(): number {
		return parseInt(this._twilight[5], 10);
	}

	set sunsetOffsetSign(value: number) {
		this._twilight = this._twilight.substring(0, 5) + value + this._twilight.substring(6, 10);
		this.colors = this.getColors();
	}

	get sunsetOffsetHours(): number {
		return parseInt(this._twilight[6], 10) || 0;
	}

	set sunsetOffsetHours(value: number) {
		this._twilight = this._twilight.substring(0, 6) + value + this._twilight.substring(7, 10);
		this.colors = this.getColors();
	}

	get sunsetOffsetMinutes(): number {
		return parseInt(this._twilight.substring(7, 9), 10) || 0;
	}

	set sunsetOffsetMinutes(value: number) {
		this._twilight = this._twilight.substring(0, 7) + _.padStart(value, 2, 0) + this._twilight.substring(9, 10);
		this.colors = this.getColors();
	}

	get sunriseWithOffset() {
		if (!this.sunriseOffsetSign) {
			return moment(this.sunrise).add(this.sunriseOffsetHours, 'hours').add(this.sunriseOffsetMinutes, 'minutes');
		} else {
			return moment(this.sunrise).subtract(this.sunriseOffsetHours, 'hours').subtract(this.sunriseOffsetMinutes, 'minutes');
		}
	}

	get sunsetWithOffset() {
		if (!this.sunsetOffsetSign) {
			return moment(this.sunset).add(this.sunsetOffsetHours, 'hours').add(this.sunsetOffsetMinutes, 'minutes');
		} else {
			return moment(this.sunset).subtract(this.sunsetOffsetHours, 'hours').subtract(this.sunsetOffsetMinutes, 'minutes');
		}
	}

	get preset(): number {
		return parseInt(this._twilight[this._twilight.length - 1], 10);
	}

	set preset(value: number) {
		this._twilight = this._twilight.substring(0, 9) + value;
		this.colors = this.getColors();
	}

	get automatic(): number {
		return parseInt(this._twilight[0], 10);
	}

	set automatic(value: number) {
		this._twilight = +value + this._twilight.substring(1, 10);
		this.refresh();
	}

	get twilightProgram(): number {
		return this._twilight[0] === '0' ? 0 : parseInt(this._twilight[this._twilight.length - 1], 10);
	}

	set twilightProgram(value: number) {
		this._twilight = value ? 1 + _.padStart(value.toString(), 9, '0') : '1000000000';
	}

	/**
	 * Creates an instance of Program.
	 *
	 * @param {string} _current
	 * @param {string} _prev
	 * @param {string} _twilight
	 *
	 * @memberof Program
	 */
	constructor(private _current: string, private _prev: string, private _twilight: string, public sunrise: any, public sunset: any) {
		this.c = this.twilightProgram;
		this.colors = this.getColors();
		this.stretches = this.getStretches();
	}

	public getStretches(): any[] {
		let init: number = null;
		let end: number = null;

		this.stretches = [];

		for (let H = 0; H < 24; H++) {
			if (['0', '2', '4', '6', '8', 'A', 'C', 'E'].indexOf(this._prev[H]) !== -1 && init !== null) {
				end = H * 60;
				this.addStretch(init, end);
				init = end = null;
			}

			if (['1', '3', '5', '7', '9', 'B', 'D', 'F'].indexOf(this._prev[H]) !== -1 && init === null) {
				init = H * 60;
			}

			switch (this._prev[H]) {
				// -
				case '0':
					break;
				// 0-15
				case '1':
					end = H * 60 + 15;
					break;
				// 15-30
				case '2':
					init = H * 60 + 15;
					end = H * 60 + 30;
					break;
				// 0-30
				case '3':
					end = H * 60 + 30;
					break;
				// 30-45
				case '4':
					init = H * 60 + 30;
					end = H * 60 + 45;
					break;
				// 0-15 30-45
				case '5':
					end = H * 60 + 15;
					this.addStretch(init, end);
					init = H * 60 + 30;
					end = H * 60 + 45;
					break;
				// 15-45
				case '6':
					init = H * 60 + 15;
					end = H * 60 + 45;
					break;
				// 0-45
				case '7':
					end = H * 60 + 45;
					break;
				// 45-60
				case '8':
					init = H * 60 + 45;
					break;
				// 0-15 45-60
				case '9':
					end = H * 60 + 15;
					this.addStretch(init, end);
					init = H * 60 + 45;
					end = null;
					break;
				// 15-30 45-60
				case 'A':
					init = H * 60 + 15;
					end = H * 60 + 30;
					this.addStretch(init, end);
					init = H * 60 + 45;
					end = null;
					break;
				// 0-30 45-60
				case 'B':
					end = H * 60 + 30;
					this.addStretch(init, end);
					init = H * 60 + 45;
					end = null;
					break;
				// 30-60
				case 'C':
					init = H * 60 + 30;
					break;
				// 0-15 30-60
				case 'D':
					end = H * 60 + 15;
					this.addStretch(init, end);
					init = H * 60 + 30;
					end = null;
					break;
				// 15-60
				case 'E':
					init = H * 60 + 15;
					break;
				// 0-60
				case 'F':
				default:
					break;
			}

			// last
			if (H === 23 && ['8', '9', 'A', 'B', 'C', 'D', 'E', 'F'].indexOf(this._prev[H]) !== -1) {
				end = H * 60 + 60;
			}

			if (init != null && end != null) {
				this.addStretch(init, end);
				init = end = null;
			}
		}

		return this.stretches;
	}

	public getColors(): any[] {
		let sunrise = [this.sunriseWithOffset.hour(), this.sunriseWithOffset.minute()];
		let sunset: any;
		let color = '--';
		let sunriseo: any;
		let sunseto: any;

		if (this.sunrise.day() > this.sunriseWithOffset.day()) {
			sunrise = [0, 0];
		}

		sunset = [this.sunsetWithOffset.hour(), this.sunsetWithOffset.minute()];

		if (this.sunset.day() < this.sunsetWithOffset.day()) {
			sunset = [24, 0];
		}

		this._colors = [];

		for (let H = 0; H < 24; H++) {
			color = parseInt(this._prev[H], 16).toString(2);
			color = _.map(_.padStart(color, 4, '0'), (o) => parseInt(o, 2) ? 'Y' : 'W').join('');
			this._colors.push(color[3] + color[2]);
			this._colors.push(color[1] + color[0]);
		}

		// automatic stretches
		sunriseo = (sunrise[0] * 4 + sunrise[1] / 15) / 2;
		sunseto = (sunset[0] * 4 + sunset[1] / 15) / 2;

		// on
		if ([2, 5].indexOf(this.twilightProgram) !== -1) {
			for (let i = 0; i < Math.floor(sunriseo); i++) {
				this._colors[i] = 'BB';
			}

			if (sunriseo % 1 !== 0) {
				this._colors[Math.floor(sunriseo)] = 'B' + this._colors[Math.floor(sunriseo)][1];
			}

			if (sunseto % 1 !== 0) {
				this._colors[Math.floor(sunseto)] = this._colors[Math.floor(sunseto)][0] + 'B';
			}

			for (let i = Math.ceil(sunseto); i < 96; i++) {
				this._colors[i] = 'BB';
			}
		}

		if ([1, 3].indexOf(this.twilightProgram) !== -1) {
			if (sunriseo % 1 !== 0) {
				this._colors[Math.floor(sunriseo)] = this._colors[Math.floor(sunriseo)][0] + 'B';
			}

			for (let i = Math.ceil(sunriseo); i < Math.floor(sunseto); i++) {
				this._colors[i] = 'BB';
			}

			if (sunseto % 1 !== 0) {
				this._colors[Math.floor(sunseto)] = 'B' + this._colors[Math.floor(sunseto)][1];
			}
		}

		// off
		if ([1, 6].indexOf(this.twilightProgram) !== -1) {
			for (let i = 0; i < Math.floor(sunriseo); i++) {
				this._colors[i] = 'RR';
			}

			if (sunriseo % 1 !== 0) {
				this._colors[Math.floor(sunriseo)] = 'R' + this._colors[Math.floor(sunriseo)][1];
			}

			if (sunseto % 1 !== 0) {
				this._colors[Math.floor(sunseto)] = this._colors[Math.floor(sunseto)][0] + 'R';
			}

			for (let i = Math.ceil(sunseto); i < 96; i++) {
				this._colors[i] = 'RR';
			}
		}

		if ([2, 4].indexOf(this.twilightProgram) !== -1) {
			if (sunriseo % 1 !== 0) {
				this._colors[Math.floor(sunriseo)] = this._colors[Math.floor(sunriseo)][0] + 'R';
			}

			for (let i = Math.ceil(sunriseo); i < Math.floor(sunseto); i++) {
				this._colors[i] = 'RR';
			}

			if (sunseto % 1 !== 0) {
				this._colors[Math.floor(sunseto)] = 'R' + this._colors[Math.floor(sunseto)][1];
			}
		}

		return this._colors;
	}

	/**
	 * Añade tramos de horas a los programas
	 *
	 * @param init indice de inicio
	 * @param end indice de finalización
	 */
	public addStretch(init: number = null, end: number = null) {
		if (_.isNil(init)) {
			init = _.indexOf(this._prev, '0') * 60;
			if (init < 0) {
				init = 0;
			}
		}

		if (_.isNil(end)) {
			/*end = (_.findIndex(this._prev, o => o !== '0', init / 60) * 60) || 1440;
			if (end <= 0) {
				end = init + 60;
			}*/
			end = 1440;
		}

		this.stretches.push({ init, end });
	}

	/**
	 * Elimina el tramo de hora pasado por parámetro
	 *
	 * @param {*} stretch tramo
	 * @memberof Program
	 */
	public deleteStretch(stretch: any) {
		_.remove(this.stretches, stretch);
	}

	/**
	 * Actualiza el programa respecto a los tramos de hroa configurados
	 *
	 * @memberof Program
	 */
	public refresh() {
		// tslint:disable-next-line:max-line-length
		let p = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]];

		this.overlapped = false;

		for (let stretch of this.stretches) {
			let init = [Math.floor(stretch.init / 60), stretch.init % 60];
			let end = [Math.floor(stretch.end / 60), stretch.end % 60];

			for (let h = init[0]; h <= end[0]; h++) {
				if (h === init[0]) {
					let max = init[0] === end[0] ? end[1] / 15 : 4;
					for (let m = init[1] / 15; m < max; m++) {
						p[h][m] = 1;
					}
				} else if (h === end[0]) {
					for (let m = 0; m < end[1] / 15; m++) {
						p[h][m] = 1;
					}
				} else {
					p[h] = [1, 1, 1, 1];
				}
			}
		}

		this._prev = _.map(p, o => parseInt(_.reverse(o).join(''), 2).toString(16).toUpperCase());
		this.colors = this.getColors();
	}
}
