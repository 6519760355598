import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { CaptchaService } from '../../services/captcha.service';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from "ngx-captcha";
import { getFullYear } from 'ngx-bootstrap/chronos';
import { RegisterStateService } from 'app/services/register-state.service';

@Component({
	selector: 'register',
	templateUrl: 'register.component.html',
	styleUrls: ['register.component.scss']
})

export class RegisterComponent implements OnInit {
	@ViewChild('reCaptcha') public captcha: ReCaptcha2Component;
	public saving: boolean = false;
	public captchaValidation: boolean = false;
	public completed: number = 0;
	public token: string;
	public conditions: boolean = false;
	public passwordAux: string = '';
	public browserLang: any;
	public user: string = '';
	public website: string = '';
	public messageCompleted: boolean = false;
	public currentYear = getFullYear(new Date(), true);
	public account: any = {
		idRole: 2,
		companyName: '',
		email: '',
		password: '',
		name: '',
		surname: '',
		phone: '',
		country: '',
		location: '',
		address: ''
	};

	constructor(
		public captchaSvc: CaptchaService,
		private _translate: TranslateService,
		private _notificationSvc: NotificationsService,
		private _authSvc: AuthService,
		private _router: Router,
		private _registerPosition: RegisterStateService) {
	}

	ngOnInit() {
		if (this._authSvc.user) {
			this._router.navigate(['/devices']);
		}
		this.completed = this._registerPosition.execute();
		this.browserLang = this._translate.getBrowserLang();
	}

	public regPosition(option) {
		switch (option) {
			case 'next':
				if (this.account.idRole === 3 && this.account.companyName === '') {
					this._notificationSvc.html(this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS'), NotificationType.Error);
					document.getElementById('company').focus();
				} else {
					this.completed++;
				}
				break;
			case 'back':
				this.completed--;
				break;
			default: break;
		}
	}

	public validate() {
		let checked: boolean;
		this.saving = true;
		checked = this._checkLength('email', 6, 80);

		if (checked) { checked = this._checkLength('password', 5, 16); }
		if (checked) { checked = this._checkLength('name', 2, 50); }
		if (checked) { checked = this._checkLength('surname', 3, 150); }
		if (checked) { checked = this._checkLength('country', 2, 250); }
		if (checked) { checked = this._checkLength('location', 2, 250); }
		if (checked) { checked = this._checkLength('address', 2, 250); }
		if (checked) { checked = this._validateField(/^[\(\)\s\-\+\d]{9,17}$/, 'phone'); }
		// tslint:disable-next-line:max-line-length
		if (checked) { checked = this._validateField(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i, 'email'); }
		if (checked) { checked = this._validateFieldPassword(); }
		if (checked) { checked = this._validateField(/^(\D)+$/i, 'name'); }
		if (checked) { checked = this._validateField(/^(\D)+$/i, 'surname'); }
		if (checked) { checked = this._checkCaptcha(); }
		//this.acceptTerms = 'XXX';


		if (checked) {
			if ((this.website === '' || this.website === undefined) && (this.user === '' || this.user === undefined)) {
				if (checked) { this._checkConditions() ? this._save() : checked = false; }
			}
		}
		if (!checked) {
			this.saving = false;
		}
	}

	private _save() {
		let date = new Date();

		const userDto = {
			usr: this.account.email,
			nombre: this.account.name,
			apellidos: this.account.surname,
			pws: this.account.password,
			telefono: this.account.phone,
			id_pais: this.account.country,
			id_loc: this.account.location,
			direccion: this.account.address,
			id_role: this.account.idRole,
			companyName: this.account.companyName,
		};

		this._authSvc.register(userDto, this.token).then((response) => {
			this._translate.get('ACCOUNT_CREATED', {
				name: this.account.name
			}).subscribe((result) => {
				this.saving = false;
				this.completed = 2;
				this.messageCompleted = result;
			});
		}).catch((reason) => {
			this.captchaValidation = false;
			this.captcha.resetCaptcha();
			if(reason.status == 409){
				this._notificationSvc.error('', this._translate.instant('EMAIL_YA_REGISTRADO'));
			} else {
				this._notificationSvc.error('', this._translate.instant(reason.message));
			}
			this.saving = false;
		});
	}

	private _checkLength(field: string, min: number, max: number): boolean {
		let result: boolean = true;
		let translation: any = { email: 'EMAIL', password: 'CONTRASENYA', name: 'NOMBRE', surname: 'APELLIDOS', phone: 'TELEFONO', country: 'PAIS', location: 'LOCALIDAD', address: 'DIRECCION' };

		translation = this._translate.instant(translation[field]);

		if (this.account[field].length < min || this.account[field].length > max) {
			let message: string = '';

			message += this._translate.instant('VALIDA_LONGITUD_1');
			message += ' ' + translation + ' ';
			message += this._translate.instant('VALIDA_LONGITUD_2') + ' ' + min + ' ';
			message += this._translate.instant('VALIDA_LONGITUD_3') + ' ' + max + ' ';
			message += this._translate.instant('VALIDA_LONGITUD_4');

			this._notificationSvc.error('', message);
			result = false;
		}

		return result;
	}

	private _validateFieldPassword(): boolean {
		let result: boolean = true;

		if (this.account.password !== this.passwordAux) {
			this._notificationSvc.error('', this._translate.instant('VALIDA_PASSWORD_DISTINTOS'));
			result = false;
		}

		return result;
	}

	private _validateField(pattern, field: string): boolean {
		let result: boolean = true;
		let translation: any = { email: 'VALIDA_EMAIL', phone: 'TELEFONO', name: 'VALIDA_NOMBRE', surname: 'VALIDA_APELLIDOS' };

		if (!pattern.test(this.account[field])) {
			this._notificationSvc.error('', this._translate.instant(translation[field]));
			result = false;
		}

		return result;
	}

	private _checkConditions(): boolean {
		if (!this.conditions) {
			this._notificationSvc.error('', this._translate.instant('VALIDA_POLITICA'));
		}

		return this.conditions;
	}

	private _checkCaptcha() {
		if (!this.captchaValidation) {
			this._notificationSvc.html(this._translate.instant('VERIFICACION_RECAPCTHA_INCORRECTA'), NotificationType.Error);
		}

		return this.captchaValidation;
	}

	public onCaptchaSuccess(token: string) {
		this.token = token;
		if (this.token) {
			this.captchaValidation = true;
		}
	}
}
