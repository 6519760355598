import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// vendors
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// services
import { AuthService } from '../../services/auth.service';
import { DevicesService } from '../../services/devices.service';
import { RequestsService } from '../../services/requests.service';
import { UsersService } from '../../services/users.service';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'request',
	templateUrl: 'request.component.html'
})

export class RequestComponent implements OnInit {
	public action: string;
	public devices: any[];
	public deviceSeries: number;
	public permissions: any = {};
	public request: any;
	public loggedUser: any = this._authSvc.user;
	public searchText: string;
	public switches: boolean[] = [false, false, false, false, false, false];
	public blockButtons: boolean = false;

	constructor(
		private _userSvc: UsersService,
		private _notificationService: NotificationsService,
		private activatedRoute: ActivatedRoute,
		private _translate: TranslateService,
		private _authSvc: AuthService,
		private _devicesSvc: DevicesService,
		private _requestSvc: RequestsService) {
		this.activatedRoute.params.subscribe((params) => {
			let id = +params['id'];

			if (id && _.isNumber(id)) {
				this.action = 'edit';

				this._requestSvc.get(id).then((request) => {
					this.request = request;
					this.deviceSeries = (this.request.device.relays.length > 4) ? 1 : 2;
					this._setSwitches();
				});
			} else {
				this.request = {
					idUser: this.loggedUser['id_user'],
					idDevice: null,
					solicitud: 'PENDIENTE',
					permisos: '222222',
					notificar_alarma: 0,
					alarma_dia: 0,
					email: ''
				};
			}
		});
	}

	public ngOnInit() {
		this.activatedRoute.params.subscribe((params) => {
			if (!params['id']) {
				this._devicesSvc.find(9999, 1).then((devices) => {
					this.devices = devices['items'];
				});
			}
		});
	}

	public save() {
		this.blockButtons = true;
		this._requestSvc.update(this.request.id, _.pick(this.request, ['permisos'])).then(result => {
			this._notificationService.success('', this._translate.instant('DATOS_GUARDADOS_CORRECTAMENTE'));
			this.blockButtons = false;
		}).catch(reason => {
			this._notificationService.html(reason.message, NotificationType.Error);
			this.blockButtons = false;
		});
	}

	public async newRequest() {
		if (this.loggedUser.isDemo) {
			this._notificationService.info('', this._translate.instant('DEMO'));
			return;
		}
		const professionalBindingRequest = {
			idDevice: parseInt(this.request.idDevice),
			idUser: this.request.idUser,
			email: this.request.email,
		};
		this.blockButtons = true;
		const device = this.devices.find(d => d.id === professionalBindingRequest.idDevice);

		let professional;
		try {
			professional = await this._userSvc.getProByMail(professionalBindingRequest.email);
		} catch (response) {
			this._notificationService.error('', this._translate.instant('ERR_USER_NO_ENCONTRADO'));
			this.blockButtons = false;
			return;
		}

		try {
			await this._requestSvc.save(professionalBindingRequest);
		} catch (response) {
			this._notificationService.error('', this._translate.instant(response.error.message));
			this.blockButtons = false;
			return;
		}

		const successMessage = this._translate.instant('OK_MANTENEDOR_SOLICITUD_1')
			+ ` <strong>${professional.nombre} ${professional.apellidos}</strong>`
			+ '<br>'
			+ this._translate.instant('OK_MANTENEDOR_SOLICITUD_2');
		this._notificationService.html(successMessage, NotificationType.Success);
		this.blockButtons = false;
	}

	changeSwitch(index) {
		if (this.request.permissions[index] === 0) {
			let swt: boolean = true;

			this.request.permissions[index] = (this.request.weeklyProgrammerPermission) ? '2' : '1';
		} else {
			if (this.request.weeklyProgrammerPermission) {
				this.request.weeklyProgrammerPermission = false;
			}

			this.request.permissions[index] = '0';
		}
	}

	changeWeeklyProgrammerPermission() {
		if (this.request.weeklyProgrammerPermission) {
			for (let i = 0; i < this.switches.length; i++) {
				this.switches[i] = true;
			}
		}
	}

	private _setSwitches() {
		for (let i = 0; i < 6; i++) {
			this.switches[i] = (this.request.permissions[i] === 0) ? false : true;
		}
	}
}
