import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevicesService } from '../../services/devices.service';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '../../entities/device';
import { Chart } from 'angular-highcharts';

import * as moment from 'moment-timezone';

declare var SunCalc: any;

@Component({
	selector: 'sun',
	templateUrl: 'sunhours.component.html'
})

export class SunhoursComponent implements OnInit {
	public device: any;
	public options: any;
	public sunChart: Chart;

	constructor(
		private route: ActivatedRoute,
		private _deviceSvc: DevicesService,
		private _translate: TranslateService) {
		}

	ngOnInit() {
		this.loadDevice();
	}

	public loadDevice() {
		this.route.params.subscribe((params) => {
			this._deviceSvc.get(params['id']).then((device: Device) => {
				this.device = device;
				this._sunGraphic();
			}).catch((error) => {
				this.loadDevice();
			});
		});
	}

	private _sunGraphic() {
		let title: string;
		const sunData = this._sunData(moment().year());
		const dataTitle = this._translate.instant('SUNRISE') + '-' + this._translate.instant('SUNSET');
		const sunriseTitle = this._translate.instant('SUNRISE');
		const sunsetTitle = this._translate.instant('SUNSET');

		if (this.device.id_loc !== '') {
			title = this._translate.instant('DURACION_DEL_DIA') + ' ' + this.device.id_loc;
		} else {
			title = this._translate.instant('DURACION_DEL_DIA') + ' ' + this._translate.instant('COORDENADAS') + '[' + this.device.lat + ', ' + this.device.long + ']';
		}

		this.options = {
			chart: {
				type: 'arearange',
			/* 	plotBackgroundColor: '#D5E1F1', */
				panning: false,
				pinchType: 'none',
				//width: window.innerWidth - 10,
				height: 800,
				backgroundColor:'transparent'
			},
			title: {
				text: title,
				style: {
					fontWeight: 'bold',
					fontSize: '26px'
				}
			},
			xAxis: {
				type: 'datetime',
				title: {
					text: this._translate.instant('FECHA').toUpperCase(),
				},
				endOnTick: false
			},
			yAxis: {
				title: {
					text: this._translate.instant('HORA').toUpperCase(),
				},
				min: 0,
				max: 24,
				endOnTick: true,
				tickInterval: 1,
				minorGridLineWidth: 0,
				minorTickWidth: 0.5,
				floor: 0,
				ceiling: 24
			},
			plotOptions: {
				area: {
					marker: {
						radius: 1.25,
						enabled:true,
						fillColor:'#FFFFFF',
						lineWidth:0.33,
						lineColor:null
					},
					lineWidth: 1,
					states: {
						hover: {
							lineWidth: 1
						}
					},
					threshold: null
				}
			},
			tooltip: {
				crosshairs: true,
				shared: true,
				formatter: function () {
					const timeHigh: any[] = [];
					const timeLow: any[] = [];
					const date: any = moment(this.x);

					timeHigh[0] = parseInt(this.points[0].point.high, 10);
					timeHigh[1] = Math.round((this.points[0].point.high - timeHigh[0]) * 60);

					if (timeHigh[0] < 10) {
						timeHigh[0] = '0' + timeHigh[0];
					}

					if (timeHigh[1] < 10) {
						timeHigh[1] = '0' + timeHigh[1];
					}

					timeLow[0] = parseInt(this.points[0].point.low, 10);
					timeLow[1] = Math.round((this.points[0].point.low - timeLow[0]) * 60);

					if (timeLow[0] < 10) {
						timeLow[0] = '0' + timeLow[0];
					}

					if (timeLow[1] < 10) {
						timeLow[1] = '0' + timeLow[1];
					}

					return '<strong>' + date.format('LL') + '</strong><br>' + sunriseTitle + ': ' + timeLow[0] + ':' + timeLow[1] + ' - ' + sunsetTitle + ': ' + timeHigh[0] + ':' + timeHigh[1];
				}
			},
			legend: {
			},
			credits: {
				enabled: false
			},
			series: []
		};

		this.options.series = [{
			name: dataTitle,
			data: sunData,
			lineWidth: 2,
			fillColor: 'rgba(255, 228, 0, 0.62)',
			lineColor: '#4679BD',
			pointStart: Date.UTC(moment().year(), 0, 1),
			pointEnd: Date.UTC(moment().year(), 12, 31),
			pointInterval: 24 * 3600 * 1000
		}];

		this.sunChart = new Chart(this.options);
	}

	private _sunData(y: number): any[] {
		let times: any;
		let sunrise: number;
		let sunset: number;
		let sunriseMoment: any;
		let sunsetMoment: any;
		let year: number = y;
		let date: any = moment(year + '-01-01');
		const data: any[] = [];
		let arrAux: number[];

		date.tz(this.device.timezone);

		while (year === y) {
			times = SunCalc.getTimes(date, this.device.lat, this.device.longitud);
			sunriseMoment = moment(times.sunrise).tz(this.device.timezone);
			sunsetMoment = moment(times.sunset).tz(this.device.timezone);

			sunrise = sunriseMoment.hour() + sunriseMoment.minutes() / 60;
			sunset = sunsetMoment.hour() + sunsetMoment.minutes() / 60;
			arrAux = [sunrise, sunset];
			data.push(arrAux);
			date = date.add(1, 'd');
			year = date.year();
		}

		return data;
	}
}
