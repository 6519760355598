import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RolesPipe } from '../../pipes/roles.pipe';
import ROLES from '../../entities/roles';

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
	@Output() userLogout = new EventEmitter(); 
	@ViewChild('inputFile', {static: true}) inputFile: any;
	@ViewChild('deleteModal', {static: false}) public deleteModal: ModalDirective;
	@ViewChild('adviceDeletedAccount', {static: false}) public adviceDeletedAccount: ModalDirective;

	public user: any;
	public blockButtons: boolean = false;
	public editableInput: boolean = false;
	public password: string[] = [];
	public loggedUser: any = this._authSvc.user;
	public file: File;
	public imgSrc: string = '/assets/images/avatares/default.jpg';
	private _action: string;
	private _mail: any = {
		to: '',
		subject: '',
		body: ''
	};

	constructor(
		private route: ActivatedRoute,
		private _router: Router,
		private _translate: TranslateService,
		private _usersSvc: UsersService,
		private _notificationService: NotificationsService,
		private _authSvc: AuthService) {
	}

	public ngOnInit() {
		this.route.params.subscribe((params) => {
			this._action = params['action'];

			if (this._action !== 'new') {
				let id = params['id'];

				if (!id) {
					id = this.loggedUser['id_user'];
				}
				this._usersSvc.get(id).then((user: any) => {
					this.user = user;
					this.imgSrc = this.user.avatar;
				});
			} else {
				this.user = {
					id: null,
					apellidos: '',
					direccion: '',
					id_loc: '',
					id_pais: '',
					id_role: 2,
					idioma_notificaciones: '',
					nombre: '',
					pws: '',
					telefono: '',
					unit_temp: '',
					unit_vel: '',
					usr: '',
					avatar: null
				};
			}
		});
	}

	compressImage(src, newX, newY) {
		return new Promise((res, rej) => {
		  const img = new Image();
		  img.src = src;
		  img.onload = () => {
			const elem = document.createElement('canvas');
			elem.width = newX;
			elem.height = newY;
			const ctx = elem.getContext('2d');
			ctx.drawImage(img, 0, 0, newX, newY);
			const data = ctx.canvas.toDataURL().split('data:').pop();
			res(data);
		  }
		  img.onerror = error => rej(error);
		})
	  }

	onFileChange(event) {
		const reader = new FileReader();
		if(event.target.files && event.target.files.length) {
		  const [file] = event.target.files;
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			this.imgSrc = reader.result as string;
			this.compressImage(this.imgSrc, 100, 100).then(compressed => {
				this.user.avatar = compressed;
			  })
		  };
		}
	  }

	public save() {
		let changes: any;

		this.user.pws = this.password[0];

		changes = _.pick(this.user, [
			'id',
			'apellidos',
			'direccion',
			'id_loc',
			'id_pais',
			'id_role',
			'idioma_notificaciones',
			'nombre',
			'pws',
			'telefono',
			'unit_temp',
			'unit_vel',
			'usr',
			'avatar']);
			if (this._action === 'new') {
					this._usersSvc.new(changes).then((result: any) => {
							this._notificationService.html(this._translate.instant('OK_CREAR_USUARIO'), NotificationType.Success);

							this.settingNewStatusToLoggedUser(changes)
							setTimeout(() => { this._router.navigate(['/users']); }, 5500);
					}).catch((error: any) => {
							if (error.message) {
									this._notificationService.error('', this._translate.instant(error.message));
							}
							this.blockButtons = false;
					});
			} else {
					this._usersSvc.save(changes).then((result: any) => {
							this._notificationService.html(this._translate.instant('OK_MODIFICAR_USUARIO'), NotificationType.Success);
							this.settingNewStatusToLoggedUser(changes)
							this.password = [];

							this.blockButtons = false;

					}).catch((error: any) => {
							if (error.message) {
									this._notificationService.error('', this._translate.instant(error.message));
							}
							this.blockButtons = false;
					});
			}

	}
	private settingNewStatusToLoggedUser(changes) {
			if (this.loggedUser.id_user === this.user.id) {
					this.loggedUser.avatar = changes.avatar;
					this.loggedUser.unit_temp = changes.unit_temp;
					this.loggedUser.unit_vel = changes.unit_vel;
					this._authSvc.user = this.loggedUser;
			}
	}
	public checkSave() {
		let checked: boolean = false;

		this.blockButtons = true;

		checked = this._checkLength('usr', 6, 80);
		if (checked) { checked = this._validatePassword(); }
		if (checked) { checked = this._checkLength('pws', 5, 16); }
		if (checked) { checked = this._checkLength('nombre', 2, 50); }
		if (checked) { checked = this._checkLength('apellidos', 3, 150); }
		if (checked) { checked = this._checkLength('id_pais', 2, 250); }
		if (checked) { checked = this._checkLength('id_loc', 2, 250); }
		if (checked) { checked = this._checkLength('direccion', 2, 250); }
		if (checked) { checked = this._validate(/^[\(\)\s\-\+\d]{9,17}$/, 'telefono'); }
		if (checked) { checked = this._validate(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i, 'usr'); }
		if (checked) { checked = this._validate(/^(\D)+$/i, 'nombre'); }
		if (checked) { checked = this._validate(/^(\D)+$/i, 'apellidos'); }
		checked ? this.save() : this.blockButtons = false;
	}

	private _checkLength(field: string, min: number, max: number): boolean {
		let result: boolean = true;
		let translation: any = { usr: 'EMAIL', nombre: 'NOMBRE', apellidos: 'APELLIDOS', telefono: 'TELEFONO', id_pais: 'PAIS', id_loc: 'LOCALIDAD', direccion: 'DIRECCION' };
		let message: string = '';

		if (field === 'pws') {
			if (!this.password[0]) {
				result = true;
			} else {
				if (this.password[0].length < min || this.password[0].length > max) {
					result = false;
				}
			}
		} else {
			translation = this._translate.instant(translation[field]);
			if (this.user[field].length < min || this.user[field].length > max) {
				result = false;
			}
		}

		if (!result) {
			message += this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS');
			message += '<br>';
			message += this._translate.instant('VALIDA_LONGITUD_1');
			message += ' ' + translation + ' ';
			message += (this._translate.instant('VALIDA_LONGITUD_2') + ' ' + min + ' ');
			message += (this._translate.instant('VALIDA_LONGITUD_3') + ' ' + max + ' ');
			message += this._translate.instant('VALIDA_LONGITUD_4');

			this._notificationService.html(message, NotificationType.Error);
		}

		return result;
	}

	private _validatePassword(): boolean {
    let passIsValid: boolean = true;

		if (this._action === 'new' && (!this.password[0] || !this.password[1])) {
			const message = this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS');
			this._notificationService.html(message, NotificationType.Error);
			passIsValid = false;
		}

		if (this.password[0] !== this.password[1]) {
      const message = this._translate.instant('VALIDA_PASSWORD_DISTINTOS');
      this._notificationService.html(message, NotificationType.Error);
      passIsValid = false;
    }

    return passIsValid
  }

	private _validate(pattern, field: string): boolean {
		let result: boolean = true;
		let translation: any = { usr: 'VALIDA_EMAIL', pws: 'VALIDA_PASSWORD', telefono: 'TELEFONO', nombre: 'VALIDA_NOMBRE', apellidos: 'VALIDA_APELLIDOS' };
		let message: string;

		if (field === 'pws') {
			if (!pattern.test(this.password[0])) {
				result = false;
			}
		} else {
			if (!pattern.test(this.user[field])) {
				result = false;
			}
		}

		if (!result) {
			message = this._translate.instant('TODOS_CAMPOS_OBLIGATORIOS') + '<br>' + this._translate.instant(translation[field]);
			this._notificationService.html(message, NotificationType.Error);
		}

		return result;
	}

	public showDelete() {
		if (this.user.id === this.loggedUser.id_user && this.loggedUser.id_role !== 1) {
				this.deleteModal.show();
		}
	}
	public deleteAccount() {
		this.deleteModal.hide();
		if (this.user.id === this.loggedUser.id_user && this.loggedUser.id_role !== 1 && !this.loggedUser.isDemo ) {
			this._usersSvc.deleteUser(this.loggedUser.id_user).then((response: any) => {
				this.adviceDeletedAccount.show();
				this.adviceDeletedAccount.onHide.subscribe(() => {
					this._authSvc.logout();
				});
			}).catch((error) => {
				if (error.message) {
					this._notificationService.html(this._translate.instant(error.message), NotificationType.Error);
				}
			});
		}
	}

	/** True when the user we're editing is different from the one logged in. */
	_editingAnotherUser(): boolean {
		return this._action !== 'new' && this.user.id !== this.loggedUser.id_user;
	}
	_canEditEmail(): boolean {
		return this._action !== 'new' && this.loggedUser.id_role != ROLES.ADMIN;
	}
}
