import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
/* import { HttpModule } from '@angular/http'; */
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ApplicationRef, enableProdMode } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
/* import { Http } from '@angular/http'; */
import { HttpClient } from '@angular/common/http';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { AgmCoreModule } from '@agm/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
/* import { ImageUploadModule } from 'ng2-imageupload'; */
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Injector, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale, deLocale, frLocale, itLocale, ptBrLocale, ruLocale } from 'ngx-bootstrap/locale';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as stock from 'highcharts/modules/stock.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as offlineExporting from 'highcharts/modules/offline-exporting.src';

export function highchartsModules() {
	// apply Highcharts Modules to this array
	//return [stock, more, exporting, exportData];
	return [stock, more, exporting,offlineExporting];
}

/*
 * Platform and Environment providers/directives/pipes
 */
import { ROUTES } from './app-routing.module';

// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { NoContentComponent } from './no-content';

// pages
import { ProfessionalsComponent } from './pages/professionals/professionals.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { DeviceComponent } from './pages/devices/device.component';
import { LoginComponent } from './pages/login/login.component';
import { RecoverComponent } from './pages/login/recover.component';
import { RegisterComponent } from './pages/login/register.component';
import { FamilyComponent } from './pages/devices/family.component';
import { InputsComponent } from './pages/devices/inputs.component';
import { RelaysComponent } from './pages/devices/relays.component';
import { StatsComponent } from './pages/stats/stats.component';
import { AlarmsComponent } from './pages/devices/alarms.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { UsersComponent } from './pages/users/users.component';
import { UserComponent } from './pages/users/user.component';
import { RequestComponent } from './pages/users/request.component';
import { LogsComponent } from './pages/action-logs/logs.component';
import { StatComponent } from './pages/devices/stat.component';
import { ProgrammerComponent } from './pages/devices/programmer.component';
import { AdvancedComponent } from './pages/devices/advanced.component';
import { SunhoursComponent } from './pages/devices/sunhours.component';
import { DocsComponent } from './pages/docs/docs.component';
import { Ds2Component } from './pages/devices/ds2.component';
import { VersionComponent } from './pages/version/version.component';
import { PbaComponent } from './pages/version/pba.component';
import { ModelsComponent } from './pages/version/models.component';
import { DevicesLogsComponent } from './pages/version/devices-logs.component';
import { UpdateLogsComponent } from './pages/version/update-logs.component';
import { HardwareComponent } from './pages/version/hardware.component';
import { FirmwareComponent } from './pages/version/firmware.component';
import { CompatibilityComponent } from './pages/version/compatibility.component';
import { ProgrammerlumiComponent } from './pages/devices/programmerlumi.component';
import { PrivacyComponent } from './pages/docs/privacy.component';
import { CookiesComponent } from './pages/docs/cookies.component';

// components
import { LoadingComponent } from './components/generic/loading/loading.component';
import { TableSorterComponent } from './components/generic/table-sorter/table-sorter.component';
import { PSHEaderComponent } from './components/generic/ps-header/ps-header.component';

// guards
import { AuthGuard } from './guards/auth.guard';

// Services
import { AuthService } from './services/auth.service';
import { DevicesService } from './services/devices.service';
import { StatsService } from './services/stats.service';
import { FirmwaresService } from './services/firmwares.service';
import { LogsService } from './services/logs.service';
import { UsersService } from './services/users.service';
import { ProfessionalsService } from './services/professionals.service';
import { RequestsService } from './services/requests.service';
import '../styles/styles.scss';
import { MessagesService } from './services/messages.service';
import { HistoryService } from './services/history.service';
import { WeatherService } from './services/weather.service';
import { GeolocationService } from './services/geolocation.service';
import { VersionsService } from './services/versions.service';
import { ImportService } from './services/import.service';
import { CaptchaService } from './services/captcha.service';

// pipes
import { SumBy } from './pipes/sumBy.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ToNumberPipe } from './pipes/toNumber.pipe';
import { TemperaturePipe } from './pipes/temperature.pipe';
import { ToFahrenheitPipe } from './pipes/to-fahrenheit.pipe';

// utils
import { MomentModule } from 'angular2-moment';
import { InputNumberComponent } from './components/generic/input-number/input-number.component';
import { OrdenableComponent } from './components/generic/ordenable/ordenable.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DoubleBarComponent } from './components/generic/double-bar/double-bar.component';
import { SimpleTinyComponent } from './components/generic/simple-tiny/simple-tiny.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { RolesPipe } from './pipes/roles.pipe';
import { TwoFactorsComponent } from './pages/users/twofactors.component';
import { VerificationComponent } from './pages/login/verification/verification.component';
import { DataProtectionComponent } from './pages/docs/data-protection.component';
import { SessionsComponent } from './pages/users/sessions/sessions.component';
import { RegisterStateService } from './services/register-state.service';

defineLocale('es', esLocale);
defineLocale('de', deLocale);
defineLocale('fr', frLocale);
defineLocale('it', itLocale);
defineLocale('pt', ptBrLocale);
defineLocale('ru', ruLocale);

// Enable production mode unless running locally
if (!/localhost/.test(document.location.host)) {
	enableProdMode();
}

// Application wide providers
const APP_PROVIDERS = [
	...APP_RESOLVER_PROVIDERS,
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptorService,
		multi: true
	},
	AppState,
];

interface StoreType {
	state: InternalStateType,
	restoreInputValues: () => void,
	disposeOldHosts: () => void
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
	bootstrap: [AppComponent],
	declarations: [
		AppComponent,
		PSHEaderComponent,
		ProfessionalsComponent,
		ClientsComponent,
		NoContentComponent,
		LoginComponent,
        TwoFactorsComponent,
		RecoverComponent,
		RegisterComponent,
		FamilyComponent,
		InputsComponent,
		RelaysComponent,
		StatsComponent,
		DevicesComponent,
		DeviceComponent,
		AlarmsComponent,
		MessagesComponent,
		UsersComponent,
		UserComponent,
		RequestComponent,
		LogsComponent,
		LoadingComponent,
		TableSorterComponent,
		StatComponent,
		AdvancedComponent,
		ProgrammerComponent,
		SunhoursComponent,
		SumBy,
		KeysPipe,
		FilterPipe,
		ToNumberPipe,
		DocsComponent,
		Ds2Component,
		InputNumberComponent,
		OrdenableComponent,
		SpinnerComponent,
		DoubleBarComponent,
		ClientsComponent,
		SimpleTinyComponent,
		VersionComponent,
		PbaComponent,
		ModelsComponent,
		DevicesLogsComponent,
		UpdateLogsComponent,
		HardwareComponent,
		FirmwareComponent,
		CompatibilityComponent,
		ProgrammerlumiComponent,
		TemperaturePipe,
		RolesPipe,
		ToFahrenheitPipe,
		PrivacyComponent,
		DataProtectionComponent,
		CookiesComponent,
		VerificationComponent,
		DataProtectionComponent,
		SessionsComponent
	],
	imports: [ // import Angular's modules
		AlertModule.forRoot(),
		ModalModule.forRoot(),
		NgbModule,
		ProgressbarModule.forRoot(),
		CollapseModule.forRoot(),
		BrowserModule,
		FormsModule,
		HttpClientModule,
		RoundProgressModule,
		MultiselectDropdownModule,
		BsDatepickerModule.forRoot(),
		RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		MomentModule,
		SimpleNotificationsModule.forRoot(),
		TabsModule.forRoot(),
		Ng2PageScrollModule.forRoot(),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyCDuuUvosA6CwctP-cL3lKhRNCNFu6UUTE'
		}),
		NgxCaptchaModule,
		CommonModule,
		UiSwitchModule,
		ChartModule,
		BrowserAnimationsModule
	],
	providers: [ // expose our Services and Providers into Angular's dependency injection
		APP_PROVIDERS,
		AuthGuard,
		DevicesService,
		StatsService,
		AuthService,
		UsersService,
		ProfessionalsService,
		RequestsService,
		LogsService,
		MessagesService,
		HistoryService,
		FirmwaresService,
		WeatherService,
		GeolocationService,
		RegisterStateService,
		VersionsService,
		ImportService,
		CaptchaService,
		{
			provide: HIGHCHARTS_MODULES,
			useFactory: highchartsModules
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		}
	]
})
export class AppModule {
	constructor(
		public appRef: ApplicationRef,
		public appState: AppState
	) { }
}
export class HighchartsModule {
	constructor() {
		const globalOptions: Highcharts.GlobalObject = {
			// http://api.highcharts.com/highstock/global.timezoneOffset
			timezoneOffset: new Date().getTimezoneOffset()
		};

		Highcharts.setOptions({
			global: globalOptions
		});
	}
}
function isLocalStorageNameSupported() {
		let testKey = 'test';
		let storage = window.localStorage;

		try {
				storage.setItem(testKey, '1');
				storage.removeItem(testKey);
				return true;
		} catch (error) {
				return false;
		}
}
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
	return () => new Promise<any>((resolve: any) => {
		const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

		locationInitialized.then(() => {
			let browserLang = translate.getBrowserLang();
				let userLang = "Ingles"
				if (!isLocalStorageNameSupported()) {
						userLang = this._getCookie('language') || 'Castellano';
				} else {
						userLang = localStorage.getItem('language') ?? sessionStorage.getItem('language');
				}

			if (!userLang) {
				let cookieValue = document.cookie;
				let cookieStart = cookieValue.indexOf(' language=');

				if (cookieStart === -1) {
					cookieStart = cookieValue.indexOf('language=');
				}

				if (cookieStart === -1) {
					cookieValue = null;
				} else {
					cookieStart = cookieValue.indexOf('=', cookieStart) + 1;
					let cookieEnd = cookieValue.indexOf(';', cookieStart);
					if (cookieEnd === -1) {
						cookieEnd = cookieValue.length;
					}

					cookieValue = cookieValue.substring(cookieStart, cookieEnd);
				}

				userLang = cookieValue;
			}

			if (userLang) {
				switch (userLang) {
					case 'Castellano':
						userLang = 'es';
						break;
					case 'Frances':
						userLang = 'fr';
						break;
					case 'Ingles':
						userLang = 'en';
						break;
					case 'Italiano':
						userLang = 'it';
						break;
					case 'Aleman':
						userLang = 'de';
						break;
					case 'Portugues':
						userLang = 'pt';
						break;
					case 'Holandes':
						userLang = 'nl';
						break;
					default:
						userLang = 'en';
						break;
				}
			} else {
				userLang = browserLang.match(/en|es|fr|de|it|pt|nl/) ? browserLang : 'en';
			}

			translate.setDefaultLang('en');

			translate.use(userLang).subscribe(() => {
				//console.log(`Idioma inicializado a '${userLang}'`);
			}, err => {
				console.error(`Problema con la inicializacion del idioma '${userLang}'`);
			}, () => {
				resolve(null);
			});
		});
	});
}
