import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

// services
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { DevicesService } from '../../services/devices.service';
import { RolesPipe } from '../../pipes/roles.pipe';
import ROLES from '../../entities/roles';

@Component({
	selector: 'users',
	templateUrl: 'users.component.html'
})
export class UsersComponent implements OnInit {
	@ViewChild('addDevice', {static: true}) public addDeviceModal: ModalDirective;
	@ViewChild('removeUser', {static: true}) public removeUserModal: ModalDirective;
	@ViewChild('removeClient', {static: true}) public removeClientModal: ModalDirective;

	public users: any[];
	public disableBtn: boolean = false;
	public loggedUser: any;
	public selectedUser: any;
	public selectedDevice: any;
	public blockButtons: boolean = false;
	public noAssociated: any;
	public showingNoAssociatedDevices = false;
	public loadingNoAssociatedDevices = false;
	public infoAssociatingDevice = {
		error: false,
		saving: false
	};

	public limit: number = 10;
	public totalPages: number;
	public totalUsers: number;
	public page: number = 1;
	public order: any = {
		field: 'nombre',
		direction: 'DESC',
	};
	public conditions: any = {
		conditions: [],
		parameters: {}
	};
	public _searchText: string = '';

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		value = value.trim();

		if (value && value !== '') {
			this._searchText = value;
			switch (this.loggedUser.id_role) {
				case ROLES.ADMIN:
					this.conditions = {
						conditions: [
							'user.nombre LIKE :search OR user.apellidos LIKE :search OR user.usr LIKE :search'
						],
						parameters: {
							search: this._searchText
						},
						users: 1
					};
					break;
				case ROLES.PROFESIONAL:
					this.conditions = {
						conditions: [
							'user.nombre LIKE :search OR user.telefono LIKE :search OR user.apellidos LIKE :search OR user.usr LIKE :search OR user.idLoc LIKE :search'
						],
						parameters: {
							search: this._searchText
						},
						users: 1
					};
					break;
			}

			this.loadItems();
		} else {
			this.conditions = {
				conditions: [],
				parameters: {}
			};
		}
	}

	/**
	 * Creates an instance of UsersComponent.
	 * @param {UsersService} _usersSvc
	 *
	 * @memberOf UsersComponent
	 */
	constructor(
		private _authSvc: AuthService,
		private _usersSvc: UsersService,
		private _translate: TranslateService,
		private _notificationService: NotificationsService,
		private _devicesSvc: DevicesService) {
	}

	public ngOnInit() {
		this.loggedUser = this._authSvc.user;

		this.loadItems();
	}

	public loadItems(page: number = 1) {
		this.disableBtn = true;
		this.page = page;

		this._usersSvc.find(this.limit, page, this.conditions, this.order).then((users: any) => {
			this.users = users.items;
			this.totalUsers = users.total;
			this.totalPages = Math.ceil(this.totalUsers / this.limit);
			this.disableBtn = false;
		}).catch((err) => {
			this.disableBtn = false;
			console.log(err);
		});
	}

	public showAddDevice(index: number) {
		this.selectedUser = null;
		this.selectedUser = this.users[index];
		this.infoAssociatingDevice.error  = false;
		this.infoAssociatingDevice.saving = false;
		this.addDeviceModal.show();
	}

	public showNoAssociatedDevices() {
		this.showingNoAssociatedDevices = true;
		this.loadingNoAssociatedDevices = true;
		this._devicesSvc.findNoAssociated(this.selectedUser.id).then((result) => {
			this.loadingNoAssociatedDevices = false;
			this.noAssociated = result;
		}).catch((error) => {
			this.loadingNoAssociatedDevices = false;
			this.addDeviceModal.hide();
		});
	}

	public associateDevice() {
		let devAux = {
			ns: this.selectedDevice,
			id_user: this.selectedUser.id
		};

		this.disableBtn = true;
		this.infoAssociatingDevice.error  = false;
		this.infoAssociatingDevice.saving = true;
		this._devicesSvc.associate(devAux).then((result: any) => {
			this.infoAssociatingDevice.saving = false;
			this.disableBtn = false;
			this.addDeviceModal.hide();
			this.selectedDevice = "";
			this.showingNoAssociatedDevices = false;
			this.noAssociated = [];
			this._notificationService.html(this._translate.instant(result.message), NotificationType.Success);

			this.loadItems();
		}).catch((error: any) => {
			this.disableBtn = false;
			this.infoAssociatingDevice.saving = false;
			if(error.status == 409) {
				this.infoAssociatingDevice.error = true;
			} else {
				this.addDeviceModal.hide();
				if (error.message) {
					this._notificationService.html(this._translate.instant(error.message), NotificationType.Error);
				}
			}
			
		});
	}

	public selectUserToDel(index: number) {
		this.selectedUser = null;
		this.selectedUser = this.users[index];

		this.removeUserModal.show();
	}

	public selectClientToDel(index: number) {
		this.selectedUser = null;
		this.selectedUser = this.users[index];

		this.removeClientModal.show();
	}

	public deleteUser(delId: number) {
		this.blockButtons = true;
		this._usersSvc.deleteUser(delId).then((result: any) => {
			this._notificationService.html(this._translate.instant(result.message), NotificationType.Success);
			this.blockButtons = false;

			this.loadItems();
		}).catch((error: any) => {
			console.log(error);
			if (error.message) {
				this._notificationService.html(this._translate.instant(error.message), NotificationType.Error);
			}

			this.blockButtons = false;
		});
	}

	public deleteClient(delId: number) {
		this.blockButtons = true;
		this._usersSvc.removeClient(delId).then((result: any) => {
			this.removeClientModal.hide();
			this.loadItems();

			this.blockButtons = false;
		}).catch((error: any) => {
			this.removeClientModal.hide();

			this.blockButtons = false;
		});
	}

	public changeOrder(order: any) {
		this.page = 1;
		this.order.field = order.column;
		this.order.direction = order.direction;

		this.loadItems();
	}
}
