import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Device } from '../../entities/device';
import { HistoryService } from '../../services/history.service';
import { DevicesService } from '../../services/devices.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { StockChart } from 'angular-highcharts';
import { AuthService } from '../../services/auth.service';

// utils
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as XLSX from 'xlsx';

@Component({
	selector: 'stat',
	templateUrl: 'stat.component.html',
	styleUrls: ['stat.component.scss']
})

export class StatComponent implements OnInit {
	public ns: string;
	public id: number;
	public device: Device;
	public loggedUser: any = this._authSvc.user;
	public parameter: string;
	public graphicMax: number;
	public emptyStats = false;
	public graphicMin: number;
	public options: any;
	public stats: StockChart = null;
	private _historyData: any;
	private _title = { mp: 'PH', mh: 'ppm', mo: 'ORP', cn: 'G/L', ta: '', a1: 'Turbidity', a2: 'Humidity', pro: '', all: '' };

	constructor(
		private route: ActivatedRoute,
		private _history: HistoryService,
		private _deviceSvc: DevicesService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService,
		private _authSvc: AuthService) {
	}

	ngOnInit() {
		this.route.params.subscribe((params) => {
			if (this.options || this.stats) {
				this.options = null;
				this.stats = null;
			}

			this.id = params['id'];
			this.parameter = params['parameter'];

			if (this.device && this.device.id === +this.id) {
				this.getHistory();
			} else {
				this._getDevice(this.id);
			}
		});
	}

	public getHistory(refresh: boolean = false) {
		let plotLineMax: number;
		let plotLineMin: number;
		let plotLineWidth = 1;
		let plotLineHigh = 'High';
		let plotLineLow = 'Low';
		let valDecimals = 1;
		let graphColor: string;
		let suffix = '';

		this.stats = null;
		this._title.all = this._translate.instant('VS_CHART');
		this._title.ta = this._translate.instant('TEMPERATURA_AGUA');
		this._title.pro = this._translate.instant('PRODUCCION');

		if (!refresh) {
			this.graphicMax = null;
		}

		switch (this.parameter) {
			case 'mo':
				plotLineMin = 650;
				plotLineMax = 850;
				//this.graphicMax = 1000;
				this.graphicMin = 350;
				valDecimals = 0;
				graphColor = '#9D3421';
				break;
			case 'mp':
				plotLineMin = 6.5;
				plotLineMax = 8.5;
				//this.graphicMax = 10;
				this.graphicMin = 5;
				valDecimals = 2;
				graphColor = '#08088A';
				break;
			case 'mh':
				plotLineMin = 0.3;
				plotLineMax = 3.5;
				//this.graphicMax = 5;
				this.graphicMin = 0;
				valDecimals = 2;
				graphColor = '#0A8A19';
				break;
			case 'ta':
				plotLineMin = undefined;
				plotLineMax = undefined;
				plotLineWidth = 0;
				plotLineHigh = '';
				plotLineLow = '';
				//this.graphicMax = this.loggedUser.unit_temp === 'C' ? 50 : 122;
				this.graphicMin = 0;
				break;
			case 'cn':
				plotLineMin = undefined;
				plotLineMax = undefined;
				plotLineWidth = 0;
				plotLineHigh = '';
				plotLineLow = '';
				//this.graphicMax = 5;
				this.graphicMin = 0;
				break;
			case 'pro':
				plotLineMin = undefined;
				plotLineMax = undefined;
				plotLineWidth = 0;
				plotLineHigh = '';
				plotLineLow = '';
				valDecimals = 0;
				graphColor = '#08088A';
				//this.graphicMax = 100;
				this.graphicMin = 0;
				break;
			case 'a1':
				this._title['a1'] = this.device.a1_name;
				plotLineMin = +this.device.a1_min;
				plotLineMax = +this.device.a1_max;

				if (!refresh) {
					this.graphicMax = this.device.a1.maxChart;
					this.graphicMin = this.device.a1.minChart;

					if (this.graphicMax === this.graphicMin) {
						this.graphicMax = null;
					}
				}

				graphColor = '#949533';

				if (this.device.a1_unidad === 'ppm') {
					valDecimals = 2;
				}

				suffix = this.device.a1_unidad;
				break;
			case 'a2':
				this._title['a2'] = this.device.a2.name;
				plotLineMin = +this.device.a2.min;
				plotLineMax = +this.device.a2.max;

				if (!refresh) {
					this.graphicMax = this.device.a2.maxChart;
					this.graphicMin = this.device.a2.minChart;

					if (this.graphicMax === this.graphicMin) {
						this.graphicMax = null;
					}
				}

				graphColor = '#7b3395';

				if (this.device.a2_unidad === 'ppm') {
					valDecimals = 2;
				}

				suffix = this.device.a2_unidad;
				break;
			case 'all':
				plotLineMin = 0;
				plotLineMax = 0;
				plotLineWidth = 0;
				plotLineHigh = '';
				plotLineLow = '';
				//this.graphicMax = 900;
				this.graphicMin = 0;
				valDecimals = 0;
				break;
			default: break;
		}

		this._history.find(this.device.ns, this.parameter).then((history: any) => {
			this.options = {
				title: {
					text: this._title[this.parameter],
					style: {
						fontWeight: 'bold',
						fontSize: '26px'
					}
				},
				legend: {
					enabled: true,
					shadow: true
				},
				colors: ['#2f7ed8', '#e39712'],
				chart: {
					height: 800,
					panning: false,
					pinchType: 'none',
					backgroundColor:'transparent'
				},
				rangeSelector: {
					allButtonsEnabled: true,
					selected: 3,
					buttons: [{
						type: 'hour',
						count: 1,
						text: '1H'
					}, {
						type: 'day',
						count: 1,
						text: '1D'
					}, {
						type: 'week',
						count: 1,
						text: '1W'
					}, {
						type: 'month',
						count: 1,
						text: '1M'
					}],
					inputDateFormat: '%d/%m/%Y'
				},
				navigation: {
					buttonOptions: {
						align: 'right',
						enabled: true,
						height: 20,
						verticalAlign: 'top',
						width: 24
					},
					menuItemHoverStyle: { background: 'blue', color: 'white' },
				},
				tooltip: {
					followTouchMove: true,
					dateTimeLabelFormats: '%d/%m %H:%M',
					shared: true,
					valueDecimals: valDecimals,
					valueSuffix: suffix,
					split: true,
					distance: 25
				},
				xAxis: {
					type: 'datetime',
					labels: {
						dateTimeLabelFormats: {
							day: '%d %m, %A'
						}
					}
				},
				yAxis: {
					max: this.graphicMax,
					min: this.graphicMin,
					plotLines: [{
						color: 'red',
						width: plotLineWidth,
						zIndex: 4,
						dashStyle: 'dash',
						label: {
							text: plotLineHigh,
							align: 'right',
							y: 12,
							x: 0
						},
						value: plotLineMax
					}, {
						color: 'red',
						width: plotLineWidth,
						zIndex: 4,
						dashStyle: 'dash',
						label: {
							text: plotLineLow,
							align: 'right',
							y: 12,
							x: 0
						},
						value: plotLineMin
					}],
				},
				plotOptions: {
					area: {
						marker: {
							radius: 1.25,
							enabled:true,
							fillColor:'#FFFFFF',
							lineWidth:0.33,
							lineColor:null
						},
						lineWidth: 1,
						states: {
							hover: {
								lineWidth: 1
							}
						},
						threshold: null
					}
				},
				exporting: {
					sourceWidth: 1920,
					sourceHeight: 1080,
					scale: 1,
				},
				navigator: {
					series: {
						includeInCSVExport: false
					}
				},
				series: null,
				credits: {
					enabled: false
				}
			};

			if (history.data.length>0) {
				let size: number;
				let serie: any;

				this._historyData = this.format(history.data);
				size = this._historyData.length;

				if (size === 1) {
					let t = '';

					switch (this.parameter) {
						case 'a1': t = this.device.a1_name; break;
						case 'a2': t = this.device.a2_name; break;
					}

					serie = [
						{ type: 'area', name: t, color: graphColor, fillOpacity: 0.2, connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[0], allowPointSelect: false }
					];
				} else if (size === 2) {
					serie = [
						{ type: 'area', name: this._title[this.parameter], color: graphColor, fillOpacity: 0.2, connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[0], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('DISPOSITIVO_DESCONECTADO'), color: 'red', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[1], allowPointSelect: false }
					];
				} else if (size === 4) {
					serie = [
						{ type: 'area', name: this._title[this.parameter], color: graphColor, fillOpacity: 0.2, connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[0], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('DISPOSITIVO_DESCONECTADO'), color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[1], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('FLUJO'), color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[2], allowPointSelect: false },
						{ type: 'line', name: 'SetPoint', color: '#FFBF00', connectNulls: true, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[3], allowPointSelect: false }
					];
				} else if (size === 5 && this.parameter !== 'cn') {
					serie = [
						{ type: 'area', name: this._title[this.parameter], color: graphColor, connectNulls: false, fillOpacity: 0.2, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[0], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('DISPOSITIVO_DESCONECTADO'), color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[1], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('FLUJO'), color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[2], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('RELE'), color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[3], allowPointSelect: false },
						{ type: 'line', name: 'SetPoint', color: '#FFBF00', connectNulls: true, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[4], allowPointSelect: false }
					];
				} else if (size === 5 && this.parameter === 'cn') {
					serie = [
						{ type: 'area', name: this._title[this.parameter], color: graphColor, connectNulls: false, fillOpacity: 0.2, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[0], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('DISPOSITIVO_DESCONECTADO'), color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[1], allowPointSelect: false },
						{ type: 'line', name: `${this._translate.instant('PRODUCCION')} < 30`, color: 'red', linkedTo: ':previous', connectNulls: true, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[2], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('RELE'), color: 'red', linkedTo: ':previous', connectNulls: true, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[3], allowPointSelect: false },
						{ type: 'line', name: this._translate.instant('FLUJO'), color: 'red', linkedTo: ':previous', connectNulls: true, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[4], allowPointSelect: false }
					];
				} else {
					serie = [];

					if (this.device.isPH) {
						serie.push({ type: 'area', name: 'PH x100', connectNulls: false, turboThreshold: 12000, color: '#08088A', fillOpacity: 0.2, pointInterval: 420 * 1000, data: this._historyData[0], allowPointSelect: false });
						serie.push({ type: 'line', name: `PH: ${this._translate.instant('DISPOSITIVO_DESCONECTADO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[1], allowPointSelect: false });
						serie.push({ type: 'line', name: `PH: ${this._translate.instant('FLUJO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[2], allowPointSelect: false });
						serie.push({ type: 'line', color: '#2c4958', connectNulls: true, name: 'SetPoint PH x100', turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[3], allowPointSelect: false });
						serie.push({ type: 'line', name: `PH: ${this._translate.instant('RELE')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[4], allowPointSelect: false });
					}

					if (this.device.isORP) {
						serie.push({ type: 'area', name: 'ORP', connectNulls: false, turboThreshold: 12000, color: '#9D3421', fillOpacity: 0.2, pointInterval: 420 * 1000, data: this._historyData[5], allowPointSelect: false });
						serie.push({ type: 'line', name: `ORP: ${this._translate.instant('DISPOSITIVO_DESCONECTADO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[6], allowPointSelect: false });
						serie.push({ type: 'line', name: `ORP: ${this._translate.instant('FLUJO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[7], allowPointSelect: false });
						serie.push({ type: 'line', color: '#800e13', connectNulls: true, name: 'SetPoint ORP', turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[8], allowPointSelect: false });
						serie.push({ type: 'line', name: `ORP: ${this._translate.instant('RELE')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[9], allowPointSelect: false });
					}

					if (this.device.isPPM) {
						serie.push({ type: 'area', name: 'PPM x100', connectNulls: false, turboThreshold: 12000, color: '#0A8A19', fillOpacity: 0.2, pointInterval: 420 * 1000, data: this._historyData[10], allowPointSelect: false });
						serie.push({ type: 'line', name: `PPM: ${this._translate.instant('DISPOSITIVO_DESCONECTADO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[11], allowPointSelect: false });
						serie.push({ type: 'line', name: `PPM: ${this._translate.instant('FLUJO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[12], allowPointSelect: false });
						serie.push({ type: 'line', color: '#335f09', connectNulls: true, name: 'SetPoint PPM x100', turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[13], allowPointSelect: false });
						serie.push({ type: 'line', name: `PPM: ${this._translate.instant('RELE')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[14], allowPointSelect: false });
					}

					serie.push({ type: 'area', name: this._translate.instant('TEMPERATURA') + ' x10', connectNulls: false, turboThreshold: 12000, color: '#ff7b00', fillOpacity: 0.2, pointInterval: 420 * 1000, data: this._historyData[15], allowPointSelect: false });
					serie.push({ type: 'line', name: `${this._translate.instant('TEMPERATURA')}: ${this._translate.instant('DISPOSITIVO_DESCONECTADO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[16], allowPointSelect: false });
					serie.push({ type: 'area', name: this._translate.instant('GRL') + ' x10', connectNulls: false, turboThreshold: 12000, color: '#bbff00', fillOpacity: 0.2, pointInterval: 420 * 1000, data: this._historyData[17], allowPointSelect: false });
					serie.push({ type: 'line', name: `${this._translate.instant('GRL')}: ${this._translate.instant('DISPOSITIVO_DESCONECTADO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[18], allowPointSelect: false });
					serie.push({ type: 'line', name: `${this._translate.instant('GRL')}: ${this._translate.instant('PRODUCCION')} < 30`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[19], allowPointSelect: false });
					serie.push({ type: 'line', name: `${this._translate.instant('GRL')}: ${this._translate.instant('FLUJO')}`, color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[20], allowPointSelect: false });
					serie.push({ type: 'line', name: this._translate.instant('RELE'), color: 'red', linkedTo: ':previous', connectNulls: false, turboThreshold: 12000, pointInterval: 420 * 1000, data: this._historyData[21], allowPointSelect: false });
				}

				this.options.series = serie;
			} else {
				const serie = [{ type: 'area', name: 'Void', color: graphColor, fillOpacity: 0.2, turboThreshold: 12000, pointInterval: 420 * 1000, data: [], allowPointSelect: false }];
				this.emptyStats = true;
				this.options.series = serie;
				this._notificationSvc.info('', this._translate.instant('NO_DATOS') + '\n');
			}

			this.stats = new StockChart(this.options);
		}).catch((error) => {
			if (error.message) {
				this._notificationSvc.error('', this._translate.instant(error.message));
				console.log(error.message);
			} else {
				this.getHistory();
				console.log(error);
			}
		});
	}

	private _getDevice(id) {
		this._deviceSvc.get(id).then((device: Device) => {
			this.device = device;
			this.getHistory();
		}).catch((error) => {
			console.log(error);
			this._getDevice(id);
		});
	}

	private format(h: any) {
		let data: any;
		let validData: any;
		let unConnectedData: any;
		let invalidProd: any;
		let noFlowData: any;
		let releOffData: any;
		let setpoint: any;

		switch (this.parameter) {
			case 'mo':
			case 'mh':
			case 'mp':
				let floor = 5;

				validData = [];
				unConnectedData = [];
				noFlowData = [];
				releOffData = [];

				switch (this.parameter) {
					case 'mo':
						floor = 350;
						break;
					case 'mh':
						floor = 0;
						break;
				}

				for (let point of h) {
					let utcDate: any;
					let unConnected = (point['unConnected']) ? point['unConnected'] : false;
					let flowOff = (point['noFlow']) ? point['noFlow'] : false;
					let releOff = (this.device.isDS2) ? point['ac'] === '0' : false;
					let dateString = point['fecha'].replace('T', ' ');

					dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
					utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

					if (unConnected) {
						validData.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						releOffData.push([utcDate, null]);
						unConnectedData.push({
							x: utcDate,
							y: floor,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('DISPOSITIVO_DESCONECTADO')} ${dateString}</div>`
						});
					} else if (flowOff) {
						validData.push([utcDate, null]);
						unConnectedData.push([utcDate, null]);
						releOffData.push([utcDate, null]);
						noFlowData.push({
							x: utcDate,
							y: floor,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('FLUJO')} ${dateString}</div>`
						});
					} else if (releOff) {
						validData.push([utcDate, null]);
						unConnectedData.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						releOffData.push({
							x: utcDate,
							y: floor,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('RELE')} OFF ${dateString}</div>`
						});
					} else {
						validData.push([utcDate, +point[this.parameter]]);
						unConnectedData.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						releOffData.push([utcDate, null]);
					}
				}

				setpoint = _.map(h, (o) => {
					let dateString = o['fecha'].replace('T', ' ');
					dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
					let utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

					return [utcDate, +o['s' + this.parameter[1]]];
				});

				data = [validData, unConnectedData, noFlowData, releOffData, setpoint];

				break;
			case 'pro':
				validData = [];
				unConnectedData = [];
				noFlowData = [];

				for (let point of h) {
					let utcDate: any;
					let unConnected = (point['unConnected']) ? point['unConnected'] : false;
					let dateString = point['fecha'].replace('T', ' ');

					dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
					utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

					if (unConnected) {
						validData.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						unConnectedData.push({
							x: utcDate,
							y: 0,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('DISPOSITIVO_DESCONECTADO')} ${dateString}</div>`
						});
					} else {
						validData.push([utcDate, +point['pa']]);
						noFlowData.push([utcDate, null]);
						unConnectedData.push([utcDate, null]);
					}
				}

				setpoint = _.map(h, (o) => {
					let dateString = o['fecha'].replace('T', ' ');
					dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
					let utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

					return [utcDate, +o['sn']];
				});

				data = [validData, unConnectedData, noFlowData, setpoint];
				break;
			case 'cn':
				validData = [];
				unConnectedData = [];
				invalidProd = [];
				noFlowData = [];
				releOffData = [];

				for (let point of h) {
					let utcDate: any;
					let lowProd = (point['lowProd'] && !this.device.isPanel3XX) ? point['lowProd'] : false;
					let unConnected = (point['unConnected']) ? point['unConnected'] : false;
					let flowOff = (point['noFlow']) ? point['noFlow'] : false;
					let dateString = point['fecha'].replace('T', ' ');
					//let releOff = point['ac'] === '1';
					let releOff = (!this.device.isDS2 && !this.device.isPanel3XX) ? point['ac'] === '0' : false;

					dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
					utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

					if (unConnected) {
						validData.push([utcDate, null]);
						invalidProd.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						releOffData.push([utcDate, null]);
						unConnectedData.push({
							x: utcDate,
							y: 0,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('DISPOSITIVO_DESCONECTADO')} ${dateString}</div>`
						});
					} else if (lowProd) {
						validData.push([utcDate, null]);
						unConnectedData.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						releOffData.push([utcDate, null]);
						invalidProd.push({
							x: utcDate,
							y: 0,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('PRODUCCION')} < 30 ${dateString}</div>`
						});
					} else if (flowOff) {
						validData.push([utcDate, null]);
						invalidProd.push([utcDate, null]);
						unConnectedData.push([utcDate, null]);
						releOffData.push([utcDate, null]);
						noFlowData.push({
							x: utcDate,
							y: 0,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('FLUJO')} ${dateString}</div>`
						});
					} else if (releOff) {
						validData.push([utcDate, null]);
						invalidProd.push([utcDate, null]);
						unConnectedData.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						releOffData.push({
							x: utcDate,
							y: floor,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('RELE')} OFF ${dateString}</div>`
						});
					} else {
						if (point['cn']) validData.push([utcDate, parseFloat(point['cn'].substring(0, 4))]);
						//validData.push([utcDate, 1]);
						invalidProd.push([utcDate, null]);
						noFlowData.push([utcDate, null]);
						unConnectedData.push([utcDate, null]);
						releOffData.push([utcDate, null]);
					}
				}

				data = [validData, unConnectedData, invalidProd, releOffData, noFlowData];
				break;
			case 'ta':
				validData = [];
				unConnectedData = [];

				for (let point of h) {
					let utcDate: any;
					let unConnected = (point['unConnected']) ? point['unConnected'] : false;
					let dateString = point['fecha'].replace('T', ' ');

					dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
					utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

					if (unConnected) {
						validData.push([utcDate, null]);
						unConnectedData.push({
							x: utcDate,
							y: 0,
							name: `<div style="color:red; font-size:10px">${this._translate.instant('DISPOSITIVO_DESCONECTADO')} ${dateString}</div>`
						});
					} else {
						validData.push([utcDate, this._taConversion(point['ta'])]);
						unConnectedData.push([utcDate, null]);
					}
				}

				data = [validData, unConnectedData];
				break;
			case 'a1':
			case 'a2':
				data = [
					_.map(h, (o) => {
						let dateString = o['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						let utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();
						return [utcDate, this._decodeNumericInput(+o[this.parameter], +this.device[this.parameter].cal, +this.device[this.parameter].max, +this.device[this.parameter].min)];
					})
				];
				break;
			case 'all':
				let unConnected: boolean;
				let lowProd: boolean;
				let noFlow: boolean;
				let releOff: boolean;
				let dateString: any;
				let utcDate: any;

				invalidProd = [];
				noFlowData = [];
				releOffData = [];
				data = [];

				if (this.device.isPH) {
					validData = [];
					unConnectedData = [];

					for (let point of h) {
						unConnected = (point['unConnected']) ? true : false;
						noFlow = (point['noFlow']) ? true : false;
						releOff = (this.device.isDS2) ? point['ac'] === '0' : false;
						dateString = point['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						if (unConnected) {
							validData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							unConnectedData.push({
								x: utcDate,
								y: 0,
							});
						} else if (noFlow) {
							validData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							noFlowData.push({
								x: utcDate,
								y: 0,
							});
						} else if (releOff) {
							validData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push({
								x: utcDate,
								y: 0
							});
						} else {
							validData.push([utcDate, (+point['mp']) * 100]);
							unConnectedData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
						}
					}

					setpoint = _.map(h, (o) => {
						dateString = o['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						return [utcDate, (+o['sp']) * 100];
					});

					data = [validData, unConnectedData, noFlowData, setpoint, releOffData];
				} else {
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
				}

				if (this.device.isORP) {
					validData = [];
					unConnectedData = [];
					noFlowData = [];
					releOffData = [];

					for (let point of h) {
						unConnected = (point['unConnected']) ? true : false;
						noFlow = (point['noFlow']) ? true : false;
						releOff = (this.device.isDS2) ? point['ac'] === '0' : false;
						dateString = point['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						if (unConnected) {
							validData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							unConnectedData.push({
								x: utcDate,
								y: 0,
							});
						} else if (noFlow) {
							validData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							noFlowData.push({
								x: utcDate,
								y: 0,
							});
						} else if (releOff) {
							validData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push({
								x: utcDate,
								y: 0
							});
						} else {
							validData.push([utcDate, +point['mo']]);
							unConnectedData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
						}
					}

					setpoint = _.map(h, (o) => {
						dateString = o['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						return [utcDate, +o['so']];
					});

					data.push(validData);
					data.push(unConnectedData);
					data.push(noFlowData);
					data.push(setpoint);
					data.push(releOffData);
				} else {
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
				}

				if (this.device.isPPM) {
					validData = [];
					unConnectedData = [];
					noFlowData = [];
					releOffData = [];

					for (let point of h) {
						unConnected = (point['unConnected']) ? true : false;
						noFlow = (point['noFlow']) ? true : false;
						releOff = (this.device.isDS2) ? point['ac'] === '0' : false;
						dateString = point['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						if (unConnected) {
							validData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							unConnectedData.push({
								x: utcDate,
								y: 0,
							});
						} else if (noFlow) {
							validData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							noFlowData.push({
								x: utcDate,
								y: 0,
							});
						} else if (releOff) {
							validData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push({
								x: utcDate,
								y: 0
							});
						} else {
							validData.push([utcDate, (+point['mh']) * 100]);
							unConnectedData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
						}
					}

					setpoint = _.map(h, (o) => {
						dateString = o['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						return [utcDate, (+o['sh'] * 100)];
					});

					data.push(validData);
					data.push(unConnectedData);
					data.push(noFlowData);
					data.push(setpoint);
					data.push(releOffData);
				} else {
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
				}

				if (this.device.isPanel3XX || this.device.isDS2) {
					validData = [];
					unConnectedData = [];
					noFlowData = [];
					releOffData = [];

					for (let point of h) {
						unConnected = (point['unConnected']) ? true : false;
						dateString = point['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						if (unConnected) {
							validData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							unConnectedData.push({
								x: utcDate,
								y: 0,
							});
						} else {
							validData.push([utcDate, this._taConversion(point['ta'])]);
							unConnectedData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
						}
					}

					data.push(validData);
					data.push(unConnectedData);

					validData = [];
					unConnectedData = [];
					noFlowData = [];
					releOffData = [];
					invalidProd = [];

					for (let point of h) {
						lowProd = (point['lowProd'] && !this.device.isPanel3XX) ? true : false;
						noFlow = (point['noFlow']) ? true : false;
						unConnected = (point['unConnected']) ? true : false;
						releOff = (this.device.isDS2) ? point['ac'] === '0' : false;
						dateString = point['fecha'].replace('T', ' ');
						dateString = (dateString.indexOf('+') !== -1) ? dateString.substring(0, dateString.indexOf('+')) : dateString.substring(0, dateString.lastIndexOf('-'));
						utcDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').valueOf();

						if (unConnected) {
							validData.push([utcDate, null]);
							invalidProd.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							unConnectedData.push({
								x: utcDate,
								y: 0,
							});
						} else if (noFlow) {
							validData.push([utcDate, null]);
							invalidProd.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							noFlowData.push({
								x: utcDate,
								y: 0,
							});
						} else if (lowProd) {
							validData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
							invalidProd.push({
								x: utcDate,
								y: 0,
							});
						} else if (releOff) {
							validData.push([utcDate, null]);
							invalidProd.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							releOffData.push({
								x: utcDate,
								y: 0
							});
						} else {
							if (point['cn']) validData.push([utcDate, parseFloat(point['cn'].substring(0, 4)) * 10]);
							invalidProd.push([utcDate, null]);
							unConnectedData.push([utcDate, null]);
							noFlowData.push([utcDate, null]);
							releOffData.push([utcDate, null]);
						}
					}

					data.push(validData);
					data.push(unConnectedData);
					data.push(invalidProd);
					data.push(noFlowData);
					data.push(releOffData);
				} else {
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
					data.push([]);
				}
				break;
		}

		return data;
	}

	private _decodeNumericInput(digitalData: number, calibrated: number, max: number, min: number) {
		let current = 0.0344 * (digitalData + calibrated) + 2.64;
		let m = (max - min) / 16;
		let b = max - (20 * m);
		let value = current * m + b;

		if (value > max) {
			value = max;
		} else if (value < min) {
			value = min;
		}

		return value;
	}

	private _taConversion(value: string) {
		if (!value) return 0.;
		let result = parseFloat(value.substr(0, 5));

		if (this.loggedUser.unit_temp !== 'C') {
			result = result * 1.8 + 32;
		}

		return (this.parameter === 'all') ? result * 10 : result;
	}

	public redraw() {
		(this.graphicMax > this.graphicMin) ? this.getHistory(true) : this._notificationSvc.error('', this._translate.instant('ERROR_VALOR_MINIMO_MAYOR_MAXIMO'));
	}

	public toCSV() {
		const dateTitle = this._translate.instant('FECHA');
		let head: string[];
		let data: string;
		let seriesDates: any;
		let fileName: string;

		switch (this.parameter) {
			case 'mo':
				head = [dateTitle, 'ORP', 'SetPoint'];
				fileName = 'ORP';
				break;
			case 'mp':
				head = [dateTitle, 'PH', 'SetPoint'];
				fileName = 'PH';
				break;
			case 'mh':
				head = [dateTitle, 'PPM', 'SetPoint'];
				fileName = 'PPM';
				break;
			case 'ta':
				head = [dateTitle, this._translate.instant('TEMP_AGUA')];
				fileName = this._translate.instant('TEMP_AGUA');
				break;
			case 'cn':
				head = [dateTitle, 'G/L'];
				fileName = this._translate.instant('CONDUCTIVIDAD');
				break;
			case 'pro':
				head = [dateTitle, this._translate.instant('PRODUCCION'), 'SetPoint'];
				fileName = this._translate.instant('PRODUCCION');
				break;
			case 'a1':
				head = [dateTitle, this.device.a1.name];
				fileName = this.device.a1.name;
				break;
			case 'a2':
				head = [dateTitle, this.device.a2.name];
				fileName = this.device.a1.name;
				break;
			case 'all':
				head = [dateTitle, 'PH', 'SetPoint_PH', 'ORP', 'SetPoint_ORP', 'PPM', 'SetPoint_PPM', this._translate.instant('TEMPERATURA'), this._translate.instant('GRL')];
				fileName = this._translate.instant('VS_CHART');
				break;
		}

		seriesDates = this._getChartDates();
		data = head.join(',') + '\n' + this._toCsvString(seriesDates.dateStart, seriesDates.dateEnd);
		this._downloadCSV(data, fileName);
	}

	private _downloadCSV(csv, fileName) {
		let a = document.createElement('a');
		let blob = new Blob([csv], { type: 'text/csv' });
		let url = window.URL.createObjectURL(blob);

		a.setAttribute('style', 'display:none;');
		document.body.appendChild(a);
		a.href = url;
		a.download = fileName + '.csv';
		a.click();
	}

	private _toCsvString(start, end) {
		let resultString: any = '';
		let indexes: any[];
		let result: any = {
			dates: []
		};

		switch (this._historyData.length) {
			case 1:
			case 2:
				indexes = [0];
				break;
			case 4:
				indexes = [0, 3];
				break;
			case 5:
				indexes = [0, 4];
				break;
			default:
				indexes = [0, 3, 5, 8, 10, 13, 15, 19];
				break;
		}

		// tslint:disable-next-line:forin
		for (const historyIndex in indexes) {
			for (const data of this._historyData[indexes[historyIndex]]) {
				if (data[0] >= start && data[0] <= end) {
					if (!result[data[0]]) {
						result[data[0]] = [];
					}

					result[data[0]][historyIndex] = data[1];

					if (historyIndex === '0') {
						result.dates.push(data[0]);
					}
				}
			}
		}

		result.dates = result.dates.sort((a: number, b: number) => a - b);
		resultString = result.dates.map(o => moment(o).tz('UTC').format('DD/MM/YYYY HH:mm:ss') + ',' + result[o].join(',') + '\n');

		return resultString.join('');
	}

	public toXLS() {
		const dateTitle = this._translate.instant('FECHA');
		const tempTitle = this._translate.instant('TEMPERATURA');
		const proTitle = this._translate.instant('PRODUCCION');
		const saltTitle = this._translate.instant('GRL');
		let seriesDates: any;
		let indexes: any[];
		let fileName: string;
		let model: any = {};
		let buffer: any = {
			dates: []
		};
		let result: any[] = [];

		switch (this.parameter) {
			case 'mo':
				model[dateTitle] = null;
				model['ORP'] = null;
				model['SetPoint'] = null;
				fileName = 'ORP';
				break;
			case 'mp':
				model[dateTitle] = null;
				model['PH'] = null;
				model['SetPoint'] = null;
				fileName = 'PH';
				break;
			case 'mh':
				model[dateTitle] = null;
				model['PPM'] = null;
				model['SetPoint'] = null;
				fileName = 'PPM';
				break;
			case 'ta':
				model[dateTitle] = null;
				model[tempTitle] = null;
				fileName = tempTitle;
				break;
			case 'cn':
				model[dateTitle] = null;
				model['G/L'] = null;
				fileName = this._translate.instant('CONDUCTIVIDAD');
				break;
			case 'pro':
				model[dateTitle] = null;
				model[proTitle] = null;
				model['SetPoint'] = null;
				fileName = proTitle;
				break;
			case 'a1':
				model[dateTitle] = null;
				model[this.device.a1.name] = null;
				fileName = this.device.a1.name;
				break;
			case 'a2':
				model[dateTitle] = null;
				model[this.device.a2.name] = null;
				fileName = this.device.a2.name;
				break;
			case 'all':
				model[dateTitle] = null;
				model['PH'] = null;
				model['SetPoint_PH'] = null;
				model['ORP'] = null;
				model['SetPoint_ORP'] = null;
				model['PPM'] = null;
				model['SetPoint_PPM'] = null;
				model[tempTitle] = null;
				model[saltTitle] = null;
				fileName = this._translate.instant('VS_CHART');
				break;
		}

		seriesDates = this._getChartDates();

		switch (this._historyData.length) {
			case 1:
			case 2:
				indexes = [0];
				break;
			case 4:
				indexes = [0, 3];
				break;
			case 5:
				if(this.parameter === 'cn'){
					indexes = [0];
				} else {
					indexes = [0, 4];
				}
				break;
			default:
				indexes = [0, 3, 5, 8, 10, 13, 15, 17];
				break;
		}

		// tslint:disable-next-line:forin
		for (const historyIndex in indexes) {
			for (const data of this._historyData[indexes[historyIndex]]) {
				if (data[0] >= seriesDates.dateStart && data[0] <= seriesDates.dateEnd) {
					if (!buffer[data[0]]) {
						buffer[data[0]] = _.clone(model);
						buffer.dates.push(data[0]);
						buffer[data[0]][dateTitle] = moment(data[0]).tz('UTC').format('DD/MM/YYYY HH:mm:ss');
					}

					switch (this.parameter) {
						case 'mo':
							switch (historyIndex) {
								case '0':
									buffer[data[0]]['ORP'] = data[1];
									break;
								case '1':
									buffer[data[0]]['SetPoint'] = data[1];
									break;
							}
							break;
						case 'mp':
							switch (historyIndex) {
								case '0':
									buffer[data[0]]['PH'] = data[1];
									break;
								case '1':
									buffer[data[0]]['SetPoint'] = data[1];
									break;
							}
							break;
						case 'mh':
							switch (historyIndex) {
								case '0':
									buffer[data[0]]['PPM'] = data[1];
									break;
								case '1':
									buffer[data[0]]['SetPoint'] = data[1];
									break;
							}
							break;
						case 'ta':
							buffer[data[0]][tempTitle] = data[1];
							break;
						case 'cn':
							buffer[data[0]]['G/L'] = data[1];
							break;
						case 'pro':
							switch (historyIndex) {
								case '0':
									buffer[data[0]][proTitle] = data[1];
									break;
								case '1':
									buffer[data[0]]['SetPoint'] = data[1];
									break;
							}
							break;
						case 'a1':
							buffer[data[0]][this.device.a1.name] = data[1];
							break;
						case 'a2':
							buffer[data[0]][this.device.a2.name] = data[1];
							break;
						case 'all':
							switch (historyIndex) {
								case '0':
									buffer[data[0]]['PH'] = data[1];
									break;
								case '1':
									buffer[data[0]]['SetPoint_PH'] = data[1];
									break;
								case '2':
									buffer[data[0]]['ORP'] = data[1];
									break;
								case '3':
									buffer[data[0]]['SetPoint_ORP'] = data[1];
									break;
								case '4':
									buffer[data[0]]['PPM'] = data[1];
									break;
								case '5':
									buffer[data[0]]['SetPoint_PPM'] = data[1];
									break;
								case '6':
									buffer[data[0]][tempTitle] = data[1];
									break;
								case '7':
									buffer[data[0]][saltTitle] = data[1];
									break;
							}
							break;
					}
				}
			}
		}

		buffer.dates = buffer.dates.sort((a: number, b: number) => a - b);

		for (let date of buffer.dates) {
			result.push(buffer[date.toString()]);
		}

		this._downloadExcel(result, fileName);
	}

	private _downloadExcel(json: any[], fileName: string): void {
		let ws = XLSX.utils.json_to_sheet(json);

		return XLSX.writeFile({ Sheets: { data: ws }, SheetNames: ['data'] }, `${fileName}.xlsx`);
	}

	private _getChartDates() {
		let dateStart: number;
		let dateEnd: number;
		let seriesStart: number;
		let seriesEnd: number;

		this.stats.ref.rangeSelector.chart.series.forEach(element => {
			if (element.points.length > 0) {
				seriesStart = moment(element.points[0].x).tz('UTC').valueOf();
				seriesEnd = moment(element.points[element.points.length - 1].x).tz('UTC').valueOf();

				if (element.options.includeInCSVExport !== false) {
					if (!dateStart || (seriesStart < dateStart)) {
						dateStart = moment(element.points[0].x).tz('UTC').valueOf();
					}

					if (!dateEnd || (seriesEnd > dateEnd)) {
						dateEnd = seriesEnd;
					}
				}
			}
		});

		return {
			dateStart,
			dateEnd
		}
	}
}
