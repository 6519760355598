
import {of as observableOf,  Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';


@Injectable()
export class DataResolver implements Resolve<any> {
	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return observableOf({ res: 'I am data' });
	}
}

// an array of services to resolve routes with data
export const APP_RESOLVER_PROVIDERS = [
	DataResolver
];
