<header ps-header>
	<h2>{{ 'MIS_SESIONES' | translate }}</h2>
</header>

<main class="container-fluid">
    <table cellspacing="1" class="table table-bordered table-responsive hidden-xs">
        <tbody>
            <tr>
                <th>{{ 'IDENTIFICADOR' | translate }}</th>
                <th>{{ 'FECHA_INICIO_SESION' | translate }}</th>
                <th>{{ 'PLATAFORMA' | translate }}</th>
                <th>{{ 'BT_ELIMINAR' | translate }}</th>
            </tr>
            <tr *ngFor="let item of sessions">
                <td>{{ item.token }}</td>
                <td>{{ item.loginDate }}</td>
                <td *ngIf="item.browser">{{ item.browser }}</td>
                <td *ngIf="!item.browser">{{ 'DESCONOCIDO' | translate }}</td>
                <td><button (click)="remove(item.token)" class="btn btn-danger fa fa-trash" title="{{'BORRAR' | translate}}" data-toggle="modal" data-target="#removeSession"></button></td>
            </tr>
        </tbody>
    </table>

</main>

	