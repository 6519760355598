<div class="row round-progress-container">
	<div class="col-xs-6 box" no-padding>
		<div class="rotatedRight">
			<!-- SE PONE DE MAXIMO EL DOBLE PARA QUE EL MAX REAL SEA A LA MITAD -->
			<round-progress [current]="(tempUnit === 'C') ? temperature : temperature - 32" [max]="(tempUnit === 'C') ? 80 : 208" color="#FF6E40" background="#eaeaea" [rounded]="true" [radius]="128" [stroke]="20" [animation]="'easeInOutQuart'" [animationDelay]="1024"></round-progress>
		</div>
	</div>
	<div class="col-xs-6 box" no-padding>
		<div class="rotatedLeft">
			<!-- SE PONE DE MAXIMO EL DOBLE PARA QUE EL MAX REAL SEA A LA MITAD -->
			<round-progress [current]="conductivity" [max]="(conductivityUnit === 'G') ? 20 : 30.78" color="#B4045F" background="#eaeaea" [radius]="128" [rounded]="true" [stroke]="20" [animation]="'easeInOutQuart'" [animationDelay]="1024"></round-progress>
		</div>
	</div>
  <ng-content></ng-content>
</div>
