
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
/* import { Http, Headers } from '@angular/http'; */
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { AppConfig } from '../AppConfig';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";


@Injectable()
export class AuthService {
	private remember: boolean = false;
	private _lastMoveInterval: any;
	protected _headers = new HttpHeaders({
		'Content-type': 'application/x-www-form-urlencoded'
	});

	get token(): string {
		let token = null;
		if (!this._isLocalStorageNameSupported()) {
			token = this._getCookie('token');
		} else {
			token = localStorage.getItem('token') ?? sessionStorage.getItem('token');
		}
		return token;
	}

	set token(value: string) {
		if (!this._isLocalStorageNameSupported()) {
			let cookieValue;
			let expirationDate = new Date();

			if (this.remember) {
				expirationDate.setDate(expirationDate.getDate() + 30);
				cookieValue = 'token=' + value + '; expires=' + expirationDate.toUTCString();
			} else {
				expirationDate.setDate(expirationDate.getDate() + 1);
				cookieValue = 'token=' + value + '; expires=' + expirationDate.toUTCString();
			}
			document.cookie = cookieValue;
		} else {
			try {
				this.remember ? localStorage.setItem('token', value) : sessionStorage.setItem('token', value);
			} catch (error) {
				console.log(error);
			}
		}
	}

	get user(): string {
		let user: any;
		if (!this._isLocalStorageNameSupported()) {
			user = this._getCookie('user');
		} else {
			user = localStorage.getItem('user') ?? sessionStorage.getItem('user');
		}

		if (user && user !== 'undefined') {
			return JSON.parse(user);
		} else {
			return null;
		}
	}

	set user(value: string) {
		if (!this._isLocalStorageNameSupported()) {
			let cookieValue;
			let expirationDate = new Date();

			if (this.remember) {
				expirationDate.setDate(expirationDate.getDate() + 30);
				cookieValue = 'user=' + JSON.stringify(value) + '; expires=' + expirationDate.toUTCString();
			} else {
					expirationDate.setDate(expirationDate.getDate() + 1);
				cookieValue = 'user=' + JSON.stringify(value) + '; expires=' + expirationDate.toUTCString();
			}
			document.cookie = cookieValue;
		} else {
			try {
					(this.remember) ? localStorage.setItem('user', JSON.stringify(value)) : sessionStorage.setItem('user', JSON.stringify(value));
			} catch (error) {
				console.log(error);
			}
		}
	}

	get language() {
		let lang: any;
		if (!this._isLocalStorageNameSupported()) {
			lang = this._getCookie('language') || 'Castellano';
		} else {
			lang = localStorage.getItem('language');
		}
		try {
			lang = JSON.parse(lang);
		} catch(e){

		}
		

		if (lang && lang !== 'undefined') {
			return lang;
		} else {
			return 'Ingles';
		}
	}

	set language(value: string) {
		if (!this._isLocalStorageNameSupported()) {
			let cookieValue;
			let expirationDate = new Date();

			if (this.remember) {
				expirationDate.setDate(expirationDate.getDate() + 30);
				cookieValue = 'language=' + JSON.stringify(value) + '; expires=' + expirationDate.toUTCString();
			} else {
				expirationDate.setDate(expirationDate.getDate() + 1);
				cookieValue = 'language=' + JSON.stringify(value) + '; expires=' + expirationDate.toUTCString();
			}
			document.cookie = cookieValue;
		} else {
			try {
				localStorage.setItem('language', value);
			} catch (error) {
				console.log(error);
			}
		}
	}

	constructor(
		private http: HttpClient,
		private router: Router) {
	}

	private getDateTime() {
		const currentdate = new Date(); 
		return ("0" + currentdate.getDate()).slice(-2) + "/"
                + ("0" + (currentdate.getMonth()+1)).slice(-2)  + "/" 
                + currentdate.getFullYear() + " "  
                + ("0" + currentdate.getHours()).slice(-2) + ":"  
                + ("0" + currentdate.getMinutes()).slice(-2) + ":" 
                + ("0" + currentdate.getSeconds()).slice(-2) ;
	}

	public login(username: string, password: string, remember: boolean = false, captchaToken = null) {
		let header = new HttpHeaders();
		const connectdate = this.getDateTime();
		let params = { username, password, connectdate, remember };
        this.remember = remember;
		if(captchaToken){
			params['captcha_token'] = captchaToken;
		}

		header.append('Content-type', 'application/json');

		return this.http.post(AppConfig.HOME_API + '/session/login', params, { headers: header }).pipe(
			map((response: any) => {
				this._processLoginResponse(response);
			}));
	}

    private _processLoginResponse(response: any) {

		let data: any = jwt_decode(response.token);
		if (data['userData'].isDemo) {
			data['userData']['idioma_notificaciones'] = 'Ingles';
		}
		if(response['avatar']) {
			data['userData']['avatar'] = response['avatar'];
		}

		this.token = response['token'];

		this.user = data.userData;
		this.language = data.userData['idioma_notificaciones'];
		// ACTIVAR/DESACTIVAR llamadas para ver si el usuario esta online
		if (!this._lastMoveInterval) {
			this._lastMove(this.token).catch((error) => {});

			this._lastMoveInterval = setInterval(() => {
				this._lastMove(this.token).catch((error) => {});
			}, 60000);
		}
    }

	public register(newAccount: any, captchaToken: string) {
		const headers = new HttpHeaders({
			'Content-type': 'application/x-www-form-urlencoded'
		});
		newAccount = JSON.stringify(newAccount);

		return new Promise((resolve, reject) => {
			this.http.post(
				AppConfig.HOME_API + '/users/register',
				`captcha_token=${captchaToken}&data=${newAccount}`,
				{headers}
			).subscribe(
				result => resolve(result),
				error => reject(error)
			);
		});
	}

	public checkSession() {
		let header = new HttpHeaders();
		header.append('Content-type', 'application/x-www-form-urlencoded');
		return new Promise((resolve, reject) => {
			this.http.post(AppConfig.HOME_API + '/session/check', null, { headers:  this._headers }).subscribe((result) => {
				resolve(result);
			}, (error) => {
				this.http.post(AppConfig.HOME_API + '/session/check', null, { headers: this._headers }).subscribe((res) => {
					resolve(res);
				}, (err) => {
					reject(this.logout());
				});
			});
		});
	}

	public getSessions() {
		return new Promise((resolve, reject) => {
			this.http.get(AppConfig.HOME_API + '/session/mine').subscribe(
				result => resolve(result),
				error => reject(error)
			);
		});
	}

	public removeSession(session: string) {
		return new Promise((resolve, reject) => {
			this.http.delete(`${AppConfig.HOME_API}/session/remove/${session}`).subscribe(
				result => resolve(result),
				error => reject(error)
			);
		});
	}

    public check2fa(verification: string, code: string) {
		const connectdate = this.getDateTime();
        const body = `verification=${verification}&code=${encodeURIComponent(code)}&connectdate=${connectdate}&remember=${this.remember}`;
        return this.http.post(AppConfig.HOME_API + '/session/validate2fa', body, { headers:  this._headers }).pipe(
			map((response: any) => {
				this._processLoginResponse(response);
			})
        );
    }

    public remove2fa(code: string) {
        const body = `code=${encodeURIComponent(code)}`;
        return new Promise((resolve, reject) => {
            this.http.post(AppConfig.HOME_API + '/session/removeCurrent2fa', body, { headers:  this._headers }).subscribe(
                (result) => {
                    resolve(result)
                }, 
                (error) => {
                    reject(error);
                }
            );
        });
    }

	public logout() {
		this.http.get(AppConfig.HOME_API + '/session/logout').subscribe((data) => {
			this.localLogout();
		});		
	}

	public localLogout() {
		this.user = null;
		sessionStorage.clear();
		localStorage.clear();
		window.location.reload();
		window.location.href = '/';
	}

	private _isLocalStorageNameSupported() {
		let testKey = 'test';
		let storage = window.localStorage;

		try {
			storage.setItem(testKey, '1');
			storage.removeItem(testKey);
			return true;
		} catch (error) {
			return false;
		}
	}

	private _getCookie(field: string): string {
		let cookieValue = document.cookie;
		let cookieStart = cookieValue.indexOf(' ' + field + '=');

		if (cookieStart === -1) {
			cookieStart = cookieValue.indexOf(field + '=');
		}

		if (cookieStart === -1) {
			cookieValue = null;
		} else {
			cookieStart = cookieValue.indexOf('=', cookieStart) + 1;
			let cookieEnd = cookieValue.indexOf(';', cookieStart);
			if (cookieEnd === -1) {
				cookieEnd = cookieValue.length;
			}

			cookieValue = cookieValue.substring(cookieStart, cookieEnd);
		}

		return cookieValue;
	}

	private _lastMove(token: string) {

		return new Promise((resolve, reject) => {
			this.http.post(AppConfig.HOME_API + '/users/lastmove', null, { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}
}
