import { Component, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// services
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { DevicesService } from '../../services/devices.service';
import { AuthService } from 'app/services/auth.service';
import { ProfessionalsService } from 'app/services/professionals.service';

// entities
import { Device } from '../../entities/device';

// utils
import * as moment from 'moment';
import * as _ from 'lodash';
import { RolesPipe } from '../../pipes/roles.pipe';
import ROLES from '../../entities/roles';

declare var SunCalc: any;

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'app-programmerlumi',
	templateUrl: './programmerlumi.component.html',
	styleUrls: ['./programmerlumi.component.scss'],
	// tslint:disable-next-line:use-host-property-decorator
	host: {
		'(window:resize)': 'onResize($event)'
	}
})
export class ProgrammerlumiComponent implements OnDestroy {
	@ViewChild('programs', {static: true}) programsView: ElementRef;
	@ViewChild('diaryProgrammer', {static: true}) dairyProgrammer: ElementRef;

	public id: number;
	public type: string;
	public device: Device;
	public loggedUser: any = this._authSvc.user;
	public hasPro: any;
	public hours: any = {};
	public sunrise;
	public sunset;
	public programs: any;
	public days = [
		'DIA_ABREVIADO_L', 'DIA_ABREVIADO_M', 'DIA_ABREVIADO_MI', 'DIA_ABREVIADO_J', 'DIA_ABREVIADO_V', 'DIA_ABREVIADO_S', 'DIA_ABREVIADO_D'
	];
	public sunriseBarLeft = 0;
	public sunsetBarLeft = 0;
	public idSelectedProgram = 0;
	public saving = false;
	private _modeTimer: any = null;
	private _windowResizeTimeout: any;
	private _changeModeTimer: any;

	get selectedProgram() {
		return this.device ? this.device.programs[this.idSelectedProgram] : null;
	}

	get state(): string {
		return String(this.device.vars.mu);
	}

	set state(value: string) {
		const previusState = this.device.vars.mu;
		const data = {
			id: this.device.id,
			sign: 'mu',
			value: value
		};

		if (this._modeTimer) {
			clearTimeout(this._modeTimer);
			this._modeTimer = null;
		}

		this.device.vars.mu = value;

		this._modeTimer = setTimeout(() => {
			this._devicesSvc.saveSign(data).then((result: any) => {
				console.log(result.message);
			}).catch((error: any) => {
				this.device.vars.mu = previusState;

				if (error.message) {
					this._notificationService.error('', this._translate.instant(error.message));
				}
			});
		}, 750);
	}

	constructor(
		private _ngZone: NgZone,
		private _router: Router,
		private _route: ActivatedRoute,
		private _devicesSvc: DevicesService,
		private _translate: TranslateService,
		private _notificationService: NotificationsService,
		private _authSvc: AuthService,
		private _proSvc: ProfessionalsService) {
		this._route.params.subscribe((params) => {
			this.id = parseInt(params['id'], 10);
			this.type = params['type'];
			this.getDevice();
		});

		// selectores de hora
		for (let hour = 0; hour < 24; hour++) {
			for (const quarter of [0, 15, 30, 45]) {
				this.hours[(hour * 60 + quarter)] = _.padStart(hour, 2, 0) + ':' + _.padEnd(quarter, 2, 0);
			}
		}

		this.hours[1440] = '24:00';
	}

	ngOnDestroy() {
		clearInterval(this._changeModeTimer);
		this._changeModeTimer = null;
	}

	public getDevice() {
		let times: any;

		this._devicesSvc.get(this.id).then((device: Device) => {
			this.device = device;
			console.log(this.device);

			this._changeModeTimer = setInterval(() => {
				if (this.device.status !== 0 && this._router.url === '/devices/lumiplus/weekly/' + this.device.id) {
					this._devicesSvc.changeMode(this.id, 'fast').then((result: any) => {
						console.log(result.message);
					}).catch((error: any) => {
						console.log(error.message);
					});
				} else {
					clearInterval(this._changeModeTimer);
					this._changeModeTimer = null;
				}
			}, 10000);

			this.programs = this.device.getPrograms('mu');

			if (this.loggedUser.id_role === ROLES.PROFESIONAL) {
				this._proSvc.findHasPro(this.device.id, this.loggedUser.id_user).then((result) => {
					this.hasPro = result;

					if (this.hasPro.permisos !== '222222') {
						this._router.navigate(['/devices']);
					}
				}).catch((error) => {
					console.log(error);
					this._router.navigate(['/devices']);
				});
			} else {
				this.hasPro = {
					permisos: '222222'
				};
			}

			// sunrise and sunset offset
			times = SunCalc.getTimes(new Date(), this.device['lat'], this.device['longitud']);

			for (const item of ['sunrise', 'sunset']) {
				this[item] = {
					time: this._nearestQuarter(times[item]),
					offset: {
						sign: '+',
						hours: 0,
						minutes: 0
					}
				};
			}
		}).catch((error) => {
			if (error.status === 401) {
				this._router.navigate(['/devices']);
			} else {
				this.getDevice();
			}
		});
	}

	private _nearestQuarter(date: Date) {
		const momentDate = moment(date);
		let remainder = 15 - momentDate.minute() % 15;

		remainder = remainder < 8 ? remainder : -1 * (15 - remainder);

		return moment(momentDate).add('minutes', remainder);
	}

	public nextProgram(i: number) {
		const program: any = _.split(this.device.vars.pu, '');

		program[i] = (parseInt(program[i], 10) || 0) % 9 + 1;
		this.device.vars.pu = program.join('');
		this.programs = this.device.getPrograms('mu');
	}

	public placeTwilightBars() {
		if (this.programsView) {
			this._ngZone.runOutsideAngular(() => {
				const containerWidth = this.dairyProgrammer.nativeElement.offsetWidth;
				const firstPart = containerWidth / 12;
				const hourPart = (containerWidth - firstPart) / 24;

				this.sunriseBarLeft = firstPart + ((this.sunrise.time.hour() + this.sunrise.time.minute() / 60) * hourPart);
				this.sunsetBarLeft = firstPart + ((this.sunset.time.hour() + this.sunset.time.minute() / 60) * hourPart);
			});
		}
	}

	onResize(event) {
		clearTimeout(this._windowResizeTimeout);

		this._windowResizeTimeout = setTimeout(() => {
			this.placeTwilightBars();
		}, 512);
	}

	public savePrograms() {
		const data: any = {
			id: this.device.id,
			status: this.device.status.value,
			programs: {}
		};

		this.saving = true;

		for (let i = 0; i < this.device.programs.length; i++) {
			data.programs['p' + (i + 1)] = _.pick(this.device.programs[i], ['current', '_twilight']);

			if (Array.isArray(data.programs['p' + (i + 1)].current)) {
				data.programs['p' + (i + 1)].current = data.programs['p' + (i + 1)].current.join('');
			}
		}

		this._devicesSvc.savePrograms(data).then((result: any) => {
			this._notificationService.success('', this._translate.instant(result.message));
			this.getDevice();
			this.saving = false;
		}).catch((error: any) => {
			if (error.message) {
				this._notificationService.error('', this._translate.instant(error.message));
			}
			this.saving = false;
		});
	}

	public save() {
		const data: any = {
			id: this.device.id,
			program: this.device.vars.pu
		};

		this.saving = true;

		this._devicesSvc.saveLumiplusProgram(data).then((result: any) => {
			this._notificationService.success('', this._translate.instant(result.message));
			this.getDevice();
			this.saving = false;
		}).catch((error: any) => {
			if (error.message) {
				this._notificationService.error('', this._translate.instant(error.message));
			}
			this.saving = false;
		});
	}
}
