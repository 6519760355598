import {
		Component,
		OnInit,
		OnDestroy,
		ViewChild,
		ElementRef,
		NgZone
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// services
import { DevicesService } from '../../services/devices.service';
import { AuthService } from 'app/services/auth.service';
import { ProfessionalsService } from 'app/services/professionals.service';

// entities
import { Device } from '../../entities/device';

// utils
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { RolesPipe } from '../../pipes/roles.pipe';
import ROLES from '../../entities/roles';

declare var SunCalc: any;

@Component({
	selector: 'programmer',
	templateUrl: 'programmer.component.html',
	styleUrls: ['programmer.component.scss'],
	// tslint:disable-next-line:use-host-property-decorator
	host: {
		'(window:resize)': 'onResize($event)'
	}
})

export class ProgrammerComponent implements OnInit, OnDestroy {
	@ViewChild('programs', {static: false}) programsView: ElementRef;
	@ViewChild('diaryProgrammer', {static: false}) dairyProgrammer: ElementRef;

	public id: number;
	public idRelay: number;
	public hasPro: any;
	public relaySignal: string;
	public device: Device;
	public rate: Device;
	public relay: any;
	public programs: any;
	public type: string = 'weekly';
	public N: any = moment().isoWeekday();
	public times: any = {};
	public sunrise;
	public sunset;
	public showAdvanced: boolean = false;
	private windowResizeTimeout: any;
	public loggedUser: any = this._authSvc.user;
	public relayTimer: any = null;
	public modeTimer: any = null;
	public disableButtons = false;
	public interlockVarString: string;
	public relaysStatus: any;
	public updateRelayTimeOut: any;
	public deviceTimeout: any = null;
	public changedByUser = false;
	public interlock: any = {
		input: null,
		previusInput: null,
		closed: '',
		opened: '',
		hysteresisTime: 0,
		hysteresisValueCounts: '000',
		hysteresisValue: 0,
		setpointValueCounts: '000',
		setpointValue: 0,
		var: '',
		jump: 0,
		decimals: 2,
		unit: '',
		calibrated: 0,
		min: 0,
		max: 0
	};
	public inputUnits: any = {
		5: 'a1_unidad',
		6: 'a2_unidad'
	};
	public days = [
		'DIA_ABREVIADO_L', 'DIA_ABREVIADO_M', 'DIA_ABREVIADO_MI', 'DIA_ABREVIADO_J', 'DIA_ABREVIADO_V', 'DIA_ABREVIADO_S', 'DIA_ABREVIADO_D'
	];
	public sunriseBarLeft: number = 0;
	public sunsetBarLeft: number = 0;
	public hours: any = {};
	public idSelectedProgram: number = 0;

	get selectedProgram() {
		return this.device ? this.device.programs[this.idSelectedProgram] : null;
	}

	get state(): string {
		return String(this.device.vars[this.relay.sign]);
	}

	get automatic() {
		return this.selectedProgram.automatic > 0;
	}

	set automatic(value: boolean) {
		this.selectedProgram.automatic = +value;

		if (this.selectedProgram.preset === 0 && value) {
			this.selectedProgram.preset = 1;
		}
	}

	set state(value: string) {
		let previusState = this.device.vars[this.relay.sign];
		let data = {
			id: this.device.id,
			sign: this.relay.sign,
			value: value
		};

		if (this.relayTimer) {
			clearTimeout(this.relayTimer);
			this.relayTimer = null;
		}

		this.changedByUser = true;
		this.device.vars[this.relay.sign] = value;

		this.relayTimer = setTimeout(() => {
			this._devicesSvc.saveSign(data).then().catch((error: any) => {
				this.device.vars[this.relay.sign] = previusState;
				if (error.message) {
					this._notificationService.error('', this._translate.instant(error.message));
				}
			});
		}, 750);
	}

	get sunriseWithOffset() {
		let swo;

		if (this.selectedProgram.twilight.sunrise.sign === '+') {
			swo = moment(this.sunrise.time).add(this.selectedProgram.twilight.sunrise.hours, 'hours').add(this.selectedProgram.twilight.sunrise.minutes, 'minutes');
		} else {
			swo = moment(this.sunrise.time).subtract(this.selectedProgram.twilight.sunrise.hours, 'hours').add(this.selectedProgram.twilight.sunrise.minutes, 'minutes');
		}

		if (this.sunrise.time.day() > swo.day()) {
			swo.set({ hour: 0, minute: 0 });
		}

		return swo;
	}

	get sunsetWithOffset() {
		let swo;

		if (this.selectedProgram.twilight.sunset.sign === '+') {
			swo = moment(this.sunset.time).add(this.selectedProgram.twilight.sunset.hours, 'hours').add(this.selectedProgram.twilight.sunset.minutes, 'minutes');
		} else {
			swo = moment(this.sunset.time).subtract(this.selectedProgram.twilight.sunset.hours, 'hours').add(this.selectedProgram.twilight.sunset.minutes, 'minutes');
		}

		if (this.sunrise.time.day() < swo.day()) {
			swo.set({ hour: 23, minute: 59 });
		}

		return swo;
	}

	get weekProgram(): string {
		return this.device.vars['p' + this.relay.sign[1]] || '0000000';
	}

	get todayConsumption(): number {
		if (this.device.rate) {
			let p: number = +this.weekProgram[moment().isoWeekday() - 1];

			return (p > 0) ? this._programConsumption(this.device.programs[p - 1].current) * +this.device.rate.precio : 0;
		} else {
			return null;
		}
	}

	get weekConsumption(): number {
		let total: number;
		let program: string;

		if (this.device.rate) {
			total = 0;

			if (!this.device.rate.precio || this.device.rate.precio === '') {
				this.device.rate.precio = 0;
			}

			for (let i = 0; i < this.weekProgram.length; i++) {
				if (this.weekProgram[i] !== '0' && this.weekProgram[i] !== '-') {
					program = this.device.programs[+this.weekProgram[i] - 1].current;
					total += this._programConsumption(program) * parseInt(this.device.rate.precio, 10);
				}
			}

			return total;
		} else {
			return null;
		}
	}

	get monthConsumption(): number {
		return this.device.rate ? this.weekConsumption * 4.28 : null;
	}

	get suncalcUrl() {
		let url: string;

		if (this.device) {
			url = 'http://suncalc.net/#/' + this.device.lat + ',' + this.device.longitud + ',11';
			url += '/' + moment().format('YYYY.M.D') + '/' + moment().format('HH:mm');
		}

		return url;
	}

	constructor(
		private _ngZone: NgZone,
		private router: Router,
		private route: ActivatedRoute,
		private _devicesSvc: DevicesService,
		private _translate: TranslateService,
		private _notificationService: NotificationsService,
		private _authSvc: AuthService,
		private _proSvc: ProfessionalsService) {
		this.route.params.subscribe((params) => {
			this.type = params['type'] || 'weekly';
			this.id = parseInt(params['id'], 10);
			this.idRelay = parseInt(params['idRelay'], 10);
			this.relaySignal = params['signal'];
			this.getDevice();
		});

		// selectores de hora
		for (let hour = 0; hour < 24; hour++) {
			for (let quarter of [0, 15, 30, 45]) {
				this.hours[(hour * 60 + quarter)] = _.padStart(hour, 2, 0) + ':' + _.padEnd(quarter, 2, 0);
			}
		}

		this.hours[1440] = '24:00';
	}

	onResize(event) {
		clearTimeout(this.windowResizeTimeout);

		this.windowResizeTimeout = setTimeout(() => {
			this.placeTwilightBars();
		}, 512);
	}

	ngOnInit() {
		window.onbeforeunload = () => {
			if (this.device) {

				this._devicesSvc.changeMode(this.device.id, 'eco');
			}
		};
	}

	ngOnDestroy() {
		clearTimeout(this.modeTimer);
		this.modeTimer = null;
		clearTimeout(this.updateRelayTimeOut);
		this.updateRelayTimeOut = null;
	}

	public getDevice() {
		this.disableButtons = true;

		this._devicesSvc.get(this.id).then((device: Device) => {
			this.device = device;

			if (this.device.status.value !== 0 && this.device.vars.ep !== '004' && this.device.vars.ep !== '005') {
				this._devicesSvc.changeMode(this.id, 'fast')
			}

			clearInterval(this.modeTimer);
			this.modeTimer = null;
			this._changeMode();

			if (this.type === 'weekly') {
					clearInterval(this.updateRelayTimeOut);
					this.updateRelayTimeOut = null;
					let mseconds = 1000;
					let refresh_time_base = 20000;
					if(+this.device.device_ep < +this.device.default_eco){
						refresh_time_base = 10000;
					}
					if(localStorage.getItem('relay_update_time') !== null) {
						const now = (new Date().getTime());
						const before = parseInt(localStorage.getItem('relay_update_time'));
						if((now - before) < refresh_time_base){
							mseconds = refresh_time_base - (now - before);
						} else {
							localStorage.removeItem('relay_update_time');
						}
					}
					if(this.deviceTimeout) {
						clearInterval(this.deviceTimeout);
					}
					if(mseconds < 0){
						mseconds = 0;
					}
					this.deviceTimeout = setTimeout(() => this._getRelay(), mseconds);
					
			}

			this.disableButtons = false;

			if (this.loggedUser.id_role === ROLES.PROFESIONAL && this.device.user && this.loggedUser.id_user !== this.device.user.id) {
				this._proSvc.findHasPro(this.device.id, this.loggedUser.id_user).then((result) => {
					this.hasPro = result;

					if (!this.idRelay && this.hasPro.permisos !== '222222') {
						this.router.navigate(['/devices']);
					}
				}).catch((error) => {
					this.router.navigate(['/devices']);
				});
			} else {
				this.hasPro = {
					permisos: '222222'
				};
			}

			if (this.idRelay && !this.changedByUser) {
					this.relay = _.find(device['relays'], ['id', this.idRelay]);
					this.programs = this.device.getPrograms(this.relay.sign);


				switch (this.relay.sign) {
					case 'mc':
						this.interlock.var = 'e1';
						break;
					case 'mj':
						this.interlock.var = 'e2';
						break;
					case 'mf':
						this.interlock.var = 'e4';
						break;
					case 'mb':
						this.interlock.var = 'e3';
						break;
					case 'ml':
						this.interlock.var = 'e6';
						break;
					case 'mx':
						this.interlock.var = 'e5';
						break;
				}

				if (this.device.vars[this.interlock.var] === '') {
					this.device.vars[this.interlock.var] = '0NN000000000';
				}

				this.interlockVarString = this.device.vars[this.interlock.var];
				this.interlock.input = this.interlockVarString.substr(0, 1);
				this.interlock.previusInput = this.interlockVarString.substr(0, 1);
				this.interlock.closed = this.interlockVarString.substr(1, 1);
				this.interlock.opened = this.interlockVarString.substr(2, 1);
				this.interlock.hysteresisTime = parseInt(this.interlockVarString.substr(3, 3), 10);

				if (this.interlock.input === '5' || this.interlock.input === '6') {
					if (this.device.isDS2) {
						this._setDs2TempInterlock();
					} else {
						this.setAnalog();
					}
				}
			}

			// sunrise and sunset offset
			if (this.device.vars.hr && this.device.vars.hs && this.device.vars.hr !== '' && this.device.vars.hs !== '' && this.device.vars.hr !== '0000' && this.device.vars.hs !== '0000') {
				this.times.sunrise = new Date();
				this.times.sunrise.setHours(this.device.vars.hr.substr(0, 2));
				this.times.sunrise.setMinutes(this.device.vars.hr.substr(2, 2));
				this.times.sunset = new Date();
				this.times.sunset.setHours(this.device.vars.hs.substr(0, 2));
				this.times.sunset.setMinutes(this.device.vars.hs.substr(2, 2));
			} else {
				this.times = SunCalc.getTimes(new Date(), this.device['lat'], this.device['longitud']);
			}

			for (let item of ['sunrise', 'sunset']) {
				this[item] = {
					time: this._nearestQuarter(this.times[item]),
					offset: {
						sign: '+',
						hours: 0,
						minutes: 0
					}
				};
			}
		}).catch((error) => {
			if (error.status === 401) {
				this.router.navigate(['/devices']);
			} else {
				this.getDevice();
			}
		});
	}

	public placeTwilightBars() {
		if (this.programsView) {
			this._ngZone.runOutsideAngular(() => {
				let containerWidth = this.dairyProgrammer.nativeElement.offsetWidth;
				let firstPart = containerWidth / 12;
				let hourPart = (containerWidth - firstPart) / 24;

				this.sunriseBarLeft = firstPart + ((this.sunrise.time.hour() + this.sunrise.time.minute() / 60) * hourPart);
				this.sunsetBarLeft = firstPart + ((this.sunset.time.hour() + this.sunset.time.minute() / 60) * hourPart);
			});
		}
	}

	public nextProgram(i: number) {
		let sign = this.relay.sign[1];
		let program: any = _.split(this.device.vars['p' + sign], '');
		this.changedByUser = true;
		program[i] = (+program[i] + 1) % 10;
		this.device.vars['p' + sign] = program.join('');
		this.programs = this.device.getPrograms(this.relay.sign);
	}


	private _nearestQuarter(date: Date) {
		let momentDate = moment(date);
		let remainder = 15 - momentDate.minute() % 15;

		remainder = remainder < 8 ? remainder : -1 * (15 - remainder);
		return moment(momentDate).add('minutes', remainder);
	}

	public save() {
		if (!this.loggedUser.isDemo) {
			if (this.device.status.value !== 0) {
				let interlockString: string = '';
				let valuesError = this._checkDs2Values();
				let timeString: string = this.interlock.hysteresisTime.toString();
				let setpointString: string = (!this.device.isDS2) ? this.interlock.setpointValueCounts.toString() : this._toStringNoDec(this.interlock.setpointValue);
				let hysteresisString: string = (!this.device.isDS2) ? this.interlock.hysteresisValueCounts.toString() : this._toStringNoDec(this.interlock.hysteresisValue);
				let data: any = {
					id: 0,
					var: '',
					interlock: '',
					sign: '',
					program: ''
				};

				this.disableButtons = true;

				if (!valuesError) {
					switch (this.interlock.var) {
						case 'e1':
							data.sign = 'pc';
							break;
						case 'e2':
							data.sign = 'pj';
							break;
						case 'e4':
							data.sign = 'pf';
							break;
						case 'e3':
							data.sign = 'pb';
							break;
						case 'e6':
							data.sign = 'pl';
							break;
						case 'e5':
							data.sign = 'px';
							break;
					}

					data.program = this.device.vars[data.sign];

					switch (timeString.length) {
						case 1:
							timeString = '00' + timeString;
							break;
						case 2:
							timeString = '0' + timeString;
							break;
					}

					if (!this.device.isDS2) {
						switch (setpointString.length) {
							case 1:
								setpointString = '00' + setpointString;
								break;
							case 2:
								setpointString = '0' + setpointString;
								break;
						}

						switch (hysteresisString.length) {
							case 1:
								hysteresisString = '00' + hysteresisString;
								break;
							case 2:
								hysteresisString = '0' + hysteresisString;
								break;
						}
					}

					interlockString = this.interlock.input + this.interlock.closed + this.interlock.opened + timeString + setpointString + hysteresisString;

					if (interlockString === '000000000000' || interlockString === '') {
						// esta igual que en le codigo antiguo 0NN000042001 en vez de 0NN000000000
						interlockString = '0NN000042001';
					}

					data.id = this.device.id;
					data.var = this.interlock.var;
					data.interlock = interlockString;

					this._devicesSvc.saveInterlock(data).then((result: any) => {
						this._notificationService.success('', this._translate.instant(result.message));
						clearInterval(this.updateRelayTimeOut);
						this.updateRelayTimeOut = null;
						this.saveUpdateTime();
						this.getDevice();
					}).catch((error: any) => {
						if (error.message) {
							this._notificationService.error('', this._translate.instant(error.message));
						}

						this.disableButtons = false;
					});
				} else {
					this._notificationService.error(this._translate.instant('ERROR'), this._translate.instant('VALORES'));
					this.disableButtons = false;
				}
			}
		} else {
			this._notificationService.info('', this._translate.instant('DEMO'));
		}
	}

	public changeContact(contact: string) {
		switch (contact) {
			case 'opened':
				if (this.interlock.closed === this.interlock.opened) { this.interlock.closed = 'N'; }
				break;
			case 'closed':
				if (this.interlock.closed === this.interlock.opened) { this.interlock.opened = 'N'; }
				break;
			default:
				break;
		}
	}
	// CAMBIO DEL SELECTOR DE ENTRADA
	public getInterlockData(input: string) {
		this.interlock.previusInput = this.interlock.input;
		this.interlock.input = input;

		if (parseInt(this.interlock.input, 10) > 4) {
			if (this.device.isDS2) {
				this._setDs2TempInterlock();
			} else {
				this.setAnalog();
			}
		}
	}

	public programerTime(operation) {
		switch (operation) {
			case 'more':
				if (this.interlock.hysteresisTime < 999) { this.interlock.hysteresisTime++; }
				break;
			case 'minus':
				if (this.interlock.hysteresisTime > 0) { this.interlock.hysteresisTime--; }
				break;
		}
	}

	private saveUpdateTime() {
		localStorage.setItem('relay_update_time', (new Date()).getTime().toString());
	}

	private setAnalog() {
		let analogCounts = parseInt(this.interlockVarString.substr(6, 3), 10);
		let setpoint: number;

		this.interlock.hysteresisValueCounts = parseInt(this.interlockVarString.substr(9, 3), 10);

		switch (this.interlock.input) {
			case '5':
				setpoint = this.device.vars.a1;
				this.interlock.min = this.device.a1_min;
				this.interlock.max = this.device.a1_max;
				this.interlock.calibrated = this.device.a1.cal;
				this.interlock.decimals = this.device.a1.decimals;
				this.interlock.unit = this.device.a1_unidad;
				break;
			case '6':
				setpoint = this.device.vars.a2;
				this.interlock.min = this.device.a2_min;
				this.interlock.max = this.device.a2_max;
				this.interlock.calibrated = this.device.a2.cal;
				this.interlock.decimals = this.device.a2.decimals;
				this.interlock.unit = this.device.a2_unidad;
				break;
		}

		if (analogCounts === 42 && this.interlock.opened === 'N' && this.interlock.closed === 'N') {
			if (setpoint < 42) {
				setpoint = 42;
			}
			analogCounts = setpoint;
		}

		this.interlock.setpointValueCounts = analogCounts;
		this.interlock.setpointValue = this.decodeNumericInput(this.interlock.setpointValueCounts, this.interlock.min, this.interlock.max, this.interlock.calibrated, this.interlock.decimals);
		this.calculateSetpoint('');
		this.calculateJump();
	}

	private _setDs2TempInterlock() {
		this.interlock.closed = this.interlockVarString.substr(1, 1);
		this.interlock.opened = this.interlockVarString.substr(2, 1);
		this.interlock.unit = (this.loggedUser.unit_temp === 'C') ? 'ºC' : 'ºF';
		this.interlock.setpointValue = this._convertValue(this.interlockVarString.substr(6, 3));
		this.interlock.hysteresisValue = this._convertValue(this.interlockVarString.substr(9, 3));
		this.interlock.hysteresisTime = parseInt(this.interlockVarString.substr(3, 3), 10);
	}

	private _convertValue(value: string) {
		let intValue = parseInt(value, 10) / 10;

		intValue = parseFloat(intValue.toFixed(1));

		return intValue;
	}

	private decodeNumericInput(digitalData: number, min: number, max: number, calibrated: number, decimals: number) {
		let value;

		decimals = (decimals) ? decimals : 2;

		if (isNaN(digitalData) || isNaN(min) || isNaN(max) || isNaN(calibrated)) {
			value = 0;
		} else {
			let current = 0.0344 * (digitalData + calibrated) + 2.64;
			let m = (max - min) / 16;
			let b = max - (20 * m);

			value = parseFloat((current * m + b).toFixed(decimals));

			if (value > max) {
				value = parseFloat(max.toFixed(decimals));
			} else if (value < min) {
				value = parseFloat(min.toFixed(decimals));
			}
		}

		return value;
	}

	private calculateJump() {
		let jump: number = 0;
		let v1 = this.decodeNumericInput(251, this.interlock.min, this.interlock.max, 0, 5);
		let v2 = this.decodeNumericInput(250, this.interlock.min, this.interlock.max, 0, 5);

		jump = v1 - v2;

		if (jump !== 0) {
			this.interlock.jump = jump;
			this.calculateHysteresis('');
		}
	}

	public calculateDs2HysteresisTemp(operation) {
		switch (operation) {
			case 'more':
				(this.interlock.hysteresisValue < 10) ? this.interlock.hysteresisValue += 0.1 : this.interlock.hysteresisValue = 10;
				break;
			case 'less':
				(this.interlock.hysteresisValue > 0) ? this.interlock.hysteresisValue -= 0.1 : this.interlock.hysteresisValue = 0;
				break;
		}

		this.interlock.hysteresisValue = parseFloat(this.interlock.hysteresisValue.toFixed(1));
	}

	public calculateHysteresis(operation) {
		let digitalValue = this.interlock.hysteresisValueCounts;
		let setpointValue = this.interlock.setpointValueCounts;
		let decimals = this.interlock.decimals;
		let jump = this.interlock.jump;

		switch (operation) {
			case 'more':
				digitalValue++;

				if (digitalValue > 509 - setpointValue) {
					digitalValue = 509 - setpointValue;
				} else if (digitalValue > setpointValue - 41) {
					digitalValue = setpointValue - 41;
				}
				break;
			case 'less':
				digitalValue--;

				if (digitalValue < 1) {
					digitalValue = 1;
				}
				break;
			default:
				break;
		}

		this.interlock.hysteresisValue = parseFloat((digitalValue * jump).toFixed(decimals));
		this.interlock.hysteresisValueCounts = digitalValue;
	}

	public calculateDs2Setpoint(operation: string) {
		switch (operation) {
			case 'more':
				(this.interlock.setpointValue < 40) ? this.interlock.setpointValue += 0.1 : this.interlock.setpointValue = 40;
				break;
			case 'less':
				(this.interlock.setpointValue > 0) ? this.interlock.setpointValue -= 0.1 : this.interlock.setpointValue = 0;
				break;
		}

		this.interlock.setpointValue = parseFloat(this.interlock.setpointValue.toFixed(1));
	}

	public calculateSetpoint(operation) {
		let digitalValue = this.interlock.setpointValueCounts;
		let hysteresisValue = this.interlock.hysteresisValueCounts;

		switch (operation) {
			case 'more':
				digitalValue++;

				if (digitalValue > 508) {
					digitalValue = 508;
				}

				if (hysteresisValue > 509 - digitalValue) {
					hysteresisValue = 509 - digitalValue;
				}
				break;
			case 'less':
				digitalValue--;

				if (digitalValue < 42) {
					digitalValue = 42;
				}

				if (hysteresisValue > digitalValue - 41) {
					hysteresisValue = digitalValue - 41;
				}
				break;
			default:
				break;
		}

		this.interlock.setpointValue = this.decodeNumericInput(digitalValue, this.interlock.min, this.interlock.max, this.interlock.calibrated, this.interlock.decimals);
		this.interlock.setpointValueCounts = digitalValue;
		this.interlock.hysteresisValue = parseFloat((hysteresisValue * this.interlock.jump).toFixed(this.interlock.decimals));
		this.interlock.hysteresisValueCounts = hysteresisValue;
	}

	public savePrograms() {
		if (!this.loggedUser.isDemo) {
			const sunUpHours = this.times.sunrise.getHours().toString();
			const sunUpMins = this.times.sunrise.getMinutes().toString();
			const sunDownHours = this.times.sunset.getHours().toString();
			const sunDownMins = this.times.sunset.getMinutes().toString();
			let data: any = {
				id: this.device.id,
				sign: this.relaySignal,
				status: this.device.status.value,
				hs: '0000',
				hr: '0000',
				programs: {}
			};

			this.disableButtons = true;

			for (let i = 0; i < this.device.programs.length; i++) {
				data.programs['p' + (i + 1)] = _.pick(this.device.programs[i], ['prev', '_twilight']);

				if (Array.isArray(data.programs['p' + (i + 1)].prev)) {
					data.programs['p' + (i + 1)].prev = data.programs['p' + (i + 1)].prev.join('');
				}

				data.programs['p' + (i + 1)]['c' + (i + 1)] = parseInt(data.programs['p' + (i + 1)]._twilight.charAt(0), 10);
			}

			data.hr = ((sunUpHours.length < 2) ? '0' + sunUpHours : sunUpHours) + ((sunUpMins.length < 2) ? '0' + sunUpMins : sunUpMins);
			data.hs = ((sunDownHours.length < 2) ? '0' + sunDownHours : sunDownHours) + ((sunDownMins.length < 2) ? '0' + sunDownMins : sunDownMins);

			this._devicesSvc.savePrograms(data).then((result: any) => {
				this._notificationService.success('', this._translate.instant(result.message));
				this.saveUpdateTime();
				this.getDevice();
			}).catch((error: any) => {
					this.disableButtons = false;
				if (error.message) {
					this._notificationService.error('', this._translate.instant(error.message));
				}
			});
		} else {
			this._notificationService.info('', 'DEMO');
		}
	}

	private _programConsumption(program: string) {
		let total: number = 0;

		for (let i = 0; i < program.length; i++) {
			switch (program[i]) {
				// Solo un cuarto de hora
				case '1':
				case '2':
				case '4':
				case '8':
					total += 0.25;
					break;
				// Solo media hora
				case '3':
				case 'C':
				case '5':
				case '6':
				case '9':
				case 'A':
					total += 0.5;
					break;
				// Tres cuartos de hora.
				case '7':
				case 'B':
				case 'D':
				case 'E':
					total += 0.75;
					break;
				// Hora completa.
				case 'F':
					total += 1;
					break;
			}
		}

		return total * (this.relay.consumo / 1000);
	}

	private _toStringNoDec(value: number) {
		let result = (value * 10).toString();
		let size = result.length;

		switch (size) {
			case 1:
				result = '00' + result;
				break;
			case 2:
				result = '0' + result;
				break;
		}

		return result;
	}

	private _checkDs2Values(): boolean {
		let result = false;

		if (this.device.isDS2) {
			if (this.interlock.setpointValue < 0) {
				this.interlock.setpointValue = 0;
				result = true;
			} else if (this.interlock.setpointValue > 40) {
				this.interlock.setpointValue = 40;
				result = true;
			}

			if (this.interlock.hysteresisValue < 0) {
				this.interlock.hysteresisValue = 0;
				result = true;
			} else if (this.interlock.hysteresisValue > 10) {
				this.interlock.hysteresisValue = 10;
				result = true;
			}

			if (this.interlock.hysteresisTime < 0) {
				this.interlock.hysteresisTime = 0;
				result = true;
			} else if (this.interlock.hysteresisTime > 999) {
				this.interlock.hysteresisTime = 999;
				result = true;
			}
		}

		return result;
	}

		private _getRelaysStats() {
				if (this.type === 'weekly' && this.router.url.indexOf('/devices/programmer/weekly/') > -1) {
						if (this.device.status.value !== 0) {
								this._timeoutRelaysStats(3000)
						} else {
								this._timeoutRelaysStats(30000)
						}
				}
		}

		private _timeoutRelaysStats(timeout: number) {
				this.updateRelayTimeOut = setTimeout(() => this._getRelay(), timeout);
		}
		private _getRelay() {
				this._devicesSvc.getRelaysStatus(this.device.ns).then((stat: any) => {
						let newPrograms = {};
						this.relaysStatus = stat;
						this.device.vars.updated = stat.updated;
						this.device.device_ep = this.relaysStatus.ep;
						this.device.device_rp = this.relaysStatus.rp;
						for (let index of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
								newPrograms['p' + index] = this.relaysStatus['p' + index];
								newPrograms['p' + index + 'anterior'] = this.relaysStatus['p' + index + 'anterior'];
								newPrograms['p' + index + 'crepuscular'] = this.relaysStatus['p' + index + 'crepuscular'];
						}

						if (!this.changedByUser) {
								//relay.sign recibimos el modo del rele, pero con p+relay.sign[1] recibimos el horario del rele
								this.device.vars[`p${this.relay.sign[1]}`] = stat[`p${this.relay.sign[1]}`]
								this.device.vars[this.relay.sign] = stat[this.relay.sign];
						} else {
								//Este if es en caso de que lo que se ha guardado y si lo que devuelve la base de datos es lo mismo que
								// lo que hay en front vuelve a empezar a recibir actualizaciones.
								if (this.device.vars[this.relay.sign] === stat[this.relay.sign] && this.device.vars[`p${this.relay.sign[1]}`] === stat[`p${this.relay.sign[1]}`]) {
										this.changedByUser = false;
								}
						}
						this.device.updatePrograms(newPrograms);
						this.programs = this.device.getPrograms(this.relay.sign);

						this._getRelaysStats();
				}).catch(() => this._getRelaysStats());
		}

	private _changeMode() {
		if (this.device.status.value !== 0 && this.router.url.indexOf('/devices/programmer/weekly/') > -1) {
			this.modeTimer = setTimeout(() => {
				this._devicesSvc.changeMode(this.id, 'fast').then((result: any) => {
					this._changeMode();
				}).catch((error: any) => {
					this._changeMode();
				});
			}, 12000);
		}
	}

	public goToSuncalc() {
		if (this.device) {
			window.open(this.suncalcUrl, '_blank');
		}
	}
}
