<div *ngIf="totalHardwares">
	<span *ngIf="totalHardwares" class="badge badge-info">{{ totalHardwares }}</span>
	<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
</div>

<p class="text-right" *ngIf="hardwares">
	<button class="btn btn-primary" (click)="createHardware()">{{ 'ADD_HARDWARE' | translate }}</button>
</p>

<table-sorter [disableBtn]="disableButtons" [loading]="!hardwares" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limit = $event; loadItems()" (prev)="page = $event; loadItems($event);" (search)="searchText = $event" (next)="page = $event; loadItems($event)" (pageChange)="loadItems($event)">	
	<div *ngIf="hardwares" class="visible-xs">
		<div *ngIf="pbasFilterOptions && hwFilterOptions && pbasFilterOptions">
			<div>
				<label>{{ 'PBAS' | translate }}</label>
				<ss-multiselect-dropdown [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'HARDWARE' | translate }}</label>
				<ss-multiselect-dropdown [settings]="hwFilterSettings" [texts]="hwFilterTexts" [options]="hwFilterOptions" [(ngModel)]="hwFilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'VERSION_VALIDA' | translate }}</label>
				<ss-multiselect-dropdown [settings]="validFilterSettings" [texts]="validFilterTexts" [options]="validFilterOptions" [(ngModel)]="validFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
			</div>
			<hr>
		</div>

		<div *ngFor="let hardware of hardwares; let i = index" class="panel panel-default">
			<div class="panel-body">
				<div>
					<strong>{{ 'PBA' | translate }}:</strong>
					<span *ngIf="pbaNames[hardware.id_pba]">{{ pbaNames[hardware.id_pba] }}</span>
					<span *ngIf="!pbaNames[hardware.id_pba]" class="text-center text-danger">
						{{ 'NO_PBA' | translate }}
					</span>
				</div>
				<div>
					<strong>{{ 'HARDWARE' | translate }}: </strong>{{ hardware.name }}
					<strong>{{ 'CODIGO_ENVIADO' | translate }}: </strong>{{ hardware.code }}
				</div>
				<div>
					<strong>{{ 'VERSION_VALIDA' | translate }}: </strong>
					{{ hardware.valid ? 'Si' : 'No' }}
				</div>
				<hr *ngIf="hardware.observations">
				<div *ngIf="hardware.observations">
					<div><strong>{{ 'NOTAS' | translate }}</strong></div>
					<div>{{ hardware.observations }}</div>
				</div>
				<hr>
				<div class="text-center">
					<button [disabled]="disableButtons" class="btn btn-primary btn-link" (click)="edit(i)">
						{{ 'EDITAR' | translate }}
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger btn-link" (click)="tempHardware = hardware; deleteHardware.show()">
						{{ 'BORRAR' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<table *ngIf="hardwares" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
		<thead>
			<tr>
				<th>{{ 'PBA' | translate }}</th>
				<th>{{ 'HARDWARE' | translate }}</th>
				<th>{{ 'CODIGO' | translate }}</th>
				<th>{{ 'VERSION_VALIDA' | translate }}</th>
				<th>{{ 'NOTAS' | translate }}</th>
				<th>{{ 'ACCIONES' | translate }}</th>
			</tr>
		</thead>
		<tbody>

			<tr *ngIf="pbasFilterOptions && hwFilterOptions && pbasFilterOptions">
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="hwFilterSettings" [texts]="hwFilterTexts" [options]="hwFilterOptions" [(ngModel)]="hwFilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<!-- <div>
						<ss-multiselect-dropdown [settings]="codeFilterSettings" [texts]="codeFilterTexts" [options]="codeFilterOptions" [(ngModel)]="codeFilterValue" (ngModelChange)="filterChanged(3)"></ss-multiselect-dropdown>
					</div> -->
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="validFilterSettings" [texts]="validFilterTexts" [options]="validFilterOptions" [(ngModel)]="validFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td></td>
				<td></td>
			</tr>

			<tr *ngFor="let hardware of hardwares; let i = index" class="text-center">
				<td>
					<strong *ngIf="pbaNames[hardware.id_pba]">{{ pbaNames[hardware.id_pba] }}</strong>
					<div *ngIf="!pbaNames[hardware.id_pba]" class="text-center text-danger">
						{{ 'NO_PBA' | translate }}
					</div>
				</td>
				<td>
					{{ hardware.name }}
				</td>
				<td>
					{{ hardware.code }}
				</td>
				<td>
					{{ hardware.valid ? 'Si' : 'No' }}
				</td>
				<td>
					{{ hardware.observations }}
				</td>
				<td class="actions">
					<button [disabled]="disableButtons" class="btn" (click)="edit(i)">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger" (click)="tempHardware = hardware; deleteHardware.show()">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</table-sorter>

<div bsModal #editHardware="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editHardware" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="editHardware.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'EDIT_HARDWARE' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div *ngIf="pbas">
					<div>
						<label>{{ 'NOMBRE' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempHardware.name" maxlength="50">
					</div>
					<br>
					<div>
						<label>{{ 'CODIGO' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempHardware.code" maxlength="6">
					</div>
					<br>
					<div>
						<label>{{ 'PBA' | translate }}</label>
						<select [disabled]="disableButtons" [(ngModel)]="tempHardware.id_pba" class="form-control">
							<option *ngFor="let pba of pbas" [value]="pba.id">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
						</select>
					</div>
					<br>
					<div>
						<label>{{ 'VERSION_VALIDA' | translate }}: </label>
						<span class="btn-group">
							<label class="radio-inline">
								<input type="radio" name="valid" [disabled]="disableButtons" [(ngModel)]="tempHardware.valid" [value]="true"> {{ 'SI' | translate }}
							</label>
							<label class="radio-inline">
								<input type="radio" name="valid" [disabled]="disableButtons" [(ngModel)]="tempHardware.valid" [value]="false"> {{ 'NO' | translate }}
							</label>
						</span>
					</div>
					<br>
					<div>
						<label>{{ 'NOTAS' | translate }}</label>
						<textarea [disabled]="disableButtons" class="form-control" [(ngModel)]="tempHardware.observations"></textarea>
					</div>
				</div>
				<div *ngIf="!pbas" class="text-center">
					<img src="/assets/images/wave.svg">
				</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-primary" (click)="save()" role="button" aria-disabled="false">{{ 'BT_GUARDAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="editHardware.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>

<div bsModal #deleteHardware="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteHardware" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="deleteHardware.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'BORRAR' | translate }} Hardware</h4>
			</div>

			<div class="modal-body">
				<div>{{ 'HARDWARE' | translate }}: {{ tempHardware.name }}</div>
				<div *ngIf="tempHardware.observations">{{ tempHardware.observations }}</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-danger" (click)="delete()" role="button" aria-disabled="false">{{ 'BORRAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="deleteHardware.hide()" class="btn btn-primary" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>
