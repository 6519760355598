import { Injectable } from '@angular/core';
import { AppConfig } from '../AppConfig';

@Injectable()
export class CaptchaService {
	private _key: string;

	public get key(): string {
		return this._key;
	}

	constructor() {
		this._key = AppConfig.CAPTCHA_KEY[window.location.host];
	}
}
