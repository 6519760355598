<div *ngIf="totalDevices">
	<span *ngIf="totalDevices" class="badge badge-info">{{ totalDevices }}</span>
	<span>{{ 'RESULTADOS' | translate }}</span>
</div>
<p></p>
<table-sorter [disableBtn]="disableButtons" [loading]="!devices" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limit = $event; loadItems()" (prev)="page = $event; loadItems($event);" (search)="searchText = $event" (next)="page = $event; loadItems($event)" (pageChange)="loadItems($event)">

	<div *ngIf="devices && hardwares && firmwaresOrdered" class="visible-xs">
		<div *ngFor="let device of devices; let i = index" class="panel panel-default">
			<div class="panel-body">

				<div display-table>
					<div display-table-cell nowrap align-middle class="status">
						<span class="fa-stack fa-lg 	fa-fw" [ngClass]="{fadeIn: !device.status.value}">
							<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
							<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
						</span>
					</div>
					<div display-table-cell>
						<div class="text-primary">{{ device.id_loc !== '' ? ('(' + device.id_loc + ')') : '' }}</div>
						<div><strong>{{ device.alias }}</strong></div>
						<div><span class="text-muted">{{ 'ns' | uppercase }} {{ device.ns }}</span></div>
						<div class="text-primary">{{ (device.user) ? device.user.nombre + ' ' + device.user.apellidos : '' }}</div>
						<br>
						<div class="text-right">
							<button [disabled]="!device.updateTimeoutError.error && this.updatesStatus[device.id].stateValue !== 8 && !updatesStatus[device.id].error" class="btn btn-warning btn-xs" (click)="resetUpdate(device.id, true, false)">{{ 'RESET_UPDATE' | translate }}{{ (device.updateTimeoutError.minutes > 1 && device.dw !== '0' && device.update_time && updatesStatus[device.id].stateValue !== 6) ? ((device.updateTimeoutError.minutes <= 10) ? ' (' + device.updateTimeoutError.minutes + 'm)' : ' (+10m)'): '' }}</button>
						</div>
					</div>
				</div>
				<hr *ngIf="updatesStatus[device.id].state">
				<div class="text-center text-danger">
					<span [ngClass]="!updatesStatus[device.id].upgradable ? 'text-danger' : 'orangeText'"><strong>{{ updatesStatus[device.id].state }}</strong></span>
				</div>
				<hr>
				<div class="text-center text-danger" *ngIf="device.pbasError">
					<span *ngIf="device.pbasError" class="badge badge-danger">{{ 'PBAS_ERROR' | translate }}</span>
				</div>
				<hr *ngIf="device.pbasError">
				<div class="row" *ngIf="device.pbas[0]">
					<div class="col-xs-12 text-center" *ngIf="device.pbas[0].hardware?.valid === false || device.pbas[0].firmware?.valid === false">
						<span class="badge badge-danger" *ngIf="device.pbas[0].hardware?.valid === false"><small>HW invalid</small></span>
						<span class="badge badge-danger" *ngIf="device.pbas[0].firmware?.valid === false"><small>SW invalid</small></span>
					</div>
					<div class="col-sm-6">
						<strong>{{ device.pbas[0] }}</strong>
					</div>
					<div class="col-xs-12">
						NS: <span class="text-primary">{{ device.vars.sl }}</span>
					</div>
					<div class="col-xs-12">
						HW:
						<span [ngClass]="{'text-primary' : hardwares[device.vars.mr]?.valid ,'text-warning' : !hardwares[device.vars.mr], 'text-danger' : hardwares[device.vars.mr] && !hardwares[device.vars.mr].valid }">
							{{ hardwares[device.vars.mr] ? hardwares[device.vars.mr].name : device.vars.mr }}
						</span>
					</div>
					<div class="col-xs-12">
						BTL:
						<span [ngClass]="firmwaresOrdered[device.vars.bl] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.bl] ? firmwaresOrdered[device.vars.bl].name : device.vars.bl }}
						</span>
					</div>
					<div class="col-xs-12">
						UP: <span class="text-primary">{{ device.vars.ul + '(' + device.vars.tl + ')' }}</span>
					</div>
					<div class="col-xs-12">
						APP:
						<span [ngClass]="firmwaresOrdered[device.vars.vr] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.vr] ? firmwaresOrdered[device.vars.vr].name : device.vars.vr }}
						</span>
						<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'APP', 'POT', device.vars.vr, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
					</div>
					<div class="col-xs-12">
						INFO: <span class="text-primary">-</span>
					</div>
					<div class="col-xs-12">
						BACK:
						<span [ngClass]="firmwaresOrdered[device.vars.il] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.il] ? firmwaresOrdered[device.vars.il].name : device.vars.il }}
						</span>
						<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" *ngIf="device.modelType === 'DS2'" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'BACK', 'POT', device.vars.il, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
					</div>
				</div>
				<div class="row" *ngIf="device.pbas[1]">
					<div class="col-xs-12 text-center" *ngIf="device.pbas[1].hardware?.valid === false || device.pbas[1].firmware?.valid === false">
						<span class="badge badge-danger" *ngIf="device.pbas[1].hardware?.valid === false"><small>HW invalid</small></span>
						<span class="badge badge-danger" *ngIf="device.pbas[1].firmware?.valid === false"><small>SW invalid</small></span>
					</div>
					<div class="col-xs-12">
						<strong>{{ device.pbas[1] }}</strong>
					</div>
					<div class="col-xs-12">
						NS: <span class="text-primary">{{ device.vars.st }}</span>
					</div>
					<div class="col-xs-12">
						HW:
						<span [ngClass]="{'text-primary' : hardwares[device.vars.mt]?.valid ,'text-warning' : !hardwares[device.vars.mt], 'text-danger' : hardwares[device.vars.mt] && !hardwares[device.vars.mt].valid }">
							{{ hardwares[device.vars.mt] ? hardwares[device.vars.mt].name : device.vars.mt }}
						</span>
					</div>
					<div class="col-xs-12">
						BTL:
						<span [ngClass]="firmwaresOrdered[device.vars.bt] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.bt] ? firmwaresOrdered[device.vars.bt].name : device.vars.bt }}
						</span>
					</div>
					<div class="col-xs-12">
						UP:
						<span [ngClass]="firmwaresOrdered[device.vars.ut] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.ut] ? firmwaresOrdered[device.vars.ut].name : device.vars.ut }}
						</span>
					</div>
					<div class="col-xs-12">
						APP:
						<span [ngClass]="firmwaresOrdered[device.vars.vt] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.vt] ? firmwaresOrdered[device.vars.vt].name : device.vars.vt }}
						</span>
						<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" *ngIf="device.modelType === 'DS2'" [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'APP', 'VIS', device.vars.vt, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
					</div>
					<div class="col-xs-12">
						INFO:
						<span [ngClass]="firmwaresOrdered[device.vars.vp] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.vp] ? firmwaresOrdered[device.vars.vp].name : device.vars.vp }}
						</span>
						<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" *ngIf="device.modelType === 'DS2'" [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'DATA', 'VIS', device.vars.vp, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
					</div>
					<div class="col-xs-12">
						BACK:
						<span [ngClass]="firmwaresOrdered[device.vars.it] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.it] ? firmwaresOrdered[device.vars.it].name : device.vars.it }}
						</span>
						<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" *ngIf="device.modelType === 'DS2'" [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'BACK', 'VIS', device.vars.it, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
					</div>
				</div>
				<div class="row" *ngIf="device.pbas[2] && device.vars.s1 !== '0000000000000'">
					<div class="col-xs-12 text-center" *ngIf="device.pbas[2].hardware?.valid === false || device.pbas[2].firmware?.valid === false">
						<span class="badge badge-danger" *ngIf="device.pbas[2].hardware?.valid === false"><small>HW invalid</small></span>
						<span class="badge badge-danger" *ngIf="device.pbas[2].firmware?.valid === false"><small>SW invalid</small></span>
					</div>
					<div class="col-xs-12">
						<strong>{{ device.pbas[2] }}</strong>
					</div>
					<div class="col-xs-12">
						NS: <span class="text-primary">{{ device.vars.s1 }}</span>
					</div>
					<div class="col-xs-12">
						HW:
						<span [ngClass]="{'text-primary' : hardwares[device.vars.h1]?.valid ,'text-warning' : !hardwares[device.vars.h1], 'text-danger' : hardwares[device.vars.h1] && !hardwares[device.vars.h1].valid }">
							{{ hardwares[device.vars.h1] ? hardwares[device.vars.h1].name : device.vars.h1 }}
						</span>
					</div>
					<div class="col-xs-12">
						APP:
						<span [ngClass]="firmwaresOrdered[device.vars.f1] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.f1] ? firmwaresOrdered[device.vars.f1].name : device.vars.f1 }}
						</span>
					</div>
				</div>
				<div class="row" *ngIf="device.pbas[3] && device.vars.s2 !== '0000000000000'">
					<div class="col-xs-12 text-center" *ngIf="device.pbas[3].hardware?.valid === false || device.pbas[3].firmware?.valid === false">
						<span class="badge badge-danger" *ngIf="device.pbas[3].hardware?.valid === false"><small>HW invalid</small></span>
						<span class="badge badge-danger" *ngIf="device.pbas[3].firmware?.valid === false"><small>SW invalid</small></span>
					</div>
					<div class="col-xs-12">
						<strong>{{ device.pbas[3] }}</strong>
					</div>
					<div class="col-xs-12">
						NS: <span class="text-primary">{{ device.vars.s2 }}</span>
					</div>
					<div class="col-xs-12">
						HW:
						<span [ngClass]="{'text-primary' : hardwares[device.vars.h2]?.valid ,'text-warning' : !hardwares[device.vars.h2], 'text-danger' : hardwares[device.vars.h2] && !hardwares[device.vars.h2].valid }">
							{{ hardwares[device.vars.h2] ? hardwares[device.vars.h2].name : device.vars.h2 }}
						</span>
					</div>
					<div class="col-xs-12">
						APP:
						<span [ngClass]="firmwaresOrdered[device.vars.f2] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.f2] ? firmwaresOrdered[device.vars.f2].name : device.vars.f2 }}
						</span>
					</div>
				</div>
				<div class="row" *ngIf="device.pbas[4] && device.vars.s3 !== '0000000000000'">
					<div class="col-xs-12 text-center" *ngIf="device.pbas[4].hardware?.valid === false || device.pbas[4].firmware?.valid === false">
						<span class="badge badge-danger" *ngIf="device.pbas[4].hardware?.valid === false"><small>HW invalid</small></span>
						<span class="badge badge-danger" *ngIf="device.pbas[4].firmware?.valid === false"><small>SW invalid</small></span>
					</div>
					<div class="col-xs-12">
						<strong>{{ device.pbas[4] }}</strong>
					</div>
					<div class="col-xs-12">
						NS: <span class="text-primary">{{ device.vars.s3 }}</span>
					</div>
					<div class="col-xs-12">
						HW:
						<span [ngClass]="{'text-primary' : hardwares[device.vars.h3]?.valid ,'text-warning' : !hardwares[device.vars.h3], 'text-danger' : hardwares[device.vars.h3] && !hardwares[device.vars.h3].valid }">
							{{ hardwares[device.vars.h3] ? hardwares[device.vars.h3].name : device.vars.h3 }}
						</span>
					</div>
					<div class="col-xs-12">
						APP:
						<span [ngClass]="firmwaresOrdered[device.vars.f3] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.f3] ? firmwaresOrdered[device.vars.f3].name : device.vars.f3 }}
						</span>
					</div>
				</div>
				<div class="row" *ngIf="device.pbas[5] && device.vars.s4 !== '0000000000000'">
					<div class="col-xs-12 text-center" *ngIf="device.pbas[5].hardware?.valid === false || device.pbas[5].firmware?.valid === false">
						<span class="badge badge-danger" *ngIf="device.pbas[5].hardware?.valid === false"><small>HW invalid</small></span>
						<span class="badge badge-danger" *ngIf="device.pbas[5].firmware?.valid === false"><small>SW invalid</small></span>
					</div>
					<div class="col-xs-12">
						<strong>{{ device.pbas[5] }}</strong>
					</div>
					<div class="col-xs-12">
						NS: <span class="text-primary">{{ device.vars.s4 }}</span>
					</div>
					<div class="col-xs-12">
						HW:
						<span [ngClass]="{'text-primary' : hardwares[device.vars.h4]?.valid ,'text-warning' : !hardwares[device.vars.h4], 'text-danger' : hardwares[device.vars.h4] && !hardwares[device.vars.h4].valid }">
							{{ hardwares[device.vars.h4] ? hardwares[device.vars.h4].name : device.vars.h4 }}
						</span>
					</div>
					<div class="col-xs-12">
						APP:
						<span [ngClass]="firmwaresOrdered[device.vars.f4] ? 'text-primary' : 'text-warning'">
							{{ firmwaresOrdered[device.vars.f4] ? firmwaresOrdered[device.vars.f4].name : device.vars.f4 }}
						</span>
					</div>
				</div>
				<!-- <hr>
				<div class="row text-center">
					MANUAL
				</div> -->

			</div>
		</div>
	</div>

	<table *ngIf="devices && hardwares && firmwaresOrdered" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
		<thead>
			<tr>
				<th>{{ 'ESTADO' | translate }}</th>
				<!-- <th>{{ 'USUARIO' | translate }}</th> -->
				<th>{{ 'PBAS' | translate }}</th>
				<th>NS</th>
				<th>HW</th>
				<th>FW BTL</th>
				<th>FW APP</th>
				<th>IMG INFO</th>
				<th>IMG BACKUP</th>
				<th>IMG UPGRADE</th>
				<!-- <th>{{ 'ACTUALIZACION' | translate }}</th> -->
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let device of devices; let i = index" class="text-left">
				<td>
					<div display-table>
						<div display-table-cell nowrap align-middle class="status">
							<span class="fa-stack fa-lg fa-fw" [ngClass]="{fadeIn: !device.status.value}">
								<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
								<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
							</span>
						</div>
						<div display-table-cell style="position: relative">
							<div class="text-primary">{{ device.id_loc !== '' ? ('(' + device.id_loc + ')') : '' }}</div>
							<div><strong>{{ device.alias }}</strong></div>
							<div><span class="text-muted">{{ 'ns' | uppercase }} {{ device.ns }}</span></div>
							<div><strong>{{ 'USUARIO' | translate }}:</strong> <span class="text-primary">{{ (device.user) ? device.user.nombre + ' ' + device.user.apellidos : '' }}</span></div>
							<p [ngClass]="!updatesStatus[device.id].upgradable ? 'text-danger' : 'orangeText'"><strong>{{ updatesStatus[device.id].state }}</strong></p>
							<div style="position: absolute; right: -5px; top: 0px; cursor: pointer" *ngIf="device.pbasError" class="badge badge-danger" title="{{ 'PBAS_ERROR' | translate }}">
								<i class="fa fa-exclamation-triangle fa-inverse"></i>
							</div>
							<div>
								<button [disabled]="!device.updateTimeoutError.error && this.updatesStatus[device.id].stateValue !== 8 && !updatesStatus[device.id].error" class="btn btn-warning btn-xs" (click)="resetUpdate(device.id, true, false)">{{ 'RESET_UPDATE' | translate }}{{ (device.updateTimeoutError.minutes > 1 && device.dw !== '0' && device.update_time && updatesStatus[device.id].stateValue !== 6) ? ((device.updateTimeoutError.minutes <= 10) ? ' (' + device.updateTimeoutError.minutes + 'm)' : ' (+10m)'): '' }}</button>
							</div>
						</div>
					</div>
				</td>
				<!-- <td>
					<p class="text-primary">{{ (device.user) ? device.user.nombre + ' ' + device.user.apellidos : '' }}</p>
				</td> -->
				<td>
					<!-- PBAS -->
					<div *ngFor="let pba of device.pbas; let i = index;" class="text-primary data">
						{{ (pba && device.vars['s' + (i - 1)] !== '0000000000000') ? pba : '-' }}
					</div>
				</td>
				<td>
					<!-- NS -->
					<div [ngSwitch]="device.modelType">
						<div *ngSwitchCase="'DS2'">
							<div class="text-primary text-center data">{{ device.vars.sl }}</div>
							<div class="text-primary text-center data">{{ device.vars.st }}</div>
							<div class="text-primary text-center data">{{ (device.pbas[2] && device.vars.s1 !== '0000000000000') ? device.vars.s1 : '-' }}</div>
							<div class="text-primary text-center data">{{ (device.pbas[3] && device.vars.s2 !== '0000000000000') ? device.vars.s2 : '-' }}</div>
							<div class="text-primary text-center data">{{ (device.pbas[4] && device.vars.s3 !== '0000000000000') ? device.vars.s3 : '-' }}</div>
							<div class="text-primary text-center data">{{ (device.pbas[5] && device.vars.s4 !== '0000000000000') ? device.vars.s4 : '-' }}</div>
						</div>
						<div *ngSwitchDefault>
							<div class="text-primary text-center data">{{ device.vars.ns }}</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
						</div>
					</div>
				</td>
				<td>
					<!-- HW -->
					<div [ngSwitch]="device.modelType">
						<div *ngSwitchCase="'DS2'">
							<div class="text-center data">
								<span [ngClass]="{'text-primary' : hardwares[device.vars.mr]?.valid ,'text-warning' : !hardwares[device.vars.mr], 'text-danger' : hardwares[device.vars.mr] && !hardwares[device.vars.mr].valid }">
									{{ hardwares[device.vars.mr] ? hardwares[device.vars.mr].name : ((device.vars.mr !== '' && device.vars.mr !== ' ') ? device.vars.mr : '*') }}
								</span>
							</div>
							<div class="text-center data">
								<span [ngClass]="{'text-primary' : hardwares[device.vars.mt]?.valid ,'text-warning' : !hardwares[device.vars.mt], 'text-danger' : hardwares[device.vars.mt] && !hardwares[device.vars.mt].valid }">
									{{ hardwares[device.vars.mt] ? hardwares[device.vars.mt].name : ((device.vars.mt !== '' && device.vars.mt !== ' ') ? device.vars.mt : '*') }}
								</span>
							</div>
							<div class="text-center data">
								<span *ngIf="!device.pbas[2] || device.vars.s1 === '0000000000000'" class="text-primary">-</span>
								<span *ngIf="device.pbas[2] && device.vars.s1 !== '0000000000000'" [ngClass]="{'text-primary' : hardwares[device.vars.h1]?.valid ,'text-warning' : !hardwares[device.vars.h1], 'text-danger' : hardwares[device.vars.h1] && !hardwares[device.vars.h1].valid }">
									{{ hardwares[device.vars.h1] ? hardwares[device.vars.h1].name : ((device.vars.h1 !== '' && device.vars.h1 !== ' ') ? device.vars.h1 : '*') }}
								</span>
							</div>
							<div class="text-center data">
								<span *ngIf="!device.pbas[3] || device.vars.s2 === '0000000000000'" class="text-primary">-</span>
								<span *ngIf="device.pbas[3] && device.vars.s2 !== '0000000000000'" [ngClass]="{'text-primary' : hardwares[device.vars.h2]?.valid ,'text-warning' : !hardwares[device.vars.h2], 'text-danger' : hardwares[device.vars.h2] && !hardwares[device.vars.h2].valid }">
									{{ hardwares[device.vars.h2] ? hardwares[device.vars.h2].name : ((device.vars.h2 !== '' && device.vars.h2 !== ' ') ? device.vars.h2 : '*') }}
								</span>
							</div>
							<div class="text-center data">
								<span *ngIf="!device.pbas[4] || device.vars.s3 === '0000000000000'" class="text-primary">-</span>
								<span *ngIf="device.pbas[4] && device.vars.s3 !== '0000000000000'" [ngClass]="{'text-primary' : hardwares[device.vars.h3]?.valid ,'text-warning' : !hardwares[device.vars.h3], 'text-danger' : hardwares[device.vars.h3] && !hardwares[device.vars.h3].valid }">
									{{ hardwares[device.vars.h3] ? hardwares[device.vars.h3].name : ((device.vars.h3 !== '' && device.vars.h3 !== ' ') ? device.vars.h3 : '*') }}
								</span>
							</div>
							<div class="text-center data">
								<span *ngIf="!device.pbas[5] || device.vars.s4 === '0000000000000'" class="text-primary">-</span>
								<span *ngIf="device.pbas[5] && device.vars.s4 !== '0000000000000'" [ngClass]="{'text-primary' : hardwares[device.vars.h4]?.valid ,'text-warning' : !hardwares[device.vars.h4], 'text-danger' : hardwares[device.vars.h4] && !hardwares[device.vars.h4].valid }">
									{{ hardwares[device.vars.h4] ? hardwares[device.vars.h4].name : ((device.vars.h4 !== '' && device.vars.h4 !== ' ') ? device.vars.h4 : '*') }}
								</span>
							</div>
						</div>
						<div *ngSwitchDefault>
							<div class="text-center data">
								<span [ngClass]="{'text-primary' : hardwares[device.vars.mt]?.valid ,'text-warning' : !hardwares[device.vars.mt], 'text-danger' : hardwares[device.vars.mt] && !hardwares[device.vars.mt].valid }">
									{{ hardwares[device.vars.mt] ? hardwares[device.vars.mt].name : ((device.vars.mt !== '' && device.vars.mt !== ' ') ? device.vars.mt : '*') }}
								</span>
							</div>
							<div class="text-center text-primary">-</div>
							<div class="text-center text-primary">-</div>
							<div class="text-center text-primary">-</div>
							<div class="text-center text-primary">-</div>
							<div class="text-center text-primary">-</div>
						</div>
					</div>
				</td>
				<td>
					<!-- FW BTL -->
					<div [ngSwitch]="device.modelType">
						<div *ngSwitchCase="'DS2'">
							<div class="text-center data" [ngClass]="!firmwaresOrdered[device.vars.bl] ? 'text-warning' : 'text-primary'">{{ firmwaresOrdered[device.vars.bl] ? firmwaresOrdered[device.vars.bl].name : ((device.vars.bl !== '' && device.vars.bl !== ' ') ? device.vars.bl : '*') }}</div>
							<div class="text-center data" [ngClass]="!firmwaresOrdered[device.vars.bt] ? 'text-warning' : 'text-primary'">{{ firmwaresOrdered[device.vars.bt] ? firmwaresOrdered[device.vars.bt].name : ((device.vars.bt !== '' && device.vars.bt !== ' ') ? device.vars.bt : '*') }}</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
						</div>
						<div *ngSwitchDefault>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
						</div>
					</div>
				</td>
				<td class="text-primary">
					<!-- FW APP -->
					<div [ngSwitch]="device.modelType">
						<div *ngSwitchCase="'DS2'">
							<div class="data" [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.vr] }">
								{{ firmwaresOrdered[device.vars.vr] ? firmwaresOrdered[device.vars.vr].name : ((device.vars.vr !== '' && device.vars.vr !== ' ') ? device.vars.vr : '*') }}
								<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'APP', 'POT', device.vars.vr, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
							</div>
							<div class="data" [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.vt] }">
								{{ firmwaresOrdered[device.vars.vt] ? firmwaresOrdered[device.vars.vt].name : ((device.vars.vt !== '' && device.vars.vt !== ' ') ? device.vars.vt : '*') }}
								<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'APP', 'VIS', device.vars.vt, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
							</div>
							<div class="data" [ngClass]="!firmwaresOrdered[device.vars.f1] && device.pbas[2]  && device.vars.s1 !== '0000000000000' ? 'text-warning' : 'text-primary'">
								{{ (device.pbas[2] && device.vars.s1 !== '0000000000000') ? (firmwaresOrdered[device.vars.f1] ? firmwaresOrdered[device.vars.f1].name : ((device.vars.f1 !== '' && device.vars.f1 !== ' ') ? device.vars.f1 : '*')) : '-' }}
							</div>
							<div class="data" [ngClass]="!firmwaresOrdered[device.vars.f2] && device.pbas[3]  && device.vars.s2 !== '0000000000000' ? 'text-warning' : 'text-primary'">
								{{ (device.pbas[3] && device.vars.s2 !== '0000000000000') ? (firmwaresOrdered[device.vars.f2] ? firmwaresOrdered[device.vars.f2].name : ((device.vars.f2 !== '' && device.vars.f2 !== ' ') ? device.vars.f2 : '*')) : '-' }}
							</div>
							<div class="data" [ngClass]="!firmwaresOrdered[device.vars.f3] && device.pbas[4]  && device.vars.s3 !== '0000000000000' ? 'text-warning' : 'text-primary'">
								{{ (device.pbas[4] && device.vars.s3 !== '0000000000000') ? (firmwaresOrdered[device.vars.f3] ? firmwaresOrdered[device.vars.f3].name : ((device.vars.f3 !== '' && device.vars.f3 !== ' ') ? device.vars.f3 : '*')) : '-' }}
							</div>
							<div class="data" [ngClass]="!firmwaresOrdered[device.vars.f4] && device.pbas[5]  && device.vars.s4 !== '0000000000000' ? 'text-warning' : 'text-primary'">
								{{ (device.pbas[5] && device.vars.s4 !== '0000000000000') ? (firmwaresOrdered[device.vars.f4] ? firmwaresOrdered[device.vars.f4].name : ((device.vars.f4 !== '' && device.vars.f4 !== ' ') ? device.vars.f4 : '*')) : '-' }}
							</div>
						</div>
						<div *ngSwitchCase="'PS'">
							<div [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.vt] }">
								{{ firmwaresOrdered[device.vars.vt] ? firmwaresOrdered[device.vars.vt].name : ((device.vars.vt !== '') ? device.vars.vt : '*') }}
								<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'APP', 'POT', device.vars.vt, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
							</div>
							<div [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.vr] }">
								{{ firmwaresOrdered[device.vars.vr] ? firmwaresOrdered[device.vars.vr].name : ((device.vars.vr !== '') ? device.vars.vr : '*') }}
							</div>
							<div class="data text-primary">-</div>
							<div class="data text-primary">-</div>
							<div class="data text-primary">-</div>
							<div class="data text-primary">-</div>
						</div>
						<div *ngSwitchCase="'MINI'">
							<div [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.vt] }">
								{{ firmwaresOrdered[device.vars.vt] ? firmwaresOrdered[device.vars.vt].name : ((device.vars.vt !== '') ? device.vars.vt : '*') }}
								<button [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'APP', 'POT', device.vars.vt, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
							</div>
							<div class="data text-primary">-</div>
							<div class="data text-primary">-</div>
							<div class="data text-primary">-</div>
							<div class="data text-primary">-</div>
							<div class="data text-primary">-</div>
						</div>
					</div>
				</td>
				<td>
					<!-- IMG INFO -->
					<div [ngSwitch]="device.modelType">
						<div *ngSwitchCase="'DS2'">
							<div class="text-primary data">-</div>
							<div class="text-primary data">
								<div *ngIf="device.pbas[1]" [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.vp] }">
									{{ firmwaresOrdered[device.vars.vp] ? firmwaresOrdered[device.vars.vp].name : ((device.vars.vp !== '' && device.vars.vp !== ' ') ? device.vars.vp : '*') }}
									<button *ngIf="device.modelType === 'DS2'" [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'DATA', 'VIS', device.vars.vp, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
								</div>
								<div *ngIf="!device.pbas[1]">-</div>
							</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
						</div>
						<div *ngSwitchDefault>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
						</div>
					</div>
				</td>
				<td>
					<!-- IMG BACKUP -->
					<div [ngSwitch]="device.modelType">
						<div *ngSwitchCase="'DS2'">
							<div class="text-primary data">
								<div *ngIf="device.pbas[0]" [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.il] }">
									{{ firmwaresOrdered[device.vars.il] ? firmwaresOrdered[device.vars.il].name : ((device.vars.il !== '' && device.vars.il !== ' ') ? device.vars.il : '*') }}
									<button *ngIf="device.modelType === 'DS2'" [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'BACK', 'POT', device.vars.il, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
								</div>
								<div *ngIf="!device.pbas[0]">-</div>
							</div>
							<div class="text-primary data">
								<div *ngIf="device.pbas[1]" [ngClass]="{ 'text-warning' : !firmwaresOrdered[device.vars.it] }">
									{{ firmwaresOrdered[device.vars.it] ? firmwaresOrdered[device.vars.it].name : ((device.vars.it !== '' && device.vars.it !== ' ') ? device.vars.it : '*') }}
									<button *ngIf="device.modelType === 'DS2'" [disabled]="!updatesStatus[device.id].upgradable || disableButtons" class="btn btn-primary btn-xs smallButton pull-right" (click)="updateFirm(device, 'BACK', 'VIS', device.vars.it, device.id)" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
								</div>
								<div *ngIf="!device.pbas[1]">-</div>
							</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
						</div>
						<div *ngSwitchDefault>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
							<div class="text-primary data">-</div>
						</div>
					</div>
				</td>
				<td>
					<!-- IMG UPGRADE -->
					<div [ngSwitch]="device.modelType">
						<div *ngSwitchCase="'DS2'">
							<div class="text-primary text-center data">
								{{ firmwaresOrdered[device.vars.ul] ? (firmwaresOrdered[device.vars.ul].name + '(' + (device.vars.tl | uppercase) + ')') : (device.vars.ul+ '(' + (device.vars.tl | uppercase) + ')') }}
							</div>
							<div class="text-primary text-center data">
								{{ firmwaresOrdered[device.vars.ut] ? firmwaresOrdered[device.vars.ut].name : ((device.vars.ut !== '' && device.vars.ut !== ' ') ? device.vars.ut : '*') }}
							</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
						</div>
						<div *ngSwitchDefault>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
							<div class="text-primary text-center data">-</div>
						</div>
					</div>
				</td>
				<!-- <td class="text-center">
					MANUAL
				</td> -->
			</tr>
		</tbody>
	</table>
</table-sorter>

<div bsModal #updateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="updateModal" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="updateModal.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div *ngIf="pbaFirmwares">
					<p>{{ 'PREGUNTA_ACTUALIZAR' | translate }}</p>
					<div *ngIf="!upgradeData.pbaid" class="text-danger">
						<p>{{ 'PBA_SIN_DETERMINAR' | translate }}</p>
						<p>{{ 'SOFT_COMPLETO' | translate }}{{ upgradeData.firmwareType + ' (' + (upgradeData.pbatype | uppercase) + ')' }}</p>
					</div>
					<br>
					<div>
						<p>{{ 'NUEVA_VERSION_DE_FIRMWARE' | translate }}:</p>
						<div class="row">
							<div class="col-sm-2 text-center">
								<h4>{{ upgradeData.oldCode }} <i class="fa fa-arrow-right" aria-hidden="true"></i></h4>
							</div>
							<div class="col-sm-10">
								<select class="form-control" [(ngModel)]="selectedFirmware">
									<option value="-2">-</option>
									<option value="-1" *ngIf="upgradeData.firmwareType === 'APP'">Backup</option>
									<!-- <option *ngFor="let firm of pbaFirmwares; let i = index" [value]="i" [disabled]="firm.code == upgradeData.oldCode">{{ firm.name }}</option> -->
									<option *ngFor="let firm of pbaFirmwares; let i = index" [value]="i" [style.color]="firm.code == upgradeData.oldCode ? 'red' : 'black'">{{ firm.name }}</option>
								</select>
							</div>
							<div class="col-sm-10 col-sm-offset-2" *ngIf="pbaFirmwares[selectedFirmware]">
								<strong>{{ 'CODIGO' | translate }}:</strong> {{ pbaFirmwares[selectedFirmware].code }}
								<br>
								<label><strong>{{ 'NOTAS' | translate }}:</strong></label>
								<p>{{ pbaFirmwares[selectedFirmware].observations }}</p>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!pbaFirmwares" class="text-center">
					<img src="/assets/images/wave.svg">
				</div>
				</div>

				<div class="modal-footer" *ngIf="pbaFirmwares">
					<button [disabled]="selectedFirmware === '-2' || (pbaFirmwares.length === 0 && upgradeData.firmwareType !== 'APP') || disableButtons" class="btn btn-primary" (click)="upgrade()" role="button" aria-disabled="false">{{ 'OK' | translate }}</button>
					<button [disabled]="disableButtons" (click)="updateModal.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
				</div>

			</div>
		</div>
	</div>
