<div *ngIf="!loading" [ngClass]="{'disabled' : disableBtn }">
	<div class="row tools">
		<div class="col-sm-offset-1 col-md-offset-3 col-xs-8 col-sm-5 col-md-3">
			<div class="input-group pagination">
				<span class="input-group-btn">
					<button [ngClass]="{'disabled' : disableBtn }" [disabled]="page == 1 || disableBtn" class="btn btn-default" (click)="changePage(1); emitOptional('first');" title="{{ 'PAGINA_PRIMERA' | translate }}"><i class="fa fa-fast-backward"></i></button>
					<button [ngClass]="{'disabled' : disableBtn }" [disabled]="page - 1 < 1 || disableBtn" class="btn btn-default" (click)="(page > 1) ? changePage(page - 1) : changePage(total); emitOptional('previus');" title="{{'PAGINA_ANTERIOR' | translate}}"><i class="fa fa-step-backward"></i></button>
				</span>
				<div [ngClass]="{'disabled' : disableBtn}" class="form-control text-center pageDisplay" nowrap>{{ page + ' / ' + total }}</div>
				<span class="input-group-btn">
					<button [ngClass]="{'disabled' : disableBtn }" [disabled]="page == total || disableBtn" class="btn btn-default no-margin" (click)="(page
					 < total)? changePage(page + 1) : changePage(total); emitOptional('next');" title="{{'PAGINA_SIGUIENTE' | translate}}"><i class="fa fa-step-forward"></i></button>
					<button [ngClass]="{'disabled' : disableBtn }" [disabled]="page == total || disableBtn" class="btn btn-default"
							(click)="changePage(total); emitOptional('last');" title="{{ 'PAGINA_ULTIMA' | translate }}"><i class="fa fa-fast-forward"></i></button>
				</span>
			</div>
		</div>
		<div class="col-xs-4 col-sm-2">
			<select [ngClass]="{'disabled' : disableBtn }" [disabled]="disableBtn" class="pagesize form-control" [(ngModel)]='limit'
					(change)="limitChanged.next($event.target.value)">
				<option *ngFor="let item of [5, 10, 20, 50]" [value]="item">{{ item }}</option>
			</select>
		</div>
		<div class="col-xs-12 col-sm-4">
			<div class="input-group">
				<span class="input-group-btn">
					<button [ngClass]="{'disabled' : disableBtn }" [disabled]="disableBtn" class="btn" (click)="refresh(page); emitOptional('refresh');">
						<i *ngIf="!disableBtn" class="fa fa-refresh"></i>
						<i *ngIf="disableBtn" class="fa fa-refresh fa-spin" aria-hidden="true"></i>
					</button>
				</span>
				<input [ngClass]="{'disabled' : disableBtn }" type="text" name="filter" (keyup)="($event.keyCode !== 13) ? setSearch() : setSearch(true)" class="form-control" [(ngModel)]="searchText">
				<span class="input-group-btn">
					<button type="submit" class="btn btn-primary" (click)="setSearch(true)" title="{{ 'BUSCAR' | translate }}">
						<i class="fa fa-search fa-fw" aria-hidden="true"></i>
						{{ 'BUSCAR' | translate }}
					</button>
				</span>
			</div>
		</div>
	</div>
	<ng-content></ng-content>
</div>
<div *ngIf="loading" class="text-center">
	<img src="/assets/images/wave.svg">
</div>
