import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';

import { BaseService } from './base.service';

// services
import { AuthService } from './auth.service';

// entities
import { Firmware } from '../entities/firmware';
import { AppConfig } from '../AppConfig';

@Injectable()
export class FirmwaresService extends BaseService {
	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public find() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmwares/', null, { headers: this.headers } ).subscribe((result: string[]) => {
				let items = result/* .json() */;
				let firmwares: Firmware[] = [];

				for (let item of items) {
					firmwares.push(new Firmware(item));
				}

				resolve(firmwares);
			}, (error) => {
				reject(error);
			});
		});
	}

	public findStable() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmwares/viewstable', null, { headers: this.headers } ).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public save(software: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmwares/save', 'data=' + JSON.stringify(software), { headers: this.headers } ).subscribe((result) => {
				resolve(result);
			}, (error) => {
				reject(error);
			});
		});
	}

	public delete(id: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/firmwares/delete', 'data=' + id, { headers: this.headers } ).subscribe((result) => {
				resolve(result);
			}, (error) => {
				reject(error);
			});
		});
	}
}
