<div *ngIf="totalUpdateLogs">
	<span *ngIf="totalUpdateLogs" class="badge badge-info">{{ totalUpdateLogs }}</span>
	<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
</div>
<p></p>
<table-sorter [disableBtn]="disableButtons" [loading]="!updateLogs" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limit = $event; loadItems()" (prev)="page = $event; loadItems($event);" (search)="searchText = $event" (next)="page = $event; loadItems($event)" (pageChange)="loadItems($event)">

	<div *ngIf="updateLogs" class="visible-xs">

		<div *ngIf="devicesFilterValue && eventsFilterValue && oldFwFilterValue && newFwFilterValue && datesRangeFormated">
			<div>
				<label>{{ 'DISPOSITIVO' | translate }}</label>
				<ss-multiselect-dropdown class="searchFilter" [settings]="devicesFilterSettings" [texts]="devicesFilterTexts" [options]="devicesFilterOptions" [(ngModel)]="devicesFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'FECHA' | translate }}</label>
				<input class="form-control" [minDate]="minDate" [maxDate]="maxDate" #drp="bsDaterangepicker" (ngModelChange)="filterByDate($event)" [(ngModel)]="datesRange" [bsConfig]="bsConfig" placement="bottom" bsDaterangepicker>
			</div>
			<div>
				<label>{{ 'MODELO' | translate }}</label>
				<ss-multiselect-dropdown [settings]="modelsFilterSettings" [texts]="modelsFilterTexts" [options]="modelsFilterOptions" [(ngModel)]="modelsFilterValue" (ngModelChange)="filterChanged(3)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'PBA' | translate }}</label>
				<ss-multiselect-dropdown [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'EVENTO' | translate }}</label>
				<ss-multiselect-dropdown class="searchFilter" [settings]="eventsFilterSettings" [texts]="eventsFilterTexts" [options]="eventsFilterOptions" [(ngModel)]="eventsFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'ORIGEN_EVENTO' | translate }}</label>
				<ss-multiselect-dropdown class="searchFilter" [settings]="originFilterSettings" [texts]="originFilterTexts" [options]="originFilterOptions" [(ngModel)]="originFilterValue" (ngModelChange)="filterChanged(5)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'FW_PREVIO' | translate }}</label>
				<ss-multiselect-dropdown class="searchFilter" [settings]="oldFwFilterSettings" [texts]="oldFwFilterTexts" [options]="oldFwFilterOptions" [(ngModel)]="oldFwFilterValue" (ngModelChange)="filterChanged(6)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'FW_NUEVO' | translate }}</label>
				<ss-multiselect-dropdown class="searchFilter" [settings]="newFwFilterSettings" [texts]="newFwFilterTexts" [options]="newFwFilterOptions" [(ngModel)]="newFwFilterValue" (ngModelChange)="filterChanged(7)"></ss-multiselect-dropdown>
			</div>
			<hr>
		</div>

		<div *ngFor="let updateLog of updateLogs; let i = index" class="panel panel-default">
			<div class="panel-heading text-center">
				<div>
					<small>{{ updateLog.update_date | date:'d/L/yyyy H:mm:ss' }}:</small>
					<strong>{{ updateLog.update_event | translate }}</strong>
				</div>
				<div class="text-primary">{{ updateLog.device.alias }}</div>
				<div><small><strong>NS:</strong> {{ updateLog.device.ns }}</small></div>
			</div>
			<div class="panel-body">
				<div>
					<strong>{{ 'MODELO' | translate }}:</strong> {{ modelType(updateLog.device) }}
					<strong>{{ 'PBA' | translate }}:</strong> {{ updateLog.pba }}
				</div>
				<hr>
				<div><strong>{{ 'ORIGEN_EVENTO' | translate }}:</strong> {{ updateLog.origin | translate }}</div>
				<hr>
				<div><strong>{{ 'FW_PREVIO' | translate }}:</strong> {{ updateLog.old_fw }}</div>
				<div><strong>{{ 'FW_NUEVO' | translate }}:</strong> {{ updateLog.new_fw }}</div>
			</div>
		</div>
	</div>

	<table *ngIf="updateLogs" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
		<thead>
			<tr>
				<th>{{ 'DISPOSITIVO' | translate }}</th>
				<th>{{ 'FECHA' | translate }}</th>
				<th>{{ 'MODELO' | translate }}</th>
				<th>{{ 'PBA' | translate }}</th>
				<th>{{ 'EVENTO' | translate}}</th>
				<th>{{ 'ORIGEN_EVENTO' | translate }}</th>
				<th>{{ 'FW_PREVIO' | translate }}</th>
				<th>{{ 'FW_NUEVO' | translate }}</th>
			</tr>
		</thead>
		<tbody>

			<tr *ngIf="devicesFilterValue && eventsFilterValue && oldFwFilterValue && newFwFilterValue && datesRangeFormated">
				<td>
					<div>
						<ss-multiselect-dropdown class="searchFilter deviceFilter" [settings]="devicesFilterSettings" [texts]="devicesFilterTexts" [options]="devicesFilterOptions" [(ngModel)]="devicesFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<input class="form-control" [minDate]="minDate" [maxDate]="maxDate" #drp="bsDaterangepicker" (ngModelChange)="filterByDate($event)" [(ngModel)]="datesRange" [bsConfig]="bsConfig" placement="right" bsDaterangepicker>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown class="toCenter" [settings]="modelsFilterSettings" [texts]="modelsFilterTexts" [options]="modelsFilterOptions" [(ngModel)]="modelsFilterValue" (ngModelChange)="filterChanged(3)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown class="toCenter" [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown class="searchFilter toCenter" [settings]="eventsFilterSettings" [texts]="eventsFilterTexts" [options]="eventsFilterOptions" [(ngModel)]="eventsFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown class="searchFilter toLeft" [settings]="originFilterSettings" [texts]="originFilterTexts" [options]="originFilterOptions" [(ngModel)]="originFilterValue" (ngModelChange)="filterChanged(5)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown class="searchFilter toLeft" [settings]="oldFwFilterSettings" [texts]="oldFwFilterTexts" [options]="oldFwFilterOptions" [(ngModel)]="oldFwFilterValue" (ngModelChange)="filterChanged(6)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown class="searchFilter toLeft" [settings]="newFwFilterSettings" [texts]="newFwFilterTexts" [options]="newFwFilterOptions" [(ngModel)]="newFwFilterValue" (ngModelChange)="filterChanged(7)"></ss-multiselect-dropdown>
					</div>
				</td>
			</tr>

			<tr *ngFor="let updateLog of updateLogs">
				<td>
					<div><strong>{{ updateLog.device.alias }}</strong></div>
					<div class="text-muted">NS: {{ updateLog.device.ns }}</div>
				</td>
				<td class="text-center">
					<div>{{ updateLog.update_date | date:'d/L/yyyy' }}</div>
					<div class="text-muted">{{ updateLog.update_date | date:'H:mm:ss' }}</div>
				</td>
				<td class="text-center text-primary">
					<div>{{ modelType(updateLog.device) }}</div>
				</td>
				<td class="text-center text-primary">
					<div>{{ updateLog.pba }}</div>
				</td>
				<td class="text-primary">
					<div [innerHtml]="updateLog.update_event"></div>
				</td>
				<td class="text-center text-primary">
					<div>{{ updateLog.origin | translate }}</div>
				</td>
				<td class="text-center text-primary">
					<div>{{ updateLog.old_fw }}</div>
				</td>
				<td class="text-center text-primary">
					<div>{{ updateLog.new_fw }}</div>
				</td>
			</tr>
		</tbody>
	</table>
</table-sorter>
