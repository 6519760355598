import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../AppConfig';

// services
import { BaseService } from './base.service';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';

// utils
import * as _ from 'lodash';

@Injectable()
export class UsersService extends BaseService {
	constructor(public _http: HttpClient, protected _authSvc: AuthService, private _translateSvc: TranslateService) {
		super(_http, _authSvc);
	}

	public find(limit: number = 20, page: number = 1, conditions: any = {}, order = null) {
		let offset = (page - 1) * limit;
		let body = null;
		let parameters = [];

		if (conditions) {
			parameters.push('conditions=' + JSON.stringify(conditions));
		}

		if (order) {
			parameters.push('order=' + JSON.stringify(order));
		}

		if (parameters.length > 0) {
			body = parameters.join('&');
		}

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/' + limit + '/' + offset, body, { headers: this.headers }).subscribe((result) => {
				result = result/*.json()*/;

				resolve({ total: result['total'], items: _.map(result['items'], o => o) });
			}, (error) => {
				reject(error);
			});
		});
	}

	public removeClient(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/removeClient/' + id, null, { headers: this._headers }).subscribe((result) => {
				resolve(result);
			}, (error) => {
				reject(error);
			});
		});
	}

	public get(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/view/' + id, null, { headers: this._headers }).subscribe((result) => {
				let item = result/*.json()*/;
				item['id_role'] = item['id_profile'];
				item['role'] = item['profile'];
				resolve(item);
			}, (error) => {
				reject(error);
			});
		});
	}

	public setLanguage(language) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/setlanguage', 'data=' + language, { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public getProfessionals(excludedProfesionals: number[]) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/professionals/allprof', 'excluded=' + excludedProfesionals, { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public addProfessional(user: number, device: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/professionals/associate', 'idUser=' + user + '&idDevice=' + device, { headers: this.headers }).subscribe((result) => {
				resolve(result);
			}, (error) => {
				reject(error);
			});
		});
	}

	public delProfessional(user: number, device: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/professionals/desassociate', 'idUser=' + user + '&idDevice=' + device, { headers: this.headers }).subscribe((result) => {
				resolve(result);
			}, (error) => {
				reject(error);
			});
		});
	}
	public new(changes: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/new', 'data=' + JSON.stringify(changes), { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public save(changes: any) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/save', 'data=' + JSON.stringify(changes), { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public deleteUser(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/delete', 'id=' + id, { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public getProByMail(email: string) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/probymail', 'email=' + email, { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public sendMail(mail) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/mail/send', 'to=' + mail.to + '&from=' + mail.from + '&subject=' + mail.subject + '&body=' + mail.body, { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public passRecover(mail) {
		let browserLang = this._translateSvc.getBrowserLang();
		let lang = (browserLang.match(/en|es|fr|de|it|pt|nl/)) ? browserLang : 'es';

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/passrecover', 'email=' + mail + '&lang=' + lang, { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public lastMove() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/lastmove', null, { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public verifyUser(id, expires, signature, token) {
		return new Promise((resolve, reject) => {

			
			
			this._http.get(AppConfig.HOME_API + '/users/verify', { headers: this._headers, params : {id, expires,signature , token } })
			.subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public getLimitUser(){
		return new Promise((resolve, reject) => {
			this._http.get(AppConfig.HOME_API + '/users/limit', { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public saveLimitUser(limit){
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/users/limit', 'data=' + limit , { headers: this._headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}
}
