import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesService } from 'app/services/devices.service';
import { VersionsService } from 'app/services/versions.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment-timezone';
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'devices-logs',
	templateUrl: './devices-logs.component.html',
	styleUrls: ['./devices-logs.component.scss']
})
export class DevicesLogsComponent implements OnInit, OnDestroy {
	@ViewChild('updateModal', {static: true}) updateModal;

	public devices: any[];
	public firmwaresOrdered: any;
	public pbaFirmwares: any[];
	public updatesStatus: any = {};
	public previusDw: any = {};
	public hardwares: any;
	public upgradeData: any;
	public selectedFirmware: any;
	public totalDevices: number;
	public limit: number = 10;
	public page: number = 1;
	public totalPages: number = 1;
	public disableButtons: boolean = false;
	public pbasVersions: any;
	public conditions: any = {
		conditions: [],
		parameters: {}
	};
	public userSearch = 1;
	private _searchText: string = '';
	private _searchTimeOut: any = null;
	private _refreshInterval: any;
	private _resetTimeOut: any;
	private _firstIn = true;
	private _devicesUpdated: any = {};

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		if (this._searchTimeOut) {
			clearTimeout(this._searchTimeOut);
		}

		value = value.trim();

		this._searchTimeOut = setTimeout(() => {
			if (this._refreshInterval) {
				clearInterval(this._refreshInterval);
				this._refreshInterval = null;
			}

			if (value && value !== '') {
				this._searchText = value;
				this.conditions = {
					conditions: [
						'device.idLoc LIKE :search OR device.ns LIKE :search OR device.alias LIKE :search'
					],
					parameters: {
						search: this._searchText
					},
					version: 1
				};
			} else {
				this._searchText = '';
				this.conditions = {
					conditions: [],
					parameters: {}
				};
			}

			this._firstIn = true;
			this.userSearch++;

			this.loadItems();
		}, 500);
	}

	constructor(
		private _translate: TranslateService,
		private _notificationSvc: NotificationsService,
		private _versionsSvc: VersionsService,
		private _devicesSvc: DevicesService,
		private _router: Router) {
	}

	ngOnInit() {
		this.loadItems();
	}

	ngOnDestroy() {
		clearInterval(this._refreshInterval);
		this._refreshInterval = null;
	}

	public loadItems(nPage: number = 1) {
		const promises = [
			this._devicesSvc.find(this.limit, nPage, this.conditions, null, false, this.userSearch)
		];

		if (!this.hardwares) {
			promises.push(this._versionsSvc.getAllHardwares());
		}

		if (!this.firmwaresOrdered) {
			promises.push(this._versionsSvc.getAllFirmwares());
		}

		this.page = nPage;
		this.disableButtons = true;

		Promise.all(promises).then((response: any) => {
			if (this.userSearch === parseInt(response[0].userSearch, 10)) {
				if (!this.hardwares) {
					this.hardwares = this._orderHardwares(response[1]);
				}

				if (!this.firmwaresOrdered) {
					this.firmwaresOrdered = this._orderFirmwares(response[2]);
				}

				this.devices = this._addModelType(response[0].items);
				this.updateStatus(this.devices);

				this.totalDevices = response[0].total;
				this.totalPages = Math.ceil(this.totalDevices / this.limit);

				this.disableButtons = false;

				if (!this._refreshInterval && this._router.url === '/version/devices') {
					this._refreshInterval = setInterval(() => {
						this.loadItems(this.page);
					}, 15000);
				}

				this.userSearch++;
				console.log(this.devices);
			}
		}).catch((err) => {
			this.loadItems();
			this.disableButtons = false;
			console.log(err);
		});
	}

	public updateFirm(device: any, firmType: string, pbatype: string, oldFirmCode: string = null, deviceid: number) {
		const data = {
			id: (this.firmwaresOrdered[oldFirmCode]) ? this.firmwaresOrdered[oldFirmCode].id_pba : null,
			type: firmType,
			pbatype
		};

		this.selectedFirmware = '-2';
		this.upgradeData = {
			device,
			devicens: device.ns,
			deviceid: deviceid,
			pbaid: data.id,
			pbatype,
			oldCode: oldFirmCode,
			firmwareType: firmType,
			idfirmware: null,
			urlfirmware: null
		};
		this.pbaFirmwares = null;

		this.updateModal.show();

		this._versionsSvc.getPbaFirmwares(data).then((result: any) => {
			this.pbaFirmwares = result;
		}).catch((error) => {
			this._versionsSvc.getPbaFirmwares(data).then((result: any) => {
				this.pbaFirmwares = result;
			}).catch((err) => {
				console.log(err.message);
			});
		});
	}

	public upgrade() {
		this.disableButtons = true;

		if (this.selectedFirmware > -1) {
			this.upgradeData.idfirmware = this.pbaFirmwares[this.selectedFirmware].id;
			this.upgradeData.urlfirmware = this.pbaFirmwares[this.selectedFirmware].url;
			this.upgradeData.newCode = this.pbaFirmwares[this.selectedFirmware].code;
		} else {
			this.upgradeData.idfirmware = this.selectedFirmware;
		}

		this.upgradeData.device = {
			id: this.upgradeData.device.id,
			vars: this.upgradeData.device.vars
		}

		this._versionsSvc.upgradeDevice(this.upgradeData).then((result: any) => {
			this.updateModal.hide();
			this._notificationSvc.success('', this._translate.instant(result.message));
			clearInterval(this._refreshInterval);
			this._refreshInterval = null;
			delete this._devicesUpdated[result.devId];
			this._firstIn = true;
			this._setPbasVersions(this.upgradeData.device);
			this._firstIn = false;
			this.loadItems(this.page);
		}).catch((error) => {
			console.log(error.message);
			this.updateModal.hide();
			this.disableButtons = false;
		});
	}

	public updateStatus(devices) {
		for (const dev of devices) {
			this.updatesStatus[dev.id] = (dev.isDS2) ? this.statusDS2(dev) : this.statusDOM(dev);
		}
	}

	public statusDS2(dev) {
		const states = [
			' ', // 0
			'INFO_INCOMPLETA', // 1
			'LISTO_PARA_ACTUALIZACION', // 2
			'DISPOSITIVO_DESCONECTADO', // 3
			'ACTUALIZANDO', // 4
			'DESCARGA_CORRECTA', // 5
			'ACTUALIZADO', // 6
			'ERROR_TIMEOUT', // 7
			'ERROR', // 8
			'DESCARGA_FALLO', // 9
			'TRANSFERENCIA_OK', // 10
			'TRANSFERENCIA_FALLO', // 11
			'TRANSFERENCIA_FLASH', // 12
			'INSTALACION_FALLO', // 13
			'INICIO_INSTALACION', // 14
			'INSTALACION_OK', // 15
			'TRANS_POT_VIS_FALLO', // 16
			'INICIO_TRANS_POT_VIS', // 17
			'TRANS_POT_VIS_OK' // 18
		];
		const pbas = this._getPbasIndex(dev);
		const llValue = dev.vars.ll;
		const dwValue = dev.dw;
		let upgradable = true;
		let stateIndex = 0;
		let state = null;
		let error = false;

		if (dev.status.value === 0 || !dev.isUpdated) {
			upgradable = false;
			stateIndex = 3; // DISPOSITIVO_DESCONECTADO
			state = this._translate.instant(states[stateIndex]);
		}

		if (upgradable) {
			for (const index of pbas) {
				switch (index) {
					case 0:
						if (!dev.vars.sl || !dev.vars.mr || !dev.vars.bl || dev.vars.sl === '' || dev.vars.mr === '' || dev.vars.bl === '' || dev.vars.sl === '0000000000000' || dev.vars.mr === '0000000000000' || dev.vars.bl === '0000000000000' || ((!dev.vars.vr || dev.vars.vr === '' || dev.vars.vr === '0000000000000') && dev.modelType === 'DS2') || ((!dev.vars.il || dev.vars.il === '' || dev.vars.il === '0000000000000') && dev.modelType === 'DS2')) {
							upgradable = false;
							stateIndex = 1; // INFO_INCOMPLETA
							state = this._translate.instant(states[stateIndex]);
						}
						break;
					case 1:
						if (!dev.vars.st || !dev.vars.mt || !dev.vars.bt || dev.vars.st === '' || dev.vars.mt === '' || dev.vars.bt === '' || dev.vars.st === '0000000000000' || dev.vars.mt === '0000000000000' || dev.vars.bt === '0000000000000' || ((!dev.vars.vt || dev.vars.vt === '' || dev.vars.vt === '0000000000000') && dev.modelType === 'DS2') || ((!dev.vars.it || dev.vars.it === '' || dev.vars.it === '0000000000000') && dev.modelType === 'DS2')) {
							upgradable = false;
							stateIndex = 1; // INFO_INCOMPLETA
							state = this._translate.instant(states[stateIndex]);
						}
						break;
				}
			}
		}

		if (upgradable) {
			if (dev.updateTimeoutError.error) {
				stateIndex = 7; // ERROR_TIMEOUT
				state = this._translate.instant(states[stateIndex]);
				upgradable = false;

				if (dev.dw !== '0' && !this._resetTimeOut) {
					this._resetTimeOut = setTimeout(() => { this.resetUpdate(dev.id, false, true); }, 10000);
				}
			}
		}

		if (upgradable) {
			switch (llValue) {
				case 'Z':
				case '-':
					switch (dwValue) {
						case '0':
							break;
						case 'U':
						case 'V':
						case 'W':
						case 'X':
						case 'Z':
						case 'Y':
						case '1':
							upgradable = false;
							stateIndex = 2; // LISTO_PARA_ACTUALIZACION
							state = this._translate.instant(states[stateIndex]);
							break;
						case '2':
							upgradable = false;
							stateIndex = 5; // DESCARGA_CORRECTA
							state = this._translate.instant(states[stateIndex]);
							break;
						default:
							upgradable = false;
							stateIndex = 13; // INSTALACION_FALLO
							state = this._translate.instant(states[stateIndex]) + dwValue;
							error = true;
							break;
					}
					break;
				case 'A':
				case 'B':
				case 'D':
				case 'E':
				case 'T':
					switch (dwValue) {
						case '0':
							break;
						case '2':
							upgradable = false;
							stateIndex = 5; // DESCARGA_CORRECTA
							state = this._translate.instant(states[stateIndex]);
							break;
						default:
							upgradable = false;
							stateIndex = 9; // DESCARGA_FALLO
							state = this._translate.instant(states[stateIndex]) + dwValue;
							error = true;
							break;
					}
					break;
				case 'G':
				case 'P':
					switch (dwValue) {
						case '0':
							break;
						case '-':
							upgradable = false;
							stateIndex = 12; // TRANSFERENCIA_FLASH
							state = this._translate.instant(states[stateIndex]);
							break;
						case '2':
							upgradable = false;
							stateIndex = 10; // TRANSFERENCIA_OK
							state = this._translate.instant(states[stateIndex]);
							break;
						default:
							upgradable = false;
							stateIndex = 11; // TRANSFERENCIA_FALLO
							state = this._translate.instant(states[stateIndex]) + dwValue;
							error = true;
							break;
					}
					break;
				case 'H':
				case 'I':
				case 'J':
				case 'Q':
				case 'R':
				case 'S':
					switch (dwValue) {
						case '0':
							break;
						case '-':
							upgradable = false;
							stateIndex = 14; // INICIO_INSTALACION
							state = this._translate.instant(states[stateIndex]);
							break;
						case '2':
							upgradable = true;
							stateIndex = 15; // INSTALACION_OK
							state = this._translate.instant(states[stateIndex]);
							break;
						default:
							upgradable = false;
							stateIndex = 13; // INSTALACION_FALLO
							state = this._translate.instant(states[stateIndex]) + dwValue;
							error = true;
							break;
					}
					break;
				case 'M':
				case 'N':
				case 'O':
					switch (dwValue) {
						case '0':
							break;
						case '-':
							upgradable = false;
							stateIndex = 17; // INICIO_TRANS_POT_VIS
							state = this._translate.instant(states[stateIndex]);
							break;
						case '2':
							upgradable = false;
							stateIndex = 18; // TRANS_POT_VIS_OK
							state = this._translate.instant(states[stateIndex]);
							break;
						default:
							upgradable = false;
							stateIndex = 16; // TRANS_POT_VIS_FALLO
							state = this._translate.instant(states[stateIndex]) + dwValue;
							error = true;
							break;
					}
					break;
			}
		}

		if ((dev.dw === '2' && this._devicesUpdated[dev.id] !== 1) || stateIndex === 15 || stateIndex === 10) {
			for (const signal in this.pbasVersions[dev.id]) {
				if ((this.pbasVersions[dev.id][signal] !== dev.vars[signal]) || stateIndex === 15 || stateIndex === 10) {
					stateIndex = 6; // ACTUALIZADO
					state = this._translate.instant(states[stateIndex]);
					upgradable = true;
					this.pbasVersions[dev.id][signal] = dev.vars[signal];
					this._devicesUpdated[dev.id] = 1;
					break;
				}
			}
		} else if (dev.dw === '2' && this._devicesUpdated[dev.id] === 1) {
			stateIndex = 6; // ACTUALIZADO
			state = this._translate.instant(states[stateIndex]);
			upgradable = true;
		}

		return {
			dw: dwValue,
			ll: llValue,
			upgradable,
			state,
			stateValue: stateIndex,
			error
		};
	}

	public statusDOM(dev) {
		const states = [
			' ', // 0
			'INFO_INCOMPLETA', // 1
			'LISTO_PARA_ACTUALIZACION', // 2
			'DISPOSITIVO_DESCONECTADO', // 3
			'ACTUALIZANDO', // 4
			'DESCARGA_CORRECTA', // 5
			'ACTUALIZADO', // 6
			'ERROR_TIMEOUT', // 7
			'ERROR' // 8
		];
		let upgradable = true;
		let stateIndex = 0;
		let error = false;

		if (dev.status.value === 0 || !dev.isUpdated) {
			upgradable = false;
			stateIndex = 3; // DISPOSITIVO_DESCONECTADO
		}

		if (upgradable) {
			if (dev.dw !== '0') {
				upgradable = false;

				if (dev.updateTimeoutError.error) {
					stateIndex = 7; // ERROR_TIMEOUT
					upgradable = false;

					if ((dev.dw === '1' || dev.dw === '2') && !this._resetTimeOut) {
						this._resetTimeOut = setTimeout(() => { this.resetUpdate(dev.id, false, true); }, 10000);
					}
				} else {
					if (dev.dw === '1') {
						stateIndex = 2; // LISTO_PARA_ACTUALIZACION
						upgradable = false;
					} else if (dev.dw !== '1' && dev.dw !== '2') {
						stateIndex = 8; // ERROR
						error = true;
					} else {
						stateIndex = 4; // ACTUALIZANDO
						upgradable = false;
					}
				}
			} else if (dev.dw === '0' && this.previusDw[dev.id] === '1' && this._devicesUpdated[dev.id] && this._devicesUpdated[dev.id] !== 2) {
				stateIndex = 4; // ACTUALIZANDO
				upgradable = false;
			}

			if (dev.dw === '2' && this._devicesUpdated[dev.id] !== 1) {
				for (const signal in this.pbasVersions[dev.id]) {
					if (this.pbasVersions[dev.id][signal] !== dev.vars[signal]) {
						stateIndex = 6; // ACTUALIZADO
						upgradable = true;
						this.pbasVersions[dev.id][signal] = dev.vars[signal];
						this._devicesUpdated[dev.id] = 1;
						break;
					}
				}
			} else if (dev.dw === '2' && this._devicesUpdated[dev.id] === 1) {
				stateIndex = 6; // ACTUALIZADO
				upgradable = true;
			}
		}

		if (this.previusDw[dev.id] === '1' && dev.dw === '0') {
			this.previusDw[dev.id] = '1';
		} else {
			this.previusDw[dev.id] = dev.dw;
		}

		return {
			dw: dev.dw,
			ll: dev.vars.ll,
			upgradable,
			state: this._translate.instant(states[stateIndex]),
			stateValue: stateIndex,
			error
		};
	}

	public resetUpdate(id: number, notification: boolean = true, auto: boolean = true) {
		this.disableButtons = true;

		if (this._resetTimeOut) {
			clearTimeout(this._resetTimeOut);
			this._resetTimeOut = null;
		}

		this._versionsSvc.resetUpgrade(id, auto).then((result: any) => {
			this.disableButtons = false;

			if (notification) {
				this._notificationSvc.success('', this._translate.instant(result.message));
			}

			this._devicesUpdated[id] = 2;
			this.loadItems(this.page);
		}).catch((error) => {
			this.disableButtons = false;
			this._notificationSvc.error('', this._translate.instant(error.message));
		});
	}

	private _addModelType(devices: any) {
		let devIsDS2 = false;
		let devIsIEZ = false;
		let ordered: any;

		if (this._firstIn) {
			this.pbasVersions = {};
		}

		for (let dev of devices) {
			devIsDS2 = dev.vars.mm.indexOf('DS2') > -1;
			devIsIEZ = dev.vars.mr.indexOf('IEZ-70') > -1;

			if (devIsDS2) {
				dev.modelType = 'DS2';
			} else if (!devIsDS2 && devIsIEZ) {
				dev.modelType = 'PS';
			} else {
				dev.modelType = 'MINI';
			}

			ordered = this._orderPbas(dev);
			dev.pbas = ordered.pbas;
			dev.pbasError = (ordered.phPbas > 1 || ordered.orpPbas > 1 || ordered.ppmPbas > 1) ? true : false;
			this._setPbasVersions(dev);
			dev.updateTimeoutError = this._hasTimeoutError(dev);
		}

		this._firstIn = false;

		return devices;
	}

	private _setPbasVersions(dev: any) {
		if (this._firstIn) {
			this.pbasVersions[dev.id] = {
				//mt: (dev.vars.mt) ? dev.vars.mt : '',
				//mr: (dev.vars.mr) ? dev.vars.mr : '',
				//bt: (dev.vars.bt) ? dev.vars.bt : '',
				//bl: (dev.vars.bl) ? dev.vars.bl : '',
				vt: (dev.vars.vt) ? dev.vars.vt : '',
				vr: (dev.vars.vr) ? dev.vars.vr : '',
				it: (dev.vars.it) ? dev.vars.it : '',
				il: (dev.vars.il) ? dev.vars.il : ''
				//vp: (dev.vars.vp) ? dev.vars.vp : ''
			};
		}
	}

	private _orderPbas(dev: any) {
		let signals = ['t1', 't2', 't3', 't4'];
		let pbas: any[] = [];
		let phPbas = 0;
		let orpPbas = 0;
		let ppmPbas = 0;

		switch (dev.modelType) {
			case 'PS':
				pbas[0] = 'ID-COM';
				pbas[1] = 'IEZ-70';
				break;
			case 'DS2':
				pbas[0] = 'DOM17-POT';
				pbas[1] = 'DOM17-VIS';
				break;
			case 'MINI':
				pbas[0] = 'ID-COM';
				break;
		}

		if (dev.modelType === 'DS2') {
			// tslint:disable-next-line:forin
			for (const index in signals) {
				switch (dev.vars[signals[index]]) {
					case 'P':
						if (dev.vars['s' + signals[index][1]] !== '0000000000000') {
							phPbas++;
						}

						pbas.push('DOM17-PH');
						break;
					case 'O':
						if (dev.vars['s' + signals[index][1]] !== '0000000000000') {
							orpPbas++;
						}

						pbas.push('DOM17-ORP');
						break;
					case 'C':
						if (dev.vars['s' + signals[index][1]] !== '0000000000000') {
							ppmPbas++;
						}

						pbas.push('DOM17-PPM');
						break;
					default:
						pbas.push(null);
						break;
				}
			}
		}

		return {
			pbas,
			phPbas,
			orpPbas,
			ppmPbas
		};
	}

	private _orderHardwares(hardwares: any[]) {
		let orderedHardwares = {};

		for (const hard of hardwares) {
			orderedHardwares[hard.code] = hard;
		}

		return orderedHardwares;
	}

	private _orderFirmwares(firmwares: any[]) {
		let orderedFirmwares = {};

		for (const firm of firmwares) {
			orderedFirmwares[firm.code] = firm;
		}

		return orderedFirmwares;
	}

	private _getPbasIndex(device) {
		const indexes = [];

		if (device.pbas[0] === 'DOM17-POT' || device.pbas[0] === 'ID-COM') {
			indexes.push(0);
		}

		if (device.pbas[1] === 'DOM17-VIS') {
			indexes.push(1);
		}

		return indexes;
	}

	private _hasTimeoutError(dev: any) {
		let updateDate = dev.update_time ? moment(dev.update_time.substring(0, 19) + '+0000') : null;
		let now: any;
		let diff: number;

		if (updateDate) {
			updateDate = updateDate.locale(dev.timezone);
			now = moment().tz(dev.timezone);
			diff = now.diff(updateDate, 'minutes');
		}

		return {
			minutes: diff,
			error: (dev.dw !== '0' && updateDate && this.updatesStatus[dev.id] && this.updatesStatus[dev.id].stateValue !== 6) ? diff > 10 : false
		};
	}
}
