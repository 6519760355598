import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';
import { AppConfig } from '../AppConfig';

// services
import { BaseService } from './base.service';
import { AuthService } from './auth.service';

// entities
import { Request } from '../entities/request';

// utils
import * as _ from 'lodash';

@Injectable()
export class RequestsService extends BaseService {
	constructor(public _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public find(limit: number = 20, offset: number = 0) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/' + limit + '/' + offset, null, { headers: this._headers } ).subscribe((result) => {
				result = result/*.json()*/;
				result['items'] = _.map(result['items'], o => new Request(o));
				resolve(result);
			}, (error) => {
				reject(error);
			});
		});
	}

	public save(request) {
		let body = 'request=' + JSON.stringify(request);

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/save', body, { headers: this._headers } ).subscribe((result) => {
				let items = result/*.json()*/;
				resolve(items);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public update(id, data) {
		let body = 'request=' + JSON.stringify(data);

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/update/' + id, body, { headers: this._headers } ).subscribe((result) => {
				let items = result/*.json()*/;
				resolve(items);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}

	public get(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/view/' + id, null, { headers: this._headers } ).subscribe((result) => {
				resolve(new Request(result/*.json()*/));
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	public delete(id: number) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/delete/' + id, null, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	public pendingRequests(option: string = 'total') {
		let body: any;

		body = 'option=' + option;

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/pendingRequests', body, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	public acceptRequest(id: number, alias: string) {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/requests/acceptRequest', 'id=' + id + '&alias=' + alias, { headers: this._headers } ).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
	}
}
