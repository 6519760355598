import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'double-bar',
	templateUrl: './double-bar.component.html',
	styleUrls: ['./double-bar.component.scss']
})
export class DoubleBarComponent implements OnInit, OnDestroy {
	@Input('temp') temp: string;
	@Input('cnd') cnd: string;

	public temperature: number = 0;
	public tempUnit: string = 'C';
	public conductivity: number = 0;
	public conductivityUnit: string = 'G';
	public refreshInterval: any;

	constructor() {
	}

	ngOnInit() {
		this._refresh();
		this.refreshInterval = setInterval(() => {
			this._refresh();
		}, 750);
	}

	ngOnDestroy() {
		clearInterval(this.refreshInterval);
		this.refreshInterval = null;
	}

	private _refresh() {
		if (this.temp) {
			this.temperature = parseFloat(this.temp.substring(0, 5));
			this.tempUnit = this.temp.substring(5, 6);
		}

		if (this.cnd) {
			this.conductivity = parseFloat(this.cnd.substring(0, 4));
			this.conductivityUnit = this.cnd.substring(4, 5);
		}
	}
}
