<div *ngIf="device" [ngStyle]="{'background-image': 'url(' + background + ')'}">
	<div class="box">
		<div class="row">
			<div class="col-md-6">
				<h1>
					<span [routerLink]="['/devices']" style="cursor:pointer">
						<i class="fa fa-chevron-left" aria-hidden="true"></i>
					</span>
					{{ device.alias }}
					<small>{{ 'VISTA_NORMAL' | translate }}</small>
				</h1>
				<div>
					<span class="fa-stack fa-lg" [ngClass]="{fadeIn: !device.status.value}">
						<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
						<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
					</span>
					<span *ngIf="device.status.value === 0">
						{{ device.lastConnection | date:'d/L/yyyy H:mm:ss' }}
					</span>
				</div>
			</div>
			<div class="col-md-6 text-right">
				<div class="row hoursSelect form-horizontal" *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO && device.id_user ===
				loggedUser.id_user">
					<div class="col-xs-9 text-right noPadding">{{ 'HORAS_DESCONECTADO' | translate }}</div>
					<div class="col-xs-3 noPadding">
						<select class="form-control smallSelect" [(ngModel)]="desconectedHours" (change)="changeOffHours()">
							<option value="1">1 Hrs.</option>
							<option value="8">8 Hrs.</option>
							<option value="16">16 Hrs.</option>
							<option value="24">24 Hrs.</option>
						</select>
					</div>
				</div>
				<p>
					<span *ngIf="forecast">
						<ng-container *ngIf="loggedUser.unit_temp === 'C'">{{ forecast.temp_C }} ºC</ng-container>
						<ng-container *ngIf="loggedUser.unit_temp === 'F'">{{ forecast.temp_F }} ºF</ng-container>&nbsp;&nbsp;
					</span>
					<span>
						<i class="fa fa-fw-fa-img"><img src="/assets/images/vfamily/iconos/vf_ico_hora.png"></i>
						{{ date | amDateFormat : 'h:mm A' }}
					</span>&nbsp;&nbsp;
					<span>
						<i class="fa fa-fw-fa-img"><img src="/assets/images/vfamily/iconos/vf_ico_fecha.png"></i>
						{{ date | amDateFormat : 'DD/MM/YYYY' }}
					</span>
				</p>
				<p *ngIf="forecast">
					<span><strong>{{ device.id_loc }}</strong></span>&nbsp;&nbsp;
					<span>{{ 'VIENTO' | translate }}:
						<strong *ngIf="loggedUser.unit_vel === 'KMH'">{{ forecast.winddir16Point }} {{ forecast.windspeedKmph }} Km/H</strong>
						<strong *ngIf="loggedUser.unit_vel !== 'KMH'">{{ forecast.winddir16Point }} {{ forecast.windspeedMiles }} mpH</strong>
					</span>&nbsp;&nbsp;
					<span>{{ 'HUMEDAD' | translate }}: <strong>{{ forecast.humidity / 100 | percent }}</strong></span>
				</p>
				<p *ngIf="!forecast">
					{{ 'OBTENIENDO_TIEMPO' | translate }}
				</p>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-6 text-center">
				<div *ngIf="!isNotOk && (device.status.value !== 0 || !deviceOffHours.limitReached); else siluetaNoBlock">
					<p>
						<img class="signal" src="/assets/images/vfamily/silueta_ok.png">
		  			</p>
				</div>

				<ng-template #siluetaNoBlock>
					<p>
						<img class="signal" src="/assets/images/vfamily/silueta_no.png">
					</p>
				</ng-template>
			</div>

			<div class="col-xs-6 text-center">
				<div *ngIf="isNotOk; else elseImageBlock2">
					<p><img class="signal" src="/assets/images/vfamily/sign_no.png"></p>
				</div>
				<ng-template #elseImageBlock2>
					<p *ngIf="!isNotOk && device.status.value !== 0; else questionBlock">
						<img class="signal" src="/assets/images/vfamily/sign_ok.png">
					</p>
					<ng-template #questionBlock><img class="signal" src="/assets/images/vfamily/question.png"></ng-template>

				</ng-template>
			</div>
			<p class="col-xs-12"></p>
			<div class="col-xs-12 text-center">
				<div *ngIf="isNotOk || (device.status.value === 0 && deviceOffHours.limitReached); else elseMessageBlock">
					<p><span class="lg text-emphasized">{{ 'REVISA_LA_PISCINA' | translate }}</span></p>
				</div>
				<ng-template #elseMessageBlock>
					<p class="xlg"><span [innerHTML]="'BANIO_OK' | translate"></span></p>
					<p><span [innerHTML]="'PISCINA_OK' | translate" class="lg text-emphasized"></span></p>
				</ng-template>
			</div>

		</div>
	</div>

	<div *ngIf="device.status.value === 0">
		<div *ngIf="!deviceOffHours.limitReached" class="familyTimeMessage">
			{{ 'DESCONECTADO_MENOR_1' | translate }}{{ deviceOffHours.hours | number:'1.1-1' }}{{ 'DESCONECTADO_MENOR_2' | translate }}{{ device.max_off_hours }} {{ 'HORAS' | translate }}.
		</div>
		<div *ngIf="deviceOffHours.limitReached" class="familyTimeMessageReached">
			<span *ngIf="deviceOffHours.hours < 48">
				{{ 'DESCONECTADO_MENOR_1' | translate }}{{ deviceOffHours.hours | number:'1.1-1' }} {{ 'HORAS' | translate }}.
			</span>
			<span *ngIf="deviceOffHours.hours > 48">
				{{ 'MAS_48H' | translate }}
			</span>
		</div>
	</div>

	<div class="values">
		<div *ngIf="device.isPH" class="box" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p nowrap>
				<i class="fa fa-fw fa-img"><img src="/assets/images/vfamily/iconos/vf_ico_ph.png"></i>
				<strong>{{ 'PH' | translate | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.vars.mp !== '' && device.vars.mp !== '-----') ? ((device.status.value !== 0 || !deviceOffHours.limitReached) && device.specialStatus.flow ? (device.vars.mp | number:'1.2') : '-') : '0' }}</strong>
			</p>
			<p class="setpoint">
				{{ (device.vars.sp !== '' && device.vars.sp !== '----') ? (device.vars.sp | number:'1.2') : '0' }}
			</p>
		</div>

		<div *ngIf="device.isORP" class="box" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p nowrap>
				<i class="fa fa-fw fa-img"><img src="/assets/images/vfamily/iconos/vf_ico_orp.png"></i>
				<strong>{{ 'ORP' | translate | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.vars.mo !== '') ? ((device.status.value !== 0 || !deviceOffHours.limitReached) && device.specialStatus.flow  && device.vars.mo !== '---' ? (device.vars.mo | number:'1.0-2') : '-') : '0' }}</strong>
			</p>
			<p class="setpoint">
				{{ (device.vars.so !== ''  && device.vars.so !== '---') ? (device.vars.so | number:'1.0-2') : '0' }}
			</p>
		</div>

		<div *ngIf="device.isPPM" class="box" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p nowrap>
				<i class="fa fa-fw fa-img"><img src="/assets/images/vfamily/iconos/vf_ico_ppm.png"></i>
				<strong>{{ 'PPM' | translate | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.vars.mh === '' && device.vars.mh !== '----' || (device.status.value === 0 && deviceOffHours.limitReached) || !device.specialStatus.flow) ? '-' : (device.vars.mh | number:'1.2') }}</strong>
			</p>
			<p class="setpoint">
				{{ (device.vars.sh !== '' && device.vars.sh !== '----') ? (device.vars.sh | number:'1.2') : '-' }}
			</p>
		</div>

		<div *ngIf="device.isPanel3XX" class="box" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p nowrap>
				<i class="fa fa-fw fa-img"><img src="/assets/images/vfamily/iconos/vf_ico_temp_agua.png"></i>
				<strong>{{ 'TEMPERATURA' | translate | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.vars.ta !== '') ? ((device.status.value !== 0 || !deviceOffHours.limitReached) && device.specialStatus.flow ? (device.vars.ta | temperature: loggedUser.unit_temp) : '-') : '0' }}</strong>
			</p>
		</div>

		<div *ngIf="device.isPanel3XX" class="box" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p nowrap>
				<strong>{{ 'GRL' | translate | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.vars.cn !== '' && (device.status.value !== 0 || !deviceOffHours.limitReached) && device.specialStatus.flow) ? (device.vars.cn.substr(0, 4) | number:'1.2') : '-' }}</strong>
			</p>
		</div>

		<div *ngIf="device.cardIZ70 && !device.isDS2" class="box emphasized" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p>
				<strong>{{ device.a1.name | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.a1.measure !== 'N/A') ? (device.a1.measure | number:'1.2-2') : 'N/A' }} {{ device.a1.unit  }}</strong>
			</p>
		</div>

		<div *ngIf="device.cardIZ70 && !device.isDS2" class="box emphasized" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p>
				<strong>{{ device.a2.name | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.a2.measure !== 'N/A') ? (device.a2.measure | number:'1.2-2') : 'N/A'  }} {{ device.a2.unit  }}</strong>
			</p>
		</div>

		<div *ngIf="device.isDS2" class="box emphasized" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p nowrap text-ellipsis>
				<i class="fa fa-fw fa-img"><img src="/assets/images/vfamily/iconos/vf_ico_temp_agua.png"></i>
				<strong>{{ 'TEMPERATURA' | translate | uppercase }}</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.status.value !== 0 || !deviceOffHours.limitReached) && device.specialStatus.flow ? (device.vars.ta | temperature: loggedUser.unit_temp) : '-' }}</strong>
			</p>
		</div>

		<div *ngIf="device.isDS2" class="box emphasized" [ngClass]="{clock: device.status.value === 0 && !deviceOffHours.limitReached}">
			<p>
				<strong>Gr/l</strong>
			</p>
			<p class="xlg">
				<strong>{{ (device.status.value !== 0 || !deviceOffHours.limitReached) && device.specialStatus.flow && device.specialStatus.prodUp30 ? (cn.value + ' ' + cn.unit) : '-' }}</strong>
			</p>
		</div>
	</div>
	<br>
</div>
