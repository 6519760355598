import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http'; */
import { HttpClient} from '@angular/common/http';

@Injectable()
export class GeolocationService {
	public key: string = 'AIzaSyCDuuUvosA6CwctP-cL3lKhRNCNFu6UUTE';

	constructor(protected _http: HttpClient) { }

	public getAddress(lat: number, long: number) {
		// CAMBIAR LA KEY
		let url: string = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + long + '&key=' + this.key;

		return new Promise((resolve, reject) => {
			this._http.get(url).subscribe((result: any) => {
				let address = {};

				result = result/*.json()*/;

				if (result.status === 'OK') {
					result = result.results[0].address_components;
					for (let item of result) {
						address[item.types[0]] = item;
					}
				} else {
					address = '';
				}

				resolve(address);
			}, (error) => {
				reject(error);
			});
		});
	}

	public getCoordinates(address: string) {
		// CAMBIAR LA KEY
		let url: string = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + this.key;

		return new Promise((resolve, reject) => {
			this._http.get(url).subscribe((result: any) => {
				result = result/*.json()*/;

				result = (result.status === 'OK') ? result.results[0].geometry.location : '';

				resolve(result);
			}, (error) => {
				reject(error);
			});
		});
	}
}
