<header ps-header>
	<h2>{{ "OPERACIONES_DISPOSITIVOS" | translate }}</h2>
</header>
<main class="container-fluid">
	<div *ngIf="devices">
		<span *ngIf="totalDevices" class="badge badge-info">{{ totalDevices }}</span>
		<span>{{ 'RESULTADOS' | translate }}</span>
	</div>

	<div [ngSwitch]="loggedUser.id_role">
		<div *ngSwitchCase="('' | roles).ADMIN">
			<p class="text-align" *ngIf="devices">
				<button  [routerLink]="['/devices/add']" class="btn btn-primary">{{ 'NUEVO_DISPOSITIVO' | translate | uppercase }}</button>
			</p>
			<table-sorter [disableBtn]="disableButtons" [loading]="!devices" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limitDevices($event)" (prev)="loadItems($event);" (search)="searchText = $event" (next)="loadItems($event)" (pageChange)="loadItems($event)">

				<div class="visible-xs">
					<div *ngFor="let device of devices; let i = index" class="panel panel-default">
						<div class="panel-body">
							<div display-table (click)="deviceTools[device.id] = !deviceTools[device.id]" style="cursor: pointer">
								<div display-table-cell nowrap align-middle class="status">
									<span class="fa-stack fa-lg fa-fw" [ngClass]="{fadeIn: !device.status.value}">
										<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
										<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
									</span>
								</div>
								<div display-table-cell>
									<div class="text-primary">{{ device.id_loc }}</div>
									<div>
										<strong class="text-overflow">{{ device.alias }}</strong> <span *ngIf="device.isDemo"
											class="badge badge-secondary">{{ 'DEMO' | translate }}</span>
									</div>
									<div><span class="text-muted">{{ 'ns' | uppercase }} {{ device.ns }}</span></div>
									<div [hidden]="device.alarmsCollapsed">
										<span class="text-danger" *ngFor="let alarm of device.alarms | filter : 'value'">
											<i class="fa" [ngClass]="alertIcons[alarm.value]"></i> {{ alarm.label | translate }}
										</span>
									</div>
									<div *ngIf="device.user" class="text-primary">
										<a href="mailto:{{ device.user.usr }}" (click)="$event.stopPropagation()">{{ device.user.nombre }} {{ device.user.apellidos }}</a>
										<br>
									</div>
								</div>
								<div display-table-cell nowrap *ngIf="(device.alarms | filter : 'value').length">
									<button class="btn btn-link btn-danger fa-stack fa-lg" (click)="device.alarmsCollapsed = !device.alarmsCollapsed; $event.stopPropagation()" style="margin-right: 10px">
										<i class="fa fa-exclamation-triangle fa-stack-2x"></i>
										<span class="badge">{{ (device.alarms | filter : 'value').length }}</span>
									</button>
								</div>
							</div>
							<div class="text-center" (click)="deviceTools[device.id] = !deviceTools[device.id]">
								<i class="fa" [ngClass]="(!deviceTools[device.id]) ? 'fa-caret-down' : 'fa-caret-up'" aria-hidden="true"></i>
							</div>
							<div *ngIf="device" class="optionsBar double" [ngClass]="(deviceTools[device.id]) ? 'opened' : 'closed'">
								<div class="logos">
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/alarms', device.id]" [title]="'ALARMAS' | translate">
										<img src="/assets/images/acciones/alarmas.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]" [title]="'VISTA_PROFESIONAL' | translate">
										<img src="/assets/images/acciones/vistaPro.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/family', device.id]" [title]="'VISTA_NORMAL' | translate">
										<img src="/assets/images/acciones/family.png">
									</a>
								</div>
								<div class="logos">
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'edit', device.id]" [title]="'EDITAR' | translate">
										<img src="/assets/images/acciones/editar.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/inputs/edit', device.id]" [title]="'ENTRADAS' | translate">
										<img src="/assets/images/acciones/entradas.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/relays/edit', device.id]" [title]="'PERSONALIZACION' | translate">
										<img src="/assets/images/acciones/personalizar.png">
									</a>
									<a [disabled]="blockButtons" class="btn" *ngIf="device.activo"  (click)="selectedDevice=device;
									deleteModal.show()" class="btn" title="{{ 'BT_ELIMINAR' | translate }}"><img width="17"
											src="/assets/images/acciones/trash.png"></a>

								</div>
							</div>

							<hr *ngIf="device.user">
							<div *ngIf="device.user" display-table class="text-center">
								<span class="label label-info">VT: {{ (device.vars.vt !== '') ? device.vars.vt : '-' }}</span>
								<span class="label label-info" *ngIf="device.isPH || device.electrolysisPH">PH: {{ device.status.value !== 0 &&
								device.specialStatus.flow && device.vars.mp !== '-----' ? (device.vars.mp | number:'1.2') : '-' }} |
									{{ device.vars.sp | number:'1.2' }}</span>
								<span class="label label-info" *ngIf="device.isORP || device.electrolysisORP">ORP: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.mo !== '---' ? device.vars.mo : '-' }} | {{ device.vars.so }}</span>
								<span class="label label-info" *ngIf="device.isPPM || device.electrolysisPPM">PPM: {{ device.status.value !== 0 &&
								device.specialStatus.flow && device.vars.mh !== '----' ? (device.vars.mh | number: '1.2') : '-' }} |
									{{ device.vars.sh | number: '1.2' }}</span>
								<span class="label label-info" *ngIf="device.electrolysis || device.electrolisisPPM">PRO: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.pa !== '---' ? device.vars.pa : '-' }} | {{ device.vars.sc }}</span>
								<span class="label label-info">{{'ALARMAS' | translate}}: &nbsp;
									<span>SF {{ device.vars.sf != '-' ?  device.vars.sf : ''}}</span>&nbsp;|&nbsp;
								<span>WS {{ device.vars.ws != '-' ?  device.vars.ws : ''}}</span>&nbsp;|&nbsp;
								<span>CC {{ device.vars.cc != '-' ?  device.vars.cc : ''}}</span>
								</span>
								<span class="label label-info" *ngIf="device.cardIZ70">{{'ENTRADAS' | translate}}: &nbsp;
									{{ device.vars.a1 }}&nbsp;|
									{{ device.vars.a2 }}&nbsp;|
									{{ device.vars.d1 }}&nbsp;|
									{{ device.vars.d2 }}&nbsp;|
									{{ device.vars.d3 }}&nbsp;|
									{{ device.vars.d4 }}
								</span>
								<span class="label label-info" *ngIf="device.cardIZ70">{{'RELES' | translate}}:
									{{ device.vars.ac }} {{ device.vars.mc }}&nbsp;|
									{{ device.vars.aj }} {{ device.vars.mj }}&nbsp;|
									{{ device.vars.ab }} {{ device.vars.mb }}&nbsp;|
									{{ device.vars.af }} {{ device.vars.mf }}&nbsp;|
									{{ device.vars.ax }} {{ device.vars.mx }}&nbsp;|
									{{ device.vars.al }} {{ device.vars.ml }}
								</span>
							</div>

							<hr>
							<div display-table class="text-center">
								<span class="badge" [ngClass]="device.alarma_dia == 1 ? 'badge-success' : 'badge-danger'" title="{{ (device.alarma_dia == 1 ? 'TIP_ALARM_24' : 'TIP_ALARM_24_DESC') | translate }}"> </span>&nbsp;{{'ALARM_DIA' | translate}}
								<br>
								<span class="badge" [ngClass]="device.notificar_alarma == 1 ? 'badge-success' : 'badge-danger'" title="{{ (device.notificar_alarma == 1 ? 'TIP_ALARM_INST' : 'TIP_ALARM_INST_DESC') | translate }}"> </span>&nbsp;{{'ALARM_INST' | translate}}
							</div>
							<div display-table class="text-center panel-footer">
								<div display-table-cell nowrap>
									<button [disabled]="blockButtons" class="btn btn-link" [routerLink]="['logs', device.id]">{{ 'HISTORICOS' | translate }}</button>
								</div>
							</div>

						</div>
					</div>
				</div>

				<table *ngIf="devices" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
					<thead>
						<tr>
							<th ordenable order="vars/updated" (change)="changeOrder($event)">{{ 'ESTADO' | translate }}</th>
							<th ordenable order="user/nombre" (change)="changeOrder($event)" class="hidden-sm">{{ 'USUARIO' | translate }}</th>
							<th style="width: 40%;">{{ 'PARAMETRO' | translate }}</th>
							<th style="width: 5%;">{{ 'ALARMAS' | translate }}</th>
							<th class="actions" style="width: 5%;">{{ 'ACCIONES' | translate}}</th>
						</tr>
					</thead>
					<tbody>
						<ng-template ngFor let-device [ngForOf]="devices" let-i="index">
							<tr (click)="deviceTools[device.id] = !deviceTools[device.id]" style="cursor: pointer">
								<td>
									<div display-table>
										<div display-table-cell nowrap align-middle>
											<span class="fa-stack fa-lg" [ngClass]="{fadeIn: !device.status.value}">
												<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
												<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
											</span> &nbsp;
										</div>
										<div display-table-cell text-ellipsis>
											<span>{{ device.id_loc }}</span>
											<br>
											<div>
												<span class="text-overflow">{{ device.alias }}</span> <span *ngIf="device.isDemo" class="badge badge-secondary">{{
												'DEMO' |
													translate }}</span>
											</div>
											<div class="text-muted">NS: {{ device.ns }}</div>
											<ng-container *ngIf="device.user">
												<a href="mailto:{{ device.user.usr }}" [title]="device.user.usr" (click)="$event.stopPropagation()" class="visible-sm">{{ device.user.nombre }} {{ device.user.apellidos }}</a>
											</ng-container>
										</div>
									</div>
								</td>
								<td class="hidden-sm">
									<ng-container *ngIf="device.user">
										<p>{{ device.user.nombre }} {{ device.user.apellidos }}</p>
										<a href="mailto:{{ device.user.usr }}" [title]="device.user.usr" (click)="$event.stopPropagation()">{{ device.user.usr }}</a>
									</ng-container>
								</td>
								<td>
									<div *ngIf="device.user">
										<span class="label label-info">VT: {{ (device.vars.vt !== '') ? device.vars.vt : '-' }}</span>
										<span class="label label-info" *ngIf="device.isPH || device.electrolysisPH">PH: {{ device.status.value !== 0 &&
										device.specialStatus.flow && device.vars.mp !== '-----' ? (device.vars.mp | number:'1.2') : '-' }} | {{
											device.vars.sp |
												number:'1.2' }}</span>
										<span class="label label-info" *ngIf="device.isORP || device.electrolysisORP">ORP: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.mo !== '---' ? device.vars.mo : '-' }} | {{ device.vars.so }}</span>
										<span class="label label-info" *ngIf="device.isPPM || device.electrolysisPPM">PPM: {{ device.status.value !== 0 &&
										device.specialStatus.flow && device.vars.mh !== '----' ? (device.vars.mh | number: '1.2') : '-' }} | {{ device.vars.sh | number: '1.2' }}</span>
										<span class="label label-info" *ngIf="device.electrolysis || device.electrolisisPPM">PRO: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.pa !== '---' ? device.vars.pa : '-' }} | {{ device.vars.sc }}</span>
										<span class="label label-info">{{'ALARMAS' | translate}}: &nbsp;
											<span>SF {{ device.vars.sf != '-' ?  device.vars.sf : ''}}</span>&nbsp;|&nbsp;
										<span>WS {{ device.vars.ws != '-' ?  device.vars.ws : ''}}</span>&nbsp;|&nbsp;
										<span>CC {{ device.vars.cc != '-' ?  device.vars.cc : ''}}</span>
										</span>
										<span class="label label-info" *ngIf="device.cardIZ70">{{'ENTRADAS' | translate}}: &nbsp;
											{{ device.vars.a1 }}&nbsp;|
											{{ device.vars.a2 }}&nbsp;|
											{{ device.vars.d1 }}&nbsp;|
											{{ device.vars.d2 }}&nbsp;|
											{{ device.vars.d3 }}&nbsp;|
											{{ device.vars.d4 }}
										</span>
										<span class="label label-info" *ngIf="device.cardIZ70">{{'RELES' | translate}}:
											{{ device.vars.ac }} {{ device.vars.mc }}&nbsp;|
											{{ device.vars.aj }} {{ device.vars.mj }}&nbsp;|
											{{ device.vars.ab }} {{ device.vars.mb }}&nbsp;|
											{{ device.vars.af }} {{ device.vars.mf }}&nbsp;|
											{{ device.vars.ax }} {{ device.vars.mx }}&nbsp;|
											{{ device.vars.al }} {{ device.vars.ml }}
										</span>
									</div>
								</td>
								<td class="actions" style="text-align: left !important;">
									<span class="badge" [ngClass]="device.alarma_dia == 1 ? 'badge-success' : 'badge-danger'" title="{{ device.alarma_dia == 1 ? 'TIP_ALARM_24' : 'TIP_ALARM_24_DESC' | translate }}"> </span>&nbsp;{{'ALARM_DIA' | translate}}
									<br>
									<span class="badge" [ngClass]="device.notificar_alarma == 1 ? 'badge-success' : 'badge-danger'" title="{{ device.notificar_alarma == 1 ? 'TIP_ALARM_INST' : 'TIP_ALARM_INST_DESC' | translate }}"> </span>&nbsp;{{'ALARM_INST' | translate}}
								</td>
								<td class="actions">
									<button [disabled]="blockButtons" class="btn btn-primary" [routerLink]="['logs', device.id]"><span class="fa fa-list"></span></button>
								</td>
							</tr>
							<tr *ngIf="deviceTools[device.id]" class="dark">
								<td colspan="5" class="logos">
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/alarms', device.id]" [title]="'ALARMAS' | translate">
										<img src="/assets/images/acciones/alarmas.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]" [title]="'VISTA_PROFESIONAL' | translate">
										<img src="/assets/images/acciones/vistaPro.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/family', device.id]" [title]="'VISTA_NORMAL' | translate">
										<img src="/assets/images/acciones/family.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'edit', device.id]" [title]="'EDITAR' | translate">
										<img src="/assets/images/acciones/editar.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/inputs/edit', device.id]" [title]="'ENTRADAS' | translate">
										<img src="/assets/images/acciones/entradas.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/relays/edit', device.id]" [title]="'PERSONALIZACION' | translate">
										<img src="/assets/images/acciones/personalizar.png">
									</a>
									<a [disabled]="blockButtons" class="btn" *ngIf="device.activo" (click)="selectedDevice = device; deleteModal.show();
									$event.stopPropagation()" title="{{'ELIMINAR_DISPOSITIVO' | translate}}"><img width="17"
											src="/assets/images/acciones/trash.png"></a>

								</td>
							</tr>
						</ng-template>
					</tbody>
				</table>
			</table-sorter>
		</div>

		<div *ngSwitchCase="('' | roles).SAT">
			<table-sorter [disableBtn]="disableButtons"  [loading]="!devices" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limitDevices($event)" (prev)="loadItems($event);" (search)="searchText = $event" (next)="loadItems($event)" (pageChange)="loadItems($event)">

				<div class="visible-xs">
					<div *ngFor="let device of devices; let i = index" class="panel panel-default">
						<div class="panel-body">
							<div display-table (click)="deviceTools[device.id] = !deviceTools[device.id]" style="cursor: pointer">
								<div display-table-cell nowrap align-middle class="status">
									<span class="fa-stack fa-lg fa-fw" [ngClass]="{fadeIn: !device.status.value}">
										<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
										<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
									</span>
								</div>
								<div display-table-cell>
									<div class="text-primary">{{ device.id_loc }}</div>
									<div>
										<strong class="text-overflow">{{ device.alias }}</strong> <span *ngIf="device.isDemo"
											class="badge badge-secondary">{{ 'DEMO' | translate }}</span>
									</div>
									<div><span class="text-muted">{{ 'ns' | uppercase }} {{ device.ns }}</span></div>
									<div [hidden]="device.alarmsCollapsed">
										<span class="text-danger" *ngFor="let alarm of device.alarms | filter : 'value'">
											<i class="fa" [ngClass]="alertIcons[alarm.value]"></i> {{ alarm.label | translate }}
										</span>
									</div>
								</div>
								<div display-table-cell nowrap *ngIf="(device.alarms | filter : 'value').length">
									<button class="btn btn-link btn-danger fa-stack fa-lg" (click)="device.alarmsCollapsed = !device.alarmsCollapsed; $event.stopPropagation()" style="margin-right: 10px">
										<i class="fa fa-exclamation-triangle fa-stack-2x"></i>
										<span class="badge">{{ (device.alarms | filter : 'value').length }}</span>
									</button>
								</div>
							</div>
							<div class="text-center" (click)="deviceTools[device.id] = !deviceTools[device.id]">
								<i class="fa" [ngClass]="(!deviceTools[device.id]) ? 'fa-caret-down' : 'fa-caret-up'" aria-hidden="true"></i>
							</div>
							<div *ngIf="device" class="optionsBar" [ngClass]="(deviceTools[device.id]) ? 'opened' : 'closed'">
								<div class="logos">
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/alarms', device.id]" [title]="'ALARMAS' | translate">
										<img src="/assets/images/acciones/alarmas.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]" [title]="'VISTA_PROFESIONAL' | translate">
										<img src="/assets/images/acciones/vistaPro.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/family', device.id]" [title]="'VISTA_NORMAL' | translate">
										<img src="/assets/images/acciones/family.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'edit', device.id]" [title]="'EDITAR' | translate">
										<img src="/assets/images/acciones/editar.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/inputs/edit', device.id]" [title]="'ENTRADAS' | translate">
										<img src="/assets/images/acciones/entradas.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/relays/edit', device.id]" [title]="'PERSONALIZACION' | translate">
										<img src="/assets/images/acciones/personalizar.png">
									</a>
									<a *ngIf="device.activo" class="btn" (click)="selectedDevice=device; deleteModal.show();
									$event.stopPropagation()" title="{{ 'BT_ELIMINAR' | translate }}"><img width="17"
											src="/assets/images/acciones/trash.png"></a>

								</div>
							</div>

							<hr *ngIf="device.user">
							<div display-table class="text-center">
								<div *ngIf="device.user">{{ device.user.nombre }} {{ device.user.apellidos }}
									<br>
									<span>{{ device.user.usr }}</span>
								</div>
							</div>

							<hr *ngIf="device.user">
							<div *ngIf="device.user" display-table class="text-center">
								<span class="label label-info">VT: {{ (device.vars.vt !== '') ? device.vars.vt : '-' }}</span>
								<span class="label label-info" *ngIf="device.isPH || device.electrolysisPH">PH: {{ device.status.value !== 0 &&
								device.specialStatus.flow && device.vars.mp !== '-----' ? (device.vars.mp | number:'1.2') : '-' }} |
									{{ device.vars.sp | number:'1.2' }}</span>
								<span class="label label-info" *ngIf="device.isORP || device.electrolysisORP">ORP: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.mo !== '---' ? device.vars.mo : '-' }} | {{ device.vars.so }}</span>
								<span class="label label-info" *ngIf="device.isPPM || device.electrolysisPPM">PPM: {{ device.status.value !== 0 &&
								device.specialStatus.flow && device.vars.mh !== '----' ? (device.vars.mh | number: '1.2') : '-' }} | {{ device.vars.sh | number: '1.2' }}</span>
								<span class="label label-info" *ngIf="device.electrolysis || device.electrolisisPPM">PRO: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.pa !== '---' ? device.vars.pa : '-' }} | {{ device.vars.sc }}</span>
								<span class="label label-info">{{'ALARMAS' | translate}}: &nbsp;
									<span>SF {{ device.vars.sf != '-' ?  device.vars.sf : ''}}</span>&nbsp;|&nbsp;
								<span>WS {{ device.vars.ws != '-' ?  device.vars.ws : ''}}</span>&nbsp;|&nbsp;
								<span>CC {{ device.vars.cc != '-' ?  device.vars.cc : ''}}</span>
								</span>
								<span class="label label-info" *ngIf="device.cardIZ70">{{'ENTRADAS' | translate}}: &nbsp;
									{{ device.vars.a1 }}&nbsp;|
									{{ device.vars.a2 }}&nbsp;|
									{{ device.vars.d1 }}&nbsp;|
									{{ device.vars.d2 }}&nbsp;|
									{{ device.vars.d3 }}&nbsp;|
									{{ device.vars.d4 }}
								</span>
								<span class="label label-info" *ngIf="device.cardIZ70">{{'RELES' | translate}}:
									{{ device.vars.ac }} {{ device.vars.mc }}&nbsp;|
									{{ device.vars.aj }} {{ device.vars.mj }}&nbsp;|
									{{ device.vars.ab }} {{ device.vars.mb }}&nbsp;|
									{{ device.vars.af }} {{ device.vars.mf }}&nbsp;|
									{{ device.vars.ax }} {{ device.vars.mx }}&nbsp;|
									{{ device.vars.al }} {{ device.vars.ml }}
								</span>
							</div>

							<hr>
							<div display-table class="text-center">
								<span class="badge" [ngClass]="device.alarma_dia == 1 ? 'badge-success' : 'badge-danger'" title="{{ (device.alarma_dia == 1 ? 'TIP_ALARM_24' : 'TIP_ALARM_24_DESC') | translate }}"> </span>&nbsp;{{'ALARM_DIA' | translate}}
								<br>
								<span class="badge" [ngClass]="device.notificar_alarma == 1 ? 'badge-success' : 'badge-danger'" title="{{ (device.notificar_alarma == 1 ? 'TIP_ALARM_INST' : 'TIP_ALARM_INST_DESC') | translate }}"> </span>&nbsp;{{'ALARM_INST' | translate}}
							</div>
							<div display-table class="text-center panel-footer">
								<div display-table-cell nowrap>
									<button class="btn btn-link" [routerLink]="['logs', device.id]">{{ 'HISTORICOS' | translate }}</button>
								</div>
							</div>

						</div>
					</div>
				</div>

				<table *ngIf="devices" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
					<thead>
						<tr>
							<th ordenable order="ns" (change)="changeOrder($event)">{{ 'ESTADO' | translate }}</th>
							<th ordenable order="user/nombre" (change)="changeOrder($event)">{{ 'USUARIO' | translate }}</th>
							<th style="width: 40%;">{{ 'PARAMETRO' | translate }}</th>
							<th style="width:5%;">{{ 'ALARMAS' | translate }}</th>
							<th class="actions" style="width: 5%;">{{ 'ACCIONES' | translate}}</th>
						</tr>
					</thead>
					<tbody>
						<ng-template ngFor let-device [ngForOf]="devices" let-i="index">
							<tr (click)="deviceTools[device.id] = !deviceTools[device.id]" style="cursor: pointer">
								<td>
									<div display-flex justify-left>
										<span class="fa-stack fa-lg" [ngClass]="{fadeIn: !device.status.value}">
											<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
											<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
										</span> &nbsp;
										<div nowrap>
											<span>{{ device.id_loc }}</span>
											<br>
											<strong class="text-overflow">{{ device.alias }}</strong> <span *ngIf="device.isDemo" class="badge badge-secondary">{{ 'DEMO' |
												translate }}</span>
											<br>
											<span class="text-muted">NS: {{ device.ns }}</span>
										</div>
									</div>
								</td>
								<td>
									<div *ngIf="device.user">{{ device.user.nombre }} {{ device.user.apellidos }}
										<br>
										<span *ngIf="loggedUser.id_role !== ('' | roles).SAT">{{ device.user.usr }}</span>
									</div>
								</td>
								<td>
									<div *ngIf="device.user">
										<span class="label label-info">VT: {{ (device.vars.vt !== '') ? device.vars.vt : '-' }}</span>
										<span class="label label-info" *ngIf="device.isPH || device.electrolysisPH">PH: {{ device.status.value !== 0 &&
										device.specialStatus.flow && device.vars.mp !== '-----' ? (device.vars.mp | number:'1.2') : '-' }} | {{ device.vars.sp |
												number:'1.2' }}</span>
										<span class="label label-info" *ngIf="device.isORP || device.electrolysisORP">ORP: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.mo !== '---' ? device.vars.mo : '-' }} | {{ device.vars.so }}</span>
										<span class="label label-info" *ngIf="device.isPPM || device.electrolysisPPM">PPM: {{ device.status.value !== 0 &&
										device.specialStatus.flow && device.vars.mh !== '----' ? (device.vars.mh | number: '1.2') : '-' }} | {{ device.vars.sh | number: '1.2' }}</span>
										<span class="label label-info" *ngIf="device.electrolysis || device.electrolisisPPM">PRO: {{ device.status.value !== 0 && device.specialStatus.flow && device.vars.pa !== '---' ? device.vars.pa : '-' }} | {{ device.vars.sc }}</span>
										<span class="label label-info">{{'ALARMAS' | translate}}: &nbsp;
											<span>SF {{ device.vars.sf != '-' ?  device.vars.sf : ''}}</span>&nbsp;|&nbsp;
										<span>WS {{ device.vars.ws != '-' ?  device.vars.ws : ''}}</span>&nbsp;|&nbsp;
										<span>CC {{ device.vars.cc != '-' ?  device.vars.cc : ''}}</span>
										</span>
										<span class="label label-info" *ngIf="device.cardIZ70">{{'ENTRADAS' | translate}}: &nbsp;
											{{ device.vars.a1 }}&nbsp;|
											{{ device.vars.a2 }}&nbsp;|
											{{ device.vars.d1 }}&nbsp;|
											{{ device.vars.d2 }}&nbsp;|
											{{ device.vars.d3 }}&nbsp;|
											{{ device.vars.d4 }}
										</span>
										<span class="label label-info" *ngIf="device.cardIZ70">{{'RELES' | translate}}:
											{{ device.vars.ac }} {{ device.vars.mc }}&nbsp;|
											{{ device.vars.aj }} {{ device.vars.mj }}&nbsp;|
											{{ device.vars.ab }} {{ device.vars.mb }}&nbsp;|
											{{ device.vars.af }} {{ device.vars.mf }}&nbsp;|
											{{ device.vars.ax }} {{ device.vars.mx }}&nbsp;|
											{{ device.vars.al }} {{ device.vars.ml }}
										</span>
									</div>
								</td>
								<td class="actions" style="text-align: left !important;">
									<span class="badge" [ngClass]="device.alarma_dia == 1 ? 'badge-success' : 'badge-danger'" title="{{ device.alarma_dia == 1 ? 'TIP_ALARM_24' : 'TIP_ALARM_24_DESC' | translate }}">   </span> &nbsp;{{'ALARM_DIA' | translate}}
									<br>
									<span class="badge" [ngClass]="device.notificar_alarma == 1 ? 'badge-success' : 'badge-danger'" title="{{ device.notificar_alarma == 1 ? 'TIP_ALARM_INST' : 'TIP_ALARM_INST_DESC' | translate }}"> </span> &nbsp;{{'ALARM_INST' | translate}}
								</td>
								<td class="actions">
									<button class="btn btn-primary" [routerLink]="['logs', device.id]"><span class="fa fa-list"></span></button>

									<button class="btn btn-default"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>

								</td>
							</tr>
							<tr *ngIf="deviceTools[device.id]" class="dark">
								<td colspan="5" class="logos">
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/alarms', device.id]" [title]="'ALARMAS' | translate">
										<img src="/assets/images/acciones/alarmas.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]" [title]="'VISTA_PROFESIONAL' | translate">
										<img src="/assets/images/acciones/vistaPro.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices/family', device.id]" [title]="'VISTA_NORMAL' | translate">
										<img src="/assets/images/acciones/family.png">
									</a>
									<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'edit', device.id]" [title]="'EDITAR' | translate">
										<img src="/assets/images/acciones/editar.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/inputs/edit', device.id]" [title]="'ENTRADAS' | translate">
										<img src="/assets/images/acciones/entradas.png">
									</a>
									<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/relays/edit', device.id]" [title]="'PERSONALIZACION' | translate">
										<img src="/assets/images/acciones/personalizar.png">
									</a>
									<a *ngIf="device.activo" class="btn"
											(click)="selectedDevice=device;
											deleteModal.show();
											$event.stopPropagation();
									" title="{{'ELIMINAR_DISPOSITIVO' | translate}}"><img width="17"
											src="/assets/images/acciones/trash.png"></a>
								</td>
							</tr>
						</ng-template>
					</tbody>
				</table>
			</table-sorter>
		</div>

		<div *ngSwitchCase="('' | roles).QC">
			<p></p>
			<table-sorter [disableBtn]="disableButtons"  [(page)]="page" [loading]="!devices" [limit]="limit" (limitChanged)="limitDevices($event)" (prev)="loadItems($event)" (next)="loadItems($event)" [total]="totalPages" (search)="searchText = $event" (pageChange)="loadItems($event)">
				<div class="visible-xs">
					<div *ngFor="let device of devices; let i=index" class="panel panel-default">
						<div class="panel-body">
							<div display-table>
								<span class="fa-stack fa-lg" [ngClass]="{'fadeIn': !device.status.value}">
									<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
									<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
								</span>
								<span>{{ device.status.label | translate }}</span>
								<div *ngIf="device.activo">
									<p><strong class="text-overflow">{{ 'ESTADO' | translate }}:</strong> {{ device.alias }}</p>
									<p *ngIf="device.id_loc">({{ device.id_loc }})</p>
								</div>
							</div>
							<hr>
							<div display-table class="text-center">
								<div>NS: <strong>{{ device.ns }}</strong></div>
								<div class="text-primary">Versión: {{ (device.vars.vt !== '') ? device.vars.vt : '-' }}</div>
							</div>
							<hr>
							<div display-table class="row">
								<div class="col-xs-10">
									<div *ngIf="device.user">
										<div>{{ device.user.nombre }} {{ device.user.apellidos }}</div>
										<div>{{ device.user.usr }}</div>
									</div>
								</div>
								<div class="col-xs-12 col-sm-10 text-center button-margin">
									<div *ngIf="device.user && device.activo">
										<button [disabled]="blockButtons || device.status.value == 0" class="btn btn-default" *ngIf="!device.isDS2 && device.vars.vt != idcomFirmware.code" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}" (click)="updateDevice(idcomFirmware.id, idcomFirmware.code, idcomFirmware.url, device.ns)"><span class="fa fa-refresh"></span></button>
										<button [disabled]="blockButtons" class="btn btn-default" title="{{ 'ELIMINAR_DISPOSITIVO' | translate }}" (click)="showDeleteDevice(i)"><i class="fa fa-trash-o"></i></button>
										<button [disabled]="blockButtons || !validFirmwares || !validFirmwares[device.vars.vt]" class="btn btn-default btn-image" title="{{ 'VISTA_PROFESIONAL' | translate }}" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]"><img src="/assets/images/acciones/vistaPro.png"></button>
									</div>
									<div *ngIf="nsValid">
										<button [disabled]="blockButtons" class="btn btn-default" *ngIf="!device.activo" title="{{ 'ASOCIARME_DISPOSITIVO' | translate }}" (click)="showLinkDevice(i)"><i class="fa fa-link"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<table *ngIf="devices" class="table table-bordered table-hover hidden-xs">
					<thead>
						<tr>
							<th ordenable order="vars/updated" (change)="changeOrder($event)" style="width: 30%">{{ 'ESTADO' | translate }}</th>
							<th ordenable order="ns" (change)="changeOrder($event)" style="width: 30%">{{ 'NUM_SERIE' | translate }}</th>
							<th style="width: 30%">{{ 'USUARIO' | translate }}</th>
							<th style="width: 10%">{{ 'ACCIONES' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<ng-template ngFor let-device [ngForOf]="devices" let-i="index">
							<tr>
								<td>
									<div>
										<span class="fa-stack fa-lg" [ngClass]="{'fadeIn': !device.status.value}">
											<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
											<i class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
										</span>
										<span>{{ device.status.label | translate }}</span>
										<div *ngIf="device.activo && device.user">
											<div class="text-overflow"><strong>{{ 'ALIAS' | translate }}:</strong > {{ device.alias }}</div>
											<div *ngIf="device.id_loc">({{ device.id_loc }})</div>
										</div>
									</div>
								</td>
								<td>
									<div>
										<div><strong>NS: {{ device.ns }}</strong></div>
										<div class="text-primary">{{ 'VERSION' | translate }}: {{ (device.vars.vt !== '') ? device.vars.vt : '-' }}</div>
									</div>
								</td>
								<td>
									<div *ngIf="device.user">
										<div>{{ device.user.nombre }} {{ device.user.apellidos }}</div>
										<div>{{ device.user.usr }}</div>
									</div>
								</td>
								<td class="actions">
									<div *ngIf="device.user && device.activo">
										<button [disabled]="blockButtons || device.status.value == 0" class="btn btn-default" *ngIf="!device.isDS2 && device.vars.vt != idcomFirmware.code" title="{{ 'ACTUALIZAR_DISPOSITIVO' | translate }}" (click)="updateDevice(idcomFirmware.id, idcomFirmware.code, idcomFirmware.url, device.ns)"><span class="fa fa-refresh"></span></button>
										<button [disabled]="blockButtons" class="btn btn-default" title="{{ 'ELIMINAR_DISPOSITIVO' | translate }}" (click)="showDeleteDevice(i)"><i class="fa fa-trash-o"></i></button>
										<button [disabled]="blockButtons || !validFirmwares || !validFirmwares[device.vars.vt]" class="btn btn-default btn-image" title="{{ 'VISTA_PROFESIONAL' | translate }}" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]"><img src="/assets/images/acciones/vistaPro.png"></button>
									</div>
									<div *ngIf="nsValid">
										<button [disabled]="blockButtons" class="btn btn-default" *ngIf="!device.activo" title="{{ 'ASOCIARME_DISPOSITIVO' | translate }}" (click)="showLinkDevice(i)"><i class="fa fa-link"></i></button>
									</div>
								</td>
							</tr>
						</ng-template>
					</tbody>
				</table>
			</table-sorter>
		</div>

		<div *ngSwitchDefault>
			<p *ngIf="loggedUser.id_role === ('' | roles).PROFESIONAL"></p>
			<p *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO && devices" class="text-align">
				<button class="btn btn-primary" [routerLink]="['/devices', 'add']" title="{{ 'ASOCIAR_DISPOSITIVO' | translate }}">{{ 'ASOCIAR_DISPOSITIVO' | translate }}</button>
			</p>

			<table-sorter [disableBtn]="disableButtons" [loading]="!devices" [(page)]="page" [limit]="limit" (search)="searchText = $event" (limitChanged)="limitDevices($event)" (prev)="loadItems($event)" (next)="loadItems($event)" [total]="totalPages" (pageChange)="loadItems($event)">

				<div *ngIf="devices?.length > 0">
					<div class="visible-xs">
						<div *ngFor="let device of devices; let i = index" class="panel panel-default">
							<div class="panel-body">
								<div display-table (click)="deviceTools[device.id] = !deviceTools[device.id]" style="cursor: pointer">
									<div display-table-cell nowrap align-middle class="status">
										<span class="fa-stack fa-lg fa-fw" [ngClass]="{fadeIn: !device.status.value}">
											<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
											<i *ngIf="device.status.value !== 0 || (device.status.value === 0 && devicesOffHours[i].limitReached)" class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
											<i *ngIf="device.status.value === 0 && !devicesOffHours[i].limitReached" class="fa fa-stack-1x fa-inverse fa-question"></i>
										</span>
									</div>
									<div display-table-cell>
										<div class="text-primary">{{ device.id_loc }}</div>
										<div><strong class="text-overflow">{{ device.alias }}</strong></div>
										<div><span class="text-muted">{{ 'ns' | uppercase }} {{ device.ns }}</span></div>
										<div [hidden]="device.alarmsCollapsed">
											<span class="text-danger" *ngFor="let alarm of device.alarms | filter : 'value'">
												<i class="fa" [ngClass]="alertIcons[alarm.value]"></i> {{ alarm.label | translate }}
											</span>
										</div>
									</div>
									<div display-table-cell nowrap class="p-r-9" *ngIf="(device.alarms | filter : 'value').length">
										<button class="btn btn-link btn-danger fa-stack fa-lg" (click)="device.alarmsCollapsed = !device.alarmsCollapsed; $event.stopPropagation()" style="margin-right: 10px">
											<i class="fa fa-exclamation-triangle fa-stack-2x"></i>
											<span class="badge">{{ (device.alarms | filter : 'value').length }}</span>
										</button>
									</div>
								</div>
								<div class="unconnectedWarning text-center" *ngIf="device.status.value === 0 && !devicesOffHours[i].limitReached">
									{{ ('DESCONECTADO_MENOR_1' | translate) + (devicesOffHours[i].hours | number:'1.1-1') + ' ' + ('HORAS' | translate) }}
								</div>
								<div class="text-center" (click)="deviceTools[device.id] = !deviceTools[device.id]">
									<i class="fa" [ngClass]="(!deviceTools[device.id]) ? 'fa-caret-down' : 'fa-caret-up'" aria-hidden="true"></i>
								</div>
								<div *ngIf="device && loggedUser" class="optionsBar" [ngClass]="(deviceTools[device.id]) ? 'opened' : 'closed'">
									<div class="logos">
										<a class="btn" href="javascript:{}" [routerLink]="['/devices/alarms', device.id]" [title]="'ALARMAS' | translate">
											<img src="/assets/images/acciones/alarmas.png">
										</a>
										<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]" [title]="'VISTA_PROFESIONAL' | translate">
											<img src="/assets/images/acciones/vistaPro.png">
										</a>
										<a *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO" class="btn" href="javascript:{}" [routerLink]="['/devices/family',
										device.id]" [title]="'VISTA_NORMAL' | translate">
											<img src="/assets/images/acciones/family.png">
										</a>
										<a *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO || loggedUser.id_role === ('' | roles).PROFESIONAL" class="btn" href="javascript:{}" [routerLink]="['/devices', 'edit',
										device.id]" [title]="'EDITAR' | translate">
											<img src="/assets/images/acciones/editar.png">
										</a>
										<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/inputs/edit', device.id]" [title]="'ENTRADAS' | translate">
											<img src="/assets/images/acciones/entradas.png">
										</a>
										<a *ngIf="device.cardIZ70 && loggedUser.id_role === ('' | roles).PROPIETARIO" class="btn" href="javascript:{}"
												[routerLink]="['/devices/relays/edit', device.id]" [title]="'PERSONALIZACION' | translate">
											<img src="/assets/images/acciones/personalizar.png">
										</a>
										<a [disabled]="blockButtons" class="btn" *ngIf="loggedUser.id_role === 2 && device.activo"
												(click)="selectedDevice = device; deleteModal.show(); $event.stopPropagation()" title="{{'ELIMINAR_DISPOSITIVO' |
												 translate}}"><img width="17"
												src="/assets/images/acciones/trash.png"></a>

									</div>
								</div>
								<hr *ngIf="device.isPH || device.isORP || device.isPPM || device.isTemperature">

								<div display-table class="text-center">
									<div *ngIf="device.isPH" display-table-cell nowrap>
										<p>{{ 'PH' | translate }}</p>
										<p *ngIf="device.phStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></p>
										<p *ngIf="device.phStatus === 1">
											<strong nowrap>
												<i *ngIf="device.vars.wp != '0' && device.vars.wp != '-'" [ngClass]="alertIcons[device.vars.wp]" class="fa fa-fw text-danger"></i>
												{{  ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached)) ? ((device.vars.mp && device.vars.mp !== '-----') ? (device.vars.mp | number:'1.2') : 0) : '-' }}
											</strong>
										</p>
										<p *ngIf="device.vars.sp" class="text-muted">{{ (device.vars.sp !== '' && device.vars.sp !== '----') ? (device.vars.sp | number:'1.2') : '0' }}</p>
									</div>
									<div *ngIf="device.isORP" display-table-cell nowrap>
										<p>{{ 'ORP' | translate }}</p>
										<p *ngIf="device.orpStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></p>
										<p *ngIf="device.orpStatus === 1">
											<strong nowrap>
												<i *ngIf="device.vars.wo != '0' && device.vars.wo != '-'" [ngClass]="alertIcons[device.vars.wo]" class="fa fa-fw text-danger"></i>
												{{ ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached)) ? ((device.vars.mo && device.vars.mo !== '---') ? (device.vars.mo | number:'1.0-0') : 0) : '-' }}
											</strong>
										</p>
										<p *ngIf="device.vars.so" class="text-muted">{{ device.vars.so }}</p>
									</div>
									<div *ngIf="device.isPPM" display-table-cell nowrap>
										<p>{{ 'PPM' | translate }}</p>
										<p *ngIf="device.ppmStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></p>
										<p *ngIf="device.ppmStatus === 1">
											<strong *ngIf="device.vars.wh" nowrap>
												<i *ngIf="device.vars.wh != '0'  && device.vars.wh != '-'" [ngClass]="alertIcons[device.vars.wh]" class="fa fa-fw text-danger"></i>
												{{ (device.vars.wh === 'I' && device.status.value !== 0) ? 'INIT' : ((device.vars.mh && device.vars.mh !== '----') ? (device.vars.mh | number:'1.2') : '-') }}
											</strong>
										</p>
										<p *ngIf="device.vars.sh" class="text-muted">{{ (device.vars.sh && device.vars.sh !== '----') ? (device.vars.sh | number:'1.2') : 0 }}</p>
									</div>
									<div *ngIf="device.isTemperature" display-table-cell nowrap>
										<p>{{ 'TEMPERATURA' | translate }}</p>
										<p *ngIf="!device.status.value"><strong>{{ 'OFF' | translate }}</strong></p>
										<p *ngIf="device.status.value">
											<strong>{{ device.specialStatus.flow ? (device.vars.ta | temperature: loggedUser.unit_temp) : '-' }}</strong>
										</p>
										<span *ngIf="!device.status.value" class="text-muted">{{ 'OFF' | translate }}</span>
										<span *ngIf="device.status.value" class="text-muted">
											{{ device.specialStatus.flow && device.specialStatus.prodUp30 && device.vars.cn !== '-----' ?
												(device.vars.cn.substring(0, 4) | number:'1.2') : '-' }} {{ 'gr/l' }}
										</span>
									</div>
								</div>
								<hr *ngIf="(device.relays | filter : null:'state').length">
								<div display-table class="text-center">
									<ng-container *ngFor="let relay of device.relays">
										<div display-table-cell nowrap *ngIf="relay?.state">
											<img [src]="relay.icon" width="32" height="32">
											<div [ngClass]="'text-' + states[relay.state].class">
												{{ relay.label | translate | uppercase }}
												<small *ngIf="relay.state === 'A'" [ngClass]="device.vars['a' + relay.sign[1]] === '1' ? 'text-success' : 'text-danger'">{{ device.vars['a' + relay.sign[1]] === '1' ? '(On)' : '(Off)' }}</small>
											</div>
										</div>
									</ng-container>

								</div>
							</div>
						</div>
					</div>

					<table *ngIf="devices" class="table table-bordered table-hover hidden-xs">
						<thead>
							<tr>
								<th ordenable order="ns" (change)="changeOrder($event)" style="width: 20%;">{{ "DISPOSITIVO" | translate }}</th>
								<th class="visible-sm" style="width:18%;">{{ 'VALORES' | translate }}</th>
								<th class="hidden-sm" style="width: 5%;">{{ 'PH' | translate }}</th>
								<th class="hidden-sm" style="width: 5%;">{{ 'ORP' | translate }}</th>
								<th class="hidden-sm" style="width: 5%;">{{ 'PPM' | translate }}</th>
								<th class="hidden-sm" style="width: 5%;">{{ 'TEMPERATURA' | translate}} - GR/L</th>
								<th style="width: 5%;">1</th>
								<th style="width: 5%;">2</th>
								<th style="width: 5%;">3</th>
								<th style="width: 5%;">4</th>
								<th style="width: 5%;">5</th>
								<th style="width: 5%;">6</th>
							</tr>
						</thead>
						<tbody>
							<ng-template ngFor let-device [ngForOf]="devices" let-i="index">
								<tr (click)="deviceTools[device.id] = !deviceTools[device.id]" style="cursor: pointer">
									<td class="text-left">
										<div display-table>
											<div display-table-cell nowrap align-middle class="status">
												<span class="fa-stack fa-lg fa-fw" [ngClass]="{fadeIn: !device.status.value}">
													<i class="fa fa-circle fa-stack-2x" [style.color]="device.status.color"></i>
													<i *ngIf="device.status.value !== 0 || (device.status.value === 0 && devicesOffHours[i].limitReached)" class="fa fa-stack-1x fa-inverse" [ngClass]="'fa-' + device.status.icon"></i>
													<i *ngIf="device.status.value === 0 && !devicesOffHours[i].limitReached" class="fa fa-stack-1x fa-inverse fa-question"></i>
												</span>
											</div>
											<div display-table-cell>
												<div class="text-primary">{{ device.id_loc }}</div>
												<div><strong class="text-overflow">{{ device.alias }}</strong></div>
												<div><span class="text-muted">{{ 'ns' | uppercase }} {{ device.ns }}</span></div>
												<div [hidden]="device.alarmsCollapsed">
													<span class="text-danger" *ngFor="let alarm of device.alarms | filter : 'value'">
														<i class="fa" [ngClass]="alertIcons[alarm.value]"></i> {{ alarm.label | translate }}
													</span>
												</div>
											</div>
											<div display-table-cell nowrap *ngIf="(device.alarms | filter : 'value').length">
												<button class="btn btn-link btn-danger fa-stack fa-lg" (click)="device.alarmsCollapsed = !device.alarmsCollapsed; $event.stopPropagation()" style="margin-right: 10px">
													<i class="fa fa-exclamation-triangle fa-stack-2x"></i>
													<span class="badge">{{ (device.alarms | filter : 'value').length }}</span>
												</button>
											</div>
										</div>
										<div class="unconnectedWarning text-center" *ngIf="device.status.value === 0 && !devicesOffHours[i].limitReached">
											{{ ('DESCONECTADO_MENOR_1' | translate) + (devicesOffHours[i].hours | number:'1.1-1') + ' ' + ('HORAS' | translate) }}
										</div>
									</td>

									<td class="visible-sm">
										<div *ngIf="device.isPH" align-middle nowrap>
											<p>
												{{ 'PH' | translate }}:
												<span *ngIf="device.phStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></span>
												<span *ngIf="device.phStatus === 1">
													<strong nowrap>
														<i *ngIf="device.vars.wp != '0' && device.vars.wp != '-'" [ngClass]="alertIcons[device.vars.wp]" class="fa fa-fw text-danger"></i>
														{{ (device.vars.mp && device.vars.mp !== '-----') ? (((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached)) ? (device.vars.mp | number:'1.2') : '-') : 0 }}
													</strong>
												</span>
												<span *ngIf="device.vars.sp" class="text-muted"> - <small>{{ (device.vars.sp !== '' && device.vars.sp !== '----') ? (device.vars.sp | number:'1.2') : 0 }}</small></span>
											</p>
										</div>
										<div *ngIf="device.isORP" align-middle nowrap>
											<p>
												{{ 'ORP' | translate }}:
												<span *ngIf="device.orpStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></span>
												<span *ngIf="device.orpStatus === 1">
													<strong nowrap>
														<i *ngIf="device.vars.wo != '0' && device.vars.wo != '-'" [ngClass]="alertIcons[device.vars.wo]" class="fa fa-fw text-danger"></i>
														{{ (device.vars.mo && device.vars.mo !== '---') ? (((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached)) ? (device.vars.mo | number:'1.0-0') : '-') : 0 }}
													</strong>
												</span>
												<span *ngIf="device.vars.so" class="text-muted"> - <small>{{ (device.vars.so) ? device.vars.so : 0 }}</small></span>
											</p>
										</div>
										<div *ngIf="device.isPPM" align-middle nowrap>
											<p>
												{{ 'PPM' | translate }}:
												<span *ngIf="device.ppmStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></span>
												<span *ngIf="device.ppmStatus === 1">
													<strong *ngIf="device.vars.wh" nowrap>
														<i *ngIf="device.vars.wh != '0' && device.vars.wo != '-'" [ngClass]="alertIcons[device.vars.wh]" class="fa fa-fw text-danger"></i>
														{{ (device.vars.wh === 'I' && device.status.value !== 0) ? 'INIT' : ((device.vars.mh && device.vars.mh !== '----') ? ((((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached))) ? (device.vars.mh | number:'1.2') : '-') : 0) }}
													</strong>
												</span>
												<span *ngIf="device.vars.sh" class="text-muted"> - <small>{{ (device.vars.sh && device.vars.sh !== '----') ? (device.vars.sh | number:'1.2') : 0 }}</small></span>
											</p>
										</div>
										<div *ngIf="device.isTemperature" align-middle nowrap>
											<p>
												{{ 'TEMPERATURA' | translate }}:
												<span *ngIf="!device.status.value"><strong>{{ 'OFF' | translate }}</strong></span>
												<span *ngIf="device.status.value">
													<strong>{{  device.specialStatus.flow ? (device.vars.ta | temperature: loggedUser.unit_temp) : '-' }}</strong>
												</span>
											</p>
											<p>
												{{ 'CONDUCTIVIDAD' | translate }}:
												<span *ngIf="!device.status.value"><strong>{{ 'OFF' | translate }}</strong></span>
												<span *ngIf="device.status.value">
													<strong>{{  device.specialStatus.flow && device.specialStatus.prodUp30 && device.vars.cn !== '-----' ?
															(device.vars.cn.substring(0, 4) | number:'1.2') : '-' }} {{'gr/l'}}</strong>
												</span>
											</p>
										</div>
									</td>

									<td class="hidden-sm">
										<div *ngIf="device.isPH" class="text-center">
											<p *ngIf="device.phStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></p>
											<p *ngIf="device.phStatus === 1">
												<strong nowrap>
													<i *ngIf="device.vars.wp != '0' && device.vars.wp != '-'" [ngClass]="alertIcons[device.vars.wp]" class="fa fa-fw text-danger"></i>
													{{ (device.vars.mp && device.vars.mp !== '-----') ? (((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached)) ? (device.vars.mp | number:'1.2') : '-') : 0 }}
												</strong>
											</p>
											<p *ngIf="device.phStatus === 1" class="text-muted">{{ (device.vars.sp !== '' && device.vars.sp !== '----') ? (device.vars.sp | number:'1.2') : '' }}</p>
										</div>
									</td>

									<td class="hidden-sm">
										<div *ngIf="device.isORP" class="text-center">
											<p *ngIf="device.orpStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></p>
											<p *ngIf="device.orpStatus === 1">
												<strong nowrap>
													<i *ngIf="device.vars.wo != '0' && device.vars.wo != '-'" [ngClass]="alertIcons[device.vars.wo]" class="fa fa-fw text-danger"></i>
													{{ (device.vars.mo) ? (((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached))  && device.vars.mo !== '---' ? (device.vars.mo | number:'1.0-0') : '-') : '' }}
												</strong>
											</p>
											<p *ngIf="device.orpStatus === 1" class="text-muted">{{ (device.vars.so !== ''  && device.vars.so !== '---') ? (device.vars.so | number: '1.0-0') : '-' }}</p>
										</div>
									</td>

									<td class="hidden-sm">
										<div *ngIf="device.isPPM" class="text-center">
											<p *ngIf="device.ppmStatus !== 1"><strong>{{ 'OFF' | translate }}</strong></p>
											<div *ngIf="device.ppmStatus === 1">
												<p>
													<strong *ngIf="device.vars.wh" nowrap>
														<i *ngIf="device.vars.wh != '0' && device.vars.wh != '-'" [ngClass]="alertIcons[device.vars.wh]" class="fa fa-fw text-danger"></i>
														{{ (device.vars.wh === 'I' && device.status.value !== 0) ? 'INIT' : ((device.vars.mh && device.vars.mh !== '----') ? (((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached)) ? (device.vars.mh | number:'1.2') : '-') : 0) }}
													</strong>
												</p>
												<p class="text-muted">{{ (device.vars.sh !== '' && device.vars.sh !== '----') ? (device.vars.sh | number:'1.2') : '' }}</p>
											</div>
										</div>
									</td>

									<td class="hidden-sm">
										<div *ngIf="device.isTemperature" class="text-center">
											<p *ngIf="!device.status.value"><strong>{{ 'OFF' | translate }}</strong></p>
											<p *ngIf="device.status.value">
												<strong>{{ ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 && !devicesOffHours[i].limitReached)) ? (device.vars.ta | temperature: loggedUser.unit_temp) : '-' }}</strong>
											</p>
											<p *ngIf="!device.status.value" class="text-center text-muted">{{ 'OFF' | translate }}</p>
											<p *ngIf="device.status.value" class="text-center text-muted">
												{{ ((device.status.value !== 0 && device.specialStatus.flow) || (device.status.value === 0 &&
													!devicesOffHours[i].limitReached)) && device.specialStatus.prodUp30 && device.vars.cn !== '-----' ?
													(device.vars.cn.substring(0, 4) | number:'1.2') : '-' }} {{ 'gr/l' }}
											</p>
										</div>
									</td>

									<td *ngFor="let relay of device.relays" class="text-center">
										<div *ngIf="relay?.state">
											<img [src]="relay.icon" width="32" height="32">
											<div [ngClass]="'text-' + states[relay.state].class">
												{{ relay.label | translate | uppercase }}
												<small *ngIf="relay.state === 'A'" [ngClass]="device.vars['a' + relay.sign[1]] == 1 ? 'text-success' : 'text-danger'">{{ device.vars['a' + relay.sign[1]] == 1 ? '(On)' : '(Off)' }}</small>
											</div>
										</div>
									</td>
								</tr>

								<tr *ngIf="deviceTools[device.id]" class="dark">
									<td colspan="12" class="logos">
										<a class="btn" href="javascript:{}" [routerLink]="['/devices/alarms', device.id]" [title]="'ALARMAS' | translate">
											<img src="/assets/images/acciones/alarmas.png">
										</a>
										<a class="btn" href="javascript:{}" [routerLink]="['/devices', 'view', device.id]" [title]="'VISTA_PROFESIONAL' | translate">
											<img src="/assets/images/acciones/vistaPro.png">
										</a>
										<a *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO" class="btn" href="javascript:{}"
												[routerLink]="['/devices/family', device.id]" [title]="'VISTA_NORMAL' | translate">
											<img src="/assets/images/acciones/family.png">
										</a>
										<a *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO || loggedUser.id_role === ('' | roles).PROFESIONAL" class="btn" href="javascript:{}" [routerLink]="['/devices',
										'edit', device.id]" [title]="'EDITAR' | translate">
											<img src="/assets/images/acciones/editar.png">
										</a>
										<a *ngIf="device.cardIZ70" class="btn" href="javascript:{}" [routerLink]="['/devices/inputs/edit', device.id]" [title]="'ENTRADAS' | translate">
											<img src="/assets/images/acciones/entradas.png">
										</a>
										<a *ngIf="device.cardIZ70 && loggedUser.id_role === ('' | roles).PROPIETARIO" class="btn" href="javascript:{}"
												[routerLink]="['/devices/relays/edit', device.id]" [title]="'PERSONALIZACION' | translate">
											<img src="/assets/images/acciones/personalizar.png">
										</a>
									<span *ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO" class="actions">
										<a [disabled]="blockButtons" class="btn" *ngIf="device.activo" (click)="selectedDevice = device; deleteModal.show();
										$event.stopPropagation()" title="{{'ELIMINAR_DISPOSITIVO' | translate}}"><img width="17"
												src="/assets/images/acciones/trash.png"></a>
									</span>
									</td>

								</tr>
							</ng-template>
						</tbody>
					</table>
				</div>
				<div *ngIf="devices?.length === 0" class="text-center">
					<div class="alert alert-info"
							*ngIf="loggedUser.id_role === ('' | roles).PROFESIONAL"><strong>{{ 'NO_DISPOSITIVOS_ASOCIADOS' | translate }}</strong></div>
					<div class="alert alert-info"
							*ngIf="loggedUser.id_role === ('' | roles).PROPIETARIO"><strong>{{ 'NO_ASOCIADOS' | translate }}</strong></div>
				</div>
			</table-sorter>
		</div>
	</div>

	<div bsModal #deleteDev="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteDev" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">

				<div class="modal-header">
					<button class="close" (click)="deleteModal.hide()" aria-hidden="true">&times;</button>
					<h4 class="modal-title">{{ 'ELIMINAR_DISPOSITIVO' | translate }}</h4>
				</div>

				<div class="modal-body">
					<p class="modal-title">{{ 'PREGUNTA_ELIMINAR_DISPOSITIVO' | translate }}</p>
				</div>

				<div class="modal-footer">
					<button class="btn btn-danger" (click)="disassociate()" aria-disabled="false">{{ 'BT_ELIMINAR' | translate }}</button>
					<button class="btn btn-primary" (click)="deleteModal.hide()" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
				</div>

			</div>
		</div>
	</div>

	<div bsModal #linkDev="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="linkDev" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">

				<div class="modal-header">
					<button (click)="linkModal.hide()" class="close" aria-hidden="true">&times;</button>
					<h4 class="modal-title">{{ 'ASOCIARME_DISPOSITIVO' | translate }}</h4>
				</div>

				<div class="modal-body">
					<p class="modal-title">{{ 'CONFIRMAR_ASOCIARME_DISPOSITIVO' | translate }} {{ selectedDevice.ns }}?</p>
				</div>

				<div class="modal-footer">
					<button class="btn btn-primary" (click)="linkDevice()" role="button" aria-disabled="false">{{ 'ASOCIAR_DISPOSITIVO' | translate }}</button>
					<button (click)="linkModal.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
				</div>

			</div>
		</div>
	</div>

</main>
