import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http';
 */
import { HttpClient} from '@angular/common/http';

import { BaseService } from './base.service';

// services
import { AuthService } from './auth.service';

// utils
import { AppConfig } from '../AppConfig';

@Injectable()
export class ImportService extends BaseService {
	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public import(email: string, force: boolean) {
		let body = `mail=${email}&force=`;

		body += (force) ? '1' : '0';

		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/import/importDataUser', body, { headers: this.headers } ).subscribe((result) => {
				resolve(result/* .json() */);
			}, (error) => {
				reject(error/*.json()*/);
			});
		});
	}
}
