<div *ngIf="totalCompatibilities">
	<span *ngIf="totalCompatibilities" class="badge badge-info">{{ totalCompatibilities }}</span>
	<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
</div>

<p class="text-right" *ngIf="compatibilities">
	<button class="btn btn-primary" (click)="createCompatibility()">{{ 'ADD_COMPA' | translate }}</button>
</p>

<table-sorter [disableBtn]="disableButtons" [loading]="!compatibilities" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limit = $event; loadItems()" (prev)="page = $event; loadItems($event);" (search)="searchText = $event" (next)="page = $event; loadItems($event)" (pageChange)="loadItems($event)">
	<div *ngIf="compatibilities" class="visible-xs">

		<div *ngIf="modelsFilterValue && pbas1FilterValue && pbas2FilterValue">
			<div>
				<label>{{ 'MODELO' | translate }}</label>
				<ss-multiselect-dropdown [settings]="modelsFilterSettings" [texts]="modelsFilterTexts" [options]="modelsFilterOptions" [(ngModel)]="modelsFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'PBA1' | translate }}</label>
				<ss-multiselect-dropdown [settings]="pbas1FilterSettings" [texts]="pbas1FilterTexts" [options]="pbas1FilterOptions" [(ngModel)]="pbas1FilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'PBA2' | translate }}</label>
				<ss-multiselect-dropdown [settings]="pbas2FilterSettings" [texts]="pbas2FilterTexts" [options]="pbas2FilterOptions" [(ngModel)]="pbas2FilterValue" (ngModelChange)="filterChanged(3)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'COMPATIBLES' | translate }}</label>
				<ss-multiselect-dropdown [settings]="validFilterSettings" [texts]="validFilterTexts" [options]="validFilterOptions" [(ngModel)]="validFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
			</div>
			<hr>
		</div>

		<div *ngFor="let compatibility of compatibilities; let i = index" class="panel panel-default">
			<div class="panel-heading text-center">
				<strong>{{ 'MODELO' | translate }}: </strong>{{ compatibility.model.name }}
			</div>
			<div class="panel-body">
				<div>
					<strong>{{ 'COMPATIBLES' | translate }}: <span [ngClass]="{'text-danger' : !compatibility.valid, 'text-success' : compatibility.valid}">{{ (compatibility.valid ? 'SI' : 'NO') | translate }}</span></strong>
				</div>
				<hr>
				<div>
					<strong>{{ 'PBA1' | translate }}: </strong><span class="text-primary">{{ compatibility.pba1.name }}</span>
				</div>
				<div>
					<strong>{{ 'HARDWARE' | translate }}: </strong>{{ compatibility.hw1?.name }}
				</div>
				<span *ngIf="compatibility.valid">
					<strong>{{ 'FIRMWARE' | translate }}: </strong>{{ (compatibility.valid) ? compatibility.fw1?.name : '-' }}					
				</span>
				<div *ngIf="compatibility.valid">
					<strong>{{ 'IMAGEN_BACK' | translate }}: </strong>{{ compatibility.bck1?.name }}
				</div>
				<hr>
				<div>
					<strong>{{ 'PBA2' | translate }}: </strong><span class="text-primary">{{ compatibility.pba2.name }}</span>
				</div>
				<div>
					<strong>{{ 'HARDWARE' | translate }}: </strong>{{ compatibility.hw2?.name }}
				</div>
				<span *ngIf="compatibility.valid">
					<strong>{{ 'FIRMWARE' | translate }}: </strong>{{ (compatibility.valid) ? compatibility.fw1?.name : '-' }}					
				</span>
				<div *ngIf="compatibility.valid">
					<strong>{{ 'IMAGEN_BACK' | translate }}: </strong>{{ compatibility.bck2?.name }}
				</div>
				<hr *ngIf="compatibility.observations">
				<div *ngIf="compatibility.observations">
					<div><strong>{{ 'NOTAS' | translate }}</strong></div>
					<div>{{ compatibility.observations }}</div>
				</div>
				<hr>
				<div class="text-center">
					<button [disabled]="disableButtons" class="btn btn-primary btn-link" (click)="edit(compatibility)">
						{{ 'EDITAR' | translate }}
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger btn-link" (click)="tempCompatibility = compatibility; deleteCompatibility.show()">
						{{ 'BORRAR' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<table *ngIf="compatibilities" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
		<thead>
			<tr>
				<th>{{ 'MODELO' | translate }}</th>
				<th>{{ 'PBA1' | translate }}</th>
				<th>{{ 'PBA2' | translate }}</th>
				<th>{{ 'HARDWARE' | translate }}/{{ 'FIRMWARE' | translate }} {{ 'PBA1' | translate }}</th>
				<th>{{ 'HARDWARE' | translate }}/{{ 'FIRMWARE' | translate }} {{ 'PBA2' | translate }}</th>
				<th>{{ 'COMPATIBLES' | translate }}</th>
				<th>{{ 'NOTAS' | translate }}</th>
				<th>{{ 'ACCIONES' | translate }}</th>
			</tr>
		</thead>
		<tbody>

			<tr *ngIf="modelsFilterValue && pbas1FilterValue && pbas2FilterValue">
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="modelsFilterSettings" [texts]="modelsFilterTexts" [options]="modelsFilterOptions" [(ngModel)]="modelsFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="pbas1FilterSettings" [texts]="pbas1FilterTexts" [options]="pbas1FilterOptions" [(ngModel)]="pbas1FilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="pbas2FilterSettings" [texts]="pbas2FilterTexts" [options]="pbas2FilterOptions" [(ngModel)]="pbas2FilterValue" (ngModelChange)="filterChanged(3)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td></td>
				<td></td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="validFilterSettings" [texts]="validFilterTexts" [options]="validFilterOptions" [(ngModel)]="validFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td></td>
				<td></td>
			</tr>

			<tr *ngFor="let compatibility of compatibilities; let i = index" class="text-center">
				<td>
					<strong>{{ compatibility.model.name }}</strong>
				</td>
				<td>
					<div class="text-primary">{{ compatibility.pba1.name }}</div>
				</td>
				<td>
					<div class="text-primary">{{ compatibility.pba2.name }}</div>
				</td>
				<td>
					<div *ngIf="compatibility.valid">
						<div><strong>{{ 'HARDWARE' | translate }}:</strong> {{ compatibility.hw1?.name }}</div>
						<div><strong>{{ 'FIRMWARE' | translate }}:</strong> {{ compatibility.fw1?.name }}</div>						
						<div><strong>{{ 'IMAGEN_BACK' | translate }}:</strong> {{ compatibility.bck1?.name }}</div>
					</div>
				</td>
				<td>
					<div *ngIf="compatibility.valid">
						<div><strong>{{ 'HARDWARE' | translate }}:</strong> {{ compatibility.hw2?.name }}</div>
						<div><strong>{{ 'FIRMWARE' | translate }}:</strong> {{ compatibility.fw2?.name }}</div>						
						<div><strong>{{ 'IMAGEN_BACK' | translate }}:</strong> {{ compatibility.bck2?.name }}</div>
					</div>
				</td>
				<td [ngClass]="{'text-danger' : !compatibility.valid, 'text-success' : compatibility.valid}">
					<strong>{{ (compatibility.valid ? 'SI' : 'NO') | translate }}</strong>
				</td>
				<td>
					{{ compatibility.observations }}
				</td>
				<td class="actions">
					<button [disabled]="disableButtons" class="btn" (click)="edit(compatibility)">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger" (click)="tempCompatibility = compatibility; deleteCompatibility.show()">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</table-sorter>

<div bsModal #editCompatibility="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editCompatibility" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="editCompatibility.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'NUEVA_COMP' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div *ngIf="models && pbas">
					<div class="form-group">
						<label>{{ 'MODELO' | translate }}</label>
						<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.id_model" class="form-control">
							<option *ngFor="let model of models" [value]="model.id">{{ model.name }}</option>
						</select>
					</div>

					<fieldset>
						<legend>{{ 'PBA1' | translate }}</legend>
						<div class="row">
							<div class="col-sm-6">
								<label>{{ 'NOMBRE' | translate }} <small *ngIf="pba1Loading" class="text-primary fadeIn">{{ 'LOADING' | translate }}</small></label>
								<select [disabled]="disableButtons || !tempCompatibility.id_model || pba1Loading" [(ngModel)]="tempCompatibility.indexPba1" class="form-control" (change)="getPba1Data(pbas[tempCompatibility.indexPba1].id)">
									<option *ngFor="let pba of pbas; let i = index" [value]="i">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
								</select>
							</div>
							<div class="col-sm-6" *ngIf="tempCompatibility.indexPba1 && hwPba1 && fwPba1">
								<label>{{ 'HARDWARE' | translate }}</label>
								<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.indexHwPba1" class="form-control">
									<option *ngFor="let hw of hwPba1; let i = index" [value]="i">{{ hw.name + ' (' + hw.code + ')' }}</option>
								</select>
							</div>
							<div class="col-sm-6" *ngIf="tempCompatibility.indexPba1 && hwPba1 && fwPba1">
								<label>{{ 'FIRMWARE' | translate }}</label>
								<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.indexFwPba1" class="form-control">
									<option *ngFor="let fw of fwPba1; let i = index" [value]="i">{{ fw.name + ' (' + fw.code + ')' }}</option>
								</select>
							</div>
							<div class="col-sm-6" *ngIf="tempCompatibility.indexPba1 && hwPba1 && fwPba1">
								<label>{{ 'IMAGEN_BACK' | translate }}</label>
								<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.indexBckPba1" class="form-control">
									<option *ngFor="let fw of fwPba1; let i = index" [value]="i">{{ fw.name + ' (' + fw.code + ')' }}</option>
								</select>
							</div>
						</div>
					</fieldset>
					<fieldset>
						<legend>{{ 'PBA2' | translate }}</legend>
						<div class="row">
							<div class="col-sm-6">
								<label>{{ 'NOMBRE' | translate }} <small *ngIf="pba2Loading" class="text-primary fadeIn">{{ 'LOADING' | translate }}</small></label>
								<select [disabled]="disableButtons || !tempCompatibility.id_model || pba2Loading" [(ngModel)]="tempCompatibility.indexPba2" class="form-control" (change)="getPba2Data(pbas[tempCompatibility.indexPba2].id)">
									<option *ngFor="let pba of pbas; let i = index" [value]="i">{{ pba.name + ((pba.observations) ? ' - ' + pba.observations : '') }}</option>
								</select>
							</div>
							<div class="col-sm-6" *ngIf="tempCompatibility.indexPba2 && hwPba2 && fwPba2">
								<label>{{ 'HARDWARE' | translate }}</label>
								<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.indexHwPba2" class="form-control">
									<option *ngFor="let hw of hwPba2; let i = index" [value]="i">{{ hw.name + ' (' + hw.code + ')' }}</option>
								</select>
							</div>
							<div class="col-sm-6" *ngIf="tempCompatibility.indexPba2 && hwPba2 && fwPba2">
								<label>{{ 'FIRMWARE' | translate }}</label>
								<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.indexFwPba2" class="form-control">
									<option *ngFor="let fw of fwPba2; let i = index" [value]="i">{{ fw.name + ' (' + fw.code + ')' }}</option>
								</select>
							</div>
							<div class="col-sm-6" *ngIf="tempCompatibility.indexPba2 && hwPba2 && fwPba2">
								<label>{{ 'IMAGEN_BACK' | translate }}</label>
								<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.indexBckPba2" class="form-control">
									<option *ngFor="let fw of fwPba2; let i = index" [value]="i">{{ fw.name + ' (' + fw.code + ')' }}</option>
								</select>
							</div>
						</div>
					</fieldset>
					<fieldset>
						<legend>{{ 'COMPATIBLES' | translate }}</legend>
						<select [disabled]="disableButtons" [(ngModel)]="tempCompatibility.valid" class="form-control">
							<option value="1">{{ 'SI' | translate }}</option>
							<option value="0">{{ 'NO' | translate }}</option>
						</select>
					</fieldset>
					<div>
						<label>{{ 'NOTAS' | translate }}</label>
						<textarea [disabled]="disableButtons" class="form-control" [(ngModel)]="tempCompatibility.observations"></textarea>
					</div>
				</div>
				<div *ngIf="!(models && pbas)" class="text-center">
					<img src="/assets/images/wave.svg">
				</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons || !(models && pbas && hwPba1 && fwPba1 && hwPba2 && fwPba2)" class="btn btn-primary" (click)="save()" role="button" aria-disabled="false">{{ 'BT_GUARDAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="editCompatibility.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>

<div bsModal #deleteCompatibility="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteCompatibility" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="deleteCompatibility.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'BORRAR' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div>{{ 'COMPATIBILIDAD' | translate }}: {{ tempCompatibility.model?.name }}</div>
				<div *ngIf="tempCompatibility.observations">{{ tempCompatibility.observations }}</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-danger" (click)="delete()" role="button" aria-disabled="false">{{ 'BORRAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="deleteCompatibility.hide()" class="btn btn-primary" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>
