import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevicesService } from '../../services/devices.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// services
import { AuthService } from '../../services/auth.service';

// utils
import * as _ from 'lodash';

@Component({
	selector: 'inputs',
	templateUrl: 'inputs.component.html',
})

export class InputsComponent implements OnInit {
	public device: any;
	public previewA1 = '';
	public previewA2 = '';
	public offsetA1 = '';
	public offsetA2 = '';
	public loggedUser: any;
	private id: number;

	constructor(
		public authSvc: AuthService,
		private route: ActivatedRoute,
		private _devicesSvc: DevicesService,
		private _notificationSvc: NotificationsService,
		private _translate: TranslateService) {
		this.loggedUser = this.authSvc.user;
	}

	public ngOnInit() {
		this.route.params.subscribe((params) => {
			this.id = params['id'];

			this._devicesSvc.get(this.id).then((device) => {
				this.device = device;
				this.calibrate('', 'a1');
				this.calibrate('', 'a2');
				for (let i = 1; i <= 4; i++) {
					if(this.device["d" + i + "_alarma"] == undefined) {
						this.device["d" + i + "_alarma"] = 0;
					}
				}

				if (this.device.status.value !== 0 && this.device.vars.ep !== '004' && this.device.vars.ep !== '005') {
					this._devicesSvc.changeMode(this.id, 'fast').catch((error: any) => {
						console.log(error.message);
					});
				}
				
			}).catch((error) => {
				console.log(error.message);
				this._devicesSvc.get(this.id).then((device) => {
					this.device = device;
					this.calibrate('', 'a1');
					this.calibrate('', 'a2');
					for (let i = 1; i <= 4; i++) {
						if(this.device["d" + i + "_alarma"] == undefined) {
							this.device["d" + i + "_alarma"] = 0;
						}
					}

					if (this.device.status.value !== 0 && this.device.vars.ep !== '004' && this.device.vars.ep !== '005') {
						this._devicesSvc.changeMode(this.id, 'fast').catch((e: any) => {
							console.log(e.message);
						});
					}
				}).catch((err) => {
					console.log(err.message);
				});
			});
		});
	}

	public save() {
		if (!this.loggedUser.isDemo) {
			let check: any;
			let inputs: any;

			if (this.device.isDS2) {
				inputs = _.pick(this.device, ['id', 'd1_name', 'd2_name', 'd3_name', 'd4_name', 'd1_alarma', 'd2_alarma', 'd3_alarma', 'd4_alarma']);
				check = { passed: true, message: '' };
			} else {
				inputs = _.pick(this.device, ['id', 'a1_cal', 'a1_name', 'a1_unidad', 'a1_min', 'a1_max', 'a2_cal', 'a2_name', 'a2_unidad', 'a2_min', 'a2_max', 'd1_name', 'd2_name', 'd3_name', 'd4_name', 'd1_alarma', 'd2_alarma', 'd3_alarma', 'd4_alarma']);
				check = this._checkAnalogInputs();
			}

			if (!check.passed) {
				this._notificationSvc.error('', this._translate.instant(check.message));
			} else {
				this._devicesSvc.save(inputs).then((result: any) => {
					this._notificationSvc.success('', this._translate.instant(result.message));
				}).catch((error: any) => {
					if (error.message) {
						this._notificationSvc.error('', this._translate.instant(error.message));
					}
				});
			}
		} else {
			this._notificationSvc.info('', this._translate.instant('DEMO'));
		}
	}

	public reset(input: string) {
		switch (input) {
			case 'a1':
				this.device.a1_cal = 0;
				this.offsetA1 = '';
				this.previewA1 = '';
				break;
			case 'a2':
				this.device.a2_cal = 0;
				this.offsetA2 = '';
				this.previewA2 = '';
				break;
			default: break;
		}

		this.calibrate('', input);
	}

	public refresh() {
		this._devicesSvc.get(this.id).then((device) => {
			this.device = device;
			this.calibrate('', 'a1');
			this.calibrate('', 'a2');
		});
	}

	public calibrate(operation, input) {
		let digitalValue;
		let max;
		let min;
		let cont;
		let calibrate = 0;
		let calibrateValue = 0;
		let plus = '';
		let maValue: any;
		let decodedValue: any;

		switch (input) {
			case 'a1':
				cont = parseInt(this.device.a1_cal, 10); // device.a1_cal
				max = parseFloat(this.device.a1_max); // device.a1_max
				min = parseFloat(this.device.a1_min); // device.a1_min
				digitalValue = parseInt(this.device.vars.a1, 10); // device.vars.a1
				break;
			case 'a2':
				cont = parseInt(this.device.a2_cal, 10); // device.a2_cal
				max = parseFloat(this.device.a2_max); // device.a2_max
				min = parseFloat(this.device.a2_min); // device.a2_min
				digitalValue = parseInt(this.device.vars.a2, 10); // device.vars.a2
				break;
			default: break;
		}

		maValue = this._decodeCalibrateInput(digitalValue, 4, 20, 0);
		decodedValue = this._decodeCalibrateInput(digitalValue, min, max, 0);

		// Sumamos o restamos 1 al contador
		switch (operation) {
			case 'more':
				cont++;
				break;
			case 'minus':
				cont--;
				break;
			default: break;
		}

		// Comprobamos si tenemos datos
		if ((digitalValue < 2) || isNaN(digitalValue) || isNaN(min) || isNaN(max)) {
			// Mostramos N/A para indicar que no podemos calcular la medida
			switch (input) {
				case 'a1':
					this.previewA1 = 'N/A';
					this.offsetA1 = 'N/A';
					break;
				case 'a2':
					this.previewA2 = 'N/A';
					this.offsetA2 = 'N/A';
					break;
				default: break;
			}
		} else {
			// Calculamos valor del sensor con el contador de calibrate
			calibrateValue = this._decodeCalibrateInput(digitalValue, min, max, cont);
			// Calculamos calibrate (cantidad que se suma o resta al valor medido por el sensor)
			// calibrate = (calibrateValue - decodedValue).toFixed(2);
			calibrate = parseFloat((calibrateValue - decodedValue).toFixed(2));

			if (calibrate > 0) {
				plus = '+';
			}

			switch (input) {
				case 'a1':
					// Guardamos valor del contador
					this.device.a1_cal = cont; // device.a1_cal
					// Guardamos resultado del calibrate
					this.previewA1 = calibrateValue + ' ' + this.device.a1_unidad + ' @ ' + maValue + ' mA'; // preview
					// Guardamos calibrate
					//this.offsetA2 = calibrate + ' ' + this.device.a1_unidad;
					this.offsetA1 = plus + calibrate + ' ' + this.device.a1_unidad; // offset
					break;
				case 'a2':
					// Guardamos valor del contador
					this.device.a2_cal = cont; // device.a1_cal
					// Guardamos resultado del calibrate
					this.previewA2 = calibrateValue + ' ' + this.device.a2_unidad + ' @ ' + maValue + ' mA'; // preview
					// Guardamos calibrate
					//this.offsetA2 = calibrate + ' ' + this.device.a2_unidad;
					this.offsetA2 = plus + calibrate + ' ' + this.device.a2_unidad; // offset
					break;
				default:
					break;
			}
		}
	}

	private _decodeCalibrateInput(digitalData, min, max, calibrate) {
		let value;

		// Validamos que los datos sean válidos para la función
		if (isNaN(digitalData) || isNaN(min) || isNaN(max) || isNaN(calibrate)) {
			value = 0;
		} else {
			let current = 0.0344 * (digitalData + calibrate) + 2.64;
			let m = (max - min) / 16;
			let b = max - (20 * m);

			value = (current * m + b).toFixed(2);
		}
		return value;
	}

	private _checkAnalogInputs(): Object {
		let result: any = {
			passed: true,
			message: ''
		};
		let analogInputs = _.pick(this.device, [
			'a1_name',
			'a1_min',
			'a1_max',
			'a2_name',
			'a2_min',
			'a2_max'
		]);

		analogInputs.a1_min = parseInt(analogInputs.a1_min, 10);
		analogInputs.a1_max = parseInt(analogInputs.a1_max, 10);
		analogInputs.a2_min = parseInt(analogInputs.a2_min, 10);
		analogInputs.a2_max = parseInt(analogInputs.a2_max, 10);

		for (const property in analogInputs) {
			if (isNaN(analogInputs[property]) && !(property.indexOf('_name') > -1)) {
				result.passed = false;
				result.message = 'ERROR_INTRODUCIR_MAXIMO_MINIMO';
				break;
			}
		}

		if (result.passed && analogInputs.a1_name === '') {
			result.passed = false;
			result.message = 'NOMBRE_SENYAL_A1';
		}

		if (result.passed && analogInputs.a2_name === '') {
			result.passed = false;
			result.message = 'NOMBRE_SENYAL_A2';
		}

		if (result.passed && (analogInputs.a1_min > analogInputs.a1_max || analogInputs.a2_min > analogInputs.a2_max)) {
			result.passed = false;
			result.message = 'ERROR_VALOR_MINIMO_MAYOR_MAXIMO';
		}

		return result;
	}
}
