import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toFahrenheit'
})
export class ToFahrenheitPipe implements PipeTransform {
	transform(value: any): any {
		let valueString: string;
		let lastDecimal: number;

		value = value * 1.8 + 32;

		valueString = (Math.round(parseFloat(value.toFixed(2)) * 100)).toString();
		lastDecimal = parseInt(valueString[valueString.length - 1], 10);

		value = parseInt(valueString, 10);

		if (lastDecimal >= 5) {
			value = ((value + 10) - lastDecimal) / 100;
		} else {
			value = (value - lastDecimal) / 100;
		}

		return value.toFixed(1);
	}
}
