<div *ngIf="totalFirmwares">
	<span *ngIf="totalFirmwares" class="badge badge-info">{{ totalFirmwares }}</span>
	<span>{{ 'REGISTROS_ENCONTRADOS' | translate }}</span>
</div>

<p class="text-right" *ngIf="firmwares">
	<button class="btn btn-primary" (click)="createFirmware()">{{ 'ADD_FIRMWARE' | translate }}</button>
</p>

<table-sorter [disableBtn]="disableButtons" [loading]="!firmwares" [(page)]="page" [limit]="limit" [total]="totalPages" (limitChanged)="limit = $event" (prev)="page = $event; loadItems($event);" (search)="searchText = $event" (next)="page = $event; loadItems($event)" (pageChange)="loadItems($event)">
	<div *ngIf="firmwares" class="visible-xs">
		<div *ngIf="pbasFilterOptions && nameFilterOptions">
			<div>
				<label>{{ 'PBA' | translate }}</label>
				<ss-multiselect-dropdown [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'TIPO' | translate }}</label>
				<ss-multiselect-dropdown [settings]="typeFilterSettings" [texts]="typeFilterTexts" [options]="typeFilterOptions" [(ngModel)]="typeFilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'FIRMWARE' | translate }}</label>
				<ss-multiselect-dropdown [settings]="nameFilterSettings" [texts]="nameFilterTexts" [options]="nameFilterOptions" [(ngModel)]="nameFilterValue" (ngModelChange)="filterChanged(3)"></ss-multiselect-dropdown>
			</div>
			<div>
				<label>{{ 'VERSION_VALIDA' | translate }}</label>
				<ss-multiselect-dropdown [settings]="validFilterSettings" [texts]="validFilterTexts" [options]="validFilterOptions" [(ngModel)]="validFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
			</div>
			<hr>
		</div>
		<div *ngFor="let firmware of firmwares; let i = index" class="panel panel-default">
			<div class="panel-body">
				<div>
					<strong>{{ 'PBA' | translate }}: </strong>
					<span *ngIf="pbaNames[firmware.id_pba]">{{ pbaNames[firmware.id_pba] }}</span>
					<span *ngIf="!pbaNames[firmware.id_pba]" class="text-center text-danger">
						{{ 'NO_PBA' | translate }}
					</span>
				</div>
				<div>
					<strong>{{ 'TIPO' | translate }}: </strong>{{ firmware.kind }}
				</div>
				<div>
					<strong>{{ 'FIRMWARE' | translate }}: </strong>{{ firmware.name }}
					<strong>{{ 'CODIGO_ENVIADO' | translate }}: </strong> {{ firmware.code }}
				</div>
				<div>
					<strong>{{ 'VERSION_VALIDA' | translate }}: </strong> {{ (firmware.valid ? 'SI' : 'NO') | translate }}
				</div>
				<hr *ngIf="firmware.observations">
				<div *ngIf="firmware.observations">
					<div><strong>{{ 'NOTAS' | translate }}</strong></div>
					<div>{{ firmware.observations }}</div>
				</div>
				<hr>
				<div class="text-center">
					<button [disabled]="disableButtons" class="btn btn-primary btn-link" (click)="edit(i)">
						{{ 'EDITAR' | translate }}
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger btn-link" (click)="tempFirmware = firmware; deleteFirmware.show()">
						{{ 'BORRAR' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<table *ngIf="firmwares" cellspacing="1" class="table table-bordered table-hover table-responsive hidden-xs">
		<thead>
			<tr>
				<th>{{ 'PBA' | translate }}</th>
				<th>{{ 'TIPO' | translate }}</th>
				<th>{{ 'FIRMWARE' | translate }}</th>
				<th>{{ 'CODIGO' | translate }}</th>
				<th>{{ 'VERSION_VALIDA' | translate }}</th>
				<th>{{ 'NOTAS' | translate }}</th>
				<th>{{ 'ACCIONES' | translate }}</th>
			</tr>
		</thead>
		<tbody>

			<tr *ngIf="pbasFilterOptions && nameFilterOptions">
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="pbasFilterSettings" [texts]="pbasFilterTexts" [options]="pbasFilterOptions" [(ngModel)]="pbasFilterValue" (ngModelChange)="filterChanged(1)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="typeFilterSettings" [texts]="typeFilterTexts" [options]="typeFilterOptions" [(ngModel)]="typeFilterValue" (ngModelChange)="filterChanged(2)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="nameFilterSettings" [texts]="nameFilterTexts" [options]="nameFilterOptions" [(ngModel)]="nameFilterValue" (ngModelChange)="filterChanged(3)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="codeFilterSettings" [texts]="codeFilterTexts" [options]="codeFilterOptions" [(ngModel)]="codeFilterValue" (ngModelChange)="filterChanged(5)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td>
					<div>
						<ss-multiselect-dropdown [settings]="validFilterSettings" [texts]="validFilterTexts" [options]="validFilterOptions" [(ngModel)]="validFilterValue" (ngModelChange)="filterChanged(4)"></ss-multiselect-dropdown>
					</div>
				</td>
				<td></td>
				<td></td>
			</tr>

			<tr *ngFor="let firmware of firmwares; let i = index" class="text-center">
				<td>
					<strong *ngIf="pbaNames[firmware.id_pba]">{{ pbaNames[firmware.id_pba] }}</strong>
					<div *ngIf="!pbaNames[firmware.id_pba]" class="text-center text-danger">
						{{ 'NO_PBA' | translate }}
					</div>
				</td>
				<td>
					{{ firmware.kind }}
				</td>
				<td>
					{{ firmware.name }}
				</td>
				<td>
					{{ firmware.code }}
				</td>
				<td>
					{{ firmware.valid ? 'Si' : 'No' }}
				</td>
				<td>
					{{ firmware.observations }}
				</td>
				<td class="actions">
					<button [disabled]="disableButtons" class="btn" (click)="edit(i)">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
					<button [disabled]="disableButtons" class="btn btn-danger" (click)="tempFirmware = firmware; deleteFirmware.show()">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</table-sorter>

<div bsModal #newFirmware="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="newFirmware" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="newFirmware.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'NUEVO_FIRMWARE' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div *ngIf="pbas">
					<div>
						<label>{{ 'NOMBRE' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempFirmware.name" maxlength="10">
					</div>
					<br>
					<div>
						<label>{{ 'CODIGO' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempFirmware.code" maxlength="3">
					</div>
					<br>
					<div>
						<label>{{ 'TIPO' | translate }}</label>
						<select [disabled]="disableButtons" [(ngModel)]="tempFirmware.kind" class="form-control">
							<option value="APP">APP</option>
							<option value="DATA">DATA</option>
						</select>
					</div>
					<br>
					<div>
						<label>{{ 'PBA' | translate }}</label>
						<select [disabled]="disableButtons" [(ngModel)]="tempFirmware.id_pba" class="form-control">
							<option *ngFor="let pba of pbas" [value]="pba.id">{{ pba.name + ' (' + ((pba.type !== 'OTH') ? pba.type : '-') + ')' }}</option>
						</select>
					</div>
					<br>
					<div>
						<label>{{ 'VERSION_VALIDA' | translate }}: </label>
						<span class="btn-group">
							<label class="radio-inline">
								<input type="radio" name="newFirmValid" [disabled]="disableButtons" [(ngModel)]="tempFirmware.valid" [value]="true"> {{ 'SI' | translate }}
							</label>
							<label class="radio-inline">
								<input type="radio" name="newFirmValid" [disabled]="disableButtons" [(ngModel)]="tempFirmware.valid" [value]="false"> {{ 'NO' | translate }}
							</label>
						</span>
						<div *ngIf="tempFirmware.valid && idcoms[tempFirmware.id_pba]" class="alert alert-danger text-center">
							<i class="fa fa-exclamation fa-fw" aria-hidden="true"></i><strong>Todos</strong> los firmwares de IDCOM se cambiarán a inválidos.
						</div>
					</div>
					<br>
					<div>
						<label>{{ 'NOTAS' | translate }}</label>
						<textarea [disabled]="disableButtons" class="form-control" [(ngModel)]="tempFirmware.observations"></textarea>
					</div>
					<br>
					<div class="form-group" style="overflow: hidden;">
						<label for="file">{{ 'SUBIR_ARCHIVO' | translate }} <small #errorBin style="visibility: hidden">({{ 'INRODUCIR_ARCHIVO_BIN' | translate }})</small></label>
						<input #inputFile accept=".bin" (change)="fileChange($event)" id="firmwareFile" type="file" name="file">
					</div>
				</div>
				<div *ngIf="!pbas" class="text-center">
					<img src="/assets/images/wave.svg">
				</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-primary" (click)="save()" role="button" aria-disabled="false">{{ 'BT_GUARDAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="newFirmware.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>

<div bsModal #editFirmware="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editFirmware" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="editFirmware.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'EDITAR_VERSION_FIRMWARE' | translate }}</h4>
			</div>

			<div class="modal-body">
				<div *ngIf="pbas">
					<div>
						<label>{{ 'NOMBRE' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempFirmware.name" maxlength="10">
					</div>
					<br>
					<div>
						<label>{{ 'CODIGO' | translate }}</label>
						<input type="text" class="form-control" [disabled]="disableButtons" [(ngModel)]="tempFirmware.code" maxlength="3">
					</div>
					<br>
					<div>
						<label>{{ 'TIPO' | translate }}</label>
						<select [disabled]="disableButtons" [(ngModel)]="tempFirmware.kind" class="form-control">
							<option value="APP">APP</option>
							<option value="DATA">DATA</option>
						</select>
					</div>
					<br>
					<div>
						<label>{{ 'PBA' | translate }}</label>
						<select [disabled]="disableButtons" [(ngModel)]="tempFirmware.id_pba" class="form-control">
							<option *ngFor="let pba of pbas" [value]="pba.id">{{ pba.name + ' (' + ((pba.type !== 'OTH') ? pba.type : '-') + ')' }}</option>
						</select>
					</div>
					<br>
					<div>
						<label>{{ 'VERSION_VALIDA' | translate }}: </label>
						<span class="btn-group">
							<label class="radio-inline">
								<input type="radio" name="editFirmValid" [disabled]="disableButtons" [(ngModel)]="tempFirmware.valid" [value]="true"> {{ 'SI' | translate }}
							</label>
							<label class="radio-inline">
								<input type="radio" name="editFirmValid" [disabled]="disableButtons" [(ngModel)]="tempFirmware.valid" [value]="false"> {{ 'NO' | translate }}
							</label>
						</span>
					</div>
					<br>
					<div>
						<label>{{ 'NOTAS' | translate }}</label>
						<textarea [disabled]="disableButtons" class="form-control" [(ngModel)]="tempFirmware.observations"></textarea>
					</div>
				</div>
				<div *ngIf="!pbas" class="text-center">
					<img src="/assets/images/wave.svg">
				</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-primary" (click)="save()" role="button" aria-disabled="false">{{ 'BT_GUARDAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="editFirmware.hide()" class="btn btn-danger" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>

<div bsModal #deleteFirmware="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="deleteFirmware" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">

			<div class="modal-header">
				<button (click)="deleteFirmware.hide()" class="close" aria-hidden="true">&times;</button>
				<h4 class="modal-title">{{ 'BORRAR' | translate }} Firmware</h4>
			</div>

			<div class="modal-body">
				<p class="text-danger">{{ 'ARCHIVO_ELIMINARA' | translate }}</p>
				<div><strong>{{ 'FIRMWARE' | translate }}:</strong> {{ tempFirmware.name }}</div>
				<div *ngIf="tempFirmware.observations">{{ tempFirmware.observations }}</div>
			</div>

			<div class="modal-footer">
				<button [disabled]="disableButtons" class="btn btn-danger" (click)="delete()" role="button" aria-disabled="false">{{ 'BORRAR' | translate }}</button>
				<button [disabled]="disableButtons" (click)="deleteFirmware.hide()" class="btn btn-primary" title="{{ 'DESCARTAR_CAMBIOS' | translate }}">{{ 'BT_CANCELAR' | translate }}</button>
			</div>

		</div>
	</div>
</div>
