<header ps-header>
	<h2>{{ 'SUNRISE' | translate }} - {{ 'SUNSET' | translate }}</h2>
</header>

<main class="container-fluid">
	<p *ngIf="device" class="text-center">
		<button *ngIf="device.isPH" class="btn btn-xs btn-primary" [title]="'PH' | translate" [routerLink]="['/devices/stat', device.id, 'mp']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_ph.png"></i>
		</button>
		<button *ngIf="device.isORP" class="btn btn-xs btn-primary" [title]="'ORP' | translate" [routerLink]="['/devices/stat', device.id, 'mo']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_orp.png"></i>
		</button>
		<button *ngIf="device.isPPM" class="btn btn-xs btn-primary" [title]="'ppm' | translate" [routerLink]="['/devices/stat', device.id, 'mh']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_ppm.png"></i>
		</button>
		<button *ngIf="device.isPanel300 || device.isDS2" class="btn btn-xs btn-primary" [title]="'GRL' | translate" [routerLink]="['/devices/stat', device.id, 'cn']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_conductividad.png"></i>
		</button>
		<button *ngIf="device.isPanel300 || device.isDS2" class="btn btn-xs btn-primary" [title]="'TEMPERATURA' | translate" [routerLink]="['/devices/stat', device.id, 'ta']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_temperatura.png"></i>
		</button>
		<button *ngIf="device.cardIZ70 && device.series !== 2" class="btn btn-xs btn-primary" [title]="device.a1.name" [routerLink]="['/devices/stat', device.id, 'a1']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_analogico.png"></i>
		</button>
		<button *ngIf="device.cardIZ70 && device.series !== 2" class="btn btn-xs btn-primary" [title]="device.a1.name" [routerLink]="['/devices/stat', device.id, 'a2']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_analogico.png"></i>
		</button>
		<button *ngIf="device.electrolysis || device.electrolysisORP || device.electrolysisPPM" class="btn btn-xs btn-primary" [title]="'PRODUCCION' | translate" [routerLink]="['/devices/stat', device.id, 'pro']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_analogico.png"></i>
		</button>
		<button *ngIf="(device.isPH || device.isORP || device.isPPM)" class="btn btn-xs btn-primary" [title]="'VS_CHART' | translate" [routerLink]="['/devices/stat', device.id, 'all']">
			<i class="fa fa-img"><img src="/assets/images/vprofesional/historicos/historico_grafica.png"></i>
		</button>
		<button disabled class="btn btn-xs btn-warning" [routerLink]="['/devices/sun/sunrisesunset', device.id]" title="{{'GRAFICA_DURACION_DEL_DIA' | translate}}">
			<i class="fa fa-img"><img src="/assets/images/suncalc.png"></i>
		</button>
	</p>

	<div class="text-center">
		<div *ngIf="sunChart" [chart]="sunChart"></div>
		<img *ngIf="!sunChart" src="/assets/images/wave.svg">
  	</div>
</main>
