import { Injectable } from '@angular/core';
/* import { Http } from '@angular/http';
 */
import { HttpClient} from '@angular/common/http';

import { BaseService } from './base.service';

// services
import { AuthService } from './auth.service';

// utils
import * as _ from 'lodash';
import { AppConfig } from '../AppConfig';

@Injectable()
export class StatsService extends BaseService {
	constructor(protected _http: HttpClient, protected _authSvc: AuthService) {
		super(_http, _authSvc);
	}

	public getStats() {
		return new Promise((resolve, reject) => {
			this._http.post(AppConfig.HOME_API + '/stats/getStats', null , { headers: this.headers }).subscribe((result) => {
				resolve(result/*.json()*/);
			}, (error) => {
				reject(error);
			});
		});
	}
}
