// utils
import * as _ from 'lodash';
// import * as moment from 'moment';

export class Request {
	private _permisos: string;
	permissions = [];

	set permisos(value: string) {
		this._permisos = value;
		this.permissions = value.split('');
	}

	get permisos(): string {
		this._permisos = _.map(this.permissions, o => +o).join('');

		if (this._permisos.indexOf('2') !== -1 && this._permisos !== '222222') {
			this._permisos = _.replace(this._permisos, /2/g, '1');
		}

		return this._permisos;
	}

	get weeklyProgrammerPermission(): boolean {
		return this._permisos === '222222';
	}

	set weeklyProgrammerPermission(value: boolean) {
		this._permisos = value ? '222222' : '111111';
		this.permissions = this._permisos.split('');
	}

	constructor(raw: any) {
		for (let prop in raw) {
			if (raw.hasOwnProperty(prop)) {
				if (['permisos'].indexOf(prop) !== -1) {
					this['_' + prop] = raw[prop];
				} else {
					this[prop] = raw[prop];
				}
			}
		}

		this.permissions = this._permisos.split('');
	}
}
