<main class="container" id="twofactors">
	<div class="row">
		<div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
            <h2 class="form-signin-heading">
				<img class="center-block img-responsive login-logo" src="../../assets/images/poolstation.png" />
			</h2>
            <div class="text-center">
                <p class="lead">
                    {{ 'SCAN_2FA_QR' | translate }}
                </p>
                <img [src]="sanitizeUrl(imageUrl)" width="400" height="400" />
            </div>
        </div>
    </div>
</main>